import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import setForceLightMode from 'owa-editor/lib/actions/setForceLightMode';
import MoonFilled from 'owa-fluent-icons-svg/lib/icons/WeatherMoonFilled';
import SunnyRegular from 'owa-fluent-icons-svg/lib/icons/WeatherSunnyRegular';
import loc from 'owa-localize';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import type { RibbonEditingInfo } from 'owa-compose-actioncontrols-sections/lib/schema/RibbonEditingInfo';
import { toggleDarkMode, toggleLightMode } from '../strings/actionControlStrings.locstring.json';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import {
    toggleLightViewModeDesc,
    toggleDarkViewModeDesc,
} from '../strings/hoverTooltipDesc.locstring.json';

const onToggleDarkMode = (
    composeId: string,
    retriever: (composeId: string) => RibbonEditingInfo | null
) => {
    const editingInfo = retriever(composeId);
    if (editingInfo) {
        const { editorViewState } = editingInfo;
        setForceLightMode(editorViewState, !editorViewState.forceInLightMode);
    }
};

export const getToggleDarkMode = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonEditingInfo | null,
        styles: IButtonStyles | undefined,
        keyTipTabId: number | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        const viewState = retriever(composeId)?.editorViewState;
        const isDarkMode: boolean = !viewState?.forceInLightMode;
        const controlName = isDarkMode ? toggleLightMode : toggleDarkMode;
        return createAppButton(
            getComposeRibbonId(7003, editorId),
            loc(controlName),
            getComputedCallback(7003, onToggleDarkMode, composeId, retriever),
            keyTipTabId ? constructKeytip([keyTipTabId], 7003) : undefined,
            isDarkMode ? SunnyRegular : MoonFilled,
            undefined /* iconColor */,
            styles,
            {
                disabled: isDisabled,
                customTooltip: getHoverTooltip(
                    controlName,
                    isDarkMode ? toggleLightViewModeDesc : toggleDarkViewModeDesc
                ),
            }
        );
    }
);
