import {
    infoVotingOrganizerResponseWithTime,
    infoVotingOrganizerResponse,
} from './createVotingInfoBarMessage.locstring.json';
import loc, { format } from 'owa-localize';
import LastAction from 'owa-mail-types/lib/types/LastAction';
import type Message from 'owa-service/lib/contract/Message';
import { formatWeekDayDateTime } from 'owa-datetime-formatters';
import { userDate } from 'owa-datetime';
import isSharedFolder from 'owa-mail-store/lib/utils/isSharedFolder';
import createVotingInfoBarOptionsAction from './createVotingInfoBarOptionsAction';
import type { InfoBarOptionsAction } from 'owa-info-bar/lib/schema/InfoBarMessageViewState';
import logVoteStatusForMessageMetrics from './logVoteStatusForMessageMetrics';
import type { ClientMessage } from 'owa-mail-store';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';

export default function createVotingInfoBarMessage(
    message: ClientMessage,
    getLastActionForMessageItem: (message: Message) => string,
    getLastActionTimeForMessageItem: (message: Message) => string
): string | InfoBarOptionsAction {
    const votingInformation = message.VotingInformation;
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2532 (24,32): Object is possibly 'undefined'.
    // @ts-expect-error
    const votingOptionsCount = votingInformation.UserOptions.length;
    const responseIndex = Number(getLastActionForMessageItem(message));
    const mailboxInfo = message.MailboxInfo ?? getModuleContextMailboxInfo();
    const isLastActionValid: boolean =
        responseIndex <= votingOptionsCount &&
        responseIndex >= LastAction.VotingOptionMin &&
        responseIndex <= LastAction.VotingOptionMax;
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (34,11): Type 'string | null | undefined' is not assignable to type 'string'.
    // @ts-expect-error
    const response: string = isLastActionValid
        ? // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
          // -> Error TS2532 (38,11): Object is possibly 'undefined'.
          // @ts-expect-error
          votingInformation.UserOptions[responseIndex - LastAction.VotingOptionMin].DisplayName
        : null;

    if (response) {
        logVoteStatusForMessageMetrics(true /* hasUserResponded */);
        const lastActionTime: string = getLastActionTimeForMessageItem(message);

        if (lastActionTime) {
            // You responded {decision} on {time}
            const formattedDate = formatWeekDayDateTime(userDate(lastActionTime));
            return format(loc(infoVotingOrganizerResponseWithTime), response, formattedDate);
        }

        // You responded {decision}
        return format(loc(infoVotingOrganizerResponse), response);
    } else if (
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2532 (57,25): Object is possibly 'undefined'.
        // @ts-expect-error
        !isSharedFolder(message.ParentFolderId.Id) &&
        mailboxInfo.type === 'UserMailbox'
    ) {
        // The sender has requested a vote. To respond
        return createVotingInfoBarOptionsAction(votingInformation);
    }

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (67,5): Type 'null' is not assignable to type 'string | InfoBarOptionsAction'.
    // @ts-expect-error
    return null;
}
