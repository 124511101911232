import React from 'react';
import { registerIcons } from '@fluentui/style-utilities';
import { numbering, fontIcon } from './style.scss';

import classNames from 'owa-classnames';

export default 'NumberingIcon';

registerIcons({
    icons: {
        NumberingIcon: <span className={classNames(numbering, fontIcon)}></span>,
    },
});
