import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppFlyoutAnchor } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { alignText } from './formatControlStrings.locstring.json';
import AlignCenter from 'owa-fluent-icons-svg/lib/icons/TextAlignCenterRegular';
import { onAlignMenuClicked } from '../actions/deferredMenuDefinitionMutator';
import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';

export const getAlignControl = owaComputedFn(
    (
        editorId: string,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean,
        source: FormattingSource,
        useContentModel: boolean
    ): RibbonControlProps => {
        const label = loc(alignText);
        const storeName = 'alignMenuDefinitionStore';
        return createAppFlyoutAnchor(
            getComposeRibbonId(6071, editorId),
            label,
            getControlMenuStore(editorId, storeName),
            tabId ? constructKeytip([tabId], 6071, true /* hasMenu */) : undefined,
            AlignCenter,
            undefined /* iconColor */,
            styles,
            {
                disabled: isDisabled,
                customTooltip: label,
                onExecute: () => {
                    onAlignMenuClicked(
                        editorId,
                        styles,
                        tabId,
                        isDisabled,
                        source,
                        useContentModel
                    );
                },
                onAfterMenuDismiss: () => {
                    resetMenuDefinition(editorId, storeName);
                    callEditorApi(editorId, 'focus');
                },
            }
        );
    }
);
