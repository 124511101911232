import { createLazyComponent, LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ThreadSummarizationContent" */ './lazyIndex'),
    { name: 'ThreadSummarizationContent' }
);

export const ThreadSummarizationContent = createLazyComponent(
    lazyModule,
    m => m.ThreadSummarizationContent
);

export const ThreadSummarizationHeader = createLazyComponent(
    lazyModule,
    m => m.ThreadSummarizationHeader
);

export const ThreadSummarizationCardLoadingHeader = createLazyComponent(
    lazyModule,
    m => m.ThreadSummarizationCardLoadingHeader
);
export const lazySetSummaryButtonDisplayStatus = new LazyAction(
    lazyModule,
    m => m.setSummaryButtonDisplayStatus
);
export type { ThreadSummarizationCardViewState } from './store/schema/ThreadSummarizationCardViewState';
