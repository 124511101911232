import type { IButtonStyles } from '@fluentui/react';
import { constructKeytip, createAppButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import loc from 'owa-localize';
import { pictureChangeButton } from './pictureControlsStrings.locstring.json';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import ImageAddRegular from 'owa-fluent-icons-svg/lib/icons/ImageAddRegular';
import { changeImageCommand } from 'owa-editor-command';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { getNonBorderFocusedStyle } from 'owa-compose-formatcontrols-sections/lib/utils/nonBorderStyles';

const onPictureChangeClick = (
    editorId: string,
    onAddInlineImage: (customAttachmentHandler?: (files: File[]) => void) => void
) => {
    onAddInlineImage(files => {
        if (files.length > 0) {
            changeImageCommand(editorId, files[0]);
        }
    });
};

export const getPictureChangeControl = (
    editorId: string,
    onAddInlineImage: (customAttachmentHandler?: (files: File[]) => void) => void,
    styles: IButtonStyles | undefined,
    tabId: number | undefined,
    isDisabled: boolean
) => {
    return createAppButton(
        getComposeRibbonId(6084, editorId),
        loc(pictureChangeButton),
        getComputedCallback(6084, onPictureChangeClick, editorId, onAddInlineImage),
        tabId ? constructKeytip([tabId], 6084) : undefined,
        ImageAddRegular,
        undefined /* iconColor */,
        getNonBorderFocusedStyle(styles),
        {
            disabled: isDisabled,
            takeFocusOnClick: true,
        }
    );
};
