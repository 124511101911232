import { getStore } from '../store/store';
import type { FontPickerItem } from './FontPickerItem';
import setLocalFontList from '../actions/setLocalFontList';
import { getFontNameMenuItems } from './FontName';
import { APTOS_FONT_NAME_LIST } from 'owa-aptos/lib/utils/aptosFonts';

interface ChromeFontItem {
    postscriptName: string;
    fullName: string;
    family: string;
    style: string;
}

export default async function loadLocalFonts(targetWindow: Window) {
    if (!getStore().isLocalFontListLoaded) {
        const items = await getLocalFonts(targetWindow);
        items.unshift(...getFontNameMenuItems(APTOS_FONT_NAME_LIST));
        if (items.length > 0) {
            setLocalFontList(items);
        }
    }
}

interface WindowExt extends Window {
    queryLocalFonts: () => Promise<ChromeFontItem[]>;
}

async function getLocalFonts(targetWindow: Window): Promise<FontPickerItem[]> {
    try {
        if (!('queryLocalFonts' in targetWindow)) {
            return [];
        }
        const fontList: Record<string, string> = {};
        const chromeFonts = await (targetWindow as WindowExt).queryLocalFonts();

        chromeFonts.forEach(font => {
            const fontName = fontList[font.family];
            // First appearance of family
            if (!fontName) {
                fontList[font.family] = font.family;
            }
            // Treat different members of same family as a new family.
            // When a redundant style is found, exclude it.
            // When a supported style is found, include it.
            if (!redundantStyle(font.style) && supportedStyle(font.style)) {
                fontList[font.fullName] = font.fullName;
            }
        });

        return Object.keys(fontList).map(family => {
            const fullName = fontList[family];
            const staticItem = getFontNameMenuItems().filter(
                item => item.displayValue == fullName
            )[0];

            return (
                staticItem || {
                    displayValue: fullName,
                    selectValue: fullName,
                    submitValue: family,
                }
            );
        });
    } catch (e) {
        return [];
    }
}

// Only match 'Regular', 'Bold', 'Italic', and 'Oblique' whole words, but avoid instances like SemiBold
// Regular is the same as the family name
// The space ' ' is there to exclude fonts that have more than one style, which is currently unsupported
// Bold is a font-weight
// Italic and Oblique are font-styles
const REDUNDANT_STYLES = /^Regular| |^Bold$|^Italic$|^Oblique$/;
export function redundantStyle(fontStyles: string): boolean {
    return REDUNDANT_STYLES.test(fontStyles);
}

// Only match currently supported font styles
// This is a non-exclusive list
const supportedStyles = [
    'Light',
    'ExtraLight',
    'SemiBold',
    'Narrow',
    'Medium',
    'Condensed',
    'SemiCondensed',
];
function createSupportedStylesRegex(): string {
    return supportedStyles
        .map(style => {
            return `^${style}$`;
        })
        .join('|');
}

// Flag 'i' added to make the regex case insensitive, as some styles have inconsistent casing
const SUPPORTED_STYLES = new RegExp(createSupportedStylesRegex(), 'i');

export function supportedStyle(fontStyles: string): boolean {
    return SUPPORTED_STYLES.test(fontStyles);
}
