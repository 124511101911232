import { owaComputedFn } from 'owa-computed-fn';
import mailStore from 'owa-mail-store/lib/store/Store';
import type Message from 'owa-service/lib/contract/Message';
import type EmailAddressWrapper from 'owa-service/lib/contract/EmailAddressWrapper';
import { isJoinedGroup } from 'owa-group-utils/lib/utils/getGroupJoinStatus';
import type { MailboxInfo } from 'owa-client-types';

export default owaComputedFn((itemId: string, mailboxInfo: MailboxInfo) => {
    const recipients: EmailAddressWrapper[] = [];

    const message = mailStore.items.get(itemId) as Message;
    message?.ToRecipients && recipients.push(...message.ToRecipients);
    message?.CcRecipients && recipients.push(...message.CcRecipients);

    const groups = recipients
        .filter(
            (recipient, index, recipientSet) =>
                recipient?.MailboxType === 'GroupMailbox' &&
                index === recipientSet.findIndex(r => r.EmailAddress == recipient.EmailAddress)
        )
        .map(recipient => ({
            smtpAddress: recipient.EmailAddress ?? '',
            displayName: recipient.Name ?? '',
        }));

    return groups.sort((group1, group2) => {
        const joinedGroup1 = isJoinedGroup(group1.smtpAddress, mailboxInfo);
        const joinedGroup2 = isJoinedGroup(group2.smtpAddress, mailboxInfo);

        if (joinedGroup1 && !joinedGroup2) {
            return -1;
        } else if (!joinedGroup1 && joinedGroup2) {
            return 1;
        }

        return 0;
    });
});
