export const writeEmailHeaderText = "tgARKd";
export const emailListHeaderText = "Wg7red";
export const readEmailHeaderText = "Kktrf";
export const goToHeaderText = "PoY13c";
export const emailActionsHeaderText = "H3qMCe";
export const newEmailCommand = "JYZMLb";
export const sendEmailCommand = "fRx6Le";
export const replyEmailCommand = "VFM5ce";
export const replyAllEmailCommand = "ulKZCe";
export const forwardEmailCommand = "qw8xtb";
export const saveDraftCommand = "hGFVhd";
export const discardDraftCommand = "uoge3d";
export const goToInboxCommand = "_E9bIc";
export const goToDraftsCommand = "Y2s7$c";
export const goToSentCommand = "c1Y8Rb";
export const searchEmailCommand = "Bk5njb";
export const undoCommand = "JgSE$d";
export const clearAllMessagesCommand = "zRSXJc";
export const homeCommand = "IFOmYb";
export const endCommand = "ayQrIe";
export const copyMailToClipboardCommand = "eePAPd";
export const pasteMailFromClipboardCommand = "htZOB";
export const deleteEmailCommand = "CnqeId";
export const openEmailCommand = "jOx25";
export const openEmailInNewWindowCommand = "CHBfnd";
export const closeEmailCommand = "RQICxe";
export const openNextItemCommand = "VIg5zd";
export const openPreviousItemCommand = "FeBOhe";
export const focusNextItemPartCommand = "WFJQXd";
export const focusPreviousItemPartCommand = "uZvFLb";
export const expandCollapseConversationCommand = "tLIeF";
export const expandConversationCommand = "i5RsXd";
export const collapseConversationCommand = "vPWE6d";
export const softDeleteCommand = "arFO3b";
export const newFolderCommand = "pvNo5c";
export const markReadCommand = "gJGdfc";
export const markUnreadCommand = "Dlcgtd";
export const flagEmailCommand = "VYJeSc";
export const archiveCommand = "HSAz1";
export const markJunkCommand = "c_Rkwc";
export const categorize = "fEcPlb";
export const insertHyperlinkCommand = "W13DN";
export const showKeyboardShortcutsCommand = "gQAXod";
export const toggleSelectMessageCommand = "IRBxwe";
export const quickStepCommand = "sG6J2d";
export const zoomInCommand = "xASbVe";
export const zoomOutCommand = "cLgHAb";
export const resetZoomCommand = "n6FBFc";
export const exitMessageEditor = "aXoif";
export const printEmailCommand = "Mbu2J";
export const syncMaiboxCommand = "axjUHc";
export default {writeEmailHeaderText,emailListHeaderText,readEmailHeaderText,goToHeaderText,emailActionsHeaderText,newEmailCommand,sendEmailCommand,replyEmailCommand,replyAllEmailCommand,forwardEmailCommand,saveDraftCommand,discardDraftCommand,goToInboxCommand,goToDraftsCommand,goToSentCommand,searchEmailCommand,undoCommand,clearAllMessagesCommand,homeCommand,endCommand,copyMailToClipboardCommand,pasteMailFromClipboardCommand,deleteEmailCommand,openEmailCommand,openEmailInNewWindowCommand,closeEmailCommand,openNextItemCommand,openPreviousItemCommand,focusNextItemPartCommand,focusPreviousItemPartCommand,expandCollapseConversationCommand,expandConversationCommand,collapseConversationCommand,softDeleteCommand,newFolderCommand,markReadCommand,markUnreadCommand,flagEmailCommand,archiveCommand,markJunkCommand,categorize,insertHyperlinkCommand,showKeyboardShortcutsCommand,toggleSelectMessageCommand,quickStepCommand,zoomInCommand,zoomOutCommand,resetZoomCommand,exitMessageEditor,printEmailCommand,syncMaiboxCommand};