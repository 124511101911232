import { createLazyComponent, LazyModule, registerLazyOrchestrator } from 'owa-bundling';
import { GlobalSettingsAccountFolderNodeWithDummyList } from './components/GlobalSettingsAccountFolderNodeWithDummyList';
import onAccountAuthSucceeded from 'owa-account-source-list-store/lib/actions/onAccountAuthSucceeded';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "Accounts" */ './lazyIndex'));

export const AccountsTreeView = createLazyComponent(
    lazyModule,
    m => m.AccountsTreeView,
    GlobalSettingsAccountFolderNodeWithDummyList
);

export const AccountsRootContextMenu = createLazyComponent(
    lazyModule,
    m => m.AccountsRootContextMenu
);

// Lazy orchestrators
registerLazyOrchestrator(
    onAccountAuthSucceeded,
    lazyModule,
    m => m.onAccountAuthSucceededOrchestrator
);
