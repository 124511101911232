import { observer } from 'owa-mobx-react';
import { getFolderTable, getEffectiveFolderDisplayName } from 'owa-folders';
import {
    animationContainer,
    contentContainer,
} from 'owa-expansion-animation/lib/styles/expansionAnimationStyles.scss';
import { transitionStyles } from 'owa-expansion-animation';
import { CSSTransition } from 'react-transition-group';
import getFolderViewStateFromId from 'owa-mail-folder-store/lib/selectors/getFolderViewStateFromId';
import React from 'react';
import { MailFolderNodeTreeDocument } from './__generated__/MailFolderNodeTreeQuery.interface';
import type { MailFolderNodeTreeProps } from 'owa-mail-folder-view';
import { MailFolderNode, MailFolderNodeChildren } from 'owa-mail-folder-view';
import { useManagedQuery } from 'owa-apollo-hooks';
import type { MailFolder } from 'owa-graph-schema';
import { isPaid } from 'owa-session-store';
import { LazyCreateSearchFolderModal } from 'owa-mail-search-folder-view';
import { SEARCH_FOLDER_ROOT_DISTINGUISHED_ID } from 'owa-folders-constants';
import { isFeatureEnabled } from 'owa-feature-flags';

/**
 * Component responsible for rendering the Node and then its children
 */
export default observer(function MailFolderNodeTree(props: MailFolderNodeTreeProps) {
    const {
        folderId,
        isFavoritesSupported,
        nestDepth,
        onContextMenu,
        treeType,
        mailboxInfo,
        distinguishedFolderParentIds,
        moveFolder,
        renderFolderNode,
        deleteFolder,
        ellipsesOnHover,
    } = props;

    const isApolloRemovalEnabled = isFeatureEnabled('fp-remove-apollo');

    const { data } = useManagedQuery(MailFolderNodeTreeDocument, {
        variables: {
            id: folderId,
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            mailboxInfo: props.mailboxInfo!,
        },
        fetchPolicy: 'cache-first',
    });

    // Gql and Satchel has to be in sync before rendering the tree node
    // since there are a lot of code that will be called that starts depending on satchel store
    if ((!isApolloRemovalEnabled && !data) || !getFolderTable().has(props.folderId)) {
        return null;
    }

    const folder = isApolloRemovalEnabled ? getFolderTable().get(folderId) : data?.folder;
    if (!folder) {
        return null;
    }

    /**
     * Add child nodes of this node if the node is expanded
     */
    const viewState = getFolderViewStateFromId(folderId);
    const hasChildFolders = !!(folder.childFolderIds && folder.childFolderIds.length > 0);
    const shouldRenderSubTree = viewState?.isExpanded && hasChildFolders;
    const effectiveFolderDisplayName = getEffectiveFolderDisplayName(folder);

    const newDistinguishedFolderParenIds = distinguishedFolderParentIds
        ? [...distinguishedFolderParentIds]
        : [];
    if (folder.distinguishedFolderType) {
        newDistinguishedFolderParenIds.push(folder.distinguishedFolderType);
    }

    return (
        <>
            {/* Render folder node tree*/}
            <MailFolderNode
                key={folderId}
                depth={nestDepth}
                folderId={folderId}
                isBeingDragged={viewState?.drag?.isBeingDragged}
                onContextMenu={onContextMenu}
                shouldHideToggleFavorite={!isFavoritesSupported}
                treeType={treeType}
                folder={folder as MailFolder}
                mailboxInfo={mailboxInfo}
                isFolderExpandable={hasChildFolders}
                effectiveFolderDisplayName={effectiveFolderDisplayName}
                distinguishedFolderParentIds={distinguishedFolderParentIds}
                moveFolder={moveFolder}
                deleteFolder={deleteFolder}
                ellipsesOnHover={ellipsesOnHover}
            />

            {/* Render sub node tree*/}
            <CSSTransition
                in={!!shouldRenderSubTree}
                timeout={200}
                unmountOnExit={true}
                classNames={transitionStyles}
            >
                <div className={animationContainer}>
                    <div className={contentContainer}>
                        <MailFolderNodeChildren
                            folderIdsToRender={folder.childFolderIds ?? emptyArray}
                            nestDepth={nestDepth + 1}
                            treeType={treeType}
                            isFavoritesSupported={isFavoritesSupported}
                            onContextMenu={onContextMenu}
                            renderFolderNode={renderFolderNode}
                            distinguishedFolderParentIds={newDistinguishedFolderParenIds}
                        />
                    </div>
                </div>
            </CSSTransition>

            {/* Render "New search folder" node and creation modal */}
            {isPaid(mailboxInfo) &&
                folder.distinguishedFolderType == SEARCH_FOLDER_ROOT_DISTINGUISHED_ID && (
                    <LazyCreateSearchFolderModal mailboxInfo={mailboxInfo} />
                )}
        </>
    );
}, 'MailFolderNodeTree');

const emptyArray: string[] = [];
