import { EntityType } from '@nova/types';

import type { LinkedEntity } from '@nova/types';
import type { MailEntrypoint, CalendarEntrypoint } from 'owa-m365-acquisitions/lib/types';
import type { MailboxInfo } from 'owa-client-types';

type ComposeLinkedEntityId = {
    scenario: Extract<'MessageCompose', MailEntrypoint>;
    anchorElementId: string;
    activeContentTabId: string;
    formId: string;
    mailboxInfo?: MailboxInfo;
};
type CalendarComposeLinkedEntityId = {
    scenario: Extract<'CalendarCompose', CalendarEntrypoint>;
    anchorElementId: string;
    activeContentTabId: string;
    formId: string;
    mailboxInfo?: MailboxInfo;
};
type ReadLinkedEntityId = {
    scenario: Extract<'MessageRead', MailEntrypoint>;
    anchorElementId: string;
    activeContentTabId: string;
    readingPaneItemId: string;
};
type CalendarReadLinkedEntityId = {
    scenario: Extract<'CalendarRead', CalendarEntrypoint>;
    anchorElementId: string;
    activeContentTabId: string;
    formId: string;
    mailboxInfo?: MailboxInfo;
};
export type DecodedLinkedEntityId =
    | ReadLinkedEntityId
    | CalendarReadLinkedEntityId
    | ComposeLinkedEntityId
    | CalendarComposeLinkedEntityId;

export type EncodedLinkedEntity = LinkedEntity & {
    id: string;
    type: EntityType.m365_messageextension;
};

export function encodeLinkedEntity(decodedParams: DecodedLinkedEntityId): EncodedLinkedEntity {
    switch (decodedParams.scenario) {
        case 'MessageCompose':
            return {
                id: JSON.stringify({
                    scenario: decodedParams.scenario,
                    formId: decodedParams.formId,
                    anchorElementId: decodedParams.anchorElementId,
                    activeContentTabId: decodedParams.activeContentTabId,
                    mailboxInfo: decodedParams.mailboxInfo,
                }),
                type: EntityType.m365_messageextension,
            };
        case 'MessageRead':
            return {
                id: JSON.stringify({
                    scenario: decodedParams.scenario,
                    readingPaneItemId: decodedParams.readingPaneItemId,
                    anchorElementId: decodedParams.anchorElementId,
                    activeContentTabId: decodedParams.activeContentTabId,
                }),
                type: EntityType.m365_messageextension,
            };
        case 'CalendarCompose':
            return {
                id: JSON.stringify({
                    scenario: decodedParams.scenario,
                    formId: decodedParams.formId,
                    anchorElementId: decodedParams.anchorElementId,
                    activeContentTabId: decodedParams.activeContentTabId,
                    mailboxInfo: decodedParams.mailboxInfo,
                }),
                type: EntityType.m365_messageextension,
            };
        case 'CalendarRead':
            return {
                id: JSON.stringify({
                    scenario: decodedParams.scenario,
                    anchorElementId: decodedParams.anchorElementId,
                    activeContentTabId: decodedParams.activeContentTabId,
                    formId: decodedParams.formId,
                    mailboxInfo: decodedParams.mailboxInfo,
                }),
                type: EntityType.m365_messageextension,
            };
    }
    throw new Error('Invalid scenario.');
}
