import { unselectItemParts } from './toggleSelectItemPart';
import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import { FocusedItemArea } from 'owa-mail-reading-pane-store/lib/store/schema/FocusedItemPart';
import type ItemPartViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemPartViewState';
import getConversationReadingPaneViewState from '../utils/getConversationReadingPaneViewState';
import findInlineComposeViewState from 'owa-mail-compose-actions/lib/utils/findInlineComposeViewState';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';

export interface ToggleIsOofRollUpExpandedState {
    conversationReadingPaneState: ConversationReadingPaneViewState;
}

export default action('toggleIsOofRollUpExpanded')(function toggleIsOofRollUpExpanded(
    conversationId: string,
    viewState: ItemPartViewState,
    state: ToggleIsOofRollUpExpandedState = {
        conversationReadingPaneState: getConversationReadingPaneViewState(conversationId),
    }
) {
    viewState.oofRollUpViewState.isOofRollUpExpanded =
        !viewState.oofRollUpViewState.isOofRollUpExpanded;
    const canFocus = !findInlineComposeViewState(conversationId);
    if (canFocus && state.conversationReadingPaneState) {
        unselectItemParts(conversationId, {
            conversationReadingPaneState: state.conversationReadingPaneState,
        });
        state.conversationReadingPaneState.focusedItemPart = {
            itemPart: viewState,
            focusedItemArea: FocusedItemArea.Oof,
        };
    }
});
