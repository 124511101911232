import { action } from 'satcheljs';
import type Message from 'owa-service/lib/contract/Message';

/**
 * Action triggered when user corrects F/O classification
 * @param isFocused whether the inbox view is Focused or not.
 * @param originalMessage the original message that can be appended for feedback.
 */
export const onFocusedInboxClassificationCorrection = action(
    'ON_FOCUSED_INBOX_CLASSIFICATION_CORRECTION',
    (isFocused: boolean, originalMessage: Message) => ({
        isFocused,
        originalMessage,
    })
);
