import { createLazyComponent, LazyImport } from 'owa-bundling';
import { lazyModule } from './lazyFunctions';

// Delay loaded components
export const Ribbon = createLazyComponent(lazyModule, m => m.Ribbon);
export const ContextualUIContainer = createLazyComponent(lazyModule, m => m.ContextualUIContainer);

// Delay load utils
export const lazyShowInsertLinkDialog = new LazyImport(lazyModule, m => m.showInsertLinkDialog);
export const lazyDisplayContextFormatBar = new LazyImport(
    lazyModule,
    m => m.displayContextFormatBar
);
export const lazyGetHandleScrollEvent = new LazyImport(lazyModule, m => m.getHandleScrollEvent);

// export synchronous interfaces
export type { RibbonButton, RibbonDropDownProps } from './components/buttons/RibbonButton';
export type { RibbonProps } from './components/RibbonProps';

export { getText } from './utils/getText';
