import type { IButtonStyles } from '@fluentui/react';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppToggleButton } from 'owa-command-ribbon';
import type RibbonFormatState from 'owa-editor-ribbonplugin-store/lib/store/schema/RibbonFormatState';
import { toggleNumberingCommand } from 'owa-editor-command';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import Numbering from '../fonts/NumberingIcon';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { numberingText } from './formatControlStrings.locstring.json';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import { getHoverTooltipHotkey } from 'owa-compose-ribbon-utils/lib/utils/hoverTooltipHotkeyMap';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';

export const getNumberingControl = owaComputedFn(
    (
        editorId: string,
        formatViewState: RibbonFormatState,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean,
        source: FormattingSource
    ): RibbonControlProps => {
        return createAppToggleButton(
            getComposeRibbonId(6006, editorId),
            loc(numberingText),
            getComputedCallback(6006, toggleNumberingCommand, editorId, source),
            !!formatViewState.isNumbering,
            tabId ? constructKeytip([tabId], 6006) : undefined,
            Numbering,
            undefined /* iconColor */,
            styles,
            {
                disabled: isDisabled,
                customTooltip: getHoverTooltip(
                    numberingText,
                    undefined /* description*/,
                    getHoverTooltipHotkey(6006)
                ),
            }
        );
    }
);
