import React from 'react';
import { registerIcons } from '@fluentui/style-utilities';
import { subscript, fontIcon } from './style.scss';

import classNames from 'owa-classnames';

export default 'SubscriptIcon';

registerIcons({
    icons: {
        SubscriptIcon: <span className={classNames(subscript, fontIcon)}></span>,
    },
});
