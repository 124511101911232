import firstLoadConversationReadingPane from './firstLoadConversationReadingPane';
import initializeInfoBarIdsForItem from 'owa-mail-reading-pane-store/lib/infoBar/initializeInfoBarIdsForItem';
import {
    addLoadedConversationReadingPaneViewState,
    releaseOrphanedLoadedConversationViewStates,
} from '../mutators/loadedConversationViewStateMutators';
import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import type ItemPartViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemPartViewState';
import type LoadingState from 'owa-mail-reading-pane-store/lib/store/schema/LoadingState';
import type QuotedBodyViewState from 'owa-mail-reading-pane-store/lib/store/schema/QuotedBodyViewState';
import type ReadingPaneStore from 'owa-mail-reading-pane-store/lib/store/schema/ReadingPaneStore';
import type SmartPillViewState from 'owa-mail-reading-pane-store/lib/store/schema/SmartPillViewState';
import { getConversationReadingPaneStateCustomData } from 'owa-mail-reading-pane-store/lib/utils/getReadingPaneStateCustomData';
import isConversationReadingPaneViewStateLoaded from '../utils/isConversationReadingPaneViewStateLoaded';
import { createOofRollUpViewState } from '../utils/rollUp/oofRollUpUtils';
import { ObservableMap } from 'mobx';
import {
    returnTopExecutingActionDatapoint,
    PerformanceDatapoint,
    logUsage,
    wrapFunctionForCoreDatapoint,
} from 'owa-analytics';
import { addTimingsAndEndDatapointOnRender } from 'owa-analytics-shared';
import { DataSource } from 'owa-analytics-types';
import type { ClientItemId } from 'owa-client-ids';
import createInfoBarHostViewState from 'owa-info-bar/lib/utils/createInfoBarHostViewState';
import checkIfConversationCacheStale from 'owa-mail-check-cache/lib/utils/checkIfConversationCacheStale';
import { getFolderIdForSelectedNode } from 'owa-mail-folder-forest-store';
import mailStore from 'owa-mail-store/lib/store/Store';
import conversationCache from 'owa-mail-store/lib/store/conversationCache';
import isValidConversationCache from 'owa-mail-store/lib/selectors/isValidConversationCache';
import type {
    ConversationItemParts,
    ConversationReadingPaneNode,
    LoadConversationItemActionSource,
    ClientItem,
} from 'owa-mail-store';
import { lazyLoadConversation } from 'owa-mail-store-actions';
import type { MruCache } from 'owa-mru-cache';
import type Item from 'owa-service/lib/contract/Item';
import type Message from 'owa-service/lib/contract/Message';
import type { InstrumentationContext } from 'owa-search-types/lib/types/InstrumentationContext';
import {
    findItemToLoad,
    shouldCreateItemPartViewState,
} from 'owa-mail-store/lib/utils/conversationsUtils';
import { prefetchSafelinksPolicy } from 'owa-mail-reading-pane-store/lib/actions/prefetchSafelinksPolicy';
import {
    clearDelayShimmerTimer,
    setShowLoadingShimmerOnDelay,
} from 'owa-mail-reading-pane-store/lib/mutators/setShowLoadingShimmer';
import { addCustomDataToSelectMailItem } from 'owa-mail-reading-pane-store/lib/utils/datapointUtils';
import type MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import { addReadingPaneLog } from 'owa-mail-reading-pane-logging';
import setItemIdToScrollTo from './setItemIdToScrollTo';
import { isFeatureEnabled } from 'owa-feature-flags';

export interface LoadConversationReadingPaneState {
    readingPaneStore?: ReadingPaneStore;
    conversationNodes: ObservableMap<string, ConversationReadingPaneNode>;
    conversations?: MruCache<ConversationItemParts>;
}

const openedConversationIds: string[] = [];

export function shouldExpandItemPart(item: ClientItem, isLocal: boolean, isDeleted: boolean) {
    return (!(<Message>item).IsRead && isLocal) || (item.IsDraft && !isDeleted);
}

export const createItemPartViewState = function createItemPartViewState(
    nodeId: ClientItemId,
    shouldShowLoadingSpinner: boolean
): [ItemPartViewState | null, Item | null] {
    // Don't create ItemPart for empty node.
    if (!nodeId || !nodeId.Id) {
        return [null, null];
    }
    const conversationNode = mailStore.conversationNodes.get(nodeId.Id);
    const [item, isLocal, isDeleted] = findItemToLoad(conversationNode);
    // Don't create ItemPart if we do not have itemId
    if (!item?.ItemId?.Id) {
        return [null, null];
    }
    if (!shouldCreateItemPartViewState(item, isDeleted, isLocal)) {
        return [null, null];
    }
    const initialQuotedBodyLoadingState: LoadingState = {
        isLoading: false,
        hasLoadFailed: false,
    };
    const quotedBodyViewState: QuotedBodyViewState = {
        isExpanded: false,
        loadingState: initialQuotedBodyLoadingState,
    };
    const smartPillViewState: SmartPillViewState = {
        smartPillBlockVisible: false,
    };
    const isExpanded = item && shouldExpandItemPart(item, isLocal, isDeleted);
    const initialItemLoadingState: LoadingState = {
        isLoading: false,
        hasLoadFailed: false,
    };
    const itemId: ClientItemId = {
        mailboxInfo: nodeId.mailboxInfo,
        Id: item?.ItemId?.Id ?? '',
    };

    const itemPartViewState: ItemPartViewState = {
        ...createInfoBarHostViewState(itemId.Id, initializeInfoBarIdsForItem()),
        conversationNodeId: nodeId.Id,
        itemId: itemId.Id,
        isConversationItemPart: true,
        isExpanded: !!isExpanded,
        isLocal,
        isDeleted,
        hasExpanded: !!isExpanded,
        attachmentWell: undefined,
        quotedBodyViewState,
        isFossilizedTextExpanded: !!isExpanded,
        hideSmartReplyFeedbackDialog: true,
        meetingRequestViewState: null,
        loadingState: initialItemLoadingState,
        actionableMessageCardInItemViewState: {
            showBodyWithMessageCard: true,
            shouldShowLoadingSpinner,
        },
        undoDarkMode: false,
        isLoadingFullBody: false,
        oofRollUpViewState: createOofRollUpViewState(item.ItemClass ?? ''),
        isInRollUp: false,
        smartPillViewState,
        omeMessageState: null,
    };
    return [itemPartViewState, item];
};

function createInitialConversationState(
    conversationId: ClientItemId,
    conversationSubject: string,
    conversationCategories: string[],
    instrumentationContext?: InstrumentationContext,
    itemIdToScrollTo?: string
): ConversationReadingPaneViewState {
    const initialLoadingState: LoadingState = {
        isLoading: true,
        hasLoadFailed: false,
        // This is set to true on delay to prevent shimmer from showing up too quickly.
        showLoadingShimmer: false,
    };
    const initialConversationState: ConversationReadingPaneViewState = {
        conversationId,
        conversationSubject,
        conversationCategories,
        initiallySelectedItemPart: undefined,
        itemIdToScrollTo: itemIdToScrollTo ?? '', // This will be null if not supplied by the caller
        itemPartsMap: new ObservableMap<string, ItemPartViewState>({}),
        loadingState: initialLoadingState,
        currentSelectedFolderId: getFolderIdForSelectedNode(),
        unsupportedItemId: undefined,
        instrumentationContext,
        attachmentWell: undefined,
        calendarInlineComposeViewState: undefined,
        focusedItemPart: undefined,
        conversationNodeIdsInCollapsedItemsRollUp: [],
        conversationNodeIdsInCollapsedItemsRollUpExcludingRSVP: [],
        nodeIdBundledWithSeeMoreMessages: undefined,
        extendedCardViewState: undefined,
        infoPaneOpened: false,
        showRSVPTimeline: false,
    };
    return initialConversationState;
}

export default wrapFunctionForCoreDatapoint(
    {
        name: 'RPPerfLoadConversationReadingPane',
    },
    function loadConversationReadingPane(
        conversationId: ClientItemId,
        actionSource: LoadConversationItemActionSource,
        instrumentationContext?: InstrumentationContext,
        conversationSubject?: string,
        conversationCategories?: string[],
        itemIdToScrollTo?: string,
        mailListItemSelectionSource?: MailListItemSelectionSource,
        selectMailItemDatapoint?: PerformanceDatapoint | null,
        isSingleLineView?: boolean,
        maxNumberOfItemsToReturn?: number
    ): Promise<void> {
        let promiseToReturn = Promise.resolve();

        // If a previous non-critical path datapoint has not ended due to user selecting another conversation before the
        // previous one loads or if the previous one resulted in an error and did not reach the end of the datapoint,
        // we should end it here.
        let datapoint = returnTopExecutingActionDatapoint((dp: PerformanceDatapoint) => {
            return dp.getEventName() == 'OpenConversationNonCritical';
        });
        if (datapoint) {
            datapoint.invalidate();
            datapoint.end();
        }

        let cacheState: string;
        clearDelayShimmerTimer();
        addCustomDataToSelectMailItem(
            selectMailItemDatapoint,
            'StackedConversationView' /* ReadingPaneSetting */,
            isSingleLineView
        );
        const conversationViewState = createInitialConversationState(
            conversationId,
            conversationSubject || '',
            conversationCategories || [],
            instrumentationContext,
            itemIdToScrollTo
        );
        const hasValidConversationCache = isValidConversationCache(conversationId.Id);

        releaseOrphanedLoadedConversationViewStates();
        const isConversationStale = checkIfConversationCacheStale(conversationId.Id);
        // If has valid conversation cache (no matter stale or not when compared to list view cached data), directly load the valid cache
        if (hasValidConversationCache) {
            cacheState = 'Valid';
            if (!isConversationStale) {
                addReadingPaneLog('LoadConversationRPStart', {
                    id: conversationId.Id,
                    source: actionSource,
                    data: 'cache',
                });
                selectMailItemDatapoint?.addDataSource(DataSource.IN_MEMORY);
            }
            // Touch valid cache to avoid the conversation gets purged from cache
            conversationCache.getAndTouch(conversationId.Id);

            if (!isConversationReadingPaneViewStateLoaded(conversationId.Id)) {
                addLoadedConversationReadingPaneViewState(conversationViewState);
                setShowLoadingShimmerOnDelay(conversationId.Id, true /*isConversation */);
                firstLoadConversationReadingPane(conversationId.Id);
            }
        }
        datapoint = returnTopExecutingActionDatapoint((dp: PerformanceDatapoint) => {
            return dp.getEventName() == 'RPPerfLoadConversationReadingPane';
        });

        if (actionSource !== 'ListViewSelectionChange') {
            // It is considered a non-core scenario if it is anything other than coming from list view selection change.
            // Some of non-core scenarios are SxS, switching between primary and secondary tabs, reload on message list settings change etc.
            // Invalidate & end RPPerfLoadConversationReadingPane datapoint and start a non-critical one
            datapoint?.invalidate();
            datapoint?.end();
            datapoint = new PerformanceDatapoint('OpenConversationNonCritical');
            datapoint.addCustomData({
                actionSource,
            });
        }

        // If has no valid conversation cache or cache is stale compared to list view cached data, fetch conversation from server
        if (!hasValidConversationCache || isConversationStale) {
            addReadingPaneLog('LoadConversationRPStart', {
                id: conversationId.Id,
                source: actionSource,
                data: 'network',
            });
            addLoadedConversationReadingPaneViewState(conversationViewState);
            setShowLoadingShimmerOnDelay(conversationId.Id, true /*isConversation */);
            cacheState = hasValidConversationCache ? 'Stale' : 'Not';
            promiseToReturn = lazyLoadConversation.importAndExecute(
                conversationId,
                actionSource,
                datapoint,
                selectMailItemDatapoint,
                maxNumberOfItemsToReturn,
                mailListItemSelectionSource,
                itemIdToScrollTo ? [itemIdToScrollTo] : undefined /* itemsRequiredInResponse */
            );
        }

        prefetchSafelinksPolicy(conversationId.mailboxInfo);

        return promiseToReturn.then(
            () => {
                if (itemIdToScrollTo) {
                    setItemIdToScrollTo(conversationId.Id, itemIdToScrollTo);
                }

                addReadingPaneLog('LoadConversationSuccess', {
                    id: conversationId.Id,
                    source: actionSource,
                });

                // For rp-enhanced-telemetry-v2, we do not want to end the datapoint here as it will be ended in the render function of each scenario.
                if (!isFeatureEnabled('rp-enhanced-telemetry-v2') && selectMailItemDatapoint) {
                    // Passing logOnly as false ends the datapoint after logging render end time if it has not already ended.
                    addTimingsAndEndDatapointOnRender(selectMailItemDatapoint, false /* logOnly */);
                }
                // Add conversation state custom data in datapoint
                instrumentationContext?.dp?.addCheckpoint?.('LCRP_POST');
                if (datapoint) {
                    // This is custom data to track prefetch hit rate
                    let hasOpened = false;
                    if (!openedConversationIds.includes(conversationId.Id)) {
                        openedConversationIds.push(conversationId.Id);
                    } else {
                        hasOpened = true;
                    }
                    const isPrefetchHit = cacheState == 'Valid' && !hasOpened;
                    datapoint?.addCustomData(
                        getConversationReadingPaneStateCustomData(
                            conversationId.Id,
                            cacheState,
                            isPrefetchHit,
                            openedConversationIds.length,
                            actionSource
                        )
                    );
                }
                if (
                    !isFeatureEnabled('rp-enhanced-telemetry-v2') &&
                    datapoint?.getEventName() === 'OpenConversationNonCritical'
                ) {
                    addTimingsAndEndDatapointOnRender(datapoint, false /* logOnly */);
                }
            },
            (error: Error) => {
                addReadingPaneLog('LoadConversationRPError', {
                    id: conversationId?.Id,
                    source: actionSource,
                    message: error?.message,
                });
                // Load has failed. End current 'RPPerfLoadConversationReadingPane' / 'OpenConversationNonCritical' datapoint and log error datapoint.
                datapoint?.invalidate();
                datapoint?.end();
                logUsage('ConversationRPLoadError', { actionSource, message: error?.message });
            }
        );
    }
);
