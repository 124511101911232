import { getMailReadingPaneDeeplinkUrl, addPopoutV2 } from 'owa-popout-v2';
import { lazyPreparePopoutDataForReadingPane } from 'owa-mail-folder-common';
import type { ClientItemId } from 'owa-client-ids';
import { type vDirType } from 'owa-vdir-types';

export function reloadMailReadingPanePopoutAsDeeplink(
    targetWindow: Window,
    itemId: ClientItemId | undefined,
    folderId: string | undefined,
    urlParams?: Record<string, string>
) {
    const readDeeplinkUrl = getMailReadingPaneDeeplinkUrl(
        itemId,
        urlParams,
        false /*isStandalone*/
    );

    if (readDeeplinkUrl != null) {
        // Set the target window URL to the deeplink URL
        targetWindow.location.href = readDeeplinkUrl;

        // Set up deeplink callback to post folder data to the reloaded window
        const deeplinkCallback = async () => {
            const preparePopoutDataForReadingPane =
                await lazyPreparePopoutDataForReadingPane.import();
            return preparePopoutDataForReadingPane(undefined /*tableView*/, folderId);
        };

        const data = {
            deeplinkData: deeplinkCallback,
            projectionTargetWindow: targetWindow,
        };

        addPopoutV2('mail', readDeeplinkUrl, data);
    }
}
