import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import Picture from 'owa-fluent-icons-svg/lib/icons/PictureInPictureRegular';
import { getGuid } from 'owa-guid';
import loc from 'owa-localize';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { inlineImageText } from '../strings/actionControlStrings.locstring.json';
import { picturesButtonDesc } from '../strings/hoverTooltipDesc.locstring.json';
import type { ResourceId } from 'owa-localize';

export const getAddInlineImagesControl = owaComputedFn(
    (
        editorId: string | undefined,
        onAddInlineImage: () => void,
        styles: IButtonStyles | undefined,
        isButtonDisabled: boolean,
        keyTipTabId: number | undefined,
        altText?: ResourceId,
        altIcon?: string,
        iconColor?: string
    ): RibbonControlProps => {
        return createAppButton(
            // getGuid() is used for compose ribbon where the image control is not working in popout overflow.
            // Giving it a random id is the workaround today.
            getComposeRibbonId(638, editorId ?? getGuid()),
            altText ? loc(altText) : loc(inlineImageText),
            onAddInlineImage,
            keyTipTabId ? constructKeytip([keyTipTabId], 638) : undefined,
            altIcon ?? Picture,
            iconColor,
            styles,
            {
                customTooltip: getHoverTooltip(inlineImageText, picturesButtonDesc),
                disabled: isButtonDisabled,
            }
        );
    }
);
