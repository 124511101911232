import type { IButtonStyles } from '@fluentui/react';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppToggleButton } from 'owa-command-ribbon';
import { setIndentationCommand } from 'owa-editor-command';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import outdentIcon from '../fonts/OutdentIcon';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { type Indentation } from 'roosterjs-editor-types/lib/enum/Indentation';
import { outdentText } from './formatControlStrings.locstring.json';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';

export const getOutdentControl = owaComputedFn(
    (
        editorId: string,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean,
        source: FormattingSource
    ): RibbonControlProps => {
        return createAppToggleButton(
            getComposeRibbonId(6008, editorId),
            loc(outdentText),
            getComputedCallback(6008, setIndentationCommand, editorId, 1, source),
            false,
            tabId ? constructKeytip([tabId], 6008) : undefined,
            outdentIcon,
            undefined /* iconColor */,
            styles,
            {
                disabled: isDisabled,
            }
        );
    }
);
