import { owaComputedFn } from 'owa-computed-fn';
import { getPinnedAddInsRuntimeControls } from './getPinnedAddInsRuntimeControls';
import { getInstalledAddInsRuntimeControls } from './getInstalledAddInsRuntimeControls';
import type { ComposeViewState } from 'owa-mail-compose-store';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { RibbonGroupId, RuntimeControlId } from 'owa-ribbon-ids';
import { type MailRibbonGroupId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { isMessageExtensionsFlyoutEnabled } from 'owa-m365-acquisitions/lib/utils/isMessageExtensionsFlyoutEnabled';

type RibbonRuntimeControlGroup = 'ComposeAddInsInstalled' | 'ComposeAddInsPinned';

export type RibbonRuntimeControl = {
    controlId: RuntimeControlId;
    buttonProps: RibbonControlProps;
};
export interface RibbonRuntimeControlsGroup {
    /* Identifies the runtime control group */
    controlsGroupName: RibbonRuntimeControlGroup;

    /* Callback that gates whether this control group should get scaling steps */
    shouldAddScalingSteps: () => boolean;

    /* Generate a list of a runtime control group ribbon's button props */
    getControlsProps: (props: { composeViewState?: ComposeViewState }) => RibbonRuntimeControl[];

    /* Get a list of a runtime control group's controlIds; needs to be called after getControlsProps */
    getControlIds: () => RuntimeControlId[];
}

// List of all runtime controls.
let runtimeControlsGroups: RibbonRuntimeControlsGroup;
let initialized = false;

/**
 * Pinned and Installed addins are mutually exclusive based on message extension flights
 * We only need one instance, to save from having to load both
 */
export const getRuntimeControls = owaComputedFn(
    function getRuntimeControls(): RibbonRuntimeControlsGroup {
        if (!initialized) {
            // getPinnedAddins and getInstalledAddins are mutually exclusive based on message extension flights
            // Only load one of them based on the flight
            runtimeControlsGroups = isMessageExtensionsFlyoutEnabled()
                ? getPinnedAddInsRuntimeControls()
                : getInstalledAddInsRuntimeControls();
            initialized = true;
        }
        return runtimeControlsGroups;
    }
);

/**
 * Runtime controls only ever are added to either Message Tab Addins group or Insert Tab Addins group
 */
export const isGroupRuntimeControlEnabled = owaComputedFn(function isGroupRuntimeControlEnabled(
    groupId: RibbonGroupId
): boolean {
    return groupId === 159 || groupId === 165;
});

/**
 * Runtime controls for spam-itegration only ever are added to either Home Tab  or Read Tab Spam Addins group
 */
export const isGroupRuntimeControlEnabledForSpamIntegration = owaComputedFn(
    function isGroupRuntimeControlEnabledForSpamIntegration(groupId: RibbonGroupId): boolean {
        return groupId === 192;
    }
);
