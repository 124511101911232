import { owaComputedFn } from 'owa-computed-fn';
import { createAppButton, constructKeytip } from 'owa-command-ribbon';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import AppFolderRegular from 'owa-fluent-icons-svg/lib/icons/AppFolderRegular';
import { lazyMessageExtensionsRibbonButtonOnExecute } from 'owa-message-extension-flyout';
import { loc } from 'owa-localize/lib/loc';
import { getMessageExtensionsDesc } from '../strings/hoverTooltipDesc.locstring.json';
import { appsInMail } from 'owa-locstrings/lib/strings/moreapps.locstring.json';
import type { AppButtonOnExecuteParameter } from '@1js/acui-button/lib/components/AppButton/AppButton.Props';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import type { ComposeViewState, SharedFolderComposeViewState } from 'owa-mail-compose-store';
import { isSharedComposeItemCheckForAddins } from 'owa-mail-store/lib/utils/sharedFolderUtilsForAddins';
import { isFeatureEnabled, isAddinMultiAccountEnabled } from 'owa-feature-flags';
import { isMessageExtensionsFlyoutEnabled } from 'owa-m365-acquisitions/lib/utils/isMessageExtensionsFlyoutEnabled';

export const getMessageExtensions = owaComputedFn(
    (
        editorId: string,
        viewState: ComposeViewState,
        retriever: (composeId: string) => {
            targetWindow: Window;
        } | null,
        styles: IButtonStyles | undefined,
        keyTipTabId: number | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        const { protectionViewState } = viewState;
        const { IRMData } = protectionViewState;
        const isFlyoutEnabled = isMessageExtensionsFlyoutEnabled(viewState.mailboxInfo);
        const isObjModelFlagFeatureEnabled = isFeatureEnabled(
            'cmp-objmodel-messageExtension',
            viewState.mailboxInfo
        );
        const isProgrammaticAccessAllowed = IRMData?.ProgrammaticAccessAllowed;

        return createAppButton(
            getComposeRibbonId(7016, editorId),
            loc(appsInMail),
            (params?: AppButtonOnExecuteParameter) => {
                const editingInfo = retriever(viewState.composeId);
                if (!!editingInfo && params) {
                    lazyMessageExtensionsRibbonButtonOnExecute.importAndExecute(
                        params,
                        editingInfo.targetWindow,
                        viewState.composeId,
                        isSharedComposeItemCheckForAddins(
                            (viewState as SharedFolderComposeViewState)?.isInSharedFolder
                        )
                            ? 'ComposeSharedFolder'
                            : 'Compose',
                        isAddinMultiAccountEnabled() ? viewState.mailboxInfo : undefined
                    );
                }
            },
            keyTipTabId ? constructKeytip([keyTipTabId], 7016) : undefined,
            AppFolderRegular,
            undefined /* iconColor */,
            styles,
            {
                customTooltip: getHoverTooltip(appsInMail, getMessageExtensionsDesc),
                // Ensure that the disabled logic for the flyout button is decided by isMessageExtensionsFlyoutEnabled
                // if isMessageExtensionsFlyoutEnabled is false, then go with isDisabled passed in from getComposeTabsControlDefinition
                // if ObjModel Flag Feature is enabled and user is not owner of the message, then disable the button if programmatic access is explicitly denied.
                disabled:
                    (!isFlyoutEnabled && isDisabled) ||
                    (isObjModelFlagFeatureEnabled &&
                        !IRMData?.IsOwner &&
                        isProgrammaticAccessAllowed === false),
            }
        );
    }
);
