import type { PictureFormatState } from 'owa-editor-ribbonplugin-store';
import { lazyUpdatePictureFormatState } from 'owa-editor-ribbonplugin-store';
import { getFormatStateCommand } from 'owa-editor-command';

export async function updatePictureFormatState(
    editorId: string,
    pictureFormatState: PictureFormatState
) {
    const format = await getFormatStateCommand(editorId);
    if (format.imageFormat) {
        lazyUpdatePictureFormatState.importAndExecute(pictureFormatState, format.imageFormat);
    }
}
