import type { ComposeViewState } from 'owa-mail-compose-store';
import { mutatorAction } from 'satcheljs';
import createRecipientWell from 'owa-readwrite-recipient-well/lib/utils/createRecipientWell';
import SubstrateSearchScenario from 'owa-search-service/lib/data/schema/SubstrateSearchScenario';

export default mutatorAction('toggleCc', function toggleCc(viewState: ComposeViewState) {
    viewState.isCcShown = !viewState.isCcShown;
    if (viewState.isCcShown && viewState.showCompactRecipientWell) {
        viewState.showCompactRecipientWell = false;
    }
    if (viewState.isCcShown && !viewState.ccRecipientWell) {
        viewState.ccRecipientWell = createRecipientWell(
            viewState.toRecipientWell.mailboxInfo,
            viewState.toRecipientWell.findPeopleFeedbackManager,
            SubstrateSearchScenario.MailCompose
        );
    }
});
