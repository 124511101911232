import type { ComposeActionKey, SurfaceActionsOptions } from 'owa-outlook-service-option-store';
import { getOptionsForFeature, OwsOptionsFeatureType } from 'owa-outlook-service-option-store';

export function shouldDisplayItemOnComposeSurface(key: ComposeActionKey): boolean {
    const alwaysSurfaceItems: ComposeActionKey[] = [
        'ToggleDarkCompose',
        'AddClassicAttachment',
        'InsertLink',
        'FluidHeroButton',
        'MessageExtensions',
    ];
    const alwaysOverflowItems: ComposeActionKey[] = ['ProofingOptions'];
    const surfaceActionsOptions = getOptionsForFeature<SurfaceActionsOptions>(
        OwsOptionsFeatureType.SurfaceActions
    );

    // If we were able to create a command bar item, check if the user has it in their surface actions.
    // VSO 130482: Allow users to hide fluid menu item from settings
    return (
        (!alwaysOverflowItems.includes(key) &&
            surfaceActionsOptions.composeSurfaceActions.includes(key)) ||
        surfaceActionsOptions.composeSurfaceAddins.includes(key) ||
        alwaysSurfaceItems.includes(key)
    );
}
