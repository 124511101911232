import type { RegisterRouteFunction, OnNavigateToRouteHandler } from 'owa-router';
import {
    lazyMountAndShowFullOptions,
    lazyHandleFullOptionsDeepLink,
    lazyHideFullOptions,
    lazyConfirmDirtyOptionsAndPerformAction,
    OPTION_ROUTE_KEYWORD as OPTION_ROUTE_KEYWORD_DEFINED_IN_OPTIONS_VIEW,
} from 'owa-options-view';

export const OPTION_ROUTE_KEYWORD = OPTION_ROUTE_KEYWORD_DEFINED_IN_OPTIONS_VIEW;

interface OptionRouteParameters {
    category?: string;
    subcategory?: string;
    option?: string;
    suboption?: string;
}

async function deeplinkOptionsRouteHandler(parameters: OptionRouteParameters) {
    const { category, subcategory, option, suboption } = parameters;
    await lazyHandleFullOptionsDeepLink.importAndExecute(
        category,
        subcategory,
        option,
        suboption,
        undefined, // mailboxInfo
        window
    );
}

async function optionsRouteHandler(parameters: OptionRouteParameters) {
    const { category, subcategory, option, suboption } = parameters;
    await lazyMountAndShowFullOptions.importAndExecute(
        category,
        subcategory,
        option,
        undefined, // targetWindow
        undefined, // mailboxInfo
        suboption
    );
}

async function optionsRouteCleanupHandler(
    _oldRoute: string[] | undefined,
    oldParameters: OptionRouteParameters,
    newRoute: string[],
    newParameters: OptionRouteParameters
): Promise<boolean> {
    const isOptionsClosing = !(
        newRoute[0] === OPTION_ROUTE_KEYWORD || newRoute[1] === OPTION_ROUTE_KEYWORD
    );
    const needToSaveDiscard =
        isOptionsClosing ||
        oldParameters.category != newParameters.category ||
        oldParameters.subcategory != newParameters.subcategory;
    return new Promise<boolean>(async resolve => {
        const confirmDirtyOptionsAndPerformAction =
            await lazyConfirmDirtyOptionsAndPerformAction.import();

        if (needToSaveDiscard) {
            confirmDirtyOptionsAndPerformAction(
                async () => {
                    resolve(false /* preventNavigation */);
                    if (isOptionsClosing) {
                        // We're navigating away from options
                        const hideFullOptions = await lazyHideFullOptions.import();
                        hideFullOptions(false);
                    }
                },
                () => resolve(true /* preventNavigation */)
            );
        } else {
            resolve(false /* preventNavigation */);
        }
    });
}

export function initializeOptionRoutes(registerRouteFunc: RegisterRouteFunction) {
    const registerRoute = (route: string, onNavigationTo: OnNavigateToRouteHandler) => {
        registerRouteFunc(route, onNavigationTo, optionsRouteCleanupHandler);
    };

    registerRoute(`/deeplink/${OPTION_ROUTE_KEYWORD}`, deeplinkOptionsRouteHandler);
    registerRoute(`/deeplink/${OPTION_ROUTE_KEYWORD}/:category`, deeplinkOptionsRouteHandler);
    registerRoute(
        `/deeplink/${OPTION_ROUTE_KEYWORD}/:category/:subcategory`,
        deeplinkOptionsRouteHandler
    );
    registerRoute(
        `/deeplink/${OPTION_ROUTE_KEYWORD}/:category/:subcategory/:option`,
        deeplinkOptionsRouteHandler
    );
    registerRoute(
        `/deeplink/${OPTION_ROUTE_KEYWORD}/:category/:subcategory/:option/:suboption`,
        deeplinkOptionsRouteHandler
    );

    registerRoute(`/${OPTION_ROUTE_KEYWORD}`, optionsRouteHandler);
    registerRoute(`/${OPTION_ROUTE_KEYWORD}/:category`, optionsRouteHandler);
    registerRoute(`/${OPTION_ROUTE_KEYWORD}/:category/:subcategory`, optionsRouteHandler);
    registerRoute(`/${OPTION_ROUTE_KEYWORD}/:category/:subcategory/:option`, optionsRouteHandler);
    registerRoute(
        `/${OPTION_ROUTE_KEYWORD}/:category/:subcategory/:option/:suboption`,
        optionsRouteHandler
    );
}
