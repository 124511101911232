import getStore from '../store/store';
import type FluidLinkViewStore from '../store/schema/FluidLinkViewStore';

export function getFocusIsOnLoopForViewStateId(ViewStateId: string | undefined): boolean {
    let focusOnLoop: boolean = false;
    if (ViewStateId !== undefined) {
        const store: FluidLinkViewStore = getStore();
        focusOnLoop = store.viewStateWithLoopFocus === ViewStateId;
    }
    return focusOnLoop;
}
