import setAppPaneUnderlayVisibility from 'owa-application/lib/actions/setAppPaneUnderlayVisibility';
import { PinnablePane } from 'owa-outlook-service-option-store';
import { openFlexPane } from 'owa-suite-header-apis';
import { isFlexPaneShown, isSuiteHeaderRendered } from 'owa-suite-header-store';
import { getPinnablePaneRegistration } from './getPinnablePaneRegistrations';
import { isPanePinningEnabled } from './isPanePinningEnabled';

/**
 * Set up app pane underlay and schedule an auto-open operation if pre-requisites are met
 */
export function tryAutoOpen(pinnablePane: PinnablePane): void {
    if (!isPanePinningEnabled() || pinnablePane === PinnablePane.None) {
        return;
    }

    const { flexPaneId, shouldAutoOpen, handleAutoOpen } =
        getPinnablePaneRegistration(pinnablePane);

    const shouldPaneAutoOpen = shouldAutoOpen?.() ?? true;
    if (!shouldPaneAutoOpen) {
        return;
    }

    // set up app pane underlay to pre-reserve space in the app layout, since auto-open will take place
    // otherwise layout thrashing will occur shortly after initial render due to pane auto-open pushing app content content to the side
    // NOTE: this is only a concern for large-screen scenarios, since pane is rendered as overlay on top of app content for small screens
    setAppPaneUnderlayVisibility(
        'suiteFlexPane',
        true /* isVisible */,
        true /* isShrinkable */,
        true /* isPlaceholder */
    );

    const autoOpenOperation = handleAutoOpen ?? (() => openFlexPane(flexPaneId));
    if (isSuiteHeaderRendered() && !isFlexPaneShown()) {
        autoOpenOperation();
    } else {
        // queue pending operation for suite-header-rendered orchestrator
        // no need to check for flex pane collision in this scenario
        pendingAutoOpenOperation = autoOpenOperation;
    }
}

let pendingAutoOpenOperation: (() => void) | undefined = undefined;

export function getPendingAutoOpenOperation() {
    return pendingAutoOpenOperation;
}

export function clearPendingAutoOpenOperation() {
    pendingAutoOpenOperation = undefined;
}
