import ImageAltTextRegular from 'owa-fluent-icons-svg/lib/icons/ImageAltTextRegular';
import { constructKeytip, createAppButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import loc from 'owa-localize';
import { altTextButton } from './pictureControlsStrings.locstring.json';
import type { IButtonStyles } from '@fluentui/react';
import { operateOwaImageEdit } from 'owa-editor-imageedit-plugin-operator';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { getNonBorderFocusedStyle } from 'owa-compose-formatcontrols-sections/lib/utils/nonBorderStyles';

const openAltTextDialog = (editorId: string) => {
    operateOwaImageEdit(editorId, imageEdit => {
        imageEdit.onAltText();
    });
};

export const getAltTextControl = (
    editorId: string,
    styles: IButtonStyles | undefined,
    tabId: number | undefined,
    isDisabled: boolean
) => {
    return createAppButton(
        getComposeRibbonId(6085, editorId),
        loc(altTextButton),
        getComputedCallback(6085, openAltTextDialog, editorId),
        tabId ? constructKeytip([tabId], 6085) : undefined,
        ImageAltTextRegular,
        undefined /* iconColor */,
        getNonBorderFocusedStyle(styles),
        {
            disabled: isDisabled,
            takeFocusOnClick: true,
        }
    );
};
