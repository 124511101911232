import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppFlyoutAnchor } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import Attach from 'owa-fluent-icons-svg/lib/icons/AttachRegular';
import loc from 'owa-localize';
import type { MailRibbonTabId } from 'owa-ribbon-ids';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { attachFile_FlyoutButton_ActionControl } from '../strings/actionControlStrings.locstring.json';
import { attachFileDesc } from '../strings/hoverTooltipDesc.locstring.json';
import type AttachmentMultiPicker from 'owa-attachment-filepicker/lib/types/AttachmentMultiPicker';
import type { ComposeViewState } from 'owa-mail-compose-store';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import {
    lazyGetAttachFileActions,
    lazyGetAttachFileSections,
} from 'owa-mail-compose-control-sections/lib/sections/getAttachFileSections';
import { lazyGetAttachmentSuggestionsMenuItems } from 'owa-attachment-suggestions';
import type { MenuDefinition } from '@1js/acui-menu/lib/components/MenuDefinition';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';

export const getAttachFileControl = owaComputedFn(
    (
        viewState: ComposeViewState,
        retriever: (composeId: string) => RibbonMailComposeInfo | null,
        attachmentPickers: AttachmentMultiPicker,
        keytipTabId: MailRibbonTabId | undefined,
        isDisabled: boolean,
        iconColor: string | undefined,
        styles: IButtonStyles | undefined
    ): RibbonControlProps => {
        const composeId: string = viewState.composeId;
        const suggestionsGetter = lazyGetAttachmentSuggestionsMenuItems.tryImportForRender()?.(
            true /* isMail */,
            composeId
        );

        const menuSections: MenuDefinition = {
            sections:
                lazyGetAttachFileSections.tryImportForRender()?.(
                    viewState,
                    retriever,
                    attachmentPickers,
                    keytipTabId,
                    suggestionsGetter
                ) ?? [],
        };

        return createAppFlyoutAnchor(
            getComposeRibbonId(620, viewState.editorId),
            loc(attachFile_FlyoutButton_ActionControl),
            menuSections,
            constructKeytip(keytipTabId ? [keytipTabId] : [], 620, true /* hasMenu */),
            Attach,
            iconColor,
            styles,
            {
                onExecute: () => {
                    lazyGetAttachFileActions.import().then(getAttachFileActions => {
                        const editingInfo = retriever(composeId);
                        if (editingInfo) {
                            const { composeViewState } = editingInfo;
                            getAttachFileActions(
                                composeViewState,
                                retriever,
                                suggestionsGetter
                            ).onMenuOpened();
                        }
                    });
                },
                onAfterMenuDismiss: () => {
                    lazyGetAttachFileActions.import().then(getAttachFileActions => {
                        const editingInfo = retriever(composeId);
                        if (editingInfo) {
                            const { composeViewState } = editingInfo;
                            getAttachFileActions(
                                composeViewState,
                                retriever,
                                suggestionsGetter
                            ).onMenuDismissed();
                        }
                    });
                    callEditorApi(viewState.editorId, 'focus');
                },
                customTooltip: getHoverTooltip(
                    attachFile_FlyoutButton_ActionControl,
                    attachFileDesc
                ),
                delayUpdateFocusOnHover: true,
                disabled: isDisabled,
            }
        );
    }
);
