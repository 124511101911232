import ConversationReadingPaneInfoPage from './ConversationReadingPaneInfoPage';
import { SAVE_SCROLL_DEBOUNCE } from 'owa-mail-reading-pane-view';
import shouldSendViewMessageSignal from 'owa-mail-reading-pane-view/lib/utils/shouldSendViewMessageSignal';
import {
    composeContainer,
    isNewestOnTop,
    isFocused as inlineComposeStyles_isFocused,
    senderImage,
    inlineCompose as inlineComposeStyles_inlineCompose,
} from 'owa-mail-reading-pane-view/lib/components/InlineComposeStyles.scss';
import {
    scrollRegionBottomBuffer,
    navButtonsContainer,
    scrollRegion as styles_scrollRegion,
    noOverlay,
    scrollRegionWillChangeScrollPosition,
} from 'owa-mail-reading-pane-view/lib/components/ReadingPane.scss';
import getUnreadMessageCount from '../utils/getUnreadMessageCount';
import logSelectionUIHotKeyUsage, {
    type SelectionUIHotkey,
} from '../utils/logSelectionUIHotKeyUsage';
import debounce from 'lodash/debounce';
import { observer } from 'owa-mobx-react';
import { lazyLogSigsDatapoint } from 'owa-sigs';
import { animateScrollTop } from 'owa-animation/lib/utils/animateScrollTop';
import type { ClientItemId } from 'owa-client-ids';
import { isFeatureEnabled } from 'owa-feature-flags';
import { logCoreUsage } from 'owa-analytics';
import { isGroupTableQuery } from 'owa-group-utils';
import { getGuid } from 'owa-guid';
import { useKeydownHandler, useKeydownHandlers } from 'owa-hotkeys';
import type { InitialActions } from 'owa-initial-actions';
import { createInitialActions, executeInitialActions } from 'owa-initial-actions';
import { isInlineImageStoreEmpty, lazyClearInlineImageStore } from 'owa-inline-image-loader';
import closeImmersiveReadingPane from 'owa-mail-actions/lib/closeImmersiveReadingPane';
import { userMailInteractionAction } from 'owa-mail-actions/lib/triage/userMailInteractionAction';
import type { CalendarCardViewState } from 'owa-mail-calendar-card';
import { CalendarCardBottom, RSVPWithNote } from 'owa-mail-calendar-card';
import findInlineComposeViewState from 'owa-mail-compose-actions/lib/utils/findInlineComposeViewState';
import type { ComposeViewState } from 'owa-mail-compose-store';
import { Compose } from 'owa-mail-compose-view';
import { ExtendedCardWrapper } from 'owa-mail-extended-card';
import { type FocusComponent, registerComponent, resetFocus } from 'owa-mail-focus-manager';
import { getCommands } from 'owa-mail-hotkeys/lib/utils/MailModuleHotKeys';
import {
    isReadingPanePositionOff,
    isReadingPanePositionBottom,
} from 'owa-mail-layout/lib/selectors/readingPanePosition';
import { shouldShowListView } from 'owa-mail-layout/lib/selectors/shouldShowListView';
import { getSelectedTableView } from 'owa-mail-list-store';
import { getApplicationSettings } from 'owa-application-settings';
import getGroupRecipientsFromItem from 'owa-mail-reading-pane-view/lib/utils/getGroupRecipientsFromItem';
import getMyOrgGroups from 'owa-groups-left-nav-store/lib/selectors/getMyOrgGroups';
import expandCollapseAllItemParts from 'owa-mail-reading-pane-store-conversation/lib/actions/expandCollapseAllItemParts';
import expandCollapseFocusedItemPart from 'owa-mail-reading-pane-store-conversation/lib/actions/expandCollapseFocusedItemPart';
import firstLoadConversationReadingPane from 'owa-mail-reading-pane-store-conversation/lib/actions/firstLoadConversationReadingPane';
import setFocusedItemPart from 'owa-mail-reading-pane-store-conversation/lib/actions/setFocusedItemPart';
import setItemIdToScrollTo from 'owa-mail-reading-pane-store-conversation/lib/actions/setItemIdToScrollTo';
import { saveReadingPaneScrollPosition } from 'owa-mail-reading-pane-store/lib/mutators/saveReadingPaneScrollPositionMutator';
import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import { ExtendedCardType } from 'owa-mail-reading-pane-store/lib/store/schema/ExtendedCardViewState';
import { FocusedItemArea } from 'owa-mail-reading-pane-store/lib/store/schema/FocusedItemPart';
import getConversationReadingPaneViewState from 'owa-mail-reading-pane-store-conversation/lib/utils/getConversationReadingPaneViewState';
import getDefaultDisposalType from 'owa-mail-reading-pane-store/lib/utils/getDefaultDisposalType';
import getFirstItemInConversation from 'owa-mail-reading-pane-store-conversation/lib/utils/getFirstItemInConversation';
import getLastestNonDraftItemId from 'owa-mail-reading-pane-store-conversation/lib/utils/getLastestNonDraftItemId';
import { isAllItemPartsExpanded } from 'owa-mail-reading-pane-store-conversation/lib/utils/isAllItemPartsExpanded';
import SenderImage from 'owa-mail-sender-persona-view/lib/components/SenderImage';
import type { InstrumentationContext } from 'owa-search-types/lib/types/InstrumentationContext';
import type ConversationItemParts from 'owa-mail-store/lib/store/schema/ConversationItemParts';
import mailStore from 'owa-mail-store/lib/store/Store';
import { getItemToShowFromNodeId } from 'owa-mail-store/lib/utils/conversationsUtils';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import isNewestOnBottom from 'owa-mail-store/lib/utils/isNewestOnBottom';
import { lazyDeleteItems } from 'owa-mail-triage-action';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import { useCustomAnimationFrame } from 'owa-react-hooks/lib/useCustomAnimationFrame';
import { useCustomTimeout } from 'owa-react-hooks/lib/useCustomTimeout';
import type Message from 'owa-service/lib/contract/Message';
import { errorThatWillCauseAlert } from 'owa-trace';
import React from 'react';
import onArchive from 'owa-mail-commands/lib/actions/onArchive';
import focusNextPrevItemPartOrArea, {
    tryGetNewFocusedNodeOnDelete,
} from 'owa-mail-reading-pane-store-conversation/lib/actions/focusNextPrevItemPart';
import {
    lazySetAutoMarkAsReadTimer,
    lazyClearAutoMarkAsReadTimer,
} from 'owa-mail-mark-read-actions';
import {
    isItemPartInCollapsedItemsRollUp,
    hasCollapsedItemsRollUp,
} from 'owa-mail-reading-pane-store-conversation/lib/utils/rollUp/collapsedItemsRollUpUtils';
import {
    renderConversationReadingPane,
    renderComponentsBySortOrder,
} from '../utils/readingPaneRenderChooser';
import {
    getInitiallySelectedItemPart,
    getFocusedItemPart,
    getFocusedItemArea,
} from 'owa-mail-reading-pane-store-conversation/lib/utils/focusedItemPartUtils';
import {
    hasExtendedCard,
    isExtendedCardCoveringOriginalContent,
    getExtendedCardViewState,
} from 'owa-mail-reading-pane-store/lib/utils/extendedCardUtils';
import classNames from 'owa-classnames';
import { isBrowserSafari, isBrowserFirefox } from 'owa-user-agent/lib/userAgent';
import {
    itemPart as conversationStyles_itemPart,
    noOverlay as conversationStyles_noOverlay,
    hasCompose,
    subjectContainer,
    isSpecialCaseCard,
    neutralPaletteBackground,
    full,
    medium,
    compact,
    flexContainer,
    scrollRegionContainer,
    itemPartFirefox,
    calendarCardV2Container,
    attendeePanelContainer,
} from './ConversationReadingPane.scss';
import {
    getZoomStyle,
    logEmbiggenZoomSettingDatapoint,
    onWheelZoom,
    onCtrlZoom,
} from 'owa-custom-zoom';
import getCurrentZoomScale from 'owa-custom-zoom-store/lib/utils/getCurrentZoomScale';
import ZoomContext from 'owa-custom-zoom-store/lib/context/ZoomContext';
import { SubjectHeaderV2, SLVNavButtons } from 'owa-mail-subject-header-v2';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import {
    LazySubjectHeaderV3,
    LazySLVNavButtons as LazySLVNavButtonsV3,
} from 'owa-mail-subject-header-v3';
import type { YammerCardViewState } from 'owa-yammer-thread-scenario';
import { lazyCloseInfoPane } from 'owa-info-pane';
import { FluentButtonContextProvider } from 'owa-fluent-v9-context';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import loc from 'owa-localize';
import type { ResourceId } from 'owa-localize';
import {
    conversationReadingPaneContentsAriaLabel,
    conversationReadingPaneAttendeePanelOpened,
    conversationReadingPaneAttendeePanelClosed,
} from './ConversationReadingPane.locstring.json';
import type { MailboxInfo } from 'owa-client-types';
import type ClientItem from 'owa-mail-store/lib/store/schema/ClientItem';
import type CalendarEvent from 'owa-calendar-types/lib/types/CalendarEvent';
import { AttendeePanel } from 'owa-meeting-message';
import getAllItemsShownInConversation from 'owa-mail-reading-pane-store/lib/utils/getAllItemsShownInConversation';
import {
    ThreadSummarizationCard,
    ThreadSummarizationCardForHeaderEntryPoint,
} from 'owa-mail-thread-summarization';
import type { ThreadSummarizationCardViewState } from 'owa-mail-thread-summarization-content/lib/store/schema/ThreadSummarizationCardViewState';
import { lazySetSummaryButtonDisplayStatus } from 'owa-mail-thread-summarization-content';
import { isCopilotFeatureEnabled } from 'owa-copilot-settings-utils';
import { lazyCreateThreadSummarizationViewState } from 'owa-mail-reading-pane-store';
import shouldShowCollapsedMeetingMessage from '../utils/shouldShowCollapsedMeetingMessage';
import { createLazyComponent, LazyModule } from 'owa-bundling';
import doesFolderIdEqualName from 'owa-session-store/lib/utils/doesFolderIdEqualName';
import { isCapabilitySupported } from 'owa-capabilities';
import { bleedThroughCapability } from 'owa-capabilities-definitions/lib/bleedThroughCapability';
import ThreadSummarizationBar from 'owa-mail-thread-summarization/lib/components/ThreadSummarizationBar';
import { lazyLoadUpdateContentProtection } from 'owa-mail-pie';
import { getTabIdFromTargetWindow } from 'owa-mail-reading-pane-store/lib/utils/getTabIdFromTargetWindow';
import ProjectionContext from 'owa-popout-v2/lib/context/ProjectionContext';

/**
 * Components needed only after data has been fetched for first conversation
 */
const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ConversationView" */ './lazyComponents'),
    { name: 'ConversationView' }
);

const ItemPart = createLazyComponent(lazyModule, m => m.ItemPart);
const ExpandCollapseConversationButton = createLazyComponent(
    lazyModule,
    m => m.ExpandCollapseConversationButton
);
const SeeMoreMessages = createLazyComponent(lazyModule, m => m.SeeMoreMessages);

const FOSSILIZED_TEXT_PEEK_HEIGHT = 140;
const SCROLL_ANIMATION_DURATION = 300;
const CONVERSATION_APP_SECTION = 'ConversationContainer';

export interface ConversationReadingPaneProps {
    conversationId: ClientItemId;
    conversationSubject: string;
    conversationCategories: string[];
    instrumentationContext?: InstrumentationContext;
    shouldNotFocusOnInitialRender?: boolean;
    isSxS?: boolean;
    maximizeScrollRegion?: boolean;
}

export default observer(function ConversationReadingPane(props: ConversationReadingPaneProps) {
    const [setFocusTimer] = useCustomTimeout('ConversationReadingPane_Focus');
    const [scrollToInlineComposeTimer] = useCustomTimeout(
        'ConversationReadingPane_scrollToInlineCompose'
    );
    const [scrollToTopTask] = useCustomAnimationFrame('ConversationReadingPane');
    const currentZoomScale = getCurrentZoomScale();

    React.useEffect(() => {
        // Cleanup happens asynchronously, so save off the reference
        let readingPaneContainerCurrent = readingPaneContainer.current;

        unregisterComponent.current = registerComponent('ReadingPane', setFocusOnReadingPane);
        if (!readingPaneContainerCurrent) {
            errorThatWillCauseAlert('readingPaneContainer should not be null');
        }
        tryFinishRenderNewConversation();
        window.addEventListener('beforeunload', readingPaneDisplayEnd);
        window.addEventListener('visibilitychange', readingPaneVisibilityChange);
        return () => {
            // Clean up any pending timers when unmounting
            lazyClearAutoMarkAsReadTimer.importAndExecute();
            if (unregisterComponent.current) {
                unregisterComponent.current();
            }
            if (scrollRegion.current) {
                scrollRegion.current.removeEventListener('scroll', onScrollRegionScroll);
                scrollRegion.current = undefined;
            }
            if (isFocusInReadingPane()) {
                resetFocus('ConversationRPUnmount');
            }
            window.removeEventListener('beforeunload', readingPaneDisplayEnd);
            window.removeEventListener('visibilitychange', readingPaneVisibilityChange);
            readingPaneContainer.current = undefined;
            readingPaneContainerCurrent = undefined;
            itemPartDivElementToScrollTo.current = undefined;
        };
    }, []);

    // We need to set isRenderingNewConversation during render rather than in an effect
    // so that it is set in the case that the first render of a new conversation happens
    // with conversation data.
    const isRenderingNewConversation = React.useRef<boolean>(true);
    const previousConversationId = React.useRef<string>('');
    const conversationIdString = props.conversationId?.Id;
    if (previousConversationId.current !== conversationIdString) {
        isRenderingNewConversation.current = true;
        previousConversationId.current = conversationIdString;
    }

    const { instrumentationContext, isSxS } = props;
    const mailboxInfo = props.conversationId.mailboxInfo ?? getModuleContextMailboxInfo();
    const readingPaneContainer = React.useRef<HTMLDivElement>();
    const scrollRegion = React.useRef<HTMLDivElement>();
    const attendeePanelRef = React.useRef<HTMLDivElement>();
    const itemPartDivElementToScrollTo = React.useRef<HTMLDivElement>();
    const rsvpWithNoteRef = React.useRef<HTMLDivElement>(null);
    const itemPartToScrollToHasFossilizedText = React.useRef<boolean>();
    const composeInitialActions = React.useRef<InitialActions>(createInitialActions());
    const shouldKeepFocus = React.useRef<boolean>();
    const readingPaneDisplayStartDate = React.useRef<Date>();
    const unregisterComponent = React.useRef<() => void>();
    const initiallySelectedItemId = React.useRef<string>();
    const initiallySelectedInternetMessageId = React.useRef<string>();
    const viewSessionGuid = React.useRef<string>();
    const isSubjectHeaderV3 = isFeatureEnabled('rp-subjectHeaderV3');
    const isFluentv9ButtonsEnabled = isFeatureEnabled('fwk-fluent-v9-button-reading-pane');
    const isSingleLine = isReadingPanePositionOff();
    const isBottomReadingPane = isReadingPanePositionBottom();
    const isSettingNewestOnBottom = isNewestOnBottom();
    const shouldRemoveComposeSxS = isFeatureEnabled('cmp-remove-compose-sxs');
    const isBleedThroughSupported = isCapabilitySupported(bleedThroughCapability);

    const composeViewState: ComposeViewState | undefined =
        shouldRemoveComposeSxS && isSxS
            ? undefined
            : findInlineComposeViewState(conversationIdString);
    const conversationItemParts = mailStore.conversations.get(conversationIdString);

    const getConversationNodeIds = (shouldShowRSVPTimeline: boolean): string[] => {
        if (!conversationItemParts?.conversationNodeIds?.length) {
            return [];
        } else {
            return conversationItemParts.conversationNodeIds.filter(nodeId => {
                const item: ClientItem | null = getItemToShowFromNodeId(nodeId);
                if (!item) {
                    return false;
                }
                // check to see if we should skip showing RSVP messages
                if (
                    isFeatureEnabled('rp-citiCardV2', mailboxInfo) &&
                    !shouldShowRSVPTimeline &&
                    shouldShowCollapsedMeetingMessage(item)
                ) {
                    return false;
                }
                // Skip the draft item which has an inline compose in current conversation.
                return !(
                    composeViewState?.itemId &&
                    item.ItemId &&
                    composeViewState.itemId.Id == item.ItemId.Id
                );
            });
        }
    };

    const conversationReadingPaneViewState =
        getConversationReadingPaneViewState(conversationIdString);

    const calendarCardViewState = getCalendarCardViewState(
        conversationReadingPaneViewState,
        mailboxInfo
    );

    const [showAttendeePanel, setShowAttendeePanel] = React.useState<boolean>(false);
    const [attendeePanelFocus, setAttendeePanelFocus] = React.useState<boolean>(false);
    const [showRSVPTimeline, setShowRSVPTimeline] = React.useState<boolean>(true);

    const showAttendeePanelRef = React.useRef<boolean>(showAttendeePanel);
    const showRSVPTimelineRef = React.useRef<boolean>(showRSVPTimeline);

    const handleSetShowAttendeePanel = React.useCallback(() => {
        setShowAttendeePanel(!showAttendeePanelRef.current);
        setAttendeePanelFocus(!showAttendeePanelRef.current);
    }, [showAttendeePanel]);

    const handleShowHideRSVPTimelineClicked = React.useCallback(() => {
        setShowRSVPTimeline(!showRSVPTimelineRef.current);
    }, [showRSVPTimeline]);

    React.useEffect(() => {
        showAttendeePanelRef.current = showAttendeePanel;
    }, [showAttendeePanel]);

    React.useEffect(() => {
        if (attendeePanelFocus) {
            setFocusOnAttendeePanel();
        }
    }, [attendeePanelFocus]);

    React.useEffect(() => {
        showRSVPTimelineRef.current = showRSVPTimeline;
    }, [showRSVPTimeline]);

    React.useEffect(() => {
        if (shouldShowExtendedCalendarCardV2(conversationReadingPaneViewState, mailboxInfo)) {
            if (
                calendarCardViewState &&
                calendarCardViewState.event &&
                calendarCardViewState.event.IsOrganizer
            ) {
                setShowAttendeePanel(true);
                setShowRSVPTimeline(false);
            } else {
                setShowAttendeePanel(false);
            }
        }
    }, [calendarCardViewState?.event]);

    const focusedItemPart = getFocusedItemPart();

    React.useEffect(() => {
        const groups = focusedItemPart
            ? getGroupRecipientsFromItem(focusedItemPart.itemId, mailboxInfo)
            : [];

        if (groups.length > 0) {
            logCoreUsage('GroupMailRead', {
                noOfGroups: getMyOrgGroups(mailboxInfo).length,
                mailboxType: mailboxInfo.type,
                listViewType: 0,
            });
        }
    }, [focusedItemPart?.itemId, mailboxInfo]);

    React.useEffect(() => {
        shouldKeepFocus.current = isFocusInReadingPane();
        readingPaneDisplayStart();

        // In some cases sxs can be loaded before RP code is loaded
        // updateLoadedConversation is called before setting the readingPaneStore.sxsId
        // Meaning firstLoadConversationReadingPane will be skipped, if this happens we must call it here
        if (isSxS && conversationReadingPaneViewState?.loadingState?.isLoading) {
            firstLoadConversationReadingPane(conversationIdString);
        }
        return () => readingPaneDisplayEnd();
    }, [props.conversationId?.Id]);

    React.useEffect(() => {
        // The effect which runs tryFinishRenderNewConversation needs to run after the effect which sets isRenderingNewConversation so that
        // tryFinishRenderNewConversation is called during first render of a conversation which has already been loaded.
        if (isRenderingNewConversation.current) {
            // Inline image store is used to support the local lie scenario within a single conversation.
            // Once we navigate to a different conversation, clear the store so we don't fill the browser memory with dataURIs.
            if (!isInlineImageStoreEmpty()) {
                lazyClearInlineImageStore.importAndExecute();
            }

            // Clear mark as read timer if it's still pending when switching to a new conversation
            lazyClearAutoMarkAsReadTimer.importAndExecute();

            tryFinishRenderNewConversation();
        } else if (composeViewState) {
            executeInitialActions(composeInitialActions.current);
        }
    });

    React.useEffect(
        () => () => {
            const viewState = conversationReadingPaneViewState;
            if (viewState?.infoPaneOpened) {
                lazyCloseInfoPane.importAndExecute();
            }
        },
        [props.conversationId?.Id]
    );
    const shouldShowSeeMoreMessages = useComputedValue((): boolean => {
        return (
            hasCollapsedItemsRollUp(conversationReadingPaneViewState) ||
            !!conversationItemParts?.canLoadMore
        );
    }, [conversationReadingPaneViewState, conversationItemParts]);

    const saveScrollPosition = React.useCallback(() => {
        if (scrollRegion.current) {
            saveReadingPaneScrollPosition(conversationReadingPaneViewState, scrollRegion.current);
        }
    }, [conversationIdString]);
    const debouncedSaveScrollPosition = React.useMemo(
        () => debounce(saveScrollPosition, SAVE_SCROLL_DEBOUNCE),
        [saveScrollPosition]
    );
    const onScrollRegionScroll = React.useCallback(() => {
        if (conversationReadingPaneViewState?.itemIdToScrollTo) {
            // Set the itemIdToScrollTo back to empty string when the user starts scrolling. This allows back to back scrolling for the same item.
            setItemIdToScrollTo(conversationIdString, '' /* itemId */);
        }

        if (!isSxS) {
            debouncedSaveScrollPosition();
        }
    }, [conversationIdString, isSxS]);
    /**
     * Callback when the reading pane gains focus from the focus manager
     */
    const setFocusOnReadingPane = (): boolean => {
        if (scrollRegion.current) {
            scrollRegion.current.focus();
            return true;
        } else if (readingPaneContainer.current) {
            readingPaneContainer.current.focus();
            return true;
        } else {
            return false;
        }
    };

    const setFocusOnAttendeePanel = (): boolean => {
        if (attendeePanelRef.current) {
            attendeePanelRef.current?.focus();
            return true;
        } else {
            return false;
        }
    };

    const isFocusInReadingPane = (): boolean => {
        return !!(
            document.activeElement == readingPaneContainer.current ||
            readingPaneContainer.current?.contains(document.activeElement)
        );
    };
    const handleFocusOnInlineComposeUnmount = () => {
        if (!isRenderingNewConversation.current && readingPaneContainer.current) {
            setFocusOnReadingPane();
        } else {
            resetFocus('ConversationRPInlineComposeUnmount');
        }
    };

    const scrollToItemPart = (
        itemPartDivElement: HTMLDivElement | undefined,
        hasFossilizedText: boolean,
        shouldAnimate: boolean
    ) => {
        if (!itemPartDivElement) {
            return;
        }

        // handleItemPartScrolling can be hit before we have a reference to the scroll region.
        // If that happens, save the itemPartDivElement and fossilizedTextHeight to scroll when we get the reference.
        if (scrollRegion.current) {
            const newScrollTopGetter = function () {
                // If fossilizedText exists on this item and sort order is newestOnBottom, offset the scroll to show a peek
                const fossilizedTextOffset =
                    isSettingNewestOnBottom && hasFossilizedText ? FOSSILIZED_TEXT_PEEK_HEIGHT : 0;
                // If we have a null reference to the itemPartDivElement (VSO #25783), fallback to 0 for offset top.
                // This could result in a negative number set for scrollTop, but this is safe as browser set scrollTop back to 0 in this case.
                /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
                 * Baseline, please provide a proper justification if touching this code
                 *	> 'offsetTop' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
                const itemPartOffsetTop = itemPartDivElement ? itemPartDivElement.offsetTop : 0;
                return itemPartOffsetTop - fossilizedTextOffset;
                // return itemPartDivElement.offsetTop - 2 - fossilizedTextOffset;
            };
            if (shouldAnimate) {
                // We don't animate for the initial render, so reflow isn't our concern in this case.
                animateScrollTop(
                    scrollRegion.current,
                    newScrollTopGetter(),
                    SCROLL_ANIMATION_DURATION
                );
            } else {
                scrollToInlineComposeTimer(() => {
                    // When there is inline compose, no need to scroll to item part and compose will handle the initial scrolling
                    if (
                        !composeViewState && // // This is for initial render, which is the case we care about reflow.
                        // // Querying scroll/box metrics on item part and fossilized text results in forced reflow.
                        // // Add timer to let reflow unblock UI rendering.
                        scrollRegion.current
                    ) {
                        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
                         * Baseline, please provide a proper justification if touching this code
                         *	> 'scrollTop' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
                        scrollRegion.current.scrollTop = newScrollTopGetter();
                    }
                }, 0);
            }
        } else {
            itemPartDivElementToScrollTo.current = itemPartDivElement;
            itemPartToScrollToHasFossilizedText.current = hasFossilizedText;
        }
    };
    const handleItemPartScrolling = React.useCallback(
        (
            viewStateItemId: string,
            itemPartDivElement: HTMLDivElement | undefined,
            hasFossilizedText: boolean,
            isDelayLoadCallback?: boolean
        ) => {
            const itemIdToScrollTo = conversationReadingPaneViewState?.itemIdToScrollTo;
            const savedScrollPosition = conversationReadingPaneViewState?.savedScrollPosition;
            const initiallySelectedItemPart = getInitiallySelectedItemPart();
            if (!(initiallySelectedItemId.current && initiallySelectedInternetMessageId.current)) {
                initiallySelectedItemId.current = initiallySelectedItemPart?.itemId;
                const message =
                    initiallySelectedItemId.current &&
                    (mailStore.items.get(initiallySelectedItemId.current) as Message);
                initiallySelectedInternetMessageId.current = message
                    ? message.InternetMessageId
                    : undefined;
            }

            if (itemIdToScrollTo) {
                // If this conversation has a specific itemId to scroll to, check for that regardless of sort order.
                if (viewStateItemId == itemIdToScrollTo) {
                    scrollToItemPart(
                        itemPartDivElement,
                        hasFossilizedText,
                        !isRenderingNewConversation.current /* don't animate for initial scroll */
                    );
                }
            } else if (!isSxS && savedScrollPosition) {
                scrollToTopTask(() => {
                    if (scrollRegion.current) {
                        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
                         * Baseline, please provide a proper justification if touching this code
                         *	> 'scrollTop' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
                        scrollRegion.current.scrollTop = savedScrollPosition;
                    }
                });
            } else if (
                initiallySelectedItemPart &&
                (isRenderingNewConversation.current ||
                    isDelayLoadCallback ||
                    shouldShowExtendedCalendarCard(
                        conversationReadingPaneViewState,
                        mailboxInfo
                    )) && // Otherwise, only handle scrolling for newest on bottom.
                // This is the initial scroll case for a new conversation or one with a delayLoaded component.
                // Note: isRenderingNewConversation will be set to true in didUpdate. While this method also immediately
                // calls tryFinishRenderNewConversation, conversationReadingPaneViewState.loadingState.isLoading will
                // still be true, therefore this method will get hit before isRenderingNewConversation gets set to false.
                viewStateItemId == initiallySelectedItemPart.itemId
            ) {
                // If the item has TxP data, don't scroll. This will keep the scroll position at 0 and the TxP cards visible.
                const item = mailStore.items.get(viewStateItemId);
                if (item && !item.EntityNamesMap) {
                    scrollToItemPart(
                        itemPartDivElement,
                        hasFossilizedText,
                        false /* shouldAnimate */
                    );
                }
            }
        },
        [
            conversationReadingPaneViewState?.itemIdToScrollTo,
            conversationReadingPaneViewState?.savedScrollPosition,
        ]
    );
    const handleFosslizedTextCollapsed = (fossilizedTextOffsetTop: number) => {
        if (scrollRegion.current) {
            const distanceOffScrollRegionTop =
                /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
                 * Baseline, please do not copy and paste this justification
                 *	> 'getBoundingClientRect' is restricted from being used. This function can cause performance problems by causing re-layouts. Please use a resize observer instead. */
                scrollRegion.current.getBoundingClientRect().top - fossilizedTextOffsetTop;
            if (distanceOffScrollRegionTop) {
                // This handles scroll when NewestOnBottomFossilizedText top is OFF scrollRegion.
                /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
                 * Baseline, please provide a proper justification if touching this code
                 *	> 'scrollTop' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
                scrollRegion.current.scrollTop -= distanceOffScrollRegionTop;
            }
        }
    };
    const handleQuotedBodyExpanded = React.useCallback(
        (quotedBodyOffsetTop: number) => {
            if (scrollRegion.current) {
                const distanceOffScreen =
                    /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
                     * Baseline, please do not copy and paste this justification
                     *	> 'getBoundingClientRect' is restricted from being used. This function can cause performance problems by causing re-layouts. Please use a resize observer instead. */
                    quotedBodyOffsetTop - scrollRegion.current.getBoundingClientRect().bottom;
                if (distanceOffScreen > -FOSSILIZED_TEXT_PEEK_HEIGHT) {
                    // This check will capture 2 scenarios:
                    // 1. If distanceOffScreen is positive, the top of the quotedBody is OFF screen. We therefore want to scroll by this distance and the peek height
                    // so the top of the quotedBody lands at the same height each time.
                    //
                    // 2. If the distanceOffScreen is negative, the top of the quotedBody is ON screen, but if it is greater than -FOSSILIZED_TEXT_PEEK_HEIGHT,
                    // not enough of it is shown. We therefore want to scroll by FOSSILIZED_TEXT_PEEK_HEIGHT - Math.abs(distanceOffScreen). But since we know
                    // distance off screen is negative in this case, the equation comes out the same.
                    animateScrollTop(
                        scrollRegion.current,
                        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
                         * Baseline, please provide a proper justification if touching this code
                         *	> 'scrollTop' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
                        scrollRegion.current.scrollTop +
                            distanceOffScreen +
                            FOSSILIZED_TEXT_PEEK_HEIGHT,
                        SCROLL_ANIMATION_DURATION
                    );
                }
            }
        },
        [scrollRegion.current]
    );

    /* Checks to see if we have finished rendering a new conversation */
    const tryFinishRenderNewConversation = () => {
        if (isRenderingNewConversation.current && isCurrentConversationLoaded()) {
            // If we've just successfully rendered a new conversation, handle first render logic now
            isRenderingNewConversation.current = false;
            // On first render of reading pane content, set focus on reading pane if needed
            if (!props.shouldNotFocusOnInitialRender && (isSingleLine || shouldKeepFocus.current)) {
                // We care reflow for initial render. Set a timer to let reflow unblock UI rendering.
                setFocusTimer(() => {
                    setFocusOnReadingPane();
                }, 0);
                shouldKeepFocus.current = false;
            }
            // On first render of reading pane content, handle auto mark as read now
            lazySetAutoMarkAsReadTimer.importAndExecute(conversationIdString);
        }
    };
    const isCurrentConversationLoaded = (): boolean => {
        return (
            conversationReadingPaneViewState?.conversationId?.Id == props.conversationId?.Id && // Make sure store is in sync with component
            !conversationReadingPaneViewState?.loadingState?.isLoading &&
            !conversationReadingPaneViewState?.loadingState?.hasLoadFailed
        );
    };

    const renderInlineCompose = (viewState: ComposeViewState): JSX.Element => {
        const composeClassNames = classNames(composeContainer, {
            [isNewestOnTop]: !isSettingNewestOnBottom,
            [inlineComposeStyles_isFocused]: true,
        });
        const senderPersona = () => {
            return (
                <SenderImage
                    viewerMailboxInfo={viewState.mailboxInfo}
                    sender={null}
                    displaySelf={true}
                    style={senderImage}
                />
            );
        };

        return (
            <div className={composeClassNames} key={`inlineComposeDiv_${conversationIdString}`}>
                <ZoomContext.Provider value={currentZoomScale}>
                    <Compose
                        viewState={viewState}
                        initialActions={composeInitialActions.current}
                        className={classNames(inlineComposeStyles_inlineCompose)}
                        senderPersona={senderPersona()}
                        onUnmountFocusCallback={handleFocusOnInlineComposeUnmount}
                        useCommandBarSetting={shouldRemoveComposeSxS ? undefined : isSxS}
                    />
                </ZoomContext.Provider>
            </div>
        );
    };

    const renderRsvpWithNote = (): JSX.Element | null => {
        if (!calendarCardViewState?.rsvpToOrganizerWithNote) {
            return null;
        }
        const composeClassNames = classNames(composeContainer, {
            [isNewestOnTop]: !isSettingNewestOnBottom,
            [inlineComposeStyles_isFocused]: true,
        });

        return (
            <div
                ref={rsvpWithNoteRef}
                className={composeClassNames}
                key={`rsvpWithNoteDiv_${conversationIdString}`}
            >
                <ZoomContext.Provider value={currentZoomScale}>
                    <RSVPWithNote viewState={calendarCardViewState} />
                </ZoomContext.Provider>
            </div>
        );
    };

    const rsvpWithNoteSection = React.useMemo(() => {
        return calendarCardViewState?.rsvpToOrganizerWithNote ? renderRsvpWithNote() : null;
    }, [calendarCardViewState?.rsvpToOrganizerWithNote]);

    React.useEffect(() => {
        if (rsvpWithNoteRef.current) {
            scrollToItemPart(
                rsvpWithNoteRef.current,
                false /* hasFossilizedText */,
                true /* shouldAnimate */
            );
        }
    }, [calendarCardViewState?.rsvpToOrganizerWithNote]);

    const renderReadingPaneContent = (
        extendedCardInScrollRegionSortOrder: JSX.Element | null
    ): JSX.Element => {
        let composeRendered = false;
        let seeMoreMessagesRendered = false;
        const calendarItemId =
            shouldShowExtendedCalendarCard(conversationReadingPaneViewState, mailboxInfo) ||
            shouldShowExtendedCalendarCardV2(conversationReadingPaneViewState, mailboxInfo)
                ? (
                      getExtendedCardViewState(
                          conversationReadingPaneViewState,
                          ExtendedCardType.CalendarCard
                      ) as CalendarCardViewState
                  ).eventId
                : null;
        // When there is inline compose, no need to scroll to item part and compose will handle
        // the initial scrolling
        const itemPartScrollingHandler = composeViewState ? () => {} : handleItemPartScrolling;
        const conversationNodeIds = getConversationNodeIds(showRSVPTimeline);

        const conversationContent =
            conversationNodeIds.length > 0
                ? conversationNodeIds.map(nodeId => {
                      let seeMoreMessages = null;
                      // Render the see more messages when
                      // This node is bundled with the see more messages and show see more messages
                      if (
                          nodeId ==
                              conversationReadingPaneViewState.nodeIdBundledWithSeeMoreMessages &&
                          shouldShowSeeMoreMessages
                      ) {
                          seeMoreMessages = renderSeeMoreMessages(
                              conversationReadingPaneViewState,
                              mailboxInfo,
                              conversationItemParts
                          );
                          seeMoreMessagesRendered = true;
                      }
                      const itemPart = conversationReadingPaneViewState.itemPartsMap.get(nodeId);

                      if (!itemPart) {
                          // If the item part is not rendered, but there is see more button bundled to this node,
                          // Render the see more button.
                          return seeMoreMessages;
                      }
                      // If this item part is in collapsed items roll up, no need to render it now.
                      if (
                          isItemPartInCollapsedItemsRollUp(
                              conversationReadingPaneViewState,
                              itemPart
                          )
                      ) {
                          return null;
                      }
                      // If this item part is in the roll up(oof roll up or calendar roll up),
                      // no need to render it as normal item part.
                      if (itemPart.isInRollUp) {
                          return null;
                      }
                      let inlineCompose = null;
                      if (composeViewState) {
                          const conversationNode = mailStore.conversationNodes.get(nodeId);
                          if (
                              conversationNode &&
                              composeViewState.referenceItemId?.Id &&
                              conversationNode.itemIds.indexOf(
                                  composeViewState.referenceItemId.Id
                              ) >= 0
                          ) {
                              inlineCompose = renderInlineCompose(composeViewState);
                              composeRendered = true;
                          }
                      }
                      const className = classNames(
                          conversationStyles_itemPart,
                          isBrowserSafari() && conversationStyles_noOverlay,
                          isBrowserFirefox() && itemPartFirefox,
                          {
                              [hasCompose]: !!inlineCompose,
                          }
                      );
                      let isFocused;
                      // We shouldn't show focused state if there is an inline compose open
                      if (composeViewState) {
                          isFocused = false;
                      } else {
                          isFocused =
                              itemPart.conversationNodeId == focusedItemPart?.conversationNodeId;
                      }
                      const isLatestNonDraft =
                          getLastestNonDraftItemId(conversationIdString) == itemPart.itemId;
                      const focusArea = isFocused ? getFocusedItemArea() : null;
                      const shouldNotGrabFocus =
                          isFocused &&
                          conversationReadingPaneViewState.focusedItemPart?.shouldNotGrabFocus;
                      return (
                          <div key={`itemPartDiv_${itemPart.itemId}`} className={className}>
                              {renderComponentsBySortOrder([
                                  <ItemPart
                                      key={`itemPart_${itemPart.itemId}`}
                                      instrumentationContext={
                                          conversationReadingPaneViewState.instrumentationContext
                                      }
                                      viewState={itemPart}
                                      handleItemPartScrolling={itemPartScrollingHandler}
                                      quotedBodyExpandedCallback={handleQuotedBodyExpanded}
                                      fossilizedTextCollapsedCallback={handleFosslizedTextCollapsed}
                                      isLatestNonDraft={isLatestNonDraft}
                                      focusedArea={focusArea}
                                      calendarItemId={calendarItemId}
                                      shouldNotGrabFocus={shouldNotGrabFocus}
                                      isSingleLineListView={isSingleLine}
                                      maximizeScrollRegion={props.maximizeScrollRegion}
                                      itemIdToScrollTo={
                                          conversationReadingPaneViewState?.itemIdToScrollTo ?? ''
                                      }
                                  />,
                                  isLatestNonDraft ? extendedCardInScrollRegionSortOrder : null,
                                  inlineCompose,
                                  seeMoreMessages,
                              ])}
                          </div>
                      );
                  })
                : null;

        const inlineCompose =
            composeViewState && !composeRendered ? renderInlineCompose(composeViewState) : null;

        // Render the see more messages if see more messages should be rendered, but not rendered yet,
        const seeMoreMessages =
            !seeMoreMessagesRendered && shouldShowSeeMoreMessages && conversationItemParts
                ? renderSeeMoreMessages(
                      conversationReadingPaneViewState,
                      mailboxInfo,
                      conversationItemParts
                  )
                : null;
        return renderConversationReadingPane(
            conversationContent,
            inlineCompose,
            rsvpWithNoteSection,
            seeMoreMessages
        );
    };

    const firstItem = getFirstItemInConversation(conversationIdString);
    const firstItemId = firstItem?.ItemId?.Id;
    const isSummarizeEnabled = isCopilotFeatureEnabled(
        'Summarize',
        mailboxInfo,
        getApplicationSettings('Copilot', mailboxInfo).enableMultiLanguage /** skipLanguageCheck */
    );

    const [summarizeProps, setSummarizeProps] = React.useState<
        ThreadSummarizationCardViewState | undefined
    >(undefined);
    React.useEffect(() => {
        if (isSummarizeEnabled) {
            const item: Message | undefined = focusedItemPart?.itemId
                ? (mailStore.items.get(focusedItemPart.itemId) as Message)
                : undefined;
            if (!!item) {
                const allItemsInConversation = getAllItemsShownInConversation(
                    conversationReadingPaneViewState,
                    false
                );
                const isIRMMail = allItemsInConversation?.some(
                    itemInConversation => !!itemInConversation?.RightsManagementLicenseData
                );
                lazyCreateThreadSummarizationViewState
                    .importAndExecute(
                        conversationReadingPaneViewState,
                        item,
                        setItemIdToScrollTo,
                        false /** isPopout */,
                        !!isIRMMail
                    )
                    .then((result: ThreadSummarizationCardViewState | undefined) => {
                        setSummarizeProps(result);
                    });
            }
        }
    }, [
        isSummarizeEnabled,
        setSummarizeProps,
        conversationReadingPaneViewState,
        conversationReadingPaneViewState?.conversationId,
        conversationReadingPaneViewState?.itemPartsMap?.size,
        focusedItemPart?.itemId,
    ]);

    function isExtractAllowedForAllItems(): boolean {
        const allItemsInConversation = getAllItemsShownInConversation(
            conversationReadingPaneViewState,
            false
        );

        if (!allItemsInConversation) {
            return true;
        }

        for (const itemInConversation of allItemsInConversation) {
            if (
                itemInConversation &&
                itemInConversation.RightsManagementLicenseData &&
                !itemInConversation.RightsManagementLicenseData.ExtractAllowed
            ) {
                return false;
            }
        }
        return true;
    }

    const targetWindow = React.useContext(ProjectionContext);
    React.useEffect(() => {
        if (isFeatureEnabled('mon-block-screencapture')) {
            const tabId = getTabIdFromTargetWindow(targetWindow);
            const isCopyAllowed = isExtractAllowedForAllItems();
            lazyLoadUpdateContentProtection.importAndExecute(tabId, isCopyAllowed);
        }
    }, [focusedItemPart?.itemId]);

    const summarizationContent = React.useCallback(() => {
        if (isSummarizeEnabled) {
            if (firstItem?.ItemId) {
                if (!!summarizeProps) {
                    const isJunkItem =
                        !!firstItem?.ParentFolderId?.Id &&
                        doesFolderIdEqualName(firstItem.ParentFolderId.Id, 'junkemail');

                    if (!isJunkItem) {
                        const shouldShowSummarizationButton =
                            isFeatureEnabled('rp-subjectHeaderV3', mailboxInfo) &&
                            (isFeatureEnabled('rp-subjectHeaderSummarizeButton', mailboxInfo) ||
                                isFeatureEnabled(
                                    'rp-subjectHeaderSummarizeButtonTooltip',
                                    mailboxInfo
                                ) ||
                                isFeatureEnabled('rp-subjectHeaderSummarizeButtonLeftAligned'));

                        if (shouldShowSummarizationButton) {
                            conversationIdString &&
                                lazySetSummaryButtonDisplayStatus.importAndExecute(true);
                            return (
                                <ThreadSummarizationCardForHeaderEntryPoint
                                    viewState={summarizeProps}
                                />
                            );
                        } else if (isFeatureEnabled('rp-thread-summarization-refactor')) {
                            return (
                                <>
                                    <ThreadSummarizationBar id={summarizeProps.conversationId} />
                                    <ThreadSummarizationCardForHeaderEntryPoint
                                        viewState={summarizeProps}
                                    />
                                </>
                            );
                        } else {
                            return <ThreadSummarizationCard viewState={summarizeProps} />;
                        }
                    }
                }
            } else {
                logCoreUsage('ThreadSummarization_NoCard_ItemIdUndefined');
            }
        }
        return null;
    }, [
        summarizeProps,
        conversationReadingPaneViewState,
        firstItem?.ItemId,
        firstItem?.ParentFolderId?.Id,
        conversationIdString,
        mailboxInfo,
        isSummarizeEnabled,
    ]);

    const setReadingPaneContainerRef = React.useCallback((ref: HTMLDivElement) => {
        if (ref) {
            readingPaneContainer.current = ref;
            readingPaneContainer.current.addEventListener('wheel', onWheelZoom);
            readingPaneContainer.current.addEventListener('keydown', onCtrlZoom);
        } else {
            if (readingPaneContainer.current) {
                readingPaneContainer.current.removeEventListener('wheel', onWheelZoom);
                readingPaneContainer.current.removeEventListener('keydown', onCtrlZoom);
            }
            readingPaneContainer.current = ref;
        }
    }, []);

    const setScrollRegionRef = React.useCallback((ref: HTMLDivElement) => {
        if (ref) {
            scrollRegion.current = ref;
            scrollRegion.current.addEventListener('scroll', onScrollRegionScroll);
            if (itemPartDivElementToScrollTo.current) {
                scrollToItemPart(
                    itemPartDivElementToScrollTo.current,
                    !!itemPartToScrollToHasFossilizedText.current,
                    false /* shouldAnimate */
                );
                itemPartDivElementToScrollTo.current = undefined;
                itemPartToScrollToHasFossilizedText.current = false;
            }
        } else {
            if (scrollRegion.current) {
                scrollRegion.current.removeEventListener('scroll', onScrollRegionScroll);
                scrollRegion.current = undefined;
            }
        }
    }, []);

    const setAttendeePanelRef = React.useCallback((ref: HTMLDivElement) => {
        if (ref) {
            attendeePanelRef.current = ref;
        } else {
            if (attendeePanelRef.current) {
                attendeePanelRef.current = undefined;
            }
        }
    }, []);

    const readingPaneVisibilityChange = () => {
        if (window.document.visibilityState == 'hidden') {
            endViewMessageSession();
        } else if (window.document.visibilityState == 'visible') {
            beginViewMessageSession();
        }
    };

    const readingPaneDisplayStart = () => {
        if (window.document.visibilityState == 'visible') {
            beginViewMessageSession();
        }
        if (instrumentationContext) {
            userMailInteractionAction('ReadingPaneDisplayStart', [instrumentationContext]);
        }
    };
    const readingPaneDisplayEnd = () => {
        // Log embiggen zoom setting
        logEmbiggenZoomSettingDatapoint();
        if (instrumentationContext) {
            userMailInteractionAction('ReadingPaneDisplayEnd', [instrumentationContext]);
        }
        endViewMessageSession();
        initiallySelectedItemId.current = undefined;
        initiallySelectedInternetMessageId.current = undefined;
    };

    const beginViewMessageSession = () => {
        readingPaneDisplayStartDate.current = new Date();
        viewSessionGuid.current = getGuid();
    };

    const endViewMessageSession = () => {
        if (!viewSessionGuid.current || !readingPaneDisplayStartDate.current) {
            return;
        }
        const message =
            initiallySelectedItemId.current &&
            (mailStore.items.get(initiallySelectedItemId.current) as Message);
        if (message && shouldSendViewMessageSignal(message)) {
            lazyLogSigsDatapoint.importAndExecute('ViewMessage', {
                start: readingPaneDisplayStartDate.current,
                itemId: initiallySelectedInternetMessageId.current,
                customProperties: {
                    MessageIdType: 'MessageODataId',
                    MessageId: initiallySelectedItemId.current,
                    ConversationId: conversationIdString,
                },
                instanceId: viewSessionGuid.current,
            });
        }
        viewSessionGuid.current = undefined;
    };
    const getExtendedCards = (): {
        cardInScrollRegionTop: JSX.Element | undefined;
        cardInScrollRegionSortOrder: JSX.Element | undefined;
        cardOutsideScrollRegion: JSX.Element | undefined;
    } => {
        let cardInScrollRegionTop = undefined;
        let cardOutsideScrollRegion = undefined;
        let cardInScrollRegionSortOrder = undefined;
        const { extendedCardViewState } = conversationReadingPaneViewState;
        // don't load CITI card v1 if the flight for CITI card v2 is enabled
        if (
            extendedCardViewState &&
            (extendedCardViewState.cardType != ExtendedCardType.CalendarCard ||
                shouldShowExtendedCalendarCard(conversationReadingPaneViewState, mailboxInfo))
        ) {
            const extendedCardWrapper = (
                <ExtendedCardWrapper
                    key={`extendedCard_${conversationIdString}`}
                    extendedCardViewState={extendedCardViewState}
                    ref={setScrollRegionRef}
                />
            );

            switch (extendedCardViewState.displayPosition) {
                case 'inScrollRegionTop':
                    cardInScrollRegionTop = extendedCardWrapper;
                    break;
                case 'outsideScrollRegion':
                    cardOutsideScrollRegion = extendedCardWrapper;
                    break;
                case 'inScrollRegionSort':
                    cardInScrollRegionSortOrder = extendedCardWrapper;
                    break;
            }
        }
        return { cardInScrollRegionTop, cardInScrollRegionSortOrder, cardOutsideScrollRegion };
    };

    const subjectHeaderStyles = React.useMemo(
        () => ({
            margin: `8px 19px 8px ${isBottomReadingPane ? 2 : 2}px`,
        }),
        [isBottomReadingPane]
    );

    const renderSubjectHeader = (): JSX.Element => {
        const { conversationId, conversationSubject, conversationCategories } = props;
        const isYammerCard = hasExtendedCard(
            conversationReadingPaneViewState,
            ExtendedCardType.Yammer
        );
        const expandCollapseButton = () => {
            return (
                <ExpandCollapseConversationButton
                    conversationId={conversationId.Id}
                    areAllItemPartsExpanded={isAllItemPartsExpanded(conversationId.Id)}
                />
            );
        };
        const specialCardIconName =
            isYammerCard &&
            (
                getExtendedCardViewState(
                    conversationReadingPaneViewState,
                    ExtendedCardType.Yammer
                ) as YammerCardViewState
            ).yammerScenario.getIcon();

        return (
            <div
                className={classNames(
                    subjectContainer,
                    {
                        [isSpecialCaseCard]: isYammerCard,
                    },
                    neutralPaletteBackground
                )}
                style={subjectHeaderStyles}
            >
                {isSubjectHeaderV3 ? (
                    <LazySubjectHeaderV3
                        categories={conversationCategories}
                        className={classNames(getDensityModeCssClass(full, medium, compact), {
                            [isSpecialCaseCard]: isYammerCard,
                        })}
                        subject={conversationSubject}
                        conversationViewState={conversationReadingPaneViewState}
                        firstItemId={firstItemId}
                        isSxS={isSxS}
                        conversationId={conversationId.Id}
                        isSingleLine={isSingleLine}
                        item={conversationId}
                        attendeeTrackingClicked={handleSetShowAttendeePanel}
                        attendeeTrackingClosed={!showAttendeePanel}
                    />
                ) : (
                    <FluentButtonContextProvider value={isFluentv9ButtonsEnabled}>
                        <SubjectHeaderV2
                            categories={conversationCategories}
                            className={classNames(getDensityModeCssClass(full, medium, compact), {
                                [isSpecialCaseCard]: isYammerCard,
                            })}
                            subject={conversationSubject}
                            viewState={conversationReadingPaneViewState}
                            firstItemId={firstItemId}
                            specialCardIconName={specialCardIconName || undefined}
                            isSxS={isSxS}
                            conversationId={conversationId.Id}
                            expandCollapseButton={
                                conversationReadingPaneViewState?.itemPartsMap?.size > 1
                                    ? expandCollapseButton()
                                    : null
                            }
                            isSingleLine={isSingleLine}
                        />
                    </FluentButtonContextProvider>
                )}
            </div>
        );
    };

    const isFocusInFluid = (target: HTMLElement | null) => {
        if (!isFeatureEnabled('cmp-prague')) {
            return false;
        }
        while (target) {
            if (target.dataset.fluidContainer) {
                return true;
            }
            target = target.parentElement;
        }
        return false;
    };
    /**
     * Hot keys
     */
    // Close mail is tied to the esc key in certain key sets.
    // Only when we know we are exiting from the immersive mode
    // we stop propagation else we let the event to propagate
    const commands = getCommands();
    useKeydownHandlers(
        readingPaneContainer,
        React.useCallback(
            () => [
                {
                    command: commands.closeMail,
                    handler: (evt: KeyboardEvent) => {
                        // Return to listview from immersive mode and stop propagation
                        if (!shouldShowListView()) {
                            evt.stopPropagation();
                            closeImmersiveReadingPane('Keyboard');
                        }
                    },
                    options: {
                        stopPropagation: false,
                    },
                },
                {
                    command: commands.archiveMail,
                    handler: () => {
                        const tableView = getSelectedTableView();
                        // archiving is disabled for groups
                        if (isGroupTableQuery(tableView.tableQuery)) {
                            return;
                        }
                        onArchive('Keyboard');
                    },
                },
            ],
            [readingPaneContainer.current]
        )
    );

    const expandCollapseAll = React.useCallback(() => {
        expandCollapseAllItemParts(
            conversationIdString,
            !isAllItemPartsExpanded(conversationIdString) /*shouldExpand*/,
            true /*isFromShortcut*/
        );
    }, [conversationIdString, readingPaneContainer.current]);
    useKeydownHandler(readingPaneContainer, commands.expandCollapseAll, expandCollapseAll);

    useKeydownHandlers(
        readingPaneContainer,
        React.useCallback(
            () => [
                {
                    command: commands.expandAll,
                    handler: () => {
                        expandCollapseAllItemParts(
                            conversationIdString,
                            true /*shouldExpand*/,
                            true /*isFromShortcut*/
                        );
                    },
                },
                {
                    command: commands.collapseAll,
                    handler: () => {
                        expandCollapseAllItemParts(
                            conversationIdString,
                            false /*shouldExpand*/,
                            true /*isFromShortcut*/
                        );
                    },
                },
                {
                    command: commands.focusNextItemPart,
                    handler: () => {
                        if (!composeViewState) {
                            focusNextPrevItemPartOrArea(conversationIdString, 1);
                            logSelectionUIHotKeyUsage(1);
                        }
                    },
                },
                {
                    command: commands.focusPrevItemPart,
                    handler: () => {
                        if (!composeViewState) {
                            focusNextPrevItemPartOrArea(conversationIdString, -1);
                            logSelectionUIHotKeyUsage(2);
                        }
                    },
                },
                {
                    command: 'enter',
                    handler: (ev: KeyboardEvent) => {
                        const target = ev.target as HTMLElement;
                        const tagName = target.tagName.toLowerCase();
                        // Blacklist all things that we render in an ItemPart
                        // that already have an action bound to 'enter'
                        if (
                            tagName != 'a' &&
                            tagName != 'button' &&
                            target.role != 'button' &&
                            !target.classList.contains('lpc-hoverTarget') &&
                            !isFocusInFluid(target)
                        ) {
                            expandCollapseFocusedItemPart(conversationIdString);
                            logSelectionUIHotKeyUsage(3);
                        }
                    },
                    options: {
                        stopPropagation: false,
                        preventDefault: false,
                    },
                },
            ],
            [props.conversationId, readingPaneContainer.current]
        )
    );

    const containerStyles = React.useMemo(() => {
        const zoomStyles = getZoomStyle(currentZoomScale);
        return {
            ...zoomStyles,
            marginLeft: isBottomReadingPane ? 0 : isBleedThroughSupported ? 5 : 8,
        };
    }, [currentZoomScale, isBottomReadingPane, isBleedThroughSupported]);

    const deleteMail = React.useCallback(() => {
        if (getFocusedItemArea() == FocusedItemArea.Item && focusedItemPart?.isExpanded) {
            const newFocusedNode = tryGetNewFocusedNodeOnDelete(
                conversationIdString,
                focusedItemPart
            );
            const itemId = focusedItemPart.itemId;
            const item = mailStore.items.get(itemId);
            if (item) {
                lazyDeleteItems
                    .importAndExecute(
                        [itemId],
                        getDefaultDisposalType(itemId),
                        instrumentationContext ? [instrumentationContext] : [],
                        'Keyboard',
                        item.MailboxInfo
                    )
                    .then(() => {
                        setFocusedItemPart(newFocusedNode, item?.ConversationId?.Id);
                    });
            }
        }
    }, [
        props.conversationId,
        mailStore,
        lazyDeleteItems,
        instrumentationContext,
        readingPaneContainer.current,
        focusedItemPart,
    ]);
    useKeydownHandler(readingPaneContainer, commands.deleteMail, deleteMail);

    const scrollRegionContentStyles = () => {
        return { height: 'unset' };
    };

    // Info page could be <Spinner />, <EmptyStateReadingPane /> or <UnsupportedItemReadingPane />
    const showInfoPage =
        !conversationReadingPaneViewState ||
        conversationReadingPaneViewState.loadingState.isLoading ||
        conversationReadingPaneViewState.loadingState.hasLoadFailed ||
        conversationReadingPaneViewState.unsupportedItemId;
    const subjectHeader = renderSubjectHeader();
    let content: JSX.Element | null = null;

    const extendedCalendarCardEvent: CalendarEvent | null = React.useMemo(() => {
        return getEventForExtendedCalendarCardV2(conversationReadingPaneViewState, mailboxInfo);
    }, [
        (
            conversationReadingPaneViewState?.extendedCardViewState
                ?.cardViewState as CalendarCardViewState
        )?.event,
    ]);

    const attendeePanel = React.useMemo(() => {
        if (!extendedCalendarCardEvent) {
            return <></>;
        }

        return (
            <div ref={setAttendeePanelRef} tabIndex={-1} className={attendeePanelContainer}>
                <AttendeePanel
                    showAttendeePanel={showAttendeePanel}
                    showRSVPTimeline={showRSVPTimeline}
                    calendarCardEvent={extendedCalendarCardEvent}
                    shouldShowRSVPTimelineButton={true}
                    dismissButtonClicked={handleSetShowAttendeePanel}
                    showHideRSVPTimelineButtonClicked={handleShowHideRSVPTimelineClicked}
                />
            </div>
        );
    }, [extendedCalendarCardEvent, showAttendeePanel, showRSVPTimeline]);

    if (showInfoPage) {
        content = (
            <>
                {subjectHeader}
                {<ConversationReadingPaneInfoPage mailboxInfo={mailboxInfo} />}
            </>
        );
    } else if (!isCurrentConversationLoaded()) {
        // During transition, the conversation id in conversationReadingPaneViewState and this.props are not matched
        // which means that the current conversation is not loaded yet, only show the header during the transition
        content = subjectHeader;
    } else {
        const { cardInScrollRegionTop, cardInScrollRegionSortOrder, cardOutsideScrollRegion } =
            getExtendedCards();
        const conversationReadingPaneContent = renderReadingPaneContent(
            cardInScrollRegionSortOrder ?? null
        );

        // prettier-ignore
        const scrollRegionContent = (<div style={scrollRegionContentStyles()}>
                {conversationReadingPaneContent}
                <div className={scrollRegionBottomBuffer}/>
            </div>);
        if (shouldShowExtendedCalendarCard(conversationReadingPaneViewState, mailboxInfo)) {
            content = (
                <>
                    {isSubjectHeaderV3 && isSingleLine && !isSxS && (
                        <div className={navButtonsContainer}>
                            <LazySLVNavButtonsV3 />
                        </div>
                    )}
                    {!isSubjectHeaderV3 && isSingleLine && !isSxS && (
                        <div className={navButtonsContainer}>
                            <SLVNavButtons />
                        </div>
                    )}
                    {cardOutsideScrollRegion}
                    {getScrollRegion(
                        <CalendarCardBottom
                            viewState={
                                getExtendedCardViewState(
                                    conversationReadingPaneViewState,
                                    ExtendedCardType.CalendarCard
                                ) as CalendarCardViewState
                            }
                            messagePane={scrollRegionContent}
                            unreadMessageCount={getUnreadMessageCount(
                                conversationReadingPaneViewState
                            )}
                            messageScrollRef={setScrollRegionRef}
                        />
                    )}
                </>
            );
        } else {
            const extendedCardCoversContent = isExtendedCardCoveringOriginalContent(
                conversationReadingPaneViewState
            );

            /* When there is no need to maximize scroll region (no browser zoom, for example) and the extended
             * card covers original content (i.e. Yammer Card), the scroll region is not shown at all.
             *
             * If the scroll region needs to be maximized (i.e. not enough space due to zooming), the scroll
             * region must be shown whether the extended card covers original content or not. This scroll
             * region now will contain the subjectHeader (making it scrollable), as well as the
             * "cardOutsideScrollRegion", but it should also contain the original content, which could be hidden
             * if the extended card covers content. If the extended original does not cover the original content,
             * then it must be shown in the scroll region.
             */
            content = (
                <>
                    {!props.maximizeScrollRegion && subjectHeader}
                    {!props.maximizeScrollRegion && summarizationContent()}
                    {!props.maximizeScrollRegion && cardOutsideScrollRegion}
                    {conversationReadingPaneViewState &&
                        (!extendedCardCoversContent || props.maximizeScrollRegion) &&
                        getScrollRegion(
                            <div
                                aria-label={loc(
                                    conversationReadingPaneContentsAriaLabel as ResourceId,
                                    conversationReadingPaneViewState.itemPartsMap.size
                                )}
                                data-app-section={CONVERSATION_APP_SECTION}
                                tabIndex={-1}
                                /* Add tab index to allow container to be scrollable via keyboard */ ref={
                                    setScrollRegionRef
                                }
                                data-is-scrollable={true}
                                className={classNames(
                                    styles_scrollRegion,
                                    isBrowserSafari() && noOverlay,
                                    isBrowserFirefox() && itemPartFirefox,
                                    scrollRegionWillChangeScrollPosition,
                                    'customScrollBar'
                                )}
                            >
                                {props.maximizeScrollRegion && subjectHeader}
                                {props.maximizeScrollRegion && summarizationContent()}
                                {props.maximizeScrollRegion && cardOutsideScrollRegion}
                                {!extendedCardCoversContent && cardInScrollRegionTop}
                                {!extendedCardCoversContent && scrollRegionContent}
                            </div>
                        )}
                </>
            );
        }
    }
    return (
        <div className={calendarCardV2Container} style={containerStyles}>
            <div
                id="ConversationReadingPaneContainer"
                className={flexContainer}
                ref={setReadingPaneContainerRef}
                tabIndex={-1}
            >
                {content}
            </div>
            <span className="screenReaderOnly" aria-live="assertive" aria-atomic="true">
                {loc(
                    showAttendeePanel
                        ? conversationReadingPaneAttendeePanelOpened
                        : conversationReadingPaneAttendeePanelClosed
                )}
            </span>
            {attendeePanel}
        </div>
    );
}, 'ConversationReadingPane');

function renderSeeMoreMessages(
    conversationReadingPaneViewState: ConversationReadingPaneViewState,
    mailboxInfo: MailboxInfo,
    conversationItemParts?: ConversationItemParts
): JSX.Element {
    const conversationId = conversationReadingPaneViewState.conversationId.Id;
    const isFluentv9ButtonsEnabled = isFeatureEnabled('fwk-fluent-v9-button-reading-pane');

    return (
        <FluentButtonContextProvider value={isFluentv9ButtonsEnabled}>
            <SeeMoreMessages
                key={conversationId}
                conversationReadingPaneViewState={conversationReadingPaneViewState}
                canLoadMore={!!conversationItemParts?.canLoadMore}
                isFocused={getFocusedItemArea() == FocusedItemArea.SeeMore}
                isLoadMoreInProgress={!!conversationItemParts?.isLoadMoreInProgress}
                isCalendarCardOpen={shouldShowExtendedCalendarCard(
                    conversationReadingPaneViewState,
                    mailboxInfo
                )}
            />
        </FluentButtonContextProvider>
    );
}

function getScrollRegion(content: JSX.Element): JSX.Element {
    return <div className={scrollRegionContainer}>{content}</div>;
}

function getEventForExtendedCalendarCardV2(
    conversationReadingPaneViewState: ConversationReadingPaneViewState,
    mailboxInfo: MailboxInfo
): CalendarEvent | null {
    return hasExtendedCard(conversationReadingPaneViewState, ExtendedCardType.CalendarCard) &&
        isFeatureEnabled('rp-citiCardV2', mailboxInfo)
        ? (
              getExtendedCardViewState(
                  conversationReadingPaneViewState,
                  ExtendedCardType.CalendarCard
              ) as CalendarCardViewState
          ).event
        : null;
}

function shouldShowExtendedCalendarCard(
    conversationReadingPaneViewState: ConversationReadingPaneViewState,
    mailboxInfo: MailboxInfo
): boolean {
    return (
        hasExtendedCard(conversationReadingPaneViewState, ExtendedCardType.CalendarCard) &&
        !isFeatureEnabled('rp-citiCardV2', mailboxInfo)
    );
}

function shouldShowExtendedCalendarCardV2(
    conversationReadingPaneViewState: ConversationReadingPaneViewState,
    mailboxInfo: MailboxInfo
): boolean {
    return (
        hasExtendedCard(conversationReadingPaneViewState, ExtendedCardType.CalendarCard) &&
        isFeatureEnabled('rp-citiCardV2', mailboxInfo)
    );
}

function getCalendarCardViewState(
    selectedConversationReadingPaneState: ConversationReadingPaneViewState,
    mailboxInfo: MailboxInfo
): CalendarCardViewState | null {
    if (shouldShowExtendedCalendarCardV2(selectedConversationReadingPaneState, mailboxInfo)) {
        return getExtendedCardViewState(
            selectedConversationReadingPaneState,
            ExtendedCardType.CalendarCard
        ) as CalendarCardViewState;
    }

    return null;
}
