import React from 'react';
import { ResizeHandleDirection } from './ResizeHandleDirection';
import { isCurrentCultureRightToLeft } from 'owa-localize';

import { dragging, resizeHandle, horizontal, vertical } from './ResizeHandle.scss';

import classnames from 'owa-classnames';

export interface ResizeHandleProps extends React.HTMLAttributes<HTMLDivElement> {
    direction?: ResizeHandleDirection;
    onResized?: (previousElementDimension: number, nextElementDimension: number) => void;
    onResizing?: (previousElementDimension: number, nextElementDimension: number) => void;
    setNewDimensions?: boolean;
    leftOffset?: number;
}

interface Dimensions {
    min: number;
    max: number;
    current: number;
}

export default React.forwardRef(function ResizeHandle(
    props: ResizeHandleProps,
    ref: React.Ref<HTMLDivElement>
) {
    props = {
        direction: ResizeHandleDirection.vertical,
        setNewDimensions: false,
        ...props,
    };

    const { direction, onResized, onResizing, setNewDimensions, leftOffset, ...divProps } = props;

    const lOffset = leftOffset ? leftOffset : 0;

    const [isDragging, setIsDragging] = React.useState(false);
    const shouldFlipForRtl = () => {
        return props.direction === ResizeHandleDirection.vertical && isCurrentCultureRightToLeft();
    };

    const onMouseDown = React.useCallback(
        (event?: React.MouseEvent<HTMLDivElement>) => {
            event?.preventDefault();
            event?.stopPropagation();
            const handle = event?.currentTarget;

            if (!handle) {
                return;
            }

            const isVertical = direction === ResizeHandleDirection.vertical;
            const firstElement = handle.previousSibling as HTMLElement | null | undefined;
            const secondElement = handle.nextSibling as HTMLElement | null | undefined;
            const containerElement = firstElement?.parentElement || secondElement?.parentElement;

            const getDimensions = isVertical ? getWidthDimensions : getHeightDimensions;
            const firstElementDimensions = getDimensions(firstElement);
            const secondElementDimensions = getDimensions(secondElement);
            // How far up/left the resize handle can go. Will be a negative number
            let minOffset = Math.max(
                firstElementDimensions.min - firstElementDimensions.current,
                secondElementDimensions.current - secondElementDimensions.max
            );
            // How far down/right the resize handle can go.
            let maxOffset = Math.min(
                firstElementDimensions.max - firstElementDimensions.current,
                secondElementDimensions.current - secondElementDimensions.min
            );
            if (shouldFlipForRtl()) {
                const temp = minOffset;
                minOffset = -maxOffset;
                maxOffset = -temp;
            }

            const start = isVertical ? event.clientX : event.clientY;
            let currentOffset = 0;
            setIsDragging(true);
            const getBoundedOffset = (clientCoordinate: number) => {
                return Math.min(maxOffset, Math.max(minOffset, clientCoordinate - start));
            };

            const mouseMoveHandler = (ev?: MouseEvent) => {
                if (!ev) {
                    return;
                }
                currentOffset = getBoundedOffset(isVertical ? ev.clientX : ev.clientY);
                if (isVertical) {
                    handle.style.left = lOffset + currentOffset + 'px';
                } else {
                    handle.style.top = currentOffset + 'px';
                }
                if (onResizing) {
                    const newFirstSize = firstElementDimensions.current + currentOffset;
                    const newSecondSize = secondElementDimensions.current - currentOffset;
                    onResizing(newFirstSize, newSecondSize);
                }
            };
            // Defined below. Needs to be declared here so the handlers can reference it.
            const cleanupDragging: () => void = () => {
                // clear handlers
                containerElement?.removeEventListener('mousemove', mouseMoveHandler);
                containerElement?.removeEventListener('mouseup', mouseUpHandler);
                containerElement?.removeEventListener('mouseleave', mouseLeaveHandler);
                // Clear styles
                handle.style.left = lOffset + 'px';
                handle.style.top = '';
                setIsDragging(false);
            };

            const mouseUpHandler = () => {
                cleanupDragging();
                if (shouldFlipForRtl()) {
                    // We need to flip the values in rtl, or dragging to the right (and visually
                    // shrinking the element first in DOM order) will increase the size of the first element.
                    currentOffset = -currentOffset;
                }
                const newFirstSize = firstElementDimensions.current + currentOffset;
                const newSecondSize = secondElementDimensions.current - currentOffset;
                // If we should, set styles on sibling elements
                if (setNewDimensions) {
                    if (isVertical) {
                        if (firstElement) {
                            firstElement.style.width = newFirstSize + 'px';
                        }
                        if (secondElement) {
                            secondElement.style.width = newSecondSize + 'px';
                        }
                    } else {
                        if (firstElement) {
                            firstElement.style.height = newFirstSize + 'px';
                        }
                        if (secondElement) {
                            secondElement.style.height = newSecondSize + 'px';
                        }
                    }
                }
                // If a callback is provided, call it now
                if (onResized) {
                    onResized(newFirstSize, newSecondSize);
                }
            };

            const mouseLeaveHandler = () => {
                cleanupDragging();
            };
            containerElement?.addEventListener('mousemove', mouseMoveHandler);
            containerElement?.addEventListener('mouseup', mouseUpHandler);
            containerElement?.addEventListener('mouseleave', mouseLeaveHandler);
        },
        [direction, onResized, onResizing, setNewDimensions]
    );
    const handleClasses = classnames(
        resizeHandle,
        props.direction === ResizeHandleDirection.horizontal ? horizontal : vertical,
        isDragging && dragging,
        props.className
    );
    return <div {...divProps} className={handleClasses} onMouseDown={onMouseDown} ref={ref} />;
});

function convertSettingToPixels(setting: number, parentDimension: number): number {
    if (setting >= 1) {
        // The value given was in pixels
        return setting;
    } else {
        // The value was a decimal percentage
        return parentDimension * setting;
    }
}

function getWidthDimensions(element: HTMLElement | undefined | null): Dimensions {
    /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
        This is used in a mouseDown handler, not during normal rendering. Changing to use useResizeObserver would require a significant rewrite and API change */
    const parentDimension = element?.parentElement?.clientWidth || 0;
    return {
        min: convertSettingToPixels(
            +(element?.getAttribute('data-min-width') || 0),
            parentDimension
        ),
        max: convertSettingToPixels(
            +(element?.getAttribute('data-max-width') || 0) || window.outerWidth,
            parentDimension
        ),
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
            This is used in a mouseDown handler, not during normal rendering. Changing to use useResizeObserver would require a significant rewrite and API change */
        current: element?.offsetWidth || 0,
    };
}

function getHeightDimensions(element: HTMLElement | undefined | null): Dimensions {
    /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
        This is used in a mouseDown handler, not during normal rendering. Changing to use useResizeObserver would require a significant rewrite and API change */
    const parentDimension = element?.parentElement?.clientHeight || 0;
    return {
        min: convertSettingToPixels(
            +(element?.getAttribute('data-min-height') || 0),
            parentDimension
        ),
        max: convertSettingToPixels(
            +(element?.getAttribute('data-max-height') || 0) || window.outerHeight,
            parentDimension
        ),
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
            This is used in a mouseDown handler, not during normal rendering. Changing to use useResizeObserver would require a significant rewrite and API change */
        current: element?.offsetHeight || 0,
    };
}
