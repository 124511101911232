import type { RibbonTabDefinitionBase } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { owaComputedFn } from 'owa-computed-fn';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import getMLRComposeTab from '../../../util/getMLRComposeTab';
import { getMLRDrawTabCommonGroupFlyout } from './getMLRDrawTabCommonGroupFlyout';
import { getMLRDrawTabGroupLayouts } from './getMLRDrawTabGroupLayouts';
import { type DrawControlId } from 'owa-ribbon-ids/lib/drawControlId';

const emptyCtrlList: RibbonControlId[] = [12005, 12012, 12006, 12007, 12008, 12009];

const getMLRDrawTab = owaComputedFn(function getMLRDrawTab(
    props: ComposeRibbonControlDefProps
): RibbonTabDefinitionBase {
    return getMLRComposeTab(
        props,
        12,
        emptyCtrlList,
        getMLRDrawTabGroupLayouts,
        getMLRDrawTabCommonGroupFlyout
    );
});
export default getMLRDrawTab;
