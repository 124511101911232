import type { RibbonButtonProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { logCoreUsage } from 'owa-analytics';
import type { MailboxInfo } from 'owa-client-types';
import { constructKeytip, createAppButton, createAppFlyoutAnchor } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import { owaComputedFn } from 'owa-computed-fn';
import { default as CopilotIconRibbon } from 'owa-copilot-icon/lib/components/CopilotIconFilled';
import type {
    CopilotDraftEntityName,
    DraftCopilotFeatureName,
    ScenarioName,
    CopilotElaborateEntryPoint,
} from 'owa-copilot-drafts-utils';
import {
    insertDraftCopilotEntity,
    logDraftCopilotSeenDatapoint,
    shouldDisableCopilot,
} from 'owa-copilot-drafts-utils';
import { isComposeCopilotEnabled } from 'owa-copilot-settings-utils';
import { lazyOperateContent } from 'owa-editor';
import { default as HatGraduationSparkleRegular } from 'owa-fluent-icons-svg/lib/icons/HatGraduationSparkleRegular';
import { default as PenSparkleRegular } from 'owa-fluent-icons-svg/lib/icons/PenSparkleRegular';
import loc from 'owa-localize';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import {
    coachWithCopilotText,
    draftWithCopilotText,
} from '../strings/actionControlStrings.locstring.json';
import {
    coachWithCopilotDescriptionTooltip,
    draftWithCopilotDescriptionTooltip,
} from '../strings/hoverTooltipDesc.locstring.json';
import { type RibbonItemInfo } from 'owa-compose-actioncontrols';

export const getCopilotDrafts = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonItemInfo | null,
        styles: IButtonStyles | undefined,
        keyTipTabId: number | undefined,
        isDisabled: boolean,
        isEncryptionEnabled: boolean
    ): RibbonControlProps => {
        const { mailboxInfo } = retriever(composeId) || {};
        const isCopilotButtonDisabled =
            isDisabled || (!!mailboxInfo && shouldDisableCopilot(isEncryptionEnabled, mailboxInfo));

        const menuSections = getMenuSections(
            editorId,
            composeId,
            retriever,
            styles,
            keyTipTabId,
            isCopilotButtonDisabled,
            mailboxInfo
        );

        if (menuSections.length == 1) {
            logDraftCopilotSeenDatapoint(
                menuSections[0].label === loc(draftWithCopilotText) ? 'Elaborate' : 'Coach',
                editorId,
                {
                    entryPoint: 'Ribbon',
                    copilotHost: 'MailCompose',
                }
            );

            return menuSections[0];
        } else {
            return createAppFlyoutAnchor(
                getComposeRibbonId(7023, editorId),
                'Copilot',
                {
                    sections: [
                        {
                            controls: menuSections,
                        },
                    ],
                },
                keyTipTabId ? constructKeytip([keyTipTabId], 7023, true /* hasMenu */) : undefined,
                CopilotIconRibbon,
                undefined /* iconColor */,
                styles,
                {
                    disabled: isCopilotButtonDisabled,
                    onExecute: () => {
                        const draftCopilotFeatureNames: DraftCopilotFeatureName[] = [
                            'Elaborate',
                            'Coach',
                        ];
                        draftCopilotFeatureNames.forEach(copilotFeatureName => {
                            logDraftCopilotSeenDatapoint(copilotFeatureName, editorId, {
                                entryPoint: 'Ribbon',
                                copilotHost: 'MailCompose',
                            });
                        });
                    },
                }
            );
        }
    }
);

function getMenuSections(
    editorId: string,
    composeId: string,
    retriever: (composeId: string) => RibbonItemInfo | null,
    styles: IButtonStyles | undefined,
    keyTipTabId: number | undefined,
    isDisabled: boolean,
    mailboxInfo: MailboxInfo | undefined
): RibbonButtonProps[] {
    const buttons: RibbonButtonProps[] = [];
    const elaborateEnabled = !!mailboxInfo && isComposeCopilotEnabled(mailboxInfo, 'Elaborate');
    const coachEnabled = mailboxInfo && isComposeCopilotEnabled(mailboxInfo, 'Coach');
    const singleButton = (elaborateEnabled ? 1 : 0) + (coachEnabled ? 1 : 0) == 1;

    if (elaborateEnabled) {
        buttons.push(
            createAppButton(
                getComposeRibbonId(7027, editorId),
                loc(draftWithCopilotText),
                getCopilotEntityInsertOperation(
                    composeId,
                    retriever,
                    'MailRibbonDraftCopilotPreinsert',
                    'DraftCopilot_ElaborateButtonClicked'
                ),
                keyTipTabId ? constructKeytip([keyTipTabId, 7023], 7027) : undefined,
                singleButton ? CopilotIconRibbon : PenSparkleRegular /* icon */,
                undefined /* iconColor */,
                styles,
                {
                    customTooltip: getHoverTooltip(
                        draftWithCopilotText,
                        draftWithCopilotDescriptionTooltip
                    ),
                    disabled: isDisabled,
                }
            )
        );
    }

    if (coachEnabled) {
        buttons.push(
            createAppButton(
                getComposeRibbonId(7028, editorId),
                loc(coachWithCopilotText),
                getCopilotEntityInsertOperation(
                    composeId,
                    retriever,
                    'CopilotCoach',
                    'CopilotCoach_Tried'
                ),
                keyTipTabId ? constructKeytip([keyTipTabId, 7023], 7028) : undefined,
                singleButton ? CopilotIconRibbon : HatGraduationSparkleRegular /* icon */,
                undefined /* iconColor */,
                styles,
                {
                    customTooltip: getHoverTooltip(
                        coachWithCopilotText,
                        coachWithCopilotDescriptionTooltip
                    ),
                    disabled: isDisabled,
                }
            )
        );
    }

    return buttons;
}

function getCopilotEntityInsertOperation(
    composeId: string,
    retriever: (composeId: string) => RibbonItemInfo | null,
    entityName: CopilotDraftEntityName,
    datapointName: 'DraftCopilot_ElaborateButtonClicked' | 'CopilotCoach_Tried',
    entryPoint?: CopilotElaborateEntryPoint
) {
    return () => {
        const editingInfo = retriever(composeId);
        if (editingInfo) {
            lazyOperateContent.import().then(operateContent => {
                operateContent(editingInfo.editorViewState, editor => {
                    insertDraftCopilotEntity(
                        editor,
                        entityName,
                        editingInfo.editorViewState.editorId,
                        undefined,
                        entryPoint
                    );
                    return null;
                });
            });

            const messageId = editingInfo?.itemId;
            const conversationId = editingInfo?.conversationId;
            const copilotHost: ScenarioName = 'MailCompose';
            const dpData = {
                entryPoint: 'Ribbon',
                messageId,
                conversationId,
                messageSynced: !!messageId,
                copilotHost,
            };

            if (datapointName === 'DraftCopilot_ElaborateButtonClicked') {
                logCoreUsage('DraftCopilot_ElaborateButtonClicked', dpData);
            } else if (datapointName === 'CopilotCoach_Tried') {
                logCoreUsage('CopilotCoach_Tried', dpData);
            }
        }
    };
}
