import type { TableView } from 'owa-mail-list-store';
import { action } from 'satcheljs';

export const onSingleMailItemSelected = action('ON_SINGLE_MAIL_ITEM_SELECT');

export const mailListScroll = action(
    'MAIL_LIST_SCROLL',
    (tableView: TableView, scrollTop: number) => ({
        tableView,
        scrollTop,
    })
);
