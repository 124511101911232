import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppToggleButton } from 'owa-command-ribbon';
import type RibbonFormatState from 'owa-editor-ribbonplugin-store/lib/store/schema/RibbonFormatState';
import { toggleBulletCommand } from 'owa-editor-command';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import Bullets from '../fonts/BulletsIcon';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { bulletsText } from './formatControlStrings.locstring.json';
import type { IButtonStyles } from '@fluentui/react';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import { getHoverTooltipHotkey } from 'owa-compose-ribbon-utils/lib/utils/hoverTooltipHotkeyMap';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';

export const getBulletsControl = owaComputedFn(
    (
        editorId: string,
        formatViewState: RibbonFormatState,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean,
        source: FormattingSource
    ): RibbonControlProps => {
        return createAppToggleButton(
            getComposeRibbonId(6005, editorId),
            loc(bulletsText),
            getComputedCallback(6005, toggleBulletCommand, editorId, source),
            !!formatViewState.isBullet,
            tabId ? constructKeytip([tabId], 6005) : undefined,
            Bullets,
            undefined /* iconColor */,
            styles,
            {
                disabled: isDisabled,
                customTooltip: getHoverTooltip(
                    bulletsText,
                    undefined /* description */,
                    getHoverTooltipHotkey(6005)
                ),
            }
        );
    }
);
