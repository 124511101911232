import { setMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import {
    lazyGetBorderSections,
    lazyGetPictureSizeSections,
    lazyGetShadowSections,
} from 'owa-compose-formatcontrols-sections/lib/sections';
import type { PictureFormatState } from 'owa-editor-ribbonplugin-store';
import type { RibbonViewStateInfo } from 'owa-compose-formatcontrols-sections/lib/schema/RibbonViewStateInfo';

export function onBorderMenuClicked(
    editorId: string,
    composeId: string,
    retriever: (composeId: string) => RibbonViewStateInfo | null,
    formatState: PictureFormatState
) {
    lazyGetBorderSections.import().then(getBorderSections => {
        setMenuDefinition(editorId, 'borderMenuDefinitionStore', () => {
            return {
                sections: getBorderSections(editorId, composeId, retriever, formatState),
            };
        });
    });
}

export function onPictureSizeMenuClicked(editorId: string, formatState: PictureFormatState) {
    lazyGetPictureSizeSections.import().then(getPictureSizeSections => {
        setMenuDefinition(editorId, 'pictureSizeMenuDefinitionStore', () => {
            return {
                sections: getPictureSizeSections(editorId, formatState),
            };
        });
    });
}

export function onShadowMenuClicked(editorId: string, formatState: PictureFormatState) {
    lazyGetShadowSections.import().then(getShadowSections => {
        setMenuDefinition(editorId, 'shadowMenuDefinitionStore', () => {
            return {
                sections: getShadowSections(editorId, formatState),
            };
        });
    });
}
