import { LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "TableControlsIcons" */ './lazyIndex')
);

export const lazyMultiSectionFullGallerySections = new LazyImport(
    lazyModule,
    m => m.multiSectionFullGallerySections
);

export const lazyFirstLineOfGridTables = new LazyImport(lazyModule, m => m.firstLineOfGridTables);

export { PredefinedStylesType } from './enum/predefinedStylesTypeEnum';
export { TableColors } from './enum/tableColors';
export { PredefinedTableStyles } from './enum/predefinedTableStylesEnum';
export { PREDEFINED_STYLES } from './constants/predefinedStyles';
