import { LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "GroupsDashNavActions"*/ './lazyIndex')
);

export const lazySelectGroupNodeInDashNav = new LazyAction(
    lazyModule,
    m => m.selectGroupNodeInDashNav
);

export const lazySelectNodeInDashNavOnLoad = new LazyAction(
    lazyModule,
    m => m.selectNodeInDashNavOnLoad
);

export const lazySwitchToGroupsModule = new LazyAction(lazyModule, m => m.onSwitchToGroupsModule);

export const lazySelectDeletedGroupNodeInDashNav = new LazyAction(
    lazyModule,
    m => m.selectDeletedGroupNodeInDashNav
);

export const lazyRestoreGroupInDashNav = new LazyAction(lazyModule, m => m.restoreGroupInDashNav);
