import type { SearchTableQuery } from 'owa-mail-list-search';
import type { PerformanceDatapoint } from 'owa-analytics';
import type { MailListRowDataType, TableQuery } from 'owa-mail-list-store';
import type { ActionSource } from 'owa-mail-store';
import type { SearchScope } from 'owa-search-service';
import { action } from 'satcheljs';
import type { SubstrateAnswerResponse, SubstrateAnswerRequest } from '@1js/search-hostapp-owa';
import type { MailboxInfo } from 'owa-client-types';

export interface LoadTableViewFromSearchTableQueryActionMessage {
    searchTableQuery: TableQuery;
    searchEndToEndPerformanceDatapoint: PerformanceDatapoint;
    actionSource: ActionSource;
    onAnswerResponseReceived?: (value: SubstrateAnswerResponse) => void;
    substrateAnswerRequest?: SubstrateAnswerRequest;
    substrateAnswersClientFlights?: string;
}

/**
 * Dispatched to clear current search scope (i.e. change it to broadest scope).
 * Action message provides option to kick off search after scope is changed.
 *
 * Currently, this action is only used in MailListEmptyViewState component.
 */
export const clearSearchScope = action(
    'CLEAR_SEARCH_SCOPE',
    (actionSource: string, shouldStartSearch: boolean) => ({
        actionSource,
        shouldStartSearch,
    })
);

export const loadMessageSuggestionIntoTable = action(
    'LOAD_MESSAGE_SUGGESTION_INTO_TABLE',
    (
        actionSource: string,
        row: MailListRowDataType,
        searchTableQuery: SearchTableQuery,
        referenceId: string,
        traceId: string,
        itemPartId?: string
    ) => ({
        actionSource,
        row,
        searchTableQuery,
        referenceId,
        traceId,
        itemPartId,
    })
);

export const loadTableViewFromSearchTableQuery = action(
    'LOAD_TABLE_VIEW_FROM_SEARCH_TABLE_QUERY',
    (
        searchTableQuery: TableQuery,
        searchEndToEndPerformanceDatapoint: PerformanceDatapoint,
        actionSource: ActionSource,
        onAnswerResponseReceived?: (value: SubstrateAnswerResponse) => void,
        substrateAnswerRequest?: SubstrateAnswerRequest,
        substrateAnswersClientFlights?: string
    ) => ({
        searchTableQuery,
        searchEndToEndPerformanceDatapoint,
        actionSource,
        onAnswerResponseReceived,
        substrateAnswerRequest,
        substrateAnswersClientFlights,
    })
);

export const setStaticSearchScope = action(
    'SET_STATIC_SEARCH_SCOPE',
    (searchScope: SearchScope) => ({
        searchScope,
    })
);

export const onFolderScopeUpdate = action(
    'onFolderScopeUpdate',
    (selectedSearchScope: SearchScope) => ({
        selectedSearchScope,
    })
);

export const startSearchWithCategory = action(
    'START_SEARCH_WITH_CATEGORY',
    (actionSource: string, categoryName: string) => ({
        actionSource,
        categoryName,
    })
);

export const findEmailFromSender = action(
    'FIND_EMAIL_FROM_SENDER_CLICKED',
    (
        senderName: string,
        senderEmail: string,
        currentMailbox?: MailboxInfo,
        actionSource?: ActionSource
    ) => ({
        senderName,
        senderEmail,
        currentMailbox,
        actionSource,
    })
);

export const findEmailFromConversation = action(
    'FIND_EMAIL_FROM_CONVERSATION_CLICKED',
    (subjectText: string, isItemView: boolean, currentMailbox: MailboxInfo) => ({
        subjectText,
        isItemView,
        currentMailbox,
    })
);

/**
 * Action to when Answer rendered is rendered
 */
export const onAnswerRendered = action('ON_ANSWER_RENDERED', (placeholderId?: string) => ({
    placeholderId,
}));
