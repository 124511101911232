import { getIndexerValueForMailboxInfo, type MailboxInfo } from 'owa-client-types';
import store from '../store/store';
import type { AddinCommandSurfaceItem, ExtensibilityModeEnum } from 'owa-addins-types';

export default function getAddinOptionSurfaceItems(
    mode: ExtensibilityModeEnum,
    mailboxInfo: MailboxInfo
): AddinCommandSurfaceItem[] | undefined {
    const idx = getIndexerValueForMailboxInfo(mailboxInfo);
    return store.addinOptionSurfaceItems.get(idx)?.[mode];
}
