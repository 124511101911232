import { mutatorAction } from 'satcheljs';
import type GroupSiteStatus from 'owa-groups-sharepoint-commands/lib/schema/GroupSiteStatus';
import type { MailboxInfo } from 'owa-client-types';
import {
    getGroupSiteStatus,
    getGroupSiteStatusMap,
} from '../selectors/groupSiteStatusStoreSelectors';

export const setGroupSiteStatus = mutatorAction(
    'setGroupSiteStatus',
    function setGroupSiteStatus(
        mailboxInfo: MailboxInfo,
        groupSmtpAddress: string,
        groupSiteStatusParam: GroupSiteStatus
    ) {
        groupSmtpAddress = groupSmtpAddress.toLocaleLowerCase();
        const groupSiteStatus = getGroupSiteStatus(mailboxInfo, groupSmtpAddress);

        if (groupSiteStatus && groupSiteStatus == groupSiteStatusParam) {
            return;
        }

        getGroupSiteStatusMap(mailboxInfo).set(groupSmtpAddress, groupSiteStatusParam);
    }
);

export default setGroupSiteStatus;
