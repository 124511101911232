import React from 'react';
import { registerIcons } from '@fluentui/style-utilities';
import { bullets, fontIcon } from './style.scss';

import classNames from 'owa-classnames';

export default 'BulletsIcon';

registerIcons({
    icons: {
        BulletsIcon: <span className={classNames(bullets, fontIcon)}></span>,
    },
});
