import { debugErrorThatWillShowErrorPopupOnly } from 'owa-trace';

export function animateScrollTop(
    scrollElement: HTMLDivElement,
    newScrollTop: number,
    scrollDuration: number
) {
    if (scrollDuration < 0) {
        debugErrorThatWillShowErrorPopupOnly(
            'animateScrollTop should not be called with a negative scrollDuration'
        );
        return;
    }

    if (scrollDuration == 0) {
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * Baseline, please provide a proper justification if touching this code
         *	> 'scrollTop' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
        scrollElement.scrollTop = newScrollTop;
        return;
    }

    /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
     * Baseline, please provide a proper justification if touching this code
     *	> 'scrollTop' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
    const initialScrollTop = scrollElement.scrollTop;
    const startTime = Date.now();

    function onAnimationFrame() {
        const progress = Math.min((Date.now() - startTime) / scrollDuration, 1);
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * Baseline, please provide a proper justification if touching this code
         *	> 'scrollTop' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
        scrollElement.scrollTop = initialScrollTop + (newScrollTop - initialScrollTop) * progress;
        if (progress < 1) {
            window.requestAnimationFrame(function () {
                onAnimationFrame();
            });
        }
    }

    window.requestAnimationFrame(function () {
        onAnimationFrame();
    });
}
