import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "HtmlEditorRibbonStore"*/ './lazyIndex')
);

export type { default as RibbonFormatState } from './store/schema/RibbonFormatState';
export type { default as RibbonViewState } from './store/schema/RibbonViewState';
export type { default as TableFormatState } from './store/schema/TableFormatState';
export type { default as TableBorderFormatState } from './store/schema/TableBorderFormatState';
export type { default as PictureFormatState } from './store/schema/PictureFormatState';

// Delay loaded actions
export const lazyUpdateRibbonWidth = new LazyAction(lazyModule, m => m.updateRibbonWidth);
export const lazySetLastTextColor = new LazyAction(lazyModule, m => m.setLastTextColor);
export const lazySetLastBackColor = new LazyAction(lazyModule, m => m.setLastBackColor);
export const lazySetLastCellShadeColor = new LazyAction(lazyModule, m => m.setLastCellShadeColor);
export const lazyToggleFormatPainter = new LazyAction(lazyModule, m => m.toggleFormatPainter);
export const lazyUpdateRibbonState = new LazyAction(lazyModule, m => m.updateRibbonState);
export const lazyUpdateSecondClassState = new LazyAction(lazyModule, m => m.updateSecondClassState);
export const lazySetButtonWidths = new LazyAction(lazyModule, m => m.setButtonWidths);
export const lazyUpdateFontSize = new LazyAction(lazyModule, m => m.updateFontSize);
export const lazyUpdateFontName = new LazyAction(lazyModule, m => m.updateFontName);
export const lazyToggleDrawTool = new LazyAction(lazyModule, m => m.toggleDrawTool);
export const lazyToggleDrawTouch = new LazyAction(lazyModule, m => m.toggleDrawTouch);
export const lazyToggleDrawSelect = new LazyAction(lazyModule, m => m.toggleDrawSelect);
export const lazyDrawCanvas = new LazyAction(lazyModule, m => m.drawCanvas);
export const lazyToggleDrawToolsActive = new LazyAction(lazyModule, m => m.toggleDrawToolsActive);
export const lazySetInsertedCanvasCount = new LazyAction(lazyModule, m => m.setInsertedCanvasCount);
export const lazyToggleDrawTextPen = new LazyAction(lazyModule, m => m.toggleDrawTextPen);
export const lazySetDrawToolStateAsGC2 = new LazyAction(lazyModule, m => m.setDrawToolStateAsGC2);
export const lazyUpdatePictureFormatState = new LazyAction(
    lazyModule,
    m => m.updatePictureFormatState
);
export const lazySetTableBorderWeight = new LazyAction(lazyModule, m => m.setTableBorderWeight);
export const lazySetTableBorderColor = new LazyAction(lazyModule, m => m.setTableBorderColor);
