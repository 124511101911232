import { observer } from 'owa-mobx-react';
import { EmptyStateReadingPane, UnsupportedItemReadingPane } from 'owa-mail-reading-pane-view';
import getConversationReadingPaneViewState from 'owa-mail-reading-pane-store-conversation/lib/utils/getConversationReadingPaneViewState';
import React from 'react';
import { LoadingShimmer } from 'owa-mail-reading-pane-loading-view';
import type { MailboxInfo } from 'owa-client-types';

const ConversationReadingPaneInfoPage = ({ mailboxInfo }: { mailboxInfo: MailboxInfo }) => {
    const selectedConversationReadingPaneState = getConversationReadingPaneViewState();
    const loadingState = selectedConversationReadingPaneState?.loadingState;

    if (
        !selectedConversationReadingPaneState ||
        (loadingState.isLoading && loadingState.showLoadingShimmer)
    ) {
        // Loading
        return <LoadingShimmer />;
    } else if (loadingState.hasLoadFailed) {
        // Error
        return <EmptyStateReadingPane isError={true} />;
    } else if (selectedConversationReadingPaneState.unsupportedItemId) {
        return (
            <UnsupportedItemReadingPane
                itemId={selectedConversationReadingPaneState.unsupportedItemId}
                mailboxInfo={mailboxInfo}
            />
        );
    }
    return null;
};

export default observer(ConversationReadingPaneInfoPage, 'ConversationReadingPaneInfoPage');
