import { createLazyComponent, LazyModule, LazyAction } from 'owa-bundling';

export { default as getMeetingCardProps } from './utils/getMeetingCardProps';
export { default as getInitialViewState } from './utils/getInitialViewState';

export type { CalendarCardViewState } from './store/schema/CalendarCardViewState';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "CalendarCard" */ './lazyIndex')
);

export const CalendarCard = createLazyComponent(lazyModule, m => m.CalendarCard);
export const CalendarCardBottom = createLazyComponent(lazyModule, m => m.CalendarCardBottom);

export const lazyInitializeCalendarCard = new LazyAction(lazyModule, m => m.initializeCalendarCard);
export const lazySetResponseEditorState = new LazyAction(lazyModule, m => m.setResponseEditorState);

export const TimeRow = createLazyComponent(lazyModule, m => m.TimeRow);
export const LocationRow = createLazyComponent(lazyModule, m => m.LocationRow);
export const PersonRow = createLazyComponent(lazyModule, m => m.PersonRow);
export const RSVPActions = createLazyComponent(lazyModule, m => m.RSVPActions);
export const CalendarCardActions = createLazyComponent(lazyModule, m => m.CalendarCardActions);
export const OnlineMeetingSection = createLazyComponent(lazyModule, m => m.OnlineMeetingSection);
export const RSVPWithNote = createLazyComponent(lazyModule, m => m.RSVPWithNote);
