import getConversationSubject from 'owa-mail-reading-pane-store/lib/utils/getConversationSubject';
import addInfoBarMessage from 'owa-info-bar/lib/actions/addInfoBarMessage';
import { lazySetItemLocale } from 'owa-mail-inline-translation';
import type ItemViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemViewState';
import type { ClientItem } from 'owa-mail-store';
import { trace } from 'owa-trace';
import { isCapabilityEnabled } from 'owa-capabilities';
import { translatorCapability } from 'owa-capabilities-definitions/lib/translatorCapability';

export default async function initItemLocale(item: ClientItem, viewState: ItemViewState) {
    if (isCapabilityEnabled(translatorCapability, item.MailboxInfo) && item.TranslationData) {
        try {
            const savedUserLanguage = item.TranslationData.userLanguage;
            let subject: string = '';

            if (viewState.isConversationItemPart) {
                subject = getConversationSubject(item.ConversationId?.Id ?? '');
            } else {
                subject = item.Subject ?? '';
            }

            await lazySetItemLocale.importAndExecute(
                viewState.isConversationItemPart,
                item,
                subject
            );

            if (
                savedUserLanguage != item.TranslationData.userLanguage &&
                (item.TranslationData.isTranslatable ||
                    item.TranslationData.isForwardContentTranslatable)
            ) {
                // Force translate info bar
                addInfoBarMessage(viewState, 'translateInfoBar');
            }
        } catch (error) {
            trace.warn(`[setItemLocale] Failed to initialize item locale: ${error}`);
        }
    }
}
