import type MailSearchStore from './schema/MailSearchStore';
import { ObservableMap } from 'mobx';
import type { SuggestionSet } from 'owa-search-service';
import { createStore } from 'satcheljs';
import getDefaultAdvancedSearchViewState from 'owa-mail-advanced-search/lib/utils/getDefaultAdvancedSearchViewState';
import getRandomInt from '../utils/getRandomInt';
import { MAX_NAMES_FOR_GHOST_TEXT } from '../bootSearchConstants';
import type { AnswerPositionAndPropertyMapping } from './schema/AnswerPositionAndPropertyMapping';

export const GENERAL_GHOST_TEXT_BANK_COUNT = 4; //keep in sync with length of array in owa-mail-search/lib/searchConstants.ts
export const PERSONALIZED_GHOST_TEXT_BANK_COUNT = 8; //keep in sync with length of array in owa-mail-search/lib/searchConstants.ts

export const defaultMailSearchStore: MailSearchStore = {
    toDate: undefined,
    fromDate: undefined,
    includeAttachments: false,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (26,5): Type 'null' is not assignable to type 'SearchScope'.
    // @ts-expect-error
    initialSearchScope: null,
    isSearchHistoryDirty: false,
    isAnswerInitialized: false,
    isAnswerLocalizationCompleted: false,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (37,5): Type 'null' is not assignable to type 'FolderForestNode'.
    // @ts-expect-error
    previousNode: null,
    searchNumber: 0,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (26,5): Type 'null' is not assignable to type 'SearchScope'.
    // @ts-expect-error
    staticSearchScope: null,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (46,5): Type 'null' is not assignable to type 'SearchScope[]'.
    // @ts-expect-error
    staticSearchScopeList: null,
    shouldShowAdvancedSearch: false,
    advancedSearchViewState: getDefaultAdvancedSearchViewState(),
    initialAdvancedSearchViewState: getDefaultAdvancedSearchViewState(),
    legacySuggestions: new ObservableMap<string, SuggestionSet>({}),
    recipientForPersonalizedGhostTextIndex: getRandomInt(MAX_NAMES_FOR_GHOST_TEXT - 1),
    generalizedGhostTextBankIndex: getRandomInt(GENERAL_GHOST_TEXT_BANK_COUNT - 1),
    personalizedGhostTextBankIndex: getRandomInt(PERSONALIZED_GHOST_TEXT_BANK_COUNT - 1),
    usePersonalGhostText: false,
    isAnswerAvailable: false,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (59,5): Type 'null' is not assignable to type 'SearchProvider'.
    // @ts-expect-error
    provider: null,
    isAlterationRecourse: false,
    answersPositionAndPropertyMapping: new ObservableMap<
        number,
        AnswerPositionAndPropertyMapping[]
    >(),
    serpHasScrolled: false,
    isAnswerCardHidden: false,
    selectedMailboxInfo: undefined,
    lastSearchQueryText: '',
    selectedSuggestion: undefined,
};

const initialStore = { ...defaultMailSearchStore };
const store = createStore<MailSearchStore>('mailSearchStore', initialStore)();
export default store;
export const getStore = () => store;
