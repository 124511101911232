import { owaComputedFn } from 'owa-computed-fn';
import { type RibbonGroupId } from 'owa-ribbon-ids';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type { Layout } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components/RibbonControlGroupMLR';
import {
    sections,
    singleRow,
    stack,
} from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components/RibbonControlGroupMLR/layoutTypings';
import { type MailRibbonGroupId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';

export const getMLRTableFormatTabGroupLayouts = owaComputedFn(
    function getMLRTableFormatTabGroupLayouts(
        group: RibbonGroupId,
        props: ComposeRibbonControlDefProps
    ): Layout[] | undefined {
        const editorId = props.composeViewState?.editorId;
        switch (group) {
            case 196:
                return [
                    sections(
                        singleRow(
                            stack(
                                getComposeRibbonId(6098, editorId).toString(),
                                getComposeRibbonId(6097, editorId).toString()
                            ),
                            getComposeRibbonId(6100, editorId).toString()
                        )
                    ),
                ];
            default:
                return undefined;
        }
    }
);
