import expandCollapsedItemsRollUp from '../actions/expandCollapsedItemsRollUp';
import loadMore from '../actions/loadMore';
import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import { FocusedItemArea } from 'owa-mail-reading-pane-store/lib/store/schema/FocusedItemPart';
import type ItemPartViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemPartViewState';
import isNewestOnBottom from 'owa-mail-store/lib/utils/isNewestOnBottom';
import mailStore from 'owa-mail-store/lib/store/Store';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';
import {
    hasCollapsedItemsRollUp,
    hasSeeMoreButton,
} from '../utils/rollUp/collapsedItemsRollUpUtils';

export default action('expandSeeMore')(function expandSeeMore(
    conversationReadingPaneViewState: ConversationReadingPaneViewState,
    collapsedCount: number,
    fromKeyboard?: boolean
) {
    // If not from keyboard focus on the see more button
    if (!fromKeyboard) {
        conversationReadingPaneViewState.focusedItemPart = {
            itemPart: undefined,
            focusedItemArea: FocusedItemArea.SeeMore,
        };
    }

    // Expand the items
    let promiseToReturn = Promise.resolve();
    if (hasCollapsedItemsRollUp(conversationReadingPaneViewState)) {
        expandCollapsedItemsRollUp(conversationReadingPaneViewState);
    } else {
        promiseToReturn = loadMore(conversationReadingPaneViewState.conversationId);
    }

    promiseToReturn.then(() => {
        // Update focus if needed:
        // If there is no see more button, focus on the first visible item part
        if (!hasSeeMoreButton(conversationReadingPaneViewState)) {
            conversationReadingPaneViewState.focusedItemPart = {
                itemPart: getLastVisibleNode(conversationReadingPaneViewState, collapsedCount),
                focusedItemArea: FocusedItemArea.Item,
            };
        }
    });
});

function getLastVisibleNode(
    conversationReadingPaneViewState: ConversationReadingPaneViewState,
    collapsedCount: number
): ItemPartViewState | undefined {
    const conversationItemParts = mailStore.conversations.get(
        conversationReadingPaneViewState.conversationId.Id
    );

    if (conversationItemParts) {
        const { conversationNodeIds } = conversationItemParts;

        if (collapsedCount) {
            const indexIncrement = isNewestOnBottom() ? -1 : 1;
            const startIndex = isNewestOnBottom()
                ? collapsedCount
                : conversationNodeIds.length - (collapsedCount + 1);
            for (
                let index = startIndex;
                index <= conversationNodeIds.length - 1 && index >= 0;
                index += indexIncrement
            ) {
                const nodeId = conversationNodeIds[index];
                const itemPart = conversationReadingPaneViewState.itemPartsMap.get(nodeId);

                if (itemPart) {
                    return itemPart;
                }
            }
        }
    }

    return undefined;
}
