import type { DrawTool } from 'owa-editor-graphic-plugin-types/lib/enums/DrawTool';
import { lazyToggleDrawTool } from 'owa-editor-ribbonplugin-store';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import type { InputMethod } from '@1js/acui-common/lib/commanding/InputMethod';

const onToggleDrawTool = (
    composeId: string,
    drawTool: DrawTool,
    retriever: ((composeId: string) => RibbonMailComposeInfo | null) | undefined,
    inputMethod: InputMethod | undefined
) => {
    const editingInfo = retriever && retriever(composeId);

    if (editingInfo) {
        const drawViewState = editingInfo.composeViewState.ribbon.drawViewState;
        lazyToggleDrawTool.importAndExecute(
            editingInfo.composeViewState.editorId,
            drawViewState,
            drawTool,
            inputMethod
        );
    }
};

export default onToggleDrawTool;
