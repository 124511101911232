import type { RibbonTabDefinitionBase } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { owaComputedFn } from 'owa-computed-fn';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import getSLRComposeTab from '../../../util/getSLRComposeTab';
import { getRuntimeControls } from '../../../util/getRuntimeControls';

let runtimeControlIds: RibbonControlId[] | undefined;
let lastProps: ComposeRibbonControlDefProps | undefined;

const getSLRMessageTab = owaComputedFn(
    (props: ComposeRibbonControlDefProps): RibbonTabDefinitionBase => {
        if (!runtimeControlIds || !lastProps || lastProps !== props) {
            runtimeControlIds = getRuntimeControls()
                .getControlsProps(props)
                .map(({ controlId }) => controlId);
            lastProps = props;
        }

        return getSLRComposeTab(
            props,
            10,
            [] /* controlsDontDropLabels */,
            [6027, 6028] /* controlsDontMoveToOverflow */,
            [
                6023, 6022, 6024, 6016, 6021, 6020, 6071, 6007, 6008, 6006, 6005, 6030, 6025, 6026,
            ] /* controlsFirstMoveOverflow */,
            [
                6001,
                6002,
                6003,
                6004,
                6005,
                6006,
                6007,
                6008,
                6013,
                6071,
                6017,
                6018,
                6019,
                6110,
                6022,
                6023,
                6020,
                6021,
                6016,
                6024,
                6025,
                6026,
                6027,
                6028,
                6029,
                6030,
                6012,
                6075,
                620,
                7025,
                506,
                529,
                7029,
                7016,
                7000,
                7021,
                7023,
                7027,
                7028,
                7005,
                7006,
                564,
                560,
                7003,
                6073,
                7026,
                ...runtimeControlIds,
            ] /* controlsWithoutLabels */
        );
    }
);

export default getSLRMessageTab;
