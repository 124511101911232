import { observer } from 'owa-mobx-react';
import { useComputed } from 'owa-react-hooks/lib/useComputed';
import {
    addFavoriteLabel,
    newFavoritePickedText,
    renameFavoriteLabel,
} from './FavoritesList.locstring.json';
import loc, { format } from 'owa-localize';
import FavoriteNode from './FavoriteNode';
import { logUsage } from 'owa-analytics';
import {
    getStore as getSharedFavoritesStore,
    isMultiAccountsCombinedFavoritesEnabled,
} from 'owa-favorites';
import { FavoritesPicker } from 'owa-mail-favorites-picker';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import { FavoriteNodeContextMenu } from '../index';
import { setContextMenuState } from 'owa-mail-favorites-store/lib/actions/favoritesContextMenu';
import { showFindFavoritesPicker, getMailFavoritesViewState } from 'owa-mail-favorites-store';
import folderStore, { getEffectiveFolderDisplayName, getFolderTable } from 'owa-folders';
import { FAVORITE_FOLDERS_TREE_TYPE } from 'owa-folders-constants';
import TreeNode from 'owa-tree-node/lib/components/TreeNode';
import React from 'react';
import { isFeatureEnabled } from 'owa-feature-flags';
import FavoriteNodeV2 from './roaming/FavoriteNodeV2';
import type { FavoriteFolderData } from 'owa-favorites-types';
import { type FolderForestNodeType } from 'owa-favorites-types';
import { default as viewStateStore } from 'owa-mail-folder-store/lib/store/store';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import publicFolderFavoriteStore from 'owa-public-folder-favorite/lib/store/publicFolderFavoriteStore';
import { getMailboxInfoFromFolderId } from 'owa-mail-mailboxinfo';
import { FolderOperationNode } from 'owa-mail-folder-view';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';

import { full, medium, compact, nodeHeight } from 'owa-tree-node/lib/components/NodeHeight.scss';

import classnames from 'owa-classnames';

export interface FavoritesListProps {
    mailboxInfo: MailboxInfo;
}

export default observer(function FavoritesList(props: FavoritesListProps) {
    const [favoriteUpdateText, setFavoriteUpdateText] = React.useState<string>('');
    const favoritesStore = useComputed(() => {
        return getSharedFavoritesStore();
    });

    const ellipsesOnHover = isFeatureEnabled('fp-ellipsesOnHover');
    const mailboxKey = getIndexerValueForMailboxInfo(props.mailboxInfo);
    const renderContextMenuIfOpen = (): JSX.Element | null => {
        const contextMenuState = getMailFavoritesViewState(mailboxKey)?.contextMenuState;
        // Render the context menu if it is set to be visible
        if (!contextMenuState || contextMenuState.showRootMenu) {
            return null;
        }

        let folder;
        if (contextMenuState.folderId) {
            if (contextMenuState.nodeType == 5) {
                folder = publicFolderFavoriteStore.folderTable.get(contextMenuState.folderId);
            } else {
                folder = folderStore.folderTable.get(contextMenuState.folderId);
            }
        }

        return (
            <FavoriteNodeContextMenu
                anchorPoint={contextMenuState.anchor}
                nodeId={contextMenuState.nodeId}
                nodeType={contextMenuState.nodeType}
                folder={folder}
                onDismiss={onContextMenuDismissed}
                mailboxInfo={props.mailboxInfo}
            />
        );
    };

    // remove when fp-remove-links is enabled for all users
    const renderAddNewFavoriteNode = (): JSX.Element => {
        // Render a TreeNode with empty text if we're showing the picker.
        // This is because we want to preserve the same space as the original 'New favorite' node
        // so that the UI doesn't jump, and blank out the text so it doesn't temporarily show while the
        // picker animates in.
        return (
            <TreeNode
                displayName={loc(addFavoriteLabel)}
                isCustomActionNode={true}
                isRootNode={false}
                key={'newFavorite'}
                onClick={onNewFavoriteNodeClicked}
            />
        );
    };

    const onContextMenuDismissed = () => {
        setContextMenuState(mailboxKey, null);
    };

    const onNewFavoriteNodeClicked = () => {
        showFindFavoritesPicker(mailboxKey, true /* shouldShow */);
        logUsage('FindFavoritesClicked');
    };

    const renderOutlookFavorites = (): JSX.Element[] | null => {
        let orderedOutlookFavoritesIds: string[] | undefined;

        if (isMultiAccountsCombinedFavoritesEnabled()) {
            orderedOutlookFavoritesIds = favoritesStore.get().orderedCombinedOutlookFavoritesIds;
        } else {
            orderedOutlookFavoritesIds = favoritesStore
                .get()
                .favoriteTreeData.get(mailboxKey)?.orderedOutlookFavoritesIds;
        }

        if (!orderedOutlookFavoritesIds) {
            return null;
        }

        logUsage('TnS_FavoriteFoldersRendered', [
            orderedOutlookFavoritesIds.length,
            getIndexerValueForMailboxInfo(props.mailboxInfo) ===
                getIndexerValueForMailboxInfo(getGlobalSettingsAccountMailboxInfo()), // is primary account
        ]);

        return orderedOutlookFavoritesIds.map(favoriteId => {
            const folderTextFieldViewState = viewStateStore.folderTextFieldViewState;
            if (
                folderTextFieldViewState &&
                folderTextFieldViewState.folderId == favoriteId &&
                folderTextFieldViewState.folderNodeType === FAVORITE_FOLDERS_TREE_TYPE
            ) {
                const folderId = (
                    favoritesStore.get().outlookFavorites.get(favoriteId) as FavoriteFolderData
                ).folderId;
                const currentFolderName = getEffectiveFolderDisplayName(
                    getFolderTable().get(folderId)
                );
                return (
                    <FolderOperationNode
                        key="favoriteTextField"
                        folderId={folderId}
                        treeType={FAVORITE_FOLDERS_TREE_TYPE}
                        nestDepth={0}
                        operationType={'rename'}
                        originalValue={currentFolderName}
                        mailboxInfo={getMailboxInfoFromFolderId(folderId)}
                        ariaAnnouncementLabel={renameFavoriteLabel}
                    />
                );
            }
            return (
                <FavoriteNodeV2
                    key={favoriteId}
                    favoriteId={favoriteId}
                    ellipsesOnHover={ellipsesOnHover}
                />
            );
        });
    };
    const renderFavoriteNodes = (): JSX.Element[] => {
        return favoritesStore.get().orderedFavoritesNodeIds.map(nodeId => {
            return (
                <FavoriteNode key={nodeId} favoriteId={nodeId} ellipsesOnHover={ellipsesOnHover} />
            );
        });
    };

    const renderFindFavoritesComponents = (): JSX.Element | null => {
        if (getMailFavoritesViewState(mailboxKey)?.shouldShowFindFavoritesPicker) {
            return renderFavoritesPicker();
        } else if (!isFeatureEnabled('fp-remove-links')) {
            return renderAddNewFavoriteNode();
        } else {
            return null;
        }
    };
    const renderFavoritesPicker = (): JSX.Element => {
        return (
            <div className={classnames(getDensityModeCssClass(full, medium, compact), nodeHeight)}>
                <FavoritesPicker mailboxInfo={props.mailboxInfo} onItemSelected={onItemSelected} />
            </div>
        );
    };
    const onItemSelected = (displayName: string) => {
        setFavoriteUpdateText(format(loc(newFavoritePickedText), displayName));
    };
    return (
        <>
            {isFeatureEnabled('tri-favorites-roaming')
                ? renderOutlookFavorites()
                : renderFavoriteNodes()}
            {renderContextMenuIfOpen()}
            {renderFindFavoritesComponents()}
            <span className="screenReaderOnly" aria-live="assertive" aria-atomic="true">
                {favoriteUpdateText}
            </span>
        </>
    );
}, 'FavoritesList');
