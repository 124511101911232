import { observer } from 'owa-mobx-react';
import { getIsSearchTableShown } from 'owa-mail-list-store';
import type { MailListItemDataProps, MailListTableProps } from 'owa-mail-list-item-shared';
import React from 'react';
import MailListItemSingleLineView from './MailListItemSingleLineView';
import MailListItemCondensedThreeColumn from './MailListItemCondensedThreeColumn';
import MailListItemFullThreeColumn from './MailListItemFullThreeColumn';

export interface MailListItemProps {
    isFirstLevelExpanded: boolean;
    isLoadingSecondLevelExpansion: boolean;
    isSecondLevelExpanded: boolean;
    mailListItemDataProps: MailListItemDataProps;
    mailListTableProps: MailListTableProps;
    styleSelectorAsPerUserSettings: string;
}

export default observer(function MailListItem(props: MailListItemProps) {
    const {
        isFirstLevelExpanded,
        isLoadingSecondLevelExpansion,
        isSecondLevelExpanded,
        mailListItemDataProps,
        mailListTableProps,
        styleSelectorAsPerUserSettings,
    } = props;

    const {
        canDelete,
        canPin,
        categories,
        conversationId,
        effectiveMentioned,
        firstLineText,
        firstLineTooltipText,
        hasAttachment,
        hasSharepointLink,
        importance,
        isComplete,
        isFlagged,
        isInArchiveFolder,
        isInNotesFolder,
        isInOutboxFolder,
        isInJunkFolder,
        isNudged,
        isPinned,
        isSelected,
        isSnoozed,
        isTaggedForBigScreen,
        isUnauthenticatedSender,
        itemClassIcon,
        lastDeliveryTimestamp,
        lastSender,
        latestItemId,
        mailboxGuids,
        parentFolderDisplayName,
        parentFolderId,
        returnTime,
        rowId,
        rowKey,
        secondLineText,
        secondLineTooltipText,
        shouldShowExternalLabel,
        shouldShowRSVP,
        shouldShowTwisty,
        shouldShowSenderGroupingsButton,
        shouldSuggestUnsubscribe,
        showAttachmentPreview,
        showCondensedView,
        showDraftsIndicator,
        smimeType,
        thirdLineText,
        unreadCount,
    } = mailListItemDataProps;

    const {
        highlightTerms,
        isSingleLine,
        listViewType,
        showFolderTag,
        showPreviewText,
        supportsFlagging,
        supportsHoverIcons,
        supportsMove,
        supportsPinning,
        tableViewId,
    } = mailListTableProps;

    const isSearchTable = getIsSearchTableShown();

    if (isSingleLine) {
        return (
            <MailListItemSingleLineView
                canDelete={canDelete}
                canPin={canPin}
                categories={categories}
                conversationId={conversationId}
                effectiveMentioned={effectiveMentioned}
                firstLineText={firstLineText}
                firstLineTooltipText={firstLineTooltipText}
                hasAttachment={hasAttachment}
                hasSharepointLink={hasSharepointLink}
                highlightTerms={highlightTerms}
                importance={importance}
                isComplete={isComplete}
                isFirstLevelExpanded={isFirstLevelExpanded}
                isFlagged={isFlagged}
                isInArchiveFolder={isInArchiveFolder}
                isInNotesFolder={isInNotesFolder}
                isInOutboxFolder={isInOutboxFolder}
                isInJunkFolder={isInJunkFolder}
                isLoadingSecondLevelExpansion={isLoadingSecondLevelExpansion}
                isNudged={isNudged}
                isPinned={isPinned}
                isSearchTable={isSearchTable}
                isSecondLevelExpanded={isSecondLevelExpanded}
                isSelected={isSelected}
                isSnoozed={isSnoozed}
                isTaggedForBigScreen={isTaggedForBigScreen}
                isUnauthenticatedSender={isUnauthenticatedSender}
                itemClassIcon={itemClassIcon}
                lastDeliveryTimestamp={lastDeliveryTimestamp}
                lastSender={lastSender}
                latestItemId={latestItemId}
                listViewType={listViewType}
                mailboxGuids={mailboxGuids}
                parentFolderDisplayName={parentFolderDisplayName}
                parentFolderId={parentFolderId}
                returnTime={returnTime}
                rowId={rowId}
                rowKey={rowKey}
                secondLineText={secondLineText}
                secondLineTooltipText={secondLineTooltipText}
                shouldShowExternalLabel={shouldShowExternalLabel}
                shouldShowRSVP={shouldShowRSVP}
                shouldShowTwisty={shouldShowTwisty}
                shouldShowSenderGroupingsButton={shouldShowSenderGroupingsButton}
                shouldSuggestUnsubscribe={shouldSuggestUnsubscribe}
                showAttachmentPreview={showAttachmentPreview}
                showCondensedView={showCondensedView}
                showDraftsIndicator={showDraftsIndicator}
                showFolderTag={showFolderTag}
                showPreviewText={showPreviewText}
                smimeType={smimeType}
                supportsFlagging={supportsFlagging}
                supportsHoverIcons={supportsHoverIcons}
                supportsMove={supportsMove}
                supportsPinning={supportsPinning}
                tableViewId={tableViewId}
                thirdLineText={thirdLineText}
                unreadCount={unreadCount}
            />
        );
    }

    if (showCondensedView) {
        return (
            <MailListItemCondensedThreeColumn
                canDelete={canDelete}
                canPin={canPin}
                categories={categories}
                conversationId={conversationId}
                effectiveMentioned={effectiveMentioned}
                firstLineText={firstLineText}
                firstLineTooltipText={firstLineTooltipText}
                hasAttachment={hasAttachment}
                hasSharepointLink={hasSharepointLink}
                highlightTerms={highlightTerms}
                importance={importance}
                isComplete={isComplete}
                isFirstLevelExpanded={isFirstLevelExpanded}
                isFlagged={isFlagged}
                isInArchiveFolder={isInArchiveFolder}
                isInNotesFolder={isInNotesFolder}
                isInOutboxFolder={isInOutboxFolder}
                isInJunkFolder={isInJunkFolder}
                isNudged={isNudged}
                isPinned={isPinned}
                isLoadingSecondLevelExpansion={isLoadingSecondLevelExpansion}
                isSearchTable={isSearchTable}
                isSecondLevelExpanded={isSecondLevelExpanded}
                isSelected={isSelected}
                isSnoozed={isSnoozed}
                isTaggedForBigScreen={isTaggedForBigScreen}
                isUnauthenticatedSender={isUnauthenticatedSender}
                itemClassIcon={itemClassIcon}
                lastDeliveryTimestamp={lastDeliveryTimestamp}
                lastSender={lastSender}
                mailboxGuids={mailboxGuids}
                parentFolderDisplayName={parentFolderDisplayName}
                parentFolderId={parentFolderId}
                rowId={rowId}
                rowKey={rowKey}
                secondLineText={secondLineText}
                secondLineTooltipText={secondLineTooltipText}
                shouldShowExternalLabel={shouldShowExternalLabel}
                shouldShowTwisty={shouldShowTwisty}
                shouldShowSenderGroupingsButton={shouldShowSenderGroupingsButton}
                showDraftsIndicator={showDraftsIndicator}
                showFolderTag={showFolderTag}
                showPreviewText={showPreviewText}
                smimeType={smimeType}
                styleSelectorAsPerUserSettings={styleSelectorAsPerUserSettings}
                supportsFlagging={supportsFlagging}
                supportsHoverIcons={supportsHoverIcons}
                supportsMove={supportsMove}
                supportsPinning={supportsPinning}
                tableViewId={tableViewId}
                unreadCount={unreadCount}
            />
        );
    } else {
        return (
            <MailListItemFullThreeColumn
                canDelete={canDelete}
                canPin={canPin}
                categories={categories}
                conversationId={conversationId}
                effectiveMentioned={effectiveMentioned}
                firstLineText={firstLineText}
                firstLineTooltipText={firstLineTooltipText}
                hasAttachment={hasAttachment}
                hasSharepointLink={hasSharepointLink}
                highlightTerms={highlightTerms}
                importance={importance}
                isComplete={isComplete}
                isFirstLevelExpanded={isFirstLevelExpanded}
                isFlagged={isFlagged}
                isInArchiveFolder={isInArchiveFolder}
                isInNotesFolder={isInNotesFolder}
                isInOutboxFolder={isInOutboxFolder}
                isInJunkFolder={isInJunkFolder}
                isLoadingSecondLevelExpansion={isLoadingSecondLevelExpansion}
                isNudged={isNudged}
                isPinned={isPinned}
                isSearchTable={isSearchTable}
                isSecondLevelExpanded={isSecondLevelExpanded}
                isSelected={isSelected}
                isSnoozed={isSnoozed}
                isTaggedForBigScreen={isTaggedForBigScreen}
                isUnauthenticatedSender={isUnauthenticatedSender}
                itemClassIcon={itemClassIcon}
                lastDeliveryTimestamp={lastDeliveryTimestamp}
                lastSender={lastSender}
                latestItemId={latestItemId}
                listViewType={listViewType}
                mailboxGuids={mailboxGuids}
                parentFolderDisplayName={parentFolderDisplayName}
                parentFolderId={parentFolderId}
                returnTime={returnTime}
                rowId={rowId}
                rowKey={rowKey}
                secondLineText={secondLineText}
                secondLineTooltipText={secondLineTooltipText}
                shouldShowExternalLabel={shouldShowExternalLabel}
                shouldShowRSVP={shouldShowRSVP}
                shouldShowTwisty={shouldShowTwisty}
                shouldShowSenderGroupingsButton={shouldShowSenderGroupingsButton}
                shouldSuggestUnsubscribe={shouldSuggestUnsubscribe}
                showAttachmentPreview={showAttachmentPreview}
                showDraftsIndicator={showDraftsIndicator}
                showFolderTag={showFolderTag}
                showPreviewText={showPreviewText}
                smimeType={smimeType}
                styleSelectorAsPerUserSettings={styleSelectorAsPerUserSettings}
                supportsFlagging={supportsFlagging}
                supportsHoverIcons={supportsHoverIcons}
                supportsMove={supportsMove}
                supportsPinning={supportsPinning}
                tableViewId={tableViewId}
                thirdLineText={thirdLineText}
                unreadCount={unreadCount}
            />
        );
    }
}, 'MailListItem');
