import { observer } from 'owa-mobx-react';
import React from 'react';
import { isFeatureEnabled } from 'owa-feature-flags';
import { headerIconsContainer, fullSubject, label } from './SubjectHeaderV2.scss';

import { InfoPaneButton } from 'owa-info-pane';
import classNames from 'owa-classnames';
import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import { ZoomButtons } from 'owa-custom-zoom';

export interface SubjectHeaderTopToolBarProps {
    expandCollapseButton?: JSX.Element | null;
    shouldShrink: boolean;
    densityMode?: string;
    conversationReadingPaneViewState?: ConversationReadingPaneViewState;
    isSxS?: boolean;
}

export default observer(function SubjectHeaderTopToolBar(props: SubjectHeaderTopToolBarProps) {
    const { expandCollapseButton, shouldShrink, conversationReadingPaneViewState, isSxS } = props;
    const headerIconsContainerClassName = shouldShrink ? headerIconsContainer : fullSubject;
    const labelClassNames = classNames(label);

    return (
        <div className={headerIconsContainerClassName}>
            {<ZoomButtons context={'default'} />}
            {expandCollapseButton}
            {isFeatureEnabled('doc-infoPane') && !isSxS && conversationReadingPaneViewState && (
                <InfoPaneButton
                    viewState={conversationReadingPaneViewState}
                    labelStyles={labelClassNames}
                />
            )}
        </div>
    );
}, 'SubjectHeaderTopToolBar');
