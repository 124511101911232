import { selectAllMessagesCommand } from 'owa-locstrings/lib/strings/selectallmessagescommand.locstring.json';
import {
    writeEmailHeaderText,
    emailListHeaderText,
    readEmailHeaderText,
    goToHeaderText,
    emailActionsHeaderText,
    newEmailCommand,
    sendEmailCommand,
    replyEmailCommand,
    replyAllEmailCommand,
    forwardEmailCommand,
    saveDraftCommand,
    discardDraftCommand,
    goToInboxCommand,
    goToDraftsCommand,
    goToSentCommand,
    searchEmailCommand,
    undoCommand,
    clearAllMessagesCommand,
    homeCommand,
    deleteEmailCommand,
    openEmailCommand,
    openEmailInNewWindowCommand,
    closeEmailCommand,
    openNextItemCommand,
    openPreviousItemCommand,
    focusNextItemPartCommand,
    focusPreviousItemPartCommand,
    expandCollapseConversationCommand,
    expandConversationCommand,
    collapseConversationCommand,
    softDeleteCommand,
    newFolderCommand,
    markReadCommand,
    markUnreadCommand,
    flagEmailCommand,
    archiveCommand,
    markJunkCommand,
    categorize as categorize_1,
    insertHyperlinkCommand,
    showKeyboardShortcutsCommand,
    endCommand,
    copyMailToClipboardCommand,
    pasteMailFromClipboardCommand,
    toggleSelectMessageCommand,
    quickStepCommand,
    zoomInCommand,
    zoomOutCommand,
    resetZoomCommand,
    exitMessageEditor,
    printEmailCommand,
    syncMaiboxCommand,
} from './MailModuleHotKeys.locstring.json';
import { moveToFolder as moveToFolder_1 } from 'owa-locstrings/lib/strings/movetofolder.locstring.json';
import { toggleLeftPaneCommand } from 'owa-locstrings/lib/strings/toggleLeftPaneCommand.locstring.json';
import { ignore } from 'owa-locstrings/lib/strings/ignore.locstring.json';
import { snooze } from 'owa-locstrings/lib/strings/snooze.locstring.json';
import loc from 'owa-localize';
import type { CommandCategory } from 'owa-hotkeys-map';
import type { HotkeyCommand, Hotkeys } from 'owa-hotkeys';
import { isMac } from 'owa-user-agent/lib/userAgent';
import { getAppModuleCommands, getF6Hotkeys } from 'owa-appmodule-hotkeys';
import { owaComputedFn } from 'owa-computed-fn';

const writeEmailCategoryKey = 'writeEmail';
const emailListCategoryKey = 'emailList';
const readEmailCategoryKey = 'readEmail';
const goToCategoryKey = 'goTo';
const emailActionsCategoryKey = 'emailActions';

export const closeMail: HotkeyCommand = {
    category: readEmailCategoryKey,
    hotmail: 'esc',
    yahoo: 'esc',
    gmail: 'u',
    owa: 'esc',
    outlook: 'esc',
};

export function getNewMailCommand(): HotkeyCommand {
    return {
        category: writeEmailCategoryKey,
        description: loc(newEmailCommand),
        hotmail: ['n'],
        yahoo: 'n',
        gmail: 'c',
        owa: 'n',
        outlook: ['ctrl+n', 'ctrl+shift+m'],
    };
}

export function getCommandCategories(): CommandCategory[] {
    return [
        {
            category: writeEmailCategoryKey,
            displayName: loc(writeEmailHeaderText),
        },
        {
            category: emailListCategoryKey,
            displayName: loc(emailListHeaderText),
        },
        {
            category: readEmailCategoryKey,
            displayName: loc(readEmailHeaderText),
        },
        {
            category: goToCategoryKey,
            displayName: loc(goToHeaderText),
        },
        {
            category: emailActionsCategoryKey,
            displayName: loc(emailActionsHeaderText),
        },
    ];
}

// Helper function to create the definition for shortcuts that are the same across all types
function createUniversalHotkeyCommand(
    category: string,
    description: string,
    key: Hotkeys
): HotkeyCommand {
    return {
        category,
        description,
        hotmail: key,
        yahoo: key,
        gmail: key,
        owa: key,
        outlook: key,
    };
}

export const getCommands = owaComputedFn(getCommandsInternal);

function getCommandsInternal(): {
    [index: string]: HotkeyCommand;
} {
    const optionalCommands: {
        exitMessageEditor?: HotkeyCommand;
        ctrlTab?: HotkeyCommand;
        ctrlShiftTab?: HotkeyCommand;
    } = {};

    const f6Hotkeys = getF6Hotkeys();

    optionalCommands.exitMessageEditor = {
        category: writeEmailCategoryKey,
        description: loc(exitMessageEditor),
        hotmail: 'alt+f10',
        gmail: 'alt+f10',
        owa: 'alt+f10',
        outlook: 'alt+f10',
    };

    return {
        ...getAppModuleCommands(),
        newMail: getNewMailCommand(),
        sendMail: {
            category: writeEmailCategoryKey,
            description: loc(sendEmailCommand),
            hotmail: isMac() ? ['ctrl+enter'] : ['ctrl+enter', 'alt+s'],
            yahoo: 'alt+s',
            gmail: 'ctrl+enter',
            owa: isMac() ? ['ctrl+enter'] : ['ctrl+enter', 'alt+s'],
            outlook: isMac() ? ['ctrl+enter'] : ['ctrl+enter', 'alt+s'],
        },
        print: createUniversalHotkeyCommand(
            emailActionsCategoryKey,
            loc(printEmailCommand),
            'ctrl+p'
        ),
        reply: {
            category: writeEmailCategoryKey,
            description: loc(replyEmailCommand),
            hotmail: 'r',
            yahoo: 'r',
            gmail: 'r',
            owa: ['r', 'ctrl+r'],
            outlook: 'ctrl+r',
        },
        replyAll: {
            category: writeEmailCategoryKey,
            description: loc(replyAllEmailCommand),
            hotmail: ['a', 'shift+r'],
            yahoo: 'a',
            gmail: 'a',
            owa: ['shift+r', 'ctrl+shift+r'],
            outlook: 'ctrl+shift+r',
        },
        forward: {
            category: writeEmailCategoryKey,
            description: loc(forwardEmailCommand),
            hotmail: 'shift+f',
            yahoo: 'f',
            gmail: 'f',
            owa: ['shift+f', 'ctrl+shift+f'],
            outlook: 'ctrl+f',
        },
        saveDraft: createUniversalHotkeyCommand(
            writeEmailCategoryKey,
            loc(saveDraftCommand),
            'ctrl+s'
        ),
        discardDraft: {
            category: writeEmailCategoryKey,
            description: loc(discardDraftCommand),
            hotmail: 'esc',
            owa: 'esc',
            outlook: 'esc',
        },
        gotoInbox: {
            category: goToCategoryKey,
            description: loc(goToInboxCommand),
            hotmail: 'g i', // 'g' then 'i'
            yahoo: 'm',
            gmail: 'g i',
            owa: 'g i',
            outlook: 'ctrl+shift+i',
        },
        gotoDrafts: {
            category: goToCategoryKey,
            description: loc(goToDraftsCommand),
            hotmail: 'g d', // 'g' then 'd'
            gmail: 'g d',
            owa: 'g d',
        },
        gotoSent: {
            category: goToCategoryKey,
            description: loc(goToSentCommand),
            hotmail: 'g s', // 'g' then 's'
            gmail: 'g t',
            owa: 'g s',
        },
        gotoSearch: {
            category: goToCategoryKey,
            description: loc(searchEmailCommand),
            hotmail: '/',
            yahoo: 's',
            gmail: '/',
            owa: 'alt+q',
            outlook: ['ctrl+e', 'f3'],
        },
        undoAction: createUniversalHotkeyCommand(emailActionsCategoryKey, loc(undoCommand), [
            'ctrl+z',
            'alt+backspace',
        ]),
        selectUnselectMessage: {
            category: emailListCategoryKey,
            description: loc(toggleSelectMessageCommand),
            hotmail: 'ctrl+space',
            yahoo: 'ctrl+space',
            gmail: ['x', 'ctrl+x'],
            owa: 'ctrl+space',
            outlook: 'ctrl+space',
        },
        selectAll: createUniversalHotkeyCommand(
            emailListCategoryKey,
            loc(selectAllMessagesCommand),
            'ctrl+a'
        ),
        deselectAll: createUniversalHotkeyCommand(
            emailListCategoryKey,
            loc(clearAllMessagesCommand),
            'esc'
        ),
        home: createUniversalHotkeyCommand(emailListCategoryKey, loc(homeCommand), [
            'home',
            'ctrl+home',
        ]),
        end: createUniversalHotkeyCommand(emailListCategoryKey, loc(endCommand), [
            'end',
            'ctrl+end',
        ]),
        copyMailToClipboard: createUniversalHotkeyCommand(
            emailListCategoryKey,
            loc(copyMailToClipboardCommand),
            'ctrl+c'
        ),
        pasteMailToFolder: createUniversalHotkeyCommand(
            emailListCategoryKey,
            loc(pasteMailFromClipboardCommand),
            'ctrl+v'
        ),
        deleteMail: {
            category: emailActionsCategoryKey,
            description: loc(deleteEmailCommand),
            hotmail: 'del',
            yahoo: 'del',
            gmail: '#',
            owa: 'del',
            outlook: ['del', 'ctrl+d'],
        },
        openMail: {
            category: readEmailCategoryKey,
            description: loc(openEmailCommand),
            hotmail: ['o', 'enter'],
            gmail: ['o', 'enter'],
            owa: ['o', 'enter'],
            outlook: 'enter',
        },
        openMailInPopout: createUniversalHotkeyCommand(
            readEmailCategoryKey,
            loc(openEmailInNewWindowCommand),
            'shift+enter'
        ),
        toggleFolderPane: createUniversalHotkeyCommand(
            goToCategoryKey,
            loc(toggleLeftPaneCommand),
            ['alt+f1']
        ),
        closeMail: {
            description: loc(closeEmailCommand),
            ...closeMail,
        },
        openNextItem: {
            category: readEmailCategoryKey,
            description: loc(openNextItemCommand),
            hotmail: 'ctrl+.',
            yahoo: 'ctrl+.',
            gmail: 'j',
            owa: 'ctrl+.',
            outlook: 'ctrl+.',
        },
        openPrevItem: {
            category: readEmailCategoryKey,
            description: loc(openPreviousItemCommand),
            hotmail: 'ctrl+,',
            yahoo: 'ctrl+,',
            gmail: 'k',
            owa: 'ctrl+,',
            outlook: 'ctrl+,',
        },
        focusNextItemPart: {
            category: readEmailCategoryKey,
            description: loc(focusNextItemPartCommand),
            hotmail: '.',
            gmail: 'n',
            owa: '.',
        },
        focusPrevItemPart: {
            category: readEmailCategoryKey,
            description: loc(focusPreviousItemPartCommand),
            hotmail: ',',
            gmail: 'p',
            owa: ',',
        },
        expandCollapseAll: {
            category: readEmailCategoryKey,
            description: loc(expandCollapseConversationCommand),
            hotmail: 'x',
            owa: 'x',
        },
        expandAll: {
            category: readEmailCategoryKey,
            description: loc(expandConversationCommand),
            gmail: ';',
            outlook: 'right',
        },
        collapseAll: {
            category: readEmailCategoryKey,
            description: loc(collapseConversationCommand),
            yahoo: ['left'],
            gmail: [':', 'left'],
            outlook: 'left',
            owa: 'left',
        },
        softDeleteMail: createUniversalHotkeyCommand(
            emailActionsCategoryKey,
            loc(softDeleteCommand),
            'shift+del'
        ),
        newFolder: {
            category: emailActionsCategoryKey,
            description: loc(newFolderCommand),
            hotmail: 'shift+e',
            yahoo: 'ctrl+shift+e',
            owa: 'shift+e',
            outlook: 'ctrl+shift+e',
        },
        markAsRead: {
            category: emailActionsCategoryKey,
            description: loc(markReadCommand),
            hotmail: 'q',
            yahoo: 'k',
            gmail: 'shift+i',
            // On mac, ctrl+q is translated into cmd+q,
            // and that closes the window at the OS level.
            //
            // Only bind the "desktop-flavor" shortcut ctrl+q on windows.
            owa: isMac() ? 'q' : ['q', 'ctrl+q'],
            outlook: 'ctrl+q',
        },
        markAsUnread: {
            category: emailActionsCategoryKey,
            description: loc(markUnreadCommand),
            hotmail: 'u',
            yahoo: 'shift+k',
            gmail: 'shift+u',
            owa: ['u', 'ctrl+u'],
            outlook: 'ctrl+u',
        },
        markAsReadUnread: {
            // Used by Ribbon hotkey display, a mix of Read and Unread.
            // category and description are empty by design
            category: '',
            description: '',
            hotmail: ['q', 'u'],
            yahoo: ['k', 'shift+k'],
            gmail: ['shift+i', 'shift+u'],
            owa: isMac() ? ['q', 'u'] : ['ctrl+q', 'ctrl+u'],
            outlook: ['ctrl+q', 'ctrl+u'],
        },
        toggleFlag: {
            category: emailActionsCategoryKey,
            description: loc(flagEmailCommand),
            hotmail: 'ins',
            yahoo: 'l',
            gmail: 's',
            owa: 'ins',
            outlook: 'ins',
        },
        archiveMail: {
            category: emailActionsCategoryKey,
            description: loc(archiveCommand),
            hotmail: 'e',
            gmail: 'e',
            owa: 'e',
            outlook: 'backspace',
        },
        markAsJunk: {
            category: emailActionsCategoryKey,
            description: loc(markJunkCommand),
            hotmail: 'j',
            gmail: 'shift+1',
            owa: 'j',
            outlook: 'ctrl+j',
        },
        zoomIn: createUniversalHotkeyCommand(emailActionsCategoryKey, loc(zoomInCommand), [
            'ctrl++',
            'ctrl+=',
        ]),
        zoomOut: createUniversalHotkeyCommand(emailActionsCategoryKey, loc(zoomOutCommand), [
            'ctrl+-',
            'ctrl+_',
        ]),
        zoomReset: createUniversalHotkeyCommand(
            emailActionsCategoryKey,
            loc(resetZoomCommand),
            'ctrl+0'
        ),
        moveToFolder: {
            category: emailActionsCategoryKey,
            description: loc(moveToFolder_1),
            hotmail: 'v',
            gmail: 'v',
            owa: 'v',
            outlook: 'ctrl+shift+v',
        },
        categorize: {
            category: emailActionsCategoryKey,
            description: loc(categorize_1),
            hotmail: 'c',
            gmail: 'l',
            owa: 'c',
        },
        ignore: {
            category: emailActionsCategoryKey,
            description: loc(ignore),
            hotmail: 'ctrl+del',
            owa: 'ctrl+del',
            outlook: 'ctrl+del',
        },
        snooze: {
            category: emailActionsCategoryKey,
            description: loc(snooze),
            hotmail: 'b',
            gmail: 'b',
            owa: 'b',
        },
        // This hotkey is display only.
        // The actual keyboard shortcut handling logic is in RibbonPlugin, and does not
        // use a @keyboardShortcut decorator
        insertHyperlink: {
            category: writeEmailCategoryKey,
            description: loc(insertHyperlinkCommand),
            hotmail: 'ctrl+k',
            gmail: 'ctrl+k',
            owa: 'ctrl+k',
            outlook: 'ctrl+k',
        },
        keyboardShortcuts: {
            category: goToCategoryKey,
            description: loc(showKeyboardShortcutsCommand),
            hotmail: '?',
            gmail: '?',
            owa: '?',
            yahoo: '?',
        },
        f6: f6Hotkeys.f6,
        shiftF6: f6Hotkeys.shiftF6,
        ctrlTab: f6Hotkeys.ctrlTab,
        ctrlShiftTab: f6Hotkeys.ctrlShiftTab,
        refresh: {
            hotmail: ['f5', 'ctrl+shift+r'],
            gmail: ['f5', 'ctrl+shift+r'],
            owa: ['f5', 'ctrl+shift+r'],
            outlook: ['f5', 'ctrl+shift+r'],
        },
        syncMailbox: createUniversalHotkeyCommand(
            emailActionsCategoryKey,
            loc(syncMaiboxCommand),
            'f9'
        ),
        ...optionalCommands,
        ...getQuickStepHotkeys(),
    };
}

function createQuickStepHotkey(num: number): HotkeyCommand {
    return createUniversalHotkeyCommand(
        emailActionsCategoryKey,
        loc(quickStepCommand),
        `ctrl+shift+${num}` as Hotkeys
    );
}

function getQuickStepHotkeys(): {} {
    return {
        quickStep5: createQuickStepHotkey(5),
        quickStep6: createQuickStepHotkey(6),
        quickStep7: createQuickStepHotkey(7),
        quickStep8: createQuickStepHotkey(8),
        quickStep9: createQuickStepHotkey(9),
    } as const;
}
