import { mutatorAction } from 'satcheljs';
import { getGroupActionState } from '../selectors/joinButtonStoreSelectors';
import type ActionState from '../schema/ActionState';
import type { MailboxInfo } from 'owa-client-types';

export const setJoinButtonState = mutatorAction(
    'setJoinButtonState',
    function setJoinButtonState(
        mailboxInfo: MailboxInfo,
        groupSmtpAddress: string,
        newState: ActionState
    ) {
        const groupActionState = getGroupActionState(mailboxInfo);
        groupActionState.set(groupSmtpAddress.toLowerCase(), newState);
    }
);

export default setJoinButtonState;
