import { getActiveContentTab, TabType } from 'owa-tab-store';
import type { TabViewState } from 'owa-tab-store';
import { type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { getIsButtonDisabledForComposeTabs } from './ComposeTabButtonIsDisabledRules';
import { getInlineComposeViewState } from 'owa-mail-reading-pane-store-conversation/lib/utils/getComposeViewState';
import { isComposeTabControl } from './isComposeTabControl';
import type { MailboxInfo } from 'owa-client-types';
import { owaComputedFn } from 'owa-computed-fn';

/**
 * These RibbonIds are excluded from the logic in disabledInSecondaryPane inside of getIsButtonDisabled()
 */
const idsEnabledInSecondaryPane = [584, 585, 586, 587, 588, 589, 591, 592];

function getControlsEnabledInSecondaryPane(controlId: number) {
    if (idsEnabledInSecondaryPane.includes(controlId)) {
        return true;
    }
    // We want to enable controls used for compose if there is inline compose
    if (isComposeTabControl(controlId) && getInlineComposeViewState()) {
        return true;
    }
    return false;
}

export const getIsComposeButtonDisabled = owaComputedFn(function getIsComposeButtonDisabled(
    controlId: number | string,
    isPopout: boolean,
    composeId: string | undefined,
    _mailboxInfo: MailboxInfo
): boolean {
    const id: number = getNumericRibbonId(controlId);

    let disabledInSecondaryPane: boolean = false;
    if (!isPopout) {
        const activeTab: TabViewState | null = getActiveContentTab();

        if (activeTab) {
            disabledInSecondaryPane =
                activeTab.type == TabType.SecondaryReadingPane &&
                !getControlsEnabledInSecondaryPane(id);
        }
    }

    let disabledForComposeTabControl: boolean = false;
    if (!!composeId) {
        disabledForComposeTabControl = getIsButtonDisabledForComposeTabs(id, composeId);
    }
    return disabledInSecondaryPane || disabledForComposeTabControl;
});

function getNumericRibbonId(id: number | string): number {
    return typeof id == 'string' ? parseInt(id) : id;
}
