import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { createAppButton, constructKeytip } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import PollRegular from 'owa-fluent-icons-svg/lib/icons/PollRegular';
import loc from 'owa-localize';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { polls_Button_ActionControl } from '../strings/actionControlStrings.locstring.json';
import { pollsDesc } from '../strings/hoverTooltipDesc.locstring.json';
import { getRecipientWellsFromComposeViewState } from 'owa-mail-compose-actions/lib/utils/getAllRecipientsAsEmailAddressStrings';
import getCurrentFromAddress from 'owa-mail-compose-actions/lib/utils/getFromAddressFromRecipientWell';
import { tryAddRecipientFromEmailAddressString } from 'owa-mail-compose-actions/lib/utils/tryAddRecipientFromEmailAddressString';
import { lazyCreateFluidForms } from 'owa-fluid-integrations';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';

const onTogglePolls = owaComputedFn(
    (composeId: string, retriever: (composeId: string) => RibbonMailComposeInfo | null) => {
        return () => {
            const editingInfo = retriever(composeId);
            if (editingInfo) {
                const composeViewState = editingInfo.composeViewState;

                const composeParams = {
                    getRecipientWells: () =>
                        getRecipientWellsFromComposeViewState(composeViewState),
                    fromAddress: getCurrentFromAddress(composeViewState.toRecipientWell),
                    linkIdContainer: {
                        ...composeViewState.attachmentWell,
                        logTraceId: composeViewState.logTraceId,
                    },
                };
                const tryAddRecipientFunc = (emailAddress: string) => {
                    tryAddRecipientFromEmailAddressString(
                        composeViewState,
                        emailAddress,
                        editingInfo.targetWindow
                    );
                };

                lazyCreateFluidForms.importAndExecute(
                    editingInfo.targetWindow,
                    composeViewState,
                    composeParams,
                    composeViewState.subject,
                    tryAddRecipientFunc,
                    composeViewState.composeId,
                    composeViewState.mailboxInfo
                );
            }
        };
    }
);

export const getPolls = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonMailComposeInfo | null,
        styles: IButtonStyles | undefined,
        keyTipTabId: number | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        return createAppButton(
            getComposeRibbonId(7021, editorId),
            loc(polls_Button_ActionControl),
            onTogglePolls(composeId, retriever),
            keyTipTabId ? constructKeytip([keyTipTabId], 7021) : undefined,
            PollRegular,
            undefined /* iconColor */,
            styles,
            {
                customTooltip: getHoverTooltip(polls_Button_ActionControl, pollsDesc),
                disabled: isDisabled,
            }
        );
    }
);
