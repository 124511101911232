import type { IButtonStyles } from '@fluentui/react';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppToggleButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import Cursor from 'owa-fluent-icons-svg/lib/icons/CursorRegular';
import loc from 'owa-localize';
import { type DrawControlId } from 'owa-ribbon-ids/lib/drawControlId';
import { selectText } from './drawControlStrings.locstring.json';
import type { RibbonDrawState } from 'owa-editor-graphic-plugin-types/lib/store/schema/RibbonDrawState';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import onToggleDrawSelect from '../actions/onToggleDrawSelect';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';

export const getSelect = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: ((composeId: string) => RibbonMailComposeInfo | null) | undefined,
        drawViewState: RibbonDrawState | undefined,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        return createAppToggleButton(
            getComposeRibbonId(12003, editorId),
            loc(selectText),
            getComputedCallback(12003, onToggleDrawSelect, composeId, retriever),
            !!(drawViewState && drawViewState.isSelectMode),
            tabId ? constructKeytip([tabId], 12003) : undefined,
            Cursor,
            undefined /* iconColor */,
            styles,
            {
                disabled: isDisabled || !!(drawViewState && !drawViewState.areDrawToolsActive),
            }
        );
    }
);
