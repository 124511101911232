import type { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import { DirectionalHint } from '@fluentui/react/lib/ContextualMenu';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list';
import type { CategoryActionSource } from 'owa-categories';
import { getCategoryMenuProps, getMasterCategoryList } from 'owa-categories';
import { lazyAddFavoriteCategory } from 'owa-favorites';
import type { TableView } from 'owa-mail-list-store';
import { getStore as getListViewStore } from 'owa-mail-list-store';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import type { MailboxInfo } from 'owa-client-types';
import type { ActionSource } from 'owa-mail-store';
import {
    lazyAddCategoriesFromTable,
    lazyClearCategoriesFromTable,
    lazyRemoveCategoriesFromTable,
} from 'owa-mail-triage-action';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { lazyMountAndShowFullOptions } from 'owa-options-view';
import { CATEGORY_MENU_WIDTH } from './constants';
import {
    getAllCategoriesOnRows,
    getCommonCategoriesOnRows,
} from './getCategoryActionDecisionProperties';
import { isFeatureEnabled } from 'owa-feature-flags';

/**
 * Gets the category menu items for context menu
 * @param tableViewId the tableView id
 * @param dismissMenu the callback to be called to dismiss of menu
 * @param actionSource the actionSource
 * @return returns the contextual menu items
 */
export function getCategoryMenuPropertiesForContextMenu(
    tableViewId: string,
    dismissMenu: (ev?: any) => void,
    actionSource: ActionSource,
    shouldShowSearch?: boolean
): IContextualMenuProps {
    const tableView = getListViewStore().tableViews.get(tableViewId);
    return getCategoriesMenuProperties(
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2345 (53,9): Argument of type 'TableView | undefined' is not assignable to parameter of type 'TableView'.
        // @ts-expect-error
        tableView,
        shouldShowSearch /* showSearchBox */,
        true /* showManageCategories */,
        dismissMenu, // Callback to dismiss the right click context menu
        actionSource,
        'ContextLV',
        DirectionalHint.rightTopEdge
    );
}

/**
 * Gets the category menu items
 * @param tableView the tableView
 * @param showSearchBox flag indicating whether to show search box or not
 * @param showManageCategoriesBox flag indicating whether to the manage categories option or not
 * @param dismissMenu callback to be called to dismiss the category context menu
 * @param actionSource the actionSource
 * @param categoryActionSource the category action source
 * @param directionalHint determines which direction the callout appears, defaults to bottomLeftEdge
 * @return returns the contextual menu items
 */
function getCategoriesMenuProperties(
    tableView: TableView,
    showSearchBox: boolean,
    showManageCategories: boolean,
    dismissMenu: () => void,
    actionSource: ActionSource,
    categoryActionSource: CategoryActionSource,
    directionalHint?: DirectionalHint
): IContextualMenuProps {
    const mailboxInfo = getMailboxInfo(tableView);
    const categoryMenuProps = getCategoryMenuProps({
        actionSource: categoryActionSource,
        categoryList: getMasterCategoryList(mailboxInfo),
        mailboxInfo,
        getCategoriesToCheck: () =>
            getCommonCategoriesOnRows(tableView, [...tableView.selectedRowKeys.keys()]),
        dismissMenu,
        shouldShowSearchBox: showSearchBox,
        shouldShowManageCategories: showManageCategories,
        getShouldShowClearCategories: () => {
            return (
                getAllCategoriesOnRows(tableView, [...tableView.selectedRowKeys.keys()]).length > 0
            );
        }, // Show clear categories if any of the selected row has categories
        addFavoriteCategory,
        onAfterCreateNewCategory: (
            category: string,
            createNewCategoryPromise?: Promise<boolean>
        ) => {
            // When a new category is created, we should apply it optimistically to the selected items
            lazyAddCategoriesFromTable.importAndExecute(
                [...tableView.selectedRowKeys.keys()],
                tableView,
                [category],
                actionSource,
                createNewCategoryPromise
            );
        },
        onCheckedCategoryClicked: category => {
            // When a checked category is clicked its a remove action
            lazyRemoveCategoriesFromTable.importAndExecute(
                [...tableView.selectedRowKeys.keys()],
                tableView,
                [category],
                actionSource
            );
        },
        onUncheckedCategoryClicked: category => {
            // When an unchecked category is clicked its an add action
            lazyAddCategoriesFromTable.importAndExecute(
                [...tableView.selectedRowKeys.keys()],
                tableView,
                [category],
                actionSource
            );
        },
        onManageCategoryClicked: () => onManageCategoryClicked(mailboxInfo),
        onClearCategoriesClicked: () => {
            lazyClearCategoriesFromTable.importAndExecute(
                [...tableView.selectedRowKeys.keys()],
                tableView,
                actionSource
            );
        },
        directionalHint,
        width: CATEGORY_MENU_WIDTH,
    });

    return categoryMenuProps;
}

/**
 * Custom callback function when manage category is clicked
 */
function onManageCategoryClicked(mailboxInfo: MailboxInfo) {
    // Navigate to options/categories
    if (isMonarchMultipleAccountsEnabled() || isFeatureEnabled('acct-multiacctcomingsoon')) {
        lazyMountAndShowFullOptions.importAndExecute(
            'accounts-category',
            'categories',
            undefined,
            undefined,
            mailboxInfo
        );
    } else {
        lazyMountAndShowFullOptions.importAndExecute('general', 'categories');
    }
}

/**
 * Custom callback function when adding a favorite category
 */
function addFavoriteCategory(categoryId: string, actionSource: CategoryActionSource) {
    lazyAddFavoriteCategory
        .import()
        .then(addFavoriteCategoryFunc =>
            addFavoriteCategoryFunc(categoryId, actionSource, getModuleContextMailboxInfo())
        );
}
