import { FluentButton } from 'owa-fluent-v9-shims';
import { observer } from 'owa-mobx-react';
import { Callout, DirectionalHint } from '@fluentui/react/lib/Callout';
import React from 'react';
import { default as Tag } from 'owa-fluent-icons-svg/lib/icons/TagRegular';
import { categoriesButtonLabel } from './CategoryWellButton.locstring.json';
import loc from 'owa-localize';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import classnames from 'owa-classnames';

import {
    icon,
    full,
    medium,
    compact,
    label,
    button,
    categoryDropDown,
    categoriesContainer,
} from './CategoriesContainer.scss';

export interface CategoriesContainerProps {
    categories: JSX.Element[];
    shouldShrink: boolean;
    areCategoriesVisible: boolean;
    hideCategories: () => void;
    showCategories: () => void;
}

const iconProps = {
    iconName: Tag,
    className: icon,
};

export default observer(function CategoriesContainer(props: CategoriesContainerProps) {
    const { categories, shouldShrink, areCategoriesVisible, hideCategories, showCategories } =
        props;
    const densityModeCssClass = getDensityModeCssClass(full, medium, compact);
    const buttonElement = React.useRef<HTMLElement | null>();
    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const onShowMenuClicked = () => {
        if (areCategoriesVisible) {
            hideCategories();
        } else {
            showCategories();
        }
    };

    const labelStyles = React.useMemo(
        () => ({
            label: classnames(label, densityModeCssClass),
        }),
        [densityModeCssClass]
    );

    const buttonStyles = classnames(button, densityModeCssClass);

    const numOfCategories = categories.length.toString();
    const labelText = shouldShrink ? numOfCategories : loc(categoriesButtonLabel, numOfCategories);

    //  we want to throw categories in the well when we have 3 or more
    return shouldShrink || categories.length > 2 ? (
        /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
         * Baseline, please do not copy and paste this justification
         *	> JSX attribute values should not contain functions created in the same scope */
        <div ref={r => (buttonElement.current = r)}>
            <FluentButton
                onClick={onShowMenuClicked}
                className={buttonStyles}
                iconProps={iconProps}
                aria-label={loc(categoriesButtonLabel, numOfCategories)}
                styles={labelStyles}
                text={labelText}
                title={shouldShrink ? labelText : undefined}
            />
            {areCategoriesVisible ? (
                <Callout
                    setInitialFocus={true}
                    isBeakVisible={false}
                    target={buttonElement.current}
                    onDismiss={hideCategories}
                    directionalHint={DirectionalHint.bottomLeftEdge}
                >
                    <div className={categoryDropDown}>{categories}</div>
                </Callout>
            ) : null}
        </div>
    ) : (
        <div className={categoriesContainer}>{categories}</div>
    );
}, 'CategoriesContainer');
