import { LazyModule, LazyImport } from 'owa-bundling';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "InboxRule" */ './lazyIndex'));

export const lazyCreateNewInboxRuleFromDialog = new LazyImport(
    lazyModule,
    m => m.createNewInboxRuleFromDialog
);
export const lazyCreateRuleFromItem = new LazyImport(lazyModule, m => m.createRuleFromItem);
export const lazyCreateRuleFromRow = new LazyImport(lazyModule, m => m.createRuleFromRow);
