import { container, neutralPaletteBackground, fullBleed } from './ReadingPaneContainer.scss';
import ReadingPane from './ReadingPane';
import { READING_PANE_CONTAINER_ID, READING_PANE_MIN_VISIBLE_HEIGHT } from '../constants';
import { observer } from 'owa-mobx-react';
import { composeStore } from 'owa-mail-compose-store';
import { FullCompose } from 'owa-mail-compose-view';
import { messageAdListViewStatusStore } from 'owa-mail-messagead-list-store';
import { MessageAdReadingPane } from 'owa-mail-messagead-readingpane-view';
import { MultiSelectReadingPane } from 'owa-mail-multiselect-reading-pane-view';
import { useBooleanState } from 'owa-react-hooks/lib/useBooleanState';
import { useCustomTimeout } from 'owa-react-hooks/lib/useCustomTimeout';
import { subscribeToResizeEvent } from 'owa-resize-event';
import type { TabViewState } from 'owa-tab-store';
import { getActiveContentTab, TabType } from 'owa-tab-store';
import { trace } from 'owa-trace';
import React from 'react';
import isAnySxSVisibleInMainWindow from 'owa-sxs-store/lib/utils/isAnySxSVisibleInMainWindow';
import classNames from 'owa-classnames';
import { isComposeMailboxStateValid } from 'owa-mail-compose-actions/lib/utils/isComposeMailboxStateValid';
import { getAccountAuthStateForMailboxInfo, StateTypeEnum } from 'owa-account-authstate-store';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { lazyGovern, GovernPriority } from 'owa-tti';
import { lazyModule as readingPaneModule } from 'owa-mail-reading-pane-view';
import { lazyModule as readingPaneStoreModule } from 'owa-mail-reading-pane-store';
import { lazyModule as conversationReadingPaneStoreModule } from 'owa-mail-reading-pane-store-conversation';
import { getSelectedTableView } from 'owa-mail-list-store';
import shouldShowUnstackedReadingPane from 'owa-mail-store/lib/utils/shouldShowUnstackedReadingPane';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isCapabilityEnabled } from 'owa-capabilities';
import { bleedThroughCapability } from 'owa-capabilities-definitions/lib/bleedThroughCapability';

export interface ReadingPaneContainerProps {
    isDumpsterOrDumpsterSearchTable?: boolean;
    suppressServerMarkReadOnReplyOrForward?: boolean;
    isNotesFolder?: boolean;
    isItemAttachment?: boolean;
    useMultiSelectReadingPane?: boolean;
}

export default observer(function ReadingPaneContainer(props: ReadingPaneContainerProps) {
    const [
        maximizeScrollRegion,
        setMaximizeScrollRegionRequired,
        setMaximizeScrollRegionNotRequired,
    ] = useBooleanState(false);

    /**
     * This function is used to determine if the reading pane has
     * enough height for reading an email properly. If it does not,
     * the maximizeScrollRegion flag is set to allow scrolling the
     * email subject and avoid the sticky item header, hence there
     * will be more space to read(the scroll region is maximized)
     */
    const checkMaximizeScrollRegionRequired = () => {
        const readingPaneContainerHeight =
            /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
             * Baseline, please provide a proper justification if touching this code
             *	> 'clientHeight' is restricted from being used. This property can cause performance problems by causing re-layouts. Please use a resize observer instead. */
            document.getElementById('ReadingPaneContainerId')?.clientHeight;
        readingPaneContainerHeight && readingPaneContainerHeight < READING_PANE_MIN_VISIBLE_HEIGHT
            ? setMaximizeScrollRegionRequired()
            : setMaximizeScrollRegionNotRequired();
    };

    const [setResizeTimer] = useCustomTimeout('ReadingPaneContainer');

    const onResize = () =>
        setResizeTimer(() => {
            checkMaximizeScrollRegionRequired();
        }, 1);

    React.useEffect(() => {
        if (isFeatureEnabled('rp-earlyLoadV2')) {
            const tableView = getSelectedTableView();
            const listViewType = tableView?.tableQuery.listViewType;
            const isConversationView = !shouldShowUnstackedReadingPane() && listViewType == 0;

            lazyGovern.importAndExecute(
                // Import Reading Pane common components
                {
                    task: () => readingPaneModule.importModule('readingPane'),
                    priority: GovernPriority.Inbox,
                },
                // Import Reading Pane store for all views as it has common logic
                {
                    task: () => readingPaneStoreModule.importModule('readingPaneStore'),
                    priority: GovernPriority.Inbox,
                },
                // Import Conversation Reading Pane store if conversation view
                {
                    condition: isConversationView,
                    task: () =>
                        conversationReadingPaneStoreModule.importModule(
                            'conversationReadingPaneStore'
                        ),
                    priority: GovernPriority.Inbox,
                }
            );
        }
        // Always check when rendering the RP component in case
        // zoom has been set before resizing
        setTimeout(checkMaximizeScrollRegionRequired, 0);
        const resizeEventScope = ''; //default
        return subscribeToResizeEvent(onResize, resizeEventScope, window);
    }, []);

    const renderReadingPane = (): JSX.Element => {
        let renderContent = null;
        const activeTab = getActiveContentTab();

        if (
            activeTab &&
            (activeTab.type == TabType.MailCompose ||
                activeTab.type == TabType.SecondaryReadingPane)
        ) {
            // For secondary tab, it is not linked to list view, so always ignore what is selected from list view
            // and just render tab content for secondary tabs
            renderContent = renderTabContent(activeTab);
        }
        // If in dumpster or dumpster search, should always show reading pane (no compose)
        else if (props.isDumpsterOrDumpsterSearchTable && !props.useMultiSelectReadingPane) {
            renderContent = (
                <ReadingPane
                    isDumpsterOrDumpsterSearchTable={props.isDumpsterOrDumpsterSearchTable}
                    isFromMainWindow={true}
                    isItemAttachment={props.isItemAttachment}
                    maximizeScrollRegion={maximizeScrollRegion}
                />
            );
        } else if (props.useMultiSelectReadingPane) {
            renderContent = (
                <MultiSelectReadingPane
                    isDumpsterOrDumpsterSearchTable={props.isDumpsterOrDumpsterSearchTable}
                    isNotesFolder={props.isNotesFolder}
                />
            );
        } else {
            const primaryComposeViewState =
                composeStore.primaryComposeId &&
                composeStore.viewStates.get(composeStore.primaryComposeId);
            // Priority for content to be loaded into the Reading Pane:
            // 1. If a message ad has been clicked.
            // 2. If we're in tab view and have an activate tab matching these types.
            // 3. If a full compose is open.
            // 4. Render the base reading pane out of the store.
            if (messageAdListViewStatusStore.selectedAdId) {
                renderContent = (
                    <MessageAdReadingPane
                        selectedAdRowId={messageAdListViewStatusStore.selectedAdId}
                    />
                );
            } else if (primaryComposeViewState && !primaryComposeViewState.isInlineCompose) {
                renderContent =
                    !isAnySxSVisibleInMainWindow() &&
                    isComposeMailboxStateValid(primaryComposeViewState) ? (
                        <FullCompose
                            viewState={primaryComposeViewState}
                            key={primaryComposeViewState.composeId}
                        />
                    ) : null;
            } else {
                renderContent =
                    !primaryComposeViewState ||
                    isComposeMailboxStateValid(primaryComposeViewState) ? (
                        <ReadingPane
                            isDumpsterOrDumpsterSearchTable={props.isDumpsterOrDumpsterSearchTable}
                            isFromMainWindow={true}
                            isItemAttachment={props.isItemAttachment}
                            maximizeScrollRegion={maximizeScrollRegion}
                        />
                    ) : null;
            }
        }
        return <div key="renderReadingPaneDiv">{renderContent}</div>;
    };

    const renderTabContent = (tab: TabViewState) => {
        let content: JSX.Element | null = null;
        switch (tab.type) {
            case TabType.MailCompose:
                const composeViewState = composeStore.viewStates.get(tab.data);
                if (composeViewState) {
                    const isAnySxSInMainWin: boolean = isAnySxSVisibleInMainWindow();
                    const isMailboxValid: boolean = isComposeMailboxStateValid(composeViewState);
                    composeViewState.perfDatapoint?.addToCustomWaterfall(
                        4,
                        'RenderFullCompose',
                        true /* discardIfDefined */
                    );
                    content =
                        !isAnySxSInMainWin && isMailboxValid ? (
                            <FullCompose
                                key={composeViewState.composeId}
                                viewState={composeViewState}
                            />
                        ) : null;
                } else {
                    // for some reason, we are trying to render a tab, but we weren't able to grab any data about that tab.
                    // Tracing here with some info that might help us diagnose.
                    let keyList = '';
                    composeStore.viewStates.forEach((_value, key) => {
                        keyList = keyList + key + ' : ';
                    });
                    trace.warn(
                        '[ReadingPaneContainer] Attempted to render compose tab ID ' +
                            tab.data +
                            ' but it was not present in the compose store. Compose store IDs are ' +
                            keyList
                    );
                }
                break;
            case TabType.SecondaryReadingPane:
                const mailboxInfo = tab?.data?.id?.mailboxInfo;
                content =
                    !mailboxInfo ||
                    getAccountAuthStateForMailboxInfo(mailboxInfo).stateType !==
                        StateTypeEnum.AuthError ? (
                        <ReadingPane
                            isDumpsterOrDumpsterSearchTable={props.isDumpsterOrDumpsterSearchTable}
                            isFromMainWindow={true}
                            isItemAttachment={props.isItemAttachment}
                            maximizeScrollRegion={maximizeScrollRegion}
                        />
                    ) : null;
                break;
        }
        return content;
    };

    const isBleedThroughEnabled = isCapabilityEnabled(bleedThroughCapability);

    return (
        <div
            className={classNames(
                container,
                isBleedThroughEnabled ? fullBleed : neutralPaletteBackground
            )}
            key="readingPaneContainerDiv"
            id={READING_PANE_CONTAINER_ID}
        >
            {renderReadingPane()}
        </div>
    );
}, 'ReadingPaneContainer');
