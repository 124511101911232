import type { RibbonTabDefinitionBase } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { owaComputedFn } from 'owa-computed-fn';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { type DrawControlId } from 'owa-ribbon-ids/lib/drawControlId';
import getSLRComposeTab from '../../../util/getSLRComposeTab';

const getSLRDrawTab = owaComputedFn(function getSLRDrawTab(
    props: ComposeRibbonControlDefProps
): RibbonTabDefinitionBase {
    return getSLRComposeTab(
        props,
        12,
        [12010, 12011] /* controlsDontDropLabels */,
        [12005, 12012, 12006, 12007, 12008, 12009, 12011] /* controlsDontMoveToOverflow */,
        [] /* controlsFirstMoveToOverflow */,
        [
            12001, 12002, 12003, 12004, 12005, 12012, 12006, 12007, 12008, 12009,
        ] /* controlsWithoutLabels */
    );
});

export default getSLRDrawTab;
