import type { IEditor as ILegacyEditor } from 'roosterjs-editor-types';
import type { IEditor } from 'roosterjs-content-model-types';

/**
 * Check if the given editor object is Content Model editor
 * @param editor The editor to check
 * @returns True if the given editor is Content Model editor, otherwise false
 */
export default function isContentModelEditor(
    editor: ILegacyEditor
): editor is ILegacyEditor & IEditor {
    const contentModelEditor = editor as ILegacyEditor & IEditor;

    return !!contentModelEditor.formatContentModel;
}
