import type { RibbonTabDefinitionBase } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { owaComputedFn } from 'owa-computed-fn';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import getSLRComposeTab from '../../../util/getSLRComposeTab';
import { getRuntimeControls } from '../../../util/getRuntimeControls';

let runtimeControlIds: RibbonControlId[] | undefined;
let lastProps: ComposeRibbonControlDefProps | undefined;

const getSLRInsertTab = owaComputedFn(function getSLRInsertTab(
    props: ComposeRibbonControlDefProps
): RibbonTabDefinitionBase {
    if (!runtimeControlIds || !lastProps || lastProps !== props) {
        runtimeControlIds = getRuntimeControls()
            .getControlsProps(props)
            .map(({ controlId }) => controlId);
        lastProps = props;
    }
    return getSLRComposeTab(
        props,
        5,
        [] /* controlsDontDropLabels */,
        [] /* controlsDontMoveToOverflow */,
        [] /* controlsFirstMoveToOverflow */,
        [...runtimeControlIds] /* controlsWithoutLabels */
    );
});

export default getSLRInsertTab;
