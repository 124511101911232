import { mutatorAction } from 'satcheljs';
import { getFollowButtonStateMap } from '../selectors/followButtonStoreSelectors';
import type ActionState from '../schema/ActionState';
import type { MailboxInfo } from 'owa-client-types';

export const setFollowButtonState = mutatorAction(
    'setFollowButtonState',
    function setFollowButtonState(
        mailboxInfo: MailboxInfo,
        groupSmtpAddress: string,
        newState: ActionState
    ) {
        const groupActionState = getFollowButtonStateMap(mailboxInfo);

        groupActionState.set(groupSmtpAddress.toLowerCase(), newState);
    }
);

export default setFollowButtonState;
