import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppToggleButton, createAppSplitButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import MicFilled from 'owa-fluent-icons-svg/lib/icons/MicFilled';
import MicRecordFilled from 'owa-fluent-icons-svg/lib/icons/MicRecordFilled';
import loc from 'owa-localize';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import type { RibbonDictationInfo } from '../schema/RibbonEditingInfo';
import { dictationText } from '../strings/actionControlStrings.locstring.json';
import { dictationDescription } from '../strings/hoverTooltipDesc.locstring.json';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import {
    lazyToggleDictation,
    getDictationStore,
    DictationButtonStateEnum,
} from 'owa-editor-dictation';
import { onDictationLanguageMenuClicked } from '../actions/deferredMenuDefinitionMutator';

import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import { getHoverTooltipHotkey } from 'owa-compose-ribbon-utils/lib/utils/hoverTooltipHotkeyMap';
import type { AugloopSession } from 'augloop-service/lib/schema/AugloopSession';
import type { MailboxInfo } from 'owa-client-types';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';
import { isFeatureEnabled } from 'owa-feature-flags';

const onToggleDictation = (
    composeId: string,
    augloopSession: AugloopSession,
    mailboxInfo: MailboxInfo,
    retriever: (composeId: string) => RibbonDictationInfo | null
) => {
    const dictationInfo = retriever(composeId);
    if (dictationInfo) {
        const { infoBarHostViewState, dictationViewState, editorViewState, targetWindow } =
            dictationInfo;
        lazyToggleDictation
            .import()
            .then(toggleDictation =>
                toggleDictation(
                    editorViewState,
                    dictationViewState,
                    infoBarHostViewState,
                    augloopSession,
                    mailboxInfo,
                    targetWindow
                )
            );
    }
};

export const getDictationToggle = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        augloopSession: AugloopSession,
        mailboxInfo: MailboxInfo,
        retriever: (composeId: string) => RibbonDictationInfo | null,
        styles: IButtonStyles | undefined,
        keyTipTabId: number | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        const storeName = 'dictationLanguageMenuDefinitionStore';
        const dictationButtonState = getDictationStore().dictationButtonState;
        if (isFeatureEnabled('cmp-dictation-ui')) {
            return createAppToggleButton(
                getComposeRibbonId(7029, editorId) /* id */,
                loc(dictationText) /* label */,
                getComputedCallback(
                    7029,
                    onToggleDictation,
                    composeId,
                    augloopSession,
                    mailboxInfo,
                    retriever
                ) /* onExecute */,
                dictationButtonState === DictationButtonStateEnum.DictationOn /* toggled */,
                keyTipTabId ? constructKeytip([keyTipTabId], 7029) : undefined,
                dictationButtonState === DictationButtonStateEnum.DictationOn
                    ? MicRecordFilled
                    : MicFilled /* icon */,
                undefined /* iconColor */,
                styles,
                {
                    disabled:
                        isDisabled ||
                        dictationButtonState === DictationButtonStateEnum.DictationStarting ||
                        dictationButtonState === DictationButtonStateEnum.DictationStopping,
                    customTooltip: getHoverTooltip(
                        dictationText,
                        dictationDescription,
                        getHoverTooltipHotkey(7029)
                    ),
                } /* overrides */
            );
        } else {
            return createAppSplitButton(
                getComposeRibbonId(7029, editorId) /* id */,
                loc(dictationText) /* label */,
                getControlMenuStore(editorId, storeName) /* menu store */,
                getComputedCallback(
                    7029,
                    onToggleDictation,
                    composeId,
                    augloopSession,
                    mailboxInfo,
                    retriever
                ) /* onExecute */,
                keyTipTabId ? constructKeytip([keyTipTabId], 7029, true /*hasMenu*/) : undefined,
                dictationButtonState === DictationButtonStateEnum.DictationOn
                    ? MicRecordFilled
                    : MicFilled /* icon */,
                undefined /* iconColor */,
                styles,
                {
                    onAfterMenuDismiss: () => {
                        resetMenuDefinition(editorId, storeName);
                        callEditorApi(editorId, 'focus');
                    },
                    splitButtonAriaLabel: loc(dictationText),
                    disabled:
                        isDisabled ||
                        dictationButtonState === DictationButtonStateEnum.DictationStarting ||
                        dictationButtonState === DictationButtonStateEnum.DictationStopping,
                    customTooltip: getHoverTooltip(
                        dictationText,
                        dictationDescription,
                        getHoverTooltipHotkey(7029)
                    ),
                    toggled: dictationButtonState === DictationButtonStateEnum.DictationOn,
                    onMenuButtonExecute: () => {
                        onDictationLanguageMenuClicked(editorId, composeId);
                    },
                } /* overrides */
            );
        }
    }
);
