import { OfficeExchangeApplicationId } from 'owa-service/lib/constants/ApplicationId';

export const OWA_APP_ID = OfficeExchangeApplicationId;
export const DriveId: string = 'driveId';
export const ItemId: string = 'itemId';

export const COMPOSE_ONLY_FLUID_KEEP_BODY = 'owaFluidKeepBodyOnSend';
export const COMPOSE_FLUID_PLACEHOLDER_ID = 'fluidPlaceholder';

export const OUTLOOK_APP_NAME = 'Outlook';
export const OUTLOOK_WEB_APP_NAME = 'Outlook_Web_App';
export const Monarch_APP_NAME = 'Outlook_Monarch';
export const OWA_APP_PLATFORM = 'Web';
export const CH2_APP_NAME = 'OneNoteMeetings';
export const VIDEO_RECORDER_SVG_ID = 'videoRecorderSvgId';
//if FLUID_ENTITY_TYPE text is changed, please change it also in owa-editor-link-plugin/src/plugin/LinkPlugin.ts
export const FLUID_ENTITY_TYPE = 'OWAFluid';
export const FLUID_CHANGE_SOURCE = 'fluid';
// If this value is changed also change in owa-insert-link/src/utils/getAllInsertLinksIds.ts
export const FLUID_CONTAINER = 'fluidContainer';
export const FLUID_HERO_MENU_ID = 'FLUID_HERO_MENU_ID';
export const CONTEXT_MENU_EVENT_NAME = 'contextmenu';
export const CLICK_EVENT_NAME = 'click';
export const ME_CARD_DATA_SUMMARY = 'MeCardDataSummary';
export const OWA_AUTO_LINKS_CLASSNAME = 'OWAAutoLink';
export const FALLBACKHTML = 'fallback';
export enum LoopStyle {
    Link = 'linkonly',
    Embedded = 'embedded',
    Pill = 'link',
}

export const OfficeFluidContainerRegistrationId = '@ms/office-fluid-container';
