/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - Migrating deprecated icons: https://outlookweb.visualstudio.com/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9297/Migrating-deprecated-icons-in-client-web
 *	> 'ControlIcons' import from owa-control-icons is restricted. Use JSX icons from @fluentui/react-icons, otherwise from owa-fluent-icons-svg if they can only be reference by string. */
import { type ControlIcons } from 'owa-control-icons';
import { getIsDarkTheme } from 'owa-fabric-theme';
import type { ComposeActionKey, HoverActionKey, ReadActionKey } from 'owa-outlook-service-options';
import { type SurfaceActionIcons } from 'owa-surface-action-icons';
import { default as ArrowReply } from 'owa-fluent-icons-svg/lib/icons/ArrowReplyRegular';
import { default as ArrowReplyAll } from 'owa-fluent-icons-svg/lib/icons/ArrowReplyAllRegular';
import { default as ArrowForward } from 'owa-fluent-icons-svg/lib/icons/ArrowForwardRegular';
import { default as Emoji } from 'owa-fluent-icons-svg/lib/icons/EmojiRegular';
import { default as Delete } from 'owa-fluent-icons-svg/lib/icons/DeleteRegular';
import { default as Flag } from 'owa-fluent-icons-svg/lib/icons/FlagRegular';
import { default as Translate } from 'owa-fluent-icons-svg/lib/icons/TranslateRegular';
import { default as FolderMail } from 'owa-fluent-icons-svg/lib/icons/FolderMailRegular';
import { default as Calendar } from 'owa-fluent-icons-svg/lib/icons/CalendarLtrRegular';
import { default as ShieldError } from 'owa-fluent-icons-svg/lib/icons/ShieldErrorRegular';
import { default as Mail } from 'owa-fluent-icons-svg/lib/icons/MailRegular';
import { default as ClipboardArrowRight } from 'owa-fluent-icons-svg/lib/icons/ClipboardArrowRightRegular';
import { default as PersonAdd } from 'owa-fluent-icons-svg/lib/icons/PersonAddRegular';
import { default as Prohibited } from 'owa-fluent-icons-svg/lib/icons/ProhibitedRegular';
import { default as MailAttach } from 'owa-fluent-icons-svg/lib/icons/MailAttachRegular';
import { default as PersonProhibited } from 'owa-fluent-icons-svg/lib/icons/PersonProhibitedRegular';
import { default as Print } from 'owa-fluent-icons-svg/lib/icons/PrintRegular';
import { default as ImmersiveReader } from 'owa-fluent-icons-svg/lib/icons/ImmersiveReaderRegular';
import { default as Branch } from 'owa-fluent-icons-svg/lib/icons/BranchRegular';
import { default as WindowNew } from 'owa-fluent-icons-svg/lib/icons/WindowNewRegular';
import { default as WeatherSunny } from 'owa-fluent-icons-svg/lib/icons/WeatherSunnyRegular';
import { default as PersonChat } from 'owa-fluent-icons-svg/lib/icons/PersonChatRegular';
import { default as CalendarDataBar } from 'owa-fluent-icons-svg/lib/icons/CalendarDataBarRegular';
import { default as ArrowDownload } from 'owa-fluent-icons-svg/lib/icons/ArrowDownloadRegular';
import { default as MailRewindRegular } from 'owa-fluent-icons-svg/lib/icons/MailRewindRegular';
import { default as EyeFilled } from 'owa-fluent-icons-svg/lib/icons/EyeFilled';
import { default as MailArrowClockwiseRegular } from 'owa-fluent-icons-svg/lib/icons/MailArrowClockwiseRegular';
import { default as Chat } from 'owa-fluent-icons-svg/lib/icons/ChatRegular';

import AppFolderRegular from 'owa-fluent-icons-svg/lib/icons/AppFolderRegular';
import { getLoopResourceUrl } from './getLoopResourceUrl';
import getSurfaceActionIconUrl from './getSurfaceActionIconUrl';

const ATTACH_SVG = 'attach.svg';
const ATTACHDARK_SVG = 'attachDark.svg';
const INLINEIMAGE_SVG = 'inlineimage.svg';
const INLINEIMAGEDARK_SVG = 'inlineimageDark.svg';
const EMOJI_SVG = 'emoji.svg';
const SHOWRIBBON_SVG = 'showribbon.svg';
const SHOWRIBBONDARK_SVG = 'showribbonDark.svg';
const SPELLING_SVG = 'spelling.svg';

export interface SurfaceActionIcon {
    iconName: string;
    fallbackIconName: string;
    isImage: boolean;
    isFluentIcon?: boolean;
}

export function getComposeActionIconName(key: ComposeActionKey): SurfaceActionIcon {
    let iconName: string = '';
    let fallbackIconName: string = '';
    let isImage = false;
    let isFluentIcon = false;

    switch (key) {
        case 'AddAttachment':
            iconName = getSurfaceActionIconUrl(getIsDarkTheme() ? ATTACHDARK_SVG : ATTACH_SVG);
            fallbackIconName = getSurfaceActionIconUrl(ATTACH_SVG);
            isImage = true;
            break;
        case 'AddInlineImage':
            iconName = getSurfaceActionIconUrl(
                getIsDarkTheme() ? INLINEIMAGEDARK_SVG : INLINEIMAGE_SVG
            );
            fallbackIconName = getSurfaceActionIconUrl(INLINEIMAGE_SVG);
            isImage = true;
            break;
        case 'AddEmoji':
            iconName = getSurfaceActionIconUrl(EMOJI_SVG);
            isImage = true;
            break;
        case 'ToggleDarkCompose':
            iconName = 'Sunny';
            break;
        case 'FluidHeroButton':
            iconName = getLoopResourceUrl();
            isImage = true;
            break;
        case 'QuickUse':
            iconName = 'ClipboardList';
            break;
        case 'ToggleRibbon':
            iconName = getSurfaceActionIconUrl(
                getIsDarkTheme() ? SHOWRIBBONDARK_SVG : SHOWRIBBON_SVG
            );
            fallbackIconName = getSurfaceActionIconUrl(SHOWRIBBON_SVG);
            isImage = true;
            break;
        case 'SensitivityMenu':
            iconName = 'MarkAsProtected';
            break;
        case 'ProtectMessage':
            iconName = 'Encryption';
            break;
        case 'SaveDraft':
            iconName = 'Save';
            break;
        case 'InsertSignature':
            iconName = 'InsertSignatureLine';
            break;
        case 'ShowFrom':
            iconName = 'ArrangeByFrom';
            break;
        case 'SetImportance':
            iconName = 'Important';
            break;
        case 'ShowMessageOptions':
            iconName = 'MailOptions';
            break;
        case 'SwitchBodyType':
            iconName = 'PlainText';
            break;
        case 'AccChecker':
            iconName = 'AccessibiltyChecker';
            break;
        case 'ProofingOptions':
            iconName = getSurfaceActionIconUrl(SPELLING_SVG);
            isImage = true;
            break;
        case 'FindTime':
            iconName = CalendarDataBar;
            isFluentIcon = true;
            break;
        case 'MessageExtensions':
            iconName = AppFolderRegular;
            isFluentIcon = true;
            break;
        default:
            iconName = '';
            break;
    }

    return {
        iconName,
        fallbackIconName: fallbackIconName || iconName,
        isImage,
        isFluentIcon,
    };
}

export function getReadActionIconName(key: ReadActionKey): SurfaceActionIcon {
    let iconName: string;

    switch (key) {
        case '-':
            iconName = '-';
            break;
        case 'Reply':
            iconName = ArrowReply;
            break;
        case 'ReplyAll':
            iconName = ArrowReplyAll;
            break;
        case 'Forward':
            iconName = ArrowForward;
            break;
        case 'ForwardEmailAsAttachment':
            iconName = MailAttach;
            break;
        case 'ReplyByMeeting':
            iconName = Calendar;
            break;
        case 'CopilotReplyByMeeting':
            iconName = Calendar;
            break;
        case 'ReportAbuse':
            iconName = ShieldError;
            break;
        case 'Delete':
            iconName = Delete;
            break;
        case 'MarkReadUnread':
            iconName = Mail;
            break;
        case 'FlagUnflag':
            iconName = Flag;
            break;
        case 'AssignPolicy':
            iconName = ClipboardArrowRight;
            break;
        case 'AddToSafeSenders':
            iconName = PersonAdd;
            break;
        case 'MarkJunkNotJunk':
            iconName = Prohibited;
            break;
        case 'MarkAsPhishing':
            iconName = ShieldError;
            break;
        case 'Block':
            iconName = PersonProhibited;
            break;
        case 'CreateRule':
            iconName = FolderMail;
            break;
        case 'Print':
            iconName = Print;
            break;
        case 'Translate':
            iconName = Translate;
            break;
        case 'ShowInImmersiveReader':
            iconName = ImmersiveReader;
            break;
        case 'ViewMessageSource':
            iconName = Branch;
            break;
        case 'Popout':
            iconName = WindowNew;
            break;
        case 'SetReaction':
            iconName = Emoji;
            break;
        case 'ToggleDarkMode':
            iconName = WeatherSunny;
            break;
        case 'ReplyByIM':
        case 'ReplyAllByIM':
            iconName = PersonChat;
            break;
        case 'ReplyByTeamsChatActions':
        case 'ReplyByTeamsChat':
        case 'ReplyAllByTeamsChat':
            iconName = Chat;
            break;
        case 'ToggleAmp':
            iconName = 'LightningBolt';
            break;
        case 'MessageExtensions':
            iconName = AppFolderRegular;
            break;
        case 'SaveEmail':
            iconName = ArrowDownload;
            break;
        case 'MessageRecall':
            iconName = MailRewindRegular;
            break;
        case 'TrackReadReceipts':
            iconName = EyeFilled;
            break;
        case 'Resend':
            iconName = MailArrowClockwiseRegular;
            break;
        default:
            iconName = '';
            break;
    }

    return {
        iconName,
        fallbackIconName: iconName,
        isImage: false,
    };
}

export function getHoverActionIconName(key: HoverActionKey): SurfaceActionIcon {
    let iconName: string;
    switch (key) {
        case '-':
            iconName = '-';
            break;
        case 'Delete':
            iconName = 'Delete';
            break;
        case 'Archive':
            iconName = 'Archive';
            break;
        case 'PinUnpin':
            iconName = 'Pinned';
            break;
        case 'ReadUnread':
            iconName = 'Mail';
            break;
        case 'FlagUnflag':
            iconName = 'Flag';
            break;
        case 'Move':
            iconName = 'FabricFolder';
            break;
        default:
            iconName = '';
            break;
    }
    return {
        iconName,
        fallbackIconName: iconName,
        isImage: false,
    };
}
