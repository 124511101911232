import { constructKeytip, createAppFlyoutAnchor } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { pictureBorderButton } from './pictureControlsStrings.locstring.json';
import ImageCopyRegular from 'owa-fluent-icons-svg/lib/icons/ImageCopyRegular';
import type { IButtonStyles } from '@fluentui/react';
import type { RibbonFlyoutAnchorProps } from '@1js/acui-ribbon-like';
import { onBorderMenuClicked } from '../actions/deferredMenuDefinitionMutator';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import type { PictureFormatState } from 'owa-editor-ribbonplugin-store';
import { owaComputedFn } from 'owa-computed-fn';
import type { RibbonViewStateInfo } from 'owa-compose-formatcontrols-sections/lib/schema/RibbonViewStateInfo';
import { updatePictureFormatState } from '../actions/updatePictureFormatState';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';

export const getPictureBorderControl = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonViewStateInfo | null,
        styles: IButtonStyles | undefined,
        pictureFormatState: PictureFormatState,
        tabId: number | undefined,
        isDisabled: boolean
    ) => {
        const storeName = 'borderMenuDefinitionStore';

        return createAppFlyoutAnchor(
            getComposeRibbonId(6079, editorId),
            loc(pictureBorderButton),
            getControlMenuStore(editorId, storeName),
            tabId ? constructKeytip([tabId], 6079, true /* hasMenu */) : undefined,
            ImageCopyRegular,
            undefined /* iconColor */,
            styles,
            <Partial<RibbonFlyoutAnchorProps>>{
                disabled: isDisabled,
                onExecute: parameter => {
                    const { event } = parameter;
                    event?.stopPropagation();
                    onBorderMenuClicked(editorId, composeId, retriever, pictureFormatState);
                },
                onAfterMenuDismiss: () => {
                    resetMenuDefinition(editorId, storeName);
                    updatePictureFormatState(editorId, pictureFormatState);
                    callEditorApi(editorId, 'focus');
                },
            }
        );
    }
);
