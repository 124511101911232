import {
    lazyGetSensitivitySections,
    lazyGetProtectEncryptSections,
    lazyGetSignatureSections,
    lazyGetEditorSections,
    lazyGetFluidHeroSections,
} from 'owa-mail-compose-control-sections/lib/sections';
import type CLPLabel from 'owa-mail-protection-types/lib/schema/CLPLabel';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import { setMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import type { MailRibbonTabId } from 'owa-ribbon-ids';
import type { ComposeViewState } from 'owa-mail-compose-store';
import type { ProofingViewState } from 'owa-editor-proofing-common/lib/store/schema/ProofingViewState';
import type { RibbonFluidMenuInfo } from 'owa-fluid-integrations';
import type { MailboxInfo } from 'owa-client-types';
import { logDiscoverMenuOpened } from 'owa-fluid-datapoints/lib/datapoints/discoverMenuDatapoints';
import { lazyGetFluidMenuItems } from 'owa-fluid-integrations';

export function onSensitivityMenuClicked(
    editorId: string,
    viewState: ComposeViewState,
    onSensitivityClicked: (label: CLPLabel) => void,
    tabId: MailRibbonTabId | undefined
) {
    lazyGetSensitivitySections.import().then(getSensitivitySections => {
        setMenuDefinition(editorId, 'sensitivityMenuDefinitionStore', () => {
            return {
                sections: getSensitivitySections(viewState, onSensitivityClicked, tabId),
            };
        });
    });
}

export function onEncryptMenuClicked(
    editorId: string,
    composeId: string,
    retriever: (composeId: string) => RibbonMailComposeInfo | null
) {
    lazyGetProtectEncryptSections.import().then(getProtectEncryptSections => {
        setMenuDefinition(editorId, 'encryptMenuDefinitionStore', () => {
            return {
                sections: getProtectEncryptSections(composeId, retriever),
            };
        });
    });
}

export function onSignatureMenuClicked(
    editorId: string,
    viewState: ComposeViewState, // Only used for getting info to create button/menus, do not use it within any event handler to avoid memory leak
    retriever: (composeId: string) => RibbonMailComposeInfo | null,
    keytipTabId: MailRibbonTabId | undefined
) {
    lazyGetSignatureSections.import().then(getSignatureSections => {
        setMenuDefinition(editorId, 'signatureMenuDefinitionStore', () => {
            return {
                sections: getSignatureSections(viewState, retriever, keytipTabId),
            };
        });
    });
}

export function onLoopMenuClicked(
    editorId: string,
    composeId: string,
    retriever: (composeId: string) => RibbonFluidMenuInfo | null,
    mailboxInfo: MailboxInfo
) {
    logDiscoverMenuOpened();
    Promise.all([lazyGetFluidHeroSections.import(), lazyGetFluidMenuItems.import()]).then(
        ([getFluidHeroSections, getFluidMenuItems]) => {
            setMenuDefinition(editorId, 'loopMenuDefinitionStore', () => {
                return {
                    sections: getFluidHeroSections(
                        getFluidMenuItems,
                        editorId,
                        composeId,
                        retriever,
                        mailboxInfo
                    ),
                };
            });
        }
    );
}

export function onEditorMenuClicked(
    viewState: ProofingViewState,
    editorId: string,
    mailboxInfo: MailboxInfo
) {
    lazyGetEditorSections.import().then(getEditorSections => {
        setMenuDefinition(editorId, 'editorMenuDefinitionStore', () => {
            return {
                sections: getEditorSections(viewState, editorId, mailboxInfo),
            };
        });
    });
}
