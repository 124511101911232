import { getStore as getProtectionStore } from 'owa-protection-store/lib/store/protectionStore';
import type { ComposeViewState } from 'owa-mail-compose-store';
import isLabelinScopeForItemType from 'owa-mail-protection/lib/utils/clp/isLabelinScopeForItemType';
import { type ItemContentTypeForCLPLabel } from 'owa-mail-protection/lib/utils/clp/constants';
import { isFeatureEnabled } from 'owa-feature-flags';

/**
 * Determines whether atleast one  eligible Sensitivity Label exists or not
 * @param viewState ComposeViewState object - contains the mailbox which is used to get the CLP Labels
 *                      and in turn, determine whether some valid label exists or not for Email workload.
 * @returns boolean - true if atleast one valid label exists: label has no children labels and is valid OR has atleast one valid child label.
 *                  - false otherwise.
 */
export default function haveAtleastOneValidSensitivityLabel(viewState: ComposeViewState): boolean {
    const mailboxInfo = viewState.mailboxInfo;
    const protectionStore = getProtectionStore(mailboxInfo);
    const { clpLabels } = protectionStore;
    const isScopingDisabled = !isFeatureEnabled(
        'cmp-clp-label-scoping',
        mailboxInfo,
        !!mailboxInfo /* dontThrowErrorIfNotInitialized  */
    );

    return clpLabels.some(
        label =>
            (label.children.length === 0 &&
                label?.isLabelEnabled &&
                (isScopingDisabled || isLabelinScopeForItemType(label, 'email'))) ||
            label.children?.some(
                childLabel =>
                    childLabel.isLabelEnabled &&
                    (isScopingDisabled || isLabelinScopeForItemType(childLabel, 'email'))
            )
    );
}
