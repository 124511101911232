import React from 'react';
import { isCapabilityEnabled } from 'owa-capabilities';
import { smartReplyCapability } from 'owa-capabilities-definitions/lib/smartReplyCapability';
import { isSelf } from 'owa-mail-compose-actions/lib/utils/isSelf';
import type { ItemViewState } from 'owa-mail-reading-pane-store';
import { MailSmartPillBlock } from 'owa-mail-smart-pill';
import type { ClientItem } from 'owa-mail-store';
import type Message from 'owa-service/lib/contract/Message';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { isCopilotFeatureEnabled } from 'owa-copilot-settings-utils';
import { isFeatureEnabled } from 'owa-feature-flags';

export default function renderSmartPillBlock(
    item: ClientItem,
    viewState: ItemViewState,
    showOnTop?: boolean
): JSX.Element | null {
    if (isCapabilityEnabled(smartReplyCapability)) {
        const message = item as Message;

        // Don't show smart pills on messages in which recipient is the sender.
        const isSelfSender: boolean = message.Sender
            ? isSelf(message.Sender.Mailbox, item.MailboxInfo)
            : false;

        if (!isSelfSender) {
            // If UserOptions does not exist, just turn off the feature.
            const isSmartReplyOptionEnabled: boolean =
                !!getUserConfiguration()?.UserOptions?.WebSuggestedRepliesEnabledForUser;

            if (
                isSmartReplyOptionEnabled ||
                (isCopilotFeatureEnabled('SuggestedDrafts', item.MailboxInfo) &&
                    isFeatureEnabled('mon-copilot-suggested-drafts-smartpills'))
            ) {
                return (
                    <MailSmartPillBlock
                        item={item}
                        viewState={viewState.smartPillViewState}
                        isConversationItemPart={viewState.isConversationItemPart}
                        showOnTop={!!showOnTop}
                        smartRepliesEnabled={isSmartReplyOptionEnabled}
                    />
                );
            }
        }
    }
    return null;
}
