import type { ComposeViewState } from 'owa-mail-compose-store';
import type CLPLabel from 'owa-mail-protection-types/lib/schema/CLPLabel';
import { lazyOnSensitivity } from 'owa-mail-commands-actions';

export default function onSensitivity(
    viewState: ComposeViewState,
    label: CLPLabel,
    targetWindow: Window,
    isLouderLabelsUxUsed: boolean = false
) {
    lazyOnSensitivity.importAndExecute(viewState, label, targetWindow, isLouderLabelsUxUsed);
}
