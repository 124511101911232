import type TxpCardViewState from 'txp-data/lib/schema/viewSchema/TxpCardViewState';
import type VotingCardViewState from 'owa-mail-voting/lib/store/schema/VotingCardViewState';
import type { CalendarCardViewState } from 'owa-mail-calendar-card';
import type { YammerCardViewState } from 'owa-yammer-thread-scenario';
import type { ThreadSummarizationCardViewState } from 'owa-mail-thread-summarization-content/lib/store/schema/ThreadSummarizationCardViewState';
import type InboxShoppingCardViewState from 'owa-mail-commercial-header/lib/store/schema/InboxShoppingCardViewState';

interface ExtendedCardViewState {
    cardViewState: CardViewState;
    cardType: ExtendedCardType;
    displayPosition: 'outsideScrollRegion' | 'inScrollRegionTop' | 'inScrollRegionSort';
    coverOriginalContent?: boolean;
}

export enum ExtendedCardType {
    TXP,
    CalendarCard,
    Voting,
    Yammer,
    InboxShopping,
    ThreadSummarization,
}

export type CardViewState =
    | TxpCardViewState
    | VotingCardViewState
    | CalendarCardViewState
    | YammerCardViewState
    | InboxShoppingCardViewState
    | ThreadSummarizationCardViewState;

export default ExtendedCardViewState;
