import { errorThatWillCauseAlert } from 'owa-trace';
import type { MailRibbonTabId, RibbonControlId } from 'owa-ribbon-ids';
import { getIsButtonDisabledForComposeTabs } from 'owa-mail-ribbon-utils/lib/ComposeTabButtonIsDisabledRules';
import { getIsComposeButtonDisabled } from 'owa-mail-ribbon-utils/lib/getIsComposeButtonDisabled';
import {
    retrieveCommonInfoForRibbon,
    retrieveFluidInfoForRibbon,
} from 'owa-mail-compose-controls/lib/utils/retrieveEditingInfoForRibbon';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { type CommandingViewMode } from 'owa-outlook-service-option-store';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type { ComposeViewState } from 'owa-mail-compose-store';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import TableSettingsRegular from 'owa-fluent-icons-svg/lib/icons/TableSettingsRegular';
import { owaComputedFn } from 'owa-computed-fn';
import { getAccChecker } from 'owa-compose-actioncontrols/lib/components/accChecker';
import { getAddEmoji } from 'owa-compose-actioncontrols/lib/components/emoji';
import { getAddInlineImagesControl } from 'owa-compose-actioncontrols/lib/components/inlineImages';
import { getAddIns } from 'owa-mail-compose-controls/lib/components/addins';
import { getAlignControl } from 'owa-compose-formatcontrols/lib/components/align';
import {
    getAlignLeftControl,
    getAlignCenterControl,
    getAlignRightControl,
    getAlignJustifyControl,
} from 'owa-compose-formatcontrols-sections/lib/sections/getAlignSections';
import { getAttachFileControl } from 'owa-mail-compose-controls/lib/components/attachFile';
import { getAttachSigControl } from 'owa-mail-compose-controls/lib/components/getAttachSigControl';
import { getAutoFormatSettings } from 'owa-compose-formatcontrols/lib/components/autoFormatOptions';
import { getBoldControl } from 'owa-compose-formatcontrols/lib/components/bold';
import { getBulletsControl } from 'owa-compose-formatcontrols/lib/components/bullets';
import { getCellShadingControl } from 'owa-compose-formatcontrols/lib/components/tableControls/getCellShadingControl';
import { getChangeCaseControl } from 'owa-compose-formatcontrols/lib/components/changeCase';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { getCutCopyControl } from 'owa-compose-formatcontrols-sections/lib/sections/getCutCopySections';
import { getDefaultRibbonStyles } from 'owa-command-ribbon-styles/lib/util/getDefaultRibbonStyles';
import { getDefaultSlrSplitButtonAppRibbonButtonStyles } from 'owa-command-ribbon-styles';
import { getDictationToggle } from 'owa-compose-actioncontrols/lib/components/dictation';
import { getEncryptControl } from 'owa-mail-compose-controls/lib/components/getEncryptControl';
import { getFindTime } from 'owa-mail-compose-controls/lib/components/findTime';
import { getFluidHero } from 'owa-mail-compose-controls/lib/components/fluidHero';
import { getFontBackColorPickerControl } from 'owa-compose-formatcontrols/lib/components/fontBackColorPicker';
import { getFontColorPickerControl } from 'owa-compose-formatcontrols/lib/components/fontColorPicker';
import { getFontPickerControl } from 'owa-compose-formatcontrols/lib/components/fontPicker';
import { getFontSizePickerControl } from 'owa-compose-formatcontrols/lib/components/fontSizePicker';
import { getFormatPainterControl } from 'owa-compose-formatcontrols/lib/components/formatPainter';
import {
    getHighImportance,
    getLowImportance,
} from 'owa-mail-compose-controls/lib/components/setImportance';
import { getIndentControl } from 'owa-compose-formatcontrols/lib/components/indent';
import { getIsViewModeSelected } from 'owa-command-ribbon-store';
import { getItalicControl } from 'owa-compose-formatcontrols/lib/components/italic';
import { getLeftToRightControl } from 'owa-compose-formatcontrols/lib/components/ltr';
import { getMessageExtensions } from 'owa-mail-compose-controls/lib/components/messageExtensions';
import { getNumberingControl } from 'owa-compose-formatcontrols/lib/components/numbering';
import { getOutdentControl } from 'owa-compose-formatcontrols/lib/components/outdent';
import { getPasteControl } from 'owa-compose-formatcontrols/lib/components/paste';
import { getPasteCutCopyControl } from 'owa-compose-formatcontrols/lib/components/pasteCutCopy';
import { getPolls } from 'owa-mail-compose-controls/lib/components/polls';
import { getCopilotDrafts } from 'owa-mail-compose-controls/lib/components/copilotDrafts';
import { getProofingOptions } from 'owa-mail-compose-controls/lib/components/proofingOptions';
import { getQuoteControl } from 'owa-compose-formatcontrols/lib/components/quote';
import { getReadOrDeliveryReceipt } from 'owa-mail-compose-controls/lib/components/getReadOrDeliveryReceipt';
import { getDisallowReactions } from 'owa-mail-compose-controls/lib/components/disallowReactions';
import { getRemoveFormatControl } from 'owa-compose-formatcontrols/lib/components/removeFormat';
import { getRightToLeftControl } from 'owa-compose-formatcontrols/lib/components/rtl';
import { getSaveControl } from 'owa-mail-compose-controls/lib/components/saveDraft';
import { getSendLater } from 'owa-mail-compose-controls/lib/components/sendLater';
import { getSensitivityControl } from 'owa-mail-compose-controls/lib/components/getSensitivityControl';
import { getShowCcBcc } from 'owa-mail-compose-controls/lib/components/showCcBcc';
import { getShowFrom } from 'owa-mail-compose-controls/lib/components/showFrom';
import { getShowMessageOptions } from 'owa-mail-compose-controls/lib/components/showMessageOptions';
import { getStrikeThroughControl } from 'owa-compose-formatcontrols/lib/components/strikethrough';
import { getStylePickerControl } from 'owa-compose-formatcontrols/lib/components/stylePicker';
import { getSubscriptControl } from 'owa-compose-formatcontrols/lib/components/subscript';
import { getSuperscriptControl } from 'owa-compose-formatcontrols/lib/components/superscript';
import { getSwitchBodyType } from 'owa-mail-compose-controls/lib/components/switchBodyType';
import { getTableControl } from 'owa-compose-actioncontrols/lib/components/getTableControl';
import { getTableFormatStateMenu } from 'owa-compose-formatcontrols/lib/components/tableControls/getTableFormatStateMenu';
import { getTableFormatTabControlIcon } from 'owa-compose-formatcontrols/lib/components/tableControls/tableFormatTabControlIconMap';
import { getTableOperationControl } from 'owa-compose-formatcontrols/lib/components/tableControls/getTableOperationControl';
import { getTableOperationMenu } from 'owa-compose-formatcontrols/lib/components/tableControls/getTableOperationMenu';
import { getTableBorderWeightControl } from 'owa-compose-formatcontrols/lib/components/tableControls/getTableBorderWeightControl';
import { getTableBorderColorControl } from 'owa-compose-formatcontrols/lib/components/tableControls/getTableBorderColorControl';
import { getApplyTableBorderControl } from 'owa-compose-formatcontrols/lib/components/tableControls/getApplyTableBorderControl';
import { getTablePredefinedStyleIconMenu } from 'owa-compose-formatcontrols/lib/components/tableControls/getTablePredefinedStyleIconMenu';
import { getToggleDarkMode } from 'owa-compose-actioncontrols/lib/components/toggleDarkCompose';
import { getUnderlineControl } from 'owa-compose-formatcontrols/lib/components/underline';
import { getUndoRedoControl } from 'owa-compose-formatcontrols/lib/components/undoRedo';
import isApprovalResponse from 'owa-mail-compose-store/lib/utils/isApprovalResponse';
import { onAddInlineImage } from 'owa-mail-compose-controls/lib/utils/onAddInlineImage';
import { onShowLinkDialogClicked } from 'owa-mail-ribbon-command-actions/lib/onShowLinkDialogClicked';
import { getSpacingControl } from 'owa-compose-formatcontrols/lib/components/spacing';
import { getLinkUnlink } from 'owa-compose-actioncontrols/lib/components/linkUnlink';
import { getDrawCanvas } from 'owa-compose-drawcontrols/lib/components/drawCanvas';
import { getDrawTouch } from 'owa-compose-drawcontrols/lib/components/drawTouch';
import { getEffectPen } from 'owa-compose-drawcontrols/lib/components/effectPen';
import { getRedPen } from 'owa-compose-drawcontrols/lib/components/redPen';
import { getBlackPen } from 'owa-compose-drawcontrols/lib/components/blackPen';
import { getEraser } from 'owa-compose-drawcontrols/lib/components/eraser';
import { getHighlighter } from 'owa-compose-drawcontrols/lib/components/highlighter';
import { getUndo } from 'owa-compose-drawcontrols/lib/components/undo';
import { getSelect } from 'owa-compose-drawcontrols/lib/components/select';
import { getLasso } from 'owa-compose-drawcontrols/lib/components/lasso';
import { getTextPen } from 'owa-compose-drawcontrols/lib/components/textPen';
import { type DrawControlId } from 'owa-ribbon-ids/lib/drawControlId';
import { getVideoRecorder } from 'owa-mail-compose-controls/lib/components/videoRecorder';
import { getPictureBorderControl } from 'owa-compose-picture-format-controls/lib/components/border';
import { getPictureShadowControl } from 'owa-compose-picture-format-controls/lib/components/shadow';
import { getAltTextControl } from 'owa-compose-picture-format-controls/lib/components/altText';
import { getPictureRotateControl } from 'owa-compose-picture-format-controls/lib/components/rotate';
import { getPictureCropControl } from 'owa-compose-picture-format-controls/lib/components/crop';
import { getPictureChangeControl } from 'owa-compose-picture-format-controls/lib/components/changePicture';
import { getPictureSizeControl } from 'owa-compose-picture-format-controls/lib/components/size';
import { getRedo } from 'owa-compose-drawcontrols/lib/components/redo';
import { getTableAlignmentMenu } from 'owa-compose-formatcontrols/lib/components/tableControls/getTableAlignmentMenu';
import { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';
import { isEncryptionEnabled } from 'owa-mail-compose-controls/lib/utils/isEncryptionEnabled';

/**
 * This function supplies a mapping from FormatControlId/ActionControlId
 * to their respective control definition function for Ribbon compose tabs.
 * For any new control in a tab, be sure to update the mapping below.
 * Note: This function should *not* have any logic regarding showing/hiding.
 *       That should be in shouldShowRibbonControl.ts instead.
 */
export const getComposeTabsControlDefinition = owaComputedFn(
    (
        controlId: RibbonControlId,
        tabId: MailRibbonTabId,
        props: ComposeRibbonControlDefProps
    ): RibbonControlProps | undefined => {
        const viewState: ComposeViewState = props.composeViewState;
        const composeId = viewState.composeId;
        const defaultStyles = getDefaultRibbonStyles();
        const useContentModelEditor = !!viewState.useContentModelEditor;

        const isSingleLineRibbon: boolean = getIsViewModeSelected(1);
        const splitButtonStyles = isSingleLineRibbon
            ? getDefaultSlrSplitButtonAppRibbonButtonStyles()
            : defaultStyles;

        const editorId = viewState.editorId;
        const formatViewState = viewState.ribbon.formatViewState;
        const drawViewState = viewState.ribbon.drawViewState;
        const isControlDisabled: boolean = getIsComposeButtonDisabled(
            controlId,
            props.isPopout,
            composeId,
            viewState.mailboxInfo
        );
        const tableControlIcon: string | undefined = getTableFormatTabControlIcon(controlId);

        const addInlineImageCallback = onAddInlineImage(
            props.attachmentPickers.addImagesInline.computer.current
        );
        const source = FormattingSource.Ribbon;

        switch (controlId) {
            case 6001:
                return getBoldControl(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    formatViewState,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    source
                );
            case 6002:
                return getItalicControl(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    formatViewState,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    source
                );
            case 6003:
                return getUnderlineControl(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    formatViewState,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    source
                );
            case 6004:
                return getStrikeThroughControl(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    formatViewState,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    source
                );
            case 6005:
                return getBulletsControl(
                    editorId,
                    formatViewState,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    source
                );
            case 6006:
                return getNumberingControl(
                    editorId,
                    formatViewState,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    source
                );
            case 6017:
                return getAlignLeftControl(
                    editorId,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    source
                );
            case 6018:
                return getAlignCenterControl(
                    editorId,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    source
                );
            case 6019:
                return getAlignRightControl(
                    editorId,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    source
                );
            case 6110:
                return getAlignJustifyControl(
                    editorId,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    source
                );
            case 6071:
                return getAlignControl(
                    editorId,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    source,
                    useContentModelEditor
                );
            case 6009:
                return getCutCopyControl(
                    true /* isCut */,
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    formatViewState,
                    defaultStyles,
                    tabId,
                    undefined /* parentControlId */,
                    isControlDisabled
                );
            case 6010:
                return getCutCopyControl(
                    false /* isCut */,
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    formatViewState,
                    defaultStyles,
                    tabId,
                    undefined /* parentControlId */,
                    isControlDisabled
                );
            case 6013:
                return getUndoRedoControl(
                    editorId,
                    formatViewState,
                    splitButtonStyles,
                    tabId,
                    isControlDisabled
                );
            case 6011:
                return getPasteControl(
                    editorId,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    false /* asPlainText  */,
                    composeId /* retrieverKey */,
                    retrieveCommonInfoForRibbon,
                    undefined /* iconColor */
                );
            case 6012:
                return getPasteCutCopyControl(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    formatViewState,
                    splitButtonStyles,
                    tabId,
                    isControlDisabled
                );
            case 6016:
                return getQuoteControl(
                    editorId,
                    formatViewState,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    source
                );
            case 6024:
                return getChangeCaseControl(
                    editorId,
                    defaultStyles,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    source
                );
            case 6022:
                return getSubscriptControl(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    formatViewState,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    source
                );
            case 6023:
                return getSuperscriptControl(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    formatViewState,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    source
                );
            case 6007:
                return getIndentControl(editorId, defaultStyles, tabId, isControlDisabled, source);
            case 6008:
                return getOutdentControl(editorId, defaultStyles, tabId, isControlDisabled, source);
            case 6020:
                return getLeftToRightControl(
                    editorId,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    source
                );
            case 6021:
                return getRightToLeftControl(
                    editorId,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    source
                );
            case 6025:
                return getFontColorPickerControl(
                    editorId,
                    formatViewState,
                    tabId,
                    splitButtonStyles,
                    isControlDisabled,
                    source
                );
            case 6026:
                return getFontBackColorPickerControl(
                    editorId,
                    formatViewState,
                    tabId,
                    splitButtonStyles,
                    isControlDisabled,
                    source
                );
            case 6027:
                return getFontPickerControl(
                    editorId,
                    formatViewState,
                    tabId,
                    isControlDisabled,
                    viewState.rawComposeFormat?.fontName,
                    source
                );
            case 6028:
                return getFontSizePickerControl(
                    editorId,
                    formatViewState,
                    tabId,
                    isControlDisabled,
                    viewState.rawComposeFormat?.fontSize,
                    source
                );
            case 6029:
                return getFormatPainterControl(
                    editorId,
                    formatViewState,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    source
                );
            case 6030:
                return getRemoveFormatControl(
                    editorId,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    source
                );
            case 6073:
                return getStylePickerControl(
                    editorId,
                    formatViewState.isBlockQuote ?? false,
                    formatViewState.headerLevel ?? -1,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    source
                );
            case 6075:
                return getSpacingControl(
                    editorId,
                    defaultStyles,
                    formatViewState,
                    tabId,
                    isControlDisabled,
                    source
                );

            case 637:
                return getTableControl(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    tabId,
                    defaultStyles,
                    isControlDisabled,
                    source
                );
            case 620:
                return getAttachFileControl(
                    viewState,
                    retrieveCommonInfoForRibbon,
                    props.attachmentPickers.addFilesAsAttachments, // Cannot be undefined, because shouldShowRibbonControl checks for this
                    tabId,
                    !viewState ||
                        isApprovalResponse(viewState) ||
                        isControlDisabled /* isDisabled */,
                    undefined /* iconColor */,
                    defaultStyles
                );
            case 506:
                return getAttachSigControl(
                    editorId,
                    viewState,
                    retrieveCommonInfoForRibbon,
                    tabId,
                    defaultStyles,
                    isControlDisabled
                );
            case 638:
                return getAddInlineImagesControl(
                    editorId,
                    addInlineImageCallback,
                    defaultStyles /* styles */,
                    isControlDisabled,
                    tabId
                );
            case 639:
                return getAddEmoji(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    undefined /* iconColor */
                );
            case 7025:
                return getLinkUnlink(
                    editorId,
                    getComputedCallback(
                        7025,
                        onShowLinkDialogClicked,
                        composeId,
                        retrieveCommonInfoForRibbon
                    ),
                    tabId,
                    splitButtonStyles,
                    formatViewState,
                    isControlDisabled,
                    source
                );
            case 529:
                return getAddIns(editorId, defaultStyles, tabId, isControlDisabled);
            case 7016:
                return getMessageExtensions(
                    editorId,
                    viewState,
                    retrieveCommonInfoForRibbon,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 7021:
                return getPolls(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 7023:
                return getCopilotDrafts(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    defaultStyles,
                    tabId,
                    isControlDisabled,
                    isEncryptionEnabled(viewState)
                );
            case 7011:
                return getFindTime(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    'MailComposeView' /* source */,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 7000:
                return getFluidHero(
                    editorId,
                    composeId,
                    retrieveFluidInfoForRibbon,
                    defaultStyles,
                    getIsButtonDisabledForComposeTabs(controlId, composeId),
                    tabId,
                    viewState.mailboxInfo
                );
            case 7026:
                return getVideoRecorder(
                    editorId,
                    composeId,
                    retrieveFluidInfoForRibbon,
                    tabId,
                    viewState.mailboxInfo,
                    defaultStyles,
                    getIsButtonDisabledForComposeTabs(controlId, composeId)
                );
            case 560:
                return getSaveControl(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    tabId,
                    defaultStyles,
                    undefined /* iconColor */,
                    isControlDisabled
                );
            case 564:
                return getSensitivityControl(
                    editorId,
                    viewState,
                    retrieveCommonInfoForRibbon,
                    tabId,
                    defaultStyles,
                    isControlDisabled
                );
            case 7012:
                return getEncryptControl(
                    editorId,
                    viewState,
                    retrieveCommonInfoForRibbon,
                    tabId,
                    defaultStyles,
                    isControlDisabled
                );
            case 7004:
                return getAccChecker(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 7029:
                return getDictationToggle(
                    editorId,
                    composeId,
                    viewState.augloopSession,
                    viewState.mailboxInfo,
                    retrieveCommonInfoForRibbon,
                    splitButtonStyles,
                    tabId,
                    isControlDisabled
                );
            case 7002:
                const isPlainText = viewState.bodyType != 'HTML';
                return getSwitchBodyType(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    isPlainText,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 7001:
                const proofingDisabled =
                    viewState.proofing?.isProofingDisabled || isControlDisabled;
                return getProofingOptions(
                    viewState.proofing,
                    editorId,
                    proofingDisabled,
                    defaultStyles,
                    tabId,
                    viewState.mailboxInfo
                );
            case 7010:
                return getShowMessageOptions(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 7005:
                return getHighImportance(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    viewState.importance,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 7006:
                return getLowImportance(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    viewState.importance,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 7013:
                return getSendLater(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 7009:
                return getShowFrom(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 7019:
                return getShowCcBcc(
                    true /* isCc */,
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 7020:
                return getShowCcBcc(
                    false /* isCc */,
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 7014:
                return getReadOrDeliveryReceipt(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    defaultStyles,
                    tabId,
                    true /* isReadReceipt */,
                    isControlDisabled
                );
            case 7015:
                return getReadOrDeliveryReceipt(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    defaultStyles,
                    tabId,
                    false /* isReadReceipt */,
                    isControlDisabled
                );
            case 7031:
                return getDisallowReactions(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 6072:
                return getAutoFormatSettings(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 7003:
                return getToggleDarkMode(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );

            /**************** contextual tab controls ****************/
            case 6049:
                return getTableOperationControl(
                    editorId,
                    controlId,
                    formatViewState,
                    defaultStyles,
                    tableControlIcon,
                    undefined /* afterExecuteCallback */,
                    tabId,
                    undefined /* menuId */
                );
            case 6042:
            case 6054:
            case 6057:
                return getTableOperationMenu(
                    editorId,
                    controlId,
                    formatViewState,
                    defaultStyles,
                    undefined /* afterExecuteCallback */,
                    tableControlIcon,
                    tabId
                );
            case 6058:
            case 6059:
            case 6060:
            case 6061:
            case 6045:
            case 6043:
            case 6044:
            case 6050:
            case 6051:
            case 6052:
            case 6053:
            case 6055:
            case 6056:
                return getTableOperationControl(
                    editorId,
                    controlId,
                    formatViewState,
                    defaultStyles,
                    tableControlIcon,
                    undefined /* afterExecuteCallback */,
                    tabId,
                    undefined /* menuId */
                );
            case 6046:
                return getTablePredefinedStyleIconMenu(editorId, tabId);

            case 6037:
                return getTableFormatStateMenu(
                    editorId,
                    viewState.ribbon,
                    defaultStyles,
                    TableSettingsRegular,
                    tabId
                );
            case 6047:
                let cellShadingStyles: any;
                if (isSingleLineRibbon) {
                    cellShadingStyles = getDefaultSlrSplitButtonAppRibbonButtonStyles();
                    if (cellShadingStyles.root) {
                        cellShadingStyles.root.marginLeft = 3;
                    }
                } else {
                    cellShadingStyles = {
                        splitButtonContainer: {
                            marginLeft: 3,
                        },
                    };
                }

                return getCellShadingControl(editorId, formatViewState, tabId, cellShadingStyles);

            case 6087:
                return getTableAlignmentMenu(
                    editorId,
                    defaultStyles,
                    tableControlIcon,
                    tabId,
                    false /* isDisabled */
                );

            case 6098:
                return getTableBorderWeightControl(
                    editorId,
                    defaultStyles,
                    viewState.ribbon.tableBorderFormatState,
                    tabId,
                    false /* isDisabled */,
                    isSingleLineRibbon /* isSingleLineRibbon */
                );
            case 6097:
                return getTableBorderColorControl(
                    editorId,
                    defaultStyles,
                    viewState.ribbon.tableBorderFormatState,
                    tabId,
                    false /* isDisabled */
                );
            case 6100:
                return getApplyTableBorderControl(
                    editorId,
                    composeId,
                    defaultStyles,
                    retrieveCommonInfoForRibbon,
                    tabId,
                    false /* isDisabled */
                );

            case 6079:
                return getPictureBorderControl(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    defaultStyles,
                    viewState.ribbon.pictureFormatState,
                    tabId,
                    isControlDisabled
                );
            case 6080:
                return getPictureShadowControl(
                    editorId,
                    viewState.ribbon.pictureFormatState,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 6081:
                return getPictureSizeControl(
                    editorId,
                    viewState.ribbon.pictureFormatState,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 6082:
                return getPictureRotateControl(editorId, defaultStyles, tabId, isControlDisabled);
            case 6083:
                return getPictureCropControl(editorId, defaultStyles, tabId, isControlDisabled);
            case 6084:
                return getPictureChangeControl(
                    editorId,
                    addInlineImageCallback,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 6085:
                return getAltTextControl(editorId, defaultStyles, tabId, isControlDisabled);

            case 12011:
                return getDrawCanvas(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    drawViewState,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 12010:
                return getDrawTouch(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    drawViewState,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 12004:
                return getLasso(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    drawViewState,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 12003:
                return getSelect(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    drawViewState,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 12001:
                return getUndo(editorId, defaultStyles, tabId, formatViewState, isControlDisabled);
            case 12002:
                return getRedo(editorId, defaultStyles, tabId, formatViewState, isControlDisabled);
            case 12005:
                return getEraser(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    drawViewState,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 12006:
                return getBlackPen(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    drawViewState,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 12007:
                return getRedPen(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    drawViewState,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 12008:
                return getEffectPen(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    drawViewState,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 12009:
                return getHighlighter(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    drawViewState,
                    defaultStyles,
                    tabId,
                    isControlDisabled
                );
            case 12012:
                return getTextPen(
                    editorId,
                    composeId,
                    retrieveCommonInfoForRibbon,
                    drawViewState,
                    defaultStyles,
                    tabId
                );

            default:
                errorThatWillCauseAlert(
                    'Unknown control: ' +
                        controlId +
                        ' searched for in getComposeTabsControlDefinition'
                );
                return undefined;
        }
    }
);
