import { ObservableMap } from 'mobx';
import { createStore } from 'satcheljs';
import type FluidLinkViewStore from './schema/FluidLinkViewStore';

const fluidLinkViewStore: FluidLinkViewStore = {
    linkViewStates: new ObservableMap({}),
    isManifestLoaded: false,
    viewStateWithLoopFocus: '',
    fluidMenuItems: undefined,
    failedHydrationIds: new Set(),
};

const store = createStore<FluidLinkViewStore>('fluidLinkViewStore', fluidLinkViewStore)();
export const getStore = () => store;
export default getStore;
