import type { ClientItem } from 'owa-mail-store';
import type { ClientItemId } from 'owa-client-ids';
import { isMeetingMessage } from 'owa-meeting-message-utils';
import type MeetingMessage from 'owa-service/lib/contract/MeetingMessage';
import getLatestNonDraftMeetingItemFromTableView from './getLatestNonDraftMeetingItemFromTableView';

export function getAssociatedCalendarItemId(item: ClientItem): ClientItemId | undefined {
    if (!isMeetingMessage(item)) {
        return undefined;
    }

    const meetingMessage = item as MeetingMessage;

    if (!meetingMessage?.AssociatedCalendarItemId) {
        return undefined;
    }

    return {
        Id: meetingMessage.AssociatedCalendarItemId.Id,
        mailboxInfo: item.MailboxInfo,
    };
}

export function getAssociatedCalendarItemIdForLatestNonDraftItem(
    conversationId: string
): ClientItemId | undefined {
    const lastItem = getLatestNonDraftMeetingItemFromTableView(conversationId);

    return lastItem ? getAssociatedCalendarItemId(lastItem) : undefined;
}
