import type ItemCLPInfo from 'owa-mail-store/lib/store/schema/ItemCLPInfo';

export default function getInitialItemCLPInfo(): ItemCLPInfo {
    return {
        nonTenantLabelString: '',
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (9,9): Type 'null' is not assignable to type 'CLPLabel'.
        // @ts-expect-error
        selectedLabel: null,
    };
}
