import { observer } from 'owa-mobx-react';
import setSummaryCardDisplayStatus from 'owa-mail-thread-summarization-content/lib/mutators/setSummaryCardDisplayStatus';
import React from 'react';
import loc from 'owa-localize';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import type { ClientItemId } from 'owa-client-ids';
import {
    logCardExpandedChanged,
    logCardInitialized,
} from 'owa-mail-thread-summarization-content/lib/telemetry/logEvent';
import isPopout from 'owa-popout-v2/lib/utils/isPopout';
import ProjectionContext from 'owa-popout-v2/lib/context/ProjectionContext';
import getSummaryCardDisplayStatus from 'owa-mail-thread-summarization-content/lib/selectors/getSummaryCardDisplayStatus';
import { Icon } from '@fluentui/react';
import type { IIconProps } from '@fluentui/react';
import { FluentButton } from 'owa-fluent-v9-shims';
import { card, headerContainer } from './ThreadSummarizationCards.scss';
import {
    header,
    compactHeader,
    collapsed,
    headerIcon,
    headerIconMedium,
    headerIconCompact,
    headerTitle,
    headerTitleCompact,
    headerName,
    headerNameCompact,
    headerButtonPrimary,
} from 'owa-mail-thread-summarization-content/lib/components/ThreadSummarizationContent.scss';
import { getIsCardDismissed } from '../selectors/getIsCardDismissed';
import setIsCardDismissed from '../mutators/setIsCardDismissed';
import { AriaRoles } from 'owa-accessibility';
import classNames from 'owa-classnames';
import { default as TextBulletListSquareSparkleRegular } from 'owa-fluent-icons-svg/lib/icons/TextBulletListSquareSparkleRegular';
import {
    cardHeaderText,
    dismissSummaryButtonAriaLabel,
    dismissSummaryButtonText,
} from 'owa-mail-thread-summarization-content/lib/components/ThreadSummarizationCard.locstring.json';

const cancelIcon: IIconProps = { iconName: 'Cancel' };

export default observer(function ThreadSummarizationBar({ id }: { id: ClientItemId }) {
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const headerRef = React.useRef<HTMLDivElement | null>(null);
    const targetWindow = React.useContext(ProjectionContext);
    const isRPPopout = isPopout(targetWindow);

    const summaryCardExpanded = getSummaryCardDisplayStatus(id.Id, isRPPopout);

    const onSummaryButtonClick = React.useCallback(() => {
        setSummaryCardDisplayStatus(id.Id, true /* isExpanded */, isRPPopout);
        logCardExpandedChanged(id, true);
    }, [id, id.Id, isRPPopout]);

    React.useEffect(() => {
        logCardInitialized(id);
        setSummaryCardDisplayStatus(id.Id, false /* !isExpanded  */, isRPPopout);
        setIsCardDismissed(false);
    }, [id, id.Id, isRPPopout]);

    const handleKeyDownEvent = React.useCallback((e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key == 'Enter') {
            onSummaryButtonClick();
        }
    }, []);

    const barDismissedClicked = React.useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setIsCardDismissed(true);
    }, []);

    const isBarDismissed = getIsCardDismissed();

    return (
        !summaryCardExpanded &&
        !isBarDismissed && (
            <div className={card} ref={containerRef}>
                <div className={headerContainer}>
                    <div
                        className={classNames(
                            header,
                            getDensityModeCssClass(undefined, undefined, compactHeader),
                            collapsed
                        )}
                        role={AriaRoles.button}
                        onClick={onSummaryButtonClick}
                        tabIndex={0}
                        onKeyDown={handleKeyDownEvent}
                        ref={headerRef}
                        aria-live={'polite'}
                        aria-label={undefined}
                    >
                        <span
                            className={classNames(
                                headerName,
                                getDensityModeCssClass(undefined, undefined, headerNameCompact)
                            )}
                        >
                            <Icon
                                className={classNames(
                                    headerIcon,
                                    getDensityModeCssClass(
                                        undefined,
                                        headerIconMedium,
                                        headerIconCompact
                                    )
                                )}
                                iconName={TextBulletListSquareSparkleRegular}
                            />
                            <span
                                className={classNames(
                                    headerTitle,
                                    getDensityModeCssClass(undefined, undefined, headerTitleCompact)
                                )}
                            >
                                <>{loc(cardHeaderText)}</>
                            </span>
                        </span>
                        <span>
                            <FluentButton
                                role={AriaRoles.button}
                                appearance="icon"
                                iconProps={cancelIcon}
                                className={headerButtonPrimary}
                                aria-label={loc(dismissSummaryButtonAriaLabel)}
                                title={loc(dismissSummaryButtonText)}
                                onClick={barDismissedClicked}
                            />
                        </span>
                    </div>
                </div>
            </div>
        )
    );
}, 'ThreadSummarizationBar');
