import type { Layout } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components/RibbonControlGroupMLR';
import type { RibbonGroupId } from 'owa-ribbon-ids';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { owaComputedFn } from 'owa-computed-fn';

export const getMLRPictureFormatTabGroupLayouts = owaComputedFn(
    function getMLRPictureFormatTabGroupLayouts(
        _group: RibbonGroupId,
        _props: ComposeRibbonControlDefProps
    ): Layout[] | undefined {
        //Picture tab has no group layouts
        return undefined;
    }
);
