import type { MailboxInfo } from 'owa-client-types';
import { makeGetRequest } from 'owa-ows-gateway';
import type { OcsRequestOptions, OcsSettingScope, OcsVersion } from './common';
import { makeOcsRequestUrl } from './common';

export async function readOcsSettings(
    mailboxInfo: MailboxInfo,
    version: OcsVersion,
    scope: OcsSettingScope,
    settings: string[],
    options?: OcsRequestOptions
): Promise<any[]> {
    const ocsRequestUrl = makeOcsRequestUrl(version, scope, settings);
    const requestHeaders = {
        'x-outlook-client': 'owa',
        ...options?.additionalHeaders,
    };
    const returnFullResponse = options?.returnFullResponse ?? false;

    const ocsResponse: any[] = await makeGetRequest(
        ocsRequestUrl,
        options?.correlationId,
        returnFullResponse,
        requestHeaders,
        true, // throwOnServiceError
        undefined, // throwOnServiceError
        undefined, // actionName
        mailboxInfo
    );

    // OCS settings names are supposed to be case insensitive, but OCS also currently preserves
    // the casing established in the client's patch request. And Outlook Win32 uses PascalCase
    // in their preftable, which bleeds through here. To reduce the number of places where code
    // would otherwise have to string.toLowerCase(), we'll proactively do that here.
    return ocsResponse.map(ocsResponseItem => ({
        ...ocsResponseItem,
        name: ocsResponseItem.name.toLowerCase(),
    }));
}
