import type { IButtonStyles } from '@fluentui/react';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { createAppButton, constructKeytip } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import ArrowRedoRegular from 'owa-fluent-icons-svg/lib/icons/ArrowRedoRegular';
import loc from 'owa-localize';
import { type DrawControlId } from 'owa-ribbon-ids/lib/drawControlId';
import { drawRedoText } from './drawControlStrings.locstring.json';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';
import type RibbonFormatState from 'owa-editor-ribbonplugin-store/lib/store/schema/RibbonFormatState';
import { type DrawAction } from 'owa-editor-graphic-plugin-types/lib/enums/DrawAction';

export const getRedo = owaComputedFn(
    (
        editorId: string,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        formatViewState: RibbonFormatState,
        isDisabled: boolean
    ): RibbonControlProps => {
        return createAppButton(
            getComposeRibbonId(12002, editorId),
            loc(drawRedoText),
            () => {
                callEditorApi(editorId, 'redo');
            },
            tabId ? constructKeytip([tabId], 12002) : undefined,
            ArrowRedoRegular,
            undefined /* iconColor */,
            styles,
            {
                disabled: isDisabled || !formatViewState.canRedo,
            }
        );
    }
);
