import type { Layout } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components/RibbonControlGroupMLR';
import {
    sections,
    singleRow,
    stack,
} from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components/RibbonControlGroupMLR/layoutTypings';
import { owaComputedFn } from 'owa-computed-fn';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import type { RibbonGroupId } from 'owa-ribbon-ids';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { type MailRibbonGroupId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';

export const getMLROptionsTabGroupLayouts = owaComputedFn(function getMLROptionsTabGroupLayouts(
    group: RibbonGroupId,
    props: ComposeRibbonControlDefProps
): Layout[] | undefined {
    const editorId = props.composeViewState?.editorId;
    switch (group) {
        case 172:
            return [
                sections(
                    singleRow(
                        getComposeRibbonId(7001, editorId).toString(),
                        getComposeRibbonId(7004, editorId).toString()
                    )
                ),
            ];
        case 124:
            return [sections(singleRow(getComposeRibbonId(7013, editorId).toString()))];
        case 175:
            return [
                sections(
                    singleRow(
                        stack(
                            getComposeRibbonId(7020, editorId).toString(),
                            getComposeRibbonId(7019, editorId).toString(),
                            getComposeRibbonId(7009, editorId).toString()
                        )
                    )
                ),
            ];
        case 176:
            return [
                sections(
                    singleRow(
                        stack(
                            getComposeRibbonId(7015, editorId).toString(),
                            getComposeRibbonId(7014, editorId).toString(),
                            getComposeRibbonId(7031, editorId).toString()
                        )
                    )
                ),
            ];
        case 167:
            return [
                sections(
                    singleRow(
                        getComposeRibbonId(7005, editorId).toString(),
                        getComposeRibbonId(7006, editorId).toString(),
                        getComposeRibbonId(564, editorId).toString(),
                        getComposeRibbonId(7012, editorId).toString()
                    )
                ),
            ];
        case 174:
            return [sections(singleRow(getComposeRibbonId(7002, editorId).toString()))];
        case 123:
            return [sections(singleRow(getComposeRibbonId(560, editorId).toString()))];

        case 162:
            return [
                sections(
                    singleRow(
                        getComposeRibbonId(6072, editorId).toString(),
                        getComposeRibbonId(7010, editorId).toString()
                    )
                ),
            ];

        default:
            return undefined;
    }
});
