import type { RibbonTabDefinitionBase } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { owaComputedFn } from 'owa-computed-fn';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import getMLRComposeTab from '../../../util/getMLRComposeTab';
import { getMLROptionsTabCommonGroupFlyout } from './getMLROptionsTabCommonGroupFlyout';
import { getMLROptionsTabGroupLayouts } from './getMLROptionsTabGroupLayouts';

const emptyCtrlList: RibbonControlId[] = [];

const getMLROptionsTab = owaComputedFn(function getMLROptionsTab(
    props: ComposeRibbonControlDefProps
): RibbonTabDefinitionBase {
    return getMLRComposeTab(
        props,
        11,
        emptyCtrlList,
        getMLROptionsTabGroupLayouts,
        getMLROptionsTabCommonGroupFlyout
    );
});
export default getMLROptionsTab;
