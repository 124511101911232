import { shorthands, type GriffelStyle } from '@griffel/react';

export const colors = {
    //== Theme Colors
    //

    '$ms-color-themeDarker': 'var(--themeDarker)',
    '$ms-color-themeDark': 'var(--themeDark)',
    '$ms-color-themeDarkAlt': 'var(--themeDarkAlt)',
    '$ms-color-themePrimary': 'var(--themePrimary)',
    '$ms-color-themeSecondary': 'var(--themeSecondary)',
    '$ms-color-themeSecondarySurfaceSelected': 'var(--themeSecondarySurfaceSelected)',
    '$ms-color-themeTertiary': 'var(--themeTertiary)',
    '$ms-color-themeLight': 'var(--themeLight)',
    '$ms-color-themeLighter': 'var(--themeLighter)',
    '$ms-color-themeLighterAlt': 'var(--themeLighterAlt)',
    '$ms-color-headerImage': 'var(--headerImage)',
    '$ms-color-headerImageStatic': 'var(--headerImageStatic)',
    '$ms-color-headerBackground': 'var(--headerBackground)',
    '$ms-color-headerBackgroundSearch': 'var(--headerBackgroundSearch)',
    '$ms-color-headerBrandText': 'var(--headerBrandText)',
    '$ms-color-headerTextIcons': 'var(--headerTextIcons)',
    '$ms-color-headerSearchBoxBackground': 'var(--headerSearchBoxBackground)',
    '$ms-color-headerSearchBoxIcon': 'var(--headerSearchBoxIcon)',
    '$ms-color-headerSearchPlaceholderText': 'var(--headerSearchPlaceholderText)',
    '$ms-color-headerSearchButtonBackground': 'var(--headerSearchButtonBackground)',
    '$ms-color-headerSearchButtonBackgroundHover': 'var(--headerSearchButtonBackgroundHover)',
    '$ms-color-headerSearchButtonIcon': 'var(--headerSearchButtonIcon)',
    '$ms-color-headerSearchFilters': 'var(--headerSearchFilters)',
    '$ms-color-headerSearchFiltersHover': 'var(--headerSearchFiltersHover)',
    '$ms-color-headerButtonsBackground': 'var(--headerButtonsBackground)',
    '$ms-color-headerButtonsBackgroundHover': 'var(--headerButtonsBackgroundHover)',
    '$ms-color-headerButtonsBackgroundSearch': 'var(--headerButtonsBackgroundSearch)',
    '$ms-color-headerButtonsBackgroundSearchHover': 'var(--headerButtonsBackgroundSearchHover)',
    '$ms-color-headerBadgeBackground': 'var(--headerBadgeBackground)',
    '$ms-color-headerBadgeText': 'var(--headerBadgeText)',
    //== New colors required for dark themes to function properly but to keep certain design intents where light always needs to be light
    //
    '$ms-color-richUserContentBackground': 'var(--richUserContentBackground)',
    '$ms-color-neutralDark': 'var(--neutralDark)',
    '$ms-color-neutralPrimary': 'var(--neutralPrimary)',
    '$ms-color-neutralPrimaryAlt': 'var(--neutralPrimaryAlt)',
    '$ms-color-neutralPrimarySurface': 'var(--neutralPrimarySurface)',
    '$ms-color-neutralSecondary': 'var(--neutralSecondary)',
    '$ms-color-neutralSecondaryAlt': 'var(--neutralSecondaryAlt)',
    '$ms-color-neutralSecondarySurface': 'var(--neutralSecondarySurface)',
    '$ms-color-neutralTertiary': 'var(--neutralTertiary)',
    '$ms-color-neutralTertiaryAlt': 'var(--neutralTertiaryAlt)',
    '$ms-color-neutralTertiarySurface': 'var(--neutralTertiarySurface)',
    '$ms-color-neutralQuaternary': 'var(--neutralQuaternary)',
    '$ms-color-neutralQuaternaryAlt': 'var(--neutralQuaternaryAlt)',
    '$ms-color-neutralLight': 'var(--neutralLight)',
    '$ms-color-neutralLighter': 'var(--neutralLighter)',
    '$ms-color-neutralLighterAlt': 'var(--neutralLighterAlt)',
    '$ms-color-white': 'var(--white)',
    '$ms-color-black': 'var(--black)',
    '$ms-color-blackTranslucent50': 'var(--blackTranslucent50)',
    '$ms-color-blackTranslucent40': 'var(--blackTranslucent40)',
    '$ms-color-blackTranslucent20': 'var(--blackTranslucent20)',
    '$ms-color-blackTranslucent10': 'var(--blackTranslucent10)',
    '$ms-color-whiteTranslucent90': 'var(--whiteTranslucent90)',
    '$ms-color-whiteTranslucent40': 'var(--whiteTranslucent40)',
    '$ms-color-gray30': 'var(--gray30)',
    '$ms-color-redDark': 'var(--redDark)',
    '$ms-color-sharedGreen20': 'var(--green20)',
    '$ms-color-purple': 'var(--purple)',
    '$ms-color-suiteuxNeutralLight': 'var(--suiteuxNeutralLight)',
    '$ms-color-suiteuxNeutralSecondary': 'var(--suiteuxNeutralSecondary)',
    '$ms-color-highlightYellow': 'var(--highlightYellow, rgba(255, 241, 0, 0.3))',
    '$ms-color-highlightYellowLight': 'var(--highlightYellowLight, rgba(255, 241, 0, 0.16))',
    '$ms-color-darkGray': 'var(--darkGray)',
    '$ms-color-dimGray': 'var(--dimGray)',
    '$ms-color-orangeDark': 'var(--orangeDark)',
    // == New color aliases to match fluent names
    //
    '$ms-color-grey190': 'var(--neutralDark)',
    '$ms-color-grey160': 'var(--neutralPrimary)',
    '$ms-color-grey150': 'var(--neutralPrimaryAlt)',
    '$ms-color-grey130': 'var(--neutralSecondary)',
    '$ms-color-grey90': 'var(--neutralTertiary)',
    '$ms-color-grey60': 'var(--neutralTertiaryAlt)',
    '$ms-color-grey50': 'var(--neutralQuaternary)',
    '$ms-color-grey40': 'var(--neutralQuaternaryAlt)',
    '$ms-color-grey30': 'var(--neutralLight)',
    '$ms-color-grey20': 'var(--neutralLighter)',
    '$ms-color-grey10': 'var(--neutralLighterAlt)',
    // == Sticky note colors
    //
    '$ms-color-stickyTextColorDark': 'var(--stickyTextColorDark)',
    '$ms-color-stickyTextColorLight': 'var(--stickyTextColorLight)',
    '$ms-color-stickyBackgroundColorDark': 'var(--stickyBackgroundColorDark)',
    '$ms-color-stickyBackgroundColorLight': 'var(--stickyBackgroundColorLight)',
    //==OWA default theme color (for branding scenarios that should not be theme-aware
    '$owa-color-defaultOutlookTheme': '#0078d4',
    //
    //== OWA colors for meeting messages
    //
    '$owa-color-cal-green': 'var(--green)',
    //== Other unique OWA colors
    //
    '$owa-color-flagged-message': 'var(--flaggedMessage)',
    '$owa-color-readingPane-card-border': 'var(--readingPaneCardBorder)',
    '$owa-color-readingPane-card-focus-border': 'var(--readingPaneCardFocusBorder)',

    // OOBE colors.
    // OOBE has the definite color that is not subject to the change of light/dark mode or theme changes
    '$ms-color-oobe-white': 'var(--oobeWhite)',
    '$ms-color-oobe-primary': 'var(--oobePrimary)',
    '$ms-color-oobe-darkAlt': 'var(--oobeDarkAlt)',
    '$ms-color-oobe-lighter': 'var(--oobeThemeLighter)',

    // Quick compose and peek background color. White in light mode and gray140 in dark mode.
    '$ms-color-card-styleWhite': 'var(--cardStyleWhite)',

    //Calendar surface grid colors
    '$owa-color-surface-gridLight': 'var(--neutralTertiaryAlt)',
    '$owa-color-surface-gridDark': 'var(--neutralTertiaryAlt)',
    // Temporary grid color before the flight fwk-newDarkPalette rolls out
    '$owa-color-surface-oldGridDark': 'var(--neutralQuaternaryAlt)',

    // OOF color
    '$ms-color-freeBusyAwayColor': 'var(--freeBusyAwayColor)',

    // https://www.figma.com/file/c5TF4jwqrpms2PSQH3EXtFTc/Fluent-Color?node-id=3301%3A115
    // https://www.figma.com/file/c5TF4jwqrpms2PSQH3EXtFTc/Fluent-Color?node-id=3273%3A435
    '$ms-color-messageWebWarning': 'var(--messageWebWarning)',
    '$ms-color-messageMobileWarningPrimary': 'var(--messageMobileWarningPrimary)',
    '$ms-color-messageMobileWarningShade20': 'var(--messageMobileWarningShade20)',
    '$ms-color-messageMobileWarningShade30': 'var(--messageMobileWarningShade30)',
    '$ms-color-messageMobileWarningShade40': 'var(--messageMobileWarningShade40)',
    '$ms-color-messageMobileWarningTint30': 'var(--messageMobileWarningTint30)',
    '$ms-color-messageMobileWarningTint40': 'var(--messageMobileWarningTint40)',
    '$ms-color-communicationBlueWebPrimary': 'var(--communicationBlueWebPrimary)',
    '$ms-color-messageMobileDangerPrimary': 'var(--messageMobileDangerPrimary)',
    '$ms-color-messageMobileSuccessPrimary': 'var(--messageMobileSuccessPrimary)',
    '$ms-color-messageMobileDangerShade20': 'var(--messageMobileDangerShade20)',
    '$ms-color-messageMobileDangerTint30': 'var(--messageMobileDangerTint30)',
    '$ms-color-messageMobileDangerTint40': 'var(--messageMobileDangerTint40)',
    '$ms-color-presenceAway': 'var(--presenceAway)',
    '$ms-color-presenceDoNotDisturb': 'var(--presenceDoNotDisturb)',
    '$ms-color-presenceOnline': 'var(--presenceOnline)',
    '$ms-color-presenceInvisible': 'var(--presenceInvisible)',

    // Addison component picker button gradient
    '$ms-color-addisonComponentButtonGradientStart': 'var(--addisonComponentButtonGradientStart)',
    '$ms-color-addisonComponentButtonGradientEnd': 'var(--addisonComponentButtonGradientEnd)',

    '$ms-color-storageCriticalStateColor': 'var(--storageCritialStateColor)',
    '$ms-color-storageNearingStateColor': 'var(--storageNearingStateColor)',
    '$ms-color-storageCriticalStateHoverColor': 'var(--storageCritialStateHoverColor)',
    '$ms-color-storageNearingStateHoverColor': 'var(--storageNearingStateHoverColor)',
    '$ms-color-storageUsageBarBorder': 'var(--storageUsageBarBorder)',

    '$ms-color-bizBarRed': 'var(--bizBarRed)',
    '$ms-color-msqOneDrive': 'var(--msqOneDrive)',
    '$ms-color-msqTeams': 'var(--msqTeams)',
    '$ms-color-msqAvailable': 'var(--msqAvailable)',
    '$ms-color-msqOutlook': 'var(--msqOutlook)',
    '$ms-color-msqWindows': 'var(--msqWindows)',
    '$ms-color-msqEdge': 'var(--msqEdge)',

    //Places Explore Colors
    '$ms-color-placesPrimary': 'var(--placesPrimary)',

    // Places linear gradient section
    '$ms-color-placesLightBlueSolidColor': 'var(--placesLightBlueSolidColor)',
    '$ms-color-placesNavyBlueSolidColor': 'var(--placesNavyBlueSolidColor)',

    // Places theme colors
    '$ms-color-placesBackgroundColorPink': 'var(--placesBackgroundColorPink)',
    '$ms-color-placesBackgroundColorPurple': 'var(--placesBackgroundColorPurple)',
    '$ms-color-placesBackgroundColorLightBlue': 'var(--placesBackgroundColorLightBlue)',
    '$ms-color-placesBackgroundColorNavy': 'var(--placesBackgroundColorNavy)',
    '$ms-color-placesBluePrimary': 'var(--placesBluePrimary)',
    '$ms-color-placesBlueTint40': 'var(--placesBlueTint40)',
    '$ms-color-placesLightBackground3': 'var(--placesLightBackground3)',
    '$ms-color-placesLightBlueBackground': 'var(--placesLightBlueBackground)',
    '$ms-color-placesNeutralGrey92': 'var(--placesNeutralGrey92)',
    '$ms-color-placesBlueGradient1': 'var(--placesBlueGradient1)',
    '$ms-color-placesBlueGradient2': 'var(--placesBlueGradient2)',
    '$ms-color-placesBlueBackground1': 'var(--placesBlueBackground1)',
    '$ms-color-placesBlueForeground1': 'var(--placesBlueForeground1)',
    '$ms-color-placesBlueBorder1': 'var(--placesBlueBorder1)',
    '$ms-color-placesCornflowerForeground1': 'var(--placesCornflowerForeground1)',
    '$ms-color-placesCornflowerForeground2': 'var(--placesCornflowerForeground2)',
    '$ms-color-placesRemote1': 'var(--placesRemote1)',
    '$ms-color-placesRemoteBorder1': 'var(--placesRemoteBorder1)',
    '$ms-color-placesPurpleOOFBackground1': 'var(--placesPurpleOOFBackground1)',
    '$ms-color-placesPurpleOOFForeground1': 'var(--placesPurpleOOFForeground1)',
    '$ms-color-placesPurpleGradient1': 'var(--placesPurpleGradient1)',

    '$ms-color-placesLavenderTint10': 'var(--placesLavenderTint10)',
    '$ms-color-placesCornflowerTint20': 'var(--placesCornflowerTint20)',
    '$ms-color-placesCornflowerTint30': 'var(--placesCornflowerTint30)',
    '$ms-color-placesCornflowerTint50': 'var(--placesCornflowerTint50)',
    '$ms-color-placesCornflowerTint60': 'var(--placesCornflowerTint60)',
    '$ms-color-places-80': 'var(--places80)',
    '$ms-color-places-80Pressed': 'var(--places80Pressed)',
    '$ms-color-paneLighterBackground': 'var(--paneLighterBackground)',

    // Colors for home page in Bookings
    '$ms-color-bookingsTeal10': 'var(--bookingsTeal10)',
    '$ms-color-bookingsTeal20': 'var(--bookingsTeal20)',
    '$ms-color-bookingsTeal50': 'var(--bookingsTeal50)',
    '$ms-color-bookingsTeal80': 'var(--bookingsTeal80)',
    '$ms-color-bookingsAccentGreen10': 'var(--bookingsAccentGreen10)',
    '$ms-color-bookingsAccentGreen20': 'var(--bookingsAccentGreen20)',
    '$ms-color-bookingsForegroundTeal60': 'var(--bookingsForegroundTeal60)',
    '$ms-color-bookingsForegroundTeal80': 'var(--bookingsForegroundTeal80)',
    '$ms-color-bookingsNeutralForeground1': 'var(--bookingsNeutralForeground1)',
    '$ms-color-bookingsNeutralForeground2': 'var(--bookingsNeutralForeground2)',
    '$ms-color-bookingsNeutralForeground4': 'var(--bookingsNeutralForeground4)',
    '$ms-color-bookingsRedForeground1': 'var(--bookingsRedForeground1)',
    '$ms-color-bookingsNeutralStroke1': 'var(--bookingsNeutralStroke1)',
    '$ms-color-bookingsNeutralBackground1': 'var(--bookingsNeutralBackground1)',
    '$ms-color-bookingsGreenConsistent': 'var(--bookingsGreenConsistent)',
    '$ms-color-bookingsGrayConsistent': 'var(--bookingsGrayConsistent)',

    //Box shadow colors
    '$ms-color-boxShadowPrimary': 'var(--boxShadowPrimary)',
    '$ms-color-boxShadowSecondary': 'var(--boxShadowSecondary)',

    $msWordBrandColorLightBlue: 'rgb(30, 139, 205)',

    '$ms-color-yellow': 'var(--yellow)',
    '$ms-color-orange': 'var(--orange)',
    '$ms-color-red': 'var(--red)',
    '$ms-color-blue': 'var(--blue)',
    '$ms-color-green': 'var(--green)',

    //Copilot gradient colors
    '$ms-color-copilotGradientStart': 'var(--copilotGradientStart)',
    '$ms-color-copilotGradientEnd': 'var(--copilotGradientEnd)',

    //Draw tab colors
    '$ms-color-drawCanvasBackground': 'var(--colorNeutralForegroundStaticInverted)',
    '$ms-color-drawCanvasOutline': 'var(--colorNeutralForeground3)',
    '$ms-color-drawCanvasAnchorBackground': 'var(--colorNeutralForegroundStaticInverted)',
    '$ms-color-drawCanvasAnchorBorder': 'var(--colorNeutralForeground3)',
    '$ms-color-drawCanvasBorder': 'var(--colorNeutralForeground3)',

    // Fabric colors
    '$ms-color-blueLight': '#00bcf2',
    '$ms-color-error': '$ms-color-redDark',
    '$ms-color-errorBackground': '#fde7e9',

    // Communication
    '$ms-color-communicationShade30': '#004578',
    '$ms-color-communicationShade20': '#005a9e',
    '$ms-color-communicationShade10': '#106ebe',
    '$ms-color-communicationPrimary': '#0078d4',
    '$ms-color-communicationTint10': '#2b88d8',
    '$ms-color-communicationTint20': '#c7e0f4',
    '$ms-color-communicationTint30': '#deecf9',
    '$ms-color-communicationTint40': '#eff6fc',

    // Neutrals
    '$ms-color-gray220': '#11100f',
    '$ms-color-gray210': '#161514',
    '$ms-color-gray200': '#1b1a19',
    '$ms-color-gray190': '#201f1e',
    '$ms-color-gray180': '#252423',
    '$ms-color-gray170': '#292827',
    '$ms-color-gray160': '#323130',
    '$ms-color-gray150': '#3b3a39',
    '$ms-color-gray140': '#484644',
    '$ms-color-gray130': '#605e5c',
    '$ms-color-gray120': '#797775',
    '$ms-color-gray110': '#8a8886',
    '$ms-color-gray100': '#979593',
    '$ms-color-gray90': '#a19f9d',
    '$ms-color-gray80': '#b3b0ad',
    '$ms-color-gray70': '#bebbb8',
    '$ms-color-gray60': '#c8c6c4',
    '$ms-color-gray50': '#d2d0ce',
    '$ms-color-gray40': '#e1dfdd',
    '$ms-color-gray20': '#f3f2f1',
    '$ms-color-gray10': '#faf9f8',

    // Shared
    '$ms-color-sharedPinkRed10': '#750b1c',
    '$ms-color-sharedRed20': '#a4262c',
    '$ms-color-sharedRed10': '#d13438',
    '$ms-color-sharedRedOrange20': '#603d30',
    '$ms-color-sharedRedOrange10': '#da3b01',
    '$ms-color-sharedOrange30': '#8e562e',
    '$ms-color-sharedOrange20': '#ca5010',
    '$ms-color-sharedOrange10': '#ffaa44',
    '$ms-color-sharedYellow10': '#fce100',
    '$ms-color-sharedOrangeYellow20': '#986f0b',
    '$ms-color-sharedOrangeYellow10': '#c19c00',
    '$ms-color-sharedYellowGreen10': '#8cbd18',
    '$ms-color-sharedGreen10': '#498205',
    '$ms-color-sharedGreenCyan10': '#00ad56',
    '$ms-color-sharedCyan40': '#005e50',
    '$ms-color-sharedCyan30': '#005b70',
    '$ms-color-sharedCyan20': '#038387',
    '$ms-color-sharedCyan10': '#00b7c3',
    '$ms-color-sharedCyanBlue20': '#004e8c',
    '$ms-color-sharedCyanBlue10': '#0078d4',
    '$ms-color-sharedBlue10': '#4f6bed',
    '$ms-color-sharedBlueMagenta40': '#373277',
    '$ms-color-sharedBlueMagenta30': '#5c2e91',
    '$ms-color-sharedBlueMagenta20': '#8764b8',
    '$ms-color-sharedBlueMagenta10': '#8378de',
    '$ms-color-sharedMagenta20': '#881798',
    '$ms-color-sharedMagenta10': '#c239b3',
    '$ms-color-sharedMagentaPink20': '#9b0062',
    '$ms-color-sharedMagentaPink10': '#e3008c',
    '$ms-color-sharedGray40': '#393939',
    '$ms-color-sharedGray30': '#7a7574',
    '$ms-color-sharedGray20': '#69797e',
    '$ms-color-sharedGray10': '#a0aeb2',

    // Accent
    // Where possible, these colors have been mapped to the new palette.
    '$ms-color-yellowLight': '#fff100',
    '$ms-color-orangeLight': '#ea4300',
    '$ms-color-orangeLighter': '#ff8c00',
    '$ms-color-magentaDark': '#5c005c',
    '$ms-color-magenta': '#b4009e',
    '$ms-color-magentaLight': '$ms-color-sharedMagentaPink10',
    '$ms-color-purpleDark': '#32145a',
    '$ms-color-purpleLight': '#b4a0ff',
    '$ms-color-blueDark': '#002050',
    '$ms-color-blueMid': '#00188f',
    '$ms-color-tealDark': '#004b50',
    '$ms-color-teal': '#008272',
    '$ms-color-tealLight': '#00b294',
    '$ms-color-greenDark': '#004b1c',
    '$ms-color-greenLight': '#bad80a',

    '$ms-color-neutralForeground4': 'var(--neutralForeground4)',

    // Eventify
    '$ms-color-eventifySurveyStatusIncompleteColor': 'var(--eventifySurveyStatusIncompleteColor)',
    '$ms-color-eventifySurveyStatusIncompleteBackground':
        'var(--eventifySurveyStatusIncompleteBackground)',
    '$ms-color-eventifyClosedSurveyBannerBorder': 'var(--eventifyClosedSurveyBannerBorder)',
    '$ms-color-eventifyClosedSurveyBannerColor': 'var(--eventifyClosedSurveyBannerColor)',
    '$ms-color-eventifySurveyListItemBorder': 'var(--eventifySurveyListItemBorder)',
    '$ms-color-eventifySurveyStatusCompleteBackground':
        'var(--eventifySurveyStatusCompleteBackground)',
    '$ms-color-eventifySurveyStatusCompleteColor': 'var(--eventifySurveyStatusCompleteColor)',
    '$ms-color-eventifyEventPreviewColor': 'var(--eventifyEventPreviewColor)',
    '$ms-color-eventifyEventCancelledBackground': 'var(--eventifyEventCancelledBackground)',
    '$ms-color-eventifyEventCancelledBorder': 'var(--eventifyEventCancelledBorder)',
    '$ms-color-eventifyEventCancelledColor': 'var(--eventifyEventCancelledColor)',
};

export const fontWeights = {
    '$ms-font-weight-light': 100,
    '$ms-font-weight-semilight': 300,
    '$ms-font-weight-regular': 400,
    '$ms-font-weight-semibold': 600,
    '$ms-font-weight-bold': 700,
};

export const fontSizes = {
    '$ms-font-size-10': '10px',
    '$ms-font-size-12': '12px',
    '$ms-font-size-14': '14px',
    '$ms-font-size-16': '16px',
    '$ms-font-size-18': '18px',
    '$ms-font-size-20': '20px',
    '$ms-font-size-32': '32px',
    '$ms-font-size-l': '17px',
    '$ms-font-size-m': '14px',
    '$ms-font-size-m-plus': '15px',
    '$ms-font-size-mi': '10px',
    '$ms-font-size-s': '12px',
    '$ms-font-size-xl': '21px',
    '$ms-font-size-xs': '11px',
    '$ms-font-size-xxl': '28px',
};

export const shadowDepths = {
    '$ms-depth-shadow-0': '0 0 0 0 transparent',
    '$ms-depth-shadow-4':
        '0 6.4px 14.4px 0 rgba(0, 0, 0, 0.132), 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.108)',
    '$ms-depth-shadow-8':
        '0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108)',
    '$ms-depth-shadow-16':
        '0 1.6px 3.6px 0 rgba(0, 0, 0, 0.132), 0 0.3px 0.9px 0 rgba(0, 0, 0, 0.108)',
    '$ms-depth-shadow-64':
        '0 25.6px 57.6px 0 rgba(0, 0, 0, 0.22), 0 4.8px 14.4px 0 rgba(0, 0, 0, 0.18)',
};

export const fontFamily = {
    '$ms-font-family-west-european':
        "'Segoe UI Web (West European)','Segoe UI', -apple-system, BlinkMacSystemFont, 'Roboto', 'Helvetica Neue', sans-serif",
};

export const mixins: Record<string, () => GriffelStyle> = {
    ['ms-font-l']() {
        return {
            fontSize: fontSizes['$ms-font-size-l'],
            fontWeight: fontWeights['$ms-font-weight-semilight'],
        };
    },
    ['ms-font-m']() {
        return {
            fontSize: fontSizes['$ms-font-size-m'],
            fontWeight: fontWeights['$ms-font-weight-regular'],
        };
    },
    ['ms-font-m-plus']() {
        return {
            fontSize: fontSizes['$ms-font-size-m-plus'],
            fontWeight: fontWeights['$ms-font-weight-regular'],
        };
    },
    ['ms-font-mi']() {
        return {
            fontSize: fontSizes['$ms-font-size-mi'],
            fontWeight: fontWeights['$ms-font-weight-semibold'],
        };
    },
    ['ms-font-s']() {
        return {
            fontSize: fontSizes['$ms-font-size-s'],
            fontWeight: fontWeights['$ms-font-weight-regular'],
        };
    },
    ['ms-font-xl']() {
        return {
            fontSize: fontSizes['$ms-font-size-xl'],
            fontWeight: fontWeights['$ms-font-weight-light'],
        };
    },
    ['ms-font-xs']() {
        return {
            fontSize: fontSizes['$ms-font-size-xs'],
            fontWeight: fontWeights['$ms-font-weight-regular'],
        };
    },
    ['ms-font-xxl']() {
        return {
            fontSize: fontSizes['$ms-font-size-xxl'],
            fontWeight: fontWeights['$ms-font-weight-light'],
        };
    },
    customScrollBar() {
        const incrementDecrementStyles = {
            backgroundPosition: 'center',
            height: '18px',
            width: '18px',
        };
        return {
            /** CSS scrollbars Level 1 styles **/
            /** https://drafts.csswg.org/css-scrollbars-1/ **/
            scrollbarColor: '$ms-color-neutralTertiaryAlt transparent',
            scrollbarWidth: 'thin',

            /** Webkit scrollbar psudoselector styles **/
            '::-webkit-scrollbar-button:horizontal:increment': {
                ...incrementDecrementStyles,
                backgroundImage:
                    'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEXM09b///8OmaLeAAAAAnRSTlP/AOW3MEoAAAAgSURBVHgBY2BEA+QLMGAIMGAIMGAIMBAUIGQoZU5HAwB+ZgDtGYq3fgAAAABJRU5ErkJggg==)',
            },
            '::-webkit-scrollbar-button:horizontal:increment:hover': {
                ...incrementDecrementStyles,
                backgroundImage:
                    'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEWdtMT///8YSU3FAAAAAnRSTlP/AOW3MEoAAAAgSURBVHgBY2BEA+QLMGAIMGAIMGAIMBAUIGQoZU5HAwB+ZgDtGYq3fgAAAABJRU5ErkJggg==)',
            },

            '::-webkit-scrollbar-button:horizontal:decrement': {
                ...incrementDecrementStyles,
                backgroundImage:
                    'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEXM09b///8OmaLeAAAAAnRSTlP/AOW3MEoAAAAiSURBVHgBY2BEAxQJMKAJMKAJMKAJMOATgIkQMBTBooZfAH56AO2yINTrAAAAAElFTkSuQmCC)',
            },
            '::-webkit-scrollbar-button:horizontal:decrement:hover': {
                ...incrementDecrementStyles,
                backgroundImage:
                    'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEWdtMT///8YSU3FAAAAAnRSTlP/AOW3MEoAAAAiSURBVHgBY2BEAxQJMKAJMKAJMKAJMOATgIkQMBTBooZfAH56AO2yINTrAAAAAElFTkSuQmCC)',
            },

            '::-webkit-scrollbar-button:vertical:increment': {
                ...incrementDecrementStyles,
                backgroundImage:
                    'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEXM09b///8OmaLeAAAAAnRSTlP/AOW3MEoAAAAfSURBVHgBY2BEA7QTYIAChAoYH6EFxkeYwQBj0MelAH3GAO2C9+AaAAAAAElFTkSuQmCC)',
            },
            '::-webkit-scrollbar-button:vertical:increment:hover': {
                ...incrementDecrementStyles,
                backgroundImage:
                    'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEWdtMT///8YSU3FAAAAAnRSTlP/AOW3MEoAAAAfSURBVHgBY2BEA7QTYIAChAoYH6EFxkeYwQBj0MelAH3GAO2C9+AaAAAAAElFTkSuQmCC)',
            },

            '::-webkit-scrollbar-button:vertical:decrement': {
                ...incrementDecrementStyles,
                backgroundImage:
                    'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEXM09b///8OmaLeAAAAAnRSTlP/AOW3MEoAAAAgSURBVHgBY2BEAzQVYIAzYHwGGAvOZ4AyIXyECH1cCgB/GgDtpWTgqAAAAABJRU5ErkJggg==)',
            },
            '::-webkit-scrollbar-button:vertical:decrement:hover': {
                ...incrementDecrementStyles,
                backgroundImage:
                    'url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAABlBMVEWdtMT///8YSU3FAAAAAnRSTlP/AOW3MEoAAAAgSURBVHgBY2BEAzQVYIAzYHwGGAvOZ4AyIXyECH1cCgB/GgDtpWTgqAAAAABJRU5ErkJggg==)',
            },

            '::-webkit-scrollbar': {
                height: '18px',
                width: '18px',
            },

            '::-webkit-scrollbar:disabled': {
                display: 'none',
            },

            '::-webkit-scrollbar-button': {
                background: 'transparent',
                cursor: 'pointer',
            },
            '::-webkit-scrollbar-corner': {
                background: 'transparent',
                cursor: 'pointer',
            },

            '::-webkit-scrollbar-track': {
                background: 'transparent',
            },

            '::-webkit-scrollbar-thumb': {
                borderRadius: '9px',
                ...shorthands.border('6px', 'solid', 'transparent'),
                backgroundClip: 'content-box',
                backgroundColor: 'var(--neutralTertiaryAlt)',
            },

            '::-webkit-scrollbar-thumb:hover': {
                backgroundColor: '#98a3a6', // hard-coded for scrollbar
            },

            '::-webkit-scrollbar-thumb:vertical': {
                minHeight: '50px',
            },

            '::-webkit-scrollbar-thumb:horizontal': {
                minWidth: '50px',
            },
        };
    },
    ellipsisOnOverflow() {
        return {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            ...shorthands.overflow('hidden'),
        };
    },
    flexColumn() {
        return {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'nowrap',
            ...shorthands.overflow('hidden'),
            ...shorthands.flex(1, 1, 'auto'),
        };
    },
    flexRow() {
        return {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
            ...shorthands.overflow('hidden'),
            alignItems: 'center',
        };
    },
    center() {
        return {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        };
    },
    allowTextSelection() {
        return {
            userSelect: 'text',
            cursor: 'auto',
        };
    },
    disableTextSelection() {
        return {
            userSelect: 'none',
            cursor: 'default',
        };
    },
    removeButtonStyles() {
        return {
            backgroundImage: 'none',
            ...shorthands.borderStyle('none'),
            color: 'inherit',
            paddingBottom: '0',
            paddingTop: '0',
            fontFamily: 'inherit',
            fontSize: 'inherit',
            cursor: 'pointer',
        };
    },
    'owa-dropShadow'() {
        return {
            boxShadow: '1px 1px 6px 0 $ms-color-blackTranslucent40',
        };
    },

    useThemeFallbackColors() {
        return {
            '--themeDarker': 'var(--fallback-themeDarker)',
            '--themeDark': 'var(--fallback-themeDark)',
            '--themeDarkAlt': 'var(--fallback-themeDarkAlt)',
            '--themePrimary': 'var(--fallback-themePrimary)',
            '--themeSecondary': 'var(--fallback-themeSecondary)',
            '--themeTertiary': 'var(--fallback-themeTertiary)',
            '--themeLight': 'var(--fallback-themeLight)',
            '--themeLighter': 'var(--fallback-themeLighter)',
            '--themeLighterAlt': 'var(--fallback-themeLighterAlt)',
            '--themeSecondarySurfaceSelected': 'var(--fallback-themeSecondarySurfaceSelected)',
        };
    },

    useHeaderFallbackColors() {
        return {
            '--headerBackground': 'var(--fallback-headerBackground)',
            '--headerBackgroundSearch': 'var(--fallback-headerBackgroundSearch)',
            '--headerBrandText': 'var(--fallback-headerBrandText)',
            '--headerTextIcons': 'var(--fallback-headerTextIcons)',
            '--headerSearchBoxBackground': 'var(--fallback-headerSearchBoxBackground)',
            '--headerSearchBoxIcon': 'var(--fallback-headerSearchBoxIcon)',
            '--headerSearchPlaceholderText': 'var(--fallback-headerSearchPlaceholderText)',
            '--headerSearchButtonBackground': 'var(--fallback-headerSearchButtonBackground)',
            '--headerSearchButtonBackgroundHover':
                'var(--fallback-headerSearchButtonBackgroundHover)',
            '--headerSearchButtonIcon': 'var(--fallback-headerSearchButtonIcon)',
            '--headerSearchFilters': 'var(--fallback-headerSearchFilters)',
            '--headerSearchFiltersHover': 'var(--fallback-headerSearchFiltersHover)',
            '--headerButtonsBackground': 'var(--fallback-headerButtonsBackground)',
            '--headerButtonsBackgroundHover': 'var(--fallback-headerButtonsBackgroundHover)',
            '--headerButtonsBackgroundSearch': 'var(--fallback-headerButtonsBackgroundSearch)',
            '--headerButtonsBackgroundSearchHover':
                'var(--fallback-headerButtonsBackgroundSearchHover)',
            '--headerBadgeBackground': 'var(--fallback-headerBadgeBackground)',
            '--headerBadgeText': 'var(--fallback-headerBadgeText)',
            '--headerSearchIcon': 'var(--fallback-headerSearchIcon)',
        };
    },

    useNeutralFallbackColors() {
        return {
            '--black': 'var(--fallback-black)',
            '--blackTranslucent40': 'var(--fallback-blackTranslucent40)',
            '--white': 'var(--fallback-white)',
            '--whiteTranslucent40': 'var(--fallback-whiteTranslucent40)',
            '--whiteTranslucent90': 'var(--fallback-whiteTranslucent90)',
            '--neutralDark': 'var(--fallback-neutralDark)',
            '--neutralPrimary': 'var(--fallback-neutralPrimary)',
            '--neutralPrimaryAlt': 'var(--fallback-neutralPrimaryAlt)',
            '--neutralPrimarySurface': 'var(--fallback-neutralPrimarySurface)',
            '--neutralSecondary': 'var(--fallback-neutralSecondary)',
            '--neutralSecondarySurface': 'var(--fallback-neutralSecondarySurface)',
            '--neutralTertiary': 'var(--fallback-neutralTertiary)',
            '--neutralTertiaryAlt': 'var(--fallback-neutralTertiaryAlt)',
            '--neutralTertiarySurface': 'var(--fallback-neutralTertiarySurface)',
            '--neutralQuaternary': 'var(--fallback-neutralQuaternary)',
            '--neutralQuaternaryAlt': 'var(--fallback-neutralQuaternaryAlt)',
            '--neutralLight': 'var(--fallback-neutralLight)',
            '--neutralLighter': 'var(--fallback-neutralLighter)',
            '--neutralLighterAlt': 'var(--fallback-neutralLighterAlt)',
        };
    },

    useSharedFallbackColors() {
        return {
            '--redDark': 'var(--fallback-redDark)',
            '--purple': 'var(--fallback-purple)',
        };
    },

    useMessageMobileFallbackColors() {
        return {
            '--messageMobileDangerPrimary': 'var(--fallback-messageMobileDangerPrimary)',
            '--messageMobileDangerShade20': 'var(--fallback-messageMobileDangerShade20)',
            '--messageMobileDangerTint30': 'var(--fallback-messageMobileDangerTint30)',
            '--messageMobileDangerTint40': 'var(--fallback-messageMobileDangerTint40)',
            '--messageMobileSuccessPrimary': 'var(--fallback-messageMobileSuccessPrimary)',
            '--messageMobileWarningPrimary': 'var(--fallback-messageMobileWarningPrimary)',
            '--messageMobileWarningShade20': 'var(--fallback-messageMobileWarningShade20)',
            '--messageMobileWarningShade30': 'var(--fallback-messageMobileWarningShade30)',
            '--messageMobileWarningShade40': 'var(--fallback-messageMobileWarningShade40)',
            '--messageMobileWarningTint30': 'var(--fallback-messageMobileWarningTint30)',
            '--messageMobileWarningTint40': 'var(--fallback-messageMobileWarningTint40)',
        };
    },

    useFallbackColorsWithoutReset() {
        return {
            ...this.useThemeFallbackColors(),
            ...this.useHeaderFallbackColors(),
            ...this.useNeutralFallbackColors(),
            ...this.useSharedFallbackColors(),
        };
    },

    useFallbackColors() {
        return {
            ...this.useFallbackColorsWithoutReset(),
            color: 'var(--neutralPrimary)',
            backgroundColor: 'var(--white)',
        };
    },
};
