import { LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailDatePicker" */ './lazyIndex')
);

export const lazyShowScheduleDatePickerDialog = new LazyAction(
    lazyModule,
    m => m.showScheduleDatePickerDialog
);
