import type { MailboxInfo } from 'owa-client-types';
import { mutatorAction } from 'satcheljs';
import type { AccountConfiguration } from '../store/schema/OwaSessionStore';
import { getAccountSettingsInternal } from '../utils/getAccountSettingsInternal';

export const updateAccountScopeUserSettings: (
    mailboxInfo: MailboxInfo,
    updateFunction: (accountScopeUserSettings: AccountConfiguration) => void
) => void = mutatorAction(
    'updateAccountScopeUserSettings',
    (
        mailboxInfo: MailboxInfo,
        updateFunction: (accountScopeUserSettings: AccountConfiguration) => void
    ) => {
        const settings = getAccountSettingsInternal(mailboxInfo);
        if (settings) {
            updateFunction(settings);
        }
    }
);
