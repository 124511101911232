import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppFlyoutAnchor } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import LockClosed from 'owa-fluent-icons-svg/lib/icons/LockClosedRegular';
import loc from 'owa-localize';
import type { MailRibbonTabId } from 'owa-ribbon-ids';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { encrypt_FlyoutButtonText } from '../strings/actionControlStrings.locstring.json';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import { encryptDesc } from '../strings/hoverTooltipDesc.locstring.json';
import { onEncryptMenuClicked } from '../actions/deferredMenuDefinitionMutator';
import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import shouldDisableEncryptionButton from 'owa-mail-protection/lib/utils/rms/shouldDisableEncryptionButton';
import type { ComposeViewState } from 'owa-mail-compose-store';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';

export const getEncryptControl = owaComputedFn(
    (
        editorId: string,
        viewState: ComposeViewState,
        retriever: (composeId: string) => RibbonMailComposeInfo | null,
        tabId: MailRibbonTabId | undefined,
        styles: IButtonStyles | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        const composeId = viewState.composeId;
        const { protectionViewState } = viewState;
        const controlText = loc(encrypt_FlyoutButtonText);
        const storeName = 'encryptMenuDefinitionStore';
        return createAppFlyoutAnchor(
            getComposeRibbonId(7012, editorId),
            controlText,
            getControlMenuStore(editorId, storeName),
            tabId ? constructKeytip([tabId], 7012, true /* hasMenu */) : undefined,
            LockClosed,
            undefined /* iconColor */,
            styles,
            {
                customTooltip: getHoverTooltip(encrypt_FlyoutButtonText, encryptDesc),
                disabled:
                    isDisabled ||
                    shouldDisableEncryptionButton(protectionViewState, viewState.mailboxInfo),
                onExecute: () => {
                    onEncryptMenuClicked(editorId, composeId, retriever);
                },
                onAfterMenuDismiss: () => {
                    resetMenuDefinition(editorId, storeName);
                    callEditorApi(editorId, 'focus');
                },
            }
        );
    }
);
