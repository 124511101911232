import type { ItemReadingPaneProps } from 'owa-mail-reading-pane-view';
import {
    SAVE_SCROLL_DEBOUNCE,
    UnsupportedItemReadingPane,
    SubjectHeader,
    ItemReadingPaneContent,
} from 'owa-mail-reading-pane-view';
import shouldSendViewMessageSignal from 'owa-mail-reading-pane-view/lib/utils/shouldSendViewMessageSignal';
import {
    composeContainer,
    isNewestOnTop,
    isFocused,
    senderImage,
    inlineCompose,
} from 'owa-mail-reading-pane-view/lib/components/InlineComposeStyles.scss';

import {
    scrollRegion as styles_scrollRegion,
    scrollRegionWillChangeScrollPosition,
    noOverlay,
    scrollRegionBottomBuffer,
} from 'owa-mail-reading-pane-view/lib/components/ReadingPane.scss';

import SupportedItemReadingPaneLoadedContent from './SupportedItemReadingPaneLoadedContent';
import debounce from 'lodash/debounce';
import { observer } from 'owa-mobx-react';
import { lazyLogSigsDatapoint } from 'owa-sigs';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getApplicationSettings } from 'owa-application-settings';
import { lazyGetAdditionalPropertiesFromServer, lazyCleanUpByItemId } from 'owa-get-item-manager';
import { isGroupTableQuery } from 'owa-group-utils';
import { getGuid } from 'owa-guid';
import { useKeydownHandler, useKeydownHandlers } from 'owa-hotkeys';
import type { InitialActions } from 'owa-initial-actions';
import { createInitialActions, executeInitialActions } from 'owa-initial-actions';
import loc from 'owa-localize';
import { itemHeaderForward } from 'owa-locstrings/lib/strings/itemheaderforward.locstring.json';
import { itemHeaderReply } from 'owa-locstrings/lib/strings/itemheaderreply.locstring.json';
import { itemHeaderReplyAll } from 'owa-locstrings/lib/strings/itemheaderreplyall.locstring.json';
import closeImmersiveReadingPane from 'owa-mail-actions/lib/closeImmersiveReadingPane';
import { userMailInteractionAction } from 'owa-mail-actions/lib/triage/userMailInteractionAction';
import { lazyForwardMessage, lazyReplyToMessage } from 'owa-mail-compose-actions';
import findInlineComposeViewState from 'owa-mail-compose-actions/lib/utils/findInlineComposeViewState';
import type { ComposeViewState } from 'owa-mail-compose-store';
import { Compose } from 'owa-mail-compose-view';
import { ExtendedCardWrapper } from 'owa-mail-extended-card';
import { type FocusComponent, registerComponent } from 'owa-mail-focus-manager';
import { getCommands } from 'owa-mail-hotkeys/lib/utils/MailModuleHotKeys';
import {
    isReadingPanePositionOff,
    isReadingPanePositionBottom,
} from 'owa-mail-layout/lib/selectors/readingPanePosition';
import { shouldShowListView } from 'owa-mail-layout/lib/selectors/shouldShowListView';
import getSelectedTableViewId from 'owa-mail-list-store/lib/utils/getSelectedTableViewId';
import initItemLocale from 'owa-mail-reading-pane-view/lib/actions/translation/initItemLocale';
import { saveReadingPaneScrollPosition } from 'owa-mail-reading-pane-store/lib/mutators/saveReadingPaneScrollPositionMutator';
import type ItemReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemReadingPaneViewState';
import type LoadingState from 'owa-mail-reading-pane-store/lib/store/schema/LoadingState';
import getDefaultDisposalType from 'owa-mail-reading-pane-store/lib/utils/getDefaultDisposalType';
import SenderImage from 'owa-mail-sender-persona-view/lib/components/SenderImage';
import mailStore from 'owa-mail-store/lib/store/Store';
import getItemRightsManagementRestrictions from 'owa-mail-store/lib/utils/getItemRightsManagementRestrictions';
import { lazyLoadActiveVotingProviders } from 'owa-mail-voting';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import { useCustomAnimationFrame } from 'owa-react-hooks/lib/useCustomAnimationFrame';
import { useCustomTimeout } from 'owa-react-hooks/lib/useCustomTimeout';
import type FlagStatus from 'owa-service/lib/contract/FlagStatus';
import type Message from 'owa-service/lib/contract/Message';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import { lazyIsSmimeItemDecoding } from 'owa-smime';
import type SmimeType from 'owa-smime-adapter/lib/store/schema/SmimeType';
import getSmimeType from 'owa-smime/lib/utils/getSmimeType';
import isSMIMEItem from 'owa-smime/lib/utils/isSMIMEItem';
import showActionBlockedDialog from 'owa-smime/lib/utils/showActionBlockedDialog';
import { errorThatWillCauseAlert } from 'owa-trace';
import React from 'react';
import onArchive from 'owa-mail-commands/lib/actions/onArchive';
import { logCoreUsage } from 'owa-analytics';
import type ExtendedCardViewState from 'owa-mail-reading-pane-store/lib/store/schema/ExtendedCardViewState';
import { ExtendedCardType } from 'owa-mail-reading-pane-store/lib/store/schema/ExtendedCardViewState';
import {
    getSelectedTableView,
    isItemOfMessageType,
    shouldSuppressServerMarkReadOnReplyOrForward,
} from 'owa-mail-list-store';
import {
    lazyClearAutoMarkAsReadTimer,
    lazySetAutoMarkAsReadTimer,
    lazyMarkItemAsReadFromReadingPane,
} from 'owa-mail-mark-read-actions';
import {
    lazyDeleteItems,
    lazyMarkItemJunkNotJunkFromReadingPane,
    lazySetItemsFlagStateFromItemIds,
} from 'owa-mail-triage-action';
import {
    isExtendedCardCoveringOriginalContent,
    getExtendedCardViewState,
    hasExtendedCard,
} from 'owa-mail-reading-pane-store/lib/utils/extendedCardUtils';
import ProjectionContext from 'owa-popout-v2/lib/context/ProjectionContext';
import getTabIdFromProjection from 'owa-popout-v2/lib/utils/getTabIdFromProjection';
import { reloadMailReadingPanePopoutAsDeeplink } from 'owa-mail-reading-pane-tabs';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { lazyClosePopout } from 'owa-popout-v2';
import getGroupRecipientsFromItem from 'owa-mail-reading-pane-view/lib/utils/getGroupRecipientsFromItem';
import getMyOrgGroups from 'owa-groups-left-nav-store/lib/selectors/getMyOrgGroups';
import {
    full,
    medium,
    compact,
    subjectContainer,
    subject as itemReadingPaneStyles_subject,
    isSpecialCaseCard,
    neutralPaletteBackground,
    blockContainer,
    flexContainer,
    noOverlay as itemReadingPaneStyles_noOverlay,
    outsideCardWrapperContainer,
    isCollapsed,
    itemContainerFirefox,
    calendarCardV2Container,
    attendeePanelContainer,
} from './ItemReadingPane.scss';

import { isBrowserSafari, isBrowserFirefox } from 'owa-user-agent/lib/userAgent';

import classNames from 'owa-classnames';
import readingPaneStore from 'owa-mail-reading-pane-store/lib/store/Store';
import { PrintPanel } from 'owa-mail-reading-pane-view-print';
import { getTabIdFromTargetWindow } from 'owa-mail-reading-pane-store/lib/utils/getTabIdFromTargetWindow';

import {
    getZoomStyle,
    logEmbiggenZoomSettingDatapoint,
    onWheelZoom,
    onCtrlZoom,
} from 'owa-custom-zoom';
import getCurrentZoomScale from 'owa-custom-zoom-store/lib/utils/getCurrentZoomScale';
import ZoomContext from 'owa-custom-zoom-store/lib/context/ZoomContext';
import { SubjectHeaderV2 } from 'owa-mail-subject-header-v2';
import { LazySubjectHeaderV3 } from 'owa-mail-subject-header-v3';
import type { YammerCardViewState } from 'owa-yammer-thread-scenario';
import { FluentButtonContextProvider } from 'owa-fluent-v9-context';
import { isCopilotFeatureEnabled } from 'owa-copilot-settings-utils';
import { lazySetSummaryButtonDisplayStatus } from 'owa-mail-thread-summarization-content';
import { lazyCreateThreadSummarizationViewState } from 'owa-mail-reading-pane-store';
import {
    ThreadSummarizationCard,
    ThreadSummarizationCardForHeaderEntryPoint,
} from 'owa-mail-thread-summarization';
import type { ThreadSummarizationCardViewState } from 'owa-mail-thread-summarization-content/lib/store/schema/ThreadSummarizationCardViewState';
import { AttendeePanel } from 'owa-meeting-message';
import type { CalendarEvent } from 'owa-calendar-types';
import doesFolderIdEqualName from 'owa-session-store/lib/utils/doesFolderIdEqualName';
import getSelectedFolder from 'owa-mail-store/lib/utils/getSelectedFolder';
import ThreadSummarizationBar from 'owa-mail-thread-summarization/lib/components/ThreadSummarizationBar';
import { RSVPWithNote } from 'owa-mail-calendar-card';
import { setRsvpToOrganizerWithNote } from 'owa-mail-reading-pane-store/lib/mutators/setRsvpToOrganizerWithNote';

const ITEM_APP_SECTION = 'ItemContainer';

const subjectHeaderStyles = {
    margin: `8px 20px 8px 2px`,
};

const source = 'ItemReadingPane';

export default observer(function ItemReadingPane(props: ItemReadingPaneProps) {
    const { itemReadingPaneViewState, isItemAttachment, isSxS, itemId } = props;
    const { itemPrintPaneViewStates } = readingPaneStore;
    const mailboxInfo = itemId?.mailboxInfo ?? getModuleContextMailboxInfo();
    const item = mailStore.items.get(itemId?.Id);
    const [setFocusTimer] = useCustomTimeout(source);
    const [scrollToTopTask] = useCustomAnimationFrame(source);
    const targetWindow = React.useContext(ProjectionContext);
    const tabId = getTabIdFromTargetWindow(targetWindow);
    const itemPrintPaneViewState = itemPrintPaneViewStates.get(tabId);
    const currentZoomScale = getCurrentZoomScale();
    const isBottomReadingPane = isReadingPanePositionBottom();

    const [showAttendeePanel, setShowAttendeePanel] = React.useState<boolean>(false);
    const [calendarEvent, setCalendarEvent] = React.useState<CalendarEvent | undefined>(undefined);
    const [attendeePanelFocus, setAttendeePanelFocus] = React.useState<boolean>(false);

    const showAttendeePanelRef = React.useRef<boolean>(showAttendeePanel);
    const attendeePanelRef = React.useRef<HTMLDivElement>();

    const handleSetShowAttendeePanel = React.useCallback(() => {
        setShowAttendeePanel(!showAttendeePanelRef.current);
        setAttendeePanelFocus(!showAttendeePanelRef.current);
    }, [showAttendeePanel]);

    const handleCalendarEventLoaded = React.useCallback(
        (event: CalendarEvent) => {
            setCalendarEvent(event);
            if (event.IsOrganizer && itemReadingPaneViewState) {
                setShowAttendeePanel(true);
            } else if (itemReadingPaneViewState) {
                setShowAttendeePanel(false);
            }
        },
        [calendarEvent]
    );

    React.useEffect(() => {
        showAttendeePanelRef.current = showAttendeePanel;
    }, [showAttendeePanel]);

    // reset AttendeePanel expansion state on navigating away
    React.useEffect(() => {
        if (itemReadingPaneViewState) {
            setShowAttendeePanel(false);
        }
    }, [itemReadingPaneViewState?.itemId]);

    React.useEffect(() => {
        window.addEventListener('beforeunload', readingPaneDisplayEnd);
        window.addEventListener('visibilitychange', readingPaneVisibilityChange);
        unregisterComponent.current = registerComponent('ReadingPane', setFocusOnReadingPane);
        if (!readingPaneContainer.current) {
            errorThatWillCauseAlert('readingPaneContainer should not be null');
        }
        tryFinishRenderNewItem();

        return () => {
            // Clean up any pending timers when unmounting
            lazyClearAutoMarkAsReadTimer.importAndExecute();
            if (unregisterComponent.current) {
                unregisterComponent.current();
            }

            if (scrollRegion.current) {
                scrollRegion.current.removeEventListener('scroll', onScrollRegionScroll);
            }
            window.removeEventListener('beforeunload', readingPaneDisplayEnd);
            window.removeEventListener('visibilitychange', readingPaneVisibilityChange);
        };
    }, []);

    const isRenderingNewItem = React.useRef<boolean>(true);
    const scrollRegion = React.useRef<HTMLDivElement>();
    const readingPaneContainer = React.useRef<HTMLDivElement | null>(null);
    const readingPaneDisplayStartDate = React.useRef<Date>();
    const composeInitialActions = React.useRef<InitialActions>(createInitialActions());
    const unregisterComponent = React.useRef<() => void>();
    const internetMessageId = React.useRef<string>();
    const viewSessionGuid = React.useRef<string>();
    const [lastGetItemManagerRequestId, setLastGetItemManagerRequestId] = React.useState<
        string | undefined
    >(undefined);

    const projectionRPTabId = getTabIdFromProjection(targetWindow) ?? undefined;
    const inlineComposeViewState = useComputedValue((): ComposeViewState | undefined => {
        return isFeatureEnabled('cmp-remove-compose-sxs') && isSxS
            ? undefined
            : findInlineComposeViewState(
                  itemId?.Id,
                  1,
                  false /*includeDelaySend*/,
                  projectionRPTabId
              );
    }, [itemId?.Id, projectionRPTabId]);

    const isFluentv9ButtonsEnabled = isFeatureEnabled('fwk-fluent-v9-button-reading-pane');

    React.useEffect(() => {
        isRenderingNewItem.current = true;
        readingPaneDisplayStart();
        return () => readingPaneDisplayEnd();
    }, [itemId?.Id]);

    React.useEffect(() => {
        if (item) {
            internetMessageId.current = (item as Message).InternetMessageId;
        }
    }, [item]);

    React.useEffect(() => {
        if (isRenderingNewItem.current) {
            // Clear mark as read timer if it's still pending when switching to a new item
            lazyClearAutoMarkAsReadTimer.importAndExecute();
            tryFinishRenderNewItem();
        } else if (inlineComposeViewState) {
            executeInitialActions(composeInitialActions.current);
        }
    });

    React.useEffect(() => {
        const groups = getGroupRecipientsFromItem(props.itemId?.Id, mailboxInfo);
        if (groups.length > 0) {
            logCoreUsage('GroupMailRead', {
                noOfGroups: getMyOrgGroups(mailboxInfo).length,
                mailboxType: mailboxInfo.type,
                listViewType: 1,
            });
        }
    }, [props.itemId?.Id, mailboxInfo]);

    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const setReadingPaneContainerRef = (ref: HTMLDivElement) => {
        if (ref) {
            readingPaneContainer.current = ref;
            // Ctrl + wheel for controlling embiggen/custom zoom
            readingPaneContainer.current.addEventListener('wheel', onWheelZoom);
            readingPaneContainer.current.addEventListener('keydown', onCtrlZoom);
        } else {
            if (readingPaneContainer.current) {
                readingPaneContainer.current.removeEventListener('wheel', onWheelZoom);
                readingPaneContainer.current.removeEventListener('keydown', onCtrlZoom);
                readingPaneContainer.current = null;
            }
        }
    };

    /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
     * Baseline, please do not copy and paste this justification
     *	> JSX attribute values should not contain functions created in the same scope */
    const setScrollRegionRef = (ref: HTMLDivElement | null) => {
        if (ref) {
            scrollRegion.current = ref;
            scrollRegion.current.addEventListener('scroll', onScrollRegionScroll);
        } else {
            if (scrollRegion.current) {
                scrollRegion.current.removeEventListener('scroll', onScrollRegionScroll);
                scrollRegion.current = undefined;
            }
        }
    };

    const setAttendeePanelRef = React.useCallback((ref: HTMLDivElement) => {
        if (ref) {
            attendeePanelRef.current = ref;
        } else {
            if (attendeePanelRef.current) {
                attendeePanelRef.current = undefined;
            }
        }
    }, []);

    React.useEffect(() => {
        if (attendeePanelFocus) {
            setFocusOnAttendeePanel();
        }
    }, [attendeePanelFocus]);

    const saveScrollPosition = React.useCallback(() => {
        if (scrollRegion.current) {
            saveReadingPaneScrollPosition(itemReadingPaneViewState, scrollRegion.current);
        }
    }, [itemReadingPaneViewState]);

    const debouncedSaveScrollPosition = React.useMemo(
        () => debounce(saveScrollPosition, SAVE_SCROLL_DEBOUNCE),
        [saveScrollPosition]
    );

    const onScrollRegionScroll = React.useCallback(() => {
        if (!isSxS) {
            debouncedSaveScrollPosition();
        }
    }, [itemReadingPaneViewState, isSxS]);

    const loadingState = useComputedValue((): LoadingState => {
        // Create a local loadingState here so isLoading can take itemId into consideration.
        // If the itemId in props is out of sync with the store, we're loading a new item.
        // When we render an item view that has inlineCompose in it, for loading we need to check the itemId and referenceItemId match.
        let isLoading: boolean = false;
        if (itemReadingPaneViewState) {
            if (inlineComposeViewState) {
                isLoading =
                    itemReadingPaneViewState.loadingState.isLoading ||
                    itemId?.Id != inlineComposeViewState.referenceItemId?.Id;
            } else {
                isLoading = itemReadingPaneViewState.loadingState.isLoading;
            }
        }
        return (
            itemReadingPaneViewState && {
                isLoading,
                hasLoadFailed: itemReadingPaneViewState.loadingState.hasLoadFailed,
                showLoadingShimmer: itemReadingPaneViewState.loadingState.showLoadingShimmer,
            }
        );
    }, [itemReadingPaneViewState?.loadingState, inlineComposeViewState, itemId?.Id]);

    /**
     * Callback when the reading pane gains focus from the focus manager
     */
    const setFocusOnReadingPane = (): boolean => {
        // set focus on message body if:
        // - popout scenario
        // - unstacked conversation mode
        if (props.isPopout || itemReadingPaneViewState?.conversationId) {
            targetWindow?.document
                ?.getElementById('UniqueMessageBody')
                ?.focus({ preventScroll: true });
            return true;
        } else if (scrollRegion.current) {
            scrollRegion.current.focus();
            return true;
        } else if (readingPaneContainer.current) {
            readingPaneContainer.current.focus();
            return true;
        } else {
            return false;
        }
    };

    const setFocusOnAttendeePanel = (): boolean => {
        if (attendeePanelRef.current) {
            attendeePanelRef.current?.focus();
            return true;
        } else {
            return false;
        }
    };

    const isGroupItem = () => {
        return mailboxInfo?.type == 'GroupMailbox';
    };
    const tryFinishRenderNewItem = () => {
        if (isRenderingNewItem.current) {
            if (isCurrentItemLoaded()) {
                isRenderingNewItem.current = false;
                if (props.onLoad) {
                    props.onLoad();
                }

                // Scroll to top after a new message is rendered.
                scrollToTopTask(() => {
                    if (scrollRegion.current) {
                        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
                         * Baseline, please provide a proper justification if touching this code
                         *	> 'scrollTop' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
                        scrollRegion.current.scrollTop =
                            itemReadingPaneViewState.savedScrollPosition
                                ? itemReadingPaneViewState.savedScrollPosition
                                : 0;
                    }
                });

                // On first render of reading pane content, set focus on reading pane if needed
                if (isReadingPanePositionOff() || props.isPopout) {
                    // We care reflow for initial render. Set a timer to let reflow unblock UI rendering.
                    setFocusTimer(() => {
                        setFocusOnReadingPane();
                    }, 0);
                }
                if (isItemOfMessageType(item)) {
                    lazySetAutoMarkAsReadTimer.importAndExecute(itemId?.Id);
                }
                if ((item as Message)?.VotingInformation) {
                    lazyLoadActiveVotingProviders
                        .import()
                        .then(loadActiveVotingProviders =>
                            loadActiveVotingProviders(itemId?.Id, item as Message, mailboxInfo)
                        );
                }
            } else if (isCurrentItemLoadedFailed() && props.onLoadFailed) {
                props.onLoadFailed();
            }
        }
    };
    const isCurrentItemLoaded = () => {
        return (
            itemReadingPaneViewState &&
            itemReadingPaneViewState.itemId == itemId?.Id && // Make sure store is in sync with component
            !itemReadingPaneViewState.loadingState.isLoading &&
            !itemReadingPaneViewState.loadingState.hasLoadFailed
        );
    };

    const currentItemLoaded = isCurrentItemLoaded();

    React.useEffect(() => {
        if (
            !currentItemLoaded ||
            isItemAttachment ||
            !item ||
            lastGetItemManagerRequestId == item?.ItemId?.Id
        ) {
            return;
        }

        lazyGetAdditionalPropertiesFromServer.import().then(getAdditionalPropertiesFromServer => {
            getAdditionalPropertiesFromServer(itemId?.Id, 1);
            setLastGetItemManagerRequestId(itemId?.Id);
            // Initialize item locale for inline translation
            initItemLocale(item, itemReadingPaneViewState.itemViewState);
        });
    }, [currentItemLoaded, isItemAttachment, item, lastGetItemManagerRequestId]);

    const isCurrentItemLoadedFailed = () => {
        return (
            itemReadingPaneViewState &&
            itemReadingPaneViewState.itemId === itemId?.Id && // Make sure store is in sync with component
            itemReadingPaneViewState.loadingState.hasLoadFailed
        );
    };
    const isGetItemManagerRequestSentForCurrentItem = () => {
        return (
            isCurrentItemLoaded() &&
            lastGetItemManagerRequestId &&
            itemId?.Id &&
            lastGetItemManagerRequestId == itemId?.Id
        );
    };

    const isSingleLine = isReadingPanePositionOff();

    const createContainer = React.useCallback((): JSX.Element | null => {
        if (itemReadingPaneViewState?.isUnsupportedItem && !isItemAttachment) {
            return (
                <UnsupportedItemReadingPane
                    itemId={item?.ItemId?.Id ?? ''}
                    mailboxInfo={mailboxInfo}
                    isItemAttachment={isItemAttachment}
                />
            );
        }

        if (isCurrentItemLoaded()) {
            const irmRestrictions = getItemRightsManagementRestrictions(item);
            return (
                <SupportedItemReadingPaneLoadedContent
                    {...props}
                    forwardAllowed={irmRestrictions.ForwardAllowed}
                    copyAllowed={irmRestrictions.CopyAllowed}
                    printAllowed={irmRestrictions.PrintAllowed}
                    isGetItemManagerRequestSentForCurrentItem={
                        !!isGetItemManagerRequestSentForCurrentItem()
                    }
                    isSingleLineListView={isSingleLine}
                    maximizeScrollRegion={props.maximizeScrollRegion}
                />
            );
        }

        return null;
    }, [props, item, isSingleLine, isGetItemManagerRequestSentForCurrentItem()]);

    const getExtendedCards = React.useCallback(() => {
        let cardInScrollRegion = null;
        let cardOutsideScrollRegion = null;
        if (itemReadingPaneViewState) {
            const { extendedCardViewState } = itemReadingPaneViewState;
            if (extendedCardViewState) {
                const extendedCardWrapperElement = (
                    <ExtendedCardWrapper
                        key={`extendedCard_${itemId?.Id}`}
                        extendedCardViewState={extendedCardViewState}
                    />
                );
                if (
                    extendedCardViewState.displayPosition === 'inScrollRegionTop' ||
                    extendedCardViewState.displayPosition === 'inScrollRegionSort'
                ) {
                    cardInScrollRegion = extendedCardWrapperElement;
                } else {
                    cardOutsideScrollRegion = getOutsideExtendedCardWrapper(
                        extendedCardViewState,
                        extendedCardWrapperElement
                    );
                }
            }
        }
        return { cardInScrollRegion, cardOutsideScrollRegion };
    }, [itemReadingPaneViewState?.extendedCardViewState]);
    /**
     * Hot keys
     */
    // Close mail is tied to the esc key in certain key sets.
    // Only when we know we are exiting from the immersive mode
    // we stop propagation else we let the event to propagate

    const commands = getCommands();
    useKeydownHandlers(
        readingPaneContainer,
        React.useCallback(
            () => [
                {
                    command: commands.closeMail,
                    handler: (evt: KeyboardEvent) => {
                        if (!shouldShowListView()) {
                            evt.stopPropagation();
                            closeImmersiveReadingPane('Keyboard');
                        }
                    },
                    options: {
                        stopPropagation: false,
                    },
                },
                {
                    command: commands.archiveMail,
                    handler: () => {
                        const tableView = getSelectedTableView();

                        // tableView is null in case of deeplink.
                        // VSO 86013 tracks the absence of archive functionality in deeplink.
                        if (tableView) {
                            // archiving is disabled for groups
                            if (isGroupTableQuery(tableView.tableQuery)) {
                                return;
                            }
                            onArchive('Keyboard');
                        }
                    },
                },
            ],
            []
        )
    );

    const isResponseBlockedForSmimeDecoding = async (): Promise<boolean> => {
        const isSmimeItemDecoding = await lazyIsSmimeItemDecoding.import();
        return item ? isSmimeItemDecoding(item) : false;
    };
    const shouldBlockResponseForSmimeItem = async (): Promise<boolean> => {
        return isSMIMEItem(item) && !isConsumer() && isResponseBlockedForSmimeDecoding();
    };

    const reply = React.useCallback(async () => {
        // Block response to S/MIME item when item is decoding
        if (await shouldBlockResponseForSmimeItem()) {
            showActionBlockedDialog(loc(itemHeaderReply));
            return;
        }
        // Reply is disabled for groups and item previews
        if (
            !isGroupItem() &&
            !isItemAttachment &&
            getItemRightsManagementRestrictions(item).ReplyAllowed
        ) {
            replyReplyAllInternal(false /* isReplyAll */);
        }
    }, [itemHeaderReply, item, isItemAttachment, itemReadingPaneViewState]);
    useKeydownHandler(readingPaneContainer, commands.reply, reply);

    const replyAll = React.useCallback(async () => {
        // Block response to S/MIME item when item is decoding
        if (await shouldBlockResponseForSmimeItem()) {
            showActionBlockedDialog(loc(itemHeaderReplyAll));
            return;
        }

        // Reply all is disabled for item previews
        if (!isItemAttachment && getItemRightsManagementRestrictions(item).ReplyAllAllowed) {
            replyReplyAllInternal(true /* isReplyAll */);
        }
    }, [itemHeaderReplyAll, isItemAttachment, item, itemReadingPaneViewState]);
    useKeydownHandler(readingPaneContainer, commands.replyAll, replyAll);

    const replyReplyAllInternal = (isReplyAll: boolean) => {
        lazyReplyToMessage.importAndExecute({
            referenceItemOrId: item || itemId?.Id,
            mailboxInfo,
            isReplyAll,
            useFullCompose: true,
            actionSource: 'Keyboard',
            instrumentationContexts: getInstrumentationContext(),
            conversationId: undefined,
            suppressServerMarkReadOnReplyOrForward: shouldSuppressServerMarkReadOnReplyOrForward(
                getSelectedTableView()
            ),
            targetWindow,
        });
    };

    const forward = React.useCallback(async () => {
        // Block response to S/MIME item when item is decoding
        if (await shouldBlockResponseForSmimeItem()) {
            showActionBlockedDialog(loc(itemHeaderForward));
            return;
        }

        // Forward is disabled for item previews
        if (!isItemAttachment && getItemRightsManagementRestrictions(item).ForwardAllowed) {
            lazyForwardMessage.importAndExecute(
                item || itemId?.Id,
                mailboxInfo,
                'Keyboard',
                getInstrumentationContext(),
                !!shouldSuppressServerMarkReadOnReplyOrForward(getSelectedTableView()),
                { targetWindow }
            );
        }
    }, [itemHeaderForward, isItemAttachment, item, itemId]);
    useKeydownHandler(readingPaneContainer, commands.forward, forward);

    const markAsJunk = React.useCallback(() => {
        const tableView = getSelectedTableView();
        // mark as junk is disabled for groups
        if (isGroupTableQuery(tableView?.tableQuery)) {
            return;
        }
        lazyMarkItemJunkNotJunkFromReadingPane
            .importAndExecute(
                itemId?.Id,
                tableView,
                'Keyboard',
                getInstrumentationContext(),
                targetWindow
            )
            .then(reportMessageStatus => {
                if (reportMessageStatus === 'Success') {
                    lazyClosePopout.importAndExecute(targetWindow);
                }
            });
    }, [itemId, targetWindow]);
    useKeydownHandler(readingPaneContainer, commands.markAsJunk, markAsJunk);

    useKeydownHandlers(
        readingPaneContainer,
        React.useCallback(
            () => [
                {
                    command: commands.deleteMail,
                    handler: () => {
                        lazyDeleteItems
                            .importAndExecute(
                                [itemId?.Id],
                                getDefaultDisposalType(itemId?.Id),
                                getInstrumentationContext(),
                                'Keyboard',
                                mailboxInfo
                            )
                            .then(() => {
                                lazyClosePopout.importAndExecute(targetWindow);
                            });
                    },
                },
                {
                    command: commands.markAsRead,
                    handler: () => {
                        markItemAsReadUnread(true);
                    },
                },
                {
                    command: commands.markAsUnread,
                    handler: () => {
                        markItemAsReadUnread(false);
                    },
                },
            ],
            [itemId]
        )
    );

    useKeydownHandler(
        readingPaneContainer,
        commands.refresh,
        React.useCallback(() => {
            if (props.isPopout) {
                reloadMailReadingPanePopoutAsDeeplink(
                    targetWindow,
                    props.itemId,
                    item?.ParentFolderId?.Id
                );
            }
        }, [item, props.isPopout, itemId, targetWindow])
    );

    useKeydownHandler(
        readingPaneContainer,
        commands.closeMail,
        React.useCallback(() => {
            if (props.isPopout) {
                lazyClosePopout.importAndExecute(targetWindow);
            }
        }, [props.isPopout, targetWindow])
    );

    const toggleFlag = React.useCallback(() => {
        // Toggle flag is disabled for groups
        if (isGroupItem()) {
            return;
        }
        let flagStatusValueToSet: FlagStatus;
        if (item?.Flag?.FlagStatus != 'Flagged') {
            flagStatusValueToSet = 'Flagged';
        } else {
            flagStatusValueToSet = 'NotFlagged';
        }
        lazySetItemsFlagStateFromItemIds.importAndExecute(
            [itemId?.Id],
            getInstrumentationContext(),
            { FlagStatus: flagStatusValueToSet },
            getSelectedTableViewId(),
            'Keyboard'
        );
    }, [item, itemId]);
    useKeydownHandler(readingPaneContainer, commands.toggleFlag, toggleFlag);

    const markItemAsReadUnread = (isReadValue: boolean) => {
        lazyMarkItemAsReadFromReadingPane.importAndExecute(
            itemId?.Id,
            getSelectedTableView(),
            isReadValue,
            getInstrumentationContext(),
            'Keyboard'
        );
    };
    const getInstrumentationContext = (viewState?: ItemReadingPaneViewState) => {
        viewState = viewState || itemReadingPaneViewState;
        return viewState?.instrumentationContext ? [viewState.instrumentationContext] : [];
    };

    const readingPaneVisibilityChange = () => {
        if (window.document.visibilityState == 'hidden') {
            endViewMessageSession();
        } else if (window.document.visibilityState == 'visible') {
            beginViewMessageSession();
        }
    };

    const readingPaneDisplayStart = () => {
        if (window.document.visibilityState == 'visible') {
            beginViewMessageSession();
        }
        userMailInteractionAction('ReadingPaneDisplayStart', getInstrumentationContext());
    };
    const readingPaneDisplayEnd = () => {
        logEmbiggenZoomSettingDatapoint();
        userMailInteractionAction(
            'ReadingPaneDisplayEnd',
            getInstrumentationContext(itemReadingPaneViewState)
        );
        if (item?.ItemId?.Id) {
            endViewMessageSession();
            // Clean up the item in GetItemManager when this reading pane is end.
            // Do nothing for item attachment preview
            const itemIdToCleanup: string = item.ItemId.Id;
            if (!isItemAttachment) {
                lazyCleanUpByItemId
                    .import()
                    .then(cleanUpByItemId => cleanUpByItemId(itemIdToCleanup));
            }
        }

        internetMessageId.current = undefined;
    };

    const beginViewMessageSession = () => {
        readingPaneDisplayStartDate.current = new Date();
        viewSessionGuid.current = getGuid();
    };

    const endViewMessageSession = () => {
        if (
            viewSessionGuid.current &&
            readingPaneDisplayStartDate.current &&
            item?.ItemId?.Id &&
            shouldSendViewMessageSignal(item as Message)
        ) {
            lazyLogSigsDatapoint
                .importAndExecute('ViewMessage', {
                    start: readingPaneDisplayStartDate.current,
                    itemId: internetMessageId.current,
                    customProperties: {
                        MessageIdType: 'MessageODataId',
                        MessageId: item?.ItemId?.Id,
                    },
                    instanceId: viewSessionGuid.current,
                })
                .then(() => {
                    viewSessionGuid.current = undefined;
                });
        }
    };
    const renderInlineCompose = (
        inlineComposeViewStateArg: ComposeViewState | undefined
    ): JSX.Element | null => {
        if (inlineComposeViewStateArg) {
            const composeClassNames = classNames(composeContainer, {
                // We render inline compose in ItemReadingPane when the email is translated.
                // The user is not using conversation view mode in which they can select where to render the compose.
                // In this case, we render the compose on the top part of the Reading Pane.
                [isNewestOnTop]: true,
                [isFocused]: true,
            });
            const senderPersona = () => {
                return (
                    <SenderImage
                        viewerMailboxInfo={mailboxInfo}
                        sender={null}
                        displaySelf={true}
                        style={senderImage}
                    />
                );
            };
            const result = (
                <div className={composeClassNames} key={`inlineComposeDiv_${itemId?.Id}`}>
                    <ZoomContext.Provider value={currentZoomScale}>
                        <Compose
                            viewState={inlineComposeViewStateArg}
                            initialActions={composeInitialActions.current}
                            className={classNames(inlineCompose)}
                            senderPersona={senderPersona()}
                            onUnmountFocusCallback={undefined}
                            useCommandBarSetting={
                                isFeatureEnabled('cmp-remove-compose-sxs') ? undefined : isSxS
                            }
                        />
                    </ZoomContext.Provider>
                </div>
            );
            return result;
        }
        return null;
    };

    const containerStyles = React.useMemo(() => {
        return {
            marginLeft: isBottomReadingPane && !props.isPopout ? 0 : 8,
        };
    }, [isBottomReadingPane, props.isPopout]);

    const [summarizeProps, setSummarizeProps] = React.useState<
        ThreadSummarizationCardViewState | undefined
    >(undefined);

    const selectedFolder = getSelectedFolder();
    const folderId = selectedFolder?.id;
    let isSummarizeButtonHidden = false; // Checks for Junk/Outbox/Draft items as Summarize will be disabled in these scenarios
    if (folderId) {
        isSummarizeButtonHidden =
            doesFolderIdEqualName(folderId, 'junkemail') ||
            doesFolderIdEqualName(folderId, 'outbox') ||
            doesFolderIdEqualName(folderId, 'drafts');
    }

    const isSummarizeButtonFlightEnabled =
        isFeatureEnabled('rp-subjectHeaderV3', mailboxInfo) &&
        (isFeatureEnabled('rp-subjectHeaderSummarizeButton', mailboxInfo) ||
            isFeatureEnabled('rp-subjectHeaderSummarizeButtonTooltip', mailboxInfo) ||
            isFeatureEnabled('rp-subjectHeaderSummarizeButtonLeftAligned', mailboxInfo)) &&
        summarizeProps;

    React.useEffect(() => {
        if (isSummarizeButtonFlightEnabled) {
            if (isSummarizeButtonHidden) {
                lazySetSummaryButtonDisplayStatus.importAndExecute(false);
            } else {
                lazySetSummaryButtonDisplayStatus.importAndExecute(true);
            }
        }
    }, [isSummarizeButtonFlightEnabled, mailboxInfo, isSummarizeButtonHidden]);

    const isSummarizeLanguageSkipEnabled = getApplicationSettings(
        'Copilot',
        mailboxInfo
    ).enableMultiLanguage; /** skipLanguageCheck */

    React.useEffect(() => {
        if (isCopilotFeatureEnabled('Summarize', mailboxInfo, isSummarizeLanguageSkipEnabled)) {
            const isIRMMail = !!item?.RightsManagementLicenseData;

            if (itemId && item) {
                lazyCreateThreadSummarizationViewState
                    .importAndExecute(
                        itemReadingPaneViewState,
                        item,
                        undefined,
                        !!props.isPopout,
                        isIRMMail
                    )
                    .then((result: ThreadSummarizationCardViewState | undefined) => {
                        setSummarizeProps(result);
                    });
            }
        }
    }, [
        setSummarizeProps,
        itemReadingPaneViewState,
        item,
        item?.RightsManagementLicenseData,
        item?.ParentFolderId?.Id,
        itemId,
        props.isPopout,
        mailboxInfo,
        isSummarizeLanguageSkipEnabled,
    ]);

    const summarizeContent = React.useMemo(() => {
        if (isCopilotFeatureEnabled('Summarize', mailboxInfo, isSummarizeLanguageSkipEnabled)) {
            if (itemId && item) {
                if (!!summarizeProps) {
                    if (isSummarizeButtonFlightEnabled) {
                        return (
                            <ThreadSummarizationCardForHeaderEntryPoint
                                viewState={summarizeProps}
                            />
                        );
                    } else if (isFeatureEnabled('rp-thread-summarization-refactor')) {
                        return (
                            <>
                                <ThreadSummarizationBar id={itemId} />
                                <ThreadSummarizationCardForHeaderEntryPoint
                                    viewState={summarizeProps}
                                />
                            </>
                        );
                    } else {
                        return <ThreadSummarizationCard viewState={summarizeProps} />;
                    }
                }
                return null;
            } else {
                logCoreUsage('ThreadSummarization_NoCard_ItemIdUndefined');
            }
        }
        return null;
    }, [
        summarizeProps,
        itemReadingPaneViewState,
        item,
        item?.RightsManagementLicenseData,
        item?.ParentFolderId?.Id,
        itemId,
        mailboxInfo,
        isSummarizeLanguageSkipEnabled,
        isSummarizeButtonFlightEnabled,
    ]);

    const attendeePanel = React.useMemo(() => {
        if (!calendarEvent) {
            return <></>;
        }

        return (
            <div ref={setAttendeePanelRef} tabIndex={-1} className={attendeePanelContainer}>
                <AttendeePanel
                    showAttendeePanel={showAttendeePanel}
                    showRSVPTimeline={false}
                    calendarCardEvent={calendarEvent}
                    shouldShowRSVPTimelineButton={false}
                    dismissButtonClicked={handleSetShowAttendeePanel}
                />
            </div>
        );
    }, [calendarEvent, showAttendeePanel]);

    const handleCloseRsvpToOrganizerWithNote = React.useCallback(() => {
        setRsvpToOrganizerWithNote(itemReadingPaneViewState.itemViewState, false);
    }, [itemReadingPaneViewState?.itemViewState]);

    const renderRsvpWithNote = (): JSX.Element | null => {
        if (!itemReadingPaneViewState.itemViewState?.rsvpToOrganizerWithNote) {
            return null;
        }
        const composeClassNames = classNames(composeContainer, {
            [isNewestOnTop]: true,
            [isFocused]: true,
        });

        return (
            <div className={composeClassNames}>
                <ZoomContext.Provider value={currentZoomScale}>
                    <RSVPWithNote
                        calendarEvent={calendarEvent}
                        itemId={itemId?.Id}
                        mailboxInfo={mailboxInfo}
                        editorViewState={itemReadingPaneViewState.rsvpWithNoteEditorViewState}
                        closeRsvpToOrganizerWithNote={handleCloseRsvpToOrganizerWithNote}
                    />
                </ZoomContext.Provider>
            </div>
        );
    };

    const rsvpWithNoteSection = React.useMemo(() => {
        return itemReadingPaneViewState?.itemViewState?.rsvpToOrganizerWithNote
            ? renderRsvpWithNote()
            : null;
    }, [itemReadingPaneViewState?.itemViewState?.rsvpToOrganizerWithNote]);

    const renderHeader = (): JSX.Element => {
        const hasSpecialCaseCard = hasExtendedCard(
            itemReadingPaneViewState,
            ExtendedCardType.InboxShopping
        );
        const headerProps = {
            subject,
            firstItemId: itemId?.Id,
            categories,
            className: classNames(getDensityModeCssClass(full, medium, compact)),
            isSmimeEncrypted,
            viewState: itemReadingPaneViewState,
            specialCardIconName: specialCardIconName || undefined,
            isSxS,
        };
        return (
            <>
                <div
                    className={classNames(
                        subjectContainer,
                        itemReadingPaneStyles_subject,
                        hasSpecialCaseCard && isSpecialCaseCard,
                        neutralPaletteBackground
                    )}
                    style={subjectHeaderStyles}
                >
                    {isFeatureEnabled('rp-subjectHeaderV3') ? (
                        <LazySubjectHeaderV3
                            {...headerProps}
                            item={itemId}
                            conversationId={itemReadingPaneViewState?.conversationId}
                            isPopout={props.isPopout}
                            isSingleLine={isSingleLine}
                            isItemAttachment={isItemAttachment}
                            itemViewState={itemReadingPaneViewState}
                            attendeeTrackingClicked={handleSetShowAttendeePanel}
                            calendarEventLoaded={handleCalendarEventLoaded}
                            attendeeTrackingClosed={!showAttendeePanel}
                        />
                    ) : (
                        <FluentButtonContextProvider value={isFluentv9ButtonsEnabled}>
                            <SubjectHeaderV2
                                {...headerProps}
                                item={item}
                                itemId={itemId}
                                isPopout={props.isPopout}
                                isSingleLine={isSingleLine}
                                isItemAttachment={isItemAttachment}
                            />
                        </FluentButtonContextProvider>
                    )}
                </div>
                {summarizeContent}
                {cardOutsideScrollRegion}
            </>
        );
    };

    const subject = props.itemSubject || item?.Subject;
    const categories = item?.Categories;
    const isSmimeEncrypted = ![0, 11, 10].includes(getSmimeType(item));
    const { cardInScrollRegion, cardOutsideScrollRegion } = getExtendedCards();
    const hasYammerCard = hasExtendedCard(itemReadingPaneViewState, ExtendedCardType.Yammer);
    const specialCardIconName =
        hasYammerCard &&
        (
            getExtendedCardViewState(
                itemReadingPaneViewState,
                ExtendedCardType.Yammer
            ) as YammerCardViewState
        ).yammerScenario.getIcon();
    const isExtendedCardCoveringContent =
        isExtendedCardCoveringOriginalContent(itemReadingPaneViewState);
    const containerClassName = isExtendedCardCoveringContent ? blockContainer : flexContainer;

    return (
        <div className={calendarCardV2Container}>
            <div
                id="ItemReadingPaneContainer"
                className={classNames(
                    containerClassName,
                    isBrowserSafari() && itemReadingPaneStyles_noOverlay
                )}
                ref={setReadingPaneContainerRef}
                style={containerStyles}
            >
                {!props.maximizeScrollRegion && !isItemAttachment && renderHeader()}
                <div
                    data-app-section={ITEM_APP_SECTION}
                    tabIndex={-1}
                    className={classNames(
                        styles_scrollRegion,
                        scrollRegionWillChangeScrollPosition,
                        isBrowserSafari() && !loadingState?.isLoading && noOverlay,
                        isBrowserFirefox() && itemContainerFirefox,
                        'customScrollBar'
                    )}
                    ref={setScrollRegionRef}
                    data-is-scrollable={true}
                >
                    {props.maximizeScrollRegion && !isItemAttachment && renderHeader()}
                    {isItemAttachment && (
                        <SubjectHeader
                            subject={subject}
                            categories={categories}
                            className={itemReadingPaneStyles_subject}
                            viewState={itemReadingPaneViewState}
                            isItemAttachment={isItemAttachment}
                        />
                    )}
                    {cardInScrollRegion}
                    {renderInlineCompose(inlineComposeViewState)}
                    {rsvpWithNoteSection}
                    {!isExtendedCardCoveringContent && (
                        <div style={getZoomStyle(currentZoomScale)}>
                            <ItemReadingPaneContent
                                loadingState={loadingState}
                                contentCreator={createContainer}
                            />
                            <div className={scrollRegionBottomBuffer} />
                        </div>
                    )}
                    {itemPrintPaneViewState?.itemId && (
                        <PrintPanel
                            itemId={itemPrintPaneViewState.itemId}
                            viewState={itemPrintPaneViewState}
                            targetWindow={targetWindow}
                        />
                    )}
                </div>
            </div>
            {attendeePanel}
        </div>
    );
}, 'ItemReadingPane');

function getOutsideExtendedCardWrapper(
    extendedCardViewState: ExtendedCardViewState,
    child: JSX.Element
): JSX.Element {
    return (
        <div
            className={classNames(outsideCardWrapperContainer, {
                [isCollapsed]: !extendedCardViewState.coverOriginalContent,
            })}
        >
            {child}
        </div>
    );
}
