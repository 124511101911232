import { observer } from 'owa-mobx-react';
import React from 'react';
import { highlightTermsInHtmlElement } from 'owa-mail-highlight-terms';
import loc, { isStringNullOrWhiteSpace } from 'owa-localize';
import { noSubjectText } from 'owa-locstrings/lib/strings/nosubjecttext.locstring.json';
import { medium, compact, subject as styles_subject, collapsed } from './SubjectHeaderV2.scss';

import { getDensityModeCssClass } from 'owa-fabric-theme';

import classnames from 'owa-classnames';

export interface SubjectLineProps {
    subject?: string;
    subjectTextClass: string;
    shouldShrink: boolean;
}

export default observer(function SubjectLine(props: SubjectLineProps) {
    const { subject, subjectTextClass, shouldShrink } = props;
    const densityMode = getDensityModeCssClass(undefined, medium, compact);
    const subjectDisplay = isStringNullOrWhiteSpace(subject) ? loc(noSubjectText) : subject;
    const [isCollapsed, setCollapsedState] = React.useState(shouldShrink);

    const subjectClassNames = classnames(
        densityMode,
        styles_subject,
        'allowTextSelection',
        isCollapsed && collapsed
    );
    const expandSubject = React.useCallback(() => {
        if (isCollapsed) {
            setCollapsedState(false);
        }
    }, [isCollapsed, setCollapsedState]);

    React.useEffect(() => {
        if (!shouldShrink) {
            setCollapsedState(false);
        } else {
            setCollapsedState(true);
        }
    }, [shouldShrink]);

    return (
        <div
            onMouseDown={expandSubject}
            key={'rp_subject'}
            ref={onSubjectRef}
            className={subjectClassNames}
            title={subject}
            role="heading"
            aria-level={2}
        >
            <span className={subjectTextClass}>{subjectDisplay}</span>
        </div>
    );
}, 'SubjectLine');
function onSubjectRef(ref: HTMLDivElement) {
    highlightTermsInHtmlElement(ref);
}
