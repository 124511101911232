export const blackPenText = "F8_r6c";
export const redPenText = "rwL5ub";
export const effectPenText = "jsmU5c";
export const drawCanvasText = "FF6Zi";
export const drawTouchText = "IjudGc";
export const eraserText = "NlMdbc";
export const highlighterText = "IIpSE";
export const lassoText = "v7lVze";
export const selectText = "Xen4q";
export const drawUndoText = "FbpXje";
export const drawRedoText = "R2Dlec";
export const textPenText = "zqib9c";
export default {blackPenText,redPenText,effectPenText,drawCanvasText,drawTouchText,eraserText,highlighterText,lassoText,selectText,drawUndoText,drawRedoText,textPenText};