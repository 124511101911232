import type {
    AttachmentPickers,
    ComposeRibbonControlDefProps,
    ReadOnlyRibbonControlDefProps,
} from 'owa-mail-ribbon-utils';
import type {
    RibbonTabDefinitionBase,
    RibbonTabDefinitionSet,
} from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { propsBaseGlobal } from '../utils/ribbonDefinitionProps';

import type { ComposeViewState } from 'owa-mail-compose-store';
import { owaComputedFn } from 'owa-computed-fn';
import { getComposeViewState } from 'owa-mail-reading-pane-store-conversation/lib/utils/getComposeViewState';
import getContextualMlrTabsDefinition from './ContextualTabs/getContextualMlrTabsDefinition';
import getMLRDrawTab from './mlr-draw-tab/getMLRDrawTab';
import getMLRFormatTab from './mlr-format-tab/getMLRFormatTab';
import getMLRHomeTab from './mlr-home-tab/getMLRHomeTab';
import getMLRInsertTab from './mlr-insert-tab/getMLRInsertTab';
import getMLRMessageTab from './mlr-message-tab/getMLRMessageTab';
import getMLRNotesTab from './mlr-notes-tab/getMLRNotesTab';
import getMLROptionsTab from './mlr-options-tab/getMLROptionsTab';
import getMLRViewTab from './mlr-view-tab/getMLRViewTab';
import { isFeatureEnabled } from 'owa-feature-flags';
import { isNotesScenario } from 'owa-mail-ribbon-utils/lib/isNotesScenario';
import { getRibbonMailboxInfo } from 'owa-mail-ribbon-utils/lib/getRibbonMailboxInfo';
import { lazyGetMLRHelpTab } from 'owa-ribbon-help';
import { getPlaceholderHelpTab } from 'owa-ribbon-help/lib/utils/getPlaceholderHelpTab';
import type { MailboxInfo } from 'owa-client-types';

let composePropsGlobal: ComposeRibbonControlDefProps;
let prevAttachmentPickers: AttachmentPickers | undefined;
let prevViewState: ComposeViewState | undefined;

// Clarification: "readOnly" does not mean that this property never has its properties changed.
// "readOnly" in this context means the mail state, so "not compose".
// Importantly, mailboxInfo can change and we track that change.
let readOnlyPropsGlobal: ReadOnlyRibbonControlDefProps = {
    isPopout: false,
    mailboxInfo: undefined,
    itemId: undefined,
    projectionTabId: undefined,
};

export const getMailRibbonDefinitionsMLR = owaComputedFn(function getMailRibbonDefinitionsMLR(
    attachmentPickers: AttachmentPickers
): RibbonTabDefinitionSet {
    const tabs: RibbonTabDefinitionBase[] = [];

    if (isFeatureEnabled('mon-ribbon-customization-multiAccount')) {
        // Check if we need to update readOnlyProps for Mailbox Info
        const mailboxInfo: MailboxInfo = getRibbonMailboxInfo(
            undefined /* RibbonControlDefinitionProps */
        );
        if (mailboxInfo !== readOnlyPropsGlobal.mailboxInfo) {
            readOnlyPropsGlobal = {
                ...readOnlyPropsGlobal,
                mailboxInfo,
            };
        }
    }

    tabs.push(getMLRHomeTab(readOnlyPropsGlobal), getMLRViewTab(readOnlyPropsGlobal));

    if (isFeatureEnabled('mon-diag-helpTab')) {
        const getMLRHelpTab = lazyGetMLRHelpTab.tryImportForRender();
        tabs.push(getMLRHelpTab ? getMLRHelpTab() : getPlaceholderHelpTab());
    }

    const viewState: ComposeViewState | undefined = getComposeViewState();
    if (viewState?.isComposeRendered) {
        // Check if we need to update compose props
        if (
            !composePropsGlobal ||
            !prevAttachmentPickers ||
            prevAttachmentPickers !== attachmentPickers ||
            !prevViewState ||
            prevViewState !== viewState
        ) {
            prevAttachmentPickers = attachmentPickers;
            prevViewState = viewState;
            composePropsGlobal = {
                ...propsBaseGlobal,
                composeViewState: viewState,
                attachmentPickers,
                mailboxInfo: viewState.mailboxInfo,
            };
        }
        tabs.push(
            getMLRMessageTab(composePropsGlobal),
            getMLRInsertTab(composePropsGlobal),
            getMLRFormatTab(composePropsGlobal),
            getMLRDrawTab(composePropsGlobal),
            getMLROptionsTab(composePropsGlobal),
            ...getContextualMlrTabsDefinition(composePropsGlobal)
        );
    }

    if (isNotesScenario()) {
        tabs.push(getMLRNotesTab(readOnlyPropsGlobal));
    }

    return {
        tabs,
    };
});
