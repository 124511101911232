import { lazyGetTabById, TabType } from 'owa-tab-store';
import { isFeatureEnabled } from 'owa-feature-flags';

export async function isTabFileActivation(tabId?: string): Promise<boolean> {
    const getTabById = await lazyGetTabById.import();
    const tab = getTabById(tabId);
    return (
        tab?.type === TabType.SecondaryReadingPane &&
        !!(
            isFeatureEnabled('mon-file-projection') &&
            tab.data?.isFileActivation &&
            tab.data?.isLocalItem
        )
    );
}
