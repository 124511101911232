import type { ClientItemId } from 'owa-client-ids';
import { trace } from 'owa-trace';
import type { ThreadSummarizationCardLoadingState } from '../store/schema/ThreadSummarizationCardLoadingState';
import { logCoreUsage } from 'owa-analytics';

// NOTE: this file is currently just logging verbose messages to the console, but I expect this to be a central point to log
// usage datapoints that are needed for telemetry and scorecards

export function logGetSummaryCacheHit(conversationId: ClientItemId) {
    trace.verbose(`[ThreadSummarization] Summary cache hit ${conversationId.Id}`);
}

export function logGetSummaryCacheMiss(conversationId: ClientItemId, reason: string) {
    trace.verbose(`[ThreadSummarization] Summary cache miss ${conversationId.Id}: ${reason}`);
}

export function logCancelLoading(conversationId: ClientItemId) {
    trace.verbose(`[ThreadSummarization] Loading canceled by user ${conversationId.Id}`);
    logCoreUsage('ThreadSummarization_CancelLoading', { conversationId: conversationId.Id });
}

export function logCardInitialized(conversationId: ClientItemId) {
    trace.verbose(`[ThreadSummarization] Card initialized ${conversationId.Id}`);
    logCoreUsage('ThreadSummarization_CardInitialized', { conversationId: conversationId.Id });
}

export function logCardDisposed(conversationId: ClientItemId) {
    trace.verbose(`[ThreadSummarization] Card disposed ${conversationId.Id}`);
}

export function logCardDismissed(conversationId: ClientItemId) {
    trace.verbose(`[ThreadSummarization] Card dismissed ${conversationId.Id}`);
    logCoreUsage('ThreadSummarization_CardDismissed', { conversationId: conversationId.Id });
}

export function logCardExpandedChanged(conversationId: ClientItemId, expanded: boolean) {
    trace.verbose(`[ThreadSummarization] Card expanded ${expanded} ${conversationId.Id}`);
    if (expanded) {
        logCoreUsage('ThreadSummarization_CardExpanded', { conversationId: conversationId.Id });
    } else {
        logCoreUsage('ThreadSummarization_CardCollapsed', { conversationId: conversationId.Id });
    }
}

export function logCardLoadingStateChanged(
    conversationId: ClientItemId,
    newState: ThreadSummarizationCardLoadingState
) {
    // Don't log usage datapoint here since the Perf datapoint associated with the service call covers this
    trace.verbose(
        `[ThreadSummarization] Card loading state changed ${conversationId.Id}: ${newState}`
    );
}

export function logFeedbackButtonPressed(conversationId: string) {
    trace.verbose(`[ThreadSummarization] Feedback button pressed ${conversationId}`);
    logCoreUsage('ThreadSummarization_FeedbackPressed', { conversationId });
}

export function logCitationFollowed(conversationId: string) {
    trace.verbose(`[ThreadSummarization] Citation link followed ${conversationId}`);
    logCoreUsage('ThreadSummarization_CitationFollowed', { conversationId });
}

export function logErrorParsingResponse() {
    trace.warn('[ThreadSummarization] Error parsing summary response');
}

export function logRetryLoad(conversationId: string) {
    trace.verbose('[ThreadSummarization] Retry load link clicked');
    logCoreUsage('ThreadSummarization_RetryLoad', { conversationId });
}

export function logImmutableIdFailure(messageId: string, errorMessage: string) {
    trace.verbose(`[ThreadSummarization] ImmutableId could not be generated ${messageId}`);
    logCoreUsage('ThreadSummarization_ImmutableIdFailure', { messageId, errorMessage });
}
