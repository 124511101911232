export const pictureBorderButton = "WsJGye";
export const pictureShadowButton = "AXKAOd";
export const altTextButton = "MZkQKc";
export const pictureSizeButton = "b4qdh";
export const pictureRotateButton = "T9LFLb";
export const pictureCropButton = "M3h6Oc";
export const pictureChangeButton = "KQlOl";
export const rotateRight90Label = "ETzXgc";
export const rotateLeft90Label = "bxFvTd";
export const flipVerticalLabel = "d1Gw3c";
export const flipHorizontalLabel = "VUGdAc";
export default {pictureBorderButton,pictureShadowButton,altTextButton,pictureSizeButton,pictureRotateButton,pictureCropButton,pictureChangeButton,rotateRight90Label,rotateLeft90Label,flipVerticalLabel,flipHorizontalLabel};