import { action } from 'satcheljs';
import { addCoreDatapointConfig } from 'owa-analytics-actions';
import type { ActionSource } from 'owa-mail-store';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import { getStore as getListViewStore } from 'owa-mail-list-store';
import { type UTFocusedOtherSelection } from '@outlook/unified_telemetry';

// Select focused view filter
export const selectFocusedViewFilter = action(
    'selectFocusedViewFilter',
    (focusedViewFilter: FocusedViewFilter, actionSource: ActionSource) =>
        addCoreDatapointConfig(
            {
                // THIS IS TEMPORARY WORKAROUND BECAUSE IT'S A CRITICAL SCENARIO
                // -> Dynamically created core event names are not supported anymore. This should be changed to a constant event name.
                // -> The following line is used by a script to properly add the events to the definition file
                // DO NOT REMOVE THIS LINE: logCoreUsage('TnS_SelectFocusedPivot'), logCoreUsage('TnS_SelectOtherPivot')
                name: focusedViewFilter === 0 ? 'TnS_SelectFocusedPivot' : 'TnS_SelectOtherPivot',
                customData: [
                    focusedViewFilter,
                    actionSource,
                    getListViewStore().inboxPausedDateTime != null /* isInboxPaused */,
                ],
                unifiedTelemetry: {
                    eventName: 'SwitchFocusedOther',
                    data: {
                        SubAction: 0 ? 'Focused' : 'Other',
                    },
                },
            },
            {
                focusedViewFilter,
                actionSource,
            }
        )
);
