import {
    createLazyComponent,
    LazyModule,
    registerLazyOrchestrator,
    LazyAction,
} from 'owa-bundling';
import { MessageExtensionControlPlaceholder } from './components/MessageExtensionControlPlaceholder';
import { onClickMessageExtensionFlyoutButton } from './actions/onClickMessageExtensionFlyoutButton';
export { onClickMessageExtensionFlyoutButton } from './actions/onClickMessageExtensionFlyoutButton';
import { launchAddinContextMenu } from './actions/launchAddinContextMenu';
import {
    onAddinTrigger,
    onMessageExtensionTrigger,
    onAddinSubcommandsTrigger,
    onMessageExtensionAddinsTrigger,
    onOpenAppStoreAddIns,
    onPinMessageExtensionTrigger,
} from 'owa-nova-actions';

import onLaunchMetaOsAddIn from 'owa-app-host-actions/lib/actions/onLaunchMetaOsAddIn';
import './orchestrators/preventDefaultOnAddinFlyoutClick';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MessageExtensionControl" */ './lazyIndex')
);

export const MessageExtensionControlRead = createLazyComponent(
    lazyModule,
    m => m.MessageExtensionControlRead,
    MessageExtensionControlPlaceholder
);
export const MessageExtensionControlCompose = createLazyComponent(
    lazyModule,
    m => m.MessageExtensionControlCompose,
    MessageExtensionControlPlaceholder
);
export const lazyLaunchAddinContextMenu = new LazyAction(lazyModule, m => m.launchAddinContextMenu);
export const lazyLaunchAddinSubCommandsMenu = new LazyAction(
    lazyModule,
    m => m.launchAddinSubCommandsMenu
);
export const lazyMessageExtensionsRibbonButtonOnExecute = new LazyAction(
    lazyModule,
    m => m.messageExtensionsRibbonButtonOnExecute
);

registerLazyOrchestrator(
    onMessageExtensionTrigger,
    lazyModule,
    m => m.messageExtensionFlyoutCommandingOrchestrator
);
registerLazyOrchestrator(
    onClickMessageExtensionFlyoutButton,
    lazyModule,
    m => m.onClickMessageExtensionFlyoutButtonOrchestrator
);

registerLazyOrchestrator(
    launchAddinContextMenu,
    lazyModule,
    m => m.launchAddinContextMenuOrchestrator
);
registerLazyOrchestrator(onAddinTrigger, lazyModule, m => m.onAddinTriggerOrchestrator);
registerLazyOrchestrator(
    onAddinSubcommandsTrigger,
    lazyModule,
    m => m.onAddinSubcommandsTriggerOrchestrator
);
registerLazyOrchestrator(
    onMessageExtensionAddinsTrigger,
    lazyModule,
    m => m.onAddinMessageExtensionTriggerOrchestrator
);
registerLazyOrchestrator(onLaunchMetaOsAddIn, lazyModule, m => m.onLaunchMetaOsAddInOrchestrator);
registerLazyOrchestrator(onOpenAppStoreAddIns, lazyModule, m => m.onOpenAppStoreAddInsOrchestrator);

registerLazyOrchestrator(
    onPinMessageExtensionTrigger,
    lazyModule,
    m => m.onPinMessageExtensionOrchestrator
);
