import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import MailSettings from 'owa-fluent-icons-svg/lib/icons/MailSettingsRegular';
import loc from 'owa-localize';
import { showMessageOptionsDialog } from 'owa-mail-compose-command';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import { showMessageOptionsText } from '../strings/actionControlStrings.locstring.json';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { showMoreOptionsDesc } from '../strings/hoverTooltipDesc.locstring.json';

const onShowMessageOptions = (
    composeId: string,
    retriever: (composeId: string) => RibbonMailComposeInfo | null
) => {
    const editingInfo = retriever(composeId);

    if (editingInfo) {
        showMessageOptionsDialog(editingInfo.composeViewState, editingInfo.targetWindow);
    }
};

export const getShowMessageOptions = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonMailComposeInfo | null,
        styles: IButtonStyles | undefined,
        keyTipTabId: number | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        return createAppButton(
            getComposeRibbonId(7010, editorId),
            loc(showMessageOptionsText),
            getComputedCallback(7010, onShowMessageOptions, composeId, retriever),
            keyTipTabId ? constructKeytip([keyTipTabId], 7010) : undefined,
            MailSettings,
            undefined /* iconColor */,
            styles,
            {
                customTooltip: getHoverTooltip(showMessageOptionsText, showMoreOptionsDesc),
                disabled: isDisabled,
            }
        );
    }
);
