export default function () {
    return {
        span: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        'span > i': {
            width: 14,
            height: 14,
        },
    };
}
