import type { MailboxInfo } from 'owa-client-types';
import { getAccountScopeUserSettings } from 'owa-session-store';
import type { AccountInfo } from '@1js/cortex-topics-bootstrapper';

export default function getAccountInfo(mailboxInfo: MailboxInfo): AccountInfo {
    const userConfig = getAccountScopeUserSettings(mailboxInfo);
    const tenantAadObjectId = userConfig.SessionSettings?.ExternalDirectoryTenantGuid;
    const userAadObjectId = userConfig.SessionSettings?.ExternalDirectoryUserGuid;
    return { tenantAadObjectId, userAadObjectId };
}
