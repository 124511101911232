import { getBposNavBarDataAsync, getBposNavBarData } from 'owa-bpos-store';
import type NavBarData from 'owa-service/lib/contract/NavBarData';
import type RecursiveReadOnly from 'owa-service/lib/RecursiveReadOnly';

export async function getBposNavBarDataHelper(functionName: string) {
    let navBarData: RecursiveReadOnly<NavBarData> | null = getBposNavBarData();
    const getBposNavSucceeded = !!navBarData;

    if (!getBposNavSucceeded) {
        navBarData = await getBposNavBarDataAsync(functionName);
    }

    return navBarData;
}
