import { tableBorderColor } from './tableControlsStrings.locstring.json';
import { onTableBorderColorMenuClicked } from '../../actions/deferredMenuDefinitionMutator';
import Highlight from '../../fonts/BackColorPickerIcon';
import { constructKeytip, createAppFlyoutAnchor } from 'owa-command-ribbon';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { owaComputedFn } from 'owa-computed-fn';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import type { TableBorderFormatState } from 'owa-editor-ribbonplugin-store';
import type { RibbonControlProps } from '@1js/acui-ribbon-like';
import type { IButtonStyles } from '@fluentui/react';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';

export const getTableBorderColorControl = owaComputedFn(
    (
        editorId: string,
        styles: IButtonStyles | undefined,
        borderState: TableBorderFormatState,
        tabId: number | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        const storeName = 'tableBorderColorMenuDefinitionStore';
        return createAppFlyoutAnchor(
            getComposeRibbonId(6097, editorId),
            loc(tableBorderColor),
            getControlMenuStore(editorId, storeName),
            tabId ? constructKeytip([tabId], 6097, true /* hasMenu */) : undefined,
            Highlight /* icon */,
            borderState.borderColor /* iconColor */,
            styles,
            {
                disabled: borderState.borderStyle == 'none' || isDisabled,
                onExecute: parameter => {
                    const { event } = parameter;
                    event?.stopPropagation();
                    onTableBorderColorMenuClicked(editorId, borderState);
                },
                onAfterMenuDismiss: () => {
                    resetMenuDefinition(editorId, storeName);
                    callEditorApi(editorId, 'focus');
                },
            }
        );
    }
);
