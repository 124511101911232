import type { MailboxInfo } from 'owa-client-types';
import getLeftNavGroups from '../selectors/getLeftNavGroups';

export function doesGroupSmtpExistInLeftNavGroupsStore(mailboxInfo: MailboxInfo, smtp: string) {
    const leftNavGroups = getLeftNavGroups(mailboxInfo);
    return leftNavGroups?.myOrgGroups.includes(smtp.toLowerCase()) ?? false;
}

export function wereGroupsLoadedWithError(mailboxInfo: MailboxInfo) {
    const leftNavGroups = getLeftNavGroups(mailboxInfo);

    return leftNavGroups.loadGroupsError;
}
