import type { RibbonButtonProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import type { OwaDate } from 'owa-datetime';
import SendClockRegular from 'owa-fluent-icons-svg/lib/icons/SendClockRegular';
import loc from 'owa-localize';
import setDeferredSendTime from 'owa-mail-compose-actions/lib/actions/setDeferredSendTime';
import { send } from 'owa-mail-compose-command';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { scheduleSendDesc } from '../strings/hoverTooltipDesc.locstring.json';
import { lazyShowScheduleDatePickerDialog } from 'owa-mail-datepicker';
import { composeSendButton } from 'owa-locstrings/lib/strings/composesendbutton.locstring.json';
import { composeSendLaterTitle } from 'owa-locstrings/lib/strings/composesendlaterbutton.locstring.json';

const onScheduleSendInternal = (
    composeId: string,
    retriever: (composeId: string) => RibbonMailComposeInfo | null
) => {
    const onScheduleSend = (date: OwaDate) => {
        const editingInfo = retriever(composeId);
        if (!!editingInfo) {
            const viewState = editingInfo.composeViewState;
            setDeferredSendTime(viewState, date);
            send(viewState, editingInfo.targetWindow);
        }
    };
    const editingInfo = retriever(composeId);
    if (!!editingInfo) {
        lazyShowScheduleDatePickerDialog.importAndExecute(
            onScheduleSend,
            undefined /* minTime */,
            editingInfo.targetWindow,
            composeSendButton,
            composeSendLaterTitle
        );
    }
};

export const getSendLater = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonMailComposeInfo | null,
        styles: IButtonStyles | undefined,
        keyTipTabId: number | undefined,
        isDisabled: boolean
    ): RibbonButtonProps => {
        return createAppButton(
            getComposeRibbonId(7013, editorId),
            loc(composeSendLaterTitle),
            getComputedCallback(7013, onScheduleSendInternal, composeId, retriever),
            keyTipTabId ? constructKeytip([keyTipTabId], 7013) : undefined,
            SendClockRegular,
            undefined /* iconColor */,
            styles,
            {
                customTooltip: getHoverTooltip(composeSendLaterTitle, scheduleSendDesc),
                disabled: isDisabled,
            }
        );
    }
);
