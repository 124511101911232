import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type { RibbonFlyoutAnchorProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon';
import type { RibbonGroupId } from 'owa-ribbon-ids';
import { owaComputedFn } from 'owa-computed-fn';

export const getMLRDrawTabCommonGroupFlyout = owaComputedFn(function getMLRDrawTabCommonGroupFlyout(
    _group: RibbonGroupId,
    _props: ComposeRibbonControlDefProps
): RibbonFlyoutAnchorProps | undefined {
    return undefined;
});
