import type { RibbonTabDefinitionBase } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { owaComputedFn } from 'owa-computed-fn';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import getSLRComposeTab from '../../../../util/getSLRComposeTab';

const getSLRTableFormatTab = owaComputedFn(function getSLRTableFormatTab(
    props: ComposeRibbonControlDefProps
): RibbonTabDefinitionBase {
    return getSLRComposeTab(
        props,
        7,
        [6098] /* controlsDontDropLabels */,
        [] /* controlsDontMoveToOverflow */,
        [] /* controlsFirstMoveOverflow */,
        [
            6054, 6057, 6058, 6059, 6060, 6061, 6045, 6043, 6044, 6050, 6051, 6052, 6053, 6055,
            6056, 6049,
        ] /* controlsWithoutLabels */
    );
});

export default getSLRTableFormatTab;
