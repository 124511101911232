import { WaitForActionDialogText } from './showActionBlockedDialog.locstring.json';
import loc, { format } from 'owa-localize';
import { confirm } from 'owa-confirm-dialog';

/**
 * Should dialog box when S/MIME response is either disabled or blocked
 */
// Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
// -> Error TS7006 (11,33): Parameter 'itemActionString' implicitly has an 'any' type.
// @ts-expect-error
const showActionBlockedDialog = itemActionString => {
    const messageString = format(loc(WaitForActionDialogText), itemActionString);
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2345 (16,13): Argument of type 'null' is not assignable to parameter of type 'string | textResolver'.
    // @ts-expect-error
    confirm(null /* title */, messageString, false /* resolveImmediately */, {
        hideCancelButton: true,
    });
};

export default showActionBlockedDialog;
