import type { RibbonControlId, RibbonGroupId } from 'owa-ribbon-ids';

import { type LabelPreferenceId } from 'owa-mail-ribbon-store-shared-types/lib/util/labelPreferencesIds';
import type { MailRibbonGroup } from 'owa-mail-ribbon-store-shared-types';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type RibbonCustomizerStore from './schema/ribbonCustomizerStore';
import { createStore } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import getAnonymousMailboxInfo from 'owa-mailbox-info/lib/getAnonymousMailboxInfo';
import isMailboxPackageFlightEnabled from 'owa-mailbox-info/lib/isMailboxPackageFlightEnabled';

const defaultMailboxInfo: MailboxInfo = isMailboxPackageFlightEnabled()
    ? getAnonymousMailboxInfo()
    : {
          type: 'UserMailbox',
          userIdentity: '',
          mailboxSmtpAddress: '',
          diagnosticData: 'RibbonCustomizerStore',
      };

/**
 * Store for the Ribbon Customizer. This essentially is a temporary holder for all the changes
 * that a user is making for a single tab. When a user clicks save, that's when we transfer the changes
 * over to owa-mail-ribbon-store to reflect the new ribbon.
 *
 * @layout is the layout of the tab
 * @controlsInOverflow is an array for the controls that are only shown in overflow
 * @staticGroupIdOrdering is the ordering of the controls in the Customizer
 * @showLabelsPreference is the user's preference for the show/hide logic of a control's label.
 * @dragControl is the control being dragged
 * @editingGroupName is a state holder to determine whether a group name is being edited
 * @dragGroup is the group being dragged
 * @isCustomizing is a state holder to determine whether currently customizing ribbon
 * @preDragLayout is a json string representing the pre-keyboard-drag layout, so we revert if cancelled
 * @selectedTab is the tab that is currently selected in the Ribbon Customizer dialog
 * @mailboxInfo is the current user account info
 */
const defaultRibbonCustomizerStore: RibbonCustomizerStore = {
    layout: [] as MailRibbonGroup[],
    controlsInOverflow: [] as RibbonControlId[],
    staticGroupIdOrdering: [] as RibbonGroupId[],
    showLabelsPreference: 1,
    dragControl: null,
    editingGroupName: null,
    dragGroup: null,
    isCustomizing: false,
    preDragLayout: null,
    selectedTab: 1,
    mailboxInfo: defaultMailboxInfo,
};

const store = createStore<RibbonCustomizerStore>(
    'RibbonCustomizer',
    defaultRibbonCustomizerStore
)();
export default store;
export const getStore = () => store;
