import type ItemPartViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemPartViewState';
import getConversationReadingPaneViewState from '../utils/getConversationReadingPaneViewState';
import isNewestOnBottom from 'owa-mail-store/lib/utils/isNewestOnBottom';
import { getStore as getMailStore } from 'owa-mail-store/lib/store/Store';
import type Message from 'owa-service/lib/contract/Message';

export default function getFirstItemInConversation(conversationId: string): Message | null {
    const conversationItemParts = getMailStore().conversations.get(conversationId);
    const conversationNodeIds = conversationItemParts?.conversationNodeIds;
    if (!conversationNodeIds?.length) {
        return null;
    }

    const indexOfFirstItem = isNewestOnBottom() ? 0 : conversationNodeIds.length - 1;
    const conversationReadingPaneState = getConversationReadingPaneViewState(conversationId);
    const itemPartsMap = conversationReadingPaneState?.itemPartsMap;
    const itemPart: ItemPartViewState | undefined = itemPartsMap?.get(
        conversationNodeIds[indexOfFirstItem]
    );
    if (!itemPart) {
        return null;
    }

    const item = getMailStore().items.get(itemPart.itemId) as Message;
    return item;
}
