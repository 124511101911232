import type { IButtonStyles } from '@fluentui/react';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppToggleButton } from 'owa-command-ribbon';
import { lazyToggleFormatPainter } from 'owa-editor-ribbonplugin-store';
import type RibbonFormatState from 'owa-editor-ribbonplugin-store/lib/store/schema/RibbonFormatState';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import PaintBrush from 'owa-fluent-icons-svg/lib/icons/PaintBrushRegular';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { formatPainterText } from './formatControlStrings.locstring.json';
import { formatPainterDesc } from '../strings/hoverTooltipDesc.locstring.json';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { getFormatPainterFormatCommand } from 'owa-editor-command';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';

const onFormatPainterClick = async (
    editorId: string,
    formatViewState: RibbonFormatState,
    source: FormattingSource
) => {
    const { formatPainterFormat, formatPainterSegmentFormat } = await getFormatPainterFormatCommand(
        editorId,
        source
    );

    lazyToggleFormatPainter.importAndExecute(
        editorId,
        formatViewState,
        formatPainterFormat,
        formatPainterSegmentFormat
    );
};

export const getFormatPainterControl = owaComputedFn(
    (
        editorId: string,
        formatViewState: RibbonFormatState,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean,
        source: FormattingSource
    ): RibbonControlProps => {
        return createAppToggleButton(
            getComposeRibbonId(6029, editorId),
            loc(formatPainterText),
            getComputedCallback(6029, onFormatPainterClick, editorId, formatViewState, source),
            !!formatViewState.formatPainterFormat || !!formatViewState.formatPainterSegmentFormat,
            tabId ? constructKeytip([tabId], 6029) : undefined,
            PaintBrush,
            undefined /* iconColor */,
            styles,
            {
                disabled: isDisabled,
                customTooltip: getHoverTooltip(formatPainterText, formatPainterDesc),
            }
        );
    }
);
