import type { RibbonControlId } from 'owa-ribbon-ids';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import TableMoveAboveRegular from 'owa-fluent-icons-svg/lib/icons/TableMoveAboveRegular';
import TableMoveBelowRegular from 'owa-fluent-icons-svg/lib/icons/TableMoveBelowRegular';
import TableMoveLeftRegular from 'owa-fluent-icons-svg/lib/icons/TableMoveLeftRegular';
import TableMoveRightRegular from 'owa-fluent-icons-svg/lib/icons/TableMoveRightRegular';
import TableCellsSplitRegular from 'owa-fluent-icons-svg/lib/icons/TableCellsSplitRegular';
import TableCellsMergeRegular from 'owa-fluent-icons-svg/lib/icons/TableCellsMergeRegular';
import TableDismissRegular from 'owa-fluent-icons-svg/lib/icons/TableDismissRegular';
import TextboxAlignTopLeftRegular from 'owa-fluent-icons-svg/lib/icons/TextboxAlignTopLeftRegular';

const tableFormatTabControlIconMap: Map<RibbonControlId, string> = new Map<RibbonControlId, string>(
    [
        [6058, TableMoveAboveRegular],
        [6059, TableMoveBelowRegular],
        [6060, TableMoveLeftRegular],
        [6061, TableMoveRightRegular],
        [6042, TableDismissRegular],
        [6049, TableCellsMergeRegular],
        [6054, TableCellsSplitRegular],
        [6087, TextboxAlignTopLeftRegular],
    ]
);

export function getTableFormatTabControlIcon(controlId: RibbonControlId): string | undefined {
    return tableFormatTabControlIconMap.get(controlId);
}
