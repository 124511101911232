import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';
import { currentEditorId } from './setEditorId';
import type EditorEntryPoint from './editorEntryPoint';
export const SHOW_EDITOR_SETTINGS_SOURCE = '_ShowEditorSettingsSource';

export default function showEditorSettings(
    showPopout: boolean,
    entryPoint: EditorEntryPoint
): () => void {
    return () => {
        // TODO: !!!!!HACK HERE, SHOULD AVOID!!!!!
        // The code to show Editor settings should be moved out from plugin, and we should not trigger event here
        callEditorApi(currentEditorId, 'triggerContentChangedEvent', SHOW_EDITOR_SETTINGS_SOURCE, {
            showPopout,
            entryPoint,
        });
    };
}
