import type { PerformanceDatapoint, PerformanceCoreDatapoint } from 'owa-analytics';
import { returnTopExecutingActionDatapoint, DatapointStatus, logUsage } from 'owa-analytics';
import type MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import type { TableView } from 'owa-mail-list-store';
import { MailRowDataPropertyGetter, type TableQueryType } from 'owa-mail-list-store';
import { doesRowBelongToNudgeSection, getNudgeItemId } from 'owa-mail-nudge-store';
import { isReadingPanePositionOff } from 'owa-mail-layout';
import { getAggregationBucket } from 'owa-analytics-actions';
import { isReadingPaneConversationEnabled } from 'owa-mail-unstacked';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import { userDate, differenceInDays, now } from 'owa-datetime';
import { getShouldAnimate } from 'owa-mail-list-item-animation-store';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { isFeatureEnabled } from 'owa-feature-flags';

export function endSelectMailItemDatapoint(shouldInvalidate?: boolean) {
    const dp = returnTopExecutingActionDatapoint((datapoint: PerformanceDatapoint) => {
        return (
            datapoint.getEventName() == 'SelectMailItem' ||
            datapoint.getEventName() == 'SelectMailItemNonCritical'
        );
    });
    if (dp) {
        shouldInvalidate
            ? dp?.invalidate()
            : dp?.end(undefined /*duration*/, DatapointStatus.BackgroundSuccess);
    }
}

export function addCustomDataToSelectMailItemDatapoint(
    mailListItemSelectionSource: MailListItemSelectionSource,
    tableView: TableView,
    rowKey: string,
    datapoint?: PerformanceDatapoint | PerformanceCoreDatapoint | null
) {
    const dp =
        datapoint ||
        returnTopExecutingActionDatapoint((perfDp: PerformanceDatapoint) => {
            return (
                perfDp.getEventName() == 'SelectMailItem' ||
                perfDp.getEventName() == 'SelectMailItemNonCritical'
            );
        });
    if (dp) {
        const timeStamp = MailRowDataPropertyGetter.getLastDeliveryOrRenewTimeStamp(
            rowKey,
            tableView
        );
        const isNudge = doesRowBelongToNudgeSection(rowKey, tableView.id, timeStamp);

        let nudgeItemId = null;
        if (isNudge) {
            nudgeItemId = getNudgeItemId(rowKey);
        }

        const daysAgo = timeStamp ? Math.max(0, differenceInDays(now(), userDate(timeStamp))) : -1;
        const autoSelectRow = getUserConfiguration()?.UserOptions?.ShowReadingPaneOnFirstLoad;
        const isConversationOnReadingPane =
            tableView.tableQuery.listViewType == 1 ? false : isReadingPaneConversationEnabled();

        dp?.addCustomData([
            mailListItemSelectionSource,
            tableView.tableQuery.listViewType,
            tableView.tableQuery.type,
            tableView.tableQuery.type === 1 && (tableView.tableQuery as any).searchScope.kind,
            0, // deprecated valid coupon indexes
            isNudge,
            !isReadingPanePositionOff(),
            getAggregationBucket({
                value: tableView.rowKeys.indexOf(rowKey),
                buckets: [10, 25, 50, 75, 100, 150, 200, 250, 300, 500],
                exactMatches: [-1, 0],
            }), // Row key index
            nudgeItemId, // Logged only if row was nudged.
            isConversationOnReadingPane,
            tableView.tableQuery.type === 0 && folderIdToName(tableView.tableQuery.folderId),
            daysAgo,
            getShouldAnimate(),
            autoSelectRow,
        ]);

        dp.addUnifiedTelemetryData({ eventName: 'ViewMessage' });
    }
}

// Track the last select mail item datapoint so that we can cancel it if a new one is started
let lastSelectMailItemDatapoint: PerformanceDatapoint | null | undefined;
export function setLastSelectMailItemDatapoint(datapoint: PerformanceDatapoint | null | undefined) {
    if (!isFeatureEnabled('rp-enhanced-telemetry-v2') && lastSelectMailItemDatapoint) {
        // Record whether or not datapoint has already ended before force ending
        const hasEnded = lastSelectMailItemDatapoint.hasEnded;

        // only end the datapoint if it hasn't already ended
        if (!hasEnded) {
            // end SelectMailItem datapoint from the previous selection
            lastSelectMailItemDatapoint?.end(
                undefined /*duration*/,
                DatapointStatus.BackgroundSuccess
            );
        }

        // Log abandonments if datapoint lasted longer than half a second (to reduce noise).
        // Must end the datapoint first to get elapsed time
        const e2eTime = lastSelectMailItemDatapoint?.getProperties()?.E2ETimeElapsed;
        if (!hasEnded && typeof e2eTime === 'number' && e2eTime > 500) {
            logUsage(
                'SelectMailItemAbandoned',
                [
                    lastSelectMailItemDatapoint?.getProperties()?.E2ETimeElapsed,
                    lastSelectMailItemDatapoint?.getPropertyBag()?.owa_1, // MailListItemSelectionSource
                    lastSelectMailItemDatapoint?.getPropertyBag()?.owa_7, // true if in 3 col view
                    lastSelectMailItemDatapoint?.getPropertyBag()?.owa_8, // index
                ],
                { sessionSampleRate: 10 }
            );
        }
    }

    lastSelectMailItemDatapoint = datapoint;
}
