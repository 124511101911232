import initializeAttachmentsForItem from 'owa-mail-reading-pane-store/lib/actions/initializeAttachmentsForItem';
import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import shouldInitializeConversationAttachmentWell from '../utils/shouldInitializeConversationAttachmentWell';
import isNewestOnBottom from 'owa-mail-store/lib/utils/isNewestOnBottom';
import type { ConversationAttachmentWellViewState } from 'owa-attachment-well-data';
import {
    lazyGetConversationAttachmentWellInitialValue,
    lazyInitializeAttachments,
    lazyMergeToConversationAttachmentWell,
    lazySortConversationAttachmentWell,
} from 'owa-attachment-well-data';
import { lazyGetAttachmentsForConversationWell } from 'owa-mail-attachment-previews';
import { getStore } from 'owa-mail-store/lib/store/Store';
import { mutatorAction } from 'satcheljs';
import { isFeatureEnabled } from 'owa-feature-flags';
import { lazyInitOneDriveInfo } from '../utils/lazyInitOneDriveInfo';

export default async function initializeAttachmentsForConversationWell(
    conversationState: ConversationReadingPaneViewState,
    forceAttachmentInitialize?: boolean
) {
    const mailStoreItems = getStore().items;
    if (
        conversationState &&
        shouldInitializeConversationAttachmentWell(conversationState, mailStoreItems)
    ) {
        if (!conversationState?.attachmentWell) {
            const [attachmentTypes, parentItemIdMap, itemReceivedTimeMap] =
                await lazyGetAttachmentsForConversationWell.importAndExecute(
                    conversationState.conversationId
                );
            const getConversationAttachmentWellInitialValue =
                await lazyGetConversationAttachmentWellInitialValue.import();
            setAttachmentWellForConversation(
                conversationState,
                getConversationAttachmentWellInitialValue(parentItemIdMap, itemReceivedTimeMap)
            );
            await lazyInitializeAttachments.importAndExecute(
                attachmentTypes,
                conversationState.conversationId.mailboxInfo,
                conversationState.attachmentWell,
                true /* forceUpdateAttachmentsInStore */,
                false /* isInitializationForCompose */
            );
            if (isFeatureEnabled('doc-infoPane')) {
                const initOneDriveInfo = await lazyInitOneDriveInfo.import();
                initOneDriveInfo(conversationState);
            }
        }
        // go through loaded item parts and add attachments to the convo well
        const itemPartsMapKeys = [...conversationState.itemPartsMap.keys()];
        for (let i = 0; i < itemPartsMapKeys.length; i++) {
            const itemPart = conversationState.itemPartsMap.get(itemPartsMapKeys[i]);
            // itemPartsMap no longer has the item part
            // possibly because the selected conversation was changed
            // during the async operations in this for loop
            if (!itemPart) {
                continue;
            }
            const item = mailStoreItems.get(itemPart.itemId);
            // if item is no longer in cache (moved or deleted)
            // skip to next item part
            if (!item) {
                continue;
            }
            // call initializeAttachmentsForItem
            // for item parts that are not expanded it will initialize
            // forceAttachmentInitialize is set to true when user navigate back to cached email
            // so that download token will get updated
            // for other cases, it will be no-op because reinitialize flag is false
            await initializeAttachmentsForItem(itemPart, item, forceAttachmentInitialize);
            await lazyMergeToConversationAttachmentWell.importAndExecute(
                itemPart.attachmentWell,
                conversationState.attachmentWell,
                // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                // -> Error TS2345 (71,17): Argument of type '{ mailboxInfo: MailboxInfo; __type?: string | undefined; Id?: string | undefined; ChangeKey?: string | undefined; }' is not assignable to parameter of type 'ClientItemId'.
                // @ts-expect-error
                {
                    ...item.ItemId,
                    mailboxInfo: item.MailboxInfo,
                },
                item.DateTimeReceived
            );
        }
        if (conversationState.attachmentWell) {
            await lazySortConversationAttachmentWell.importAndExecute(
                conversationState.attachmentWell,
                isNewestOnBottom()
            );
        }
    }
}

const setAttachmentWellForConversation = mutatorAction(
    'setAttachmentWellForConversation',
    (
        conversationState: ConversationReadingPaneViewState,
        viewState: ConversationAttachmentWellViewState
    ) => {
        conversationState.attachmentWell = viewState;
    }
);
