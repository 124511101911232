import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { isCapabilitySupported } from 'owa-capabilities';
import { editorCapability } from 'owa-capabilities-definitions/lib/editorCapability';
import { constructKeytip, createAppButton, createAppFlyoutAnchor } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import { showEditorSettings } from 'owa-editor-proofing-common';
import type { ProofingViewState } from 'owa-editor-proofing-common/lib/store/schema/ProofingViewState';
import { isDexLitePaneEnabled } from 'owa-editor-proofing-common/lib/utils/dexPaneFeatureFlights';
import setEditorId from 'owa-editor-proofing-common/lib/utils/setEditorId';
import GrammarCheck from 'owa-fluent-icons-svg/lib/icons/TextGrammarCheckmarkFilled';
import loc from 'owa-localize';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { proofingOptionsText } from '../strings/actionControlStrings.locstring.json';
import { proofingOptionsDescription } from '../strings/hoverTooltipDesc.locstring.json';
import EditorEntryPoint from 'owa-editor-proofing-common/lib/utils/editorEntryPoint';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import Editor from 'owa-editor-proofing-plugin/lib/fonts/EditorIcon';
import { onEditorMenuClicked } from '../actions/deferredMenuDefinitionMutator';
import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import type MailboxInfo from 'owa-client-types/lib/MailboxInfo';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';
import { colors } from 'owa-sass-mixins/lib/mixins';

const onShowEditorSettings = (editorId: string, isDisabled: boolean) => {
    return () => {
        if (!isDisabled) {
            setEditorId(editorId);
            showEditorSettings(false, EditorEntryPoint.MailComposeToolbar)();
        }
    };
};

export const getProofingOptions = owaComputedFn(
    (
        viewState: ProofingViewState,
        editorId: string,
        isDisabled: boolean,
        styles: IButtonStyles | undefined,
        keyTipTabId: number | undefined,
        mailboxInfo: MailboxInfo
    ): RibbonControlProps => {
        const label = loc(proofingOptionsText);
        const id = getComposeRibbonId(7001, editorId);
        const keyTipProps = keyTipTabId ? constructKeytip([keyTipTabId], 7001) : undefined;

        const overrides = {
            customTooltip: getHoverTooltip(proofingOptionsText, proofingOptionsDescription),
            disabled: isDisabled || !isCapabilitySupported(editorCapability),
        };

        if (isDexLitePaneEnabled()) {
            const storeName = 'editorMenuDefinitionStore';
            return createAppFlyoutAnchor(
                id,
                label,
                getControlMenuStore(editorId, storeName),
                keyTipProps,
                Editor,
                colors.$msWordBrandColorLightBlue,
                styles,
                {
                    ...overrides,
                    onExecute: () => onEditorMenuClicked(viewState, editorId, mailboxInfo),
                    onAfterMenuDismiss: () => {
                        resetMenuDefinition(editorId, storeName);
                        callEditorApi(editorId, 'focus');
                    },
                }
            );
        } else {
            return createAppButton(
                id,
                label,
                getComputedCallback(7001, onShowEditorSettings, editorId, isDisabled),
                keyTipProps,
                GrammarCheck,
                undefined /* iconColor */,
                styles,
                overrides
            );
        }
    }
);
