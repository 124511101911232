import type UnifiedGroupAccessType from 'owa-service/lib/contract/UnifiedGroupAccessType';
import { doesGroupSmtpExistInLeftNavGroupsStore } from 'owa-groups-left-nav-store/lib/utils/availabilityCheck';
import getGroupDetails from 'owa-groups-shared-store/lib/selectors/getGroupDetails';
import type { MailboxInfo } from 'owa-client-types';

export function isPrivateUnjoinedGroup(groupId: string, mailboxInfo: MailboxInfo): boolean {
    if (groupId && !doesGroupSmtpExistInLeftNavGroupsStore(mailboxInfo, groupId)) {
        const groupDetails = getGroupDetails(mailboxInfo, groupId);

        if (groupDetails) {
            return groupDetails.AccessType == 1 && !groupDetails.UserGroupRelationship?.IsMember;
        }

        return true;
    }

    return false;
}

export function isJoinedGroup(groupId: string, mailboxInfo: MailboxInfo): boolean {
    if (groupId && !doesGroupSmtpExistInLeftNavGroupsStore(mailboxInfo, groupId)) {
        const groupDetails = getGroupDetails(mailboxInfo, groupId);

        return !!groupDetails?.UserGroupRelationship?.IsMember;
    }

    return groupId != null;
}
