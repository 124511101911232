/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import { FolderTag } from '../index';
import { observer } from 'owa-mobx-react';
import { CategoryWell } from 'owa-categories';
import {
    labelsContainerWithCategories,
    labelsContainerWithoutCategories,
} from 'owa-mail-listitem-styles/lib/scss/MailListItem.scss';

import { lazyStartSearchWithCategory } from 'owa-mail-search';
import type { MailboxInfo } from 'owa-client-types';
import React from 'react';

export interface MailListItemLabelsContainerProps {
    categories: string[];
    categoryContainerClass: string;
    categoryWellContainerClass: string;
    isNudged: boolean;
    isSingleLine: boolean;
    mailboxGuids: string[];
    parentFolderDisplayName: string;
    parentFolderId: string;
    rowId: string;
    showCategories: boolean;
    showFolderTag: boolean;
    showPreviewText: boolean;
    mailboxInfo: MailboxInfo;
}

export default observer(function MailListItemLabelsContainer(
    props: MailListItemLabelsContainerProps
) {
    const {
        categories,
        categoryContainerClass,
        categoryWellContainerClass,
        isNudged,
        isSingleLine,
        mailboxGuids,
        parentFolderDisplayName,
        parentFolderId,
        rowId,
        showCategories,
        showFolderTag,
        showPreviewText,
        mailboxInfo,
    } = props;

    return (
        <div
            className={
                showCategories ? labelsContainerWithCategories : labelsContainerWithoutCategories
            }
        >
            {showCategories && (
                <CategoryWell
                    key={rowId}
                    categories={categories}
                    categoryContainerClass={categoryContainerClass}
                    categoryWellContainerClass={categoryWellContainerClass}
                    onCategoryClicked={onCategoryClicked}
                    mailboxInfo={mailboxInfo}
                />
            )}
            {showFolderTag && (
                <FolderTag
                    folderId={parentFolderId}
                    parentFolderDisplayName={parentFolderDisplayName}
                    isPreviewTextOn={showPreviewText || isNudged}
                    isSingleLine={isSingleLine}
                    mailboxGuids={mailboxGuids}
                />
            )}
        </div>
    );
},
'MailListItemLabelsContainer');

const onCategoryClicked = async (
    ev: React.MouseEvent<unknown>,
    category: string,
    actionSource: string
) => {
    // Stop propagation so the click doesn't bubble up to container (i.e list view item and reading pane subject)
    ev.stopPropagation();

    const startSearchWithCategory = await lazyStartSearchWithCategory.import();
    startSearchWithCategory(actionSource, category);
};
