import { getRuntimeControls, isGroupRuntimeControlEnabled } from './getRuntimeControls';
import { modifyIdsForCompose } from './modifyIdsForCompose';
import { modifyIdsForRead } from './modifyIdsForRead';
import { changeGroupLayout, dropLabel, moveToOverflowWell } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { owaComputedFn } from 'owa-computed-fn';
import { type LabelPreferenceId } from 'owa-mail-ribbon-store-shared-types/lib/util/labelPreferencesIds';
import { getReadRibbonId } from 'owa-mail-ribbon-utils/lib/getReadRibbonId';
import type { RibbonScalingStep } from '@1js/acui-ribbon-like';
import type { ClientItemId } from 'owa-client-ids';
import type { MailRibbonGroup } from 'owa-mail-ribbon-store-shared-types';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { type MailRibbonGroupId, type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { RibbonRuntimeControlsGroup } from './getRuntimeControls';

/**
 * MLR scaling can be pretty complex. At this time, we haven't created a general means to
 * represent it.  For now, we'll create the scaling steps "by hand"
 */
export const getMLRScalingSteps = owaComputedFn(function getMLRScalingSteps(
    tab: MailRibbonTabId
): RibbonScalingStep[] {
    const scalingSteps: RibbonScalingStep[] = new Array();

    switch (tab) {
        case 1:
            scalingSteps.push(
                changeGroupLayout(128),
                changeGroupLayout(113),
                changeGroupLayout(121)
            );

            /* ADO171622: `convertGroupToFlyout is currently causing issues and crashing when provoked.
                          Currently disabling these for the time being until the issue can be fixed. */
            // scalingSteps.push(convertGroupToFlyout(MailRibbonGroupId.Group_Tags));
            // scalingSteps.push(convertGroupToFlyout(MailRibbonGroupId.Group_Move));
            // scalingSteps.push(convertGroupToFlyout(MailRibbonGroupId.Group_Groups));
            // scalingSteps.push(convertGroupToFlyout(MailRibbonGroupId.Group_Find));
            break;

        default:
            break;
    }

    return scalingSteps;
});

/**
 * Converts the saved ordering of RibbonIds to a series of scaling action functions
 * (drop label or move to overflow well).
 * @returns a series of RibbonScalingStep that represent the order in which scaling
 * should occur
 */
export const getScalingSteps = owaComputedFn(function getScalingSteps(
    storedRibbonConfig: MailRibbonGroup[],
    showLabelsPreference: LabelPreferenceId,
    controlsDontMoveToOverflow: RibbonControlId[],
    controlsFirstMoveToOverflow: RibbonControlId[],
    controlsDontDropLabels: RibbonControlId[],
    editorId: string | undefined,
    itemId: ClientItemId | undefined
): RibbonScalingStep[] {
    const scalingSteps: RibbonScalingStep[] = new Array();

    /**
     * In the below for loop we are iterating from the end to the beginning of the scalingConfig
     * array as we want to drop the labels on the right-most group's buttons first. The right-most
     * group's buttons were those that were added last to the scalingConfig array last. This will
     * drop labels from the right-most group, and all the elements within the group as the same time.
     */
    if (showLabelsPreference === 1) {
        for (let groupIndex = storedRibbonConfig.length - 1; groupIndex >= 0; groupIndex--) {
            const perGroupRibbonIds: RibbonControlId[] = storedRibbonConfig[groupIndex].controlIds;

            //some controls we never want to drop labels for
            const controlsThatDropLabels: RibbonControlId[] = perGroupRibbonIds.filter(
                control => !controlsDontDropLabels.includes(control)
            );
            // Drop labels for controls that are added at runtime.
            if (isGroupRuntimeControlEnabled(storedRibbonConfig[groupIndex].groupId)) {
                const runtimeControls: RibbonRuntimeControlsGroup = getRuntimeControls();
                if (runtimeControls.shouldAddScalingSteps()) {
                    controlsThatDropLabels.push(...runtimeControls.getControlIds());
                }
            }

            //drops labels for all controls within the group at groupIndex, simultaneously
            if (controlsThatDropLabels.length > 0) {
                if (itemId) {
                    scalingSteps.push(
                        dropLabel(...modifyIdsForRead(controlsThatDropLabels, itemId))
                    );
                } else {
                    scalingSteps.push(
                        dropLabel(...modifyIdsForCompose(controlsThatDropLabels, editorId))
                    );
                }
            }
        }
    }

    const addToOverFlow = (controlId: RibbonControlId) => {
        let idToMove: RibbonControlId | string;
        if (!!editorId) {
            idToMove = getComposeRibbonId(controlId, editorId);
        } else if (itemId) {
            idToMove = getReadRibbonId(controlId, itemId);
        } else {
            idToMove = controlId;
        }
        scalingSteps.push(moveToOverflowWell(idToMove));
    };

    // controls that first move to the overflow by customization
    if (controlsFirstMoveToOverflow.length > 0) {
        const perGroupRibbonIds: RibbonControlId[] = controlsFirstMoveToOverflow;
        for (let i = 0; i < perGroupRibbonIds.length; i++) {
            if (!controlsDontMoveToOverflow.includes(perGroupRibbonIds[i])) {
                addToOverFlow(perGroupRibbonIds[i]);
            }
        }
    }
    // controls that move to the overflow from right to left by default
    for (let groupIndex = storedRibbonConfig.length - 1; groupIndex >= 0; groupIndex--) {
        // Move controls that are added at runtime to the overflow menu.

        if (isGroupRuntimeControlEnabled(storedRibbonConfig[groupIndex].groupId)) {
            const runtimeControls: RibbonRuntimeControlsGroup = getRuntimeControls();
            if (runtimeControls.shouldAddScalingSteps()) {
                const controlIds = runtimeControls.getControlIds();
                for (let i = controlIds.length - 1; i >= 0; i--) {
                    addToOverFlow(controlIds[i]);
                }
            }
        }

        //iterates through every control from right to left and adds a move to overflow scaling step
        const perGroupRibbonIds: RibbonControlId[] = storedRibbonConfig[groupIndex].controlIds;
        for (let i = perGroupRibbonIds.length - 1; i >= 0; i--) {
            if (
                !controlsDontMoveToOverflow.includes(perGroupRibbonIds[i]) &&
                !controlsFirstMoveToOverflow.includes(perGroupRibbonIds[i])
            ) {
                addToOverFlow(perGroupRibbonIds[i]);
            }
        }
    }

    return scalingSteps;
});
