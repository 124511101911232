import type { RibbonTabDefinitionBase } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { owaComputedFn } from 'owa-computed-fn';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import getMLRComposeTab from '../../../util/getMLRComposeTab';
import { getMLRFormatTabCommonGroupFlyout } from './getMLRFormatTabCommonGroupFlyout';
import { getMLRFormatTabGroupLayouts } from './getMLRFormatTabGroupLayouts';

const emptyCtrlList: FormatControlId[] = [
    6001, 6002, 6003, 6004, 6005, 6006, 6007, 6008, 6017, 6018, 6019, 6110, 6022, 6023, 6030, 6027,
    6028, 6024, 6025, 6026, 6073, 6075, 6020, 6021, 6016,
];

const getMLRFormatTab = owaComputedFn(function getMLRFormatTab(
    props: ComposeRibbonControlDefProps
): RibbonTabDefinitionBase {
    return getMLRComposeTab(
        props,
        4,
        emptyCtrlList,
        getMLRFormatTabGroupLayouts,
        getMLRFormatTabCommonGroupFlyout
    );
});
export default getMLRFormatTab;
