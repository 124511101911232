import { getStore } from 'owa-protection-store/lib/store/protectionStore';
import type { MailboxInfo } from 'owa-client-types';
import type ProtectionViewState from 'owa-mail-protection-types/lib/schema/ProtectionViewState';

export default function shouldDisableEncryptionButton(
    protectionViewState: ProtectionViewState,
    mailboxInfo: MailboxInfo,
    templateId?: string
): boolean {
    const { IRMData } = protectionViewState;
    // Disable encryption button in following conditions
    // 1. The message is already encrypted with the given templateId, in that case disable the corresponding encryption button
    if (IRMData && templateId && IRMData.RmsTemplateId == templateId) {
        return true;
    }

    // 2. Current user is not the message owner
    if (IRMData && !IRMData.IsOwner) {
        return true;
    }

    const protectionStore = getStore(mailboxInfo);

    // 3. Compliance configuration is not ready
    if (protectionStore.rmsTemplates.size == 0) {
        return true;
    }

    // 4. Compliance configuration does not contain the given templateId
    if (templateId && !protectionStore.rmsTemplates.has(templateId)) {
        return true;
    }

    return false;
}
