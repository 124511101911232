import { folderHierarchyTypePolicy } from './folderHierarchyTypePolicy';

export const folderTypePolicies = {
    MailFolder: {
        fields: {
            childFolderIds: {
                // Short for always preferring incoming over existing data.
                merge: false,
                read(existing: any) {
                    if (!existing) {
                        return [];
                    }

                    return existing;
                },
            },
            parentFolderId: {
                merge: false,
            },
            FolderId: {
                merge(existing: any, incoming: any) {
                    return {
                        Id: incoming?.Id || existing?.Id,
                        ChangeKey: existing?.ChangeKey || incoming?.ChangeKey || null,
                        __typename: 'FolderId',
                    };
                },
            },
        },
    },
    Query: {
        fields: {
            folder(_: any, { args, toReference }: any) {
                return toReference({
                    __typename: 'MailFolder',
                    id: args?.id,
                });
            },
            folderHierarchy: folderHierarchyTypePolicy,
        },
    },
} as const;
