import { LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "TopicAnnotations" */ './lazyIndex'),
    { name: 'TopicAnnotations' }
);

export const lazyGetTopicDefinition = new LazyAction(lazyModule, m => m.getTopicDefinition);
export const lazyGetTopicAnnotations = new LazyAction(lazyModule, m => m.getTopicAnnotations);
export const lazyGetMyTopics = new LazyAction(lazyModule, m => m.getMyTopics);
export const lazyGetTopicsByName = new LazyAction(lazyModule, m => m.getTopicsByName);
export const lazyGetTopicsByPrefix = new LazyAction(lazyModule, m => m.getTopicsByPrefix);
export const lazyLogEvent = new LazyAction(lazyModule, m => m.logEvent);
export const lazyUnsafeGetTopicsSdk = new LazyAction(lazyModule, m => m.unsafeGetTopicsSdk);
export const lazyGetTopicBootstrapperConfig = new LazyAction(
    lazyModule,
    m => m.getTopicBootstrapperConfig
);
export const lazyAddTopicHighlight = new LazyAction(lazyModule, m => m.addTopicHighlight);
export const lazyUpdateIsTopicHighlightsEnabled = new LazyAction(
    lazyModule,
    m => m.updateIsTopicHighlightsEnabled
);
export const lazyRemoveTopicHighlightByTopicId = new LazyAction(
    lazyModule,
    m => m.removeTopicHighlightByTopicId
);
