import type { TopicsSDK } from '@1js/cortex-topics-bootstrapper';
import { tryIsUserEnabled } from '@1js/cortex-topics-bootstrapper/lib/utilities/tryIsUserEnabled';
import {
    getGlobalSettingsAccountMailboxInfo,
    getMailAccountSources,
} from 'owa-account-source-list-store';
import { getApplicationSettings } from 'owa-application-settings';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getAccountScopeUserSettings, getUserConfiguration, isConsumer } from 'owa-session-store';
import { isEditorTopicsSuggestionsBitSet, isTopicHighlightsBitSet } from './topicsBitFlags';
import { tryGetTopicsSdk } from './tryGetTopicsSdk';

/**
 * Returns true if topic highlights are enabled.
 * This checks flights, topics permissions and user settings.
 */
export function isTopicsHighlightEnabled(mailboxInfo: MailboxInfo): boolean {
    return (
        !isFeatureEnabled('rp-hideVivaTopics', mailboxInfo) &&
        isTopicsHighlightEnabledForUser(mailboxInfo) &&
        isTopicHighlightsBitSet()
    );
}

/**
 * Returns true if the topic highlights flight enabled or the user is part of the early adopter.
 * This does not check the setting and it should be used only to enable rendering the settings.
 */
export function isTopicsHighlightEnabledForUser(mailboxInfo: MailboxInfo): boolean {
    return areTopicsHighlightsAllowed(mailboxInfo) && isAutomaticTopicsEnabled(mailboxInfo);
}

/**
 * Returns true if the topic highlights flight enabled or the user is part of the early adopter.
 * @param mailboxInfo
 * @returns true if the user can see a topic badge, this checks topic-badge flight or the EAP flight for topic highlights.
 */
export function isTopicBadgeEnabled(mailboxInfo: MailboxInfo): boolean {
    return (
        (isFeatureEnabled('csi-owa-topic-badge') || areTopicBadgesEAPEnabled(mailboxInfo)) &&
        !!isTopicsMvpEnabled(mailboxInfo)
    );
}

/**
 * Returns true if the context action and the topic highlights experiences are enabled.
 * The context action experiences is bundled with the topic highlights, so the check needs to happen for both.
 * @param mailboxInfo
 * @returns true if the user can see the context action, this checks topic-badge flight or the EAP flight for topic highlights.
 */
export function isContextActionEnabled(mailboxInfo: MailboxInfo): boolean {
    return (
        isFeatureEnabled('csi-owa-topic-context-action') &&
        !!isTopicsMvpEnabled(mailboxInfo) &&
        areTopicsHighlightsAllowed(mailboxInfo)
    );
}

/**
 * Returns true if automatic topics support is enabled.
 * This checks both the settings for the user and the main flight for Viva Topics support.
 */
export function isAutomaticTopicsEnabled(mailboxInfo: MailboxInfo): boolean {
    return !!(
        !isFeatureEnabled('rp-hideVivaTopics', mailboxInfo) &&
        !!getAccountScopeUserSettings(mailboxInfo).UserOptions?.IsTopicHighlightsEnabled && // The setting is misnamed as it's re-used
        isTopicsMvpEnabled(mailboxInfo)
    );
}

/**
 * Check if the Editor topic suggestions flight (custom workflow) is enabled along with the pre-requiste
 * manual tagging of topics flight. Note: we also check that the new UX experience shouldn't be enabled.
 * @returns true is editor topic suggestions (custom workflow) should be enabled.
 */
export function isEditorCustomTopicSuggestionsEnabled(): boolean | undefined {
    return (
        isFeatureEnabled('csi-editor-topicsuggestions') &&
        isTopicsMvpEnabled(getGlobalSettingsAccountMailboxInfo()) &&
        !isSystemInitiatedCIQTopicsEnabled() &&
        isEditorSuggestionsSettingEnabled()
    );
}

/**
 * Check if the Editor CIQ Flag for Topic Suggestions is enabled along with the pre-requiste
 * manual tagging of topics flight.
 * @returns true is editor ciq topic flag suggestions should be enabled.
 */
export function isSystemInitiatedCIQTopicsEnabled(): boolean | undefined {
    return (
        isFeatureEnabled('mc-editor-oa-topics-flags') &&
        isTopicsMvpEnabled(getGlobalSettingsAccountMailboxInfo()) &&
        isEditorSuggestionsSettingEnabled()
    );
}

/**
 * Check is the Editor CIQ viva topic suggestions flight for @ mention is enabled along with the pre-requiste
 * manual tagging of topics flight.
 * @returns
 * true is editor topic suggestions should be enabled.
 * false/undefined is editor topic suggestions should be disabled/unknown.
 *
 */
export function isUserInitiatedCIQTopicsEnabled(): boolean | undefined {
    return (
        isFeatureEnabled('mc-editor-oa-vivatopics') &&
        isTopicsMvpEnabled(getGlobalSettingsAccountMailboxInfo())
    );
}

/**
 * Check to determine if Editor Viva Topics Setting Option should be shown or not based on
 * flights for Topic integration in Editor
 * @returns true if the setting should be shown
 */
export function shouldShowEditorSuggestionsSetting(): boolean | undefined {
    return (
        (isFeatureEnabled('csi-editor-topicsuggestions') ||
            isFeatureEnabled('mc-editor-oa-topics-flags')) &&
        isTopicsMvpEnabled(getGlobalSettingsAccountMailboxInfo())
    );
}

/**
 * Returns true/false/undefined for Topics MVP feature. It checks the flight and topics license.
 * Works with both the SDK calling the API and checking the cached license data if the Topics SDK is not loaded.
 *
 * @returns
 * true if the Topics MVP feature is enabled.
 * false if the Topics MVP feature is disabled.
 * undefined if topicsSdk is not loaded and no cached licensed data.
 */
export function isTopicsMvpEnabled(mailboxInfo: MailboxInfo): boolean | undefined {
    // get getApplicationSettings('Topics').loadSDK is set to false in AirGap, don't load the SDK there.
    if (
        !isFeatureEnabled('rp-hideVivaTopics', mailboxInfo) &&
        getApplicationSettings('Topics').loadSDK &&
        !isConsumer(undefined, mailboxInfo)
    ) {
        const topicsSdk: TopicsSDK | undefined = tryGetTopicsSdk(mailboxInfo);
        return !!topicsSdk
            ? topicsSdk.canUserViewTopics()
            : canUserViewTopicsFromCachedData(mailboxInfo);
    } else {
        return false;
    }
}

export function isTopicsMultiAccountEnabled(): boolean {
    return isFeatureEnabled('csi-owa-topic-multi-account-all-users');
}

export function isTopicsMultiAccountEnabledForHVCAndSetting(): boolean {
    // will enable this for both business and consumer users
    return isFeatureEnabled('csi-owa-topic-multi-account-HVC-setting');
}

/**
 * Returns true for enable viva topics setting.
 *
 * @returns
 * true if there is one Topics MVP feature is enabled. Otherwise return false
 */
export function isVivaTopicHighlightsSettingEnabled(): boolean | undefined {
    return isTopicsMultiAccountEnabledForHVCAndSetting()
        ? getMailAccountSources().some(accountSource => {
              return isTopicsMvpEnabled(accountSource.mailboxInfo);
          })
        : isTopicsMvpEnabled(getGlobalSettingsAccountMailboxInfo());
}

/**
 * Checks if the topic highlights flight is enabled or if the topic highlights are enabled for a EAP user.
 * @param mailboxInfo
 * @returns true if highlights are allowed
 */
export function areTopicsHighlightsAllowed(mailboxInfo: MailboxInfo): boolean {
    return (
        isFeatureEnabled('csi-owa-auto-topic-highlights') ||
        isTopicsHighlightsEAPEnabled(mailboxInfo)
    );
}

/**
 * Returns true/false it checks the flight in EAP and if the topics highlights are enabled for the EAP user.
 * @param mailboxInfo
 * @returns true if the EAP flight is enabled and if the user is a previewUser
 */
function isTopicsHighlightsEAPEnabled(mailboxInfo: MailboxInfo): boolean {
    return isFeatureEnabled('csi-owa-topic-highlights-EAP') && !!isPreviewUser(mailboxInfo);
}

/**
 * Returns true/false it checks the flight and if the topics badges are enabled for the EAP user.
 * @param mailboxInfo
 * @returns true if the EAP flight is enabled and the user is a previewUser
 */
function areTopicBadgesEAPEnabled(mailboxInfo: MailboxInfo): boolean {
    return isFeatureEnabled('csi-owa-topic-badges-EAP') && !!isPreviewUser(mailboxInfo);
}

/**
 * Returns true/false/undefined for checking Read permissions from the cached data.
 *
 * @remarks
 * This is useful when the Topics SDK is not loaded to check if the user has permissions without loading the SDK.
 * Returns undefined when there is no cached data.
 */
function canUserViewTopicsFromCachedData(
    mailboxInfo: MailboxInfo,
    previewOnly?: boolean
): boolean | undefined {
    let userConfig;
    if (isTopicsMultiAccountEnabled()) {
        // once we graduate this flight, we could use getAccountInfo() instead to get the proper AccountInfo.
        userConfig = getAccountScopeUserSettings(mailboxInfo);
    } else {
        userConfig = getUserConfiguration();
    }

    const tenantAadObjectId = userConfig.SessionSettings?.ExternalDirectoryTenantGuid;
    const userAadObjectId = userConfig.SessionSettings?.ExternalDirectoryUserGuid;
    return tryIsUserEnabled({ tenantAadObjectId, userAadObjectId }, 'Read', previewOnly);
}

/**
 * Check if user has enabled/disabled Viva Topic suggestions in Editor via UserConfiguration
 * @returns true if the UserConfiguration allows showing Viva Topics while composing email
 */
function isEditorSuggestionsSettingEnabled(): boolean {
    return isEditorTopicsSuggestionsBitSet();
}

/**
 * Check if a Viva Topics preview user (Early adopter) and the flight for topic highlights is enabled.
 * @returns true if the user is a preview user and the flight is enabled
 * @returns false if the is not a preview user or the Topics SDK is not loaded or the flight is disabled
 */
function isPreviewUser(mailboxInfo: MailboxInfo): boolean | undefined {
    const topicsSdk: TopicsSDK | undefined = tryGetTopicsSdk(mailboxInfo);
    return !!topicsSdk
        ? topicsSdk.isPreviewUser()
        : canUserViewTopicsFromCachedData(mailboxInfo, true);
}
