import { type AddinCommandSurfaceItem, ExtensibilityModeEnum } from 'owa-addins-types';
import { type MailboxInfo } from 'owa-client-types';
import { owaComputedFn } from 'owa-computed-fn';
import getAddinCollection from './getAddinCollection';
import { invalidAddInsFilter } from './getAddinFilters';
import { getReadHostItemIndex } from 'owa-addins-store/lib/utils/hostItemIndexUtils';

const getValidAddins = owaComputedFn(function getValidAddins(
    hostItemIndex: string,
    isSharedItem: boolean,
    mailboxInfo: MailboxInfo,
    targetWindow: Window,
    mode: ExtensibilityModeEnum
): AddinCommandSurfaceItem[] {
    return getAddinCollection(mode, isSharedItem, hostItemIndex, mailboxInfo, targetWindow).filter(
        invalidAddInsFilter
    );
});

export function getAddinAsSurfaceItem(
    itemId: string | undefined,
    isSharedItem: boolean,
    mailboxInfo: MailboxInfo,
    addinIdentity: string[],
    windowRetriever?: (tabId?: string) => Window,
    projectionTabId?: string
): AddinCommandSurfaceItem | undefined {
    const targetWindow: Window =
        projectionTabId && windowRetriever ? windowRetriever(projectionTabId) : window;
    const hostItemIndex: string = getReadHostItemIndex(itemId ?? '');
    const configuredAddins: AddinCommandSurfaceItem[] = getValidAddins(
        hostItemIndex,
        isSharedItem,
        mailboxInfo,
        targetWindow,
        ExtensibilityModeEnum.MessageRead
    );

    //   return the first addin that matches
    return configuredAddins.find(configuredAddin => {
        return addinIdentity.some(
            addinId =>
                configuredAddin.id.localeCompare(addinId, undefined, {
                    sensitivity: 'base',
                }) === 0 // case-insensitive comparison
        );
    });
}
