import { isAccountSourceListStoreInitialized } from 'owa-account-source-list-store';
import { logStartGreyError } from 'owa-analytics-start';
import type { MailboxInfo } from 'owa-client-types';
import { getAccountSettingsInternal } from './getAccountSettingsInternal';
import { mailboxInfoOrTemporaryGlobal } from './mailboxInfoOrTemporaryGlobal';

export default function folderNameToId(folderName: string, mailboxInfo?: MailboxInfo): string {
    if (!folderName) {
        const error = new Error('FolderNameToIdInvalidParameters');
        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logStartGreyError(error.message, error);
        return '';
    }

    if (!isAccountSourceListStoreInitialized()) {
        const error = new Error('FolderNameToIdUninitializedAccountSourceListStore');
        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logStartGreyError(error.message, error);
        return '';
    }

    const settings = getAccountSettingsInternal(mailboxInfoOrTemporaryGlobal(mailboxInfo));
    const folderId = settings?.defaultFolderNameToIdMap?.get(folderName);
    return folderId ?? '';
}
