import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import AutocorrectRegular from 'owa-fluent-icons-svg/lib/icons/AutocorrectRegular';
import loc from 'owa-localize';
import { autoFormatSettings } from './formatControlStrings.locstring.json';
import { lazyShowEditorAutoFormatOptions } from 'owa-editor-auto-format-common';
import type RibbonDialogControlInfo from '../schema/RibbonDialogControlInfo';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import { autoFormatOptionDesc } from '../strings/hoverTooltipDesc.locstring.json';

export const getAutoFormatSettings = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonDialogControlInfo | null,
        styles: IButtonStyles | undefined,
        keyTipTabId: number | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        const label = loc(autoFormatSettings);
        return createAppButton(
            getComposeRibbonId(6072, editorId),
            label,
            () => {
                const editingInfo = retriever(composeId);
                if (editingInfo) {
                    const { editorViewState, targetWindow } = editingInfo;
                    lazyShowEditorAutoFormatOptions.importAndExecute(
                        editorViewState,
                        targetWindow,
                        'ribbon'
                    );
                }
            },
            keyTipTabId ? constructKeytip([keyTipTabId], 6072) : undefined,
            AutocorrectRegular,
            undefined /* iconColor */,
            styles,
            {
                disabled: isDisabled,
                customTooltip: getHoverTooltip(autoFormatSettings, autoFormatOptionDesc),
            }
        );
    }
);
