import type { IAttachmentLocalComputerFilePicker } from 'owa-attachment-filepicker';
import type { SurfaceActionItem } from 'owa-surface-actions';
import { logUserAction } from 'owa-surface-actions';
import type { ComposeActionKey } from 'owa-outlook-service-option-store';

const logCSAUserAction = (
    action: ComposeActionKey,
    item: SurfaceActionItem,
    suffix: string = ''
) => {
    logUserAction(true /* isCompose */, `${action}${suffix}`, item);
};

export const onAddInlineImage = (
    computerAttachmentPicker: React.RefObject<IAttachmentLocalComputerFilePicker>
) => {
    return (customAttachmentHandler?: (files: File[]) => void) => {
        const key = 'AddInlineImage';
        computerAttachmentPicker.current?.click(customAttachmentHandler);
        logCSAUserAction(key, { key });
    };
};
