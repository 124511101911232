import type { Layout } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components/RibbonControlGroupMLR';
import { owaComputedFn } from 'owa-computed-fn';
import type { RibbonGroupId } from 'owa-ribbon-ids';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { type DrawControlId } from 'owa-ribbon-ids/lib/drawControlId';
import { type MailRibbonGroupId } from 'owa-ribbon-ids/lib/mailRibbonId';
import {
    sections,
    singleRow,
} from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components/RibbonControlGroupMLR/layoutTypings';
import { isTextPenSupported } from 'owa-text-pen-utils/lib/utils/isPenSupported';
export const getMLRDrawTabGroupLayouts = owaComputedFn(function getMLRDrawTabGroupLayouts(
    group: RibbonGroupId,
    props: ComposeRibbonControlDefProps
): Layout[] | undefined {
    const editorId = props.composeViewState?.editorId;
    const isHTML: boolean = props.composeViewState?.bodyType === 'HTML';
    if (group === 183) {
        return [
            sections(
                singleRow(
                    getComposeRibbonId(12005, editorId).toString(),
                    ...(isTextPenSupported() && isHTML
                        ? [getComposeRibbonId(12012, editorId).toString()]
                        : []),
                    getComposeRibbonId(12006, editorId).toString(),
                    getComposeRibbonId(12007, editorId).toString(),
                    getComposeRibbonId(12008, editorId).toString(),
                    getComposeRibbonId(12009, editorId).toString()
                )
            ),
        ];
    } else {
        return undefined;
    }
});
