import AppsAddIn from 'owa-fluent-icons-svg/lib/icons/AppsAddInRegular';
import Attach from 'owa-fluent-icons-svg/lib/icons/AttachRegular';
import CalendarDataBarRegular from 'owa-fluent-icons-svg/lib/icons/CalendarDataBarRegular';
import ClipboardPaste from 'owa-fluent-icons-svg/lib/icons/ClipboardPasteRegular';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import FontColor from 'owa-fluent-icons-svg/lib/icons/TextColorRegular';
import GroupStrings from 'owa-locstrings/lib/strings/ribbon_mail_groups.locstring.json';
import Important from 'owa-fluent-icons-svg/lib/icons/ImportantRegular';
import Loop from 'owa-fluent-mobile-brand-icons-svg/lib/icons/IcFluentLoopColor';
import { type MailRibbonGroupId } from 'owa-ribbon-ids/lib/mailRibbonId';
import MailSettings from 'owa-fluent-icons-svg/lib/icons/MailSettingsRegular';
import type { MenuDefinition } from '@1js/acui-menu/lib/components/MenuDefinition';
import type { RibbonFlyoutAnchorProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon';
import type { RibbonGroupId } from 'owa-ribbon-ids';
import SaveRegular from 'owa-fluent-icons-svg/lib/icons/SaveRegular';
import { owaComputedFn } from 'owa-computed-fn';
import { createMailAppFlyoutAnchor } from 'owa-mail-ribbon-utils/lib/createMailRibbonButton';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import loc from 'owa-localize';

const emptyMenuDefinition: MenuDefinition = {
    sections: [],
};

export const getMLRMessageTabCommonGroupFlyout = owaComputedFn(
    function getMLRMessageTabCommonGroupFlyout(
        group: RibbonGroupId,
        props: ComposeRibbonControlDefProps
    ): RibbonFlyoutAnchorProps | undefined {
        const groupId = getComposeRibbonId(group, props.composeViewState?.editorId).toString();
        switch (group) {
            case 163:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.clipboard_Group),
                    loc(GroupStrings.clipboard_Group) /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    undefined /* keytipProps */,
                    ClipboardPaste
                );
            case 157:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.basicText_Group),
                    loc(GroupStrings.basicText_Group) /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    undefined /* keytipProps */,
                    FontColor
                );
            case 174:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.format_Group),
                    loc(GroupStrings.format_Group) /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    undefined /* keytipProps */,
                    FontColor
                );
            case 161:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.insert_Group),
                    loc(GroupStrings.insert_Group) /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    undefined /* keytipProps */,
                    Attach
                );
            case 159:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.addIns_GroupButton),
                    loc(GroupStrings.addIns_GroupButton) /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    undefined /* keytipProps */,
                    AppsAddIn
                );
            case 173:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.loop_Group),
                    loc(GroupStrings.loop_Group) /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    undefined /* keytipProps */,
                    Loop
                );
            case 160:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.tags_Group),
                    loc(GroupStrings.tags_Group) /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    undefined /* keytipProps */,
                    Important
                );
            case 170:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.save_Group),
                    loc(GroupStrings.save_Group) /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    undefined /* keytipProps */,
                    SaveRegular
                );
            case 171:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.findTime_GroupButton),
                    loc(GroupStrings.findTime_GroupButton) /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    undefined /* keytipProps */,
                    CalendarDataBarRegular
                );
            case 162:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.options_Group),
                    loc(GroupStrings.options_Group) /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    undefined /* keytipProps */,
                    MailSettings
                );
            default:
                return undefined;
        }
    }
);
