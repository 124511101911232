import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import type { FindTimePanelSource } from 'findtime-compose';
import { lazyOpenMeetingPollPane } from 'findtime-compose';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import CalendarDataBarRegular from 'owa-fluent-icons-svg/lib/icons/CalendarDataBarRegular';
import loc from 'owa-localize';
import { meetingPoll } from 'owa-locstrings/lib/strings/findtime.locstring.json';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import { findTimeDescription } from '../strings/hoverTooltipDesc.locstring.json';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';

const showFindTimeComposePane = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonMailComposeInfo | null,
        source: FindTimePanelSource
    ) => {
        const editingInfo = retriever(composeId);
        const composeViewState = editingInfo?.composeViewState;
        const toRecipients =
            composeViewState?.toRecipientWell?.recipients?.map(
                recipient => recipient.persona.EmailAddress
            ) ?? [];
        const ccRecipients =
            composeViewState?.ccRecipientWell?.recipients?.map(
                recipient => recipient.persona.EmailAddress
            ) ?? [];

        if (editingInfo) {
            lazyOpenMeetingPollPane.importAndExecute(
                editorId,
                source,
                toRecipients,
                ccRecipients,
                (editingInfo?.composeViewState?.subject || '').trim(),
                editingInfo.targetWindow,
                editingInfo?.composeViewState?.mailboxInfo,
                editingInfo?.composeViewState?.composeId
            );
        }
    }
);

export const getFindTime = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonMailComposeInfo | null,
        source: FindTimePanelSource,
        styles: IButtonStyles | undefined,
        keyTipTabId: number | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        return createAppButton(
            getComposeRibbonId(7011, editorId),
            loc(meetingPoll),
            getComputedCallback(
                7011,
                showFindTimeComposePane,
                editorId,
                composeId,
                retriever,
                source
            ),
            keyTipTabId ? constructKeytip([keyTipTabId], 7011) : undefined,
            CalendarDataBarRegular,
            undefined /* iconColor */,
            styles,
            {
                customTooltip: getHoverTooltip(meetingPoll, findTimeDescription),
                disabled: isDisabled,
            }
        );
    }
);
