import { lazyDrawCanvas } from 'owa-editor-ribbonplugin-store';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import type { InputMethod } from '@1js/acui-common/lib/commanding/InputMethod';

const onDrawCanvas = (
    composeId: string,
    retriever: ((composeId: string) => RibbonMailComposeInfo | null) | undefined,
    inputMethod: InputMethod | undefined
) => {
    const editingInfo = retriever && retriever(composeId);

    if (editingInfo) {
        const drawViewState = editingInfo.composeViewState.ribbon.drawViewState;
        lazyDrawCanvas.importAndExecute(
            editingInfo.composeViewState.editorId,
            drawViewState,
            editingInfo.composeViewState,
            inputMethod
        );
    }
};

export default onDrawCanvas;
