import { LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "DrawControlsIcons" */ './lazyIndex')
);

export const lazyGetBlackPenIcon = new LazyImport(lazyModule, m => m.getBlackPenIcon);
export const lazyGetRedPenIcon = new LazyImport(lazyModule, m => m.getRedPenIcon);
export const lazyGetHighlighterIcon = new LazyImport(lazyModule, m => m.getHighlighterIcon);
export const lazyGetEffectPenIcon = new LazyImport(lazyModule, m => m.getEffectPenIcon);
export const lazyGetEraserIcon = new LazyImport(lazyModule, m => m.getEraserIcon);
export const lazyGetTextPenIcon = new LazyImport(lazyModule, m => m.getTextPenIcon);
export const lazyGetDrawToolClayButtonStyles = new LazyImport(
    lazyModule,
    m => m.getDrawToolClayButtonStyles
);
