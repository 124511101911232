import type { Layout } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components/RibbonControlGroupMLR';
import {
    multiRow,
    sections,
    singleRow,
    stack,
} from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components/RibbonControlGroupMLR/layoutTypings';
import { owaComputedFn } from 'owa-computed-fn';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import type { RibbonGroupId } from 'owa-ribbon-ids';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { type MailRibbonGroupId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';

export const getMLRFormatTabGroupLayouts = owaComputedFn(function getMLRFormatTabGroupLayouts(
    group: RibbonGroupId,
    props: ComposeRibbonControlDefProps
): Layout[] | undefined {
    const editorId = props.composeViewState?.editorId;
    switch (group) {
        case 102:
            return [
                sections(
                    singleRow(
                        getComposeRibbonId(6013, editorId).toString(),
                        getComposeRibbonId(6011, editorId).toString(),
                        stack(
                            getComposeRibbonId(6009, editorId).toString(),
                            getComposeRibbonId(6010, editorId).toString(),
                            getComposeRibbonId(6029, editorId).toString()
                        )
                    )
                ),
            ];

        case 134:
            return [
                sections(
                    multiRow(
                        [
                            getComposeRibbonId(6027, editorId).toString(),
                            getComposeRibbonId(6028, editorId).toString(),
                            getComposeRibbonId(6024, editorId).toString(),
                            getComposeRibbonId(6030, editorId).toString(),
                        ],
                        [
                            getComposeRibbonId(6001, editorId).toString(),
                            getComposeRibbonId(6002, editorId).toString(),
                            getComposeRibbonId(6003, editorId).toString(),
                            getComposeRibbonId(6004, editorId).toString(),
                            getComposeRibbonId(6022, editorId).toString(),
                            getComposeRibbonId(6023, editorId).toString(),
                            getComposeRibbonId(6026, editorId).toString(),
                            getComposeRibbonId(6025, editorId).toString(),
                            getComposeRibbonId(6073, editorId).toString(),
                        ]
                    )
                ),
                sections(
                    multiRow(
                        [
                            getComposeRibbonId(6027, editorId).toString(),
                            getComposeRibbonId(6028, editorId).toString(),
                        ],
                        [
                            getComposeRibbonId(6001, editorId).toString(),
                            getComposeRibbonId(6002, editorId).toString(),
                            getComposeRibbonId(6003, editorId).toString(),
                            getComposeRibbonId(6004, editorId).toString(),
                            getComposeRibbonId(6022, editorId).toString(),
                            getComposeRibbonId(6023, editorId).toString(),
                            getComposeRibbonId(6030, editorId).toString(),
                        ],
                        [
                            getComposeRibbonId(6026, editorId).toString(),
                            getComposeRibbonId(6025, editorId).toString(),
                            getComposeRibbonId(6024, editorId).toString(),
                            getComposeRibbonId(6073, editorId).toString(),
                        ]
                    )
                ),
            ];

        case 135:
            return [
                sections(
                    multiRow(
                        [
                            getComposeRibbonId(6005, editorId).toString(),
                            getComposeRibbonId(6006, editorId).toString(),
                            getComposeRibbonId(6008, editorId).toString(),
                            getComposeRibbonId(6007, editorId).toString(),
                            getComposeRibbonId(6017, editorId).toString(),
                            getComposeRibbonId(6018, editorId).toString(),
                        ],
                        [
                            getComposeRibbonId(6019, editorId).toString(),
                            getComposeRibbonId(6110, editorId).toString(),
                            getComposeRibbonId(6075, editorId).toString(),
                            getComposeRibbonId(6020, editorId).toString(),
                            getComposeRibbonId(6021, editorId).toString(),
                            getComposeRibbonId(6016, editorId).toString(),
                        ]
                    )
                ),
            ];

        default:
            return undefined;
    }
});
