import { getSelectedTableView, getListViewState, isItemPartOperation } from 'owa-mail-list-store';
import { lazyMoveItemsBasedOnNodeIds, lazyArchiveMailListRows } from 'owa-mail-triage-action';
import { resetFocus } from 'owa-mail-focus-manager';
import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';
import type { ActionSource } from 'owa-analytics-types';
import { lazyOnSingleSelectionChanged } from 'owa-mail-mark-read-actions';
import { startAnimationOrHandleActionAsNeeded } from 'owa-mail-list-item-animation-store';
import getSelectedFolderMailboxInfoOrDefault from 'owa-mail-store/lib/utils/getSelectedFolderMailboxInfoOrDefault';

export default async function onArchive(actionSource: ActionSource) {
    const tableView = getSelectedTableView();
    const rowKeys = [...tableView.selectedRowKeys.keys()];
    const nodeIds =
        actionSource === 'ContextMenu'
            ? getListViewState().expandedConversationViewState.allNodeIds
            : getListViewState().expandedConversationViewState.selectedNodeIds;
    let handleArchive: () => void;
    //Mark as read when archive from menu
    if (tableView.selectedRowKeys.size === 1) {
        const selectedRowKey = [...tableView.selectedRowKeys.keys()][0];
        lazyOnSingleSelectionChanged.importAndExecute(selectedRowKey, tableView, true);
    }

    if (isItemPartOperation()) {
        const moveItemsBasedOnNodeIds = await lazyMoveItemsBasedOnNodeIds.import();
        handleArchive = () => {
            moveItemsBasedOnNodeIds(
                nodeIds,
                folderNameToId('archive', getSelectedFolderMailboxInfoOrDefault()),
                tableView.id,
                actionSource
            );
        };
    } else {
        handleArchive = () => {
            lazyArchiveMailListRows.importAndExecute(rowKeys, tableView, actionSource);
        };
    }

    if (!isItemPartOperation()) {
        startAnimationOrHandleActionAsNeeded('Archive' /*actionId*/, rowKeys, handleArchive);
    } else {
        handleArchive();
    }
    resetFocus('archiveCommand');
}
