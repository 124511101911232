import { getStore } from 'owa-protection-store/lib/store/protectionStore';
import type { MailboxInfo } from 'owa-client-types';
import type ItemCLPInfo from 'owa-mail-store/lib/store/schema/ItemCLPInfo';
import type CLPLabel from 'owa-mail-protection-types/lib/schema/CLPLabel';
import getInitialItemCLPInfo from './getInitialItemCLPInfo';
import { isNullOrWhiteSpace } from 'owa-string-utils';
const GUID_REGEX = /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/g;

function getExistingCLPInfo(
    existingLabelString: string | undefined,
    mailboxInfo: MailboxInfo
): ItemCLPInfo {
    const existingCLPInfo = getInitialItemCLPInfo();
    if (!existingLabelString) {
        return existingCLPInfo;
    }

    const labelFields = existingLabelString.split(';');

    const existingLabels: {
        [key: string]: string[];
    } = {};

    for (let i = 0; i < labelFields.length; i++) {
        const curLabelField = labelFields[i];
        if (!curLabelField) {
            continue;
        }

        const idList = curLabelField.match(GUID_REGEX);
        if (!idList || idList.length == 0) {
            continue;
        }

        const id = idList[0];
        if (existingLabels[id]) {
            existingLabels[id].push(curLabelField);
        } else {
            existingLabels[id] = [curLabelField];
        }
    }

    const ids = Object.keys(existingLabels);
    for (let i = 0; i < ids.length; i++) {
        const setLabel = getLabelFromStore(ids[i], mailboxInfo);
        if (setLabel) {
            existingCLPInfo.selectedLabel = setLabel;
            delete existingLabels[ids[i]];
            break;
        }
    }
    Object.values(existingLabels).forEach(fields => {
        existingCLPInfo.nonTenantLabelString += `${fields.join(';')};`;
    });

    return existingCLPInfo;
}

export function getLabelFromStore(
    id: string | null | undefined,
    mailboxInfo: MailboxInfo
): CLPLabel | null {
    if (isNullOrWhiteSpace(id)) {
        return null;
    }
    const { clpLabels } = getStore(mailboxInfo);
    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return getLabelFromId(id!, clpLabels);
}

function getLabelFromId(id: string, labels: CLPLabel[]): CLPLabel {
    if (!labels) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (69,9): Type 'null' is not assignable to type 'CLPLabel'.
        // @ts-expect-error
        return null;
    }

    for (let i = 0; i < labels.length; i++) {
        const label = labels[i];
        if (label.id == id) {
            return label;
        }
        const childLabelFromId = getLabelFromId(id, label.children);
        if (childLabelFromId) {
            return childLabelFromId;
        }
    }
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (85,5): Type 'null' is not assignable to type 'CLPLabel'.
    // @ts-expect-error
    return null;
}

export default getExistingCLPInfo;
