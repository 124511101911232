import { LazyModule, LazyAction, LazyImport } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "YammerPrebootstrap" */ './lazyIndex')
);

// Export lazy actions
export const lazyInitializeConfigForYammer = new LazyAction(
    lazyModule,
    m => m.initializeConfigForYammer
);

export const lazyVivaEngageIconOutline = new LazyImport(lazyModule, m => m.VivaEngageIconOutline);

export const lazyVivaEngageIconOutlineWithPadding = new LazyImport(
    lazyModule,
    m => m.VivaEngageIconOutlineWithPadding
);
