import type FocusedItemPart from 'owa-mail-reading-pane-store/lib/store/schema/FocusedItemPart';
import getConversationReadingPaneViewState from '../utils/getConversationReadingPaneViewState';
import { mutatorAction } from 'satcheljs';

export default mutatorAction(
    'setFocusedItemPart',
    function setFocusedItemPart(focusedItemPart?: FocusedItemPart, conversationId?: string) {
        const conversationReadingPaneViewState = getConversationReadingPaneViewState();
        // This action might be invoked in a callback function,
        // if a different conversation has been loaded before this action is called, skip.
        if (
            !conversationReadingPaneViewState ||
            conversationReadingPaneViewState.conversationId.Id != conversationId
        ) {
            return;
        }
        conversationReadingPaneViewState.focusedItemPart = focusedItemPart;
    }
);
