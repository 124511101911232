import { isFeatureEnabled } from 'owa-feature-flags';
import type { SearchScope } from 'owa-search-service/lib/data/schema/SearchScope';
import { SearchScopeKind } from 'owa-search-service/lib/data/schema/SearchScope';
import { SearchProvider } from 'owa-search-service/lib/data/schema/SearchProvider';
import is3SServiceAvailable from '../utils/is3SServiceAvailable';
import { getUserConfiguration } from 'owa-session-store';
import type { SearchScenarioId } from 'owa-search-store';
import { getScenarioStore } from 'owa-search-store';
import { isOffline } from 'owa-offline/lib/isOffline';

// Cache to store search providers for each search session
const searchProviderCache: Map<string, SearchProvider> = new Map();

export default function getSearchProvider(
    searchScope: SearchScope,
    scenarioId: SearchScenarioId
): SearchProvider {
    const scopeKind = searchScope?.kind;
    const searchSessionGuid = getScenarioStore(scenarioId).searchSessionGuid;

    if (searchProviderCache.has(searchSessionGuid)) {
        const searchProviderFromCache = searchProviderCache.get(searchSessionGuid);
        if (searchProviderFromCache) {
            return searchProviderFromCache;
        }
    }

    if (isFeatureEnabled('sea-queryTree')) {
        if (scopeKind === SearchScopeKind.PstFile) {
            if (searchSessionGuid) {
                searchProviderCache.set(searchSessionGuid, SearchProvider.PST);
            }
            return SearchProvider.PST;
        }
        if (isOffline()) {
            if (searchSessionGuid) {
                searchProviderCache.set(searchSessionGuid, SearchProvider.Offline);
            }
            return SearchProvider.Offline;
        }
    }

    if (scopeKind === SearchScopeKind.SharedFolders) {
        const searchProvider = is3SServiceAvailable()
            ? SearchProvider.SubstrateV2
            : SearchProvider.FindItem;
        if (searchSessionGuid) {
            searchProviderCache.set(searchSessionGuid, searchProvider);
        }
        return searchProvider;
    }

    if (scopeKind === SearchScopeKind.PublicFolder) {
        const searchProvider = SearchProvider.FindItem;
        if (searchSessionGuid) {
            searchProviderCache.set(searchSessionGuid, searchProvider);
        }
        return searchProvider;
    }

    if (getUserConfiguration().SessionSettings?.IsExplicitLogon) {
        const searchProvider = searchProviderForExplicitLogon(scopeKind);
        if (searchSessionGuid) {
            searchProviderCache.set(searchSessionGuid, searchProvider);
        }
        return searchProvider;
    }

    /**
     * Key off of the staticSearchScope to determine which search provider to use.
     * This is determined by whether or not 3S supports the given scenario.
     */
    if (
        scopeKind === SearchScopeKind.PrimaryMailbox ||
        scopeKind === SearchScopeKind.ArchiveMailbox
    ) {
        const searchProvider = is3SServiceAvailable()
            ? SearchProvider.SubstrateV2
            : SearchProvider.ExecuteSearch;
        if (searchSessionGuid) {
            searchProviderCache.set(searchSessionGuid, searchProvider);
        }
        return searchProvider;
    } else if (scopeKind === SearchScopeKind.Group) {
        const searchProvider =
            is3SServiceAvailable() && isFeatureEnabled('grp-3sSearch')
                ? SearchProvider.SubstrateV2
                : SearchProvider.ExecuteSearch;
        if (searchSessionGuid) {
            searchProviderCache.set(searchSessionGuid, searchProvider);
        }
        return searchProvider;
    }

    // Default to 3Sv2
    if (searchSessionGuid) {
        searchProviderCache.set(searchSessionGuid, SearchProvider.SubstrateV2);
    }
    return SearchProvider.SubstrateV2;
}

function searchProviderForExplicitLogon(scopeKind?: SearchScopeKind): SearchProvider {
    if (scopeKind === SearchScopeKind.PrimaryMailbox) {
        return is3SServiceAvailable() ? SearchProvider.SubstrateV2 : SearchProvider.ExecuteSearch;
    } else if (scopeKind === SearchScopeKind.ArchiveMailbox) {
        return is3SServiceAvailable() ? SearchProvider.SubstrateV2 : SearchProvider.FindItem;
    } else if (scopeKind === SearchScopeKind.Group) {
        return is3SServiceAvailable() && isFeatureEnabled('grp-3sSearch')
            ? SearchProvider.SubstrateV2
            : SearchProvider.ExecuteSearch;
    }

    return SearchProvider.SubstrateV2; // Default to SubstrateV2 for explicit logon
}
