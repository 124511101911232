import { createLazyComponent, LazyAction, LazyModule, LazyImport } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "NotificationBar" */ './lazyIndex'),
    { name: 'NotificationBar' }
);

export const NotificationBarHost = createLazyComponent(lazyModule, m => m.NotificationBarHost);

export const lazyShowNotification = new LazyAction(lazyModule, m => m.showNotification);

export const lazyDismissNotification = new LazyAction(lazyModule, m => m.dismissNotification);

export const lazyClearNotificationBar = new LazyImport(lazyModule, m => m.clearNotificationBar);
export type { default as NotificationBarHostId } from './store/schema/NotificationBarHostId';
export type { NotificationBarOptions } from './orchestrators/showNotification';
export { NotificationBarCallbackReason } from './callbacksMap/NotificationBarCallbackReason';
