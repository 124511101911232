import React from 'react';
import type { ICheckboxProps } from '@fluentui/react/lib/Checkbox';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import {
    slvStyles,
    checkmarkStyles,
    squareCheckbox,
    selectedMessageCheckboxStyle,
    indeterminateOrCheckedStyle,
    indeterminateOrCheckedSlvStyle,
    rowHoverStyles,
} from './Checkbox.scss';

import { observer } from 'owa-mobx-react';

import classnames from 'owa-classnames';

export interface FluentCheckboxProps extends ICheckboxProps {
    isMessageSelected?: boolean;
    isSLVStyleApplied?: boolean;
    isMailListItemHovered?: boolean;
}

export default observer(function FluentCheckbox(props: FluentCheckboxProps) {
    const { checked, indeterminate, isMessageSelected, isSLVStyleApplied, isMailListItemHovered } =
        props;

    const checkboxStyles = React.useMemo(
        () => ({
            ...props.styles,
            checkbox: classnames(
                squareCheckbox,
                isSLVStyleApplied && slvStyles,
                isMessageSelected && selectedMessageCheckboxStyle,
                isSLVStyleApplied && isMailListItemHovered && rowHoverStyles,
                (checked || indeterminate) &&
                    (isSLVStyleApplied
                        ? indeterminateOrCheckedSlvStyle
                        : indeterminateOrCheckedStyle)
            ),
            checkmark: checkmarkStyles,
        }),
        [
            props.styles,
            isMessageSelected,
            checked,
            indeterminate,
            isSLVStyleApplied,
            isMailListItemHovered,
        ]
    );

    return <Checkbox {...props} styles={checkboxStyles} />;
}, 'FluentCheckbox');
