import type { OcsPatchEntry } from 'owa-ocs-service';
import { OcsSettingScope, OcsSettingType, OcsVersion, writeOcsSettings } from 'owa-ocs-service';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { CONDITIONAL_FORMATTING_SETTING_NAME } from '../utils/constants';
import type { MailboxInfo } from 'owa-client-types';
import { logUsage } from 'owa-analytics';

export default function saveConditionalFormattingRulesService(
    mailboxInfo: MailboxInfo,
    rules: string
) {
    const ocsConditionalFormattingPatch: OcsPatchEntry = {
        name: CONDITIONAL_FORMATTING_SETTING_NAME,
        value: rules,
        type: OcsSettingType.Json,
        scope: getAccountScopeUserSettings(mailboxInfo).SessionSettings?.UserEmailAddress || '',
        itemClass: 'RoamingSetting',
    };
    writeOcsSettings(mailboxInfo, OcsVersion.V1, OcsSettingScope.Account, [
        ocsConditionalFormattingPatch,
    ]).catch(err => {
        logUsage('TnS_ConditionalFormatting_ErrorSave', {
            message: err.message,
        });
    });
}
