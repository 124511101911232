import { FocusZoneDirection } from '@fluentui/react';
import type { AppControlProps } from '@1js/acui-ribbon-like';
import type { MenuDefinition } from '@1js/acui-menu/lib/components/MenuDefinition/MenuDefinition';

export function getMenuDefinitionForTableControls(controls: AppControlProps[]): MenuDefinition {
    return {
        sections: [
            {
                controls,
            },
        ],
        focusZoneProps: {
            isCircularNavigation: true,
            direction: FocusZoneDirection.domOrder,
        },
    };
}
