import { sanitize } from 'dompurify';

let trustedTypesCustomPolicy: TrustedTypePolicy | undefined;
if (window.trustedTypes) {
    trustedTypesCustomPolicy = window.trustedTypes.createPolicy('trustedTypesCustomPolicy ', {
        createHTML: (input: string, config: DOMPurify.Config = {}): string => {
            if (input === '') {
                return '';
            } else {
                return sanitize(input, {
                    RETURN_TRUSTED_TYPE: true,
                    USE_PROFILES: { html: true },
                    ...config,
                }).toString();
            }
        },
        createScriptURL: (input: string): string => input,
        createScript: (input: string): string => input,
    });
}

export { trustedTypesCustomPolicy };
