import { LazyAction, LazyModule, createLazyComponent } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "InlineTranslation"*/ './lazyIndex'),
    {
        name: 'InlineTranslation',
    }
);

export const lazySetItemLocale = new LazyAction(lazyModule, m => m.setItemLocale);
export const lazyTranslateItem = new LazyAction(lazyModule, m => m.translateItem);
export const lazyUntranslateItem = new LazyAction(lazyModule, m => m.untranslateItem);
export const TranslationFeedbackRibbon = createLazyComponent(
    lazyModule,
    m => m.TranslationFeedbackRibbon
);
