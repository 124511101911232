import { lazySelectGroup } from 'owa-mail-folder-forest-actions';
import {
    lazyGroupHeaderCommandBarAction,
    lazyGroupHeaderNavigationButton,
} from 'owa-group-header-actions';
import { setSharePointItemsViewNavigationTargetPath } from 'owa-group-files-hub-store';
import type { MailGroupRouteParameters } from './utils/mailGroupRouteSettings';
import { ensureValidGroupSmtpAddress } from './utils/mailGroupRouteSettings';
import { logUsage } from 'owa-analytics';
import { getSourceQueryParam } from 'owa-querystring';
import { getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { lazySwitchToGroupsModule } from 'owa-groups-dash-nav-actions';
import getGroupsAppIsOptedIn from 'owa-groups-toggle-store/lib/utils/getGroupsAppIsOptedIn';
import isGroupsSwitchEnabled from 'owa-groups-toggle-store/lib/utils/isGroupsSwitchEnabled';
import { lazyLoadGroupsList } from 'owa-groups-left-nav-actions/lib/lazyFunction';
import GroupsDashTabId from 'owa-groups-dash-content-store/lib/store/schema/GroupsDashTabId';

export default async function mailGroupFilesRouteHandler(
    parameters: MailGroupRouteParameters,
    allParameters?: string[] | undefined
) {
    const groupSmtp = ensureValidGroupSmtpAddress(parameters);

    if (!groupSmtp) {
        return;
    }
    logUsage('MailGroupFilesRouteHandlerEvent', { source: getSourceQueryParam() });

    const commandBarAction = await lazyGroupHeaderCommandBarAction.import();
    const groupHeaderNavigationButton = await lazyGroupHeaderNavigationButton.import();

    commandBarAction(groupHeaderNavigationButton.Files);
    const mailboxInfo = getGlobalSettingsAccountMailboxInfo();

    if (isGroupsSwitchEnabled() && getGroupsAppIsOptedIn(mailboxInfo)) {
        const loadGroupsList = await lazyLoadGroupsList.import();
        await loadGroupsList(mailboxInfo, false /*isReload*/, true /*SubscribeAll */);
        lazySwitchToGroupsModule.importAndExecute(
            mailboxInfo,
            groupSmtp,
            undefined /*folderId*/,
            GroupsDashTabId.Files,
            'MailAppGroupsFilesRouting'
        );
        return;
    }

    await lazySelectGroup.importAndExecute(groupSmtp, 'groups', mailboxInfo);

    const spPath = allParameters && allParameters.length > 2 ? allParameters[2] : null;
    if (spPath) {
        const realSpPath = '/' + spPath;
        setSharePointItemsViewNavigationTargetPath(groupSmtp, realSpPath);
    } else {
        setSharePointItemsViewNavigationTargetPath(groupSmtp, '');
    }
}
