import initializeMailRibbonConfiguration from '../actions/initializeMailRibbonConfiguration';
import { setTabControlsInOverflow } from '../actions/setTabControlsInOverflow';
import { getStore } from '../store/store';
import { hasEnterpriseAccount } from './hasEnterpriseAccount';
import { OnlyMailStateChanges, RegisterForAccountStateChange } from 'owa-account-source-list';
import { logUsage } from 'owa-analytics';
import { isFeatureEnabled } from 'owa-feature-flags';
import { defaultConfig } from 'owa-mail-ribbon-store-shared-types';
import { ribbonValidityCheck } from 'owa-mail-ribbon-versioning/lib/ribbonValidityCheck';
import { ribbonVersionHandler } from 'owa-mail-ribbon-versioning/lib/ribbonVersionHandler';
import { type MailRibbonGroupId, type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { getAccountScopeUserSettings } from 'owa-session-store';
import { updateAccountScopeUserSettingsAndService } from 'owa-userconfiguration/lib/utils/updateAccountScopeUserSettingsAndService';
import {
    getGlobalSettingsAccountMailboxInfo,
    type AccountSource,
} from 'owa-account-source-list-store';
import type { MailboxInfo } from 'owa-client-types';
import type {
    MailRibbonConfigStore,
    RibbonConfiguration,
} from 'owa-mail-ribbon-store-shared-types';

/**
 * Load the appropriate mail ribbon configuration for all of the user's accounts
 */
export default function loadMailRibbonConfiguration() {
    loadMailRibbonConfigurationHelper(getGlobalSettingsAccountMailboxInfo());
    if (isFeatureEnabled('mon-ribbon-customization-multiAccount')) {
        // Use the RegisterForAccountStateChange to be called for all accounts that are pre-render complete
        // either now or in the future
        RegisterForAccountStateChange(
            (accountSource: AccountSource) => {
                loadMailRibbonConfigurationHelper(accountSource.mailboxInfo);
            },
            (_accountSource: AccountSource) => {}, // no-op the pre-account removed callback
            OnlyMailStateChanges
        );
    }
}

/**
 * Load the appropriate mail ribbon configuration for this mailboxInfo
 * This function calls owa-session-store's @see {getAccountScopeUserSettings} to pull in the
 * user's persisted ribbon. If they have never persisted before, it'll be an empty string.
 */
async function loadMailRibbonConfigurationHelper(mailboxInfo: MailboxInfo) {
    // If we've already loaded the config, we don't need to do it again
    if (getStore(mailboxInfo).hasLoaded) {
        return;
    }

    /**
     * We start with an initial initialization of Ribbon, disregarding any customizations.
     * This allows the Ribbon to load quickly, and then we can update it later with the user's customization,
     * once it has done all the validityChecking and customization checking.
     */
    initializeMailRibbonConfiguration(defaultConfig, mailboxInfo);

    if (isFeatureEnabled('mon-ribbon-customization-hard-reset')) {
        hardResetRibbon(mailboxInfo);
        return;
    }

    const ribbonConfigJSON =
        getAccountScopeUserSettings(mailboxInfo)?.ViewStateConfiguration?.MailRibbonConfig;

    if (!ribbonConfigJSON) {
        // Default scenario of an uncustomized ribbon

        if (isFeatureEnabled('mon-default-consumer-overflow-ribbon') && !hasEnterpriseAccount()) {
            initializeConsumerMailRibbonConfiguration(mailboxInfo);
        }

        // We already default initialize the user to defaultConfig, so just logUsage and return
        logUsage('RibbonCustomization_Default');
        return;
    }

    // User has a customized ribbon.
    const ribbonConfig = JSON.parse(ribbonConfigJSON);
    logUsage('RibbonCustomization_Custom');

    // Fail safe for an erroneous state that some users are in. See ADO246765 for more details.
    if (!checkForSinglelineCustomizationGroup(ribbonConfig.singleline)) {
        logUsage('RibbonCustomization_FailSafeTriggered');
        hardResetRibbon(mailboxInfo);
        return;
    }

    // Check if the user's customized Ribbon config is on a higher version than the app's, which means
    // that the user is on an old version of the app, which is dangerous because this older version
    // may not be able to parse some of the RibbonIDs in the user's config, which may cause the app to crash.
    if (ribbonConfig.version > defaultConfig.version) {
        // Run the user's config through a validityChecker to prune out bad IDs.
        // In this scenario of user version > defaultConfig version, we never reset the user's config.
        // Instead, if there's something we can't find, we instead locally prune it from the user's ribbon.
        // This allows the user to still use the ribbon with the majority of their customizations, while also
        // pruning away any ID that this version of Outlook may not be able to render.
        if (
            ribbonValidityCheck(
                ribbonConfig.singleline,
                ribbonConfig.version,
                true /* shouldPrune */
            )
        ) {
            logUsage('RibbonVersioning_PassedValidityCheck_OlderConfigVersion', [
                ribbonConfig.version,
                defaultConfig.version,
            ]);
        } else {
            logUsage('RibbonVersioning_FailedValidityCheck_OlderConfigVersion', [
                ribbonConfig.version,
                defaultConfig.version,
            ]);
        }
        // Now, update the uncustomizable tabs of the user's configuration file to match this version's defaultConfig.
        updateUncustomizableTabs(ribbonConfig);
        initializeMailRibbonConfiguration(ribbonConfig as MailRibbonConfigStore, mailboxInfo);
    } else {
        updateUncustomizableTabs(ribbonConfig);

        // Now, check if the user's customized Ribbon config is on a lower version than the app's.
        // If so, this means we want to update the user's Ribbon config via versioning.
        if (ribbonConfig.version < defaultConfig.version) {
            ribbonVersionHandler(ribbonConfig, mailboxInfo);
            initializeMailRibbonConfiguration(ribbonConfig as MailRibbonConfigStore, mailboxInfo);
        } else {
            // ribbonConfig.version === defaultConfig.version
            // We don't have any extra work we need to do if the ribbon versions are the same, however
            // we still want to validityCheck to safeguard engineers and users in case they end up in a bad Ribbon state.
            // If we fail this validityCheck, default back to defaultConfig.
            if (!ribbonValidityCheck(ribbonConfig.singleline, ribbonConfig.version)) {
                logUsage('RibbonVersioning_FailedValidityCheck_EqualConfigVersions', [
                    ribbonConfig.version,
                    defaultConfig.version,
                ]);
            } else {
                initializeMailRibbonConfiguration(
                    ribbonConfig as MailRibbonConfigStore,
                    mailboxInfo
                );
            }
        }
    }
}

/**
 * The tabs in this function are currently uncustomizable, and therefore, don't need versioning and
 * will *always* use the default version.
 * Note that when turning on customization for these tabs, be sure to remove the tabs below so that versioning can begin.
 * Additionally, when adding a new tab, be sure to add it to this function.
 */
function updateUncustomizableTabs(ribbonConfig: any) {
    ribbonConfig.multiline = defaultConfig.multiline;
    ribbonConfig.singleline.formatTab = defaultConfig.singleline.formatTab;
    ribbonConfig.singleline.optionsTab = defaultConfig.singleline.optionsTab;
    ribbonConfig.singleline.messageTab = defaultConfig.singleline.messageTab;
    ribbonConfig.singleline.insertTab = defaultConfig.singleline.insertTab;
    ribbonConfig.singleline.notesTab = defaultConfig.singleline.notesTab;
    ribbonConfig.singleline.tableFormatTab = defaultConfig.singleline.tableFormatTab;
    ribbonConfig.singleline.readTab = defaultConfig.singleline.readTab;
    ribbonConfig.contextualTabs = defaultConfig.contextualTabs;
    ribbonConfig.singleline.helpTab = defaultConfig.singleline.helpTab;
    ribbonConfig.singleline.drawTab = defaultConfig.singleline.drawTab;
    ribbonConfig.singleline.pictureTab = defaultConfig.singleline.pictureTab;
}

/**
 * If the flight `mon-ribbon-customization-hard-reset` is turned on, then upon load
 * the ribbon will return to the default non-customizable state.
 * This is just in case versioning causes the ribbon to become in a bad state.
 */
function hardResetRibbon(mailboxInfo: MailboxInfo) {
    const ribbonConfigString: string = JSON.stringify(defaultConfig);
    updateAccountScopeUserSettingsAndService(mailboxInfo, {
        ViewStateConfiguration: {
            MailRibbonConfig: ribbonConfigString,
        },
    });
}

/**
 * Sanity check the user's single line ribbon config to make sure it's not in an egregiously erroneous state.
 * We do this by checking for the MailRibbonGroupId.Group_RibbonCustomizer, the Ribbon group that holds the customizer entry point.
 * This is in response to ADO246765, and is targetted to fix those particular users who are in the bad state.
 */
function checkForSinglelineCustomizationGroup(singlelineRibbonConfig: RibbonConfiguration) {
    return singlelineRibbonConfig.homeTab.layout.some(item => item.groupId === 122);
}

/**
 * This function is being used to initialize the ribbon from consumers that meet the following criteria:
 * - don't have any corp account added on their Monarch
 * - haven't customized their ribbon
 */
function initializeConsumerMailRibbonConfiguration(mailboxInfo: MailboxInfo) {
    const controlsToOverflow: RibbonControlId[] = [658, 509, 548, 549, 559, 571, 572, 663];

    // Update owa-mail-ribbon-store for this tab configuration.
    setTabControlsInOverflow(mailboxInfo, 'homeTab', controlsToOverflow);
}
