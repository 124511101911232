import type { IButtonStyles } from '@fluentui/react';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppToggleButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import loc from 'owa-localize';
import { type DrawControlId } from 'owa-ribbon-ids/lib/drawControlId';
import { effectPenText } from './drawControlStrings.locstring.json';
import type { RibbonDrawState } from 'owa-editor-graphic-plugin-types/lib/store/schema/RibbonDrawState';
import { type DrawTool } from 'owa-editor-graphic-plugin-types/lib/enums/DrawTool';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import onToggleDrawTool from '../actions/onToggleDrawTool';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import type { AppButtonOnExecuteParameter } from '@1js/acui-button/lib/components/AppButton/AppButton.Props';
import { getRibbonViewMode } from 'owa-command-ribbon-store';
import { lazyGetEffectPenIcon } from 'owa-compose-drawcontrols-icons';
import { getIsDarkTheme } from 'owa-fabric-theme';
import { getIsMsHighContrast } from 'owa-high-contrast';
import { getDrawButtonStyles } from '../util/getDrawButtonStyles';

export const getEffectPen = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: ((composeId: string) => RibbonMailComposeInfo | null) | undefined,
        drawViewState: RibbonDrawState | undefined,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        const effectPenIcon = lazyGetEffectPenIcon.tryImportForRender()?.(
            getRibbonViewMode(),
            getIsDarkTheme(),
            getIsMsHighContrast()
        );

        return createAppToggleButton(
            getComposeRibbonId(12008, editorId),
            loc(effectPenText),
            (parameter?: AppButtonOnExecuteParameter) => {
                const inputMethod = parameter && parameter.inputMethod;

                getComputedCallback(
                    12008,
                    onToggleDrawTool,
                    composeId,
                    3,
                    retriever,
                    inputMethod
                )();
            },
            !!(drawViewState && drawViewState.currentSelectedDrawTool === 3),
            tabId ? constructKeytip([tabId], 12008) : undefined,
            effectPenIcon,
            undefined,
            getDrawButtonStyles(styles),
            {
                disabled: isDisabled || !!(drawViewState && !drawViewState.areDrawToolsActive),
            }
        );
    }
);
