import { action } from 'satcheljs';
import type { IContextualMenuItem } from '@fluentui/react/lib/ContextualMenu';

export const launchAddinContextMenu = action(
    'launchAddinContextMenu',
    (contextMenuItems: IContextualMenuItem[], contextMenuHostId: string, tabId: string) => ({
        contextMenuItems,
        contextMenuHostId,
        tabId,
    })
);
