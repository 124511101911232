import type SmimeType from 'owa-smime-adapter/lib/store/schema/SmimeType';
import RibbonRegular from 'owa-fluent-icons-svg/lib/icons/RibbonRegular';
import LockClosedRegular from 'owa-fluent-icons-svg/lib/icons/LockClosedRegular';

const getIconForSmimeType = (smimeType: SmimeType): 'RibbonRegular' | 'LockClosedRegular' => {
    switch (smimeType) {
        case -1:
        case 6:
        case 12:
        case 13:
        case 14:
            return LockClosedRegular;
        case 11:
        case 10:
            return RibbonRegular;
        case 0:
        default:
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (25,13): Type 'null' is not assignable to type 'ControlIcons | "RibbonRegular" | "LockClosedRegular"'.
            // @ts-expect-error
            return null;
    }
};

export default getIconForSmimeType;
