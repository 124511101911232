import { observer } from 'owa-mobx-react';
import { getDensityModeCssClass, getDensityModeString } from 'owa-fabric-theme';
import React from 'react';
import { isReadingPanePositionOff, isSingleLineListView } from 'owa-mail-layout';
import { isFeatureEnabled } from 'owa-feature-flags';
import type { LoadMoreListViewHandle } from 'owa-loadmore-listview/lib/components/LoadMoreListView';
import type { VirtualizedLoadMoreListViewRef } from 'owa-loadmore-listview/lib/components/VirtualizedLoadMoreListView';
import { Icon } from '@fluentui/react/lib/Icon';
import { default as ChevronDown } from 'owa-fluent-icons-svg/lib/icons/ChevronDownRegular';
import { default as ChevronRight } from 'owa-fluent-icons-svg/lib/icons/ChevronRightRegular';
import isSenderImageEnabled from 'owa-sender-image-option/lib/utils/isSenderImageEnabled';
import type { TableView, MailFolderTableQuery } from 'owa-mail-list-store';
import { type SortColumn, getIsSearchTableShown } from 'owa-mail-list-store';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import { CategoryIcon } from 'owa-categories';

import {
    full,
    medium,
    compact,
    outerContainer,
    groupHeaderClickable,
    scl,
    senderImageOn as styles_senderImageOn,
    selectionModeActivated,
    iconContainer,
    categoryIcon,
} from './MailListGroupHeader.scss';

import classnames from 'owa-classnames';
import {
    toggleVirtualizedGroupHeaderCollapse,
    virtualizedMailGroupHeadersStore,
} from 'owa-virtualized-group-headers';

export interface MailListGroupHeaderProps {
    headerText: string;
    groupHeaderStylesAsPerUserSettings: string;
    hasSenderImageOffInFullView: boolean;
    listViewRef: React.RefObject<LoadMoreListViewHandle | VirtualizedLoadMoreListViewRef | null>;
    tableView: TableView;
    onCollapse: () => void;
}

const MailListGroupHeader = observer(function MailListGroupHeader(props: MailListGroupHeaderProps) {
    const { headerText, listViewRef, tableView, onCollapse } = props;
    const isSCL = !isSingleLineListView();
    const densityModeCssClass = getDensityModeCssClass(full, medium, compact);
    const densityModeString = getDensityModeString();
    const senderImageEnabled = isSenderImageEnabled();
    const isTableInSelectionMode = tableView?.isInCheckedMode || tableView?.isInSelectAllState;
    const isSelectionModeActivated = getIsSearchTableShown() || isTableInSelectionMode;

    const rowId = 'groupHeader' + headerText;

    const isCollapsibleGHEnabled = isFeatureEnabled('tri-virtualizedGroupHeaders');

    const groupHeaderDataFromStore = () =>
        virtualizedMailGroupHeadersStore().virtualizedGroupHeaders.get(headerText);

    const showExpansionChevron = useComputedValue(() => {
        const rowsForGroupHeaderLoaded = groupHeaderDataFromStore()?.isLoadingOfRowKeysComplete;
        return rowsForGroupHeaderLoaded;
    }, [groupHeaderDataFromStore]);

    const headerClassNames = classnames(
        outerContainer,
        showExpansionChevron && groupHeaderClickable,
        isReadingPanePositionOff()
            ? 'singleLineCirclePersonaDivWidth'
            : 'threeColumnCirclePersonaDivWidth',
        densityModeCssClass
    );

    const onVirtualizedHeaderClick = React.useCallback(() => {
        toggleVirtualizedGroupHeaderCollapse(headerText);
        listViewRef?.current?.getScrollRegion()?.dispatchEvent(new CustomEvent('scroll'));
        onCollapse();
    }, [onCollapse, headerText]);

    const iconClassName = classnames(
        iconContainer,
        isSCL && scl,
        senderImageEnabled && styles_senderImageOn,
        isSelectionModeActivated && selectionModeActivated,
        densityModeCssClass
    );

    const getGroupHeaderChevronIcon = () => {
        return virtualizedMailGroupHeadersStore().virtualizedGroupHeaders.get(headerText)
            ?.isCollapsed
            ? ChevronRight
            : ChevronDown;
    };

    const headerIconVirtualized = () => {
        if (showExpansionChevron) {
            return <Icon className={iconClassName} iconName={getGroupHeaderChevronIcon()} />;
        }

        return <></>;
    };

    const outerContainerPaddingLeft = useComputedValue(() => {
        let paddingLeft = 0;
        if (isSCL) {
            if (densityModeString === 'full') {
                if (showExpansionChevron) {
                    if (senderImageEnabled || isSelectionModeActivated) {
                        paddingLeft = 40;
                    } else if (!senderImageEnabled) {
                        paddingLeft = 10;
                    }
                } else {
                    if (senderImageEnabled || isSelectionModeActivated) {
                        paddingLeft = 72;
                    } else if (!senderImageEnabled) {
                        paddingLeft = 40;
                    }
                }
            } else if (densityModeString === 'medium') {
                if (showExpansionChevron) {
                    if (senderImageEnabled || isSelectionModeActivated) {
                        paddingLeft = 40;
                    } else if (!senderImageEnabled) {
                        paddingLeft = 14;
                    }
                } else {
                    if (senderImageEnabled || isSelectionModeActivated) {
                        paddingLeft = 68;
                    } else if (!senderImageEnabled) {
                        paddingLeft = 40;
                    }
                }
            } else {
                // compact
                if (showExpansionChevron) {
                    if (senderImageEnabled || isSelectionModeActivated) {
                        paddingLeft = 38;
                    } else if (!senderImageEnabled) {
                        paddingLeft = 14;
                    }
                } else {
                    if (senderImageEnabled || isSelectionModeActivated) {
                        paddingLeft = 64;
                    } else if (!senderImageEnabled) {
                        paddingLeft = 40;
                    }
                }
            }
        } else {
            // MCL
            if (showExpansionChevron) {
                paddingLeft = 38;
            } else {
                if (densityModeString === 'full') {
                    paddingLeft = 68;
                } else {
                    paddingLeft = 64;
                }
            }
        }

        return {
            paddingLeft: `${paddingLeft}px`,
        };
    }, [
        isSCL,
        densityModeString,
        senderImageEnabled,
        showExpansionChevron,
        isSelectionModeActivated,
    ]);

    return (
        <div
            id={rowId}
            role="heading"
            aria-level={3}
            className={headerClassNames}
            style={outerContainerPaddingLeft}
            onClick={
                isCollapsibleGHEnabled && showExpansionChevron
                    ? onVirtualizedHeaderClick
                    : undefined
            }
        >
            {isCollapsibleGHEnabled && headerIconVirtualized()}
            {(tableView?.tableQuery as MailFolderTableQuery)?.sortBy?.sortColumn === 14 ? (
                <CategoryIcon
                    categoryName={headerText}
                    mailboxInfo={getMailboxInfo(tableView)}
                    iconClassName={categoryIcon}
                />
            ) : null}
            <div title={headerText}>{headerText}</div>
        </div>
    );
}, 'MailListGroupHeader');
export default MailListGroupHeader;
