import AlignCenter from 'owa-fluent-icons-svg/lib/icons/TextAlignCenterRegular';
import ClipboardPaste from 'owa-fluent-icons-svg/lib/icons/ClipboardPasteRegular';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import FontColor from 'owa-fluent-icons-svg/lib/icons/TextColorRegular';
import GroupStrings from 'owa-locstrings/lib/strings/ribbon_mail_groups.locstring.json';
import { type MailRibbonGroupId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { MenuDefinition } from '@1js/acui-menu/lib/components/MenuDefinition';
import type { RibbonFlyoutAnchorProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon';
import type { RibbonGroupId } from 'owa-ribbon-ids';
import { owaComputedFn } from 'owa-computed-fn';
import { createMailAppFlyoutAnchor } from 'owa-mail-ribbon-utils/lib/createMailRibbonButton';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import loc from 'owa-localize';

const emptyMenuDefinition: MenuDefinition = {
    sections: [],
};

export const getMLRFormatTabCommonGroupFlyout = owaComputedFn(
    function getMLRFormatTabCommonGroupFlyout(
        group: RibbonGroupId,
        props: ComposeRibbonControlDefProps
    ): RibbonFlyoutAnchorProps | undefined {
        const groupId = getComposeRibbonId(group, props.composeViewState?.editorId).toString();
        switch (group) {
            case 102:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.clipboard_Group),
                    undefined /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    undefined /* keytipProps */,
                    ClipboardPaste
                );

            case 134:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.basicText_Group),
                    undefined /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    undefined /* keytipProps */,
                    FontColor
                );

            case 135:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.paragraph_Group),
                    undefined /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    undefined /* keytipProps */,
                    AlignCenter
                );

            default:
                return undefined;
        }
    }
);
