import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppFlyoutAnchor } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import loc from 'owa-localize';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { fluidText } from '../strings/actionControlStrings.locstring.json';
import {
    loopMenuTitleTooltip,
    loopMenuDescriptionTooltip,
    deviceNotCompliantForLoopsFluidHero,
} from '../strings/hoverTooltipDesc.locstring.json';
import Loop from './LoopIcon';
import getCombinedAccessIssue from 'owa-attachment-policy-access-issue-checker/lib/utils/getCombinedAccessIssue';
import type AccessIssue from 'owa-attachment-policy-access-issue-checker/lib/schema/AccessIssue';
import type { MailboxInfo } from 'owa-client-types';
import { onLoopMenuClicked } from '../actions/deferredMenuDefinitionMutator';
import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import type { RibbonFluidMenuInfo } from 'owa-fluid-integrations';
import type { ResourceId } from 'owa-localize';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';

interface CustomToolTip {
    ToolTipControlName: ResourceId;
    ToolTipDescription: ResourceId | undefined;
}

const getLoopCustomTooptip = (mailboxInfo: MailboxInfo): CustomToolTip => {
    const accessIssue: AccessIssue = getCombinedAccessIssue(mailboxInfo);
    const hasAccessIssue = accessIssue !== 2;
    return {
        ToolTipControlName: hasAccessIssue
            ? deviceNotCompliantForLoopsFluidHero
            : loopMenuTitleTooltip,
        ToolTipDescription: hasAccessIssue ? undefined : loopMenuDescriptionTooltip,
    };
};

export const getFluidHero = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonFluidMenuInfo | null,
        styles: IButtonStyles | undefined,
        isDisabled: boolean,
        keyTipTabId: number | undefined,
        mailboxInfo: MailboxInfo
    ): RibbonControlProps => {
        const loopCustomTooptip: CustomToolTip = getLoopCustomTooptip(mailboxInfo);
        const storeName = 'loopMenuDefinitionStore';
        return createAppFlyoutAnchor(
            getComposeRibbonId(7000, editorId),
            loc(fluidText),
            getControlMenuStore(editorId, storeName),
            keyTipTabId ? constructKeytip([keyTipTabId], 7000) : undefined,
            Loop,
            undefined /*iconColors*/,
            styles,
            {
                customTooltip: getHoverTooltip(
                    loopCustomTooptip.ToolTipControlName,
                    loopCustomTooptip.ToolTipDescription
                ),
                disabled: isDisabled,
                onExecute: () => {
                    onLoopMenuClicked(editorId, composeId, retriever, mailboxInfo);
                },
                onAfterMenuDismiss: () => {
                    resetMenuDefinition(editorId, storeName);
                    callEditorApi(editorId, 'focus');
                },
            }
        );
    }
);
