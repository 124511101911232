import { HoverIcon, PropertyIcon } from './IconBar';
import type { HoverActionIconProps, PropertyIconProps } from 'owa-mail-list-item-shared';
import { pinTitle } from './MailListItemIconBar.locstring.json';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import { lazyGetFlagContextMenu } from '../index';
import { DirectionalHint } from '@fluentui/react/lib/Callout';
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import { getDensityModeString } from 'owa-fabric-theme';
import { default as Archive } from 'owa-fluent-icons-svg/lib/icons/ArchiveRegular';
import { default as Checkmark } from 'owa-fluent-icons-svg/lib/icons/CheckmarkRegular';
import { default as Delete } from 'owa-fluent-icons-svg/lib/icons/DeleteRegular';
import { default as FlagFilled } from 'owa-fluent-icons-svg/lib/icons/FlagFilled';
import { default as FolderArrowRight } from 'owa-fluent-icons-svg/lib/icons/FolderArrowRightRegular';
import { default as MailRead } from 'owa-fluent-icons-svg/lib/icons/MailReadRegular';
import { default as Mail } from 'owa-fluent-icons-svg/lib/icons/MailRegular';
import { default as PinFilled } from 'owa-fluent-icons-svg/lib/icons/PinFilled';
import { default as Pin } from 'owa-fluent-icons-svg/lib/icons/PinRegular';
import loc from 'owa-localize';
import { archive } from 'owa-locstrings/lib/strings/archive.locstring.json';
import { deleteItem } from 'owa-locstrings/lib/strings/deleteitem.locstring.json';
import { markAsRead } from 'owa-locstrings/lib/strings/markasread.locstring.json';
import { markAsUnread } from 'owa-locstrings/lib/strings/markasunread.locstring.json';
import { moveTo } from 'owa-locstrings/lib/strings/moveto.locstring.json';
import { unpin } from 'owa-locstrings/lib/strings/unpin.locstring.json';
import { getIconBarTheme } from 'owa-mail-densities/lib/utils/getIconBarTheme';
import { resetFocus } from 'owa-mail-focus-manager';
import { hideMailItemContextMenu } from 'owa-mail-list-actions/lib/actions/itemContextMenuActions';
import getPropertyIcon from 'owa-mail-list-actions/lib/utils/conversationProperty/getPropertyIcon';
import PropertyIcons from 'owa-mail-list-actions/lib/utils/conversationProperty/PropertyIcons';
import getListViewStore from 'owa-mail-list-store/lib/store/Store';
import { lazyToggleRowReadState } from 'owa-mail-mark-read-actions';
import { getMoveToPropertiesForContextMenu } from 'owa-mail-movetofolder-view';
import { lazyToggleRowPinnedState, lazyToggleRowsFlagState } from 'owa-mail-triage-action';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import type SmimeType from 'owa-smime-adapter/lib/store/schema/SmimeType';
import getIconForSmimeType from 'owa-smime/lib/utils/getIconForSmimeType';
import { getBigHoverAction } from 'owa-surface-actions/lib/utils/getBigHoverAction';
import React from 'react';
import {
    archiveRow,
    deleteRow,
    useFlagCompleteHelper,
    useMailListItemContainerContext,
} from 'owa-mail-list-item-shared';
import type { IContextualMenuProps } from '@fluentui/react/lib/ContextualMenu';
import {
    getMailMenuItemShouldShow,
    doesUserHaveSharedFolderPermissionFor,
} from 'owa-mail-filterable-menu-behavior';
import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';
import type { HoverActionKey } from 'owa-outlook-service-options';
import type ImportanceType from 'owa-service/lib/contract/ImportanceType';
import { startAnimationOrHandleActionAsNeeded } from 'owa-mail-list-item-animation-store';
import {
    getHoverSurfaceAction,
    getStore as getHoverSurfaceActionStore,
} from 'owa-surface-actions-option';
import getMailboxInfo from 'owa-mail-mailboxinfo/lib/getMailboxInfo';

// Remark: We have to share css file with MailListItem since we need to trigger child hover selectors
// off of the MailListItem container css class
import {
    hoverActionIcon,
    icon,
    iconBarContainer,
    iconFull,
    slvIconHide,
    propertyIcon,
    alwaysShowPropertyIcons,
} from 'owa-mail-listitem-styles/lib/scss/IconBar.scss';
import {
    atMentionIconUnreadMailListItem,
    hoverActionIconNext,
    mailListItemIsPinned,
    pinIcon,
    propertyActionIconNext,
    visibilityHidden,
} from './MailListItemIconBar.scss';
import { flagIcon } from 'owa-mail-listitem-styles/lib/scss/FlagIconStyles.scss';

const hoverActionSource = 'Hover';

export interface MailListItemIconBarProps {
    neverShowHoverIcons?: boolean;
    neverShowPropertyIcons?: boolean;
    tableViewId: string;
    lastDeliveryTimestamp: string;
    rowKey: string;
    isRowExpanded: boolean;
    isFlagged: boolean;
    isComplete: boolean;
    isPinned: boolean;
    isInNotesFolder: boolean;
    isInArchiveFolder: boolean;
    isInOutboxFolder: boolean;
    canDelete: boolean;
    unreadCount: number;
    canPin: boolean;
    itemClassIcon: PropertyIcons;
    importance: ImportanceType;
    smimeType: SmimeType;
    hasAttachment: boolean;
    hasSharepointLink: boolean;
    effectiveMentioned: boolean;
    isSnoozed: boolean;
    isTaggedForBigScreen: boolean;
    isSingleLine: boolean;
    supportsFlagging: boolean;
    supportsMove: boolean;
    supportsPinning: boolean;
    containerClassName?: string;
}

/*
Renders the mail list icon bar for both action icons and property icons
*/
export default observer(function MailListItemIconBar(props: MailListItemIconBarProps) {
    const tableView = getListViewStore().tableViews.get(props.tableViewId);
    const mailboxInfo = getMailboxInfo(tableView);
    const isSingleLine = props.isSingleLine;

    const onDismissMoveToMenu = React.useCallback(() => {
        hideMailItemContextMenu();
        resetFocus('MailListMoveMenuDismissed');
    }, []);

    const moveRow = React.useCallback((): IContextualMenuProps | undefined => {
        return getMoveToPropertiesForContextMenu(
            props.tableViewId,
            mailboxInfo,
            onDismissMoveToMenu,
            hoverActionSource,
            getMailMenuItemShouldShow,
            DirectionalHint.bottomLeftEdge,
            [props.rowKey]
        );
    }, [props.tableViewId, onDismissMoveToMenu, props.rowKey]);

    const toggleMarkAsReadRow = React.useCallback(() => {
        lazyToggleRowReadState.importAndExecute(props.rowKey, props.tableViewId, hoverActionSource);
    }, [props.rowKey, props.tableViewId]);

    const togglePinnedStateRow = React.useCallback(() => {
        lazyToggleRowPinnedState.importAndExecute(
            props.rowKey,
            props.tableViewId,
            hoverActionSource
        );
        // Reset focus after the stitch to pin the item is raised
        resetFocus('MailListItemIconBarPinned');
    }, [props.rowKey, props.tableViewId]);

    const flaggedOnContextMenu = React.useCallback(
        (evt: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
            evt.stopPropagation();
            evt.persist();
            lazyGetFlagContextMenu.importAndExecute(
                props.rowKey,
                props.tableViewId,
                '' /* nodeId */,
                hoverActionSource,
                evt
            );
            evt.preventDefault();
        },
        [props.rowKey, props.tableViewId]
    );

    const toggleFlaggedStateRow = React.useCallback(() => {
        lazyToggleRowsFlagState.importAndExecute(
            [props.rowKey],
            props.tableViewId,
            hoverActionSource
        );
        // Reset focus after the stitch to flag the item is raised
        resetFocus('MailListItemIconBarFlagged');
    }, [props.rowKey, props.tableViewId]);

    const shouldHideSlvHoverIcon = (hoverIconKey: string) => {
        switch (hoverIconKey) {
            case 'pinIcon':
                const { isFlagged, isComplete, isPinned } = props;
                return (isFlagged || isComplete) && !isPinned;
            default:
                return false;
        }
    };

    const flagEntry = useFlagCompleteHelper(
        {
            title: null,
            key: null,
            onClickCommand: toggleFlaggedStateRow,
            onContextMenu: flaggedOnContextMenu,
        },
        props.isFlagged,
        props.isComplete
    );

    const getHoverActionIcons = useComputedValue((): HoverActionIconProps[] => {
        const hoverActions: HoverActionIconProps[] = [];
        let hoverActionKeys: HoverActionKey[] = getHoverSurfaceActionStore().hoverSurfaceActions;
        // If in notes folder, only show delete hover icon
        // Else no values exist in the store, read values from owa userConfig.
        if (props.isInNotesFolder || props.isInOutboxFolder) {
            hoverActionKeys = ['Delete'];
        } else if (hoverActionKeys === null) {
            hoverActionKeys = getHoverSurfaceAction();
        }
        const bigHoverAction = getBigHoverAction(hoverActionKeys);
        hoverActionKeys.forEach(action => {
            switch (action) {
                case 'Archive':
                    if (bigHoverAction !== action && !props.isInArchiveFolder) {
                        /*We do not want the icon to be shown if already shown in the big hover action.*/
                        hoverActions[0] = {
                            title: loc(archive),
                            key: 'archiveIcon',
                            iconName: Archive,
                            onClickCommand: () => {
                                const handleArchive = () => {
                                    archiveRow(props.rowKey, props.tableViewId, hoverActionSource);
                                };
                                startAnimationOrHandleActionAsNeeded(
                                    'Archive',
                                    [props.rowKey],
                                    handleArchive
                                );
                            },
                        };
                    }
                    break;
                case 'Move':
                    const shouldShowMove =
                        props.supportsMove && doesUserHaveSharedFolderPermissionFor(29);

                    if (shouldShowMove) {
                        hoverActions[1] = {
                            title: loc(moveTo),
                            key: 'moveIcon',
                            iconName: FolderArrowRight,
                            menuProps: moveRow(),
                        };
                    }
                    break;
                case 'Delete':
                    if (
                        props.canDelete &&
                        bigHoverAction !==
                            action /*We do not want the icon to be shown if already shown in the big hover action.*/
                    ) {
                        hoverActions[2] = {
                            title: loc(deleteItem),
                            key: 'deleteIcon',
                            iconName: Delete,
                            onClickCommand: () =>
                                deleteRow(props.rowKey, props.tableViewId, hoverActionSource),
                        };
                    }
                    break;
                case 'ReadUnread':
                    const hasUnread = props.unreadCount > 0;
                    hoverActions[3] = {
                        title: hasUnread ? loc(markAsRead) : loc(markAsUnread),
                        key: 'markReadIcon',
                        iconName: hasUnread ? MailRead : Mail,
                        onClickCommand: toggleMarkAsReadRow,
                    };
                    break;
                case 'FlagUnflag':
                    if (
                        props.supportsFlagging &&
                        doesUserHaveSharedFolderPermissionFor(
                            18
                        ) /* Shared folder permission check */
                    ) {
                        flagEntry.iconClassName = classnames(flagEntry.iconClassName);
                        hoverActions[4] = flagEntry;
                    }
                    break;
                case 'PinUnpin':
                    const isPinned = props.isPinned;
                    if (
                        props.supportsPinning &&
                        props.canPin &&
                        doesUserHaveSharedFolderPermissionFor(
                            39
                        ) /* Shared folder permission check */
                    ) {
                        const pinClasses = classnames(isPinned && pinIcon);
                        hoverActions[5] = {
                            title: isPinned ? loc(unpin) : loc(pinTitle),
                            key: 'pinIcon',
                            iconClassName: classnames(pinClasses),
                            iconName: isPinned ? PinFilled : Pin,
                            onClickCommand: togglePinnedStateRow,
                        };
                    }
                    break;
                case 'None':
                    break;
            }
        });
        return hoverActions;
    }, [
        props.isInNotesFolder,
        props.isInArchiveFolder,
        props.isInOutboxFolder,
        props.rowKey,
        props.canDelete,
        props.unreadCount,
        props.isPinned,
        props.canPin,
        props.supportsFlagging,
        props.supportsPinning,
        flagEntry,
    ]);
    const getPropertyIconProps = useComputedValue((): PropertyIconProps[] => {
        const propertyIconProps: PropertyIconProps[] = [];
        const supportsFlagging = props.supportsFlagging;
        const supportsPinning = props.supportsPinning;
        // Build the property icon list from left to right
        // Item class icon first (meeting, note, etc.)
        if (props.itemClassIcon && props.itemClassIcon !== PropertyIcons.None) {
            propertyIconProps.push({
                key: props.itemClassIcon.toString(),
                ...getPropertyIcon(props.itemClassIcon),
            });
        }
        // Importance icon
        if (props.importance == 'Low') {
            propertyIconProps.push({
                key: PropertyIcons.ImportanceLow.toString(),
                ...getPropertyIcon(PropertyIcons.ImportanceLow),
            });
        } else if (props.importance == 'High') {
            propertyIconProps.push({
                key: PropertyIcons.ImportanceHigh.toString(),
                ...getPropertyIcon(PropertyIcons.ImportanceHigh),
            });
        }
        // S/MIME icons
        if (props.smimeType !== 0) {
            const smimeIcon: 'LockClosedRegular' | 'RibbonRegular' = getIconForSmimeType(
                props.smimeType
            );
            smimeIcon &&
                propertyIconProps.push({
                    key: smimeIcon,
                    iconName: smimeIcon,
                });
        }
        // Attachment icon
        if (props.hasAttachment) {
            propertyIconProps.push({
                key: PropertyIcons.Attachment.toString(),
                ...getPropertyIcon(PropertyIcons.Attachment),
            });
        } else if (props.hasSharepointLink) {
            propertyIconProps.push({
                key: PropertyIcons.Link.toString(),
                ...getPropertyIcon(PropertyIcons.Link),
            });
        }
        // At mention icon
        if (props.effectiveMentioned) {
            propertyIconProps.push({
                key: PropertyIcons.GlobalMentionedMe.toString(),
                ...getPropertyIcon(PropertyIcons.GlobalMentionedMe),
                iconClasses: classnames(
                    props.supportsPinning && props.isPinned && mailListItemIsPinned,
                    props.unreadCount > 0 && atMentionIconUnreadMailListItem
                ),
            });
        }
        // If the conversation/item has been snoozed or tagged for big screen, then show the clock icon
        if (props.isSnoozed || props.isTaggedForBigScreen) {
            propertyIconProps.push({
                key: PropertyIcons.Snooze.toString(),
                ...getPropertyIcon(PropertyIcons.Snooze),
            });
        }
        // Complete icon
        if (
            supportsFlagging &&
            props.isComplete &&
            doesUserHaveSharedFolderPermissionFor(18) /* Shared folder permission check */
        ) {
            propertyIconProps.push({
                key: 'completeIcon',
                iconName: Checkmark,
            });
        }
        // Flag icon
        if (
            supportsFlagging &&
            props.isFlagged &&
            !props.isComplete &&
            doesUserHaveSharedFolderPermissionFor(18) /* Shared folder permission check */
        ) {
            propertyIconProps.push({
                key: 'flagIcon',
                iconClasses: flagIcon,
                iconName: FlagFilled,
            });
        }
        // Pin icon if pinned
        // Add flag/complete icon as the right most icon if pinning is not supported
        // If pinning is supported we leave a place for pin hover action/property icon
        if (
            supportsPinning &&
            (props.isPinned || props.isFlagged || props.isComplete) &&
            doesUserHaveSharedFolderPermissionFor(39) /* Shared folder permission check */
        ) {
            const pinIconClasses = classnames(
                pinIcon,
                (props.isFlagged || props.isComplete) && !props.isPinned && visibilityHidden
            );

            propertyIconProps.push({
                key: 'pinIcon',
                iconClasses: pinIconClasses,
                iconName: PinFilled,
            });
        }

        return propertyIconProps;
    }, [
        props.itemClassIcon,
        props.importance,
        props.smimeType,
        props.hasAttachment,
        props.hasSharepointLink,
        props.effectiveMentioned,
        props.unreadCount,
        props.isSnoozed,
        props.isTaggedForBigScreen,
        props.rowKey,
        props.isComplete,
        props.isFlagged,
        props.isPinned,
        props.supportsFlagging,
        props.supportsPinning,
    ]);

    const containerClassNames = classnames(iconBarContainer, props.containerClassName);

    const iconKeyIntersectionSet = useComputedValue(() => {
        // Find Icons in both property and hover icons for hovering. if a property icon is only in hover actions, always show the hover action and hide the property icon
        if (!isSingleLine) {
            return null;
        }
        const hoverIconKeySet = new Set();
        const keyIntersectionSet = new Set();
        getHoverActionIcons.forEach(item => {
            hoverIconKeySet.add(item.key);
        });

        getPropertyIconProps.forEach(item => {
            if (hoverIconKeySet.has(item.key)) {
                keyIntersectionSet.add(item.key);
            }
        });
        return keyIntersectionSet;
    }, [getHoverActionIcons, getPropertyIconProps]);

    const isMailListItemHovered = useMailListItemContainerContext().isHovered;

    const shouldRenderHoverActions =
        !props.neverShowHoverIcons && (isMailListItemHovered || iconKeyIntersectionSet?.size);

    const shouldRenderPropertyIcons =
        !shouldRenderHoverActions &&
        !props.neverShowPropertyIcons &&
        getPropertyIconProps.length > 0;

    /**
     * Render the component if the row is hovered (hover icons) or if the row has
     * property icons to show.
     */
    if (shouldRenderHoverActions || shouldRenderPropertyIcons) {
        return (
            <ThemeProvider
                applyTo="none"
                theme={getIconBarTheme(getDensityModeString())}
                className={containerClassNames}
            >
                {shouldRenderHoverActions &&
                    getHoverActionIcons.map(hoverProps => {
                        let shouldBeVisible = false;
                        let shouldTakeSpace = false;

                        if (isMailListItemHovered) {
                            shouldBeVisible = true;
                        } else if (isSingleLine) {
                            if (
                                iconKeyIntersectionSet?.has(hoverProps.key) &&
                                !shouldHideSlvHoverIcon(hoverProps.key)
                            ) {
                                shouldBeVisible = true;
                            } else {
                                shouldTakeSpace = true;
                            }
                        }

                        return (
                            <HoverIcon
                                {...hoverProps}
                                key={hoverProps.key}
                                iconClasses={classnames(
                                    hoverProps.iconClasses,
                                    hoverActionIcon,
                                    shouldHideSlvHoverIcon(hoverProps.key) && slvIconHide,
                                    icon,
                                    getDensityModeString() === 'full' && iconFull,
                                    shouldBeVisible && hoverActionIconNext,
                                    shouldTakeSpace && propertyActionIconNext
                                )}
                            />
                        );
                    })}
                {shouldRenderPropertyIcons &&
                    getPropertyIconProps.map(propertyIconProps => (
                        <PropertyIcon
                            {...propertyIconProps}
                            key={propertyIconProps.key}
                            iconClasses={classnames(
                                propertyIconProps.iconClasses,
                                propertyIcon,
                                isSingleLine &&
                                    !iconKeyIntersectionSet?.has(propertyIconProps.key) &&
                                    alwaysShowPropertyIcons, // keep hover icons with a big hover action
                                iconKeyIntersectionSet?.has(propertyIconProps.key) && slvIconHide
                            )}
                        />
                    ))}
            </ThemeProvider>
        );
    } else {
        return null;
    }
}, 'MailListItemIconBar');
