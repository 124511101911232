enum EditorEntryPoint {
    Settings,
    MailComposeToolbar,
    CalendarComposeToolbar,
    NewslettersComposeToolbar,
    Unknown,
    DexPane,
    EditorMenu,
    ToneRewriteFre,
    ContextualCard,
    LanguageDetectionNotificationBar,
    MultilingualFooterFre,
}

export default EditorEntryPoint;
