import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppSplitButton } from 'owa-command-ribbon';
import type RibbonFormatState from 'owa-editor-ribbonplugin-store/lib/store/schema/RibbonFormatState';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import Undo from 'owa-fluent-icons-svg/lib/icons/ArrowUndoRegular';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { undoText } from './formatControlStrings.locstring.json';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import { getHoverTooltipHotkey } from 'owa-compose-ribbon-utils/lib/utils/hoverTooltipHotkeyMap';
import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import { onUndoRedoMenuClicked } from '../actions/deferredMenuDefinitionMutator';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';

export const getUndoRedoControl = owaComputedFn(
    (
        editorId: string,
        formatViewState: RibbonFormatState,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        const storeName = 'undoRedoMenuDefinitionStore';
        const label = loc(undoText);
        return createAppSplitButton(
            getComposeRibbonId(6013, editorId),
            label,
            getControlMenuStore(editorId, storeName),
            getComputedCallback<any, any>(6014, callEditorApi, editorId, 'undo'),
            tabId ? constructKeytip([tabId], 6013, true /* hasMenu */) : undefined,
            Undo,
            undefined /* iconColor */,
            styles,
            {
                primaryDisabled: isDisabled || !formatViewState.canUndo,
                customTooltip: getHoverTooltip(
                    undoText,
                    undefined /* description */,
                    getHoverTooltipHotkey(6014)
                ),
                disabled: isDisabled,
                onMenuButtonExecute: () => {
                    onUndoRedoMenuClicked(editorId, formatViewState, styles, tabId);
                },
                onAfterMenuDismiss: () => {
                    resetMenuDefinition(editorId, storeName);
                    callEditorApi(editorId, 'focus');
                },
                splitButtonAriaLabel: label,
            }
        );
    }
);
