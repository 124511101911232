import type { ControlInfo, ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { constructKeytip } from 'owa-command-ribbon';
import {
    createMailAppButton,
    createMailAppFlyoutAnchor,
    createMailAppSplitButton,
} from 'owa-mail-ribbon-utils/lib/createMailRibbonButton';
import {
    getDefaultMlrAppButtonStyles,
    getDefaultSlrAppRibbonButtonStyles,
    getDefaultSlrSplitButtonAppRibbonButtonStyles,
} from 'owa-command-ribbon-styles';
import {
    onArchiveClicked,
    onBlockClicked,
    onDeleteClicked,
    onFlagUnflagClicked,
    onForwardClicked,
    onMarkAsReadUnreadClicked,
    onPrintClicked,
    onReplyAllClicked,
    onReplyClicked,
    onReplyWithMeetingClicked,
    onCopilotReplyWithMeetingClicked,
    onReportSplitButtonClicked,
    onShowInImmersiveReaderClicked,
    onMessageRecallClicked,
    onZoomClicked,
    onTrackReadReceiptsClicked,
    onChatAroundEmailButtonsClicked,
    onMessageResendClicked,
    onShareToTeamsButtonClicked,
} from 'owa-mail-ribbon-command-actions/lib/popoutCommandActions';
import { onAssignPolicyMenuClicked } from '../utils/deferredOnAssignPolicyMenuClicked';
import { onBlockMenuClicked } from '../utils/deferredOnBlockMenuClicked';
import { onCategorizeMenuClicked } from '../utils/deferredOnCategorizeMenuClicked';
import { onFlagUnflagMenuClicked } from '../utils/deferredOnFlagUnflagMenuClicked';
import { onForwardMenuClicked } from '../utils/deferredOnForwardMenuClicked';
import { onReportMenuClicked } from '../utils/deferredOnReportMenuClicked';
import { onRespondMenuClicked } from '../utils/deferredOnRespondMenuClicked';
import { onRulesMenuClicked } from '../utils/deferredOnRulesMenuClicked';
import { onCopilotReplyWithMeetingMenuClicked } from '../utils/deferredOnCopilotReplyWithMeetingMenuClicked';
import { onChatAroundEmailMenuClicked } from '../utils/deferredOnChatAroundEmailMenuClicked';
import { resetMenuDefinition } from '../../mutatorActions/menuDefinitionMutatorActions';
import { type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { type MenuItemType } from 'owa-filterable-menu/lib/components/MenuItemType';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { getComposeViewState } from 'owa-mail-reading-pane-store-conversation/lib/utils/getComposeViewState';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { getIsPopoutButtonDisabled } from 'owa-mail-ribbon-utils/lib/getIsPopoutButtonDisabled';
import { getIsReadButtonDisabled } from 'owa-mail-ribbon-utils/lib/getIsReadButtonDisabled';
import { getMailMenuItemShouldShow } from 'owa-mail-filterable-menu-behavior';
import { getMenuDefinition } from '../../store/menuDefinitionStore';
import { getSharedControlInfo } from 'owa-mail-ribbon-utils/lib/getSharedControlInfo';
import { getReadRibbonId } from 'owa-mail-ribbon-utils/lib/getReadRibbonId';
import { getRibbonMailboxInfo } from 'owa-mail-ribbon-utils/lib/getRibbonMailboxInfo';
import isReplyAllTheDefaultResponse from 'owa-mail-reading-pane-store/lib/utils/isReplyAllTheDefaultResponse';
import { resetCategorizeSearch } from 'owa-mail-ribbon-command-actions/lib/onCategorizeCommandActions';
import { retrieveWindowForRibbon } from 'owa-mail-compose-controls/lib/utils/retrieveEditingInfoForRibbon';
import { TeamsRespondType } from 'owa-teams-integration';
import { onCopyToMenuClicked } from '../utils/deferredOnCopyToMenuClicked';
import { shareToTeamsGUIDs } from 'owa-addins-surface-actions/lib/utils/addinGUIDConst';
import { getAddinAsSurfaceItem } from 'owa-addins-surface-actions/lib/utils/getAddinAsSurfaceItem';
import { getAddinIcon } from 'owa-addins-surface-actions/lib/utils/getAddinIcon';
import type { AddinCommandSurfaceItem } from 'owa-addins-types';
import { resetMoveToSearch } from 'owa-mail-ribbon-command-actions/lib/resetMoveToSearch';

/**
 * This file is components that are shared between the read and read projection ribbons.
 *
 * For controls that are only in the main read ribbon, used readSharedControlDefinitions.
 * For controls that show up in compose-only scenarios, please use composeSharedControlDefinitions.ts.
 */

export const getArchiveControl = owaComputedFn(function getArchiveControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo: ControlInfo = getSharedControlInfo(505, props);
    const { projectionTabId, itemId } = props;
    return createMailAppButton(
        getReadRibbonId(505, itemId),
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(
            505,
            onArchiveClicked,
            projectionTabId,
            retrieveWindowForRibbon,
            itemId
        ),
        constructKeytip([keytipTabId], 505),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined,
        itemId
    );
});

export const getAssignPolicyControl = owaComputedFn(function getAssignPolicyControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getSharedControlInfo(616, props);
    const storeName = 'AssignPolicyMenuDefinitionStore';
    return createMailAppFlyoutAnchor(
        getReadRibbonId(616, props.itemId),
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName, props.projectionTabId),
        constructKeytip([keytipTabId], 616, true /* hasMenu */),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined,
        props.itemId,
        {
            onExecute: () => {
                onAssignPolicyMenuClicked(keytipTabId, props);
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinition(storeName, props.projectionTabId);
            },
        }
    );
});

export const getBlockControl = owaComputedFn(function getBlockControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getSharedControlInfo(658, props);
    const { projectionTabId, itemId } = props;
    const storeName = 'BlockMenuDefinitionStore';
    return createMailAppSplitButton(
        getReadRibbonId(658, itemId),
        controlInfo.text,
        controlInfo.ariaText,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName, props.projectionTabId),
        getComputedCallback(658, onBlockClicked, projectionTabId, retrieveWindowForRibbon, itemId),
        constructKeytip([keytipTabId], 658, true /* hasMenu */),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrSplitButtonAppRibbonButtonStyles() : undefined,
        itemId,
        {
            onMenuButtonExecute: () => {
                onBlockMenuClicked(keytipTabId, props);
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinition(storeName, props.projectionTabId);
            },
        }
    );
});

export const getCategorizeControl = owaComputedFn(function getCategorizeControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getSharedControlInfo(509, props);
    const storeName = 'CategorizeMenuDefinitionStore';

    return createMailAppFlyoutAnchor(
        getReadRibbonId(509, props.itemId),
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName, props.projectionTabId),
        constructKeytip([keytipTabId], 509, true /* hasMenu */),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined,
        props.itemId,
        {
            delayUpdateFocusOnHover: true,
            onExecute: () => {
                onCategorizeMenuClicked(keytipTabId, props);
            },
            onAfterMenuDismiss: () => {
                resetCategorizeSearch(undefined /* showAllCategories */);
                resetMenuDefinition(storeName, props.projectionTabId);
            },
            shouldFocusOnMount: false, // To not override searchbox focus
            shouldFocusOnContainer: false, // To not override searchbox focus
            enableDownArrowNavigation: true, // to allow the searchbox lose its focus on the menu and enable the down arrow key navigation
        }
    );
});

export const getDeleteControl = owaComputedFn(function getDeleteControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getSharedControlInfo(519, props);
    const { projectionTabId, itemId } = props;
    return createMailAppButton(
        getReadRibbonId(519, itemId),
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(519, onDeleteClicked, projectionTabId, retrieveWindowForRibbon, itemId),
        constructKeytip([keytipTabId], 519, true /* hasMenu */),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined,
        itemId
    );
});

export const getFlagUnflagControl = owaComputedFn(function getFlagUnflagControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getSharedControlInfo(527, props);
    const storeName = 'FlagUnflagMenuDefinitionStore';
    const overrides = {
        onMenuButtonExecute: () => {
            onFlagUnflagMenuClicked(keytipTabId, props);
        },
        onAfterMenuDismiss: () => {
            resetMenuDefinition(storeName, props.projectionTabId);
        },
    };
    return createMailAppSplitButton(
        getReadRibbonId(527, props.itemId),
        controlInfo.text,
        controlInfo.ariaText,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName, props.projectionTabId),
        getComputedCallback(
            527,
            onFlagUnflagClicked,
            undefined /* flagType */,
            undefined /* override */,
            props.itemId
        ),
        constructKeytip([keytipTabId], 527, true /* hasMenu */),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrSplitButtonAppRibbonButtonStyles() : undefined,
        props.itemId,
        overrides
    );
});

export const getForwardControl = owaComputedFn(function getForwardControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getSharedControlInfo(555, props);
    const storeName = 'ForwardMenuDefinitionStore';
    const { projectionTabId, itemId } = props;
    return createMailAppSplitButton(
        getReadRibbonId(555, itemId),
        controlInfo.text,
        controlInfo.ariaText,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName, props.projectionTabId),
        getComputedCallback(
            555,
            onForwardClicked,
            projectionTabId,
            retrieveWindowForRibbon,
            itemId
        ),
        constructKeytip([keytipTabId], 555),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrSplitButtonAppRibbonButtonStyles() : undefined,
        itemId,
        {
            onMenuButtonExecute: () => {
                onForwardMenuClicked(props);
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinition(storeName, props.projectionTabId);
            },
        }
    );
});

export const getImmersiveReaderControl = owaComputedFn(function getImmersiveReaderControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getSharedControlInfo(532, props);
    const { projectionTabId, itemId } = props;
    return createMailAppButton(
        getReadRibbonId(532, itemId),
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(
            532,
            onShowInImmersiveReaderClicked,
            projectionTabId,
            retrieveWindowForRibbon,
            itemId
        ),
        constructKeytip([keytipTabId], 532),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined,
        itemId
    );
});

export const getPrintControl = owaComputedFn(function getPrintControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const { projectionTabId, itemId, isPopout } = props;
    const viewState = getComposeViewState();
    const mailboxInfo = getRibbonMailboxInfo(props);
    const isDisabled = isPopout
        ? getIsPopoutButtonDisabled(549, itemId)
        : getIsReadButtonDisabled(549, false /*isPopout*/, mailboxInfo, undefined /*itemId*/) ||
          viewState?.isComposeRendered; // disable if the button should be disabled, or if we're composing
    const controlInfo = getSharedControlInfo(549, props);
    return createMailAppButton(
        getReadRibbonId(549, itemId),
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(549, onPrintClicked, projectionTabId, retrieveWindowForRibbon, itemId),
        constructKeytip([keytipTabId], 549),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined,
        undefined /*itemId*/,
        { disabled: isDisabled }
    );
});

export const getReplyControl = owaComputedFn(function getReplyControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getSharedControlInfo(556, props);
    const { projectionTabId, itemId } = props;
    return createMailAppButton(
        getReadRibbonId(556, itemId),
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(556, onReplyClicked, projectionTabId, retrieveWindowForRibbon, itemId),
        constructKeytip([keytipTabId], 556),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined,
        itemId
    );
});

export const getReplyAllControl = owaComputedFn(function getReplyAllControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getSharedControlInfo(557, props);
    const { projectionTabId, itemId } = props;
    return createMailAppButton(
        getReadRibbonId(557, itemId),
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(
            557,
            onReplyAllClicked,
            projectionTabId,
            retrieveWindowForRibbon,
            itemId
        ),
        constructKeytip([keytipTabId], 557),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined,
        itemId
    );
});

export const getReplyWithMeetingControl = owaComputedFn(function getReplyWithMeetingControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getSharedControlInfo(553, props);
    return createMailAppButton(
        getReadRibbonId(553, props.itemId),
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(553, onReplyWithMeetingClicked, props.itemId),
        constructKeytip([keytipTabId], 553),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined,
        props.itemId
    );
});

export const getCopilotReplyWithMeetingFlyoutControl = owaComputedFn(
    function getCopilotReplyWithMeetingFlyoutControl(
        isSLR: boolean,
        keytipTabId: MailRibbonTabId,
        props: ReadOnlyRibbonControlDefProps
    ): RibbonControlProps {
        const controlInfo = getSharedControlInfo(698, props);
        const { itemId } = props;

        const storeName = 'CopilotMeetingMenuDefinitionStore';
        return createMailAppSplitButton(
            getReadRibbonId(698, itemId),
            controlInfo.text,
            controlInfo.ariaText,
            controlInfo.tooltip,
            props,
            getMenuDefinition(storeName, props.projectionTabId),
            getComputedCallback(698, onCopilotReplyWithMeetingClicked, itemId),
            constructKeytip([keytipTabId], 698, true /* hasMenu */),
            controlInfo.iconName,
            undefined /* iconColor */,
            isSLR ? getDefaultSlrSplitButtonAppRibbonButtonStyles() : undefined,
            itemId,
            {
                onMenuButtonExecute: () => {
                    onCopilotReplyWithMeetingMenuClicked(props);
                },
                onAfterMenuDismiss: () => {
                    resetMenuDefinition(storeName, props.projectionTabId);
                },
            }
        );
    }
);

export const getReportControl = owaComputedFn(function getReportControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getSharedControlInfo(657, props);
    const { itemId } = props;

    const storeName = 'ReportMenuDefinitionStore';
    return createMailAppSplitButton(
        getReadRibbonId(657, itemId),
        controlInfo.text,
        controlInfo.ariaText,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName, props.projectionTabId),
        getComputedCallback(657, onReportSplitButtonClicked, props, retrieveWindowForRibbon),
        constructKeytip([keytipTabId], 657, true /* hasMenu */),
        controlInfo.iconName,
        undefined /* iconColor */,
        isSLR ? getDefaultSlrSplitButtonAppRibbonButtonStyles() : undefined,
        itemId,
        {
            onMenuButtonExecute: () => {
                onReportMenuClicked(keytipTabId, props);
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinition(storeName, props.projectionTabId);
            },
        }
    );
});

export const getRespondFlyoutControl = owaComputedFn(function getRespondFlyoutControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const isDefaultReplyAll = isReplyAllTheDefaultResponse();
    const controlInfo = getSharedControlInfo(678, props);
    const { itemId, projectionTabId, isPopout } = props;
    const popoutControlId = isDefaultReplyAll ? 681 : 679;
    const menuItem = isDefaultReplyAll ? 61 : 60;
    const mailboxInfo = getRibbonMailboxInfo(props);
    const storeName = 'RespondMenuDefinitionStore';

    return createMailAppSplitButton(
        getReadRibbonId(678, itemId),
        controlInfo.text,
        controlInfo.ariaText,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName, props.projectionTabId),
        getComputedCallback(
            678,
            isDefaultReplyAll ? onReplyAllClicked : onReplyClicked,
            projectionTabId,
            retrieveWindowForRibbon,
            itemId
        ),
        constructKeytip([keytipTabId], 678),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrSplitButtonAppRibbonButtonStyles() : undefined,
        undefined /*itemId*/,
        {
            primaryDisabled: isPopout
                ? getIsPopoutButtonDisabled(popoutControlId, itemId)
                : !getMailMenuItemShouldShow(menuItem, mailboxInfo),
            onMenuButtonExecute: () => {
                onRespondMenuClicked(props);
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinition(storeName, props.projectionTabId);
            },
        }
    );
});

export const getRulesControl = owaComputedFn(function getRulesControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getSharedControlInfo(559, props);
    const storeName = 'RulesMenuDefinitionStore';
    return createMailAppFlyoutAnchor(
        getReadRibbonId(559, props.itemId),
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName, props.projectionTabId),
        constructKeytip([keytipTabId], 559, true /* hasMenu */),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined,
        props.itemId,
        {
            onExecute: () => {
                onRulesMenuClicked(props);
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinition(storeName, props.projectionTabId);
            },
        }
    );
});

export const getUnreadReadControl = owaComputedFn(function getUnreadReadControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getSharedControlInfo(552, props);
    return createMailAppButton(
        getReadRibbonId(552, props.itemId),
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(552, onMarkAsReadUnreadClicked, props.itemId),
        constructKeytip([keytipTabId], 552),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined,
        props.itemId
    );
});

export const getMessageRecallControl = owaComputedFn(function getMessageRecallControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const { projectionTabId, itemId } = props;
    const controlInfo = getSharedControlInfo(686, props);
    return createMailAppButton(
        getReadRibbonId(686, props.itemId),
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(
            686,
            onMessageRecallClicked,
            itemId,
            props.mailboxInfo,
            projectionTabId,
            retrieveWindowForRibbon
        ),
        constructKeytip([keytipTabId], 686),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined,
        props.itemId
    );
});

export const getZoomControl = owaComputedFn(function getZoomControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const { projectionTabId } = props;
    const controlInfo = getSharedControlInfo(688, props);
    return createMailAppButton(
        688,
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(keytipTabId, onZoomClicked, projectionTabId, retrieveWindowForRibbon),
        constructKeytip([keytipTabId], 688),
        controlInfo.iconName,
        undefined,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined
    );
});

export const getTrackReadReceiptsControl = owaComputedFn(function getTrackReadReceiptsControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const { projectionTabId, itemId } = props;
    const controlInfo = getSharedControlInfo(697, props);
    return createMailAppButton(
        getReadRibbonId(697, itemId),
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(
            697,
            onTrackReadReceiptsClicked,
            itemId,
            projectionTabId,
            retrieveWindowForRibbon
        ),
        constructKeytip([keytipTabId], 697),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined,
        itemId
    );
});

export const getChatAroundEmailControl = owaComputedFn(function getChatAroundEmailControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo = getSharedControlInfo(702, props);
    const storeName = 'TeamsChatMenuDefinitionStore';

    return createMailAppSplitButton(
        getReadRibbonId(702, props.itemId),
        controlInfo.text,
        controlInfo.ariaText,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName, props.projectionTabId),
        getComputedCallback(
            702,
            onChatAroundEmailButtonsClicked,
            isSLR,
            props.itemId,
            getRibbonMailboxInfo(props),
            TeamsRespondType.Reply
        ),
        constructKeytip([keytipTabId], 702, true /* hasMenu */),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrSplitButtonAppRibbonButtonStyles() : undefined,
        props.itemId,
        {
            onMenuButtonExecute: () => {
                onChatAroundEmailMenuClicked(isSLR, keytipTabId, props);
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinition(storeName, props.projectionTabId);
            },
        }
    );
});

export const getShareToTeamsChatControl = owaComputedFn(function getShareToTeamsChatControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps | undefined {
    const mailboxInfo = getRibbonMailboxInfo(props);
    const controlInfo = getSharedControlInfo(709, props);
    const addinSurfaceItem: AddinCommandSurfaceItem | undefined = getAddinAsSurfaceItem(
        undefined /* itemId */,
        false /*isSharedItem*/,
        mailboxInfo,
        shareToTeamsGUIDs,
        retrieveWindowForRibbon,
        props.projectionTabId ?? ''
    );

    if (addinSurfaceItem) {
        return createMailAppButton(
            709,
            addinSurfaceItem.title ?? addinSurfaceItem.name ?? controlInfo.text /* label */,
            addinSurfaceItem.description ?? controlInfo.tooltip /* tooltip */,
            props,
            getComputedCallback(
                709,
                onShareToTeamsButtonClicked,
                addinSurfaceItem,
                isSLR,
                props.itemId,
                props.projectionTabId
            ),
            constructKeytip([keytipTabId], 709),
            getAddinIcon(addinSurfaceItem),
            undefined /* iconColor */,
            isSLR ? getDefaultSlrAppRibbonButtonStyles() : getDefaultMlrAppButtonStyles()
        );
    }

    return undefined;
});

export const getCopyToControl = owaComputedFn(function getCopyToControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const controlInfo: ControlInfo = getSharedControlInfo(705, props);
    const storeName = 'CopyToMenuDefinitionStore';

    return createMailAppFlyoutAnchor(
        getReadRibbonId(705, props.itemId),
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getMenuDefinition(storeName, props.projectionTabId),
        constructKeytip([keytipTabId], 705, true /* hasMenu */),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined,
        props.itemId,
        {
            delayUpdateFocusOnHover: true,
            onExecute: () => {
                resetMoveToSearch(true /* onEnter */);
                onCopyToMenuClicked(keytipTabId, props);
            },
            onAfterMenuDismiss: () => {
                resetMoveToSearch(false /* onEnter */);
                resetMenuDefinition(storeName, props.projectionTabId);
            },
            shouldFocusOnMount: false, // To not override searchbox focus
            shouldFocusOnContainer: false, // To not override searchbox focus
            enableDownArrowNavigation: true, // to allow the searchbox lose its focus on the menu and enable the down arrow key navigation
        }
    );
});

export const getMessageResendControl = owaComputedFn(function getMessageResendControl(
    isSLR: boolean,
    keytipTabId: MailRibbonTabId,
    props: ReadOnlyRibbonControlDefProps
): RibbonControlProps {
    const { projectionTabId, itemId } = props;
    const controlInfo = getSharedControlInfo(712, props);
    return createMailAppButton(
        getReadRibbonId(712, props.itemId),
        controlInfo.text,
        controlInfo.tooltip,
        props,
        getComputedCallback(
            712,
            onMessageResendClicked,
            itemId,
            projectionTabId,
            retrieveWindowForRibbon
        ),
        constructKeytip([keytipTabId], 712),
        controlInfo.iconName,
        undefined /*iconColor*/,
        isSLR ? getDefaultSlrAppRibbonButtonStyles() : undefined,
        props.itemId
    );
});
