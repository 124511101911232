import type { MailListRowDragData } from 'owa-mail-types/lib/types/MailListRowDragData';
import { DraggableItemTypes } from 'owa-dnd/lib/utils/DraggableItemTypes';
import { lazyCopyMailListRows } from 'owa-mail-triage-action';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import { isPublicFolderSelected } from 'owa-publicfolder-utils';
import { getGroupFolderAndRulePermission } from 'owa-groups-shared-store/lib/selectors/groupFolderRulePermissionStoreSelectors';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { lazyDropMailListRowsOnGroup } from 'owa-group-mail-left-nav-actions';
import {
    getStore as getListViewStore,
    type TableQueryType,
    getSelectedTableView,
    isConversationView,
} from 'owa-mail-list-store';
import { isSearchFolderForFolderId } from 'owa-favorites';
import { logUsage } from 'owa-analytics';
import ClipboardErrorRegular from 'owa-fluent-icons-svg/lib/icons/ClipboardErrorRegular';
import { getListViewNotificationDimensions } from 'owa-mail-layout';
import { lazyShowNotification } from 'owa-notification-bar';
import loc from 'owa-localize';
import { onPasteFailedNotificationText } from './onPaste.locstring.json';

const owaItemClipboardMime = 'web application/owa-item-drag-data';

export default async function onPaste(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === 'v') {
        event.preventDefault();
        const clipboardItems = await window.navigator.clipboard.read();
        for (const clipboardItem of clipboardItems) {
            for (const type of clipboardItem.types) {
                if (!(type === owaItemClipboardMime)) {
                    return;
                }
                const blob = await clipboardItem.getType(type);
                const dragInfo: MailListRowDragData = JSON.parse(await blob.text());
                const dragItemType = dragInfo.itemType;

                // Copy item part is not supported
                if (
                    dragItemType !== DraggableItemTypes.MailListRow &&
                    dragItemType !== DraggableItemTypes.MultiMailListConversationRows &&
                    dragItemType !== DraggableItemTypes.MultiMailListMessageRows
                ) {
                    return;
                }

                const originalTableView = getListViewStore().tableViews.get(dragInfo.tableViewId);
                const destinationTableView = getSelectedTableView();

                if (!originalTableView || !destinationTableView) {
                    logUsage('TnS_Copy_OnPasteFailed', {
                        tableViewId: dragInfo.tableViewId,
                        isOriginalTableViewDefined: !!originalTableView,
                        isDestinationTableViewDefined: !!destinationTableView,
                    });

                    const notificationDimensions = getListViewNotificationDimensions();

                    lazyShowNotification.importAndExecute(
                        'pasteErrorNotification',
                        'MailModuleNotificationBarHost',
                        window,
                        loc(onPasteFailedNotificationText),
                        {
                            icon: ClipboardErrorRegular,
                            minWidth: notificationDimensions.minWidth,
                            maxWidth: notificationDimensions.maxWidth,
                        }
                    );
                    return;
                }

                const sourceMailboxInfo = getMailboxInfo(originalTableView);
                const destinationMailboxInfo = getMailboxInfo(destinationTableView);

                // Paste in Folder
                if (destinationTableView.tableQuery.type === 0) {
                    const destinationFolderId = destinationTableView.tableQuery.folderId;

                    // Cross mailbox copy is not supported
                    // Conversations can't be copied to Public Folders
                    // Copy not allowed in Search Folders
                    if (
                        sourceMailboxInfo.userIdentity !== destinationMailboxInfo.userIdentity ||
                        (isConversationView(originalTableView) && isPublicFolderSelected()) ||
                        isSearchFolderForFolderId(destinationFolderId)
                    ) {
                        return;
                    }

                    lazyCopyMailListRows.importAndExecute(
                        dragInfo.rowKeys,
                        originalTableView,
                        destinationFolderId,
                        'Keyboard'
                    );

                    // Paste in Group
                } else if (
                    (destinationTableView.tableQuery.type === 2 ||
                        destinationTableView.tableQuery.type === 3) &&
                    (dragItemType === DraggableItemTypes.MultiMailListMessageRows ||
                        dragItemType === DraggableItemTypes.MailListRow)
                ) {
                    const folderRulePermission = getGroupFolderAndRulePermission(
                        destinationMailboxInfo,
                        destinationTableView.tableQuery.folderId // folderId == groupId
                    );

                    // Copy not allowed if user doesn't have copy permission
                    // Copy not allowed across Group mailboxes
                    // Copy not allowed across accounts
                    if (
                        !folderRulePermission?.canCopyMessage ||
                        (sourceMailboxInfo.type === 'GroupMailbox' &&
                            sourceMailboxInfo.mailboxSmtpAddress !==
                                destinationTableView.tableQuery.folderId) ||
                        getIndexerValueForMailboxInfo(destinationMailboxInfo) !==
                            getIndexerValueForMailboxInfo(sourceMailboxInfo)
                    ) {
                        return;
                    }

                    lazyDropMailListRowsOnGroup.importAndExecute(
                        dragInfo,
                        destinationTableView.tableQuery.folderId /* groupId */,
                        true /* copy */,
                        'Keyboard'
                    );
                }
            }
        }
    }
}
