import type { IEditor } from 'roosterjs-editor-types';
import { operateHTMLContentInternal } from 'owa-editor/lib/utils/ContentOperator';
import isContentModelEditor from 'owa-editor-content-model/lib/utils/isContentModelEditor';
import { logUsage } from 'owa-analytics';
import type { FormattingSource } from '../schema/FormattingSource';

const LOG_ENTRY = 'EditorRibbonUsage';

export default function internalRunWithHtmlEditor(
    editorOrId: IEditor | string,
    callback: (editor: IEditor) => void,
    loggingInfo?: {
        apiName: string;
        actionSource?: FormattingSource;
        data: any[];
    }
) {
    const callbackAndLog = (editor: IEditor) => {
        callback(editor);

        // No need to log when editor is in shadow edit since it will bring more noise.
        if (loggingInfo && !editor.isInShadowEdit()) {
            /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
             * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
             *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
            logUsage(LOG_ENTRY, [
                loggingInfo.apiName,
                isContentModelEditor(editor),
                editor.isDarkMode(),
                loggingInfo.actionSource,
                ...loggingInfo.data.map(data => JSON.stringify(data)),
            ]);
        }
    };

    if (typeof editorOrId == 'string') {
        operateHTMLContentInternal(editorOrId, callbackAndLog);
    } else {
        callbackAndLog(editorOrId);
    }
}
