import ConversationReadingPane from 'owa-mail-reading-pane-view-conversation/lib/components/ConversationReadingPane';
import EmptyStateReadingPane from 'owa-mail-reading-pane-view/lib/components/EmptyStateReadingPane';
import ItemReadingPane from 'owa-mail-reading-pane-view-item/lib/components/ItemReadingPane';
import NullReadingPane from './NullReadingPane';
import { PrintItemReadingPane, PrintPanel } from 'owa-mail-reading-pane-view-print';
import { observer } from 'owa-mobx-react';
import { SelectionType } from 'owa-addins-core';
import type { ClientItemId } from 'owa-client-ids';
import { lazyInitializeGetItemManagerForRP } from 'owa-get-item-manager-initialization';
import { updateAddinOnItemNavigation } from 'owa-mail-addins';
import findInlineComposeViewState from 'owa-mail-compose-actions/lib/utils/findInlineComposeViewState';
import { isReadingPanePositionOff } from 'owa-mail-layout/lib/selectors/readingPanePosition';
import getInstrumentationContextsFromTableView from 'owa-mail-list-store/lib/utils/getInstrumentationContextsFromTableView';
import { getMailListLoadState } from 'owa-mail-list-store/lib/utils/getMailListLoadState';
import setShouldShowMobileUpsellEmptyState from 'owa-mail-reading-pane-store/lib/actions/setShouldShowMobileUpsellEmptyState';
import readingPaneStore from 'owa-mail-reading-pane-store/lib/store/Store';
import getConversationReadingPaneViewState from 'owa-mail-reading-pane-store-conversation/lib/utils/getConversationReadingPaneViewState';
import getItemReadingPaneViewState from 'owa-mail-reading-pane-store/lib/utils/getItemReadingPaneViewState';
import { type MailListViewState } from 'owa-mail-store/lib/store/schema/MailListViewState';
import shouldShowUnstackedReadingPane from 'owa-mail-store/lib/utils/shouldShowUnstackedReadingPane';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import { getActiveContentTab, TabType } from 'owa-tab-store';
import type { InstrumentationContext } from 'owa-search-types/lib/types/InstrumentationContext';
import { errorThatWillCauseAlert } from 'owa-trace';
import { logUsage } from 'owa-analytics';
import React from 'react';
import {
    isMultipleSelectedItemParts,
    getSelectedTableView,
    MailRowDataPropertyGetter,
    type TableQueryType,
    getStore as getMailListStore,
} from 'owa-mail-list-store';
import { EmptyStateMobilePromoComponent, OutlookMobileContainer } from 'owa-guided-setup-cards';
import { shouldShowEmptyStateMobilePromo } from 'owa-emptystate-mobilepromo/lib/utils/shouldShowEmptyStateMobilePromo';
import { setPhoneAppCardDismissState } from 'owa-emptystate-mobilepromo';
import { isPopout } from 'owa-popout-v2';
import ProjectionContext from 'owa-popout-v2/lib/context/ProjectionContext';
import isAnySxSVisibleInMainWindow from 'owa-sxs-store/lib/utils/isAnySxSVisibleInMainWindow';
import { absolutePosition } from './ReadingPane.scss';
import { isComposeMailboxStateValid } from 'owa-mail-compose-actions/lib/utils/isComposeMailboxStateValid';
import type { ComposeViewState } from 'owa-mail-compose-store';
import { getTabIdFromTargetWindow } from 'owa-mail-reading-pane-store/lib/utils/getTabIdFromTargetWindow';
import { isFeatureEnabled } from 'owa-feature-flags';
import { getIsCurrentThemeModernImage } from 'owa-theme';
import { NotificationBarHost } from 'owa-notification-bar';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { createDropViewState, type DragData, Droppable } from 'owa-dnd';
import type DropEffect from 'owa-dnd/lib/store/schema/DropEffect';
import { lazyOnDropFile } from 'owa-mail-file-support';
import initializeRingNovaLogger from 'owa-persona-ring/lib/telemetry';

export interface ReadingPaneProps {
    isDeeplink?: boolean;
    isDumpsterOrDumpsterSearchTable?: boolean;
    itemId?: ClientItemId;
    isFromMainWindow?: boolean;
    isItemAttachment?: boolean;
    maximizeScrollRegion?: boolean;
}

const canDropFile = (_dragInfo: DragData): DropEffect => {
    return 'copy';
};

const droppableStyle = {
    height: '100%',
};

function useReadingPaneInitAndTeardown(props: ReadingPaneProps) {
    React.useEffect(() => {
        shouldShowEmptyStateMobilePromo().then((showMobilePromo: boolean) => {
            setShouldShowMobileUpsellEmptyState(showMobilePromo);
        });

        // Intialize the get item manager for reading pane.
        lazyInitializeGetItemManagerForRP.import().then(initializeGetItemManagerForRP => {
            initializeGetItemManagerForRP();
        });

        // Initialize nova logger for Reading Pane Persona Ring.
        initializeRingNovaLogger();

        return () => {
            if (isReadingPanePositionOff()) {
                updateAddinOnItemNavigation(
                    SelectionType.NotSupported,
                    props.itemId?.mailboxInfo ?? getModuleContextMailboxInfo()
                );
            }
        };
    }, []);
}

function ReadingPane(props: ReadingPaneProps) {
    useReadingPaneInitAndTeardown(props);

    const tableView = getSelectedTableView();
    const mailListViewState = getMailListLoadState();
    const selectedRowKeys = tableView && [...tableView.selectedRowKeys?.keys()];
    const isSearchTable = tableView?.tableQuery?.type === 1;
    if (isSearchTable) {
        setShouldShowMobileUpsellEmptyState(false);
    }

    const {
        itemId,
        isDumpsterOrDumpsterSearchTable,
        isFromMainWindow,
        isItemAttachment,
        maximizeScrollRegion,
    } = props;
    const { itemPrintPaneViewStates, deeplinkId, primaryReadingPaneTabId } = readingPaneStore;
    const conversationReadingPaneViewState = getConversationReadingPaneViewState();
    const activeTab = getActiveContentTab();
    const targetWindow = React.useContext(ProjectionContext);
    const tabId = getTabIdFromTargetWindow(targetWindow);
    const itemPrintPaneViewState = itemPrintPaneViewStates.get(tabId);
    let readingPaneContent: JSX.Element = <NullReadingPane />;
    const composeViewState: ComposeViewState | undefined =
        conversationReadingPaneViewState?.conversationId &&
        findInlineComposeViewState(conversationReadingPaneViewState.conversationId.Id);
    const isInlineCompose = !!composeViewState?.isInlineCompose;
    if (isInlineCompose && !isComposeMailboxStateValid(composeViewState)) {
        return readingPaneContent;
    } else if (!isFromMainWindow || !isAnySxSVisibleInMainWindow()) {
        // we render reading pane only if it is from main window with no SxS open, or, it is from a projection window
        if (deeplinkId) {
            // Deeplink UIs.
            readingPaneContent =
                deeplinkId.isPrint && itemPrintPaneViewState ? (
                    <PrintItemReadingPane
                        itemId={deeplinkId.id}
                        viewState={itemPrintPaneViewState}
                    />
                ) : (
                    renderItemReadingPane(
                        {
                            mailboxInfo: deeplinkId.mailboxInfo ?? getModuleContextMailboxInfo(),
                            Id: deeplinkId.id,
                        },
                        itemId?.Id,
                        isItemAttachment,
                        maximizeScrollRegion,
                        true /*isPopout*/
                    )
                );
        } else if (itemId) {
            readingPaneContent = renderItemReadingPane(
                itemId,
                itemId?.Id,
                isItemAttachment,
                maximizeScrollRegion,
                isPopout(targetWindow)
            );
        } else if (activeTab && activeTab.type == TabType.SecondaryReadingPane) {
            const secondaryReadingPaneTabData = activeTab.data;
            switch (secondaryReadingPaneTabData.listViewType) {
                case 0:
                    if (shouldShowUnstackedReadingPane()) {
                        readingPaneContent = renderItemReadingPane(
                            secondaryReadingPaneTabData.id,
                            secondaryReadingPaneTabData.id?.Id,
                            isItemAttachment,
                            maximizeScrollRegion,
                            isPopout(targetWindow),
                            secondaryReadingPaneTabData.subject
                        );
                    } else {
                        if (secondaryReadingPaneTabData.id) {
                            readingPaneContent = renderConversationReadingPane(
                                secondaryReadingPaneTabData.id,
                                secondaryReadingPaneTabData.subject,
                                secondaryReadingPaneTabData.categories,
                                maximizeScrollRegion
                            );
                        } else {
                            errorThatWillCauseAlert(
                                'ReadingPane.render() secondaryReadingPaneTabData.id should not be null.'
                            );
                        }
                    }
                    break;
                case 1:
                    readingPaneContent = renderItemReadingPane(
                        secondaryReadingPaneTabData.id,
                        undefined /* idToUseForViewState */,
                        isItemAttachment,
                        maximizeScrollRegion,
                        isPopout(targetWindow),
                        secondaryReadingPaneTabData.subject
                    );
                    break;
            }
        } else if (composeViewState) {
            // If RP has inline compose open, render conversation in RP store.
            readingPaneContent = renderConversationReadingPane(
                conversationReadingPaneViewState.conversationId,
                conversationReadingPaneViewState.conversationSubject,
                conversationReadingPaneViewState.conversationCategories,
                maximizeScrollRegion
            );
        } else if (
            selectedRowKeys?.length == 1 &&
            !isMultipleSelectedItemParts() &&
            !tableView?.isInVirtualSelectAllMode &&
            !isDumpsterOrDumpsterSearchTable &&
            getMailListStore().shouldShowSelectedRowInRp
        ) {
            // 1 row selected: display reading pane only if select all is not checked and not in dumpster or dumpster search
            const rowId = MailRowDataPropertyGetter.getRowIdToShowInReadingPane(
                selectedRowKeys[0],
                tableView
            );
            const rowSubject = MailRowDataPropertyGetter.getSubject(selectedRowKeys[0], tableView);
            const rowCategories = MailRowDataPropertyGetter.getCategories(
                selectedRowKeys[0],
                tableView
            );
            switch (tableView?.tableQuery.listViewType) {
                case 0:
                    if (shouldShowUnstackedReadingPane() && !!primaryReadingPaneTabId) {
                        readingPaneContent = renderItemReadingPane(
                            primaryReadingPaneTabId,
                            undefined /* idToUseForViewState */,
                            isItemAttachment,
                            maximizeScrollRegion,
                            isPopout(targetWindow),
                            rowSubject
                        );
                    } else {
                        if (rowId) {
                            const instrumentationContext = getInstrumentationContextsFromTableView(
                                [selectedRowKeys[0]],
                                tableView
                            )[0];

                            readingPaneContent = renderConversationReadingPane(
                                rowId,
                                rowSubject,
                                rowCategories,
                                maximizeScrollRegion,
                                instrumentationContext
                            );
                        } else {
                            logUsage('RPConversationRowIdNullError');
                        }
                    }
                    break;
                case 1:
                    readingPaneContent = renderItemReadingPane(
                        rowId,
                        rowId?.Id,
                        isItemAttachment,
                        maximizeScrollRegion,
                        isPopout(targetWindow),
                        rowSubject
                    );
                    break;
            }
        } else if (
            mailListViewState === 'Loaded' ||
            mailListViewState === 'Empty' ||
            readingPaneStore.shouldShowEmptyReadingPane
        ) {
            // Empty state.
            const themeHasBackgroundImage = getIsCurrentThemeModernImage();
            const shouldRenderMobileUpsell =
                readingPaneStore.shouldShowMobileUpsellEmptyState && !themeHasBackgroundImage;

            readingPaneContent = shouldRenderMobileUpsell ? (
                <EmptyStateMobilePromoComponent
                    containerName={OutlookMobileContainer.ReadingPane}
                    onComponentUnmount={onOutlookMobileUnmount}
                />
            ) : (
                <EmptyStateReadingPane
                    isDumpsterOrDumpsterSearchTable={isDumpsterOrDumpsterSearchTable}
                    mailListViewState={mailListViewState}
                />
            );
        }
    }

    function onOutlookMobileUnmount() {
        setPhoneAppCardDismissState(true);
        setShouldShowMobileUpsellEmptyState(false);
    }

    const paneInner = (
        <>
            {readingPaneContent}
            {itemPrintPaneViewState?.itemId && (
                <PrintPanel
                    itemId={itemPrintPaneViewState.itemId}
                    viewState={itemPrintPaneViewState}
                    targetWindow={targetWindow}
                />
            )}
            {isFeatureEnabled(
                'doc-mon-openClassicInNative',
                props.itemId?.mailboxInfo,
                true /* dontThrowErrorIfNotInitialized */
            ) && (
                <NotificationBarHost
                    hostId={'ItemNotificationBarHost'}
                    targetWindow={targetWindow}
                />
            )}
        </>
    );

    return (
        <div key="readingPaneDiv" className={absolutePosition}>
            {isFeatureEnabled('mon-file-eml-msg') && !isInlineCompose ? (
                <Droppable
                    dropViewState={createDropViewState()}
                    onDrop={lazyOnDropFile.importAndExecute}
                    canDrop={canDropFile}
                    shouldIgnoreTransientOnDragLeave={true}
                    style={droppableStyle}
                >
                    {paneInner}
                </Droppable>
            ) : (
                paneInner
            )}
        </div>
    );
}

function renderItemReadingPane(
    itemId: ClientItemId,
    idUsedForViewState: string | undefined,
    isItemAttachment?: boolean,
    maximizeScrollRegion?: boolean,
    openInPopout?: boolean,
    subject?: string
): JSX.Element {
    const itemReadingPaneViewState = getItemReadingPaneViewState(idUsedForViewState);
    return (
        <ItemReadingPane
            itemId={itemId}
            itemSubject={subject}
            itemReadingPaneViewState={itemReadingPaneViewState}
            isPopout={openInPopout}
            isItemAttachment={isItemAttachment}
            maximizeScrollRegion={maximizeScrollRegion}
        />
    );
}

function renderConversationReadingPane(
    conversationId: ClientItemId,
    subject: string,
    categories: string[],
    maximizeScrollRegion?: boolean,
    instrumentationContext?: InstrumentationContext
): JSX.Element {
    return (
        <ConversationReadingPane
            conversationId={conversationId}
            conversationSubject={subject}
            conversationCategories={categories}
            instrumentationContext={instrumentationContext}
            maximizeScrollRegion={maximizeScrollRegion}
        />
    );
}

export default observer(ReadingPane, 'ReadingPane');
