import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppToggleButton } from 'owa-command-ribbon';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import Important from 'owa-fluent-icons-svg/lib/icons/ImportantRegular';
import ArrowDownFilled from 'owa-fluent-icons-svg/lib/icons/ArrowDownFilled';
import loc from 'owa-localize';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import type ImportanceType from 'owa-service/lib/contract/ImportanceType';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import { highImportance, lowImportance } from '../strings/actionControlStrings.locstring.json';
import { lowImportanceDesc, highImportanceDesc } from '../strings/hoverTooltipDesc.locstring.json';
import setImportance from 'owa-mail-compose-actions/lib/actions/setImportance';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';

const onSetImportance = (
    composeId: string,
    retriever: (composeId: string) => RibbonMailComposeInfo | null,
    selectedImportanceType: ImportanceType,
    currentImportanceType: ImportanceType
) => {
    const editingInfo = retriever(composeId);

    if (editingInfo) {
        //set to Normal when reselect
        if (selectedImportanceType === currentImportanceType) {
            setImportance(editingInfo.composeViewState, 'Normal');
        } else {
            setImportance(editingInfo.composeViewState, selectedImportanceType);
        }
    }
};

export const getLowImportance = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonMailComposeInfo | null,
        currentImportanceType: ImportanceType,
        styles: IButtonStyles | undefined,
        keyTipTabId: number | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        return createAppToggleButton(
            getComposeRibbonId(7006, editorId),
            loc(lowImportance),
            getComputedCallback(
                7006,
                onSetImportance,
                composeId,
                retriever,
                'Low',
                currentImportanceType
            ),
            currentImportanceType === 'Low',
            keyTipTabId ? constructKeytip([keyTipTabId], 7006) : undefined,
            ArrowDownFilled,
            undefined /* iconColor */,
            styles,
            {
                disabled: isDisabled,
                customTooltip: getHoverTooltip(lowImportance, lowImportanceDesc),
            }
        );
    }
);

export const getHighImportance = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonMailComposeInfo | null,
        currentImportanceType: ImportanceType,
        styles: IButtonStyles | undefined,
        keyTipTabId: number | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        return createAppToggleButton(
            getComposeRibbonId(7005, editorId),
            loc(highImportance),
            getComputedCallback(
                7005,
                onSetImportance,
                composeId,
                retriever,
                'High',
                currentImportanceType
            ),
            currentImportanceType === 'High',
            keyTipTabId ? constructKeytip([keyTipTabId], 7005) : undefined,
            Important,
            undefined /* iconColor */,
            styles,
            {
                disabled: isDisabled,
                customTooltip: getHoverTooltip(highImportance, highImportanceDesc),
            }
        );
    }
);
