import type { TabViewState } from 'owa-tab-store';
import { findTabByData } from 'owa-tab-store';
import getProjection from 'owa-popout-v2/lib/utils/getProjection';

export default function isComposePopout(composeId: string): boolean {
    const tab: TabViewState | null = findTabByData(composeId);
    if (!!tab) {
        const projection = getProjection(tab.id);
        return !!projection;
    }
    return false;
}
