import { observer } from 'owa-mobx-react';
import { Icon } from '@fluentui/react/lib/Icon';
import { Category } from 'owa-categories';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - Migrating deprecated icons: https://outlookweb.visualstudio.com/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9297/Migrating-deprecated-icons-in-client-web
 *	> 'ControlIcons' import from owa-control-icons is restricted. Use JSX icons from @fluentui/react-icons, otherwise from owa-fluent-icons-svg if they can only be reference by string. */
import { type ControlIcons } from 'owa-control-icons';
import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import type ItemReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemReadingPaneViewState';
import { lazyStartSearchWithCategory } from 'owa-mail-search';
import type { ClientItem } from 'owa-mail-store';
import mailStore from 'owa-mail-store/lib/store/Store';
import type CLPLabel from 'owa-mail-protection-types/lib/schema/CLPLabel';
import { lazyLogReadCLPLabel, CLPSubjectHeaderLabel } from 'owa-mail-protection';
import { getLabelFromStore } from 'owa-mail-protection/lib/utils/clp/getExistingCLPInfo';
import React from 'react';
import { isImmersiveReadingPaneShown } from 'owa-mail-layout/lib/selectors/isImmersiveReadingPaneShown';
import { isFeatureEnabled } from 'owa-feature-flags';
import { ImmersiveExitButton } from 'owa-immersive-exit-button';
import { lazyRemoveCategoriesFromTable } from 'owa-mail-triage-action';
import {
    getSelectedTableView,
    getRowKeysFromRowIds,
    isConversationView,
} from 'owa-mail-list-store';
import { getDensityModeString, getDensityModeCssClass } from 'owa-fabric-theme';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import type { MailboxInfo } from 'owa-client-types';
import {
    readingPaneHeader,
    isSpecialCaseCard as styles_isSpecialCaseCard,
    full,
    medium,
    compact,
    subjectText,
    subjectTextWithCategoriesDensity,
    immersiveExitContainer,
    subjectContainer,
    subjectIcons,
    readingPaneInnerContainer,
    subjectLineContainer,
    lockIcon,
    subjectHeaderV2,
    slvTop,
    topRightContainer,
    inlineSubject,
    top,
    categoryOuterContainer,
    specialCardContainerWithExitTreatment,
    specialCardIconContainer,
    specialCardIconWithExitTreatment,
    specialCardIcon,
    isConversationView as styles_isConversationView,
} from './SubjectHeaderV2.scss';

import classnames from 'owa-classnames';
import SubjectHeaderTopToolBar from './SubjectHeaderTopToolBar';
import SubjectHeaderBottomToolBar from './SubjectHeaderBottomToolBar';
import SubjectLine from './SubjectLine';
import { useResizeObserver } from 'owa-react-hooks/lib/useResizeObserver';
import { useBooleanState } from 'owa-react-hooks/lib/useBooleanState';
import SLVNavButtons from './SLVNavButtons';
import type { ClientItemId } from 'owa-client-ids';
import CategoriesContainer from './CategoriesContainer';
import { FocusedExplanationIcon } from 'owa-focused-explanations';
import MessageAttachmentsContainer from './MessageAttachmentsContainer';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { getActiveContentTab, TabType } from 'owa-tab-store';

export interface SubjectHeaderProps {
    subject: string | undefined;
    expandCollapseButton?: JSX.Element | null;
    conversationId?: string;
    firstItemId?: string;
    categories?: string[];
    className?: string;
    isSmimeEncrypted?: boolean;
    viewState?: ConversationReadingPaneViewState | ItemReadingPaneViewState;
    specialCardIconName?: string;
    isSxS?: boolean;
    item?: ClientItem;
    itemId?: ClientItemId;
    isPopout?: boolean;
    isSingleLine?: boolean;
    isItemAttachment?: boolean;
}

const SHRINK_WIDTH = 370;
// Please update this number with largest possible width of:
// MessageAttachmentsContainer + Zoom + Conversation expansion button
// if you are going to add an additional button
const TOP_CLUSTER_WIDTH = 220;
const INLINE_RATIO = 0.3;

export default observer(function SubjectHeaderV2(props: SubjectHeaderProps) {
    const {
        isSxS,
        specialCardIconName,
        isSmimeEncrypted,
        conversationId,
        firstItemId,
        viewState,
        categories,
        className,
        subject,
        expandCollapseButton,
        item,
        isSingleLine,
        itemId,
        isItemAttachment,
        isPopout,
    } = props;

    // indicates that categories/attachments/topics need to be in a 'collapsed' mode
    const headerRef = React.useRef<HTMLDivElement>(null);
    const topToolbarRef = React.useRef<HTMLDivElement>(null);
    const subjectRef = React.useRef<HTMLDivElement>(null);
    const [isShrunk, setShouldShrink, setNoShrink] = useBooleanState(false);
    const [areCategoriesVisible, showCategories, hideCategories] = useBooleanState(false);

    const [shouldInlineAttachments, setInlineAttachments, setAttachmentsOnTop] =
        useBooleanState(true);
    const messageCLPLabel: CLPLabel | null = useComputedValue(() => {
        if (firstItemId) {
            const firstItem = mailStore.items.get(firstItemId);

            return (
                (firstItem?.MSIPLabelGuid &&
                    getLabelFromStore(
                        firstItem.MSIPLabelGuid,
                        firstItem.MailboxInfo ?? getModuleContextMailboxInfo()
                    )) ||
                null
            );
        }

        return null;
    }, [firstItemId]);

    const shrinkCallback = React.useCallback(
        ({ width }: DOMRectReadOnly) => {
            if (width != 0) {
                if (width < SHRINK_WIDTH) {
                    setShouldShrink();
                } else {
                    setNoShrink();
                }
                // this logic determines whether or not we inline topics/attachments with
                // the subject line or display it next to the zoom/conversation expansion buttons
                const ratio = TOP_CLUSTER_WIDTH / width;
                if (ratio < INLINE_RATIO) {
                    setAttachmentsOnTop();
                } else {
                    setInlineAttachments();
                }
            }
        },
        [setShouldShrink, setNoShrink, setAttachmentsOnTop, setInlineAttachments]
    );
    useResizeObserver('SubjectHeaderV2', headerRef, shrinkCallback);

    React.useEffect(() => {
        if (messageCLPLabel) {
            lazyLogReadCLPLabel.import().then((logReadCLPLabel: (label: CLPLabel) => void) => {
                messageCLPLabel && logReadCLPLabel(messageCLPLabel);
            });
        }
    }, [messageCLPLabel]);

    const activeSubject: string | undefined = useComputedValue(() => {
        if (viewState) {
            const itemReadingPaneViewState = viewState as ItemReadingPaneViewState;
            if (itemReadingPaneViewState?.itemViewState) {
                const itemReadingPaneViewStateItemId = itemReadingPaneViewState.itemId;
                if (itemReadingPaneViewStateItemId) {
                    const clientItem: ClientItem | undefined = mailStore.items.get(
                        itemReadingPaneViewStateItemId
                    );

                    if (
                        clientItem?.TranslationData?.isShowingSubjectTranslation &&
                        clientItem.TranslationData.subjectTranslationText
                    ) {
                        return clientItem.TranslationData.subjectTranslationText;
                    }
                }
            } else {
                const conversationReadingPaneViewState =
                    viewState as ConversationReadingPaneViewState;
                if (conversationReadingPaneViewState.conversationId?.Id) {
                    const conversationItemParts = mailStore.conversations.get(
                        conversationReadingPaneViewState.conversationId.Id
                    );
                    // Get subject from inline translation if active
                    if (
                        conversationItemParts != null &&
                        conversationItemParts.subjectTranslationData != null &&
                        conversationItemParts.subjectTranslationData.isShowingTranslation &&
                        conversationItemParts.subjectTranslationData.translationText
                    ) {
                        return conversationItemParts.subjectTranslationData.translationText;
                    }
                }
            }
        }
        return subject;
    }, [viewState]);

    const mailboxInfo: MailboxInfo | undefined = useComputedValue(() => {
        if (viewState) {
            const itemReadingPaneViewState = viewState as ItemReadingPaneViewState;
            if (itemReadingPaneViewState?.itemViewState) {
                const itemReadingPaneViewStateItemId = itemReadingPaneViewState.itemId;
                if (itemReadingPaneViewStateItemId) {
                    const clientItem: ClientItem | undefined = mailStore.items.get(
                        itemReadingPaneViewStateItemId
                    );
                    return clientItem?.MailboxInfo;
                }
            } else {
                const conversationReadingPaneViewState =
                    viewState as ConversationReadingPaneViewState;
                if (conversationReadingPaneViewState?.conversationId) {
                    return conversationReadingPaneViewState.conversationId.mailboxInfo;
                }
            }
        } else {
            if (firstItemId) {
                const clientItem: ClientItem | undefined = mailStore.items.get(firstItemId);
                return clientItem?.MailboxInfo;
            }
        }
        return undefined;
    }, [viewState, firstItemId]);

    const isSpecialCaseCard = specialCardIconName != null;
    const containerClassNames = classnames(className, readingPaneHeader, {
        [styles_isSpecialCaseCard]: isSpecialCaseCard,
    });
    const shouldRenderCategories = !!categories;
    const densityModeCssClass = getDensityModeCssClass(full, medium, compact);

    const subjectTextClass = classnames(densityModeCssClass, subjectText, {
        [subjectTextWithCategoriesDensity]: shouldRenderCategories && !messageCLPLabel,
    });

    const immersiveExit = classnames(immersiveExitContainer, {
        [styles_isSpecialCaseCard]: isSpecialCaseCard,
    });

    const showNavigateBackButton = isImmersiveReadingPaneShown();

    const subjectClassNames = classnames(subjectContainer, densityModeCssClass);
    const subjectIconsClassNames = classnames(subjectIcons, densityModeCssClass);

    const onRemoveCategoryClicked = (category: string) => {
        if (!firstItemId) {
            return;
        }

        const tableView = getSelectedTableView();
        let rowKeys = [];

        if (isConversationView(tableView)) {
            const firstItem = mailStore.items.get(firstItemId);

            if (firstItem?.ConversationId?.Id) {
                const firstItemConversationId = firstItem.ConversationId.Id;
                rowKeys = getRowKeysFromRowIds([firstItemConversationId], tableView);
            } else {
                return;
            }
        } else {
            rowKeys = getRowKeysFromRowIds([firstItemId], tableView);
        }

        lazyRemoveCategoriesFromTable.importAndExecute(
            rowKeys,
            tableView,
            [category],
            'SubjectHeader',
            true
        );
        hideCategories();
    };

    const categoriesToRender: JSX.Element[] = shouldRenderCategories
        ? renderCategories(
              categories || [],
              onRemoveCategoryClicked,
              mailboxInfo ?? getModuleContextMailboxInfo(),
              isShrunk
          )
        : [];

    const topToolBar =
        conversationId || itemId ? (
            <SubjectHeaderTopToolBar
                expandCollapseButton={expandCollapseButton}
                shouldShrink={isShrunk}
                conversationReadingPaneViewState={
                    conversationId ? (viewState as ConversationReadingPaneViewState) : undefined
                }
                isSxS={isSxS}
            />
        ) : null;

    const renderSubjectLineContainer = (isSlv: boolean, activeSubjectForContainer?: string) => {
        return (
            <div
                className={containerClassNames}
                key={`subjectHeaderContainerDiv_${activeSubjectForContainer || ''}`}
            >
                <div className={readingPaneInnerContainer}>
                    <div className={subjectClassNames}>
                        {/* immersive exit button is not part of this container for single line view */}
                        {!isSlv && showNavigateBackButton && !isSxS && !isPopout && (
                            <ImmersiveExitButton
                                iconButtonContainer={immersiveExit}
                                shouldUseTextButton={true}
                            />
                        )}

                        {specialCardIconName &&
                            renderSpecialCardIcon(
                                showNavigateBackButton,
                                specialCardIconName,
                                viewState
                            )}
                        <div
                            className={classnames(subjectLineContainer, {
                                [styles_isSpecialCaseCard]:
                                    getDensityModeString() == 'compact' && isSpecialCaseCard,
                            })}
                        >
                            <SubjectLine
                                subject={activeSubjectForContainer}
                                subjectTextClass={subjectTextClass}
                                shouldShrink={isShrunk}
                            />
                            {!isShrunk && (
                                <div className={subjectIconsClassNames} ref={subjectRef}>
                                    {isSmimeEncrypted && (
                                        <Icon iconName={'Lock'} className={lockIcon} />
                                    )}
                                    {categories && categories.length > 0 && (
                                        <CategoriesContainer
                                            categories={categoriesToRender}
                                            shouldShrink={isShrunk}
                                            areCategoriesVisible={areCategoriesVisible}
                                            hideCategories={hideCategories}
                                            showCategories={showCategories}
                                        />
                                    )}
                                    {!!viewState && <FocusedExplanationIcon />}
                                    {messageCLPLabel && (
                                        <CLPSubjectHeaderLabel
                                            subjectHeaderLabel={messageCLPLabel}
                                            hideLabel={isShrunk}
                                        />
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const subjectHeaderClassName = classnames(subjectHeaderV2, densityModeCssClass);
    const isInfoPaneEnabled = isFeatureEnabled('doc-infoPane') && !!conversationId;
    const shouldGetMessageAttachments =
        !isInfoPaneEnabled &&
        !isShrunk &&
        !isItemAttachment &&
        !isPopout &&
        !viewState?.loadingState?.isLoading;

    const messageAttachments = useComputedValue(() => {
        return shouldGetMessageAttachments ? (
            <MessageAttachmentsContainer
                conversationId={conversationId}
                firstItemId={firstItemId}
                shouldShrink={!shouldInlineAttachments}
                item={item}
            />
        ) : undefined;
    }, [shouldGetMessageAttachments, conversationId, firstItemId, shouldInlineAttachments, item]);

    const isActivePrimaryTab = getActiveContentTab()?.type == TabType.Primary;

    return (
        <div className={subjectHeaderClassName} ref={headerRef}>
            {isSingleLine && showNavigateBackButton && !isSxS && !isPopout && isActivePrimaryTab ? (
                <>
                    <div className={slvTop}>
                        {<SLVNavButtons shouldShrink={isShrunk} />}
                        <div className={topRightContainer} ref={topToolbarRef}>
                            {!shouldInlineAttachments && messageAttachments}
                            {topToolBar}
                        </div>
                    </div>
                    <div className={inlineSubject}>
                        {renderSubjectLineContainer(true /* isSlv */, activeSubject)}
                        {shouldInlineAttachments && messageAttachments}
                    </div>
                    {isShrunk && (
                        <SubjectHeaderBottomToolBar
                            conversationId={conversationId}
                            categories={categoriesToRender}
                            messageCLPLabel={messageCLPLabel}
                            shouldShrink={isShrunk}
                            isItemAttachment={isItemAttachment}
                            isPopout={isPopout}
                            areCategoriesVisible={areCategoriesVisible}
                            showCategories={showCategories}
                            hideCategories={hideCategories}
                        />
                    )}
                </>
            ) : (
                <>
                    <div className={top}>
                        <div>
                            {renderSubjectLineContainer(false /* isSlv */, activeSubject)}
                            {shouldInlineAttachments && messageAttachments}
                        </div>
                        <div className={topRightContainer} ref={topToolbarRef}>
                            {!shouldInlineAttachments && messageAttachments}
                            {topToolBar}
                        </div>
                    </div>
                    {isShrunk && (
                        <SubjectHeaderBottomToolBar
                            conversationId={conversationId}
                            categories={categoriesToRender}
                            messageCLPLabel={messageCLPLabel}
                            shouldShrink={isShrunk}
                            isItemAttachment={isItemAttachment}
                            isPopout={isPopout}
                            areCategoriesVisible={areCategoriesVisible}
                            showCategories={showCategories}
                            hideCategories={hideCategories}
                        />
                    )}
                </>
            )}
        </div>
    );
}, 'SubjectHeaderV2');

async function onCategoryClicked(
    ev: React.MouseEvent<unknown>,
    category: string,
    actionSource: string
) {
    // Need stop propagation so the click doesn't bubble up to container, i.e list view item and reading pane subject
    ev.stopPropagation();
    const startSearchWithCategory = await lazyStartSearchWithCategory.import();
    startSearchWithCategory(actionSource, category);
}

function renderCategories(
    categories: string[],
    onRemoveCategoryClicked: (category: string) => void,
    mailboxInfo: MailboxInfo,
    shouldShrink?: boolean
) {
    const categoryElements = [];

    for (let i = categories.length - 1; i >= 0; i--) {
        const categoryName = categories[i];
        categoryElements.push(
            <div
                key={categoryName}
                //  if we have 3 or more categories they sould not be styled in non-well mode
                className={classnames(
                    categories.length < 3 && !shouldShrink && categoryOuterContainer
                )}
            >
                <Category
                    category={categoryName}
                    actionSource={'SubjectHeader'}
                    isLastInWell={i == 0}
                    onCategoryClicked={onCategoryClicked}
                    showCategoryRemove={true}
                    onRemoveCategoryClicked={onRemoveCategoryClicked}
                    mailboxInfo={mailboxInfo}
                />
            </div>
        );
    }
    return categoryElements;
}

function renderSpecialCardIcon(
    showNavigateBackButton: boolean,
    specialCardIconName: string,
    viewState: ConversationReadingPaneViewState | ItemReadingPaneViewState | undefined
) {
    if (viewState) {
        const specialCardStyle = showNavigateBackButton
            ? specialCardContainerWithExitTreatment
            : specialCardIconContainer;
        const specialIconStyle = showNavigateBackButton
            ? specialCardIconWithExitTreatment
            : specialCardIcon;
        const conversationReadingPaneViewState = viewState as ConversationReadingPaneViewState;
        return (
            <div
                className={classnames(specialCardStyle, {
                    [styles_isConversationView]: !!conversationReadingPaneViewState?.conversationId,
                })}
            >
                <Icon iconName={specialCardIconName} className={specialIconStyle} />
            </div>
        );
    }
    return <></>;
}
