import { unselectItemParts } from './toggleSelectItemPart';
import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import { FocusedItemArea } from 'owa-mail-reading-pane-store/lib/store/schema/FocusedItemPart';
import type ItemPartViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemPartViewState';
import getConversationReadingPaneViewState from '../utils/getConversationReadingPaneViewState';
import findInlineComposeViewState from 'owa-mail-compose-actions/lib/utils/findInlineComposeViewState';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';

export interface ExpandCollapseNewestOnBottomFossilizedTextState {
    itemPartViewState: ItemPartViewState;
    conversationReadingPaneState: ConversationReadingPaneViewState;
}

export default action('expandCollapseNewestOnBottomFossilizedText')(
    function expandCollapseNewestOnBottomFossilizedText(
        conversationId: string,
        nodeId: string,
        isFossilizedTextExpanded: boolean,
        state: ExpandCollapseNewestOnBottomFossilizedTextState = {
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2322 (23,13): Type 'ItemPartViewState | undefined' is not assignable to type 'ItemPartViewState'.
            // @ts-expect-error
            itemPartViewState:
                getConversationReadingPaneViewState(conversationId).itemPartsMap.get(nodeId),
            conversationReadingPaneState: getConversationReadingPaneViewState(conversationId),
        }
    ) {
        state.itemPartViewState.isFossilizedTextExpanded = !isFossilizedTextExpanded;
        const canFocus = !findInlineComposeViewState(
            state.conversationReadingPaneState.conversationId.Id
        );
        if (canFocus) {
            unselectItemParts(conversationId, {
                conversationReadingPaneState: state.conversationReadingPaneState,
            });
            state.conversationReadingPaneState.focusedItemPart = {
                itemPart: state.itemPartViewState,
                focusedItemArea: FocusedItemArea.FossilizedText,
            };
        }
    }
);
