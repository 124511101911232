import type { RibbonTabDefinitionBase } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { owaComputedFn } from 'owa-computed-fn';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import getMLRComposeTab from '../../../util/getMLRComposeTab';
import { getMLRInsertTabCommonGroupFlyout } from './getMLRInsertTabCommonGroupFlyout';
import { getMLRInsertTabGroupLayouts } from './getMLRInsertTabGroupLayouts';

const emptyCtrlList: RibbonControlId[] = [];

const getMLRInsertTab = owaComputedFn(function getMLRInsertTab(
    props: ComposeRibbonControlDefProps
): RibbonTabDefinitionBase {
    return getMLRComposeTab(
        props,
        5,
        emptyCtrlList,
        getMLRInsertTabGroupLayouts,
        getMLRInsertTabCommonGroupFlyout
    );
});
export default getMLRInsertTab;
