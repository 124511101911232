import { LazyAction, LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "InlineImageLoader" */ './lazyIndex'),
    { name: 'InlineImageLoader' }
);

export const lazyClearInlineImageStore = new LazyAction(lazyModule, m => m.clearInlineImageStore);

export const lazyTryCreateDataURIFromImage = new LazyImport(
    lazyModule,
    m => m.tryCreateDataURIFromImage
);

export const lazyCacheInlineImage = new LazyImport(lazyModule, m => m.cacheInlineImage);
export const lazyLogImageUpload = new LazyImport(lazyModule, m => m.logImageUpload);
export const lazyLogImageLoadError = new LazyImport(lazyModule, m => m.logImageLoadError);

export const lazyLoadFromAttachmentServiceUrl = new LazyImport(
    lazyModule,
    m => m.loadFromAttachmentServiceUrl
);

export const lazyLoadFromAttachment = new LazyImport(lazyModule, m => m.loadFromAttachment);
export const lazyLoadFromProxy = new LazyImport(lazyModule, m => m.loadFromProxy);
export const lazyLoadFromImageTag = new LazyImport(lazyModule, m => m.loadFromImageTag);

export { default as isImageProxyEnabled } from './utils/isImageProxyEnabled';
export { default as isInlineImageStoreEmpty } from './utils/isInlineImageStoreEmpty';
