import { ObservableMap } from 'mobx';
import type AddinOptionSurfaceItemsStore from './schema/AddinOptionSurfaceItems';
import type SurfaceActionsStore from './schema/SurfaceActionsStore';
import { createStore } from 'satcheljs';

const initialSurfaceActionsStore: SurfaceActionsStore = {
    addinOptionSurfaceItems: new ObservableMap<string, AddinOptionSurfaceItemsStore>(),
};
const store = createStore<SurfaceActionsStore>('SurfaceActionsStore', initialSurfaceActionsStore)();

export default store;
