import { action } from 'satcheljs';

/**
 * Action triggered when hovers bookmark icon and executes orchestrator to
 * get classification reason
 * @param rowKey the rowKey of the selected mail item
 */
export const renderMessageOnHover = action('ON_HOVER_RENDER_MESSAGE', (rowKey: string) => ({
    rowKey,
}));
