import { getConditionalFormattingStore } from '../store/store';
import { orchestrator } from 'satcheljs';
import saveConditionalFormattingRulesService from '../services/saveConditionalFormattingRulesService';
import clearRowToModificationMap from '../mutators/clearRowToModificationMap';
import { setConditionalFormattingRules } from '../actions/setConditionalFormattingRules';
import { setItem } from 'owa-local-storage';
import { CONDITIONAL_FORMATTING_SETTING_NAME } from '../utils/constants';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { logUsage } from 'owa-analytics';

/* eslint-disable-next-line owa-custom-rules/forbid-orchestrator-in-same-package -- (https://aka.ms/OWALintWiki)
 * Baseline. DO NOT COPY AND PASTE!
 *	> Do not register an orchestrator with an action that is defined in the same package */
orchestrator(setConditionalFormattingRules, actionMessage => {
    const { mailboxInfo, conditionalFormattingRules, isFetch } = actionMessage;

    if (conditionalFormattingRules) {
        if (conditionalFormattingRules.some(cfRule => !cfRule)) {
            logUsage('TnS_ConditionalFormatting_Error', {
                message: isFetch
                    ? 'Null values while fetching conditional formatting rules'
                    : 'Null values while saving conditional formatting rules',
            });
        }
        const cleanedRules = conditionalFormattingRules.filter(
            conditionalFormattingRule => conditionalFormattingRule
        );
        getConditionalFormattingStore(mailboxInfo).conditionalFormattingRules = cleanedRules;

        saveConditionalFormattingRulesService(mailboxInfo, JSON.stringify(cleanedRules));

        setItem(
            window,
            CONDITIONAL_FORMATTING_SETTING_NAME + getIndexerValueForMailboxInfo(mailboxInfo),
            JSON.stringify(cleanedRules)
        );
    }

    // Whenever we modify (add, delete, edit, move position) the conditional formatting rules, we want to invalidate the rowToModificationMap
    // so that we trigger a re-evaluation of the rules to apply the appropriate modifications for the rows in the message list.
    clearRowToModificationMap(mailboxInfo);
});
