import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppSplitButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import { getHoverTooltipHotkey } from 'owa-compose-ribbon-utils/lib/utils/hoverTooltipHotkeyMap';
import ClipboardPaste from 'owa-fluent-icons-svg/lib/icons/ClipboardPasteRegular';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { onCutCopyMenuClicked } from '../actions/deferredMenuDefinitionMutator';
import type RibbonCutCopyPasteInfo from 'owa-compose-formatcontrols-sections/lib/schema/RibbonCutCopyPasteInfo';
import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import { pasteDesc } from '../strings/hoverTooltipDesc.locstring.json';
import { pasteText } from './formatControlStrings.locstring.json';
import { PasteType, pasteOperation } from './paste';
import type RibbonFormatState from 'owa-editor-ribbonplugin-store/lib/store/schema/RibbonFormatState';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';

export const getPasteCutCopyControl = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonCutCopyPasteInfo | null,
        formatViewState: RibbonFormatState,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        const locPasteText = loc(pasteText);
        const storeName = 'cutCopyMenuDefinitionStore';
        return createAppSplitButton(
            getComposeRibbonId(6012, editorId),
            locPasteText,
            getControlMenuStore(editorId, storeName),
            getComputedCallback(
                6012,
                pasteOperation,
                editorId,
                PasteType.Normal,
                composeId /*retrieverKey*/,
                retriever
            ),
            tabId ? constructKeytip([tabId], 6012, true /* hasMenu */) : undefined,
            ClipboardPaste,
            undefined /* iconColor */,
            styles,
            {
                splitButtonAriaLabel: locPasteText,
                disabled: isDisabled,
                customTooltip: getHoverTooltip(pasteText, pasteDesc, getHoverTooltipHotkey(6011)),
                onMenuButtonExecute: () => {
                    onCutCopyMenuClicked(
                        editorId,
                        composeId,
                        retriever,
                        formatViewState,
                        styles,
                        tabId,
                        isDisabled
                    );
                },
                onAfterMenuDismiss: () => {
                    resetMenuDefinition(editorId, storeName);
                    callEditorApi(editorId, 'focus');
                },
            }
        );
    }
);
