import { createLazyComponent, LazyModule } from 'owa-bundling';
import isBrowserSupportProjection from './utils/isBrowserSupportProjection';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ProjectionPopout" */ './lazyIndex'),
    { name: 'ProjectionPopout' }
);

export const ProjectionPopoutHost = createLazyComponent(
    lazyModule,
    m => m.ProjectionPopoutHost,
    undefined,
    undefined,
    {
        onlyImportIf: () => isBrowserSupportProjection(),
    }
);
