import type { InfoBarHostViewState } from '../schema/InfoBarMessageViewState';
import { infoBarMessageCreatorsMap } from '../utils/infoBarMessageCreatorsMap';
import { mutatorAction } from 'satcheljs';
import { errorThatWillCauseAlert } from 'owa-trace';

/**
 * @param viewState the host viewstate storing the list of messageIds to render
 * @param messageIds the message Id of the infobar to removem can be a single message id or a array of ids.
 * If messageIds is not passed, all existing messages will be removed.
 */
const removeInfoBarMessageMutator = mutatorAction(
    'removeInfoBarMessages',
    (viewState: InfoBarHostViewState, index: number, messageId: string) => {
        viewState.infoBarIds.splice(index, 1);
        const key = viewState.infoBarHostKey;

        if (infoBarMessageCreatorsMap[key]) {
            delete infoBarMessageCreatorsMap[key][messageId];
            if (Object.keys(infoBarMessageCreatorsMap[key]).length === 0) {
                delete infoBarMessageCreatorsMap[key];
            }
        }
    }
);

/**
 * If no messageIds are passed, this function removes all info bars. Example, when closing compose.
 */
export default function removeInfoBarMessage(
    viewState: InfoBarHostViewState,
    messageIds?: string | string[]
) {
    // Try/catch in order to avoid component errors; we'll still trace errors so we can track down
    // and fix the root cause.
    try {
        if (viewState) {
            const messagesToRemove = Array.isArray(messageIds)
                ? messageIds
                : messageIds
                ? [messageIds]
                : viewState.infoBarIds
                ? [...viewState.infoBarIds]
                : [];

            for (const messageId of messagesToRemove) {
                const index = viewState.infoBarIds ? viewState.infoBarIds.indexOf(messageId) : -1;
                if (index >= 0) {
                    removeInfoBarMessageMutator(viewState, index, messageId);
                }
            }
        }
    } catch (error) {
        errorThatWillCauseAlert('Error in removeInfoBarMessage', error);
    }
}
