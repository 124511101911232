import type { Layout } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components/RibbonControlGroupMLR';
import {
    multiRow,
    sections,
    singleRow,
    stack,
} from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components/RibbonControlGroupMLR/layoutTypings';
import { owaComputedFn } from 'owa-computed-fn';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import type { RibbonGroupId } from 'owa-ribbon-ids';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { type MailRibbonGroupId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';

export const getMLRMessageTabGroupLayouts = owaComputedFn(
    (group: RibbonGroupId, props: ComposeRibbonControlDefProps): Layout[] | undefined => {
        const editorId = props.composeViewState?.editorId;
        switch (group) {
            case 163:
                return [
                    sections(
                        singleRow(
                            getComposeRibbonId(6012, editorId).toString(),
                            stack(
                                getComposeRibbonId(6013, editorId).toString(),
                                getComposeRibbonId(6029, editorId).toString(),
                                null
                            )
                        )
                    ),
                ];

            case 157:
                return [
                    sections(
                        multiRow(
                            [
                                getComposeRibbonId(6027, editorId).toString(),
                                getComposeRibbonId(6028, editorId).toString(),
                                getComposeRibbonId(6005, editorId).toString(),
                                getComposeRibbonId(6006, editorId).toString(),
                                getComposeRibbonId(6024, editorId).toString(),
                                getComposeRibbonId(6030, editorId).toString(),
                                getComposeRibbonId(6016, editorId).toString(),
                            ],
                            [
                                getComposeRibbonId(6001, editorId).toString(),
                                getComposeRibbonId(6002, editorId).toString(),
                                getComposeRibbonId(6003, editorId).toString(),
                                getComposeRibbonId(6004, editorId).toString(),
                                getComposeRibbonId(6026, editorId).toString(),
                                getComposeRibbonId(6025, editorId).toString(),
                                getComposeRibbonId(6008, editorId).toString(),
                                getComposeRibbonId(6007, editorId).toString(),
                                getComposeRibbonId(6071, editorId).toString(),
                                getComposeRibbonId(6075, editorId).toString(),
                                getComposeRibbonId(6020, editorId).toString(),
                                getComposeRibbonId(6021, editorId).toString(),
                                getComposeRibbonId(6022, editorId).toString(),
                                getComposeRibbonId(6023, editorId).toString(),
                            ]
                        )
                    ),
                ];
            case 174:
                return [sections(singleRow(getComposeRibbonId(7002, editorId).toString()))];
            case 161:
                return [
                    sections(
                        singleRow(getComposeRibbonId(620, editorId).toString()),
                        singleRow(getComposeRibbonId(7025, editorId).toString()),
                        singleRow(getComposeRibbonId(506, editorId).toString()),
                        singleRow(getComposeRibbonId(7026, editorId).toString()),
                        singleRow(
                            stack(
                                getComposeRibbonId(638, editorId).toString(),
                                getComposeRibbonId(639, editorId).toString(),
                                getComposeRibbonId(637, editorId).toString()
                            )
                        )
                    ),
                ];

            case 187:
                return [sections(singleRow(getComposeRibbonId(7029, editorId).toString()))];

            case 173:
                return [sections(singleRow(getComposeRibbonId(7000, editorId).toString()))];

            case 160:
                return [
                    sections(
                        singleRow(
                            getComposeRibbonId(7005, editorId).toString(),
                            getComposeRibbonId(7006, editorId).toString(),
                            getComposeRibbonId(564, editorId).toString()
                        )
                    ),
                ];

            case 170:
                return [sections(singleRow(getComposeRibbonId(560, editorId).toString()))];

            case 171:
                return [sections(singleRow(getComposeRibbonId(7011, editorId).toString()))];

            case 162:
                return [
                    sections(
                        singleRow(
                            getComposeRibbonId(7001, editorId).toString(),
                            getComposeRibbonId(7004, editorId).toString(),
                            getComposeRibbonId(6072, editorId).toString(),
                            getComposeRibbonId(7010, editorId).toString()
                        )
                    ),
                ];

            default:
                return undefined;
        }
    }
);
