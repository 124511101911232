import type { RibbonTabDefinitionBase } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, Multiline } from 'owa-command-ribbon';
import loc from 'owa-localize';
import { getMailRibbonConfigStore } from 'owa-mail-ribbon-store/lib/store/store';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { getUniqueTabId } from 'owa-mail-ribbon-utils/lib/getUniqueTabId';
import { getInitialScalingSteps } from './getInitialScalingSteps';
import { getMLRScalingSteps } from './getScalingSteps';
import { getMLRTabGroups } from './getTabGroups';
import { getComposeTabsControlDefinition } from '../components/controlDefinitions/getComposeTabsControlDefinition';
import { MailRibbonTabTitleMap } from './getSLRComposeTab';
import type { TabScalingAndLayoutConfig } from 'owa-mail-ribbon-store-shared-types';
import { assertNever } from 'owa-assert';
import type { RibbonGroupId, RibbonControlId } from 'owa-ribbon-ids';
import type { Layout } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components/RibbonControlGroupMLR';
import type { RibbonFlyoutAnchorProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon';
import { ContextualRibbonTabs } from './contextualRibbonTabs';

const getTabConfig = (mailRibbonTabId: MailRibbonTabId): TabScalingAndLayoutConfig => {
    const multiLine = getMailRibbonConfigStore().multiline;
    switch (mailRibbonTabId) {
        case 4:
            return multiLine.formatTab;
        case 5:
            return multiLine.insertTab;
        case 10:
            return multiLine.messageTab;
        case 11:
            return multiLine.optionsTab;
        case 7:
            return multiLine.tableFormatTab;
        case 12:
            return multiLine.drawTab;
        case 13:
            return multiLine.pictureTab;
        default:
            return assertNever(mailRibbonTabId as never);
    }
};

const getMLRComposeTab = owaComputedFn(function getMLRComposeTab(
    props: ComposeRibbonControlDefProps,
    tabId: MailRibbonTabId,
    emptyCtrlList: RibbonControlId[],
    getMLRTabGroupLayouts: (
        group: RibbonGroupId,
        props: ComposeRibbonControlDefProps
    ) => Layout[] | undefined,
    getMLRTabCommonGroupFlyout: (
        group: RibbonGroupId,
        props: ComposeRibbonControlDefProps
    ) => RibbonFlyoutAnchorProps | undefined
): RibbonTabDefinitionBase {
    const tabTitle = MailRibbonTabTitleMap.get(tabId) ?? '';
    const tabConfig: TabScalingAndLayoutConfig = getTabConfig(tabId);
    const editorId = props.composeViewState?.editorId;
    return {
        type: Multiline,
        id: getUniqueTabId(tabId, editorId, undefined /* itemId*/, props.isPopout),
        title: loc(tabTitle),
        controlGroups: {
            type: Multiline,
            groups: getMLRTabGroups(
                tabId,
                tabConfig.layout,
                getComposeTabsControlDefinition,
                getMLRTabGroupLayouts,
                getMLRTabCommonGroupFlyout,
                props
            ),
        },
        initialScalingSteps: getInitialScalingSteps(
            tabConfig,
            emptyCtrlList /*controlsWithoutLabels*/,
            editorId,
            undefined /*itemId*/
        ),
        scalingSteps: getMLRScalingSteps(tabId),
        keytipProps: constructKeytip([], tabId, true /* hasMenu */),
        tabType: ContextualRibbonTabs.indexOf(tabId) > -1 ? 'contextual' : 'default',
    };
});
export default getMLRComposeTab;
