import { observer } from 'owa-mobx-react';
import React, { useMemo } from 'react';
import { Shimmer, ShimmerElementsGroup, ShimmerElementType } from '@fluentui/react/lib/Shimmer';
import { getPaletteAsRawColors } from 'owa-theme';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import {
    header,
    personaCircle,
    shimmerContainer,
    neutralPaletteBackground,
    messageContent,
} from './LoadingShimmer.scss';
import classNames from 'owa-classnames';
import {
    getItemReadingPaneViewState,
    getConversationReadingPaneViewState,
} from 'owa-mail-reading-pane-store';
import { logUsage } from 'owa-analytics';

const shimmerStyle = {
    root: {
        paddingTop: '40px',
    },
};

const personaCircleShimmerElements = [
    { type: ShimmerElementType.gap, width: '16px', height: 20 },
    { type: ShimmerElementType.circle, height: 40 },
    { type: ShimmerElementType.gap, width: '100%', height: 20 },
];

const firstHeaderLineShimmerElements = [
    { type: ShimmerElementType.line, width: '10%' },
    { type: ShimmerElementType.gap, width: '90%', height: 20 },
    { type: ShimmerElementType.line, width: '16%' },
    {
        type: ShimmerElementType.gap,
        width: '84%',
        height: 20,
    },
];

const secondHeaderLineShimmerElements = [
    { type: ShimmerElementType.line, width: '100%', height: 32 },
    { type: ShimmerElementType.gap, width: '100%' },
];

const messageContentShimmerElements = [
    {
        type: ShimmerElementType.line,
        width: '16%',
        height: 32,
    },
    {
        type: ShimmerElementType.gap,
        width: '84%',
        height: 32,
    },
];

export interface LoadingShimmerProps {
    isItem?: boolean;
}

const LoadingShimmer = observer(function LoadingShimmer(props: LoadingShimmerProps) {
    React.useEffect(() => {
        const mountTimestamp = performance.now();

        // Shimmer unmounts when there is a change in loading state of reading pane
        // or when user selects a different item
        return () => {
            const shimmerDuration = performance.now() - mountTimestamp;
            const readingPaneViewState = props.isItem
                ? getItemReadingPaneViewState()
                : getConversationReadingPaneViewState();

            logUsage('LoadingShimmer', {
                cardType: readingPaneViewState?.extendedCardViewState?.cardType,
                isLoading: readingPaneViewState?.loadingState?.isLoading,
                hasLoadFailed: readingPaneViewState?.loadingState?.hasLoadFailed,
                shimmerDuration,
                isItem: props.isItem,
            });
        };
    }, []);

    const generateHeaders = (): JSX.Element => {
        return (
            <div className={header}>
                <div className={personaCircle}>
                    <ShimmerElementsGroup
                        width={'100%'}
                        shimmerElements={personaCircleShimmerElements}
                        backgroundColor={newDarkPaletteBackground}
                    />
                </div>
                <ShimmerElementsGroup
                    width={'78%'}
                    flexWrap={true}
                    shimmerElements={firstHeaderLineShimmerElements}
                    backgroundColor={newDarkPaletteBackground}
                />
                <ShimmerElementsGroup
                    width={'16%'}
                    flexWrap={true}
                    shimmerElements={secondHeaderLineShimmerElements}
                    backgroundColor={newDarkPaletteBackground}
                />
            </div>
        );
    };
    const newDarkPaletteBackground = useComputedValue(
        () => getPaletteAsRawColors().neutralPrimarySurface,
        []
    );

    const StyledShimmer = React.memo(
        (shimmerProps: { paddingTop: string; shimmerWidth: string }) => {
            const { paddingTop, shimmerWidth } = shimmerProps;
            const basicShimmerLineStyle = React.useMemo(
                () => ({
                    root: {
                        paddingTop,
                        backgroundColor: newDarkPaletteBackground,
                    },
                }),
                [paddingTop]
            );

            return <Shimmer styles={basicShimmerLineStyle} width={shimmerWidth} />;
        }
    );
    const shimmerColors = useMemo(
        () => ({ background: newDarkPaletteBackground }),
        [newDarkPaletteBackground]
    );

    return (
        <div
            className={classNames(shimmerContainer, {
                [neutralPaletteBackground]: true,
            })}
        >
            <Shimmer improveCSSPerformance={true} customElementsGroup={generateHeaders()} />
            <div className={messageContent}>
                <StyledShimmer paddingTop="16px" shimmerWidth="30%" />
                <StyledShimmer paddingTop="8px" shimmerWidth="95%" />
                <StyledShimmer paddingTop="8px" shimmerWidth="95%" />
                <StyledShimmer paddingTop="16px" shimmerWidth="95%" />
                <StyledShimmer paddingTop="8px" shimmerWidth="95%" />
                <StyledShimmer paddingTop="8px" shimmerWidth="60%" />
                <Shimmer
                    styles={shimmerStyle}
                    shimmerElements={messageContentShimmerElements}
                    shimmerColors={shimmerColors}
                />
            </div>
        </div>
    );
}, 'LoadingShimmer');

export default LoadingShimmer;
