import {
    type RibbonIconColor,
    getRibbonIconModeColor,
} from 'owa-command-ribbon/lib/util/ribbonIconModeColors';
import {
    getQuickStepHotkeyText,
    getQuickSteps,
    getQuickStepsRunOnce,
} from 'owa-mail-quick-steps-store';
import { AppGalleryButton } from 'owa-command-ribbon';
import type { AppGalleryStyles } from '@1js/acui-gallery';
import type { AppIconGalleryButtonProps } from '@1js/acui-gallery-icon';
import type { IButtonStyles } from '@fluentui/react/lib/Button';
import { type MailRibbonControlId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { QuickStepItem } from 'owa-quick-step-types';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { owaComputedFn } from 'owa-computed-fn';
import { getControlInfo } from 'owa-mail-ribbon-utils/lib/getControlInfo';
import { getIconInfoFromIconId } from 'owa-mail-quick-steps-store/lib/util/getQuickStepIcon';
import { getIsQuickStepDisabled } from 'owa-mail-quick-step-actions';
import { getPalette } from 'owa-theme';
import { getRibbonMailboxInfo } from 'owa-mail-ribbon-utils/lib/getRibbonMailboxInfo';
import { getHoverTooltip } from 'owa-mail-ribbon-utils/lib/HoverTooltips/hoverTooltipConfig';
import { singleLineRibbonStyleConstants } from 'owa-command-ribbon-styles/lib/singleLineRibbonStyleConstants';

const maxGalleryItems = 6;

function truncateQuickStepName(name: string) {
    if (name.length > MAX_LENGTH) {
        return [true, name.slice(0, MAX_LENGTH) + TRUNCATION_ELLIPSIS];
    } else {
        return [false, name];
    }
}
const TRUNCATION_ELLIPSIS = '...';
const MAX_LENGTH = 25;

// SLR gallery button styles
const getSLRGalleryButtonInPreviewStyles = owaComputedFn(
    function getSLRGalleryButtonInPreviewStyles(): IButtonStyles {
        return {
            root: {
                borderRadius: 0,
            },
            rootPressed: {
                backgroundColor: getPalette().neutralQuaternary,
            },
            rootHovered: {
                backgroundColor: getPalette().neutralLighterAlt,
            },
        };
    }
);

// MLR gallery button styles
const getMLRGalleryButtonInPreviewStyles = owaComputedFn(
    function getMLRGalleryButtonInPreviewStyles(): IButtonStyles {
        return {
            root: {
                height: '50%',
                borderRadius: 4,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: 'transparent',
                width: '100px',
            },
            rootPressed: {
                backgroundColor: getPalette().neutralQuaternary,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: getPalette().neutralTertiary,
            },
            rootHovered: {
                backgroundColor: getPalette().neutralLighterAlt,
                borderWidth: 1,
                borderStyle: 'solid',
                borderColor: getPalette().neutralQuaternary,
            },
        };
    }
);

// Gallery styles
const getDefaultAppGalleryStyles = owaComputedFn(function getDefaultAppGalleryStyles(
    isSLR: boolean
): Partial<AppGalleryStyles> {
    const palette = getPalette();

    const { borderRadius, chevronIconSize, rootHeight, rootMarginTop } =
        singleLineRibbonStyleConstants;

    // rootHeight - (borderWidth * 2)
    const innerHeight = rootHeight - 2;

    return {
        galleryContainer: {
            root: {
                borderRadius,
                borderColor: isSLR ? palette.neutralQuaternary : palette.neutralTertiary,
                background: palette.neutralPrimarySurface,
                height: isSLR ? rootHeight : undefined,
                marginRight: isSLR ? 4 : undefined,
                marginTop: rootMarginTop,
                overflow: 'hidden',
            },
        },
        galleryButtonInPreview: {
            root: {
                height: isSLR ? innerHeight : undefined,
            },
        },
        previewExpandButton: {
            icon: {
                // Setting `height` or `width` here is ignored, so using `marginLeft` to align the icon correctly
                marginLeft: isSLR ? undefined : -4,
            },
            root: {
                borderLeft: `1px solid ${
                    isSLR ? palette.neutralQuaternary : palette.neutralTertiary
                }`,
                backgroundColor: palette.neutralPrimarySurface,
                fontSize: chevronIconSize,
                height: isSLR ? innerHeight : undefined,
            },
            rootHovered: {
                backgroundColor: palette.neutralLight,
                outline: 'transparent dashed 2px',
                borderColor: palette.neutralQuaternary,
            },
            rootPressed: {
                backgroundColor: palette.neutralQuaternary,
                borderColor: palette.neutralQuaternary,
            },
            rootExpanded: {
                background: palette.neutralQuaternary,
            },
            rootExpandedHovered: {
                background: palette.neutralLight,
            },
        },
    };
});

export function getQuickStepsGalleryItems(
    isSLR: boolean,
    props: ReadOnlyRibbonControlDefProps
): AppIconGalleryButtonProps[] {
    const galleryButtons: AppIconGalleryButtonProps[] = [];

    const mailboxInfo = getRibbonMailboxInfo(props);

    const quickSteps: QuickStepItem[] = getQuickSteps(mailboxInfo).slice(0, maxGalleryItems);
    const quickStepsRunOnce: boolean = getQuickStepsRunOnce(mailboxInfo);

    const { iconSize } = singleLineRibbonStyleConstants;
    const previewIconSize = isSLR ? iconSize : 16;

    // If we have no quick steps, only show the default quick steps icon
    // Also if the user has never used a quick step, we will show the default quick steps icon
    if (quickSteps.length === 0 || !quickStepsRunOnce) {
        const controlInfo = getControlInfo(550, props);
        const iconName = controlInfo.iconName;
        const iconColor = getRibbonIconModeColor(2);
        galleryButtons.push({
            id: (550).toString(),
            type: AppGalleryButton,
            label: controlInfo.text,
            previewIconProps: {
                iconName,
                style: {
                    fontSize: previewIconSize,
                    height: previewIconSize,
                    width: previewIconSize,
                    color: iconColor,
                },
            },
            title: controlInfo.tooltip,
            style: isSLR
                ? getSLRGalleryButtonInPreviewStyles()
                : getMLRGalleryButtonInPreviewStyles(),
        });
    } else {
        const qsLength = isSLR ? 1 : Math.min(maxGalleryItems, quickSteps.length);
        for (let i = 0; i < qsLength; i++) {
            const step = quickSteps[i];
            const nameInfo = truncateQuickStepName(step.name);
            const iconInfo = getIconInfoFromIconId(step, mailboxInfo);
            const iconName = iconInfo.name;
            galleryButtons.push({
                id: step.id,
                type: AppGalleryButton,
                label: nameInfo[1] as string,
                previewIconProps: {
                    iconName,
                    style: {
                        fontSize: previewIconSize,
                        height: previewIconSize,
                        width: previewIconSize,
                        color: iconInfo.color,
                    },
                },
                title: getHoverTooltip(undefined /* controlId */, step.name, {
                    description: step.description,
                    fallbackToName: nameInfo[0] as boolean,
                    hotkey: getQuickStepHotkeyText(step.hotkey),
                }),
                disabled: getIsQuickStepDisabled(step.actions),
                style: isSLR
                    ? getSLRGalleryButtonInPreviewStyles()
                    : getMLRGalleryButtonInPreviewStyles(),
            });
        }
    }
    return galleryButtons;
}

// The icon gallery code is currently set up to have a set number of items in stacked mode
// however, our users can have any number from 1-6 (well, we cap at 6 for the preview gallery,
// they can have more in the dropdown). so to make sure the gallery isn't way too big
// for the amount of quick steps, we manually set the width. this is a hack.
export const getQuickStepGalleryStyles = owaComputedFn(function getQuickStepGalleryStyles(
    isSLR: boolean,
    numSteps: number
): Partial<AppGalleryStyles> {
    const defaultAppGalleryStyles = getDefaultAppGalleryStyles(isSLR);

    let galleryWidth = '100px';

    if (numSteps === 3 || numSteps === 4) {
        galleryWidth = '200px';
    } else if (numSteps === 5 || numSteps === 6) {
        galleryWidth = '300px';
    }

    return {
        ...defaultAppGalleryStyles,
        ...(!isSLR && {
            previewStackStyle: {
                root: {
                    width: galleryWidth,
                },
            },
        }),
    };
});
