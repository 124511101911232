import getSelectedTableView from 'owa-mail-list-store/lib/utils/getSelectedTableView';
import { highlightTermsInHtmlElement } from 'owa-highlight';

export default function (element: HTMLElement) {
    const tableView = getSelectedTableView();
    if (tableView) {
        // TableView is null when open a message from photohub
        highlightTermsInHtmlElement(
            element,
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2345 (13,13): Argument of type 'string[] | undefined' is not assignable to parameter of type 'string[]'.
            // @ts-expect-error
            tableView.highlightTerms,
            false /* separateWordSearch */,
            tableView.highlightAccuracy /* highlightAccuracy */
        );
    }
}
