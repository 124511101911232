import { PersonaSize } from '@fluentui/react/lib/Persona';
import { getDensityModeString } from 'owa-fabric-theme';

export function calculatePersonaSize(): PersonaSize {
    switch (getDensityModeString()) {
        case 'medium':
            return PersonaSize.size32;
        case 'compact':
            return PersonaSize.size24;
        default:
            return PersonaSize.size40;
    }
}
