import type Message from 'owa-service/lib/contract/Message';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';

const folderExclusionList: string[] = [
    'junkemail',
    'deleteditems',
    'drafts',
    'notes',
    'conversationhistory',
];

const shouldSendViewMessageSignal = (message: Message | undefined) => {
    return (
        message &&
        !message.IsDraft &&
        !(
            message?.ParentFolderId?.Id &&
            folderExclusionList.includes(folderIdToName(message.ParentFolderId.Id))
        )
    );
};

export default shouldSendViewMessageSignal;
