import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppFlyoutAnchor } from 'owa-command-ribbon';
import { sensitivity_FlyoutButton_ActionControl } from '../strings/actionControlStrings.locstring.json';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import Classification from 'owa-fluent-icons-svg/lib/icons/ClassificationRegular';
import loc from 'owa-localize';
import type { ComposeViewState } from 'owa-mail-compose-store';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import { sensitivityDesc } from '../strings/hoverTooltipDesc.locstring.json';
import mailStore from 'owa-mail-store/lib/store/Store';
import { isMeetingMessage } from 'owa-meeting-message-utils/lib/utils/isMeetingMessage';
import { type ComposeOperation } from 'owa-mail-compose-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import { onSensitivityMenuClicked } from '../actions/deferredMenuDefinitionMutator';
import haveAtleastOneValidSensitivityLabel from '../utils/haveAtleastOneValidSensitivityLabel';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';
import type CLPLabel from 'owa-mail-protection-types/lib/schema/CLPLabel';
import onSensitivity from 'owa-mail-commands/lib/actions/onSensitivity';

export const getSensitivityControl = owaComputedFn(
    (
        editorId: string,
        viewState: ComposeViewState,
        retriever: (composeId: string) => RibbonMailComposeInfo | null,
        keyTipTabId: number | undefined,
        styles: IButtonStyles | undefined,
        isDisabled: boolean,
        onSensitivityClicked?: (viewState: ComposeViewState, label: CLPLabel) => void
    ): RibbonControlProps => {
        const composeId: string = viewState.composeId;
        const storeName = 'sensitivityMenuDefinitionStore';

        const onSensitivityLabelClicked = (label: CLPLabel) => {
            const mailComposeInfo = retriever(composeId);
            if (mailComposeInfo) {
                onSensitivityClicked
                    ? onSensitivityClicked(mailComposeInfo.composeViewState, label)
                    : onSensitivity(
                          mailComposeInfo.composeViewState,
                          label,
                          mailComposeInfo.targetWindow
                      );
            }
        };

        return createAppFlyoutAnchor(
            getComposeRibbonId(564, viewState.editorId),
            loc(sensitivity_FlyoutButton_ActionControl),
            getControlMenuStore(editorId, storeName),
            keyTipTabId ? constructKeytip([keyTipTabId], 564, true /* hasMenu */) : undefined,
            Classification,
            undefined /* iconColor */,
            styles,
            {
                customTooltip: getHoverTooltip(
                    sensitivity_FlyoutButton_ActionControl,
                    sensitivityDesc
                ),
                disabled:
                    isDisabled ||
                    !haveAtleastOneValidSensitivityLabel(viewState) ||
                    (isFeatureEnabled('cal-clpLabel-respect-rml') &&
                        viewState.operation === 3 &&
                        isMeetingMessageFromViewState(viewState)),
                onExecute: () => {
                    const mailComposeInfo = retriever(composeId);
                    if (mailComposeInfo) {
                        onSensitivityMenuClicked(
                            editorId,
                            mailComposeInfo.composeViewState,
                            onSensitivityLabelClicked,
                            keyTipTabId
                        );
                    }
                },
                onAfterMenuDismiss: () => {
                    resetMenuDefinition(editorId, storeName);
                    callEditorApi(editorId, 'focus');
                },
            }
        );
    }
);

const isMeetingMessageFromViewState = (viewState: ComposeViewState): boolean => {
    if (viewState.itemId) {
        const item = mailStore.items.get(viewState.itemId.Id);
        if (item) {
            return !!isMeetingMessage(item);
        }
    }
    return true;
};
