// extracted by mini-css-extract-plugin
export var attendeePanelContainer = "AYK0Q";
export var calendarCardV2Container = "NiD4s";
export var collapsedItemPart = "GjFKx";
export var collapsedItemPartStatusBar = "c7KoH";
export var collapsedItemsRollUpContainer = "PCN7W";
export var collapsedItemsRollUpIconButton = "W6_zW";
export var collapsedItemsRollUpText = "H3VIT";
export var compact = "Vi1QO";
export var deletedItemBleedThrough = "gwEE3";
export var deletedItemStrikeThrough = "U_FVo";
export var expCollConversationButton = "GUu0J";
export var expandedItemPart = "SlLx9";
export var flexContainer = "MtujV";
export var full = "jmmB7";
export var hasCompose = "gXGox";
export var headerRightContainer = "Ts94W";
export var isFocused = "byzS1";
export var isRead = "YoK0k";
export var isSpecialCaseCard = "Fu9JA";
export var itemPart = "aVla3";
export var itemPartFirefox = "XyUOw";
export var loadingSpinner = "ofJyQ";
export var loadingSpinnerDiv = "uUpvk";
export var loadingText = "LeZ28";
export var medium = "G8bTU";
export var messageBody = "XbIp4";
export var neutralPaletteBackground = "WWy1F";
export var noOverlay = "xzEcH";
export var oofRollUp = "OuBzC";
export var pendingNodeExpandedItemPart = "HMs1m";
export var preview = "fnz7h";
export var previewBody = "_nzWz";
export var previewCompact = "dgSFQ";
export var previewFull = "vmKcz";
export var previewMedium = "E0d1R";
export var previewTime = "NNlvm";
export var scrollRegionContainer = "L72vd";
export var seeMoreMessagesThemeProvider = "PnhcN";
export var senderImageContainer = "iaEpS";
export var slide_up_fade_in = "PYrle";
export var subjectContainer = "NTPm6";
export var undoDarkMode = "wIgSn";
export var undoDarkModeFocusedBorder = "lioMG";