import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { isActionControl } from './isActionControl';
import { isFormatControl } from './isFormatControl';
import { isDrawControl } from './isDrawControl';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';

const ActionControlsEnabledForPlainText: Set<ActionControlId> = new Set([
    7002, 7001, 620, 564, 529, 7005, 7006, 560, 7010, 506, 7009, 7020, 7019, 7014, 7015, 7003, 7031,
]);

const FormatControlsEnabledForPlainText: Set<FormatControlId> = new Set([6009, 6010, 6012]);

export const getIsDisabledForPlainText = (id: number): boolean => {
    if (ActionControlsEnabledForPlainText.has(id) || FormatControlsEnabledForPlainText.has(id)) {
        return false;
    }
    return isActionControl(id) || isFormatControl(id) || isDrawControl(id);
};
