export const DESKTOP_SETTINGS_URL: string =
    '/ows/beta/OutlookCloudSettings/settings/account?settingname=desktop_notification_settings';
export const settingName: string = 'desktop_notification_settings';

export enum DesktopNotificationSetting {
    DesktopSettingEnabled = 'DesktopSettingEnabled',
    NewMailNotificationSettingEnabled = 'NewMailNotificationSettingEnabled',
    NewMailNotificationOption = 'NewMailNotificationOption',
    EventReminderNotificationSettingEnabled = 'EventReminderNotificationSettingEnabled',
    EventReminderDisplayOption = 'EventReminderDisplayOption',
    NativePushNotificationSettingEnabled = 'NativePushNotificationSettingEnabled',
    EventAutomaticallyDismissPastDueRemindersEnabled = 'EventAutomaticallyDismissPastDueRemindersEnabled',
}

export enum MessageNotificationSetting {
    Inbox,
    Favorite,
}

export enum EventReminderDisplaySetting {
    Popup,
    Desktop,
}

export interface DesktopNotificationSettingServiceValue {
    DesktopSettingEnabled: boolean;
    NewMailNotificationSettingEnabled: boolean;
    NewMailNotificationOption: MessageNotificationSetting;
    EventReminderNotificationSettingEnabled: boolean;
    EventReminderDisplayOption: EventReminderDisplaySetting;
    EventAutomaticallyDismissPastDueRemindersEnabled: boolean;
    NativePushNotificationSettingEnabled: boolean;
}

export type NotificationSettingValue =
    | MessageNotificationSetting
    | EventReminderDisplaySetting
    | boolean;
