import type { RibbonTabDefinitionBase } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { owaComputedFn } from 'owa-computed-fn';
import { assertNever } from 'owa-assert';
import { constructKeytip, SingleLine } from 'owa-command-ribbon';
import loc from 'owa-localize';
import Strings from 'owa-locstrings/lib/strings/ribbon_mail_buttons_and_tabs.locstring.json';
import { getMailRibbonConfigStore } from 'owa-mail-ribbon-store/lib/store/store';
import type { TabScalingAndLayoutConfig } from 'owa-mail-ribbon-store-shared-types';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { getUniqueTabId } from 'owa-mail-ribbon-utils/lib/getUniqueTabId';
import type { RibbonControlId } from 'owa-ribbon-ids';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { getComposeTabsControlDefinition } from '../components/controlDefinitions/getComposeTabsControlDefinition';
import { getInitialScalingSteps } from './getInitialScalingSteps';
import { getRuntimeControlsInitialScalingSteps } from './getRuntimeControlsInitialScalingSteps';
import { getScalingSteps } from './getScalingSteps';
import { getTabGroups } from './getTabGroups';
import type { ResourceId } from 'owa-localize';
import { ContextualRibbonTabs } from './contextualRibbonTabs';

export const MailRibbonTabTitleMap: Map<MailRibbonTabId, ResourceId> = new Map<
    MailRibbonTabId,
    ResourceId
>([
    [4, Strings.format_tab],
    [5, Strings.insert_tab],
    [10, Strings.message_tab],
    [11, Strings.options_tab],
    [7, Strings.format_table_tab],
    [12, Strings.draw_tab],
    [13, Strings.format_picture_tab],
]);

const getTabConfig = (mailRibbonTabId: MailRibbonTabId): TabScalingAndLayoutConfig => {
    const singleLine = getMailRibbonConfigStore().singleline;
    switch (mailRibbonTabId) {
        case 4:
            return singleLine.formatTab;
        case 5:
            return singleLine.insertTab;
        case 10:
            return singleLine.messageTab;
        case 11:
            return singleLine.optionsTab;
        case 7:
            return singleLine.tableFormatTab;
        case 12:
            return singleLine.drawTab;
        case 13:
            return singleLine.pictureTab;
        default:
            return assertNever(mailRibbonTabId as never);
    }
};

const getSLRComposeTab = owaComputedFn(function getSLRComposeTab(
    props: ComposeRibbonControlDefProps,
    tabId: MailRibbonTabId,
    controlsDontDropLabels: RibbonControlId[],
    controlsDontMoveToOverflow: RibbonControlId[],
    controlsFirstMoveToOverflow: RibbonControlId[],
    controlsWithoutLabels: RibbonControlId[]
): RibbonTabDefinitionBase {
    const tabTitle = MailRibbonTabTitleMap.get(tabId) ?? '';
    const tabConfig: TabScalingAndLayoutConfig = getTabConfig(tabId);
    const editorId = props.composeViewState?.editorId;
    return {
        id: getUniqueTabId(tabId, editorId, undefined /* itemId */, props.isPopout),
        type: SingleLine,
        title: loc(tabTitle),
        controlGroups: {
            type: SingleLine,
            groups: getTabGroups(tabId, tabConfig.layout, getComposeTabsControlDefinition, props),
        },
        initialScalingSteps: [
            ...getRuntimeControlsInitialScalingSteps(tabId, editorId),
            ...getInitialScalingSteps(
                tabConfig,
                controlsWithoutLabels,
                editorId,
                undefined /*itemId */
            ),
        ],
        scalingSteps: getScalingSteps(
            tabConfig.layout,
            tabConfig.showLabelsPreference,
            controlsDontMoveToOverflow,
            controlsFirstMoveToOverflow,
            controlsDontDropLabels,
            editorId,
            undefined /*itemId */
        ),
        keytipProps: constructKeytip([], tabId, true /* hasMenu */),
        tabType: ContextualRibbonTabs.indexOf(tabId) > -1 ? 'contextual' : 'default',
    };
});
export default getSLRComposeTab;
