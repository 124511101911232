import { trustedTypesCustomPolicy } from '../trustedTypesCustomPolicy';
import { sanitize } from 'dompurify';

export function getTrustedHTML(htmlString: string, extendedConfig: DOMPurify.Config = {}): string {
    return trustedTypesCustomPolicy
        ? trustedTypesCustomPolicy.createHTML(htmlString, extendedConfig).toString()
        : sanitize(htmlString, {
              USE_PROFILES: { html: true },
              ...extendedConfig,
          }).toString();
}
