import React from 'react';
import { registerIcons } from '@fluentui/style-utilities';
import { createIconComponent } from 'owa-fluent-icons-svg';

export default 'Loop';

const LoopIcon = createIconComponent({
    IconComponent: () => (
        <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path
                fill="#0078D4"
                d="M2 18V10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18H2ZM5.44843 17H10C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10V16.9624C3.92616 16.825 4.84907 16.3188 5.58395 15.5839C6.45127 14.7166 7 13.5874 7 12.5V10C7 8.34315 8.34315 7 10 7C11.6569 7 13 8.34315 13 10C13 11.6569 11.6569 13 10 13H7.9711C7.82946 14.2325 7.17929 15.4028 6.29105 16.2911C6.03231 16.5498 5.74962 16.7883 5.44843 17ZM8 12H10C11.1046 12 12 11.1046 12 10C12 8.89543 11.1046 8 10 8C8.89543 8 8 8.89543 8 10V12Z"
            />
        </svg>
    ),
    displayName: 'Loop',
});

registerIcons({
    icons: {
        Loop: <LoopIcon />,
    },
});
