import { getIsDisabledForPlainText } from 'owa-compose-ribbon-utils/lib/utils/getIsDisabledForPlainText';
import { isFormatControl } from 'owa-compose-ribbon-utils/lib/utils/isFormatControl';
import { type LastFocusedControl } from 'owa-editor-addin-plugin-types/lib/utils/AddinViewState';
import { getFocusIsOnLoopForViewStateId } from 'owa-fluid-integrations/lib/selectors/getFocusIsOnLoopForViewStateId';
import isComposePopout from 'owa-mail-compose-actions/lib/utils/isComposePopout';
import { composeStore } from 'owa-mail-compose-store';
import type { ComposeViewState } from 'owa-mail-compose-store';
import { getIsMultipleSelection } from 'owa-mail-list-store/lib/utils/getIsMultipleSelection';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { getActiveContentTab, TabType } from 'owa-tab-store';
import { isComposeTabControl } from './isComposeTabControl';
import getCombinedAccessIssue from 'owa-attachment-policy-access-issue-checker/lib/utils/getCombinedAccessIssue';
import type AccessIssue from 'owa-attachment-policy-access-issue-checker/lib/schema/AccessIssue';
import { type DrawControlId } from 'owa-ribbon-ids/lib/drawControlId';
import { logUsage } from 'owa-analytics';

type IsButtonDisabledRule = (controlId: number, viewState: ComposeViewState) => boolean;

// export only for test
export const isDisabledForMultipleSelection = (
    controlId: number,
    viewState: ComposeViewState
): boolean => {
    const activeTab = getActiveContentTab();
    return (
        isComposeTabControl(controlId) &&
        !isComposePopout(viewState.composeId) &&
        getIsMultipleSelection() &&
        activeTab?.type === TabType.Primary
    );
};

// export only for test
export const isDisabledForLoopFocus = (controlId: number, viewState: ComposeViewState): boolean => {
    return (
        (controlId === 7029 || isFormatControl(controlId)) &&
        getFocusIsOnLoopForViewStateId(viewState.composeId)
    );
};

// export only for test
export const isDisableLoopButtonForTableOrConditionalAccess = (
    controlId: number,
    viewState: ComposeViewState
): boolean => {
    if (controlId === 7000 || controlId === 7026) {
        const cursorIsOnTable: boolean = !!viewState.ribbon.formatViewState.isInTable;
        const hasAccessISsue = getCombinedAccessIssue(viewState.mailboxInfo) !== 2;
        if (cursorIsOnTable || hasAccessISsue) {
            logUsage('FluidNotEnabledCursorOrAccessIssue', { cursorIsOnTable, hasAccessISsue });
            return true;
        }
    }
    return false;
};

// export only for test
export const isDisabledForFocusInTable = (
    controlId: number,
    viewState: ComposeViewState
): boolean => {
    const controlsDisabled: Set<number> = new Set<number>([7021, 12011]);
    if (controlsDisabled.has(controlId)) {
        const cursorIsOnTable: boolean = !!viewState.ribbon.formatViewState.isInTable;
        return cursorIsOnTable;
    }

    return false;
};

const isDisabledForQuotedBody = (controlId: number, viewState: ComposeViewState): boolean => {
    return controlId === 7002 && viewState.virtualEdit.useSmartResponse && !viewState.quotedBody;
};

// export only for test
export const isDisabledForInlineCompose = (
    controlId: number,
    viewState: ComposeViewState
): boolean => {
    return controlId === 7002 && viewState.isInlineCompose;
};

// export only for test
export const isDisabledForRecipientSubjectFocus = (
    controlId: number,
    viewState: ComposeViewState
): boolean => {
    const controlsEnabled: Set<number> = new Set<number>([
        7012, 7009, 7020, 7019, 7002, 620, 506, 7005, 7006, 564, 7001, 7014, 7015, 7003, 7010, 7016,
        7023, 7031, 560, 12006, 12007, 12008, 12009, 12005, 12004, 12003, 12012, 12010,
    ]);

    if (controlsEnabled.has(controlId)) {
        return false;
    }

    return (
        isComposeTabControl(controlId) &&
        (viewState.addin.lastFocusedControl === 1 ||
            viewState.addin.lastFocusedControl === 0 ||
            viewState.addin.lastFocusedControl === 3)
    );
};

// export only for test
export const isDisabledForPlainText = (controlId: number, viewState: ComposeViewState): boolean => {
    const isPlainText: boolean = viewState.bodyType == 'Text';
    return isPlainText && getIsDisabledForPlainText(controlId);
};

const getButtonDisabledRules = (): IsButtonDisabledRule[] => {
    return [
        isDisableLoopButtonForTableOrConditionalAccess,
        isDisabledForRecipientSubjectFocus,
        isDisabledForPlainText,
        isDisabledForMultipleSelection,
        isDisabledForLoopFocus,
        isDisabledForInlineCompose,
        isDisabledForFocusInTable,
        isDisabledForQuotedBody,
    ];
};

export const getIsButtonDisabledForComposeTabs = (
    controlId: number,
    composeId: string
): boolean => {
    const viewState: ComposeViewState | undefined = composeStore.viewStates.get(composeId);
    if (!!viewState) {
        return getButtonDisabledRules().some(fn => fn(controlId, viewState));
    }
    return true;
};
