import { logStartGreyError } from 'owa-analytics-start';
import type DistinguishedFolderIdName from 'owa-service/lib/contract/DistinguishedFolderIdName';
import folderIdToName from './folderIdToName';

export default function doesFolderIdEqualName(
    folderId: string,
    distinguishedFolderName: DistinguishedFolderIdName
): boolean {
    if (!folderId) {
        const error = new Error('DoesFolderIdEqualNameInvalidParameters');
        /* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
         * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
         *	> Datapoint's event names can only be a string literals as the first argument of the function call. */
        logStartGreyError(error.message, error);

        return false;
    }

    return folderIdToName(folderId) === distinguishedFolderName;
}
