import type { RibbonTabDefinitionBase } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { owaComputedFn } from 'owa-computed-fn';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { getMLRTableFormatTabGroupLayouts } from './getMLRTableFormatTabGroupLayouts';
import { getMLRTableFormatTabCommonGroupFlyout } from './getMLRTableFormatTabCommonGroupFlyout';
import getMLRComposeTab from '../../../../util/getMLRComposeTab';

const getMLRTableFormatTab = owaComputedFn(function getMLRTableFormatTab(
    props: ComposeRibbonControlDefProps
): RibbonTabDefinitionBase {
    return getMLRComposeTab(
        props,
        7,
        [] /*controlsWithoutLabels*/,
        getMLRTableFormatTabGroupLayouts,
        getMLRTableFormatTabCommonGroupFlyout
    );
});

export default getMLRTableFormatTab;
