import type { OwaImageEditPlugin } from '../OwaImageEditPlugin';

const imageEdit: {
    [editorId: string]: OwaImageEditPlugin;
} = {};

export function addImageEditOperator(editorId: string, imageEditPlugin: OwaImageEditPlugin) {
    imageEdit[editorId] = imageEditPlugin;
}

export function removeImageEditOperator(editorId: string) {
    delete imageEdit[editorId];
}

export function operateOwaImageEdit(
    editorId: string | null,
    callback: (imageEditPlugin: OwaImageEditPlugin) => void
) {
    if (!!editorId) {
        const imageEditPlugin = imageEdit[editorId];
        if (imageEditPlugin) {
            callback(imageEditPlugin);
        }
    }
}
