import {
    rangeSelectRow,
    singleSelectRow,
    toggleSelectRow,
} from 'owa-mail-actions/lib/mailListSelectionActions';
import { onSingleMailItemSelected } from 'owa-mail-actions/lib/mailListActions';
import { getIsSearchTableShown } from 'owa-mail-list-store';
import getListViewStore from 'owa-mail-list-store/lib/store/Store';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import type React from 'react';
import { isMac } from 'owa-user-agent';
import { isReadingPanePositionOff } from 'owa-mail-layout/lib/selectors/readingPanePosition';
import { setInteractionTimestamp } from 'owa-mail-logging/lib/utils/selectMailItemDatapointUtils';

/**
 * A handler for events created by user clicking mail list item(s)
 * @param evt the mouse event
 * @param selectionSource the source of event
 * @param rowKey the given conversation rowKeys
 * @param tableViewId - the table view id that this conversation belong to (or null if we don't care)
 * @returns a promise with boolean value based on if we have handled the event here
 */
export default function onMailListItemClickHandler(
    evt: React.MouseEvent<unknown> | KeyboardEvent | React.KeyboardEvent<unknown>,
    selectionSource: MailListItemSelectionSource,
    rowKey: string,
    tableViewId: string
): void {
    evt.stopPropagation();

    debounce(
        (simulateDoubleClick?: boolean) => {
            const selectionSourceToUse = simulateDoubleClick
                ? MailListItemSelectionSource.MailListItemSimulatedDoubleClick
                : selectionSource;
            onMailListItemClickHandlerInternal(evt, selectionSourceToUse, rowKey, tableViewId);
        },
        selectionSource === MailListItemSelectionSource.MailListItemBodyDoubleClick,
        rowKey
    );
}

function onMailListItemClickHandlerInternal(
    evt: React.MouseEvent<unknown> | KeyboardEvent | React.KeyboardEvent<unknown>,
    selectionSource: MailListItemSelectionSource,
    rowKey: string,
    tableViewId: string
) {
    // If the OS is a Mac, use evt.metaKey to detect Cmd key
    const isCtrlOrCmdKeyDown = isMac() ? evt.metaKey : evt.ctrlKey;
    const tableView = getListViewStore().tableViews.get(tableViewId);
    let isExplicitSelect;
    const eventTimeStamp = (evt as React.MouseEvent<unknown> | React.KeyboardEvent<unknown>)
        .nativeEvent.timeStamp;

    // The promise returns false if we do not handle the click here
    // Otherise promise returns true meaning we have captured the click event here
    if (evt.shiftKey) {
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2345 (40,24): Argument of type 'TableView | undefined' is not assignable to parameter of type 'TableView'.
        // @ts-expect-error
        rangeSelectRow(tableView, rowKey, selectionSource, isCtrlOrCmdKeyDown);
    } else if (
        isCtrlOrCmdKeyDown ||
        selectionSource === MailListItemSelectionSource.MailListItemCheckbox
    ) {
        tableView &&
            toggleSelectRow(
                tableView,
                rowKey,
                true /* isUserNavigation */,
                selectionSource,
                eventTimeStamp
            );
    } else {
        if (tableView) {
            setInteractionTimestamp(eventTimeStamp);
            singleSelectRow(
                tableView,
                rowKey,
                true /* isUserNavigation */,
                selectionSource,
                eventTimeStamp
            );
        }
        isExplicitSelect = selectionSource !== MailListItemSelectionSource.MailListItemRichPreview;
    }

    if (isExplicitSelect && !getIsSearchTableShown()) {
        onSingleMailItemSelected();
    }
}

let timer: ReturnType<typeof setInterval>;
let pendingClickedRowKey: string = ''; // The rowKey of the clicked item waiting to be processed
const DEBOUNCE_DELAY = 200; // in ms

/**
 * Execute the callback and reset the pendingClickedRowKey (since the click is
 * no longer pending).
 *
 * @param callback the callback to execute
 * @param simulateDoubleClick if the user is double clicking
 */
const executeCallback = (callback: any, simulateDoubleClick: boolean = false) => {
    callback(simulateDoubleClick);
    pendingClickedRowKey = '';
    clearTimeout(timer);
};

/**
 * Debounce the callback if the user is clicking on the same row.
 *
 * @param callback the callback to execute
 * @param isDoubleClick if the user is double clicking
 * @param rowKey the rowKey of the clicked item
 */
const debounce = (callback: any, isDoubleClick: boolean, rowKey: string) => {
    // If there is a pending click, we need to check if the user is double clicking.
    if (pendingClickedRowKey) {
        clearTimeout(timer);

        // If the user is double clicking, we don't need to debounce.
        if (rowKey === pendingClickedRowKey) {
            executeCallback(callback, true /* simulateDoubleClick */);
            return;
        }
    }

    // If reading pane is on (or if an actual double click), we don't need
    // to debounce.
    if (isDoubleClick || !isReadingPanePositionOff()) {
        executeCallback(callback);
    } else {
        // Track the rowKey of the clicked item and debounce the callback.
        pendingClickedRowKey = rowKey;

        // If the user doesn't click again within DEBOUNCE_DELAY ms, we'll
        // process the click.
        timer = setTimeout(() => {
            executeCallback(callback);
        }, DEBOUNCE_DELAY);
    }
};
