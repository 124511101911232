import type AttachmentWellHost from 'owa-calendar-meeting-forms-common-store/lib/store/schema/AttachmentWellHost';
import type { CalendarEvent } from 'owa-calendar-types';
import type { ClientItemId } from 'owa-client-ids';
import type EditorViewState from 'owa-editor/lib/store/schema/EditorViewState';
import type { ResponseType } from 'owa-graph-schema';
import type CollabSpaceFormsViewState from 'owa-calendar-meeting-forms-common-store/lib/store/schema/CollabSpaceFormsViewState';
export type ResponseEditorState = any;

export enum CalendarCardPivot {
    Messages = 'Messages',
    MeetingDetails = 'MeetingDetails',
}

export enum CalendarCardContentWidth {
    Unset = 'Unset',
    Narrow = 'Narrow',
    Medium = 'Medium',
    Large = 'Large',
    Wide = 'Wide',
}

export interface CalendarCardViewState extends AttachmentWellHost, CollabSpaceFormsViewState {
    /**
     * the id of the latest meeting mesage that used to populate calendar card, which can be
     * - meeting reuqest, or cancellation, or response(organizer receives RSVP for series exception)
     */
    latestMeetingMessageId: ClientItemId | null;
    latestRequestId: ClientItemId | null;
    latestCancellationId: ClientItemId | null;
    eventId: ClientItemId | null;
    event: CalendarEvent;
    removedFromCalendar: boolean;
    responseType: ResponseType;
    responseEditorState: ResponseEditorState;
    emailOrganizer: boolean;
    editorViewState: EditorViewState;
    activePivot: CalendarCardPivot;
    contentWidth: CalendarCardContentWidth;
    unreadMessageCount: number;
    eventViewedLogged: boolean;
    rsvpToOrganizerWithNote: boolean;
}
