import { action } from 'satcheljs';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import { addDatapointConfig } from 'owa-analytics-actions';

/**
 * Action triggered when click on the tooltip to move a mail item to another inbox.
 * @param rowKey the rowKey of the selected mail item.
 * @param tableViewId the id of the table where the conversation belongs.
 * @param destination the inbox where the mail item will be placed.
 */
export const onBookmarkIconClicked = action(
    'ON_CLICK_BOOKMARK_ICON',
    (rowKey: string, tableViewId: string, destination: FocusedViewFilter) =>
        addDatapointConfig(
            {
                name: 'TnS_FocusedInboxClassificationCorrection',
                customData: {
                    origin: destination == 1 ? 'Focused' : 'Other',
                },
            },
            {
                rowKey,
                tableViewId,
                destination,
            }
        )
);
