import { getIsMsHighContrast } from 'owa-high-contrast';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { getRibbonViewMode } from 'owa-command-ribbon-store';
import { lazyGetDrawToolClayButtonStyles } from 'owa-compose-drawcontrols-icons';

export const getDrawButtonStyles = owaComputedFn(
    (styles: IButtonStyles | undefined): IButtonStyles | undefined => {
        const buttonStyles = lazyGetDrawToolClayButtonStyles.tryImportForRender()?.(
            styles,
            getRibbonViewMode(),
            getIsMsHighContrast()
        );

        return buttonStyles;
    }
);
