import ActionState from '../schema/ActionState';
import joinButtonStore from '../JoinButtonStore';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import type { ActionButtonState } from '../schema/ActionButtonState';
import { ObservableMap } from 'mobx';

export function getGroupActionState(mailboxInfo: MailboxInfo) {
    const index = getIndexerValueForMailboxInfo(mailboxInfo);
    const joinButtonState = joinButtonStore.groupActionStateByMailboxInfo.get(index);

    if (!joinButtonState) {
        const defaultGroupActionState: ActionButtonState = {
            groupActionState: new ObservableMap<string, ActionState>(),
        };
        joinButtonStore.groupActionStateByMailboxInfo.set(index, defaultGroupActionState);
        return defaultGroupActionState.groupActionState;
    }

    return joinButtonState.groupActionState;
}

export function getJoinButtonState(
    mailboxInfo: MailboxInfo,
    groupSmtpAddress: string
): ActionState {
    groupSmtpAddress = groupSmtpAddress.toLowerCase();
    const groupActionState = getGroupActionState(mailboxInfo);

    return groupActionState.get(groupSmtpAddress) ?? ActionState.None;
}

export function hasJoinGroupActionReturned(
    mailboxInfo: MailboxInfo,
    groupSmtpAddress: string
): boolean {
    const actionState = getJoinButtonState(mailboxInfo, groupSmtpAddress);
    return (
        actionState !== undefined &&
        (actionState === ActionState.Succeed || actionState === ActionState.FailureNotice)
    );
}
