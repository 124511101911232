import { useWindowEvent } from 'owa-react-hooks/lib/useWindowEvent';
import { lazyOnFocusedElementChanged, lazyOnKeyDownEvent } from 'owa-accessibility-loop-nav';

export function useNavigationManager() {
    useWindowEvent(
        'focusout',
        (event: FocusEvent) => {
            lazyOnFocusedElementChanged.importAndExecute(event);
        },
        []
    );
    useWindowEvent(
        'keydown',
        (event: KeyboardEvent) => {
            lazyOnKeyDownEvent.importAndExecute(event);
        },
        []
    );
}
