import { getUserConfiguration } from 'owa-session-store';
export type TopicsBitFlagsMasks = any;

/**
 * Without this value there wouldn't be a way for the users who modified the setting to have another setting enabled by default.
 * In this scenario we can use other last 7bits for a feature we want to be enabled by default and rest bits for feature that can be off initially.
 */
export const DEFAULT_ENABLE_LAST_8BITS = (1 << 9) - 1;

/**
 * Gets a boolean to indicate if the n th bit is 1
 * @param bitMask represents the nth bit
 * @return true if nth bit is 1, false if 0
 */
function isTopicsFeatureEnabled(topicsBitFlags: number, bitMask: TopicsBitFlagsMasks): boolean {
    return !!(bitMask & topicsBitFlags);
}

/**
 * Gets the value for TopicsBitFlags from OWA store
 * @returns TopicsBitFlags from the OWA store or default value
 */
export function getTopicsBitFlags(): number {
    const settingBitFlag = getUserConfiguration().UserOptions?.TopicsBitFlags;
    // Since zero could be a possible value, check specifically for null and undefined
    return settingBitFlag === null || settingBitFlag === undefined
        ? DEFAULT_ENABLE_LAST_8BITS
        : settingBitFlag;
}

/**
 * Change the TopicsBitFlags value after enabling/disabling the feature
 * @param topicsBitFlags Current TopicsBitFlags value
 * @param bitMask BitMask for the feature
 * @param isTopicFeatureEnabled Boolean to enable/disable
 * @returns New TopicsBitFlags value
 */
function setTopicsFeatureBit(
    topicsBitFlags: number,
    bitMask: TopicsBitFlagsMasks,
    isTopicFeatureEnabled: boolean
): number {
    if (isTopicFeatureEnabled) {
        topicsBitFlags |= bitMask;
    } else {
        topicsBitFlags &= ~bitMask;
    }
    return topicsBitFlags;
}

export function isEditorTopicsSuggestionsBitSet(): boolean {
    return isTopicsFeatureEnabled(getTopicsBitFlags(), 2);
}

export function setEditorTopicsSuggestionsBit(enable: boolean): number {
    return setTopicsFeatureBit(getTopicsBitFlags(), 2, enable);
}

export function isTopicHighlightsBitSet(): boolean {
    return isTopicsFeatureEnabled(getTopicsBitFlags(), 1);
}

export function setTopicHighlightsBit(enable: boolean): number {
    return setTopicsFeatureBit(getTopicsBitFlags(), 1, enable);
}
