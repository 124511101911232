import loc from 'owa-localize';
import { ActionControlSize } from '@1js/acui-common';
import { applyPredefinedStyles } from './applyPredefinedStyles';
import { constructKeytip } from 'owa-command-ribbon';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getMenuDefinitionForTableControls } from './getMenuDefinitionForTableControls';
import { owaComputedFn } from 'owa-computed-fn';
import { predefinedTableStyles } from './tableControlsStrings.locstring.json';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import {
    lazyMultiSectionFullGallerySections,
    lazyFirstLineOfGridTables,
} from 'owa-compose-tablecontrols-predefinedstyles';
import type { PredefinedTableStyles } from 'owa-compose-tablecontrols-predefinedstyles';
import type { AppIconGalleryButtonProps } from '@1js/acui-gallery-icon/lib/AppIconFullGalleryButton';
import type { AppGalleryStyles } from '@1js/acui-gallery';
import {
    galleryContainer,
    previewExpandButton,
    galleryButtonInPreview,
    galleryButtonInExpanded,
} from './tableIconStyles.scss';
import TableEditRegular from 'owa-fluent-icons-svg/lib/icons/TableEditRegular';

const previewStyles: Partial<AppGalleryStyles> = {
    galleryContainer: {
        root: galleryContainer,
    },
    previewExpandButton: {
        root: previewExpandButton,
    },
    galleryButtonInPreview: {
        root: galleryButtonInPreview,
    },
};

const expandedStyles = {
    galleryContainer: {
        root: {
            padding: '4px',
        },
    },
    galleryButtonInExpanded: {
        root: galleryButtonInExpanded,
    },
};

export const getTablePredefinedStyleIconMenu = owaComputedFn(
    (editorId: string, tabId: number | undefined): RibbonControlProps => {
        const firstLineOfGridTables = lazyFirstLineOfGridTables.tryImportForRender() ?? [];
        return {
            type: 'AppIconPreviewGalleryProps',
            id: getComposeRibbonId(6046, editorId).toString(),
            galleryButtons: firstLineOfGridTables,
            menuLauncherLabel: loc(predefinedTableStyles),
            onGalleryButtonExecute: (parameter: { galleryButton: AppIconGalleryButtonProps }) => {
                applyPredefinedStyles(
                    editorId,
                    parameter.galleryButton.id as PredefinedTableStyles
                );
            },
            onMenuButtonExecute: () => {},
            expandedOptions: getGalleryMenuDefinition(editorId),
            keytipProps: tabId ? constructKeytip([tabId], 6046) : undefined,
            size: ActionControlSize.Large,
            styles: previewStyles,
            flyoutAnchorProps: {
                icon: TableEditRegular,
                styles: {
                    label: {
                        marginLeft: '4px',
                    },
                },
            },
        };
    }
);

const getGalleryMenuDefinition = owaComputedFn((editorId: string) => {
    const multiSectionFullGallerySections =
        lazyMultiSectionFullGallerySections.tryImportForRender() ?? [];
    return getMenuDefinitionForTableControls([
        {
            type: 'AppIconFullGalleryProps',
            id: getComposeRibbonId(6070, editorId).toString(),
            columns: 7,
            gallerySections: multiSectionFullGallerySections,
            onGalleryButtonExecute: parameter => {
                applyPredefinedStyles(
                    editorId,
                    parameter.galleryButton.id as PredefinedTableStyles
                );
            },
            styles: expandedStyles,
            positionInSet: 1,
            setSize: multiSectionFullGallerySections.length + 1,
        },
    ]);
});
