import type {
    SearchFile,
    TeamsMessage,
    SearchPerson,
} from 'owa-search-service/lib/data/schema/SubstrateSearchResponse';
import type { SearchTab } from 'owa-search-types/lib/types/SearchTab';
import type { Refiner } from 'owa-search-types';

export default interface TabbedSearchStore {
    activeSearchTab?: SearchTab;
    activeScopeTab?: SearchTab;
    fileSearchResults: SearchFile[];
    teamsSearchResults: TeamsMessage[];
    peopleSearchResults: SearchPerson[];
    loadingState: TabbedSearchLoadingState;
    queryString: string;
    isLoadingMoreResults: boolean;
    isTeamsChatEnabled: boolean;
    shouldSuppressTabs: boolean; // Allows tabs to be turned off temporarily, e.g., for a Find Related Conversation search
    shouldShowRightPane: boolean;
    personId: string;
    searchTerms: string[];
    appliedRefiners: Refiner[];
}

export enum TabbedSearchLoadingState {
    NotStarted,
    InProgress,
    Complete,
    Error,
}
