import expandCollapsedItemsRollUp from './expandCollapsedItemsRollUp';
import initializeExtendedStateForItemViewState from 'owa-mail-reading-pane-store/lib/actions/initializeExtendedStateForItemViewState';
import loadMore from './loadMore';
import datapoints from 'owa-mail-reading-pane-store/lib/datapoints';
import getConversationReadingPaneViewState, {
    getCurrentRenderedConversationId,
} from '../utils/getConversationReadingPaneViewState';
import { hasCollapsedItemsRollUp } from '../utils/rollUp/collapsedItemsRollUpUtils';
import { wrapFunctionForDatapoint } from 'owa-analytics';
import setCoverOriginalContent from 'owa-mail-reading-pane-store/lib/actions/setCoverOriginalContent';
import { isExtendedCardCoveringOriginalContent } from 'owa-mail-reading-pane-store/lib/utils/extendedCardUtils';
import canConversationLoadMore from 'owa-mail-store/lib/utils/canConversationLoadMore';
import { mutatorAction } from 'satcheljs';
import type ItemPartViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemPartViewState';
import type { ClientItemId } from 'owa-client-ids';

/* eslint-disable-next-line owa-custom-rules/no-dynamic-event-names  -- (https://aka.ms/OWALintWiki)
 * Datapoint's event names can only be string literals (variables, string templates and other dynamic names are not accepted).
 *	> Datapoint's event names can only be a constant string defined in an a object as the first argument of the function call. */
export default wrapFunctionForDatapoint(
    datapoints.RPCountExpCollAllItemParts,
    function expandCollapseAllItemParts(
        conversationId: string,
        shouldExpand: boolean,
        _isFromShortcut: boolean
    ): Promise<void> {
        const conversationReadingPaneViewState =
            getConversationReadingPaneViewState(conversationId);
        const itemPartsMap = conversationReadingPaneViewState?.itemPartsMap;
        let promiseToReturn = Promise.resolve();

        // If the extended card is covering the original content, collapse it before expanding/collapsing all
        if (isExtendedCardCoveringOriginalContent(conversationReadingPaneViewState)) {
            setCoverOriginalContent(conversationReadingPaneViewState.extendedCardViewState, false);
        }

        // Expand the collapsed items roll up if it's existed.
        if (shouldExpand && hasCollapsedItemsRollUp(conversationReadingPaneViewState)) {
            expandCollapsedItemsRollUp(conversationReadingPaneViewState, true /*isAuto*/);
        }

        // Load more items from server if the conversation can load more
        if (
            shouldExpand &&
            canConversationLoadMore(conversationId || getCurrentRenderedConversationId())
        ) {
            promiseToReturn = loadMoreRecursively(conversationReadingPaneViewState.conversationId);
        }

        return promiseToReturn.then(() => {
            itemPartsMap?.forEach(itemPart => {
                // Initialize the extended state for item viewstate if expand a collapsed item.
                if (shouldExpand && !itemPart.isExpanded) {
                    initializeExtendedStateForItemViewState(itemPart);
                }

                setItemPartExpand(itemPart, shouldExpand);
            });
        });
    }
);

const loadMoreRecursively = (conversationClientItemId: ClientItemId): Promise<void> =>
    loadMore(conversationClientItemId).then(() => {
        if (canConversationLoadMore(conversationClientItemId.Id)) {
            return loadMoreRecursively(conversationClientItemId);
        } else {
            return Promise.resolve();
        }
    });

const setItemPartExpand = mutatorAction(
    'setItemPartExpand',
    (itemPart: ItemPartViewState, shouldExpand: boolean) => {
        itemPart.isExpanded = shouldExpand;
        itemPart.isFossilizedTextExpanded = shouldExpand;
        itemPart.oofRollUpViewState.isOofRollUpExpanded = shouldExpand;
        if (!itemPart.hasExpanded) {
            itemPart.hasExpanded = true;
        }
    }
);
