import { observer } from 'owa-mobx-react';
import { shouldUseAppHostSearch } from 'owa-app-host-search/lib/utils/shouldUseAppHostSearch';
import React from 'react';
import type { SearchBoxContainerHandle } from 'owa-search/lib/types/SearchBoxContainerHandle';
import SearchBox from 'owa-search/lib/components/SearchBox';
import is3SServiceAvailable from 'owa-search/lib/utils/is3SServiceAvailable';
import { SearchScenarioId } from 'owa-search-store';
import { isReadingPanePositionRight, getSearchBoxWidthFromListView } from 'owa-mail-layout';
import { getStore as getMailSearchStore } from '../store/store';
import { shouldShowInScopeSelector } from 'owa-mail-search-utils';
import { AdvancedSearchWrapper } from 'owa-mail-advanced-search';
import {
    isSearchBarDisabled,
    getSearchPlaceHolderText,
    getAriaLabelForSearchBox,
} from '../utils/mailSearchUtils';

export default observer(
    React.forwardRef(function MailSearchBoxContainer(
        _props: {},
        ref: React.Ref<SearchBoxContainerHandle>
    ) {
        const searchBox = React.useRef<SearchBox>();
        const [isHovered, setIsHovered] = React.useState<boolean>(false);
        const {
            shouldShowAdvancedSearch,
            advancedSearchViewState,
            initialSearchScope,
            staticSearchScope,
            staticSearchScopeList,
            selectedMailboxInfo,
        } = getMailSearchStore();
        /**
         * This computed represents if suggestions is disabled
         */
        const isSuggestionsCalloutDisabled = !shouldShowInScopeSelector(initialSearchScope);
        const isAdvancedSearchSupported = !shouldUseAppHostSearch();

        React.useImperativeHandle(
            ref,
            () => ({
                /**
                 * Publicly  exposed function that allows parent to set focus inside of the
                 * search input when search scope is empty on click of search scope item
                 */
                onSearchScopeSelected(searchScenarioId: SearchScenarioId) {
                    searchBox.current?.onSearchScopeSelected(searchScenarioId);
                },
                /**
                 * Publicly  exposed function that allows parent to set focus inside of the
                 * search input.
                 */
                setFocus() {
                    searchBox.current?.focusSearchInput();
                },
            }),
            []
        );
        /**
         * This function gets the max width of the search box.
         */
        const getCustomCollapsedMaxWidth = (): number | undefined => {
            /**
             * If the user is in 3 column view, the max width of the search box should
             * match the width of the message list. Otherwise, it's unbounded.
             */
            return isReadingPanePositionRight() ? getSearchBoxWidthFromListView() : undefined;
        };

        const onMouseLeaveCallback = React.useCallback(() => {
            setIsHovered(false);
        }, [isHovered]);

        const onMouseEnterCallback = React.useCallback(() => {
            setIsHovered(true);
        }, [isHovered]);

        const onHoverHandlers = React.useMemo(
            () => ({
                onMouseEnter: onMouseEnterCallback,
                onMouseLeave: onMouseLeaveCallback,
            }),
            []
        );

        /**
         * We always use 3S for suggestions if we're not
         * in explicit logon.
         */
        return (
            <SearchBox
                customCollapsedMaxWidth={getCustomCollapsedMaxWidth()}
                isUsing3S={is3SServiceAvailable()}
                // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                // -> Error TS2322 (118,17): Type 'MutableRefObject<SearchBox | undefined>' is not assignable to type 'LegacyRef<SearchBox> | undefined'.
                // @ts-expect-error
                ref={searchBox}
                renderRefiners={
                    isSuggestionsCalloutDisabled || !isAdvancedSearchSupported
                        ? undefined
                        : /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
                           * Baseline, please do not copy and paste this justification
                           *	> JSX attribute values should not contain functions created in the same scope */
                          () => (
                              <AdvancedSearchWrapper
                                  shouldShowAdvancedSearch={shouldShowAdvancedSearch}
                                  scenarioId={SearchScenarioId.Mail}
                                  advancedSearchViewState={advancedSearchViewState}
                                  initialSearchScope={initialSearchScope}
                                  staticSearchScope={staticSearchScope}
                                  staticSearchScopeList={staticSearchScopeList}
                                  mailboxInfo={selectedMailboxInfo}
                              />
                          )
                }
                searchPlaceHolderText={getSearchPlaceHolderText()}
                isDisabled={isSearchBarDisabled.get()}
                scenarioId={SearchScenarioId.Mail}
                isSuggestCalloutDisabled={isSuggestionsCalloutDisabled}
                onHoverHandlers={onHoverHandlers}
                isHovered={isHovered}
                ariaLabel={getAriaLabelForSearchBox.get()}
            />
        );
    }),
    'MailSearchBoxContainer'
);
