import { action } from 'satcheljs';
import type { MailboxInfo } from 'owa-client-types';
import type { ConditionalFormattingRule } from '../store/schema/ConditionalFormattingRule';

export const setConditionalFormattingRules = action(
    'setConditionalFormattingRules',
    (
        mailboxInfo: MailboxInfo,
        conditionalFormattingRules: ConditionalFormattingRule[],
        isFetch?: boolean
    ) => ({
        mailboxInfo,
        conditionalFormattingRules,
        isFetch,
    })
);
