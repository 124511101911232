import {
    GlobalReplyBar,
    ItemHeader,
    LoadFullBodyButton,
    MailMessageBody,
} from 'owa-mail-reading-pane-view';
import type { ItemReadingPaneProps } from 'owa-mail-reading-pane-view';
import renderSmartPillBlock from 'owa-mail-reading-pane-view/lib/utils/renderSmartPillBlock';
import {
    undoDarkMode as itemReadingPaneStyles_undoDarkMode,
    contentContainer,
    isItemAttachment as itemReadingPaneStyles_isItemAttachment,
    neutralPaletteBackground,
    messageBody,
    bottomPadding,
} from './ItemReadingPane.scss';
import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import { logUsage } from 'owa-analytics';
import WideContentHost from 'owa-controls-content-handler/lib/handlers/wideContentHandler/WideContentHost';
import { getIsDarkTheme } from 'owa-fabric-theme';
import getDisplayedMessageBody from 'owa-mail-reading-pane-store/lib/utils/getDisplayedMessageBody';
import ProjectionContext from 'owa-popout-v2/lib/context/ProjectionContext';
import { trace } from 'owa-trace';
import React from 'react';
import type BodyContentType from 'owa-service/lib/contract/BodyContentType';
import type ClientItem from 'owa-mail-store/lib/store/schema/ClientItem';
import mailStore from 'owa-mail-store/lib/store/Store';
import { isFeatureEnabled } from 'owa-feature-flags';
import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';
import { OwaFluentProvider } from 'owa-react-dom';
import { getTabIdFromTargetWindow } from 'owa-mail-reading-pane-store/lib/utils/getTabIdFromTargetWindow';
import { lazyLoadUpdateContentProtection } from 'owa-mail-pie';

const newLineRegex = /(?:\r\n|\r|\n)/g;
const newLineHtml = '<br />';

export interface SupportedItemReadingPaneLoadedContentProps extends ItemReadingPaneProps {
    forwardAllowed: boolean;
    copyAllowed: boolean;
    printAllowed: boolean;
    isGetItemManagerRequestSentForCurrentItem: boolean;
    isSingleLineListView?: boolean;
    maximizeScrollRegion?: boolean;
}

interface ItemReadingPaneLoadedContentProps extends SupportedItemReadingPaneLoadedContentProps {
    item: ClientItem;
}

export default observer(function SupportedItemReadingPaneLoadedContent(
    props: SupportedItemReadingPaneLoadedContentProps
) {
    const item = mailStore.items.get(props.itemId?.Id);
    if (!item) {
        // VSTS: 41235. If the item has been removed from the mailStore, return null to avoid component errors.
        trace.warn("SupportedItemReadingPaneLoadedContent can't find item");
        logUsage('SupportedReadingPaneNoItem');
        return null;
    }

    const itemReadingPaneProps = { ...props, item };

    return (
        <WideContentHost>
            <ItemReadingPaneLoadedContent {...itemReadingPaneProps}></ItemReadingPaneLoadedContent>
        </WideContentHost>
    );
},
'SupportedItemReadingPaneLoadedContent');

const ItemReadingPaneLoadedContent = observer(function ItemReadingPaneLoadedContent(
    props: ItemReadingPaneLoadedContentProps
) {
    const targetWindow = React.useContext(ProjectionContext);
    const tabId = getTabIdFromTargetWindow(targetWindow);

    React.useEffect(() => {
        if (isFeatureEnabled('mon-block-screencapture')) {
            lazyLoadUpdateContentProtection.importAndExecute(tabId, props.copyAllowed);
        }
    }, [props.copyAllowed, tabId]);

    const [showDeferredComponents, setShowDeferredComponents] = React.useState(false);
    const handleMessageBodyMount = React.useCallback(() => {
        setShowDeferredComponents(true);
    }, []);
    const { itemReadingPaneViewState, item } = props;
    const translatedBody: BodyContentType | null =
        isFeatureEnabled('rp-inlineTranslation-conversationViewOff') &&
        item.TranslationData?.isShowingTranslation
            ? { Value: item.TranslationData.translationText ?? '' }
            : null;
    const outboxId = folderNameToId('outbox', props.item.MailboxInfo);
    const isOutboxItem: boolean = item.ParentFolderId?.Id === outboxId;

    const displayedMessageBody = getDisplayedMessageBody(
        item.RightsManagementLicenseData,
        translatedBody || (isOutboxItem ? item.UniqueBody || item.Body : item.NormalizedBody)
    );
    const itemViewState = itemReadingPaneViewState.itemViewState;
    const darkModeClassName = itemViewState.undoDarkMode ? itemReadingPaneStyles_undoDarkMode : '';
    const isReadonly = itemReadingPaneViewState.isReadonly;
    // Replace new lines with <br /> for text body as new-lines will be ignored by 'dangerouslySetInnerHTML'
    const shouldReplaceNlWithBr =
        item?.NormalizedBody &&
        item.NormalizedBody.BodyType === 'Text' &&
        (displayedMessageBody == item.NormalizedBody.Value ||
            displayedMessageBody == item.TranslationData?.translationText);
    const isDarkModeEnabled = itemViewState.undoDarkMode ? false : getIsDarkTheme();

    const itemContent = (
        <div
            className={classnames(
                contentContainer,
                {
                    [itemReadingPaneStyles_isItemAttachment]: props.isItemAttachment,
                    [neutralPaletteBackground]: true,
                },
                darkModeClassName
            )}
        >
            <ItemHeader
                item={item}
                viewState={itemViewState}
                isPopout={props.isPopout}
                instrumentationContext={itemReadingPaneViewState.instrumentationContext}
                isItemAttachment={props.isItemAttachment}
                isReadonly={isReadonly}
                isLatestNonDraft={true}
                isGetItemManagerRequestSentForCurrentItem={
                    props.isGetItemManagerRequestSentForCurrentItem
                }
                isSingleLineListView={props.isSingleLineListView}
                maximizeScrollRegion={props.maximizeScrollRegion}
                hideDateTime={itemReadingPaneViewState.hideDateTime}
                treatAsNonDraft={itemReadingPaneViewState.treatAsNonDraft}
                isDarkMode={isDarkModeEnabled}
            />

            {!isReadonly &&
                !props.isItemAttachment &&
                renderSmartPillBlock(item, itemViewState, true /* showOnTop */)}
            <MailMessageBody
                className={messageBody}
                messageBody={
                    shouldReplaceNlWithBr ? nlToBr(displayedMessageBody) : displayedMessageBody
                }
                item={item}
                copyAllowed={props.copyAllowed}
                printAllowed={props.printAllowed}
                forwardAllowed={props.forwardAllowed}
                isLoading={itemViewState.loadingState.isLoading}
                undoDarkMode={itemViewState.undoDarkMode}
                actionableMessageCardInItemViewState={
                    itemViewState.actionableMessageCardInItemViewState
                }
                isPopout={props.isPopout}
                targetWindow={targetWindow}
                onMount={handleMessageBodyMount}
            />
            {showDeferredComponents && item.NormalizedBody?.IsTruncated && (
                <LoadFullBodyButton viewState={itemViewState} />
            )}
            {showDeferredComponents && !isReadonly && !isOutboxItem && (
                <GlobalReplyBar
                    item={item}
                    instrumentationContext={itemReadingPaneViewState.instrumentationContext}
                    targetWindow={targetWindow}
                />
            )}
            {/* This div is only required to add a bottom padding to the container. Adding it here
        instead of including in the container class prevents a layout shift when the empty container
        is populated with content */}
            {showDeferredComponents && <div className={bottomPadding}></div>}
        </div>
    );

    const wrappedItemContent = isFeatureEnabled('fwk-fluent-v9-mail-theme-override') ? (
        <OwaFluentProvider isDarkModeEnabled={isDarkModeEnabled}>{itemContent}</OwaFluentProvider>
    ) : (
        itemContent
    );

    return wrappedItemContent;
},
'ItemReadingPaneLoadedContent');

function nlToBr(displayedMessageBody: string) {
    return displayedMessageBody.replace(newLineRegex, newLineHtml);
}
