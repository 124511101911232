import { FluentButton } from 'owa-fluent-v9-shims';
import { Icon } from '@fluentui/react/lib/Icon';
import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import type { AriaProperties } from 'owa-accessibility';
import { AriaRoles, generateDomPropertiesForAria } from 'owa-accessibility';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list/lib/flights';
import { getDensityMode, getDensityModeString, getDensityModeCssClass } from 'owa-fabric-theme';
import { isFeatureEnabled } from 'owa-feature-flags';
import FolderRegular from 'owa-fluent-icons-svg/lib/icons/FolderRegular';
import { useKeydownHandlers } from 'owa-hotkeys';
import loc, { isCurrentCultureRightToLeft } from 'owa-localize';
import React from 'react';
import { getUITreeNodeDepth, getTreeNodesFarPadding } from '../utils/treeNodeUIUtils';
import { selectedFolderNodeScreenReaderOnlyText } from './TreeNode.locstring.json';
import { default as ChevronDown } from 'owa-fluent-icons-svg/lib/icons/ChevronDownRegular';
import { default as ChevronRight } from 'owa-fluent-icons-svg/lib/icons/ChevronRightRegular';
import { default as More } from 'owa-fluent-icons-svg/lib/icons/MoreHorizontalRegular';
import { logUsage } from 'owa-analytics';
import {
    full,
    medium,
    compact,
    iconsNext,
    rootIcon,
    icon,
    leftNavIconExtraPadding,
    folderWithChevron as styles_folderWithChevron,
    leftNavAllFoldersPadding,
    folderPadding,
    folderIcons,
    rootFolderAlignment,
    chevronFontSize,
    regularNodeChevronIcon,
    chevronWithFolderIcon,
    chevronPadding,
    chevronIcon as styles_chevronIcon,
    rootNode,
    regularNode,
    roundedCorners,
    densityNodeHeights,
    showAsHoverOnDroppedOver as styles_showAsHoverOnDroppedOver,
    showBorderOnDroppedOver,
    isBeingDragged as styles_isBeingDragged,
    customActionNode,
    overridenFont,
    selected,
    withContextMenuOpen,
    disabledCursor,
    isDisabled as styles_isDisabled,
    displayName as styles_displayName,
    densityFontSizes,
    allFolderIconsRootText,
    rightCharm as styles_rightCharm,
    hoverTreatmentPresent,
    hoverPadding,
    rightCharmHover as styles_rightCharmHover,
    rootNodeFont,
    chevronAnimation,
    collapse,
    ellipsesIcon,
    rtlStyles,
    ltrStyles,
    rootHover,
    ellipsesOnHover as styles_ellipsesOnHover,
    ellipsesHovered,
} from './TreeNode.scss';

export type ChevronProps = {
    isExpanded: boolean;
    onClick: (evt: React.MouseEvent<unknown> | KeyboardEvent) => void;
    iconButtonClassName?: string;
    iconClassName?: string;
    isAnimated?: boolean;
};

export interface TreeNodeProps {
    displayName: string;
    isRootNode: boolean;

    // Optional parameters
    chevronProps?: ChevronProps; // Chevron related props, if not passed, we will not show chevron
    customIcon?: string; // Render an icon for special tree nodes
    customIconClassName?: string;
    onRenderCustomIcon?: (customIconClassName: string, hideIcon?: boolean) => JSX.Element;
    depth?: number; // The indentation int the tree node structure, where 0 is the root
    isBeingDragged?: boolean;
    isCustomActionNode?: boolean; // A node that contains a custom action (e.g. "New folder" node that creates new folder)
    isDroppedOver?: boolean;
    isSelected?: boolean;
    isWithContextMenuOpen?: boolean;
    onClick?: React.MouseEventHandler<EventTarget>;
    onKeyDown?: React.KeyboardEventHandler<EventTarget>;
    onContextMenu?: React.MouseEventHandler<EventTarget>;
    onMouseEnter?: React.MouseEventHandler<EventTarget>;
    onMouseLeave?: React.MouseEventHandler<EventTarget>;
    onTouchStart?: React.TouchEventHandler<EventTarget>;
    onTouchEnd?: React.TouchEventHandler<EventTarget>;
    renderCustomTreeNodeDisplay?: (textClassName: string) => JSX.Element; // Method to render the main content (if not specified the tree node will render the default UI)
    renderRightCharm?: () => JSX.Element | null; // Method to render the right charm (if any)
    renderRightCharmHover?: () => JSX.Element | null; // Method to render the right charm on hover state (if any)
    isFavorited?: boolean;
    toggleFavorite?: () => void;
    showAsHoverOnDroppedOver?: boolean; // True if we want to show the hover style css when the tree node is dropped over
    isCursorDisabled?: boolean;
    setSize?: number;
    positionInSet?: number;
    isinSharedFolderTree?: boolean;
    ariaLabel?: string;
    isDisabled?: boolean;
    idUsedForTooltip?: string;
    elementRef?: React.RefObject<HTMLDivElement>;
    customTreeNodeTooltip?: string;
    hideIcon?: boolean;
    ellipsesOnHover?: boolean;
    source?: string;
}

export default observer(function TreeNode(props: TreeNodeProps) {
    const localRef = React.useRef<HTMLDivElement>(null);
    const divRef = props.elementRef ?? localRef;
    const isMultiAccountEnabled = isMonarchMultipleAccountsEnabled();
    const densityModeCssClass = getDensityModeCssClass(full, medium, compact);
    const chevronProps = props.chevronProps;
    const ellipsesOnHover = props.ellipsesOnHover;
    const [isHovered, setIsHovered] = React.useState(false);
    const [isEllipsesHovered, setIsEllipsesHovered] = React.useState(false);

    const renderCustomIcon = (customIconClassNames: string, hideIcon?: boolean) => {
        return (
            <Icon
                className={classnames(customIconClassNames, densityModeCssClass, iconsNext)}
                iconName={hideIcon ? undefined : props.customIcon || FolderRegular}
                id={
                    isFeatureEnabled('acct-multiacctcomingsoon')
                        ? props.idUsedForTooltip
                        : undefined
                }
            />
        );
    };

    const getContainerStyle = () => {
        // The first level of tree nodes must be inline with root in the UI
        const firstLevelPadding = 0;
        const hasDepthOrIsFavorited = !!depth || props.isFavorited;
        const uiTreeNodeDepth = hasDepthOrIsFavorited
            ? getUITreeNodeDepth(depth ?? 0, getDensityModeString(), props.hideIcon)
            : firstLevelPadding;
        const treePadding =
            uiTreeNodeDepth + (hasDepthOrIsFavorited ? firstLevelPadding : 0) + 'px';
        const farPadding = ellipsesOnHover ? undefined : getTreeNodesFarPadding();
        const isRTL = isCurrentCultureRightToLeft();
        const width = 'auto';
        const paddingRight = isRTL ? treePadding : farPadding;
        const paddingLeft = isRTL ? farPadding : treePadding;

        // Just add paddin so the hover of the tree node is the same width
        // independent on its depth
        return {
            paddingLeft,
            paddingRight,
            width,
        };
    };

    const renderCustomIconFunction = props.onRenderCustomIcon || renderCustomIcon;
    useKeydownHandlers(
        divRef,
        React.useCallback(
            () => [
                {
                    command: 'right',
                    handler: (evt: KeyboardEvent) => {
                        if (chevronProps?.onClick && !chevronProps.isExpanded) {
                            chevronProps.onClick(evt);
                        }
                    },
                },
                {
                    command: 'left',
                    handler: (evt: KeyboardEvent) => {
                        if (chevronProps?.onClick && chevronProps.isExpanded) {
                            chevronProps.onClick(evt);
                        }
                    },
                },
            ],
            [chevronProps?.onClick, chevronProps?.isExpanded]
        )
    );

    const renderIcon = () => {
        if (chevronProps) {
            return renderChevronIcon();
        }
        // If tree node has a chevron, always display it (even if there is also a custom icon).
        // Otherwise display a custom icon or nothing if no custom icon is passed in.
        const customIconClassNames = classnames(
            props.isRootNode && props.customIcon && rootIcon,
            icon,
            props.customIconClassName,
            (chevronProps || props.isRootNode) && densityModeCssClass,
            !props.isRootNode && leftNavIconExtraPadding
        );
        if (props.onRenderCustomIcon || props.customIcon) {
            return renderCustomIconFunction(customIconClassNames);
        }

        return renderInvisibleIcon();
    };

    const renderFolderIcon = () => {
        const chevronIcon = (chevronProps && renderChevronIcon()) || null;
        const folderWithChevron = !!chevronIcon;

        const customIconClassNames = classnames(
            icon,
            densityModeCssClass,
            isCustomActionNode && !props.customIcon && 'visibilityHidden',
            props.customIconClassName,
            leftNavAllFoldersPadding,
            folderWithChevron && styles_folderWithChevron,
            !folderWithChevron && isMultiAccountEnabled && folderPadding // When there is no chevron we want to use different spacing
        );

        const customIcon = renderCustomIconFunction(customIconClassNames, props.hideIcon);

        return (
            <div className={folderIcons}>
                {chevronIcon}
                {customIcon}
            </div>
        );
    };

    // creates an icon which is not visible to preserve the alignment and nested spacing of folders.
    const renderInvisibleIcon = () => {
        return (
            <Icon
                className={classnames(
                    icon,
                    'visibilityHidden',
                    isMultiAccountEnabled && isRootNode && rootFolderAlignment,
                    leftNavIconExtraPadding
                )}
            />
        );
    };

    const chevronOnClick = (evt: React.MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
        chevronProps?.onClick(evt);
    };

    const chevronAnimationClassNames = classnames(
        chevronAnimation,
        !chevronProps?.isExpanded && collapse
    );

    const chevronIconClassNames = classnames(
        icon,
        // We want the font size for icon to be picked up from the global css,
        // since we are rolling this out as a flight, we are keeping the overriden
        // css value for the case the flight is not enabled.
        chevronFontSize,
        densityModeCssClass,
        !props.isRootNode && regularNodeChevronIcon,
        leftNavIconExtraPadding,
        chevronProps?.iconClassName,
        chevronProps?.isAnimated ? chevronAnimationClassNames : 'flipForRtl'
    );

    const chevronIcon = chevronProps?.isAnimated
        ? ChevronDown // expansion is handled by CSS animation
        : chevronProps?.isExpanded
        ? ChevronDown
        : ChevronRight;

    const chevronIconProps = React.useMemo(
        () => ({
            iconName: chevronIcon,
            className: chevronIconClassNames,
            styles: {
                root: {
                    fontSize: '14px',
                },
            },
        }),
        [chevronIcon, chevronIconClassNames]
    );

    const renderChevronIcon = () => {
        // Make this button hidden to narrator/jaws since all the aria
        // attributes are set on the main content. If we dont do this, narrator reads the content twice.
        // This button is hidden from aria/tabs but its action can be performed from the main content as well
        // We cant make this an icon since there are consumers
        // that uses the click handler from the button different from the chevron
        const ariaProps: AriaProperties = {
            role: 'button',
            hidden: true,
        };

        const buttonClassName = !(isMultiAccountEnabled && props.isRootNode)
            ? classnames(
                  densityModeCssClass,
                  chevronWithFolderIcon,
                  isMultiAccountEnabled && chevronPadding,
                  styles_chevronIcon,
                  chevronProps?.iconButtonClassName
              )
            : classnames(styles_chevronIcon, chevronWithFolderIcon, chevronPadding);

        return (
            <FluentButton
                appearance="icon"
                data-is-focusable={false}
                tabIndex={-1}
                className={buttonClassName}
                iconProps={chevronIconProps}
                onClick={chevronOnClick}
                {...generateDomPropertiesForAria(ariaProps)}
            />
        );
    };

    const openContextMenu = (evt: React.MouseEvent<HTMLDivElement>, src: string) => {
        evt.stopPropagation();
        logUsage('FP_OpenContextMenu', { source: props.source, eventSource: src });
        onContextMenu?.(evt);
    };

    const ellipsesContextMenu = React.useCallback(
        (evt: React.MouseEvent<HTMLDivElement>) => {
            openContextMenu(evt, 'Ellipses');
        },
        [openContextMenu]
    );

    const rightClickContextMenu = React.useCallback(
        (evt: React.MouseEvent<HTMLDivElement>) => {
            openContextMenu(evt, 'Right Click');
        },
        [openContextMenu]
    );

    const density = getDensityMode();
    enum ellipsisFontSize {
        Compact = 16,
        Full = 20,
        Simple = 18,
    }
    const ellipsesIconProps = {
        iconName: More,
        styles: {
            root: {
                fontSize: ellipsisFontSize[density] + 'px',
                paddingBottom: '2px',
            },
        },
    };

    const handleMouseEnterEllipses = React.useCallback(() => {
        setIsEllipsesHovered(true);
    }, [setIsEllipsesHovered]);

    const handleMouseLeaveEllipses = React.useCallback(() => {
        setIsEllipsesHovered(false);
    }, [setIsEllipsesHovered]);

    const renderEllipsesIcon = (isRootNode: boolean) => {
        // Make this button hidden to narrator/jaws since all the aria
        // attributes are set on the main content. If we dont do this, narrator reads the content twice.
        // This button is hidden from aria/tabs but its action can be performed from the main content as well
        // We cant make this an icon since there are consumers
        // that uses the click handler from the button different from the chevron
        const ariaProps: AriaProperties = {
            role: 'button',
            hidden: true,
        };

        const classes = classnames(ellipsesIcon, isRootNode && rootHover, densityModeCssClass);

        return (
            <FluentButton
                appearance="icon"
                data-is-focusable={false}
                tabIndex={-1}
                className={classes}
                iconProps={ellipsesIconProps}
                onClick={ellipsesContextMenu}
                onMouseEnter={handleMouseEnterEllipses}
                onMouseLeave={handleMouseLeaveEllipses}
                {...generateDomPropertiesForAria(ariaProps)}
            />
        );
    };

    const renderDefaultTreeNodeDisplay = (className: string): JSX.Element => {
        return (
            <>
                <span className={className}>{props.displayName}</span>
                {props.isSelected && (
                    <span className="screenReaderOnly">
                        {loc(selectedFolderNodeScreenReaderOnlyText)}
                    </span>
                )}
            </>
        );
    };

    const {
        isCustomActionNode,
        isDroppedOver,
        isSelected,
        showAsHoverOnDroppedOver,
        isBeingDragged,
        isWithContextMenuOpen,
        isCursorDisabled,
        isRootNode,
        depth,
        onClick,
        onKeyDown,
        onContextMenu,
        onMouseEnter,
        onMouseLeave,
        onTouchStart,
        onTouchEnd,
        displayName,
        renderCustomTreeNodeDisplay,
        renderRightCharm,
        renderRightCharmHover,
        isinSharedFolderTree,
        isDisabled,
        customTreeNodeTooltip,
    } = props;
    const treeNodeClasses = classnames(
        isRootNode ? rootNode : regularNode,
        densityModeCssClass,
        roundedCorners,
        densityNodeHeights,
        ellipsesOnHover && isCurrentCultureRightToLeft() ? rtlStyles : ltrStyles,

        {
            [styles_showAsHoverOnDroppedOver]: isDroppedOver && showAsHoverOnDroppedOver,
            [showBorderOnDroppedOver]: isDroppedOver && !showAsHoverOnDroppedOver,
            [styles_isBeingDragged]: isBeingDragged,
            [customActionNode]: overridenFont && isCustomActionNode,
            [selected]: isSelected,
            [withContextMenuOpen]: isWithContextMenuOpen,
            [disabledCursor]: isCursorDisabled,
            [styles_isDisabled]: !!isDisabled,
            [ellipsesHovered]: isEllipsesHovered,
        }
    );
    const textClassNames = classnames(
        styles_displayName,
        densityFontSizes,
        densityModeCssClass,
        {
            [selected]: isSelected,
        },
        isRootNode && allFolderIconsRootText,
        isRootNode && rootNodeFont
    );
    const ariaProps: AriaProperties = {
        role: AriaRoles.treeitem,
        expanded: chevronProps ? chevronProps.isExpanded : undefined,
        selected: !isRootNode ? isSelected : undefined,
        // temporary fix for OW:15414 because it is currently possible for depth to be null causing aria-level to be NaN
        // aria-level is an integer equal to or greater than 1 where 1 is the root
        level: depth == null ? (isRootNode ? 1 : 2) : depth + 1,
        setSize: props.setSize,
        positionInSet: props.positionInSet,
        label: props.ariaLabel,
    };

    /**
     * Hover treatment is optional and hence the CSS classes to hide the rest treatment with onHover treatment
     * should only be applied if hover treatment is specified.
     */
    const rightCharmClassName = classnames(
        styles_rightCharm,
        isMultiAccountEnabled &&
            !isinSharedFolderTree &&
            renderRightCharmHover &&
            hoverTreatmentPresent,
        isMultiAccountEnabled && hoverPadding,
        ellipsesOnHover && hoverTreatmentPresent
    );

    const ellipseClassName = classnames(
        styles_ellipsesOnHover,
        ellipsesOnHover && hoverTreatmentPresent
    );

    const handleMouseEnter = React.useCallback(
        (event: React.MouseEvent<EventTarget, MouseEvent>) => {
            if (ellipsesOnHover) {
                setIsHovered(true);
            }
            if (onMouseEnter) {
                onMouseEnter(event);
            }
        },
        [setIsHovered, onMouseEnter]
    );

    const handleMouseLeave = React.useCallback(
        (event: React.MouseEvent<EventTarget, MouseEvent>) => {
            if (ellipsesOnHover) {
                setIsHovered(false);
            }
            if (onMouseLeave) {
                onMouseLeave(event);
            }
        },
        [setIsHovered, onMouseLeave]
    );

    // decides which icon to render on the left of the display name
    const getIconLeftOfFolderName = () => {
        // We want to give all folders a folder Icon, except the root folders.
        if (!isRootNode) {
            return renderFolderIcon();
        }

        return renderIcon();
    };

    const treeNodeDisplay = renderCustomTreeNodeDisplay
        ? renderCustomTreeNodeDisplay(textClassNames)
        : renderDefaultTreeNodeDisplay(textClassNames);
    const rightCharm = renderRightCharm?.();
    const rightCharmHover = renderRightCharmHover?.();

    return (
        <div
            ref={divRef}
            style={getContainerStyle()}
            onClick={onClick}
            onKeyDown={onKeyDown}
            onContextMenu={rightClickContextMenu}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onTouchStart={onTouchStart}
            onTouchEnd={onTouchEnd}
            className={treeNodeClasses}
            data-is-focusable={true}
            title={customTreeNodeTooltip ?? displayName}
            tabIndex={-1}
            {...generateDomPropertiesForAria(ariaProps)}
        >
            {getIconLeftOfFolderName()}
            {treeNodeDisplay}
            {rightCharm && <span className={rightCharmClassName}>{rightCharm}</span>}
            {rightCharmHover && <span className={styles_rightCharmHover}>{rightCharmHover}</span>}
            {isHovered && (
                <span className={ellipseClassName}>{renderEllipsesIcon(isRootNode)}</span>
            )}
        </div>
    );
}, 'TreeNode');
