import { createLazyComponent, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "FavoriteContextMenu" */ './lazyIndex')
);

// Delay loaded components
export const FavoriteNodeContextMenu = createLazyComponent(
    lazyModule,
    m => m.FavoriteNodeContextMenu
);

export { default as FavoritesTree, getFavoritesNodeCount } from './components/FavoritesTree';
