import type { IButtonStyles } from '@fluentui/react';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppButton } from 'owa-command-ribbon';
import { setDirectionCommand } from 'owa-editor-command';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import TextDirectionHorizontalRightRegular from 'owa-fluent-icons-svg/lib/icons/TextDirectionHorizontalRightRegular';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { type Direction } from 'roosterjs-editor-types/lib/enum/Direction';
import { dirLtrText } from './formatControlStrings.locstring.json';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';

export const getLeftToRightControl = owaComputedFn(
    (
        editorId: string,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean,
        source: FormattingSource
    ): RibbonControlProps => {
        return createAppButton(
            getComposeRibbonId(6020, editorId),
            loc(dirLtrText),
            getComputedCallback(6020, setDirectionCommand, editorId, 0, source),
            tabId ? constructKeytip([tabId], 6020) : undefined,
            TextDirectionHorizontalRightRegular,
            undefined /* iconColor */,
            styles,
            {
                disabled: isDisabled,
            }
        );
    }
);
