import { mutatorAction } from 'satcheljs';
import threadSummarizationStore from '../store/store';
import { createSummaryExpansionKey } from '../utils/typeGuards';

export default mutatorAction(
    'setSummaryCardDisplayStatus',
    function setSummaryCardDisplayStatus(id: string, isExpanded: boolean, isPopout: boolean) {
        const key = createSummaryExpansionKey(id, isPopout);
        threadSummarizationStore.isCurrentSummaryExpanded.set(key, isExpanded);
    }
);
