import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
/**
 * If certain controls are set to receive focus on click, they will be surrounded by a block border which does not match the default button style. As a result, this border needs to be removed.
 * @param styles are the styles added to the button. Inside of them we delete the block border
 * @returns new styles object withouth a border on focus state.
 */
export const getNonBorderFocusedStyle = owaComputedFn(
    (styles: IButtonStyles | undefined): IButtonStyles => {
        return {
            ...styles,
            rootFocused: {
                '::after': {
                    borderStyle: 'none',
                },
            },
        };
    }
);
