// extracted by mini-css-extract-plugin
export var card = "Yd98x";
export var compact = "hae5v";
export var copilotIconCompact = "KknAq";
export var copilotIconFull = "FiyJM";
export var copilotIconMedium = "lkKcB";
export var full = "NX81r";
export var headerContainer = "SdovJ";
export var isFocused = "iSmug";
export var labelForSummarizeButton = "w55aM";
export var leftAlignedSummaryButtonSubjectHeader = "e8YIX";
export var medium = "HkV9z";
export var undoDarkModeFocusedBorder = "C_b_a";