import { LoadingStage, ErrorReason } from '../selectors/getSpListViewState';
import { PerformanceDatapoint, DatapointStatus } from 'owa-analytics';
import { getGroupMailboxExternalId } from 'owa-groups-shared-store';
import type { MailboxInfo } from 'owa-client-types';

let perfDatapoint: PerformanceDatapoint | null = null;
let lastGroup: string | null = null;

// undefined indicates not finished
let isRecentsSuccess: boolean | undefined = undefined;
let isSpSuccess: boolean | undefined = undefined;

export const LogSpAbsent = (): void => {
    if (perfDatapoint != null) {
        perfDatapoint.addCheckmark('No SP site');
        isSpSuccess = true;
        perfDatapoint.addCustomData({ SPStatus: 'NoSPSite' });
        EndPerfDataPointWhenFilesHubLoaded();
    }
};

export const LogSpFilesSuccess = (): void => {
    if (perfDatapoint != null) {
        perfDatapoint.addCheckmark('SP completed');
        isSpSuccess = true;
        perfDatapoint.addCustomData({ SPStatus: 'success' });
        EndPerfDataPointWhenFilesHubLoaded();
    }
};

export const LogSpFilesTokenTransientFailure = (): void => {
    if (perfDatapoint != null) {
        perfDatapoint.addCheckmark('SP completed');

        isSpSuccess = true;
        perfDatapoint.addCustomData({ SPError: 'TokenTransientError' });
        perfDatapoint.addCustomData({ SPStatus: 'UnsureError' });

        EndPerfDataPointWhenFilesHubLoaded();
    }
};

export const LogGroupDetailsComplete = (mailboxInfo: MailboxInfo, smtpAddress: string): void => {
    const groupExternalId = perfDatapoint?.getCustomData('groupExternalId');
    if (perfDatapoint && !groupExternalId) {
        perfDatapoint.addCustomProperty(
            'groupExternalId',
            getGroupMailboxExternalId(mailboxInfo, smtpAddress)
        );
    }
};

export const LogSpMissingSMTP = (): void => {
    if (perfDatapoint != null) {
        perfDatapoint.addCheckmark('SP SMTP Missing');
    }
};

export const LogSpMissingGroup = (): void => {
    if (perfDatapoint != null) {
        perfDatapoint.addCheckmark('SP Group Missing');
    }
};

export const LogSpFilesFailure = (errorReason: ErrorReason): void => {
    if (perfDatapoint != null) {
        perfDatapoint.addCheckmark('SP completed');

        isSpSuccess = false;
        if (typeof errorReason === 'number' || typeof errorReason === 'string') {
            perfDatapoint.addCustomData({ SPError: ErrorReason[errorReason] });
        }

        perfDatapoint.addCustomData({ SPStatus: 'error' });

        EndPerfDataPointWhenFilesHubLoaded();
    }
};

export const LogSpLoadingStatus = (loadingStage: LoadingStage): void => {
    if (perfDatapoint != null) {
        perfDatapoint.addCheckmark(LoadingStage[loadingStage]);
    }
};

export const EndFilesHubLoadListDataPointWhenNotComplete = (reason: string): void => {
    if (perfDatapoint != null) {
        perfDatapoint.endWithError(
            DatapointStatus.RequestNotComplete,
            /* eslint-disable-next-line owa-custom-rules/no-error-dynamic-event-names -- (https://aka.ms/OWALintWiki)
             * Error constructor names can only be a string literals.
             *	> Error constructor names can only be a string literals. Use the diagnosticInfo to add custom data. */
            reason != undefined && reason != null ? new Error(reason) : undefined
        );
        perfDatapoint = null;
        lastGroup = null;
        isRecentsSuccess = undefined;
        isSpSuccess = undefined;
    }
};

export const StartFilesHubListLoadDatapoint = (smtpAddress: string): void => {
    if (perfDatapoint == null) {
        perfDatapoint = createDatapoint();
        lastGroup = smtpAddress;
    } else if (lastGroup != null && lastGroup.toLowerCase() != smtpAddress.toLowerCase()) {
        perfDatapoint.endWithError(DatapointStatus.RequestNotComplete, new Error('group changed'));

        // start new dataPoint as the previous one was for a different group
        perfDatapoint = createDatapoint();
        lastGroup = smtpAddress;
        isRecentsSuccess = undefined;
        isSpSuccess = undefined;
    }
};

function createDatapoint() {
    const datapoint = new PerformanceDatapoint('GroupFilesHubListsLoad', {
        logVerbose: true,
        timeout: 300000,
    });

    return datapoint;
}

const EndPerfDataPointWhenFilesHubLoaded = (): void => {
    // only if both SP and recents are completed
    if (perfDatapoint && isSpSuccess != undefined && isRecentsSuccess != undefined) {
        if (isSpSuccess == true && isRecentsSuccess == true) {
            perfDatapoint.end();
            perfDatapoint = null;
        } else {
            perfDatapoint.endWithError(DatapointStatus.ClientError);
            perfDatapoint = null;
        }
    }
};
