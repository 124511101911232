import isNewestOnBottom from 'owa-mail-store/lib/utils/isNewestOnBottom';
import React from 'react';

export function renderConversationReadingPane(
    readingPane: (JSX.Element | null)[] | null,
    inlineCompose: JSX.Element | null,
    rsvpWithNote: JSX.Element | null,
    seeMoreMessages: JSX.Element | null
) {
    const newestOnTopItemPart = (
        <>
            {rsvpWithNote}
            {inlineCompose}
            {readingPane}
            {seeMoreMessages}
        </>
    );

    const newestOnBottomItemPart = (
        <>
            {seeMoreMessages}
            {readingPane}
            {inlineCompose}
            {rsvpWithNote}
        </>
    );

    return renderChooser(newestOnTopItemPart, newestOnBottomItemPart);
}

export function renderComponentsBySortOrder(components: (JSX.Element | null)[]): JSX.Element {
    return isNewestOnBottom() ? <>{components}</> : <>{components.reverse()}</>;
}

export function renderItemPart(
    expandedItemPart: JSX.Element,
    collapsedItemPart: JSX.Element,
    fossilizedText: JSX.Element,
    rollUp: JSX.Element | null,
    isCollapsed: boolean,
    hasExpanded: boolean
): JSX.Element {
    const newestOnTopCollapsedItemPart = (
        <>
            {rollUp}
            {hasExpanded && <div hidden={isCollapsed}>{expandedItemPart}</div>}
            {isCollapsed && collapsedItemPart}
            {!isCollapsed && fossilizedText}
        </>
    );
    const newestOnBottomCollapsedItemPart = (
        <>
            {fossilizedText}
            {hasExpanded && <div hidden={isCollapsed}>{expandedItemPart}</div>}
            {isCollapsed && collapsedItemPart}
            {rollUp}
        </>
    );

    return renderChooser(newestOnTopCollapsedItemPart, newestOnBottomCollapsedItemPart);
}

export function renderFossilizedText(
    newestOnTopFossilizedText: JSX.Element,
    newestOnBottomFossilizedText: JSX.Element
): JSX.Element {
    return renderChooser(newestOnTopFossilizedText, newestOnBottomFossilizedText);
}

function renderChooser(
    newestOnTopElement: JSX.Element,
    newestOnBottomElement: JSX.Element
): JSX.Element {
    return isNewestOnBottom() ? newestOnBottomElement : newestOnTopElement;
}
