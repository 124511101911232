import { emailButtonLabel } from 'owa-locstrings/lib/strings/emailbuttonlabel.locstring.json';
import { personaHeaderViewProfilebutton } from './getMailListHeader.locstring.json';
import {
    searchResults,
    searchResultsFromArchive,
} from 'owa-locstrings/lib/strings/searchresults.locstring.json';
import loc from 'owa-localize';
import React from 'react';
import folderNameToId from 'owa-session-store/lib/utils/folderNameToId';
import {
    PRIMARY_DUMPSTER_DISTINGUISHED_ID,
    ARCHIVE_DUMPSTER_DISTINGUISHED_ID,
    PRIMARY_DELETED_ITEMS_DISTINGUISHED_ID,
    ARCHIVE_DELETED_ITEMS_DISTINGUISHED_ID,
} from 'owa-folders-constants';
import { lazyInitializeThirdRow } from 'owa-mail-list-view';
import MailListFolderHeaderSecondRowContent from 'owa-mail-list-view/lib/components/listHeaders/MailListFolderHeaderSecondRowContent';
import MailListHeader from 'owa-mail-list-view/lib/components/MailListHeader';
import MailListHeaderSecondRowTextContent from 'owa-mail-list-view/lib/components/listHeaders/MailListHeaderSecondRowTextContent';
import MailListHeaderSelectionRowContent from 'owa-mail-list-view/lib/components/listHeaders/MailListHeaderSelectionRowContent';
import type { SearchTableQuery } from 'owa-mail-list-search';
import { BulkActionProgressBar } from 'owa-mail-bulk-action-view';
import { getSelectedNode } from 'owa-mail-folder-forest-store';
import { isFeatureEnabled } from 'owa-feature-flags';
import { MailListCategoryHeaderSecondRowContent } from 'owa-mail-category-view';
import SearchScenarioId from 'owa-search-store/lib/store/schema/SearchScenarioId';
import type { HeaderButtonData } from 'owa-people-persona-header-view';
import { PersonaHeader } from 'owa-people-persona-header-view';
import type { TableView, GroupFolderTableQuery } from 'owa-mail-list-store';
import {
    getViewFilterForTable,
    getStore as getListViewStore,
    type TableQueryType,
} from 'owa-mail-list-store';
import { SearchHeaderFirstRowContent } from 'owa-mail-search';
import { GroupSearchHeaderFirstRowContent } from 'owa-groups-search-view';
import { RefinersContainer } from 'owa-search-refiners';
import shouldShowModifiedQueryInformationalView from 'owa-mail-search/lib/utils/shouldShowModifiedQueryInformationalView';
import { type SearchHeaderType } from 'owa-mail-search/lib/store/schema/SearchHeaderType';
import { getHeaderPersonaData } from '../selectors/getHeaderPersonaData';
import shouldShowCategoryHeader from '../selectors/shouldShowCategoryHeader';
import type { ActionSource } from 'owa-mail-store';
import isFolderUnderArchiveRoot from 'owa-mail-store/lib/utils/isFolderUnderArchiveRoot';
import { type FolderForestNodeType } from 'owa-favorites-types';
import folderStore, { getEffectiveFolderDisplayName } from 'owa-folders';
import { SearchScopeKind } from 'owa-search-service/lib/data/schema/SearchScope';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - Migrating deprecated icons: https://outlookweb.visualstudio.com/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9297/Migrating-deprecated-icons-in-client-web
 *	> 'ControlIcons' import from owa-control-icons is restricted. Use JSX icons from @fluentui/react-icons, otherwise from owa-fluent-icons-svg if they can only be reference by string. */
import { type ControlIcons } from 'owa-control-icons';
import { default as MailRegular } from 'owa-fluent-icons-svg/lib/icons/MailRegular';
import { logUsage } from 'owa-analytics';
import { lazyNewMessage } from 'owa-mail-message-actions';
import { GroupHeader } from 'owa-group-header-view';
import { ErrorBoundary, ErrorComponentType } from 'owa-error-boundary';
import { isBulkActionValid } from 'owa-bulk-action-store';
import { isReadingPanePositionOff } from 'owa-mail-layout';
import { TabbedSearchPivot, isTabbedSearchEnabled } from 'owa-tabbed-search';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import { owaComputedFn } from 'owa-computed-fn';
import type { UserSettingsStyleSelector } from 'owa-mail-list-view/lib/utils/getStyleSelectorAsPerUserSettings';
import {
    shouldShowSenderGroupingsHeader,
    SenderGroupingsHeader,
} from 'owa-sender-groupings-header';

/**
 * Gets the appropriate mail list header based on the tableView properties
 * @param tableViewId tableView id
 * @param styleSelectorAsPerUserSettings css styles for the component when density flight is enabled
 * @return returns a header component
 */
const getMailListHeader = owaComputedFn(
    (
        tableViewId: string,
        styleSelectorAsPerUserSettings: UserSettingsStyleSelector | undefined
    ): JSX.Element => {
        const tableView = getListViewStore().tableViews.get(tableViewId);
        if (tableView == undefined) {
            return <></>;
        }
        const mailboxInfo = getMailboxInfo(tableView);
        const selectedNode = getSelectedNode();
        const isSearchTable = tableView.tableQuery.type == 1;
        const shouldShowSearchHeader = isSearchTable || selectedNode.type == 3;
        const currentSelectedFilter = getViewFilterForTable(tableView);

        if (
            tableView.tableQuery.type == 2 ||
            (isFeatureEnabled('grp-loadFolders') && tableView.tableQuery.type == 3)
        ) {
            const isFolderTableQuery = tableView.tableQuery.type === 3;
            const folderTableQuery = tableView.tableQuery as GroupFolderTableQuery;
            return (
                <GroupHeader
                    groupId={
                        isFolderTableQuery ? folderTableQuery.groupId : folderTableQuery.folderId
                    }
                    folderId={isFolderTableQuery ? folderTableQuery.folderId : undefined}
                    tableViewId={tableViewId}
                    styleSelectorAsPerUserSettings={styleSelectorAsPerUserSettings}
                />
            );
        }

        // When reading pane is hidden the selection related information needs to be shown
        // in selection header on top of list view
        const shouldShowSelectionHeader =
            isReadingPanePositionOff() &&
            ((tableView.isInCheckedMode && tableView.selectedRowKeys.size > 0) ||
                tableView.isInVirtualSelectAllMode);

        if (selectedNode.type === 1 && !isSearchTable) {
            const headerPersona = getHeaderPersonaData(isSearchTable, tableViewId, selectedNode);

            // VSO 23726 - Refactor Persona header to use MailListHeader and only have custom content defined as components
            return !!headerPersona.displayName && !!headerPersona.email ? (
                <ErrorBoundary
                    type={
                        isFeatureEnabled('fwk-devTools')
                            ? ErrorComponentType.Full
                            : ErrorComponentType.None
                    }
                >
                    <PersonaHeader
                        isInSearch={shouldShowSearchHeader}
                        hideFilter={currentSelectedFilter == null}
                        personaId={headerPersona.personaId}
                        personEmail={headerPersona.email}
                        personName={headerPersona.displayName}
                        favoriteActionSource={headerPersona.origin}
                        mailboxType={headerPersona.mailboxType}
                        headerButtonData={getActionButtonData(
                            headerPersona.email,
                            headerPersona.displayName
                        )}
                        headerActionsStylesAsPerUserSettings={styleSelectorAsPerUserSettings}
                    />
                </ErrorBoundary>
            ) : (
                <></>
            );
        }

        if (shouldShowSenderGroupingsHeader(tableView)) {
            const headerPersona = getHeaderPersonaData(isSearchTable, tableViewId, selectedNode);
            return (
                <ErrorBoundary
                    type={
                        isFeatureEnabled('fwk-devTools')
                            ? ErrorComponentType.Full
                            : ErrorComponentType.None
                    }
                >
                    <SenderGroupingsHeader
                        senderEmail={headerPersona.email}
                        senderName={headerPersona.displayName}
                        mailboxType={headerPersona.mailboxType}
                        headerActionsStylesAsPerUserSettings={styleSelectorAsPerUserSettings}
                    />
                </ErrorBoundary>
            );
        }

        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (199,9): Type 'null' is not assignable to type '(mailListFirstRowCssClass: string) => any'.
        // @ts-expect-error
        let renderFirstRow: (mailListFirstRowCssClass: string) => any = null;
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (203,9): Type 'null' is not assignable to type '(containerCssClass: string) => any'.
        // @ts-expect-error
        let renderSecondRowContent: (containerCssClass: string) => any = null;
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (207,9): Type 'null' is not assignable to type '(mailListThirdRowCssClass: string) => any'.
        // @ts-expect-error
        let renderThirdRow: (mailListThirdRowCssClass: string) => any = null;
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (211,9): Type 'null' is not assignable to type '() => any'.
        // @ts-expect-error
        let renderBulkActionProgressBar: () => any = null;

        let shouldShowFilterMenu: boolean = true;
        const filterMenuSource: ActionSource = 'MailFilterMenu';

        // We pass categoryHeaderData so that we do not run the same logic twice
        // first to determine if the header should be shown and next to determine the name of the category
        const categoryHeaderData = { categoryName: null };
        if (tableView && shouldShowCategoryHeader(tableView, categoryHeaderData)) {
            shouldShowFilterMenu = false;
            if (shouldShowSearchHeader) {
                const searchTableQuery = tableView.tableQuery as SearchTableQuery;
                renderFirstRow = (mailListFirstRowCssClass: string) => {
                    if (searchTableQuery.searchScenarioId === SearchScenarioId.GroupsMail) {
                        return React.createElement(GroupSearchHeaderFirstRowContent, {
                            className: mailListFirstRowCssClass,
                        });
                    } else {
                        return React.createElement(SearchHeaderFirstRowContent, {
                            className: mailListFirstRowCssClass,
                            showModifiedQueryInformation:
                                shouldShowModifiedQueryInformationalView(3),
                        });
                    }
                };
            }

            const categoryName = categoryHeaderData.categoryName;
            if (shouldShowSelectionHeader) {
                renderSecondRowContent = getSelectionHeader(tableView);
            } else {
                renderSecondRowContent = (containerCssClass: string) => {
                    const searchTableQuery = tableView.tableQuery as SearchTableQuery;
                    const searchScope = searchTableQuery.searchScope;
                    const searchResultHeaderText = shouldShowSearchHeader
                        ? searchScope?.kind == SearchScopeKind.ArchiveMailbox
                            ? loc(searchResultsFromArchive)
                            : loc(searchResults)
                        : undefined;
                    return (
                        <>
                            {categoryName && (
                                <MailListCategoryHeaderSecondRowContent
                                    key={categoryName}
                                    categoryName={categoryName}
                                    containerCssClass={containerCssClass}
                                />
                            )}
                            <MailListHeaderSecondRowTextContent
                                text={searchResultHeaderText}
                                containerCssClass={containerCssClass}
                                styleSelectorAsPerUserSettings={styleSelectorAsPerUserSettings}
                            />
                            {shouldShowSearchHeader && (
                                <RefinersContainer scenarioId={searchTableQuery.searchScenarioId} />
                            )}
                        </>
                    );
                };
            }
        } else if (shouldShowSearchHeader) {
            const searchTableQuery = tableView.tableQuery as SearchTableQuery;
            shouldShowFilterMenu = false; // Search has its own refiners, so filters are not shown

            renderFirstRow = (mailListFirstRowCssClass: string) => {
                if (searchTableQuery.searchScenarioId === SearchScenarioId.GroupsMail) {
                    return React.createElement(GroupSearchHeaderFirstRowContent, {
                        className: mailListFirstRowCssClass,
                    });
                } else {
                    return React.createElement(SearchHeaderFirstRowContent, {
                        className: mailListFirstRowCssClass,
                    });
                }
            };

            if (shouldShowSelectionHeader) {
                if (tableView) {
                    renderSecondRowContent = getSelectionHeader(tableView);
                }
            } else {
                renderSecondRowContent = (containerCssClass: string) => {
                    const searchScope = searchTableQuery.searchScope;
                    const searchResultHeaderText =
                        searchScope?.kind == SearchScopeKind.ArchiveMailbox
                            ? loc(searchResultsFromArchive)
                            : loc(searchResults);
                    return (
                        <>
                            {isTabbedSearchEnabled(false /*isFromQfScopeTabs*/) &&
                            searchScope?.kind === SearchScopeKind.PrimaryMailbox ? (
                                <TabbedSearchPivot
                                    mailListHeaderStylesAsPerUserSettings={
                                        styleSelectorAsPerUserSettings
                                    }
                                />
                            ) : (
                                <MailListHeaderSecondRowTextContent
                                    text={searchResultHeaderText}
                                    containerCssClass={containerCssClass}
                                    styleSelectorAsPerUserSettings={styleSelectorAsPerUserSettings}
                                />
                            )}
                            <RefinersContainer scenarioId={searchTableQuery.searchScenarioId} />
                        </>
                    );
                };
            }
        } else {
            const folderId = tableView.tableQuery.folderId;
            let dumpsterFolderDistinguishedId: string;
            let dumpsterFolderId: string;
            let deletedItemsFolderDistinguishedId: string;
            let treeType: string;
            if (isFolderUnderArchiveRoot(folderId)) {
                dumpsterFolderDistinguishedId = ARCHIVE_DUMPSTER_DISTINGUISHED_ID;
                dumpsterFolderId = folderNameToId(ARCHIVE_DUMPSTER_DISTINGUISHED_ID, mailboxInfo);
                treeType = 'archiveFolderTree';
                deletedItemsFolderDistinguishedId = ARCHIVE_DELETED_ITEMS_DISTINGUISHED_ID;
            } else {
                dumpsterFolderDistinguishedId = PRIMARY_DUMPSTER_DISTINGUISHED_ID;
                dumpsterFolderId = folderNameToId(PRIMARY_DUMPSTER_DISTINGUISHED_ID, mailboxInfo);
                treeType = 'primaryFolderTree';
                deletedItemsFolderDistinguishedId = PRIMARY_DELETED_ITEMS_DISTINGUISHED_ID;
            }
            const isDumpsterTable = dumpsterFolderId === folderId;
            const isNotesFolder = folderIdToName(folderId) === 'notes';
            shouldShowFilterMenu = !isDumpsterTable && !isNotesFolder;

            renderThirdRow = (containerCssClass: string) => {
                const initializeThirdRow = lazyInitializeThirdRow.tryImportForRender();
                return initializeThirdRow?.(
                    dumpsterFolderDistinguishedId,
                    dumpsterFolderId,
                    deletedItemsFolderDistinguishedId,
                    folderId,
                    treeType,
                    styleSelectorAsPerUserSettings,
                    containerCssClass,
                    tableView
                );
            };

            if (isDumpsterTable) {
                renderSecondRowContent = (containerCssClass: string) => {
                    return React.createElement(
                        MailListHeaderSecondRowTextContent,
                        {
                            text: getEffectiveFolderDisplayName(
                                folderStore.folderTable.get(dumpsterFolderId)
                            ),
                            containerCssClass,
                            showFavoriteToggle: false,
                            styleSelectorAsPerUserSettings,
                        },
                        null /* children */
                    );
                };
            }
            // This checks whether the second row has been set. This is possible if:
            // The dumpster flag is off OR
            // We are in a non-dumpster folder
            if (!renderSecondRowContent) {
                if (shouldShowSelectionHeader) {
                    renderSecondRowContent = getSelectionHeader(tableView);
                } else {
                    renderSecondRowContent = (containerCssClass: string) => {
                        return React.createElement(
                            MailListFolderHeaderSecondRowContent,
                            {
                                tableViewId,
                                containerCssClass,
                                mailListHeaderStylesAsPerUserSettings:
                                    styleSelectorAsPerUserSettings,
                            },
                            null /* children */
                        );
                    };
                }
            }

            // Show bulk action progress bar there is a valid bulk operation running on that folder
            if (isBulkActionValid(folderId)) {
                renderBulkActionProgressBar = () => {
                    return React.createElement(BulkActionProgressBar);
                };
            }
        }

        return React.createElement(
            MailListHeader,
            {
                tableViewId,
                shouldShowFilterMenu,
                filterMenuSource,
                renderFirstRow,
                renderSecondRowCustomContent: renderSecondRowContent,
                renderThirdRow,
                renderBulkActionProgressBar,
                mailListHeaderStylesAsPerUserSettings: styleSelectorAsPerUserSettings,
            },
            null /* children */
        );
    }
);
export default getMailListHeader;

function getActionButtonData(personaEmail: string, personaName: string): HeaderButtonData[] {
    const actionButtonData: HeaderButtonData[] = [
        {
            icon: MailRegular,
            text: loc(emailButtonLabel),
            clickCallback: () => handleEmailClick(personaEmail, personaName),
        },
    ];

    actionButtonData.push({
        icon: 'ContactCard',
        text: loc(personaHeaderViewProfilebutton),
        isLPCWrapped: true,
        lpcClientScenario: 'PersonaHeaderProfileButton',
        personaEmail,
        personaName,
        lpcLocationToOpen: 'ExpandedView',
        clickCallback: () => {
            logUsage('ViewProfileFromHeader');
        },
    });

    return actionButtonData;
}

function handleEmailClick(personaEmail: string, personaName: string) {
    logUsage('MailComposeFromHeader');

    lazyNewMessage.importAndExecute('PersonaHeader', {
        toEmailAddressWrappers: [{ EmailAddress: personaEmail, Name: personaName }],
    });
}

function getSelectionHeader(tableView: TableView) {
    return (containerCssClass: string) => {
        return React.createElement(
            MailListHeaderSelectionRowContent,
            {
                numChecked: tableView.selectedRowKeys.size,
                containerCssClass,
                tableViewId: tableView.id,
                folderId: tableView.tableQuery.folderId,
            },
            null /* children */
        );
    };
}
