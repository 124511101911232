import { lazyToggleDrawTextPen } from 'owa-editor-ribbonplugin-store';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';

const onToggleDrawTextPen = (
    composeId: string,
    retriever: ((composeId: string) => RibbonMailComposeInfo | null) | undefined
) => {
    const editingInfo = retriever && retriever(composeId);

    if (editingInfo) {
        const drawViewState = editingInfo.composeViewState.ribbon.drawViewState;
        lazyToggleDrawTextPen.importAndExecute(
            editingInfo.composeViewState.editorId,
            drawViewState
        );
    }
};

export default onToggleDrawTextPen;
