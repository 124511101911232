import { constructKeytip, createAppButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { pictureCropButton } from './pictureControlsStrings.locstring.json';
import CropRegular from 'owa-fluent-icons-svg/lib/icons/CropRegular';
import type { IButtonStyles } from '@fluentui/react';
import { operateOwaImageEdit } from 'owa-editor-imageedit-plugin-operator';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { type ImageEditOperation } from 'roosterjs-editor-types/lib/enum/ImageEditOperation';
import { getNonBorderFocusedStyle } from 'owa-compose-formatcontrols-sections/lib/utils/nonBorderStyles';

const startCropping = (editorId: string) => {
    operateOwaImageEdit(editorId, imageEdit => {
        const selection = imageEdit.getImageSelection();
        if (selection) {
            imageEdit.setEditingImage(null, false /* selectImage */); //blur isn't triggered by ribbon, remove wrapper manually
            imageEdit.setEditingImage(selection, 8);
        }
    });
};

export const getPictureCropControl = (
    editorId: string,
    styles: IButtonStyles | undefined,
    tabId: number | undefined,
    isDisabled: boolean
) => {
    return createAppButton(
        getComposeRibbonId(6083, editorId),
        loc(pictureCropButton),
        getComputedCallback(6083, startCropping, editorId),
        tabId ? constructKeytip([tabId], 6083) : undefined,
        CropRegular,
        undefined /* iconColor */,
        getNonBorderFocusedStyle(styles),
        {
            disabled: isDisabled,
            takeFocusOnClick: true,
        }
    );
};
