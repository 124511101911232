import { createLazyComponent, LazyModule, LazyImport, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "HtmlEditor2ndUI" */ './lazyIndex')
);

export const TextColorPicker = createLazyComponent(lazyModule, m => m.TextColorPicker);
export const BackColorPicker = createLazyComponent(lazyModule, m => m.BackColorPicker);
export const FontPicker = createLazyComponent(lazyModule, m => m.FontPicker);
export const FontNamePicker = createLazyComponent(lazyModule, m => m.FontNamePicker);
export const FontSizePicker = createLazyComponent(lazyModule, m => m.FontSizePicker);
export const CasePicker = createLazyComponent(lazyModule, m => m.CasePicker);
export const InsertTableCallout = createLazyComponent(lazyModule, m => m.InsertTableCallout);
export const lazyDisplayInsertLinkDialog = new LazyImport(
    lazyModule,
    m => m.displayInsertLinkDialog
);
export const lazyShowImageAltTextDialog = new LazyImport(lazyModule, m => m.showImageAltTextDialog);
export const lazyShowCustomizedColorPickerDialog = new LazyAction(
    lazyModule,
    m => m.showCustomizedColorPickerDialog
);
export const lazyShowEditorPasteWarningDialog = new LazyAction(
    lazyModule,
    m => m.showEditorPasteWarningDialog
);

export type { InsertLinkProperties } from './components/InsertLinkDialog/InsertLinkProperties';
export type { default as FontPickerProps } from './components/FontPickerProps';
