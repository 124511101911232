import mailStore from 'owa-mail-store/lib/store/Store';
import type { ClientItem } from 'owa-mail-store';
import { getItemToShowFromNodeId } from 'owa-mail-store/lib/utils/conversationsUtils';
import { isMeetingMessage } from 'owa-meeting-message-utils';

export default function getLatestNonDraftMeetingItemFromTableView(
    conversationId: string
): ClientItem | undefined {
    const conversationItemParts = mailStore.conversations.get(conversationId);
    const conversationNodeIds = conversationItemParts
        ? conversationItemParts.conversationNodeIds
        : [];

    for (const nodeId of conversationNodeIds) {
        const item = getItemToShowFromNodeId(nodeId);
        if (item && !item.IsDraft && isMeetingMessage(item)) {
            return item;
        }
    }

    return undefined;
}
