import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppToggleButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import type RibbonFormatState from 'owa-editor-ribbonplugin-store/lib/store/schema/RibbonFormatState';
import Subscript from '../fonts/SubscriptIcon';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import type RibbonToggleFormatControlInfo from '../schema/RibbonToggleFormatControlInfo';
import { subscriptText } from './formatControlStrings.locstring.json';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { onToggleButtonClicked } from '../util/commandActions';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';

export const getSubscriptControl = owaComputedFn(
    (
        editorId: string,
        composeId: string | undefined,
        retriever: ((composeId: string) => RibbonToggleFormatControlInfo | null) | undefined,
        formatViewState: RibbonFormatState,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean,
        source: FormattingSource
    ): RibbonControlProps => {
        return createAppToggleButton(
            getComposeRibbonId(6022, editorId),
            loc(subscriptText),
            getComputedCallback(
                6022,
                onToggleButtonClicked,
                editorId,
                composeId,
                'toggleSubscript',
                retriever,
                source
            ),
            !!formatViewState.isSubscript,
            tabId ? constructKeytip([tabId], 6022) : undefined,
            Subscript,
            undefined /* iconColor */,
            styles,
            {
                disabled: isDisabled,
            }
        );
    }
);
