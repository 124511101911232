import { lazyNovaLogger } from 'owa-analytics';
export const RingIngestionKey =
    '9156e11e549943e5970de3f1661df5b2-1c673737-125c-416c-9121-b8a0db2ec86a-6465';
export const RingAppName = 'PeopleHighlightsRing';

export default function initializeRingNovaLogger() {
    // Initialize nova logger for Reading Pane Persona Ring.
    lazyNovaLogger.import().then(logger => {
        logger.associateIngestionKeyWithAppName(RingIngestionKey, RingAppName);
    });
}
