import type AddinOptionSurfaceItems from '../store/schema/AddinOptionSurfaceItems';
import store from '../store/store';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';
import { type MailboxInfo, getIndexerValueForMailboxInfo } from 'owa-client-types';

export default action('setAddinOptionSurfaceItems')(function setAddinOptionSurfaceItems(
    addinOptionSurfaceItems: AddinOptionSurfaceItems,
    mailboxInfo: MailboxInfo
) {
    const idx = getIndexerValueForMailboxInfo(mailboxInfo);
    store.addinOptionSurfaceItems.set(idx, addinOptionSurfaceItems);
});
