import { owaComputedFn } from 'owa-computed-fn';
import { moveToOverflowWell } from 'owa-command-ribbon';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { getRuntimeControls } from './getRuntimeControls';
import { modifyIdsForCompose } from './modifyIdsForCompose';

import type { RibbonScalingStep } from '@1js/acui-ribbon-like';
import type { RibbonRuntimeControlsGroup } from './getRuntimeControls';

export const getRuntimeControlsInitialScalingSteps = owaComputedFn(
    function getRuntimeControlsInitialScalingSteps(
        tabId: MailRibbonTabId,
        editorId: string
    ): RibbonScalingStep[] {
        const initialScalingSteps = [];
        switch (tabId) {
            case 5:
            case 10:
                const runtimeControlGroups: RibbonRuntimeControlsGroup = getRuntimeControls();
                if (runtimeControlGroups.controlsGroupName === 'ComposeAddInsInstalled') {
                    const installedAddInsIds = modifyIdsForCompose(
                        runtimeControlGroups.getControlIds(),
                        editorId
                    );
                    if (installedAddInsIds.length > 0) {
                        initialScalingSteps.push(moveToOverflowWell(...installedAddInsIds));
                    }
                }
                break;
        }
        return initialScalingSteps;
    }
);
