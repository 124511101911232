import { isFeatureEnabled } from 'owa-feature-flags';
import {
    PerformanceCoreDatapoint,
    PerformanceDatapoint,
    returnTopExecutingActionDatapoint,
} from 'owa-analytics';
import type TableView from 'owa-mail-list-store/lib/store/schema/TableView';
import type SelectionDirection from 'owa-mail-list-store/lib/store/schema/SelectionDirection';
import type MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import isSelectMailItemCore from './isSelectMailItemCore';
import {
    getSelectMailItemDatapoint,
    setSelectMailItemDatapoint,
} from './selectMailItemDatapointGetterAndSetter';

let eventTimestamp: number | undefined = undefined;

export function setInteractionTimestamp(timeStamp?: number) {
    eventTimestamp = timeStamp;
}

export function createSelectMailItemDatapoint(
    mailListItemSelectionSource: MailListItemSelectionSource,
    tableView: TableView,
    rowKey: string | undefined,
    selectionDirection?: SelectionDirection
) {
    if (isFeatureEnabled('rp-enhanced-telemetry-v2')) {
        // end the SMI datapoint existing due to v1 implementation
        const dp = returnTopExecutingActionDatapoint((datapoint: PerformanceDatapoint) => {
            return (
                datapoint.getEventName() == 'SelectMailItem' ||
                datapoint.getEventName() == 'SelectMailItemNonCritical'
            );
        });
        dp?.invalidate();
        dp?.end();

        // End previous V2 SMI if it has not ended yet.
        const previousV2SMI = getSelectMailItemDatapoint();
        previousV2SMI?.invalidate();
        previousV2SMI?.end();
        const isCoreAction = isSelectMailItemCore(
            mailListItemSelectionSource,
            tableView,
            rowKey,
            selectionDirection
        );
        const options = {
            timeout: 90 * 1000,
            eventTimestamp,
        };
        if (isCoreAction) {
            setSelectMailItemDatapoint(new PerformanceCoreDatapoint('SelectMailItem', options));
        } else {
            setSelectMailItemDatapoint(
                new PerformanceDatapoint('SelectMailItemNonCritical', options)
            );
        }
    }
}
