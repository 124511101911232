import { createLazyComponent, LazyModule } from 'owa-bundling';

export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ReadingPane"*/ './lazyIndex'),
    { name: 'ReadingPane' }
);

// Lazy components
export const MailMessageBody = createLazyComponent(lazyModule, m => m.MailMessageBody);
export const LoadFullBodyButton = createLazyComponent(lazyModule, m => m.LoadFullBodyButton);
export const EmptyStateReadingPane = createLazyComponent(lazyModule, m => m.EmptyStateReadingPane);
export const GlobalReplyBar = createLazyComponent(lazyModule, m => m.GlobalReplyBar);
export const SubjectHeader = createLazyComponent(lazyModule, m => m.SubjectHeader);
export const UnsupportedItemReadingPane = createLazyComponent(
    lazyModule,
    m => m.UnsupportedItemReadingPane
);
export const ItemHeader = createLazyComponent(lazyModule, m => m.ItemHeader);
export const SentReceivedSavedTime = createLazyComponent(lazyModule, m => m.SentReceivedSavedTime);

// Schema
export type { default as ItemReadingPaneProps } from './schema/ItemReadingPaneProps';
export type { default as SubjectHeaderProps } from './schema/SubjectHeaderProps';

// Utils
export { SAVE_SCROLL_DEBOUNCE } from './utils/saveScrollPositionUtils';

export const ItemReadingPaneContent = createLazyComponent(
    lazyModule,
    m => m.ItemReadingPaneContent
);
