import type StaticAction from '../store/schema/StaticAction';
import type ReadStaticAction from '../store/schema/ReadStaticAction';
import isMessageOptionsEnabled from './isMessageOptionsEnabled';
import { isEncryptionEnabled } from 'owa-encryption-common';
import { isFeatureEnabled } from 'owa-feature-flags';
import { LoopType, isFluidEnabledForSource } from 'owa-fluid-validations';
import type { ActionKey, ReadActionKey, SurfaceActionsOptions } from 'owa-outlook-service-options';
import { getOptionsForFeature, OwsOptionsFeatureType } from 'owa-outlook-service-options';
import { getIsDarkTheme } from 'owa-fabric-theme';
import { getAccountScopeUserSettings, isConsumer } from 'owa-session-store';
import { isNativeReportingEnabled } from 'owa-reporting';
import type { MailboxInfo } from 'owa-client-types';
import { isCapabilityEnabled } from 'owa-capabilities';
import { learningToolsCapability } from 'owa-capabilities-definitions/lib/learningToolsCapability';
import { translatorCapability } from 'owa-capabilities-definitions/lib/translatorCapability';
import { editorCapability } from 'owa-capabilities-definitions/lib/editorCapability';
import { isMessageExtensionsFlyoutEnabled } from 'owa-m365-acquisitions/lib/utils/isMessageExtensionsFlyoutEnabled';
import isMessageRecallEnabled from 'owa-cloud-message-recall/lib/utils/isMessageRecallEnabled';
import { isCopilotFeatureEnabled } from 'owa-copilot-settings-utils';
import isFileActivation from 'owa-mail-file-support/lib/utils/isFileActivation';
import { isTeamsChatEnabled } from 'owa-teams-integration/lib/utils/isTeamsChatEnabled';

/**
 * Return all the actions that should be shown on an item in Reading Pane
 *
 * IMPORTANT: When adding or removing an action ensure that a corresponding lines are added/removed in the following files:
 * getIconNameFromKey.ts
 * getDisplayNameFromKey.ts
 */
export function getReadStaticActions(mailboxInfo: MailboxInfo): ReadStaticAction[] {
    const isConsumerUser = isConsumer(undefined, mailboxInfo);
    const isFileActivationEnabled = isFeatureEnabled('mon-file-eml-msg') && isFileActivation();
    const actions: ReadStaticAction[] = [
        { key: 'Reply', hideInGroup: true, hideInSxS: true },
        { key: 'ReplyAll', hideInGroup: false, hideInSxS: true },
        { key: 'Forward', hideInGroup: false, hideInSxS: true },
    ];
    if (isFeatureEnabled('cmp-message-resend')) {
        actions.push({ key: 'Resend', hideInGroup: false, hideInSxS: true });
    }
    actions.push({ key: '-', hideInGroup: false, hideInSxS: false });

    if (
        !isConsumer(undefined, mailboxInfo) &&
        !!isCopilotFeatureEnabled('ReplyByMeeting', mailboxInfo)
    ) {
        actions.push({ key: 'CopilotReplyByMeeting', hideInGroup: true, hideInSxS: true });
    }

    actions.push(
        { key: 'ReplyByMeeting', hideInGroup: true, hideInSxS: true },
        { key: 'ForwardEmailAsAttachment', hideInGroup: false, hideInSxS: true },
        { key: '-', hideInGroup: false, hideInSxS: false },
        { key: 'Delete', hideInGroup: false, hideInSxS: true },
        { key: '-', hideInGroup: false, hideInSxS: false },
        { key: 'MarkReadUnread', hideInGroup: false, hideInSxS: true },
        { key: 'FlagUnflag', hideInGroup: true, hideInSxS: true },
        { key: '-', hideInGroup: false, hideInSxS: false }
    );

    if (isFeatureEnabled('mon-teams-ChatAroundEmail') && isTeamsChatEnabled(mailboxInfo)) {
        actions.push(
            { key: 'ReplyByTeamsChatActions', hideInGroup: true, hideInSxS: false },
            { key: 'ReplyByTeamsChat', hideInGroup: true, hideInSxS: false },
            { key: 'ReplyAllByTeamsChat', hideInGroup: true, hideInSxS: false },
            { key: '-', hideInGroup: true, hideInSxS: false }
        );
    } else if (isFeatureEnabled('fwk-skypeBusinessV2')) {
        actions.push(
            { key: 'ReplyByIM', hideInGroup: true, hideInSxS: false },
            { key: 'ReplyAllByIM', hideInGroup: true, hideInSxS: false },
            { key: '-', hideInGroup: true, hideInSxS: false }
        );
    }

    /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
     * Adding IsShadowMailbox to restricted properties/methods.
     *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
    if (!getAccountScopeUserSettings(mailboxInfo)?.SessionSettings?.IsShadowMailbox) {
        actions.push({ key: 'AddToSafeSenders', hideInGroup: true, hideInSxS: true });

        if (isNativeReportingEnabled(mailboxInfo)) {
            actions.push(
                {
                    key: 'MarkJunkNotJunk',
                    hideInGroup: true,
                    hideInSxS: true,
                    hideInDeepLink: isFileActivationEnabled,
                    hideInFileProjection: true,
                },
                {
                    key: 'MarkAsPhishing',
                    hideInGroup: true,
                    hideInSxS: true,
                    hideInDeepLink: isFileActivationEnabled,
                    hideInFileProjection: true,
                }
            );
        }

        actions.push({
            key: 'Block',
            hideInGroup: true,
            hideInSxS: true,
            hideInFileProjection: true,
        });

        if (isConsumer(undefined, mailboxInfo)) {
            actions.push({
                key: 'ReportAbuse',
                hideInGroup: !isConsumer(undefined, mailboxInfo),
                hideInSxS: true,
                hideInFileProjection: true,
            });
        }
        actions.push({ key: '-', hideInGroup: false, hideInSxS: false });
    } else if (isNativeReportingEnabled(mailboxInfo)) {
        // Expose the mark as junk action for cloud cache mailboxes
        actions.push({
            key: 'MarkJunkNotJunk',
            hideInGroup: true,
            hideInSxS: true,
            hideInFileProjection: true,
        });
    }

    if (!isConsumerUser) {
        actions.push(
            {
                key: 'AssignPolicy',
                hideInGroup: false,
                hideInSxS: true,
                hideInFileProjection: true,
            },
            { key: '-', hideInGroup: false, hideInSxS: false },
            { key: 'TrackReadReceipts', hideInGroup: true }
        );
    }

    /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
     * Adding IsShadowMailbox to restricted properties/methods.
     *	> 'IsShadowMailbox' is restricted from being used. IsCloudCache/IsShadowMailbox should be resolved in ECS as a filter in a feature flight if possible. */
    if (!getAccountScopeUserSettings(mailboxInfo)?.SessionSettings?.IsShadowMailbox) {
        actions.push({ key: 'CreateRule', hideInGroup: true, hideInSxS: true });

        if (!isConsumerUser && isMessageRecallEnabled(mailboxInfo)) {
            actions.push({ key: 'MessageRecall', hideInGroup: true });
        }
    }

    actions.push({ key: 'Print', hideInGroup: false, hideInSxS: false });

    if (isCapabilityEnabled(translatorCapability, mailboxInfo)) {
        actions.push({ key: 'Translate', hideInGroup: true, hideInSxS: true });
    }

    if (getIsDarkTheme()) {
        actions.push({ key: 'ToggleDarkMode', hideInGroup: false, hideInSxS: false });
    }

    if (isCapabilityEnabled(learningToolsCapability, mailboxInfo)) {
        actions.push({ key: 'ShowInImmersiveReader', hideInGroup: false, hideInSxS: true });
    }

    actions.push(
        { key: 'ViewMessageSource', hideInGroup: false, hideInSxS: true },
        {
            key: 'Popout',
            hideInGroup: false,
            hideInSxS: true,
            hideInDeepLink: isFileActivationEnabled,
            hideInFileProjection: true,
        }
    );

    // SetReaction.
    if (isFeatureEnabled('rp-reactions', mailboxInfo) && !isConsumerUser) {
        actions.push({
            key: 'SetReaction',
            hideInGroup: false,
            hideInSxS: true,
            hideInMailSurfaceSettings: true,
            hideInDeepLink: isFileActivationEnabled,
            hideInFileProjection: true,
        });
    }

    if (isMessageExtensionsFlyoutEnabled(mailboxInfo)) {
        actions.push({
            key: 'MessageExtensions',
            hideInGroup: false,
            hideInSxS: true,
            hideInMailSurfaceSettings: true,
        });
    }

    actions.push(
        { key: 'OtherReplyActions', hideInMailSurfaceSettings: true },
        { key: 'MessageSafety', hideInMailSurfaceSettings: true },
        { key: 'BlockOptions', hideInMailSurfaceSettings: true },
        { key: 'View', hideInMailSurfaceSettings: true },
        { key: 'CustomizeActions', hideInMailSurfaceSettings: true },
        { key: 'AdvancedActions', hideInMailSurfaceSettings: true },
        { key: 'Addins', hideInMailSurfaceSettings: true },
        { key: 'SaveEmail', hideInGroup: false, hideInSxS: false }
    );

    return actions;
}

/**
 * IMPORTANT: When adding or removing an action ensure that a corresponding lines are added/removed in the following files:
 * getIconNameFromKey.ts
 * getDisplayNameFromKey.ts
 */
export function getComposeStaticActions(mailboxInfo: MailboxInfo): StaticAction[] {
    let actions: StaticAction[] = [];
    actions.push(
        { key: 'AddAttachment', hideInGroup: false },
        { key: 'AddInlineImage', hideInGroup: false }
    );

    if (isFluidEnabledForSource('MailCompose', mailboxInfo, LoopType.Fluid)) {
        // VSO 130482: Allow users to hide fluid menu item from settings
        actions.push({
            key: 'FluidHeroButton',
            hideInGroup: false,
            hideInMailSurfaceSettings: true,
        });
    }

    // If adding the expression picker button, it must be placed after
    // button to add inline image.
    actions.push({ key: 'AddEmoji', hideInGroup: false });

    if (isFeatureEnabled('cal-native-findtime') && !isConsumer(undefined, mailboxInfo)) {
        actions.push({ key: 'FindTime', hideInGroup: false });
    }

    if (isCapabilityEnabled(editorCapability, mailboxInfo)) {
        actions.push({ key: 'ProofingOptions', hideInGroup: false });
    }

    // Add the rest of the always-provided actions.
    actions = actions.concat([
        { key: 'ToggleRibbon', hideInGroup: false },
        { key: 'SaveDraft', hideInGroup: false },
        { key: 'InsertSignature', hideInGroup: false },
        { key: 'ShowFrom', hideInGroup: false },
        { key: 'SetImportance', hideInGroup: false },
        { key: 'SwitchBodyType', hideInGroup: false },
        { key: 'AccChecker', hideInGroup: false },
    ]);

    if (
        isFeatureEnabled(
            'cmp-clp',
            mailboxInfo,
            !!mailboxInfo /* dontThrowErrorIfNotInitialized to help visually identify the scenarios hitting the error */
        ) &&
        !isConsumer(undefined, mailboxInfo)
    ) {
        actions.push({ key: 'SensitivityMenu', hideInGroup: false });
    }

    if (isEncryptionEnabled(mailboxInfo)) {
        actions.push({ key: 'ProtectMessage', hideInGroup: false });
    }

    // TODO #33638 Move ShowMessageOptions to owa-mail.
    if (isMessageOptionsEnabled()) {
        actions.push({ key: 'ShowMessageOptions', hideInGroup: false });
    }

    actions.push({ key: 'CustomizeActions', hideInGroup: false, hideInMailSurfaceSettings: true });

    return actions;
}

export function shouldAddActionToEnd(action: ActionKey): boolean {
    // Reply, ReplyAll, and Forward are the first 3 actions in the set of static actions in the reading pane.
    // While all other actions should render from right to left based on their order in the settings,
    // the desire is for these three to render left to right, so they should be inserted at the end of the collection.
    return (
        action == 'Reply' ||
        action == 'ReplyAll' ||
        action == 'Forward' ||
        action == 'MessageExtensions'
    );
}

export function isReadingPaneSurfaceActionPinnedByUser(
    actionKey: ReadActionKey,
    mailboxInfo: MailboxInfo
) {
    const surfaceActionsOptions = getOptionsForFeature<SurfaceActionsOptions>(
        OwsOptionsFeatureType.SurfaceActions,
        mailboxInfo
    );

    switch (actionKey) {
        case 'ReplyByTeamsChatActions':
            if (isFeatureEnabled('mon-teams-ChatAroundEmail') && isTeamsChatEnabled(mailboxInfo)) {
                // Only show ReplyByTeamsChatActions if either of the chat actions are enabled
                return (
                    surfaceActionsOptions.readSurfaceActions.includes('ReplyByTeamsChat') ||
                    surfaceActionsOptions.readSurfaceActions.includes('ReplyAllByTeamsChat')
                );
            } else {
                return false;
            }
        case 'ReplyByTeamsChat':
        case 'ReplyAllByTeamsChat':
            // Only ReplyByTeamsChatActions is surface action, so return false for these actions
            return false;
        default:
            return surfaceActionsOptions.readSurfaceActions.indexOf(actionKey) !== -1;
    }
}

export function shouldActionBeOnReadingPaneSurface(
    actionKey: ReadActionKey,
    mailboxInfo: MailboxInfo
): boolean {
    return (
        isReadingPaneSurfaceActionPinnedByUser(actionKey, mailboxInfo) ||
        // pin toggle dark mode on surface if it is dark theme
        !!(actionKey === 'ToggleDarkMode' && getIsDarkTheme()) ||
        !!(
            actionKey === 'SetReaction' &&
            isFeatureEnabled('rp-reactions', mailboxInfo) &&
            !isConsumer(undefined, mailboxInfo)
        ) ||
        !!(actionKey === 'MessageExtensions' && isMessageExtensionsFlyoutEnabled(mailboxInfo))
    );
}
