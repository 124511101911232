// extracted by mini-css-extract-plugin
export var addinLaunchButtonMargin = "oCfuN";
export var cardContainer = "SmuTL";
export var citationBadge = "Luy21";
export var citationNumber = "OcFnG";
export var collapsed = "wekzh";
export var compactHeader = "U1CjN";
export var content = "mkv5U";
export var contentCompact = "o0je3";
export var contentFooter = "fx8vP";
export var contentFooterCompact = "CAzxU";
export var contentFooterMedium = "lXYDY";
export var contentMedium = "mb8JR";
export var floatAddinPickerRight = "Su1A5";
export var formatedSummaryPersonName = "DATJg";
export var header = "UpUOL";
export var headerButtonPrimary = "GD_JY";
export var headerIcon = "c_HI7";
export var headerIconCompact = "hm_48";
export var headerIconMedium = "HqIAB";
export var headerName = "Cx2mP";
export var headerNameCompact = "UIGMb";
export var headerTitle = "l7xwn";
export var headerTitleCompact = "UEAIn";
export var iconImg = "VgaQA";
export var loadingHeader = "CMfbm";
export var loadingHeaderCompact = "pq5S4";
export var loadingHeaderProgressBar = "vp5HU";
export var loadingHeaderSpinner = "XqGDM";
export var loadingHeaderSpinnerCompact = "XaGiH";
export var loadingHeaderSpinnerMedium = "fnsLa";
export var loadingHeaderSpinnerWrapper = "pyxhL";
export var messageBar = "gm0PT";
export var messageBarContent = "rsTZt";
export var messageBarRoot = "nymWa";
export var raiDisclosureText = "wsART";