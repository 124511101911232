import { mutatorAction } from 'satcheljs';
import type ItemReadingPaneViewState from '../store/schema/ItemReadingPaneViewState';
import type ConversationReadingPaneViewState from '../store/schema/ConversationReadingPaneViewState';

export const saveReadingPaneScrollPosition = mutatorAction(
    'saveReadingPaneScrollPosition',
    function (
        viewState: ItemReadingPaneViewState | ConversationReadingPaneViewState,
        scrollRegionRef: HTMLElement
    ) {
        if (viewState) {
            /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
             * Baseline, please provide a proper justification if touching this code
             *	> 'scrollTop' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
            viewState.savedScrollPosition = scrollRegionRef.scrollTop;
        }
    }
);
