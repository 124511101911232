import type VirtualizedGroupHeader from '../type/VirtualizedGroupHeader';
import { mutatorAction } from 'satcheljs';
import getMailListGroupHeader from '../helpers/getMailListGroupHeader';
import getMailListGroupHeaderGenerator from '../helpers/getMailListGroupHeaderGenerator';
import virtualizedMailGroupHeadersStore from '../store/store';
import { getSelectedTableView } from 'owa-mail-list-store';
import { getCanTableLoadMore } from 'owa-mail-triage-table-load-extra';

export default mutatorAction(
    'addVirtualizedGroupHeadersAction',
    function addVirtualizedGroupHeadersAction() {
        const tableView = getSelectedTableView();
        const tableRowKeys = [...tableView.rowKeys];
        let previousRowKey: string = '';
        let currentRowKey: string = '';
        let newGroupHeader: VirtualizedGroupHeader;
        let currentGroupHeaderText: string = '';
        const groupHeadersFromStore = virtualizedMailGroupHeadersStore().virtualizedGroupHeaders;

        tableRowKeys.forEach((rowKey: string) => {
            currentRowKey = rowKey;
            const headerText = getMailListGroupHeader(
                previousRowKey,
                currentRowKey,
                tableView,
                getMailListGroupHeaderGenerator(tableView)
            );
            // If a header text is generated a check is run to see if it already exists in the store. If not, it is added to the store.
            if (headerText) {
                // Header text is generated for the row key(and other row keys after it) & the store has it already - eg. new mail list item arrives in mail list
                const preExistingGroupHeaderFromStore = groupHeadersFromStore.get(headerText);
                if (preExistingGroupHeaderFromStore) {
                    currentGroupHeaderText = headerText;
                    preExistingGroupHeaderFromStore.rowKeys.push(currentRowKey);
                } else {
                    // New header text that does not exist in store
                    const previousGroupHeaderLoaded =
                        groupHeadersFromStore.get(currentGroupHeaderText);
                    if (previousGroupHeaderLoaded) {
                        previousGroupHeaderLoaded.isLoadingOfRowKeysComplete = true;
                    }
                    currentGroupHeaderText = headerText;
                    newGroupHeader = {
                        isCollapsed: false,
                        isLoadingOfRowKeysComplete: false,
                        rowKeys: [currentRowKey],
                    };
                    groupHeadersFromStore.set(headerText, newGroupHeader);
                }
                // If no headerText is generated, we add it to the itemIds attached to the last header text
            } else {
                const existingGroupHeaderValue = groupHeadersFromStore.get(currentGroupHeaderText);
                if (
                    existingGroupHeaderValue &&
                    !existingGroupHeaderValue.rowKeys.includes(currentRowKey)
                ) {
                    existingGroupHeaderValue.rowKeys.push(currentRowKey);
                    groupHeadersFromStore.set(currentGroupHeaderText, existingGroupHeaderValue);
                }
            }
            previousRowKey = currentRowKey;
        });

        // For the last header - if all rows in the table are loaded and more cannot be loaded, the loading is considered complete.
        const lastGroupHeaderLoaded = groupHeadersFromStore.get(currentGroupHeaderText);
        if (lastGroupHeaderLoaded && !getCanTableLoadMore(tableView)) {
            lastGroupHeaderLoaded.isLoadingOfRowKeysComplete = true;
        }
    }
);
