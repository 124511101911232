import { FluentButton } from 'owa-fluent-v9-shims';
import React from 'react';
import AppFolderRegular from 'owa-fluent-icons-svg/lib/icons/AppFolderRegular';

const iconProps = {
    iconName: AppFolderRegular,
    styles: {
        root: {
            display: 'flex',
            fontSize: '20px',
            height: 'auto',
        },
    },
};

export function MessageExtensionControlPlaceholder() {
    return <FluentButton appearance="icon" iconProps={iconProps} />;
}
