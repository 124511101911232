import { LazyImport, LazyModule, LazyAction } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "GetItemManager"*/ './lazyIndex'),
    { name: 'GetItemManager' }
);

export const lazyGetAdditionalPropertiesFromServer = new LazyImport(
    lazyModule,
    m => m.getAdditionalPropertiesFromServer
);
export const lazyGetItemProperties = new LazyImport(lazyModule, m => m.getItemProperties);
export const lazyCleanUpByItemId = new LazyImport(lazyModule, m => m.cleanUpByItemId);
export const lazyInitializeGetItemManager = new LazyAction(lazyModule, m => m.initialize);
