import type { Layout } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components/RibbonControlGroupMLR';
import { owaComputedFn } from 'owa-computed-fn';
import type { RibbonGroupId } from 'owa-ribbon-ids';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';

export const getMLRInsertTabGroupLayouts = owaComputedFn(function getMLRInsertTabGroupLayouts(
    _group: RibbonGroupId,
    _props: ComposeRibbonControlDefProps
): Layout[] | undefined {
    // Insert tab has no group layouts
    return undefined;
});
