import React from 'react';
import { registerIcons } from '@fluentui/style-utilities';
import { superscript, fontIcon } from './style.scss';

import classNames from 'owa-classnames';

export default 'SuperscriptIcon';

registerIcons({
    icons: {
        SuperscriptIcon: <span className={classNames(superscript, fontIcon)}></span>,
    },
});
