import { tableBorderNoWeight } from './tableControlsStrings.locstring.json';
import { onTableBorderWeightMenuClicked } from '../../actions/deferredMenuDefinitionMutator';
import { constructKeytip, createAppFlyoutAnchor } from 'owa-command-ribbon';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { owaComputedFn } from 'owa-computed-fn';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import type { TableBorderFormatState } from 'owa-editor-ribbonplugin-store';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';

export const getTableBorderWeightControl = owaComputedFn(
    (
        editorId: string,
        styles: IButtonStyles | undefined,
        borderState: TableBorderFormatState,
        tabId: number | undefined,
        isDisabled: boolean,
        isSingleLineRibbon: boolean
    ): RibbonControlProps => {
        const storeName = 'tableBorderWeightMenuDefinitionStore';
        const noBorder = borderState.borderStyle == 'none';

        return createAppFlyoutAnchor(
            getComposeRibbonId(6098, editorId),
            noBorder ? loc(tableBorderNoWeight) : borderState.borderWidthLabel ?? '',
            getControlMenuStore(editorId, storeName),
            tabId ? constructKeytip([tabId], 6098, true /* hasMenu */) : undefined,
            noBorder ? undefined : ' ' /* icon */,
            undefined /* iconColor */,
            {
                ...styles,
                flexContainer: {
                    width: isSingleLineRibbon ? '105px' : '101px',
                },
                label: {
                    marginLeft: noBorder ? 0 : '-1px !important',
                    textAlign: noBorder ? 'center' : 'right',
                    paddingRight: noBorder ? 0 : '6px',
                },
                //Remove empty icon to display the border weight preview
                icon: {
                    height: 0,
                    overflow: 'hidden',
                    borderTop: `${borderState.borderWidth} solid ${
                        borderState.borderColor ?? 'var(--neutralDark)'
                    }`,
                    marginTop: isSingleLineRibbon ? 0 : '15px',
                    minWidth: '40px',
                },
                textContainer: {
                    order: -1,
                    minWidth: noBorder ? '75px' : 0,
                },
            },
            {
                disabled: isDisabled,
                onExecute: parameter => {
                    const { event } = parameter;
                    event?.stopPropagation();
                    onTableBorderWeightMenuClicked(editorId, borderState);
                },
                onAfterMenuDismiss: () => {
                    resetMenuDefinition(editorId, storeName);
                    callEditorApi(editorId, 'focus');
                },
            }
        );
    }
);
