// extracted by mini-css-extract-plugin
export var arrowButtonIcons = "RgGDF";
export var bottomToolBarLeftSide = "x01Qi";
export var bottomToolBarRightSide = "vmaKW";
export var categoryOuterContainer = "lgYCY";
export var collapsed = "W0NbQ";
export var compact = "nvILJ";
export var exitButtonIcon = "U9pKi";
export var full = "M07MM";
export var fullSubject = "CCN_O";
export var headerIconsContainer = "LiYQG";
export var iconCompact = "T1R4j";
export var iconFull = "JA1t1";
export var iconMedium = "Rb9b4";
export var immersiveExitContainer = "tfNzr";
export var inlineAttachments = "kyCyq";
export var inlineSubject = "UXx3I";
export var isConversationView = "OpphO";
export var isSpecialCaseCard = "sFEhK";
export var label = "FX5vC";
export var lockIcon = "NLjsO";
export var medium = "uR605";
export var navButtonsContainer = "AzXTd";
export var readingPaneHeader = "NUbju";
export var readingPaneInnerContainer = "iMFaY";
export var separator = "sdVXX";
export var slvNav = "iiCUi";
export var slvNavButtons = "LqF5E";
export var slvTop = "fd1EW";
export var specialCardContainerWithExitTreatment = "rQ3CY";
export var specialCardIcon = "HpvCz";
export var specialCardIconContainer = "HJkGC";
export var specialCardIconWithExitTreatment = "M3Nxp";
export var subject = "RCfNE";
export var subjectContainer = "hXFUn";
export var subjectHeaderIcon = "jdRgB";
export var subjectHeaderV2 = "emOrD";
export var subjectIcons = "sTEBX";
export var subjectLineContainer = "dZIwV";
export var subjectText = "UAxMv";
export var subjectTextWithCategoriesDensity = "g8O9y";
export var top = "s8rZ5";
export var topRightContainer = "Y0qxw";