import { LazyModule, createLazyComponent, registerLazyOrchestrator } from 'owa-bundling';
import { renderMessageOnHover } from './actions/renderMessageOnHover';
import { onBookmarkIconClicked } from './actions/onBookmarkIconClicked';
import { onFocusedInboxClassificationCorrection } from './actions/onFocusedInboxClassificationCorrection';
import { onOverrideCorrection } from 'owa-mail-triage-action/lib/actions/actionCreators/onOverrideCorrection';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "FocusedExplanations" */ './lazyIndex')
);

// Delayed Loaded Components
export const FocusedExplanationIcon = createLazyComponent(
    lazyModule,
    m => m.FocusedExplanationIcon
);

registerLazyOrchestrator(
    onFocusedInboxClassificationCorrection,
    lazyModule,
    m => m.getFeedbackFocusedInboxClassificationOrchestrator
);

registerLazyOrchestrator(
    renderMessageOnHover,
    lazyModule,
    m => m.getFocusedOtherReasonOrchestrator
);

registerLazyOrchestrator(
    onBookmarkIconClicked,
    lazyModule,
    m => m.moveToFocusedOtherInboxOrchestrator
);

registerLazyOrchestrator(
    onOverrideCorrection,
    lazyModule,
    m => m.removeFocusedInboxOverrideOrchestrator
);
