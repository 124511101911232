import getCleanPreview from './getCleanPreview';
import { owaComputedFn } from 'owa-computed-fn';
import { logUsage } from 'owa-analytics';
import {
    getConditionalFormattingRulesByMailbox,
    getKeyForRowIdInRowToModificationMap,
    getConditionalFormattingModificationForRow,
    addEntryToRowToModificationMap,
    removeEntryFromRowToModificationMap,
} from 'owa-conditional-formatting';
import type { ConditionalFormattingModification } from 'owa-conditional-formatting';
import getConditionalFormattingModificationsToApplyOnRow from './getConditionalFormattingModificationsToApplyOnRow';
import { isFeatureEnabled } from 'owa-feature-flags';
import isConsumer from 'owa-session-store/lib/utils/isConsumer';
import loc from 'owa-localize';
import { noSubject } from 'owa-locstrings/lib/strings/nosubject.locstring.json';
import { unknownSenderOrRecipient } from 'owa-locstrings/lib/strings/unknownsenderorrecipient.locstring.json';
import { TAG_EMAIL_HASHTAG } from 'owa-mail-fetch-tagged-email';
import shouldAllowDelete from 'owa-mail-filterable-menu-behavior/lib/utils/shouldAllowDelete';
import getItemClassIcon from 'owa-mail-list-actions/lib/utils/conversationProperty/getItemClassIcon';
import doesConversationHaveDrafts from 'owa-mail-list-actions/lib/utils/doesConversationHaveDrafts';
import { doesRowBelongToNudgeSection, getNudgedReason } from 'owa-mail-nudge-store';
import isMessageUnauthenticated from 'owa-mail-sender-persona-view/lib/utils/isMessageUnauthenticated';
import { isSuggestedUnsubscribeItem } from 'owa-mail-suggested-unsubscribe-store';
import type { ClientItem } from 'owa-mail-store';
import mailStore from 'owa-mail-store/lib/store/Store';
import { externalInOutlookEnabled } from 'owa-nonboot-userconfiguration-manager';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import getUserConfiguration from 'owa-session-store/lib/actions/getUserConfiguration';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import getSmimeType, { getSmimeTypeForConversation } from 'owa-smime/lib/utils/getSmimeType';
import * as trace from 'owa-trace';
import type { MailListItemDataProps, MailListTableProps } from 'owa-mail-list-item-shared';
import {
    isSecondLevelExpanded,
    isFirstLevelExpanded,
} from 'owa-mail-list-store/lib/selectors/isConversationExpanded';
import type Message from 'owa-service/lib/contract/Message';
import type {
    TableView,
    MailFolderTableQuery,
    TableQuery,
    ConversationItem,
} from 'owa-mail-list-store';
import {
    getUserCategoryName,
    getTableConversationRelation,
    getMeetingMessageItems,
    isSearchFolderScenario,
    isItemOfMessageType,
    getStore as getListViewStore,
    MailRowDataPropertyGetter,
    type TableQueryType,
} from 'owa-mail-list-store';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
import type { MailboxInfo } from 'owa-client-types';
import { shouldShowSenderGroupingsActionButton } from 'owa-sender-groupings-button';
import getSenderNameForDisplay from 'owa-mail-store/lib/utils/getSenderNameForDisplay';
import { isMeetingRequest } from 'owa-meeting-message-utils';
import type MeetingRequestMessageType from 'owa-service/lib/contract/MeetingRequestMessageType';

const getMailListItemDataProps = owaComputedFn(
    (rowKey: string, mailListTableProps: MailListTableProps): MailListItemDataProps | null => {
        const tableView = getListViewStore().tableViews.get(mailListTableProps.tableViewId);
        if (!tableView) {
            return null;
        }

        const listViewType = tableView.tableQuery.listViewType;
        const isNudged = doesRowBelongToNudgeSection(
            rowKey,
            tableView.id,
            MailRowDataPropertyGetter.getLastDeliveryOrRenewTimeStamp(rowKey, tableView)
        );

        // VSO 20477: Combine getMailListItemPropsForConversation and getMailListItemPropsForItem
        switch (listViewType) {
            case 0:
                return getMailListItemPropsForConversation(
                    rowKey,
                    tableView,
                    mailListTableProps,
                    isNudged
                );
            case 1:
                return getMailListItemPropsForItem(rowKey, tableView, mailListTableProps, isNudged);

            default:
                trace.trace.warn('Not supported listViewType:' + listViewType);
                return null;
        }
    }
);

function getMailListItemPropsForItem(
    rowKey: string,
    tableView: TableView,
    mailListTableProps: MailListTableProps,
    isNudged: boolean
): MailListItemDataProps | null {
    if (!rowKey) {
        throw new Error('getMailListItemPropsForItem: ItemId should be present for given rowKey');
    }

    const mailboxInfo = getMailboxInfo(tableView);
    const rowId = MailRowDataPropertyGetter.getRowIdString(rowKey, tableView);
    const item: ClientItem | undefined = mailStore.items.get(rowId);
    if (!item) {
        trace.errorThatWillCauseAlert('getMailListItemDataProps: item does not exist in cache.');
        return null;
    }

    if (!item.ParentFolderId) {
        trace.errorThatWillCauseAlert('getMailListItemDataProps: item.ParentFolderId is null.');
    }

    let message;

    if (isItemOfMessageType(item)) {
        message = item as Message;
    } else {
        message = null;
    }

    const userConfiguration = getUserConfiguration();

    // Calculate first two line displays
    const isItemPinned = MailRowDataPropertyGetter.getIsPinned(rowKey, tableView);
    const subject = item.Subject ? item.Subject : loc(noSubject);
    const parentFolderId = item.ParentFolderId?.Id;
    const parentFolderDisplayName = item.ParentFolderDisplayName;
    const folderName =
        tableView.tableQuery.folderId && folderIdToName(tableView.tableQuery.folderId);
    const parentFolderName = parentFolderId && folderIdToName(parentFolderId);
    const isDraftsFolder = folderName == 'drafts';
    const isItemInDraftsFolder = isDraftsFolder || parentFolderName == 'drafts';
    const isItemInSentItemsFolder = folderName == 'sentitems' || parentFolderName == 'sentitems';
    const isItemInNotesFolder = folderName == 'notes' || parentFolderName == 'notes';
    const isItemInOutboxFolder = folderName == 'outbox' || parentFolderName == 'outbox';
    const isItemInArchiveFolder = folderName == 'archive' || parentFolderName == 'archive';
    const isItemInJunkFoler = folderName == 'junkemail' || parentFolderName == 'junkemail';
    const mailboxGuids = MailRowDataPropertyGetter.getMailboxGuids(rowKey, tableView);
    const isGroupItem = tableView.tableQuery.type == 2;

    let senderName = message?.From?.Mailbox?.Name || '';
    const senderEmailAddress = message?.From?.Mailbox?.EmailAddress ?? '';

    let conditionalFormattingModifications: ConditionalFormattingModification[] = [];
    const conditionalFormattingRules = getConditionalFormattingRulesByMailbox(mailboxInfo);

    if (isFeatureEnabled('tri-conditionalFormatting') && conditionalFormattingRules.length > 0) {
        const messageToMe = message?.MessageToMe;
        const messageCcMe = message?.MessageCcMe;
        const existingKeyForRowId = getKeyForRowIdInRowToModificationMap(rowId, mailboxInfo) ?? '';
        const newKeyForRowId =
            rowId +
            subject +
            (messageToMe !== undefined && messageToMe !== null ? messageToMe.toString() : '') +
            (messageCcMe !== undefined && messageCcMe !== null ? messageCcMe.toString() : '');
        conditionalFormattingModifications = getConditionalFormattingModificationForRow(
            existingKeyForRowId,
            mailboxInfo
        );

        // If the key is different from the new key, then delete the previous entry from the map and re-evaluate rules and add a new entry to the map
        if (existingKeyForRowId !== newKeyForRowId) {
            removeEntryFromRowToModificationMap(existingKeyForRowId, mailboxInfo);

            conditionalFormattingModifications = getConditionalFormattingModificationsToApplyOnRow(
                conditionalFormattingRules,
                subject,
                messageToMe,
                messageCcMe,
                senderEmailAddress
            );

            addEntryToRowToModificationMap(
                newKeyForRowId,
                conditionalFormattingModifications,
                mailboxInfo
            );
        }
    }

    //Canceled appointments (type IPM.Appointment) can have an empty sender name.
    //So we will show the recipients and senders. In parity.
    if (!senderName && item.ItemClass && isItemOfAppointmentType(item.ItemClass)) {
        senderName = item.DisplayTo ?? '';
    }

    const { sendersOrRecipients, singleLastSenderSMTP } = getSendersOrRecipients(
        [item.DisplayTo ?? ''],
        [senderName],
        MailRowDataPropertyGetter.getLastSenderSMTP(rowKey, tableView),
        isItemInSentItemsFolder,
        isItemInDraftsFolder,
        isItemInOutboxFolder,
        mailListTableProps.isSearchNotInDraftOrSentItemFolder,
        tableView.tableQuery
    );
    let firstLineText: string;
    let firstLineTooltipText: string | null = null;
    let secondLineText: string;
    let secondLineTooltipText: string | null = null;

    let unreadCount = 0;
    if (message) {
        unreadCount = message.IsRead ? 0 : 1;
    }

    // Show pinned items rows in condensed view when
    // table supports it and
    // item is pinned
    // item is already read
    const showCondensedView =
        mailListTableProps.showCondensedPinnedRows && isItemPinned && unreadCount === 0;

    const shouldShowSenderOnTop = userConfiguration?.UserOptions?.ShowSenderOnTopInListView;

    if (
        mailListTableProps.isSingleLine ||
        shouldShowSenderOnTop ||
        isDraftsFolder // When in Drafts folder we always show recipients on the first line irrespective of user's settings
    ) {
        firstLineText = sendersOrRecipients;
        firstLineTooltipText = singleLastSenderSMTP;
        secondLineText = subject;
    } else {
        secondLineText = sendersOrRecipients;
        secondLineTooltipText = singleLastSenderSMTP;
        firstLineText = subject;
        firstLineTooltipText = null;
    }

    const shouldSuggestUnsubscribe = shouldShowSuggestedUnsubscribe(rowId, tableView, mailboxInfo);

    // Do not populate preview text if preview is disabled
    const previewText = userConfiguration?.UserOptions?.ShowPreviewTextInListView
        ? getCleanPreview(item.Preview ?? '')
        : '';

    const thirdLineText = getThirdLineText(previewText, isNudged, rowKey);

    // We make the assumption that if a ReceivedOrRenewTime != DateTimeReceived the item must either be pinned or snoozed.
    // If the item has a return time or is NOT pinned and has the above inequality, we will mark the item as snoozed.
    const isSnoozed =
        mailListTableProps.supportsSnooze &&
        !!(
            !!item.ReturnTime ||
            (!isItemPinned &&
                item.ReceivedOrRenewTime &&
                item.DateTimeReceived &&
                !isTimestampEqual(item.ReceivedOrRenewTime, item.DateTimeReceived))
        );

    const isTaggedForBigScreen = !!item.Hashtags && item.Hashtags.indexOf(TAG_EMAIL_HASHTAG) >= 0;
    const itemClassIcon = getItemClassIcon(
        item.ItemClass ? [item.ItemClass] : undefined,
        item.IconIndex ?? 'Default',
        false /* hasIrm */
    );

    const showAttachmentPreviews: boolean =
        !!userConfiguration?.UserOptions?.ShowInlinePreviews && !!item.shouldShowAttachmentPreviews;

    const meetingStartTime = isMeetingRequest(item?.ItemClass)
        ? (item as MeetingRequestMessageType)?.Start
        : undefined;

    return {
        canDelete: shouldAllowDelete(tableView, rowKey),
        categories: getCategoriesToShow(tableView, item.Categories),
        conversationId: item.ConversationId?.Id ?? '',
        effectiveMentioned: !!item.MentionedMe,
        firstLineText,
        firstLineTooltipText,
        hasAttachment: !!item.HasAttachments,
        importance: item.Importance ?? 'Normal',
        isComplete: item.Flag ? item.Flag.FlagStatus === 'Complete' : false, // item.Flag could be undefined in search results
        isFlagged: item.Flag ? item.Flag.FlagStatus === 'Flagged' : false, // item.Flag could be undefined in search results
        isPinned: isItemPinned,
        isNudged,
        isSelected: tableView.selectedRowKeys.has(rowKey),
        itemClassIcon,
        lastDeliveryTimestamp: getLastDeliveryTimestamp(
            item,
            isDraftsFolder,
            isItemInSentItemsFolder
        ),
        lastSender: message?.From,
        latestItemId: item.ItemId?.Id ?? '',
        parentFolderId: parentFolderId ?? '',
        parentFolderDisplayName: parentFolderDisplayName ?? '',
        rowId,
        rowKey,
        secondLineText,
        secondLineTooltipText,
        showAttachmentPreview: showAttachmentPreviews,
        isUnauthenticatedSender: !!message && !!isMessageUnauthenticated(message),
        isSnoozed,
        returnTime: getItemReturnTime(item, isSnoozed),
        showCondensedView,
        showDraftsIndicator: !!item.IsDraft,
        subject,
        thirdLineText,
        unreadCount,
        smimeType: getSmimeType(item),
        sendersOrRecipients,
        shouldShowTwisty: false,
        isInNotesFolder: isItemInNotesFolder,
        isInOutboxFolder: isItemInOutboxFolder,
        isInArchiveFolder: isItemInArchiveFolder,
        isInJunkFolder: isItemInJunkFoler,
        shouldShowRSVP: !!item.shouldShowRSVP,
        shouldShowExternalLabel: !!item.IsExternalSender && externalInOutlookEnabled(mailboxInfo),
        shouldShowSenderGroupingsButton:
            shouldShowSenderGroupingsActionButton(tableView, message?.From) ?? false,
        shouldSuggestUnsubscribe,
        hasSharepointLink: !!item.HasProcessedSharepointLink,
        canPin: canPin(tableView, mailListTableProps.supportsPinning, parentFolderId ?? ''),
        isInGroup: isGroupItem,
        isTaggedForBigScreen,
        mailboxGuids,
        conditionalFormattingModifications,
        meetingStartTime,
    };
}

function getMailListItemPropsForConversation(
    rowKey: string,
    tableView: TableView,
    mailListTableProps: MailListTableProps,
    isNudged: boolean
): MailListItemDataProps | null {
    const tableConversationRelation = getTableConversationRelation(rowKey, tableView.id);
    if (!tableConversationRelation) {
        logUsage('tableConversationRelationNotFound');
        trace.trace.warn('tableConversationRelation not found in store.');
        return null;
    }
    const rowId = tableConversationRelation.id;
    const conversationItem: ConversationItem | undefined =
        getListViewStore().conversationItems.get(rowId);
    const userConfiguration = getUserConfiguration();
    const mailboxInfo = getMailboxInfo(tableView);

    if (!conversationItem) {
        // Converting exception to trace error here to prevent app crash.
        // VSO 25058: Investigate why conversationItem not found in store in getMailListItemDataProps
        const sort = (tableView.tableQuery as MailFolderTableQuery).sortBy?.sortColumn;
        logUsage('ConversationNotFound', { sort });
        trace.trace.warn('getProps Conversation not found in store. sortColumn ' + sort);
        return null;
    }

    // Get item status
    const isComplete = tableConversationRelation.flagStatus === 'Complete';
    const isPinned = MailRowDataPropertyGetter.getIsPinned(rowKey, tableView);
    const mailboxGuids = MailRowDataPropertyGetter.getMailboxGuids(rowKey, tableView);
    const isFlagged = tableConversationRelation.flagStatus === 'Flagged';
    const effectiveMentioned = tableConversationRelation.effectiveMentioned;

    // Calculate first two line displays
    const subject = conversationItem.subject ? conversationItem.subject : loc(noSubject);
    const parentFolderId = tableConversationRelation.parentFolderId;
    const folderName =
        tableView.tableQuery.folderId && folderIdToName(tableView.tableQuery.folderId);
    const parentFolderName = parentFolderId && folderIdToName(parentFolderId);
    const isDraftsFolder = folderName == 'drafts';
    const isItemInDraftsFolder = isDraftsFolder || parentFolderName == 'drafts';
    const isItemInSentItemsFolder = folderName == 'sentitems' || parentFolderName == 'sentitems';
    const isItemInNotesFolder = folderName == 'notes' || parentFolderName == 'notes';
    const isItemInOutboxFolder = folderName == 'outbox' || parentFolderName == 'outbox';
    const isItemInArchiveFolder = folderName == 'archive' || parentFolderName == 'archive';
    const isItemInJunkFoler = folderName == 'junkemail' || parentFolderName == 'junkemail';
    const isGroupItem = tableView.tableQuery.type == 2;
    const lastSenderEmailAddress =
        tableConversationRelation.lastSender?.Mailbox?.EmailAddress ?? '';

    let conditionalFormattingModifications: ConditionalFormattingModification[] = [];
    const conditionalFormattingRules = getConditionalFormattingRulesByMailbox(mailboxInfo);

    if (isFeatureEnabled('tri-conditionalFormatting') && conditionalFormattingRules.length > 0) {
        const conversationToMe = tableConversationRelation.conversationToMe;
        const conversationCcMe = tableConversationRelation.conversationCcMe;
        const existingKeyForRowId = getKeyForRowIdInRowToModificationMap(rowId, mailboxInfo) ?? '';
        const newKeyForRowId =
            rowId +
            subject +
            (conversationToMe !== undefined && conversationToMe !== null
                ? conversationToMe.toString()
                : '') +
            (conversationCcMe !== undefined && conversationCcMe !== null
                ? conversationCcMe.toString()
                : '');
        conditionalFormattingModifications = getConditionalFormattingModificationForRow(
            existingKeyForRowId,
            mailboxInfo
        );

        // If the key is different from the new key, then delete the previous entry from the map and re-evaluate rules and add a new entry to the map
        if (existingKeyForRowId !== newKeyForRowId) {
            removeEntryFromRowToModificationMap(existingKeyForRowId, mailboxInfo);
            conditionalFormattingModifications = getConditionalFormattingModificationsToApplyOnRow(
                conditionalFormattingRules,
                subject,
                conversationToMe,
                conversationCcMe,
                lastSenderEmailAddress
            );

            addEntryToRowToModificationMap(
                newKeyForRowId,
                conditionalFormattingModifications,
                mailboxInfo
            );
        }
    }

    const { sendersOrRecipients, singleLastSenderSMTP } = getSendersOrRecipients(
        tableConversationRelation.uniqueRecipients,
        tableConversationRelation.uniqueSenders,
        MailRowDataPropertyGetter.getLastSenderSMTP(rowKey, tableView),
        isItemInSentItemsFolder,
        isItemInDraftsFolder,
        isItemInOutboxFolder,
        mailListTableProps.isSearchNotInDraftOrSentItemFolder,
        tableView.tableQuery
    );
    const unreadCount = tableConversationRelation.unreadCount;
    // Show conversation row in condensed view when
    // table supports condensed view
    // they are pinned and
    // not expanded
    // items are all read
    const showCondensedView =
        mailListTableProps.showCondensedPinnedRows &&
        isPinned &&
        !(isSecondLevelExpanded(rowKey) || isFirstLevelExpanded(rowKey)) &&
        unreadCount === 0;

    const shouldShowSenderOnTop = userConfiguration?.UserOptions?.ShowSenderOnTopInListView;

    let firstLineText: string;
    let firstLineTooltipText: string | null = null;
    let secondLineText: string;
    let secondLineTooltipText: string | null = null;

    if (
        mailListTableProps.isSingleLine ||
        shouldShowSenderOnTop ||
        isDraftsFolder // When in Drafts folder we always show recipients on the first line irrespective of user's settings
    ) {
        firstLineText = sendersOrRecipients;
        firstLineTooltipText = singleLastSenderSMTP;
        secondLineText = subject;
    } else {
        secondLineText = sendersOrRecipients;
        secondLineTooltipText = singleLastSenderSMTP;
        firstLineText = subject;
    }

    const shouldSuggestUnsubscribe = shouldShowSuggestedUnsubscribe(rowId, tableView, mailboxInfo);

    // Do not populate preview text if preview is disabled
    const previewText = userConfiguration?.UserOptions?.ShowPreviewTextInListView
        ? getCleanPreview(tableConversationRelation.previewText)
        : '';

    const thirdLineText = getThirdLineText(previewText, isNudged, rowKey);

    // Add [Drafts] indicator if the conversation has any drafts
    const showDraftsIndicator: boolean =
        doesConversationHaveDrafts(tableConversationRelation) || isItemInDraftsFolder;

    const showAttachmentPreviews =
        !!userConfiguration?.UserOptions?.ShowInlinePreviews &&
        conversationItem.shouldShowAttachmentPreviews;

    // We make the assumption that if a lastDeliveryOrRenewTimeStamp != lastDeliveryTimeStamp the conversation must either be pinned or snoozed.
    // If the conversation has a return time or is NOT pinned and has the above inequality, we will mark the conversation as snoozed.
    const isSnoozed =
        mailListTableProps.supportsSnooze &&
        (!!tableConversationRelation.returnTime ||
            (!isPinned &&
                !isTimestampEqual(
                    tableConversationRelation.lastDeliveryOrRenewTimeStamp,
                    tableConversationRelation.lastDeliveryTimeStamp
                )));
    const itemClassIcon = getItemClassIcon(
        tableConversationRelation.itemClasses,
        tableConversationRelation.iconIndex,
        tableConversationRelation.hasIrm
    );

    const meetingMessageItem = getMeetingMessageItems(tableConversationRelation.itemIds[0]);
    const meetingStartTime = isMeetingRequest(meetingMessageItem?.ItemClass)
        ? (meetingMessageItem as MeetingRequestMessageType)?.Start
        : undefined;

    return {
        rowKey,
        rowId,
        conversationId: rowId,
        firstLineText,
        firstLineTooltipText,
        secondLineText,
        secondLineTooltipText,
        thirdLineText,
        isComplete,
        isFlagged,
        isPinned,
        isNudged,
        isSelected: tableView.selectedRowKeys.has(rowKey),
        canDelete: shouldAllowDelete(tableView, rowKey),
        unreadCount,
        subject,
        categories: getCategoriesToShow(tableView, tableConversationRelation.categories),
        lastSender: tableConversationRelation.lastSender,
        latestItemId: tableConversationRelation.itemIds[0],
        lastDeliveryTimestamp: isItemInSentItemsFolder
            ? tableConversationRelation.lastSentTimeStamp
            : tableConversationRelation.lastDeliveryTimeStamp,
        itemClassIcon,
        importance: tableConversationRelation.importance,
        hasAttachment: tableConversationRelation.hasAttachments,
        showDraftsIndicator,
        showAttachmentPreview: showAttachmentPreviews,
        effectiveMentioned,
        parentFolderDisplayName: '',
        parentFolderId,
        isUnauthenticatedSender: false, // conversationItem.hasUnauthenticatedEmails
        isSnoozed,
        returnTime: getTableConversationRelationReturnTime(tableConversationRelation, isSnoozed),
        showCondensedView,
        smimeType: getSmimeTypeForConversation(tableConversationRelation.itemClasses),
        sendersOrRecipients,
        shouldShowTwisty: conversationItem.globalMessageCount > 1,
        shouldShowRSVP: tableConversationRelation.shouldShowRSVP,
        isInNotesFolder: isItemInNotesFolder,
        isInOutboxFolder: isItemInOutboxFolder,
        isInArchiveFolder: isItemInArchiveFolder,
        isInJunkFolder: isItemInJunkFoler,
        shouldShowExternalLabel:
            !!conversationItem.hasExternalEmails && externalInOutlookEnabled(mailboxInfo),
        shouldShowSenderGroupingsButton:
            shouldShowSenderGroupingsActionButton(
                tableView,
                tableConversationRelation.lastSender
            ) ?? false,
        shouldSuggestUnsubscribe,
        hasSharepointLink: conversationItem.hasSharepointLink,
        canPin: canPin(tableView, mailListTableProps.supportsPinning, parentFolderId),
        isInGroup: isGroupItem,
        isTaggedForBigScreen: tableConversationRelation.isTaggedForBigScreen,
        mailboxGuids,
        conditionalFormattingModifications,
        meetingStartTime,
    };
}

function getSendersOrRecipients(
    recipients: string[],
    uniqueSenders: string[],
    lastSenderSMTP: string,
    isItemInSentItemsFolder: boolean,
    isItemInDraftsFolder: boolean,
    isItemInOutboxFolder: boolean,
    isSearchNotInDraftOrSentItemFolder: boolean,
    tableQuery: TableQuery
): {
    sendersOrRecipients: string;
    singleLastSenderSMTP: string | null;
} {
    let sendersOrRecipients;
    let singleLastSenderSMTP;
    const { scenarioType } = tableQuery as MailFolderTableQuery;
    if (
        (isItemInSentItemsFolder || isItemInDraftsFolder || isItemInOutboxFolder) &&
        !isSearchNotInDraftOrSentItemFolder &&
        !isSearchFolderScenario(scenarioType)
    ) {
        // Show recipients for conversations belonging to drafts, sentitems and outbox folders
        // EXCEPT when we are in a search scenario since we want the preview text, name, and circle persona to match
        sendersOrRecipients = recipients && recipients.join('; ');
        singleLastSenderSMTP = null;
    } else {
        sendersOrRecipients =
            uniqueSenders && uniqueSenders.map(getSenderNameForDisplay).join('; ');

        // Show tooltip text when there is single sender
        singleLastSenderSMTP =
            uniqueSenders && uniqueSenders.length === 1
                ? getSenderNameForDisplay(lastSenderSMTP)
                : null;
    }

    // If there are no senders or no recipients add a default string [Unknown]
    // For an item in a drafts folder user may not have defined any recipients
    // For items (in both sent & drafts) that do not have recipients in To: and
    // have recipients in Cc:/Bcc: we do not receive any recipients today from server.
    if (!sendersOrRecipients) {
        sendersOrRecipients = isItemInDraftsFolder ? '' : loc(unknownSenderOrRecipient);
    }

    return { sendersOrRecipients, singleLastSenderSMTP };
}

// Helper function to normalize and compare date times for isSnoozed calculation. This function is
// required to normalize times returned by search results with millisecond. For the present we will
// simply ignore milliseconds and assume the two times are equal when in search.
function isTimestampEqual(time1: string, time2: string) {
    return new Date(time1).setMilliseconds(0) == new Date(time2).setMilliseconds(0);
}

// Return item's return time IF the item has a defined return time. Otherwise, if we know
// item is snoozed and the return time does not exist (ex: in inbox), we will rely on RoRT.
function getItemReturnTime(item: ClientItem, isSnoozed: boolean) {
    if (item.ReturnTime) {
        return item.ReturnTime;
    }

    if (isSnoozed && item.ReceivedOrRenewTime) {
        return item.ReceivedOrRenewTime;
    }

    return null;
}

// Return TCR's return time IF the TCR has a defined return time. Otherwise, if we know
// the TCR was snoozed and the return time does not exist (ex: in inbox), we will rely on LDoRT.
function getTableConversationRelationReturnTime(
    tableConversationRelation: MailRowDataPropertyGetter.TableViewConversationRelation,
    isSnoozed: boolean
) {
    return tableConversationRelation.returnTime
        ? tableConversationRelation.returnTime
        : isSnoozed
        ? tableConversationRelation.lastDeliveryOrRenewTimeStamp
        : null;
}

// Gets LastDeliveryTimestamp
function getLastDeliveryTimestamp(
    item: ClientItem,
    isDraftsFolder: boolean,
    isItemInSentItemsFolder: boolean
) {
    if (isDraftsFolder && item.LastModifiedTime) {
        return item.LastModifiedTime;
    }

    if (isItemInSentItemsFolder && item.DateTimeSent) {
        return item.DateTimeSent;
    }

    if (item.DateTimeReceived) {
        return item.DateTimeReceived;
    }

    return '';
}

// If user has selected a category node , we want to hide that category in the category list.
// This function removes a category given a category list.
function getCategoriesToShow(tableView: TableView, categories: string[] | undefined) {
    if (!categories) {
        return [];
    }

    const categoryName = getUserCategoryName(tableView);

    if (!categoryName) {
        return categories;
    }

    const filteredCategories = [...categories];
    const categoryNameIndex = filteredCategories.indexOf(categoryName);

    if (categoryNameIndex > -1) {
        filteredCategories.splice(categoryNameIndex, 1);
    }

    return filteredCategories;
}

function getThirdLineText(previewText: string, isNudged: boolean, rowKey: string) {
    return isNudged ? getNudgedReason(rowKey) : previewText;
}

/**
 * Determine, according to the rowKey, if for certain message list item
 * unsubscribe should be suggested.
 * @param rowId Message rowId
 * @param tableView Table view
 * @param mailboxInfo MailboxInfo of the selected account
 *
 * @returns Whether the item should show suggested unsubscribe component
 */
function shouldShowSuggestedUnsubscribe(
    rowId: string,
    tableView: TableView,
    mailboxInfo: MailboxInfo
): boolean {
    return (
        isConsumer(undefined /* smtpAddress */, mailboxInfo) &&
        folderIdToName(tableView.tableQuery.folderId) == 'inbox' &&
        isSuggestedUnsubscribeItem(rowId, mailboxInfo)
    );
}

function isItemOfAppointmentType(itemClass: string) {
    return (
        itemClass && itemClass.indexOf('IPM.Appointment') == 0 // check if null as well
    );
}

/**
 * This function determines if an item can be pinned. This check is required
 * on an item level for scenarios in which a subset of the rows can be pinned
 * and others cannot be (i.e. search).
 */
function canPin(
    tableView: TableView,
    tableSupportsPinning: boolean,
    parentFolderId: string
): boolean {
    /**
     * If the tableView isn't for search, then we can rely on whether or not
     * the table supports pinning. Additionally, we can early return if the
     * table itself doesn't support pinning at all.
     */
    if (tableView.tableQuery.type !== 1 || !tableSupportsPinning) {
        return tableSupportsPinning;
    }

    // Folders where pin is not supported
    const restrictedFoldersForPin = [
        'drafts',
        'sentitems',
        'clutter',
        'junkemail',
        'deleteditems',
        'archive',
        'notes',
        'recoverableitemsdeletions', // PRIMARY_DUMPSTER_DISTINGUISHED_ID
        'archiverecoverableitemsdeletions', // ARCHIVE_DUMPSTER_DISTINGUISHED_ID
    ];

    const parentFolderName = parentFolderId && folderIdToName(parentFolderId);
    for (const restrictedFolder of restrictedFoldersForPin) {
        if (parentFolderName === restrictedFolder) {
            return false;
        }
    }

    return true;
}

export default getMailListItemDataProps;
