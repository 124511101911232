import type { IButtonStyles } from '@fluentui/react';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import AlignCenter from 'owa-fluent-icons-svg/lib/icons/TextAlignCenterRegular';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { type Alignment } from 'roosterjs-editor-types/lib/enum/Alignment';
import {
    alignCenterText,
    alignLeftText,
    alignRightText,
    alignJustifyText,
} from '../formatControlMenuStrings.locstring.json';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { setAlignmentCommand } from 'owa-editor-command';
import AlignLeft from 'owa-fluent-icons-svg/lib/icons/TextAlignLeftRegular';
import AlignRight from 'owa-fluent-icons-svg/lib/icons/TextAlignRightRegular';
import AlignJustify from 'owa-fluent-icons-svg/lib/icons/TextAlignJustifyRegular';
import type { MenuSection } from '@1js/acui-menu/lib/components/MenuDefinition';
import type { ResourceId } from 'owa-localize';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';
import { getNonBorderFocusedStyle } from '../utils/nonBorderStyles';

interface AlignInfo {
    command: Alignment | 'justify';
    icon: string;
    text: ResourceId;
}

const AlignInfoMap: Map<FormatControlId, AlignInfo> = new Map<FormatControlId, AlignInfo>([
    [6017, { command: 0, icon: AlignLeft, text: alignLeftText }],
    [6018, { command: 1, icon: AlignCenter, text: alignCenterText }],
    [6019, { command: 2, icon: AlignRight, text: alignRightText }],
    [6110, { command: 'justify', icon: AlignJustify, text: alignJustifyText }],
]);

const getAlignControl = (
    controlId: FormatControlId,
    editorId: string,
    styles: IButtonStyles | undefined,
    tabId: number | undefined,
    isDisabled: boolean,
    source: FormattingSource
): RibbonControlProps => {
    return createAppButton(
        getComposeRibbonId(controlId, editorId),
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        loc(AlignInfoMap.get(controlId)!.text),
        getComputedCallback(
            controlId,
            setAlignmentCommand,
            editorId,
            /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
             * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
             *	> Forbidden non-null assertion. */
            AlignInfoMap.get(controlId)!.command,
            source
        ),
        tabId ? constructKeytip([tabId], controlId) : undefined,
        /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
         * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
         *	> Forbidden non-null assertion. */
        AlignInfoMap.get(controlId)!.icon,
        undefined /* iconColor */,
        styles,
        {
            disabled: isDisabled,
        }
    );
};

export const getAlignLeftControl = owaComputedFn(
    (
        editorId: string,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean,
        source: FormattingSource
    ): RibbonControlProps => {
        return getAlignControl(
            6017,
            editorId,
            getNonBorderFocusedStyle(styles),
            tabId,
            isDisabled,
            source
        );
    }
);

export const getAlignCenterControl = owaComputedFn(
    (
        editorId: string,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean,
        source: FormattingSource
    ): RibbonControlProps => {
        return getAlignControl(
            6018,
            editorId,
            getNonBorderFocusedStyle(styles),
            tabId,
            isDisabled,
            source
        );
    }
);

export const getAlignJustifyControl = owaComputedFn(
    (
        editorId: string,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean,
        source: FormattingSource
    ): RibbonControlProps => {
        return getAlignControl(
            6110,
            editorId,
            getNonBorderFocusedStyle(styles),
            tabId,
            isDisabled,
            source
        );
    }
);
export const getAlignRightControl = owaComputedFn(
    (
        editorId: string,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean,
        source: FormattingSource
    ): RibbonControlProps => {
        return getAlignControl(
            6019,
            editorId,
            getNonBorderFocusedStyle(styles),
            tabId,
            isDisabled,
            source
        );
    }
);

export default function getAlignSections(
    editorId: string,
    styles: IButtonStyles | undefined,
    tabId: number | undefined,
    isDisabled: boolean,
    source: FormattingSource,
    useContentModelEditor: boolean
): MenuSection[] {
    const controls = [
        getAlignLeftControl(editorId, styles, tabId, isDisabled, source),
        getAlignCenterControl(editorId, styles, tabId, isDisabled, source),
        getAlignRightControl(editorId, styles, tabId, isDisabled, source),
    ];

    if (useContentModelEditor) {
        controls.push(getAlignJustifyControl(editorId, styles, tabId, isDisabled, source));
    }

    return [{ controls }];
}
