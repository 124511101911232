import React from 'react';
import { errorThatWillCauseAlert } from 'owa-trace';

import { GovernPriority, lazyGovern } from 'owa-tti';
import {
    getDefaultIconPreviewGalleryRenderer,
    getDefaultRibbonButtonRenderer,
    getDefaultRibbonFlyoutAnchorRenderer,
    getDefaultSplitButtonRenderer,
} from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/ControlRenderers';
import {
    lazyGetAppCheckBoxControlRenderer,
    lazyGetAppComboBoxControlRenderer,
    lazyGetAppToggleButtonControlRenderer,
} from '../lazyFunctions';

import type { AppCheckboxProps } from '@1js/acui-checkbox/lib/components/AppCheckbox/AppCheckbox.Props';
import type { AppComboBoxProps } from '@1js/acui-combobox/lib/components/AppComboBox/AppComboBox.Props';
import type { AppControlUnionProps } from '@1js/acui-ribbon-like/lib/UISurfaces/controls/AppControlProps';
import type { AppToggleButtonProps } from '@1js/acui-button/lib/components/AppToggleButton/AppToggleButton.Props';
import type { ControlInMenuRendererFunction } from '@1js/acui-menu/lib/ControlInMenuRendererFunction';
import type { ControlRendererFunction } from '@1js/acui-ribbon-like/lib/UISurfaces/controls/controlRendererFunctionTypes';
import { appIcons } from 'owa-acui-theme';
import { memoizeFunction } from '@fluentui/react/lib/Utilities';

/**
 * Mail Ribbon shares a ribbon with Compose, even though Compose can be loaded later on in the app.
 * With this in mind, we implement a lazyGovern + lazyLoad strategy for the Compose-only control renderers
 * to lower the bundle size for MailBoot.
 */
interface ComposeControlRendererFunctions {
    appCheckboxControlRendererFunction: (appIcons: any) => (props: AppCheckboxProps) => JSX.Element;
    appComboboxControlRendererFunction: (appIcons: any) => (props: AppComboBoxProps) => JSX.Element;
    appToggleButtonControlRendererFunction: () => (props: AppToggleButtonProps) => JSX.Element;
}

// We default initialize the lazyComposeControlRenderers to empty functions.
// This does have the caveat that should a user immediately pop into a Compose-only scenario,
// they may see a flicker as the control renderers load in. Should that be the case,
// we can always increase the lazyGovern priority.
const composeControlRendererFunctions: ComposeControlRendererFunctions = {
    appCheckboxControlRendererFunction: () => () => <></>,
    appComboboxControlRendererFunction: () => () => <></>,
    appToggleButtonControlRendererFunction: () => () => <></>,
};
let composeControlRendererFunctionsInitialized: boolean = false;

/**
 * This function should contain the minimal set of control renderers for only the buttons
 * that show up on Ribbon surface.
 * Nothing in the dropdowns, just any type of control that the user could see on boot.
 */
export const getMailControlRendererFunction = memoizeFunction(
    (controlInMenuRendererFunction: ControlInMenuRendererFunction): ControlRendererFunction => {
        // Lazy load in the Compose-only control renderers.
        if (!composeControlRendererFunctionsInitialized) {
            lazyGovern.importAndExecute({
                task: () => {
                    lazyGetAppCheckBoxControlRenderer.import().then(checkBoxRendererFunction => {
                        composeControlRendererFunctions.appCheckboxControlRendererFunction =
                            checkBoxRendererFunction;
                    });
                    lazyGetAppComboBoxControlRenderer.import().then(comboBoxRendererFunction => {
                        composeControlRendererFunctions.appComboboxControlRendererFunction =
                            comboBoxRendererFunction;
                    });
                    lazyGetAppToggleButtonControlRenderer
                        .import()
                        .then(toggleButtonRendererFunction => {
                            composeControlRendererFunctions.appToggleButtonControlRendererFunction =
                                toggleButtonRendererFunction;
                        });
                    composeControlRendererFunctionsInitialized = true;
                },
                priority: GovernPriority.Compose,
            });
        }

        return (props: AppControlUnionProps) => {
            const { shouldTakeFocus = true, type } = props;

            switch (type) {
                case 'AppButtonProps':
                    return getDefaultRibbonButtonRenderer()({
                        ...props,
                        shouldTakeFocus,
                    });
                case 'AppComboBoxProps':
                    return composeControlRendererFunctions.appComboboxControlRendererFunction(
                        appIcons
                    )(props as AppComboBoxProps);
                case 'AppToggleButtonProps':
                    return composeControlRendererFunctions.appToggleButtonControlRendererFunction()(
                        {
                            ...(props as AppToggleButtonProps),
                            shouldTakeFocus,
                        }
                    );
                case 'AppCheckboxProps':
                    return composeControlRendererFunctions.appCheckboxControlRendererFunction(
                        appIcons
                    )(props);
                case 'AppFlyoutAnchorProps':
                    return getDefaultRibbonFlyoutAnchorRenderer(
                        appIcons,
                        controlInMenuRendererFunction
                    )(props);
                case 'AppSplitButtonProps':
                    return getDefaultSplitButtonRenderer(
                        appIcons,
                        controlInMenuRendererFunction
                    )({
                        ...props,
                        shouldTakeFocus,
                    });
                case 'AppIconPreviewGalleryProps':
                    return getDefaultIconPreviewGalleryRenderer(
                        appIcons,
                        controlInMenuRendererFunction
                    )({ menuLauncherLabel: '', ...props });

                default:
                    errorThatWillCauseAlert(
                        'getMailControlRendererFunction: Unknown control type: ' +
                            type +
                            '. This means this function is missing a control renderer for that specific control.'
                    );
                    return <></>;
            }
        };
    }
);
