// extracted by mini-css-extract-plugin
export var attendeePanelContainer = "GKoFv";
export var blockContainer = "cCzk7";
export var bottomPadding = "Rs0kg";
export var calendarCardV2Container = "Pzn2X";
export var compact = "ISg9w";
export var contentContainer = "Vm3e5";
export var flexContainer = "KD9CV";
export var full = "zMb9B";
export var isCollapsed = "ZeoUN";
export var isFocused = "RuX4h";
export var isItemAttachment = "ln63U";
export var isSpecialCaseCard = "xGhch";
export var itemContainerFirefox = "fkR4u";
export var medium = "cN8f4";
export var messageBody = "ulb23";
export var neutralPaletteBackground = "pinIo";
export var noOverlay = "GSQBG";
export var outsideCardWrapperContainer = "KbmhQ";
export var slide_up_fade_in = "RrmSj";
export var subject = "bwgAh";
export var subjectContainer = "ubHDG";
export var undoDarkMode = "J8bId";
export var undoDarkModeFocusedBorder = "qjzwX";