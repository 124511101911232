import React from 'react';
import { registerIcons } from '@fluentui/style-utilities';
import { indent, fontIcon } from './style.scss';

import classNames from 'owa-classnames';

export default 'IndentIcon';

registerIcons({
    icons: {
        IndentIcon: <span className={classNames(indent, fontIcon)}></span>,
    },
});
