import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import { senderGroupingsActionButtonTooltipText } from './senderGroupings.locstring.json';
import React from 'react';
import { MailListActionButton } from 'owa-mail-list-item-components';
import { PropertyIcons } from 'owa-mail-list-actions';
import { logUsage } from 'owa-analytics';
import { lazyFindEmailFromSender } from 'owa-mail-search';
import type SingleRecipientType from 'owa-service/lib/contract/SingleRecipientType';
import type { MailboxInfo } from 'owa-client-types';

export interface SenderGroupingsActionButtonProps {
    lastSender: SingleRecipientType;
    isMailListItemHovered: boolean;
    isMailListItemSelected: boolean;
    mailboxInfo: MailboxInfo;
}

export default observer(function SenderGroupingsActionButton(
    props: SenderGroupingsActionButtonProps
) {
    React.useEffect(() => {
        logUsage('SenderGroupingsActionButtonRendered');
    }, []);

    const onClick = React.useCallback(
        (ev?: React.MouseEvent<unknown>) => {
            // Stop click so it doesn't get acted on by MailListItem and mark the item as read
            ev?.stopPropagation();

            if (!props.lastSender.Mailbox?.Name || !props.lastSender.Mailbox?.EmailAddress) {
                return;
            }
            lazyFindEmailFromSender.importAndExecute(
                props.lastSender.Mailbox.Name,
                props.lastSender.Mailbox.EmailAddress,
                props.mailboxInfo,
                'FindEmailFromSenderGroupings'
            );
        },
        [props.lastSender, props.mailboxInfo]
    );

    return (
        <MailListActionButton
            itemClassIcon={PropertyIcons.SenderGroupings}
            descriptionText={loc(senderGroupingsActionButtonTooltipText)}
            onClick={onClick}
            isMailListItemHovered={props.isMailListItemHovered}
            isMailListItemSelected={props.isMailListItemSelected}
        />
    );
},
'SenderGroupingsActionButton');
