export const help_tab = "$JLmCb";
export const help_Button = "Wqa6Yc";
export const support_Button = "Quk6ge";
export const feedbackOcv_Button = "aUsvve";
export const rolloutOverrides_Button = "bNDVLd";
export const diagnostics_Button = "B$EVb";
export const qrCode_Button = "dWnS9";
export const switchToClassicOutlook_Button = "Jwi$Nb";
export const helpButtonDesc = "SK0iJ";
export const contactSupportButtonDesc = "QSmgJc";
export const feedbackButtonDesc = "YrJtLb";
export const rolloutOverridesButtonDesc = "rStjr";
export const getDiagnosticsButtonDesc = "NBe5Ec";
export const qrCodeButtonDesc = "RzdJgb";
export const switchToClassicOutlookButtonDesc = "pn6W1d";
export default {help_tab,help_Button,support_Button,feedbackOcv_Button,rolloutOverrides_Button,diagnostics_Button,qrCode_Button,switchToClassicOutlook_Button,helpButtonDesc,contactSupportButtonDesc,feedbackButtonDesc,rolloutOverridesButtonDesc,getDiagnosticsButtonDesc,qrCodeButtonDesc,switchToClassicOutlookButtonDesc};