import type { TopicsSDK } from '@1js/cortex-topics-bootstrapper';
import { tryGetTopicsSdk as realTryGetTopicsSdk } from '@1js/cortex-topics-bootstrapper';
import { getApplicationSettings } from 'owa-application-settings';
import type { MailboxInfo } from 'owa-client-types';
import getAccountInfo from 'owa-topic-annotations/lib/utils/getAccountInfo';
import { isTopicsMultiAccountEnabled } from './TopicsFeatureGate';
import getTopicsSdkAsync from './getTopicsSdkAsync';

/**
 * Returns the Topics SDK if it's been initialized.
 * If it hasn't been initialized, it will trigger the initialization.
 */
export function tryGetTopicsSdk(mailboxInfo: MailboxInfo): TopicsSDK | undefined {
    // The application setting for loadSDK is set to false in AirGap, so that we
    // don't load the SDK there.
    if (!getApplicationSettings('Topics').loadSDK) {
        return undefined;
    }

    let topicsSdk;
    if (isTopicsMultiAccountEnabled()) {
        topicsSdk = realTryGetTopicsSdk(getAccountInfo(mailboxInfo));
    } else {
        topicsSdk = realTryGetTopicsSdk();
    }

    if (!topicsSdk) {
        getTopicsSdkAsync(mailboxInfo).catch(() => {
            /* no-op */
        });
    }

    return topicsSdk;
}
