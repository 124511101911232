import type { FluidPlugin } from './plugin/FluidPlugin';
import type { VideoClipsTeachingBubblePlugin } from './plugin/VideoClipsTeachingBubblePlugin';
import { preloadFluid, preloadFluidScripts } from 'owa-fluid-tenantconfig';
import type { LazyPlugin } from 'owa-editor-lazy-plugin/lib/utils/createLazyPlugin';
import createLazyPlugin from 'owa-editor-lazy-plugin/lib/utils/createLazyPlugin';
import { LazyModule, LazyImport, LazyAction, registerLazyOrchestrator } from 'owa-bundling';
import { type PluginEventType } from 'roosterjs-editor-types/lib/enum/PluginEventType';
import type PluginNames from 'owa-editor-lazy-plugin/lib/utils/PluginNames';

const lazyModule = new LazyModule(() => import(/* webpackChunkName: "Fluid" */ './lazyIndex'), {
    name: 'Fluid',
});

export type LazyFluidPlugin = LazyPlugin<FluidPlugin>;
export const LazyFluidPluginClass = createLazyPlugin(
    lazyModule,
    m => m.FluidPlugin,
    'Fluid',
    event => event.eventType == 11
);

export type LazyVideoClipsTeachingBubblePlugin = LazyPlugin<VideoClipsTeachingBubblePlugin>;
export const LazyVideoClipsTeachingBubbleClass = createLazyPlugin(
    lazyModule,
    m => m.VideoClipsTeachingBubblePlugin,
    'VideoClipsTeachingBubble',
    event => event.eventType == 3 || event.eventType == 7
);

export const lazyMountFluidComponent = new LazyImport(
    lazyModule,
    m => m.mountExistingFluidComponent
);

export const lazyTryGetFluidPreCheckInfo = new LazyImport(
    lazyModule,
    m => m.tryGetFluidPreCheckInfo
);

export const lazyTryGetHrefFromElement = new LazyImport(lazyModule, m => m.tryGetHrefFromElement);

export const lazyCreateNewFluidComponent = new LazyImport(
    lazyModule,
    m => m.createNewFluidComponent
);

export const lazyGetAgendaCodeDetails = new LazyImport(lazyModule, m => m.getAgendaCodeDetails);

export const lazyGetFluidMenuItems = new LazyAction(lazyModule, m => m.getFluidMenuItems);

export const lazyStartRecorder = new LazyAction(lazyModule, m => m.startRecorder);

export const lazyCreateFluidForms = new LazyAction(lazyModule, m => m.createFluidForms);

export const lazyIsLoopUrl = new LazyAction(lazyModule, m => m.isLoopUrl);
export const lazyIsLoopUrlAsync = new LazyAction(lazyModule, m => m.isLoopUrlAsync);

export const lazyProviderCallbacksStore = new LazyImport(
    lazyModule,
    m => m.LoopProviderCallbackStore
);

export const lazyGetGenericLoaderManifest = new LazyImport(
    lazyModule,
    m => m.getGenericLoaderManifest
);

// Lazy orchestrators
// Used to prefetch scripts, tokens, and the discover manifest (for create new).
registerLazyOrchestrator(preloadFluid, lazyModule, m => m.preloadFluidOrchestrator);
registerLazyOrchestrator(preloadFluidScripts, lazyModule, m => m.preloadFluidScriptsOrchestrator);

export const lazyPreloadFluid = new LazyAction(lazyModule, m => m.preLoadFluidFunction);
export const lazyPreloadFluidInCollabSpace = new LazyAction(lazyModule, m => m.preloadFluidScripts);

export const lazyIsPreloadFluidInCollabSpaceComplete = new LazyAction(
    lazyModule,
    m => m.isPreloadFluidInCollabSpaceComplete
);

export type {
    FluidContainerConfigForEditor,
    FluidContainerConfigForDiv,
} from './store/schema/FluidContainerConfig';
export type {
    NewFluidContainerConfigForEditor,
    NewFluidContainerConfigForDiv,
} from './store/schema/NewFluidContainerConfig';
export type { FluidCallbackProps } from './store/schema/FluidCallbackProps';
export type { LoopProviderCallbacks } from './store/schema/FluidRenderProps';
export type { FluidRenderCustomizationProps } from './store/schema/FluidRenderCustomizationProps';

export { onFluidComponentRendered } from './actions/publicActions';
export {
    FLUID_HERO_MENU_ID,
    FLUID_CONTAINER,
    FLUID_ENTITY_TYPE,
    VIDEO_RECORDER_SVG_ID,
    LoopStyle,
} from './utils/constants';
export { checkIsInsideTable } from './utils/checkIsInsideTable';
export type { RibbonFluidMenuInfo } from './store/schema/RibbonFluidMenuInfo';
export type { GetFluidMenuItemsFunc } from './utils/createNewLoopUtils/getFluidMenuItems';
