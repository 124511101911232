import { ObservableMap } from 'mobx';
import type { MailboxInfo } from 'owa-client-types';
import { getIndexerValueForMailboxInfo } from 'owa-client-types';
import { getGroupSiteStatusStore } from '../GroupSiteStatusStore';
import type { GroupSiteStatusInformation } from '../schema/GroupSiteStatusInformation';
import type GroupSiteStatus from 'owa-groups-sharepoint-commands/lib/schema/GroupSiteStatus';

export function getGroupSiteStatusMap(mailboxInfo: MailboxInfo) {
    const index = getIndexerValueForMailboxInfo(mailboxInfo);
    const groupSiteStatusForThisMailboxinInfo =
        getGroupSiteStatusStore().groupSiteStatusByMailboxInfo.get(index);

    if (!groupSiteStatusForThisMailboxinInfo) {
        const defaultGroupSiteStatus: GroupSiteStatusInformation = {
            groupSiteStatus: new ObservableMap<string, GroupSiteStatus>(),
        };
        getGroupSiteStatusStore().groupSiteStatusByMailboxInfo.set(index, defaultGroupSiteStatus);
        return defaultGroupSiteStatus.groupSiteStatus;
    }

    return groupSiteStatusForThisMailboxinInfo.groupSiteStatus;
}

export function getGroupSiteStatus(mailboxInfo: MailboxInfo, groupSmtp: string) {
    return getGroupSiteStatusMap(mailboxInfo).get(groupSmtp.toLowerCase());
}
