import type { AttachmentWellViewState } from 'owa-attachment-well-data';
import getConversationReadingPaneViewState from '../utils/getConversationReadingPaneViewState';
import mailStore from 'owa-mail-store/lib/store/Store';
import type Message from 'owa-service/lib/contract/Message';
import { getAttachmentWellViewStateByItemIdHelper } from 'owa-sxs-store/lib/utils/getAttachmentWellViewStateByItemIdHelper';

// get the attachmentWellViewState from itemId, where itemId is the unqiue identifier for each conversation in the conversation reading pane
function getAttachmentWellViewStateByItemId(
    conversationId: string,
    itemId: string
): AttachmentWellViewState {
    const message: Message = mailStore.items.get(itemId) as Message;
    if (!!message) {
        const nodeId = message.InternetMessageId;
        const itemPart = getConversationReadingPaneViewState(conversationId)?.itemPartsMap.get(
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2345 (19,13): Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
            // @ts-expect-error
            nodeId
        );
        // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
        // -> Error TS2322 (24,9): Type 'AttachmentWellViewState | null' is not assignable to type 'AttachmentWellViewState'.
        // @ts-expect-error
        return !!itemPart ? itemPart.attachmentWell : null;
    }
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (29,5): Type 'null' is not assignable to type 'AttachmentWellViewState'.
    // @ts-expect-error
    return null;
}

export default function registerGetAttachmentWellViewStateByItemHelper() {
    getAttachmentWellViewStateByItemIdHelper.register(getAttachmentWellViewStateByItemId);
}
