import React from 'react';
import { registerIcons } from '@fluentui/style-utilities';
import { editor, fontIcon } from './styles.scss';

import classNames from 'owa-classnames';

export default 'EditorRegular';

registerIcons({
    icons: {
        EditorRegular: <span className={classNames(editor, fontIcon)}></span>,
    },
});
