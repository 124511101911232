import { LazyModule, LazyAction } from 'owa-bundling';

const lazyFunctionsModule = new LazyModule(
    () => import(/* webpackChunkName: "OwaPersonaOffline" */ './lazyIndexFunctions'),
    {
        name: 'OwaPersonaOffline',
    }
);

export const lazyEnhanceLpcConfigForOffline = new LazyAction(
    lazyFunctionsModule,
    m => m.enhanceLpcConfigForOffline
);

export const lazyMakeOfflineEnhancedLpeOnCloseCallback = new LazyAction(
    lazyFunctionsModule,
    m => m.makeOfflineEnhancedLpeOnCloseCallback
);

export const lazyGetLPCAccountCallbacks = new LazyAction(
    lazyFunctionsModule,
    m => m.getLPCAccountCallbacks
);

export const lazyLpcV2CallbacksEnhancer = new LazyAction(
    lazyFunctionsModule,
    m => m.lpcV2CallbacksEnhancer
);

export const lazyIsCAPIv3Enabled = new LazyAction(lazyFunctionsModule, m => m.isCAPIv3Enabled);

export const lazyMakeAddOrEditContactV3Callback = new LazyAction(
    lazyFunctionsModule,
    m => m.makeAddOrEditContactV3Callback
);

export const lazyCheckAndInitializeLivePersonaEditor = new LazyAction(
    lazyFunctionsModule,
    m => m.checkAndInitializeLivePersonaEditor
);
