import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import type { RibbonGroupId } from 'owa-ribbon-ids';
import type { RibbonFlyoutAnchorProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon';
import { owaComputedFn } from 'owa-computed-fn';

export const getMLRPictureFormatTabCommonGroupFlyout = owaComputedFn(
    function getMLRPictureFormatTabCommonGroupFlyout(
        _group: RibbonGroupId,
        _props: ComposeRibbonControlDefProps
    ): RibbonFlyoutAnchorProps | undefined {
        //Picture tab has no commonGroupFlyouts
        return undefined;
    }
);
