import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "ReadingPaneTabs"*/ './lazyIndex')
);

export const lazyEnsureSecondaryReadingPaneTabHandler = new LazyAction(
    lazyModule,
    m => m.ensureSecondaryReadingPaneTabHandler
);

export { reloadMailReadingPanePopoutAsDeeplink } from './utils/reloadMailReadingPanePopoutAsDeeplink';
export const lazyMoveReadingPaneToTab = new LazyAction(lazyModule, m => m.moveReadingPaneToTab);
