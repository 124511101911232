import type { AppCheckboxProps } from '@1js/acui-checkbox';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppCheckBox } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import loc from 'owa-localize';
import setIsDeliveryReceiptRequested from 'owa-mail-compose-actions/lib/actions/setIsDeliveryReceiptRequested';
import setIsReadReceiptRequested from 'owa-mail-compose-actions/lib/actions/setIsReadReceiptRequested';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import {
    deliveryReceiptText,
    readReceiptText,
} from '../strings/actionControlStrings.locstring.json';
import { deliveryReceiptDesc, readReceiptDesc } from '../strings/hoverTooltipDesc.locstring.json';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import getCheckboxRootStyles from '../utils/getCheckboxRootStyles';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';

const onReceiptCheck = (
    composeId: string,
    retriever: (composeId: string) => RibbonMailComposeInfo | null,
    isReadReceipt: boolean
) => {
    const editingInfo = retriever(composeId);
    if (editingInfo) {
        const viewState = editingInfo.composeViewState;
        if (isReadReceipt) {
            setIsReadReceiptRequested(viewState, !viewState.isReadReceiptRequested);
        } else {
            setIsDeliveryReceiptRequested(viewState, !viewState.isDeliveryReceiptRequested);
        }
    }
};

export const getReadOrDeliveryReceipt = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonMailComposeInfo | null,
        styles: IButtonStyles | undefined,
        keyTipTabId: number | undefined,
        isReadReceipt: boolean,
        isDisabled: boolean
    ): AppCheckboxProps => {
        const composeViewState = retriever(composeId)?.composeViewState;
        const isChecked: boolean = !!composeViewState
            ? isReadReceipt
                ? composeViewState.isReadReceiptRequested
                : composeViewState.isDeliveryReceiptRequested
            : false;
        const controlId = isReadReceipt ? 7014 : 7015;
        const controlText = isReadReceipt ? readReceiptText : deliveryReceiptText;
        const controlDesc = isReadReceipt ? readReceiptDesc : deliveryReceiptDesc;
        return createAppCheckBox(
            getComposeRibbonId(controlId, editorId),
            loc(controlText),
            getComputedCallback(controlId, onReceiptCheck, composeId, retriever, isReadReceipt),
            isChecked,
            keyTipTabId ? constructKeytip([keyTipTabId], controlId) : undefined,
            {
                ...styles,
                root: getCheckboxRootStyles(),
            },
            {
                customTooltip: getHoverTooltip(controlText, controlDesc),
                disabled: isDisabled,
            }
        );
    }
);
