import { createLazyComponent, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "SenderGroupingsHeader" */ './lazyIndex')
);

// Delay loaded components
export const SenderGroupingsHeader = createLazyComponent(lazyModule, m => m.SenderGroupingsHeader);

// Utils
export { default as shouldShowSenderGroupingsHeader } from './utils/shouldShowSenderGroupingsHeader';
