import type { CalendarCardViewState } from '../store/schema/CalendarCardViewState';
import {
    type ResponseEditorState,
    CalendarCardPivot,
    CalendarCardContentWidth,
} from '../store/schema/CalendarCardViewState';

export default function getInitialViewState(): CalendarCardViewState {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2352 (12,12): Conversion of type '{ latestMeetingMessageId: null; latestRequestId: null; latestCancellationId: null; eventId: null; event: null; removedFromCalendar: false; responseType: "Unknown"; responseEditorState: ResponseEditorState.Closed; ... 7 more ...; eventViewedLogged: false; }' to type 'CalendarCardViewState' may be a mistake because neither type sufficiently overlaps with the other. If this was intentional, convert the expression to 'unknown' first.
    // @ts-expect-error
    return {
        latestMeetingMessageId: null,
        latestRequestId: null,
        latestCancellationId: null,
        eventId: null,
        event: null,
        removedFromCalendar: false,
        responseType: 'Unknown',
        responseEditorState: 0,
        emailOrganizer: true,
        editorViewState: null,
        activePivot: CalendarCardPivot.Messages,
        attachmentWell: null,
        contentWidth: CalendarCardContentWidth.Unset,
        unreadMessageCount: 0,
        eventViewedLogged: false,
        rsvpToOrganizerWithNote: false,
    } as CalendarCardViewState;
}
