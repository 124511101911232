import { paused } from './FolderNode.locstring.json';
import loc from 'owa-localize';
import { computed, makeObservable } from 'mobx';
import { observer } from 'owa-mobx-react';
import { selectFolder, lazyToggleFavoriteFolder } from 'owa-mail-folder-forest-actions';
import { getSelectedNode } from 'owa-mail-folder-forest-store';
import isArchiveFolder from 'owa-mail-folder-store/lib/utils/isArchiveFolder';
import type KeyboardCharCodes from 'owa-hotkeys/lib/utils/keyboardCharCodes';
import type { MailFolder, FolderForestTreeType } from 'owa-graph-schema';
import type DistinguishedFolderIdName from 'owa-service/lib/contract/DistinguishedFolderIdName';
import { isFolderInFavorites } from 'owa-favorites';

import type { ChevronProps } from 'owa-tree-node/lib/components/TreeNode';
import TreeNode from 'owa-tree-node/lib/components/TreeNode';
import React from 'react';
import { default as viewStateStore } from 'owa-mail-folder-store/lib/store/store';

import { type FolderForestNodeType } from 'owa-favorites-types';
import { isFolderPausedInbox } from 'owa-mail-list-store';
import type { ITouchHandlerParams } from 'owa-touch-handler';
import { touchHandler } from 'owa-touch-handler';
import { BulkActionSpinner, isBulkActionRunning } from 'owa-bulk-action-store';
import { UnreadReadCountBadge } from 'owa-unreadread-count-badge';
import { isFeatureEnabled } from 'owa-feature-flags';
import { buildUnreadTotalCountTooltip } from 'owa-folders';
import { SEARCH_FOLDER_ROOT_DISTINGUISHED_ID, SourceMap } from 'owa-folders-constants';
import toggleFolderNodeExpansion from 'owa-mail-folder-store/lib/actions/toggleFolderNodeExpansion';
import { Spinner, SpinnerSize } from '@fluentui/react/lib/Spinner';

// Folders that should show total count instead of unread count
const FOLDERS_TO_SHOW_TOTALCOUNT: DistinguishedFolderIdName[] = [
    'drafts',
    'junkemail',
    'outbox',
    'notes',
    'scheduled',
];

export interface FolderNodeProps extends React.HTMLProps<HTMLDivElement> {
    displayName: string;
    folderId: string;
    treeType: FolderForestTreeType;
    distinguishedFolderId: string;
    totalCount: number;
    unreadCount: number;

    // Optional parameters
    chevronProps?: ChevronProps;
    customIcon?: string;
    depth?: number; // root is 0, every sub node increase this number by 1.
    isBeingDragged?: boolean;
    isDroppedOver?: boolean;
    onContextMenu?: (evt: React.MouseEvent<any>, folder?: MailFolder) => void;
    showHoverStateOnDroppedOver?: boolean;
    shouldHideToggleFavorite?: boolean;
    isPublicFolder?: boolean;
    isLoading?: boolean;
    deleteFolder?: () => void;
    hideIcon?: boolean;
    ellipsesOnHover?: boolean;
}

class FolderNode extends React.Component<FolderNodeProps, {}> {
    constructor(props: FolderNodeProps) {
        super(props);

        makeObservable(this, {
            isSelected: computed,
            isWithContextMenuOpen: computed,
            touchHandler: computed,
            isSearchFolderRoot: computed,
        });
    }

    get isSelected(): boolean {
        const selectedNode = getSelectedNode();
        return (
            this.props.treeType == selectedNode.treeType && this.props.folderId == selectedNode.id
        );
    }

    get isWithContextMenuOpen(): boolean {
        return this.props.folderId == viewStateStore.withContextMenuFolderId;
    }

    get touchHandler() {
        const touchHandlerParams: ITouchHandlerParams = {
            onLongPress: this.props.onContextMenu,
        };
        return touchHandler(touchHandlerParams);
    }

    get isSearchFolderRoot(): boolean {
        return this.props.distinguishedFolderId == SEARCH_FOLDER_ROOT_DISTINGUISHED_ID;
    }

    render() {
        return (
            <TreeNode
                {...this.touchHandler}
                chevronProps={this.props.chevronProps}
                customIcon={this.props.customIcon}
                depth={this.props.depth}
                displayName={this.props.displayName}
                isBeingDragged={this.props.isBeingDragged}
                isDroppedOver={this.props.isDroppedOver}
                isRootNode={false}
                isSelected={this.isSelected}
                isWithContextMenuOpen={this.isWithContextMenuOpen}
                key={this.props.folderId}
                onKeyDown={this.onKeyDown}
                onClick={this.onClick}
                onContextMenu={this.props.onContextMenu}
                renderRightCharm={this.renderRightCharm}
                showAsHoverOnDroppedOver={this.props.showHoverStateOnDroppedOver}
                isFavorited={isFolderInFavorites(this.props.folderId)}
                toggleFavorite={this.toggleFavorite}
                isinSharedFolderTree={this.props.treeType == 'sharedFolderTree'}
                isDisabled={this.props.isLoading}
                customTreeNodeTooltip={
                    this.isSearchFolderRoot
                        ? undefined
                        : buildUnreadTotalCountTooltip(
                              this.props.displayName,
                              this.props.totalCount,
                              this.props.unreadCount
                          )
                }
                hideIcon={this.props.hideIcon}
                ellipsesOnHover={this.props.ellipsesOnHover}
                source={SourceMap.subfolder}
            />
        );
    }

    private renderRightCharm = (): JSX.Element | null => {
        const { folderId } = this.props;

        if (this.props.isLoading) {
            return <Spinner size={SpinnerSize.small} />;
        }

        if (isFolderPausedInbox(folderId)) {
            return renderPausedIndicator();
        }

        if (isBulkActionRunning(folderId)) {
            return <BulkActionSpinner folderId={folderId} folderName={this.props.displayName} />;
        }

        if (!isArchiveFolder(folderId) || isFeatureEnabled('tri-showArchiveUnreadCount')) {
            return this.renderUnreadOrTotalCount();
        }

        return null;
    };

    private renderUnreadOrTotalCount = (): JSX.Element | null => {
        const shouldDisplayTotalCount =
            FOLDERS_TO_SHOW_TOTALCOUNT.indexOf(
                this.props.distinguishedFolderId as DistinguishedFolderIdName
            ) >= 0 && this.props.totalCount > 0;
        const count = shouldDisplayTotalCount ? this.props.totalCount : this.props.unreadCount;

        return count ? (
            <UnreadReadCountBadge
                count={count}
                shouldDisplayTotalCount={shouldDisplayTotalCount}
                isSelected={this.isSelected}
            />
        ) : null;
    };

    private onClick = (evt: React.MouseEvent<unknown>) => {
        evt.stopPropagation();
        // The Search Folders node toggles its subtree expansion state instead of selecting the folder
        if (this.isSearchFolderRoot) {
            // If the user has no search folders, pull up the create search folder dialog
            if (!this.props.chevronProps) {
                this.props.onContextMenu?.(evt);
            }
            toggleFolderNodeExpansion(this.props.folderId);
        } else {
            /**
             * selectFolder, even if it's the same folder users are already selected on
             * the action has several side effects that users are counting on, including that
             * if users are in immersive reader view, the reading pane is closed
             */
            selectFolder(
                this.props.folderId,
                this.props.treeType,
                'FolderNodeClick' /* actionSource */,
                this.props.isPublicFolder ? 5 : 0,
                undefined,
                evt.nativeEvent.timeStamp
            );
        }
    };

    private onKeyDown = (evt: React.KeyboardEvent<unknown>) => {
        switch (evt.keyCode) {
            case 13:
            case 32:
                evt.stopPropagation();

                // explicity cancelling event so click won't fire after this in case when folder pane is opened as overlay.
                // only stopPropagation is not enough
                evt.preventDefault();
                selectFolder(
                    this.props.folderId,
                    this.props.treeType,
                    'Keyboard' /* actionSource */,
                    undefined,
                    undefined,
                    evt.nativeEvent.timeStamp
                );
                break;
            case 46:
                this.props.deleteFolder?.();
                break;
        }
    };

    private toggleFavorite = () => {
        const isFavorite = isFolderInFavorites(this.props.folderId);
        lazyToggleFavoriteFolder.importAndExecute(this.props.folderId, isFavorite, 'ContextFolder');
    };
}

function renderPausedIndicator(): JSX.Element {
    return <>{loc(paused)}</>;
}

export default observer(FolderNode);
