import { isFeatureEnabled } from 'owa-feature-flags';

export function isDexLitePaneEnabled() {
    return isDexPaneFeatureEnabled();
}

export function isEditorIndicatorEnabled() {
    return isDexPaneFeatureEnabled();
}

export function isCritiqueFilteringEnabled() {
    return isDexPaneFeatureEnabled();
}

export function isSharedSettingsEnabled() {
    return isDexPaneFeatureEnabled();
}

export function isSharedSettingsUxEnabled() {
    return isDexPaneFeatureEnabled();
}

function isDexPaneFeatureEnabled() {
    return isFeatureEnabled('mc-officeEditorProofingMasterFlight');
}
