import React from 'react';
import { CommandBarButton } from '@fluentui/react/lib/Button';
import LineHorizontal3 from 'owa-fluent-icons-svg/lib/icons/LineHorizontal3Regular';
import Strings from '../strings/toggleLeftPaneButton.locstring.json';
import { TooltipHost } from '@fluentui/react/lib/Tooltip';
import loc from 'owa-localize';
import { observer } from 'owa-mobx-react';
import { toggleButton, toggleButtonIcon } from './ExtraTopRibbonControlsBeforeTabs.scss';

import { getPalette } from 'owa-theme';

interface ExtraTopRibbonControlsBeforeTabsProps {
    onClick: () => void;
    isExpanded: boolean;
}

const defaultIconProps = {
    iconName: LineHorizontal3,
};

export const ExtraTopRibbonControlsBeforeTabs = observer(function ExtraTopRibbonControlsBeforeTabs(
    props: ExtraTopRibbonControlsBeforeTabsProps
) {
    const { onClick, isExpanded } = props;
    const displayText: string = isExpanded
        ? loc(Strings.hideLeftPaneButton)
        : loc(Strings.showLeftPaneButton);

    const palette = getPalette();

    const defaultToggleButtonButtonStyles = React.useMemo(
        () => ({
            root: toggleButton,
            icon: toggleButtonIcon,
            iconHovered: { color: palette.neutralDark },
        }),
        [palette]
    );

    return (
        <TooltipHost content={displayText} key="ToggleButton_ExtraTopRibbonControlsBeforeTabs">
            <CommandBarButton
                aria-label={displayText}
                iconProps={defaultIconProps}
                styles={defaultToggleButtonButtonStyles}
                onClick={onClick}
            />
        </TooltipHost>
    );
},
'ExtraTopRibbonControlsBeforeTabs');
