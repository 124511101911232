import { FluentButton } from 'owa-fluent-v9-shims';
import React from 'react';
import loc from 'owa-localize';
import { closeImmersiveButtonLabel } from './ImmersiveExitButton.locstring.json';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - Migrating deprecated icons: https://outlookweb.visualstudio.com/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9297/Migrating-deprecated-icons-in-client-web
 *	> 'ControlIcons' import from owa-control-icons is restricted. Use JSX icons from @fluentui/react-icons, otherwise from owa-fluent-icons-svg if they can only be reference by string. */
import { type ControlIcons } from 'owa-control-icons';
import closeImmersiveReadingPane from 'owa-mail-actions/lib/closeImmersiveReadingPane';
import { getActiveContentTab, TabType } from 'owa-tab-store';
import classNames from 'owa-classnames';
import { exitButton, textButton, exitButtonIcon } from './ImmersiveExitButton.scss';

export interface ImmersiveExitButtonProps {
    iconButtonContainer: string;
    iconButtonStyle?: string;
    shouldUseTextButton?: boolean;
    onlyUseIconButtonStyle?: boolean;
    extraButtonStyles?: string;
}

export function ImmersiveExitButton(props: ImmersiveExitButtonProps) {
    const {
        iconButtonStyle,
        iconButtonContainer,
        shouldUseTextButton,
        onlyUseIconButtonStyle,
        extraButtonStyles,
    } = props;

    const iconPropsStyle =
        shouldUseTextButton || onlyUseIconButtonStyle ? props.iconButtonStyle : exitButtonIcon;
    const iconProps = React.useMemo(
        () => ({
            iconName: 'Cancel',
            className: iconPropsStyle,
        }),
        [iconPropsStyle]
    );

    const buttonText = shouldUseTextButton ? loc(closeImmersiveButtonLabel) : undefined;
    return getActiveContentTab()?.type != TabType.SecondaryReadingPane ? (
        <div className={iconButtonContainer} id="immersiveExitButton">
            <FluentButton
                appearance={shouldUseTextButton ? 'command' : 'icon'}
                iconProps={iconProps}
                title={loc(closeImmersiveButtonLabel)}
                aria-label={loc(closeImmersiveButtonLabel)}
                onClick={onCloseButtonClick}
                className={classNames(
                    exitButton,
                    shouldUseTextButton && textButton,
                    iconButtonStyle,
                    extraButtonStyles
                )}
            >
                {buttonText}
            </FluentButton>
        </div>
    ) : null;
}

export function onCloseButtonClick() {
    closeImmersiveReadingPane('ImmersiveRPCloseButton');
}
