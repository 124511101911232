import getAllItemPartsShownInConversation from './getAllItemPartsShownInConversation';
import type { ClientItem } from 'owa-mail-store';
import mailStore from 'owa-mail-store/lib/store/Store';
import type ConversationReadingPaneViewState from '../store/schema/ConversationReadingPaneViewState';
import type ItemPartViewState from '../store/schema/ItemPartViewState';

export default function getAllItemsShownInConversation(
    conversationViewState: ConversationReadingPaneViewState,
    getNewestItemFirst: boolean
): ClientItem[] | null {
    const itemParts = getAllItemPartsShownInConversation(conversationViewState, getNewestItemFirst);
    if (!itemParts) {
        return null;
    }

    const itemIds = itemParts.map((itemPart: ItemPartViewState) =>
        itemPart ? itemPart.itemId : null
    );

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (23,5): Type '(ClientItem | undefined)[]' is not assignable to type 'ClientItem[]'.
    // @ts-expect-error
    return itemIds.map(itemId => mailStore.items.get(itemId ?? '')).filter(item => !!item);
}
