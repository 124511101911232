import type { RibbonTabDefinitionBase } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import getMLRComposeTab from '../../../../util/getMLRComposeTab';
import { owaComputedFn } from 'owa-computed-fn';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { getMLRPictureFormatTabCommonGroupFlyout } from './getMLRPictureFormatTabCommonGroupFlyout';
import { getMLRPictureFormatTabGroupLayouts } from './getMLRPictureFormatGroupLayouts';

const getMLRPictureFormatTab = owaComputedFn(function getMLRPictureFormatTab(
    props: ComposeRibbonControlDefProps
): RibbonTabDefinitionBase {
    return getMLRComposeTab(
        props,
        13,
        [] /*controlsWithoutLabels*/,
        getMLRPictureFormatTabGroupLayouts,
        getMLRPictureFormatTabCommonGroupFlyout
    );
});

export default getMLRPictureFormatTab;
