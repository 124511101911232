/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';
import type { ActionSource } from 'owa-mail-store';
import { composeStore } from 'owa-mail-compose-store';
import { getMailboxInfo } from 'owa-mail-mailboxinfo';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline. Do not copy and paste"
 *	> '../index' import is restricted from being used. */
import { lazyNewMessage } from '../index';
import { shouldPopoutNewMessage } from '../utils/shouldPopoutNewMessage';

// VSO 29687: Check if a new message is in the process of being created,
// and if not (or complete), create a new message
export default action('checkAndCreateNewMessage')(async function checkAndCreateNewMessage(
    actionSource: ActionSource,
    targetId: string | null,
    eventTimestamp?: number
) {
    if (!composeStore.newMessageCreationInProgress) {
        composeStore.newMessageCreationInProgress = true;
        const shouldPopout = await shouldPopoutNewMessage();
        try {
            return lazyNewMessage.importAndExecute(actionSource, {
                mailboxInfo: targetId ? getMailboxInfo(undefined) : undefined,
                targetId: targetId ?? undefined,
                isPopout: shouldPopout,
                eventTimestamp,
            });
        } finally {
            composeStore.newMessageCreationInProgress = false;
        }
    }

    return Promise.resolve();
});
