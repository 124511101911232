import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import { lazyShowInsertLinkDialog } from 'owa-editor-ribbonplugin';
import { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';

export const onShowLinkDialogClicked = (
    composeId: string,
    retriever: (composeId: string) => RibbonMailComposeInfo | null
) => {
    lazyShowInsertLinkDialog.import().then(showInsertLinkDialog => {
        const editingInfo = retriever(composeId);
        if (editingInfo) {
            showInsertLinkDialog(
                editingInfo.composeViewState,
                editingInfo.targetWindow,
                FormattingSource.Ribbon
            );
        }
    });
};
