import { LazyModule, LazyImport } from 'owa-bundling';
import wrapLazy from './utils/wrapLazy';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "HtmlEditorCommand" */ './lazyIndex')
);

export const insertTableCommand = wrapLazy(lazyModule, 'insertTableCommand');
export const editTableCommand = wrapLazy(lazyModule, 'editTableCommand');
export const formatTableCommand = wrapLazy(lazyModule, 'formatTableCommand');
export const applyCellShadingCommand = wrapLazy(lazyModule, 'applyCellShadingCommand');
export const setTableHeaderRowCommand = wrapLazy(lazyModule, 'setTableHeaderRowCommand');
export const toggleBulletCommand = wrapLazy(lazyModule, 'toggleBulletCommand');
export const toggleNumberingCommand = wrapLazy(lazyModule, 'toggleNumberingCommand');
export const setIndentationCommand = wrapLazy(lazyModule, 'setIndentationCommand');
export const setListStartNumberCommand = wrapLazy(lazyModule, 'setListStartNumberCommand');
export const toggleInlineFormatCommand = wrapLazy(lazyModule, 'toggleInlineFormatCommand');
export const removeLinkCommand = wrapLazy(lazyModule, 'removeLinkCommand');
export const insertLinkCommand = wrapLazy(lazyModule, 'insertLinkCommand');
export const adjustImageSelectionCommand = wrapLazy(lazyModule, 'adjustImageSelectionCommand');
export const setImageAltTextCommand = wrapLazy(lazyModule, 'setImageAltTextCommand');
export const finishFormatPainterCommand = wrapLazy(lazyModule, 'finishFormatPainterCommand');
export const getFormatPainterFormatCommand = wrapLazy(lazyModule, 'getFormatPainterFormatCommand');
export const setTextColorCommand = wrapLazy(lazyModule, 'setTextColorCommand');
export const setBackgroundColorCommand = wrapLazy(lazyModule, 'setBackgroundColorCommand');
export const setFontNameCommand = wrapLazy(lazyModule, 'setFontNameCommand');
export const setFontSizeCommand = wrapLazy(lazyModule, 'setFontSizeCommand');
export const changeCapitalizationCommand = wrapLazy(lazyModule, 'changeCapitalizationCommand');
export const clearFormatCommand = wrapLazy(lazyModule, 'clearFormatCommand');
export const getFormatStateCommand = wrapLazy(lazyModule, 'getFormatStateCommand');
export const setAlignmentCommand = wrapLazy(lazyModule, 'setAlignmentCommand');
export const setDirectionCommand = wrapLazy(lazyModule, 'setDirectionCommand');
export const toggleBlockQuoteCommand = wrapLazy(lazyModule, 'toggleBlockQuoteCommand');
export const applyStylingCommand = wrapLazy(lazyModule, 'applyStylingCommand');
export const insertImageCommand = wrapLazy(lazyModule, 'insertImageCommand');
export const setSpacingCommand = wrapLazy(lazyModule, 'setSpacingCommand');
export const setParagraphMarginCommand = wrapLazy(lazyModule, 'setParagraphMarginCommand');
export const changeFontSizeCommand = wrapLazy(lazyModule, 'changeFontSizeCommand');
export const changeImageCommand = wrapLazy(lazyModule, 'changeImageCommand');
export const setImageBorderCommand = wrapLazy(lazyModule, 'setImageBorderCommand');
export const setImageBoxShadowCommand = wrapLazy(lazyModule, 'setImageBoxShadowCommand');
export const adjustLinkSelectionCommand = wrapLazy(lazyModule, 'adjustLinkSelectionCommand');
export const pasteCommand = wrapLazy(lazyModule, 'pasteCommand');
export const insertEntityCommand = wrapLazy(lazyModule, 'insertEntityCommand');
export const applyTableBorderCommand = wrapLazy(lazyModule, 'applyTableBorderCommand');
export const getSelectionCommand = wrapLazy(lazyModule, 'getSelectionCommand');
export const lazyInsertEntityCommand = new LazyImport(lazyModule, m => m.insertEntityCommand);

export { default as internalRunWithHtmlEditor } from './utils/internalRunWithHtmlEditor';

export type { FormatStateResult } from './commands/getFormatStateCommand';
