import type { AttachmentWellViewState } from 'owa-attachment-well-data';
import { debugErrorThatWillShowErrorPopupOnly } from 'owa-trace';

let registeredCallback:
    | ((conversationId: string, itemId: string) => AttachmentWellViewState)
    | null = null;

const getAttachmentWellViewStateByItemIdHelper = {
    register: (callback: (conversationId: string, itemId: string) => AttachmentWellViewState) => {
        registeredCallback = callback;
    },
    available: () => !!registeredCallback,
    run: (conversationId: string, itemId: string) => {
        if (registeredCallback) {
            return registeredCallback(conversationId, itemId);
        } else {
            debugErrorThatWillShowErrorPopupOnly(
                'getAttachmentWellViewStateByItemIdHelper is not registered.'
            );
            return null;
        }
    },
};

export { getAttachmentWellViewStateByItemIdHelper };
