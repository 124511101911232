export const cardHeaderText = "aVQuMd";
export const loadingHeaderTextAlt1 = "wC$ecb";
export const loadingHeaderTextAlt2 = "xC$ecb";
export const loadingHeaderTextAlt3 = "yC$ecb";
export const loadingHeaderTextAlt4 = "zC$ecb";
export const loadingHeaderTextAlt5 = "AC$ecb";
export const loadingHeaderTextAlt6 = "BC$ecb";
export const addinLoadingHeaderText = "_QBPIe";
export const dismissSummaryButtonText = "$jFzre";
export const dismissSummaryButtonAriaLabel = "n4mUcb";
export const stopGeneratingButtonText = "f1Aglc";
export const citationAriaLabel = "kNqbse";
export const summarizationLongThreadMessage = "AXEjYd";
export const summarizationLoadErrorRAI = "tXK$vd";
export const summarizationLoadErrorAddinNotApplied = "TlPCfd";
export const addinPickerTooltipTitle = "JPxowd";
export const addinPickerTooltipContentOutlookOnlyMode = "iV$hM";
export const addinPickerTooltipContentSalesDefault = "BMB3C";
export const summarizeFallbackLanguageLabel = "LE5$re";
export const learnMoreLinkLabel = "vcNsGc";
export const summarizeEnglishFallbackLanguage = "Lw4Sub";
export const summaryCompletedGeneration = "ZV3Yab";
export const summaryMessageEncryptedWarningMessage = "Uq0w7";
export const summarizeAddinFallbackLanguageLabel = "pPE3ye";
export default {cardHeaderText,loadingHeaderTextAlt1,loadingHeaderTextAlt2,loadingHeaderTextAlt3,loadingHeaderTextAlt4,loadingHeaderTextAlt5,loadingHeaderTextAlt6,addinLoadingHeaderText,dismissSummaryButtonText,dismissSummaryButtonAriaLabel,stopGeneratingButtonText,citationAriaLabel,summarizationLongThreadMessage,summarizationLoadErrorRAI,summarizationLoadErrorAddinNotApplied,addinPickerTooltipTitle,addinPickerTooltipContentOutlookOnlyMode,addinPickerTooltipContentSalesDefault,summarizeFallbackLanguageLabel,learnMoreLinkLabel,summarizeEnglishFallbackLanguage,summaryCompletedGeneration,summaryMessageEncryptedWarningMessage,summarizeAddinFallbackLanguageLabel};