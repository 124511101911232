import type { RibbonTabDefinitionBase } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { owaComputedFn } from 'owa-computed-fn';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import getSLRComposeTab from '../../../util/getSLRComposeTab';

const getSLROptionsTab = owaComputedFn(function getSLROptionsTab(
    props: ComposeRibbonControlDefProps
): RibbonTabDefinitionBase {
    return getSLRComposeTab(
        props,
        11,
        [] /* controlsDontDropLabels */,
        [] /* controlsDontMoveToOverflow */,
        [] /* controlsFirstMoveToOverflow */,
        [] /* controlsWithoutLabels */
    );
});

export default getSLROptionsTab;
