import type { IButtonStyles } from '@fluentui/react';
import { constructKeytip, createAppFlyoutAnchor } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import type RibbonFormatState from 'owa-editor-ribbonplugin-store/lib/store/schema/RibbonFormatState';
import TextLineSpacingRegular from 'owa-fluent-icons-svg/lib/icons/TextLineSpacingRegular';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { spacingText } from './formatControlStrings.locstring.json';
import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import { onSpacingMenuClicked } from '../actions/deferredMenuDefinitionMutator';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';

export const getSpacingControl = (
    editorId: string,
    styles: IButtonStyles | undefined,
    formatViewState: RibbonFormatState,
    tabId: number | undefined,
    isDisabled: boolean,
    source: FormattingSource
) => {
    const storeName = 'spacingMenuDefinitionStore';
    return createAppFlyoutAnchor(
        getComposeRibbonId(6075, editorId),
        loc(spacingText),
        getControlMenuStore(editorId, storeName),
        tabId ? constructKeytip([tabId], 6075, true /* hasMenu */) : undefined,
        TextLineSpacingRegular,
        undefined /* iconColor */,
        styles,
        {
            disabled: isDisabled,
            onExecute: () => {
                onSpacingMenuClicked(editorId, tabId, formatViewState, source);
            },
            onAfterMenuDismiss: () => {
                resetMenuDefinition(editorId, storeName);
                callEditorApi(editorId, 'focus');
            },
        }
    );
};
