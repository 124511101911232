import type { IButtonStyles } from '@fluentui/react';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppToggleButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import LassoRegular from 'owa-fluent-icons-svg/lib/icons/LassoRegular';
import loc from 'owa-localize';
import { type DrawControlId } from 'owa-ribbon-ids/lib/drawControlId';
import { lassoText } from './drawControlStrings.locstring.json';
import type { RibbonDrawState } from 'owa-editor-graphic-plugin-types/lib/store/schema/RibbonDrawState';
import { type DrawTool } from 'owa-editor-graphic-plugin-types/lib/enums/DrawTool';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import onToggleDrawTool from '../actions/onToggleDrawTool';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import type { AppButtonOnExecuteParameter } from '@1js/acui-button/lib/components/AppButton/AppButton.Props';

export const getLasso = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: ((composeId: string) => RibbonMailComposeInfo | null) | undefined,
        drawViewState: RibbonDrawState | undefined,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        return createAppToggleButton(
            getComposeRibbonId(12004, editorId),
            loc(lassoText),
            (parameter?: AppButtonOnExecuteParameter) => {
                const inputMethod = parameter && parameter.inputMethod;

                getComputedCallback(
                    12004,
                    onToggleDrawTool,
                    composeId,
                    6,
                    retriever,
                    inputMethod
                )();
            },
            !!(drawViewState && drawViewState.currentSelectedDrawTool === 6),
            tabId ? constructKeytip([tabId], 12004) : undefined,
            LassoRegular,
            undefined /* iconColor */,
            styles,
            {
                disabled: isDisabled || !!(drawViewState && !drawViewState.areDrawToolsActive),
            }
        );
    }
);
