export const findTimeDescription = "Y2OKA";
export const attachSignatureButtonDesc = "y9EoHd";
export const encryptDesc = "wWFu6";
export const sensitivityDesc = "If8oMe";
export const saveDraftDesc = "$aEnH";
export const readReceiptDesc = "p2k$fc";
export const deliveryReceiptDesc = "JRkIMe";
export const attachFileDesc = "EEt6Rb";
export const getMessageExtensionsDesc = "oSB4Qb";
export const getAddinsDesc = "Ce0fVc";
export const pollsDesc = "ZlnK9d";
export const proofingOptionsDescription = "wjl7wd";
export const scheduleSendDesc = "mgptZ";
export const showBccDesc = "mFjMx";
export const showCcDesc = "UatUDb";
export const showFromDesc = "op1ocd";
export const switchBodyTypeDesc = "RXgZ7";
export const showMoreOptionsDesc = "VvMfrd";
export const highImportanceDesc = "rHvGic";
export const lowImportanceDesc = "HeXWwd";
export const deviceNotCompliantForLoopsFluidHero = "S_DJJb";
export const loopMenuDescriptionTooltip = "q34RPc";
export const loopMenuTitleTooltip = "oq_rad";
export const recordVideoDesc = "jB$AE";
export const disallowReactionsDesc = "kbmmt";
export const draftWithCopilotDescriptionTooltip = "IF9Qtc";
export const coachWithCopilotDescriptionTooltip = "lC9eXc";
export default {findTimeDescription,attachSignatureButtonDesc,encryptDesc,sensitivityDesc,saveDraftDesc,readReceiptDesc,deliveryReceiptDesc,attachFileDesc,getMessageExtensionsDesc,getAddinsDesc,pollsDesc,proofingOptionsDescription,scheduleSendDesc,showBccDesc,showCcDesc,showFromDesc,switchBodyTypeDesc,showMoreOptionsDesc,highImportanceDesc,lowImportanceDesc,deviceNotCompliantForLoopsFluidHero,loopMenuDescriptionTooltip,loopMenuTitleTooltip,recordVideoDesc,disallowReactionsDesc,draftWithCopilotDescriptionTooltip,coachWithCopilotDescriptionTooltip};