import { action } from 'satcheljs';
import type { TableView } from 'owa-mail-list-store';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
/**
 * If there is an override configuration, provides the option to disable it.
 * @param rowKey the rowKey of the selected mail item.
 * @param tableView the table where the conversation belongs.
 * @param destinationViewFilter  message destination inbox.
 */
export const onOverrideCorrection = action(
    'ON_OVERRIDE_CORRECTION',
    (rowKey: string, tableView: TableView, destinationViewFilter: FocusedViewFilter) => ({
        rowKey,
        tableView,
        destinationViewFilter,
    })
);
