import { getStore } from '../store/SharePointItemsViewNavigationLocationStore';
import { mutatorAction } from 'satcheljs';

export const setSharePointItemsViewNavigationPath = mutatorAction(
    'setSharePointItemsViewNavigationPath',
    (groupSmtpAddress: string, path: string): void => {
        const store = getStore();
        groupSmtpAddress = groupSmtpAddress.toLowerCase();
        if (store.has(groupSmtpAddress)) {
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2532 (13,13): Object is possibly 'undefined'.
            // @ts-expect-error
            store.get(groupSmtpAddress).currentPath = path;
        } else {
            store.set(groupSmtpAddress, {
                currentPath: path,
                // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                // -> Error TS2322 (20,17): Type 'undefined' is not assignable to type 'string'.
                // @ts-expect-error
                targetPath: undefined,
            });
        }
    }
);

export const setSharePointItemsViewNavigationTargetPath = mutatorAction(
    'setSharePointItemsViewNavigationTargetPath',
    (groupSmtpAddress: string, path: string): void => {
        const store = getStore();
        groupSmtpAddress = groupSmtpAddress.toLowerCase();
        if (store.has(groupSmtpAddress)) {
            // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
            // -> Error TS2532 (35,13): Object is possibly 'undefined'.
            // @ts-expect-error
            store.get(groupSmtpAddress).targetPath = path;
        } else {
            store.set(groupSmtpAddress, {
                // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
                // -> Error TS2322 (41,17): Type 'undefined' is not assignable to type 'string'.
                // @ts-expect-error
                currentPath: undefined,
                targetPath: path,
            });
        }
    }
);
