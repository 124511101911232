import { DirectionalHint } from '@fluentui/react/lib/Callout';
import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import { DatapointStatus, PerformanceDatapoint } from 'owa-analytics';
import { ConversationAttachmentWellButton } from 'owa-attachment-conversation-well-view';
import type { ClientItemId } from 'owa-client-ids';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import { isFeatureEnabled } from 'owa-feature-flags';
import { GroupsDropdownButton } from 'owa-groups-dropdown';
import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import type ItemReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemReadingPaneViewState';
import getConversationReadingPaneViewState from 'owa-mail-reading-pane-store-conversation/lib/utils/getConversationReadingPaneViewState';
import getItemReadingPaneViewState from 'owa-mail-reading-pane-store/lib/utils/getItemReadingPaneViewState';
import type { ClientItem } from 'owa-mail-store';
import mailStore from 'owa-mail-store/lib/store/Store';
import canConversationLoadMore from 'owa-mail-store/lib/utils/canConversationLoadMore';
import { getItemToShowFromNodeId } from 'owa-mail-store/lib/utils/conversationsUtils';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import isSMIMEItem from 'owa-smime/lib/utils/isSMIMEItem';
import type { TopicsSDK } from 'owa-topic-common';
import { lazyGetTopicsSdkAsync } from 'owa-topic-common/lib/lazyFunctions';
import { isTopicsMvpEnabled } from 'owa-topic-common/lib/utils/TopicsFeatureGate';
import { tryGetTopicsSdk } from 'owa-topic-common/lib/utils/tryGetTopicsSdk';
import { TopicDropdownButton } from 'owa-topic-dropdown';
import type { TraceErrorObject } from 'owa-trace';
import React from 'react';
import {
    medium,
    compact,
    label,
    subjectHeaderIcon,
    bottomToolBarRightSide,
    inlineAttachments,
} from './SubjectHeaderV2.scss';

/**
 * This component should only contain buttons that are additions to the message e.g.:
 * Attachments, Topics, etc..
 */
export interface MessageAttachmentsContainerProps {
    item?: ClientItem;
    itemId?: ClientItemId;
    conversationId?: string;
    firstItemId?: string;
    shouldShrink: boolean;
}

export default observer(function MessageAttachmentsContainer(
    props: MessageAttachmentsContainerProps
) {
    const { conversationId, shouldShrink, item } = props;
    // messageAttachemntesContainer only shows in Mail, item, itemId and conversationId are optional inputs,
    // so we could get mailboxInfo directly
    const mailboxInfo = getModuleContextMailboxInfo();

    const [topicsSdk, setTopicsSdk] = React.useState<TopicsSDK | undefined>(
        isTopicsMvpEnabled(mailboxInfo) ? tryGetTopicsSdk(mailboxInfo) : undefined
    );

    const mountedRef = React.useRef(true);
    const isTopicsEnabled = isTopicsMvpEnabled(mailboxInfo);

    React.useEffect(() => {
        if (isTopicsEnabled && !topicsSdk) {
            const datapoint = new PerformanceDatapoint(
                'TopicAnnotations.ConversationRollup.LoadTopicsSdk'
            );
            lazyGetTopicsSdkAsync
                .importAndExecute(mailboxInfo)
                .then((promiseTopicsSdk: TopicsSDK | undefined) => {
                    if (mountedRef.current) {
                        setTopicsSdk(promiseTopicsSdk);
                    }
                    datapoint.end();
                })
                .catch((error: TraceErrorObject) => {
                    try {
                        Object.defineProperty(error, 'message', {
                            value: 'SubjectHeaderBottomToolBar: ' + error.message,
                        });
                    } catch {
                        // no op
                    }

                    datapoint.endWithError(DatapointStatus.ClientError, error).catch(() => {});
                    error.reported = true;
                });
            return () => {
                mountedRef.current = false; // clean up function
            };
        } else {
            return () => {};
        }
    }, [props.itemId]);

    let conversationItemParts;
    if (conversationId) {
        conversationItemParts = mailStore.conversations.get(conversationId);
    }

    const sMIMEitems =
        conversationItemParts?.conversationNodeIds.filter(nodeId => {
            const itemToShow = getItemToShowFromNodeId(nodeId);
            if (itemToShow) {
                return isSMIMEItem(itemToShow);
            }

            return false;
        }) ?? [];

    const densityMode = getDensityModeCssClass(undefined, medium, compact);
    const labelClassNames = classnames(label, densityMode);
    const iconClassNames = classnames(subjectHeaderIcon, densityMode);
    const groupsButtonEnabled = isFeatureEnabled('grp-inboxExp-subjectHeader');

    const renderAttachmentWell = (
        selectedConversationReadingPaneState: ConversationReadingPaneViewState,
        canLoadMore: boolean,
        sMIMEitemsToCheck: string[]
    ): JSX.Element | null => {
        if (
            sMIMEitemsToCheck.length === 0 &&
            selectedConversationReadingPaneState?.attachmentWell
        ) {
            return (
                <ConversationAttachmentWellButton
                    viewState={selectedConversationReadingPaneState.attachmentWell}
                    canLoadMore={canLoadMore}
                    instrumentationContext={
                        selectedConversationReadingPaneState.instrumentationContext
                    }
                    labelStyles={labelClassNames}
                    iconPropsStyles={iconClassNames}
                    hideText={shouldShrink}
                    directionalHint={
                        shouldShrink
                            ? DirectionalHint.bottomRightEdge
                            : DirectionalHint.bottomLeftEdge
                    }
                />
            );
        }
        return null;
    };

    const renderConversationTopicButton = (
        viewState: ConversationReadingPaneViewState,
        canLoadMore: boolean
    ) => {
        return isTopicsEnabled && !!viewState && !!topicsSdk ? (
            <TopicDropdownButton
                conversationId={viewState.conversationId.Id}
                conversationLength={viewState.itemPartsMap.size}
                mailboxInfo={mailboxInfo}
                topicsSdk={topicsSdk}
                isLoading={viewState.loadingState.isLoading}
                canLoadMore={canLoadMore}
                labelStyles={labelClassNames}
                hideText={shouldShrink}
                iconPropsStyles={iconClassNames}
            />
        ) : null;
    };

    const renderItemTopicButton = (viewState: ItemReadingPaneViewState) => {
        return isTopicsEnabled &&
            !!topicsSdk &&
            !item?.IsExternalSender &&
            item?.ConversationId?.Id ? (
            <TopicDropdownButton
                isLoading={viewState?.loadingState?.isLoading}
                canLoadMore={false}
                item={item}
                conversationId={item.ConversationId.Id}
                mailboxInfo={mailboxInfo}
                conversationLength={1}
                topicsSdk={topicsSdk}
                hideText={shouldShrink}
                iconPropsStyles={iconClassNames}
            />
        ) : null;
    };

    const renderItemGroupsButton = (viewState?: ItemReadingPaneViewState): JSX.Element | null => {
        return groupsButtonEnabled && !!viewState ? (
            <GroupsDropdownButton
                itemId={viewState.itemId}
                iconPropsStyles={iconClassNames}
                hideText={shouldShrink}
            />
        ) : null;
    };

    const renderConversationGroupsButton = (
        viewState?: ConversationReadingPaneViewState
    ): JSX.Element | null => {
        return groupsButtonEnabled && !!viewState?.focusedItemPart?.itemPart ? (
            <GroupsDropdownButton
                itemId={viewState.focusedItemPart.itemPart.itemId}
                labelStyles={labelClassNames}
                iconPropsStyles={iconClassNames}
                hideText={shouldShrink}
            />
        ) : null;
    };

    const conversationReadingPaneViewState = getConversationReadingPaneViewState(conversationId);
    const itemReadingPaneViewState = getItemReadingPaneViewState();

    const canLoadMore: boolean = !!(conversationId && canConversationLoadMore(conversationId));
    const topicButton = item
        ? renderItemTopicButton(itemReadingPaneViewState)
        : renderConversationTopicButton(conversationReadingPaneViewState, canLoadMore);
    const groupsButton = item
        ? renderItemGroupsButton(itemReadingPaneViewState)
        : renderConversationGroupsButton(conversationReadingPaneViewState);
    const attachmentWell =
        !item && renderAttachmentWell(conversationReadingPaneViewState, canLoadMore, sMIMEitems);

    return (
        <div className={classnames(bottomToolBarRightSide, !shouldShrink && inlineAttachments)}>
            {topicButton}
            {groupsButton}
            {attachmentWell}
        </div>
    );
},
'MessageAttachmentsContainer');
