import type { RibbonTabDefinitionBase } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { owaComputedFn } from 'owa-computed-fn';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import getSLRComposeTab from '../../../../util/getSLRComposeTab';

const getSLRPictureFormatTab = owaComputedFn(function getSLRPictureFormatTab(
    props: ComposeRibbonControlDefProps
): RibbonTabDefinitionBase {
    return getSLRComposeTab(
        props,
        13,
        [] /* controlsDontDropLabels */,
        [] /* controlsDontMoveToOverflow */,
        [] /* controlsFirstMoveOverflow */,
        [] /* controlsWithoutLabels */
    );
});

export default getSLRPictureFormatTab;
