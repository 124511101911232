// This is an autogenerated file. Do not manually edit.
// Run `node packages/framework/owa-fluent-mobile-brand-icons-svg/updateIcons.js` to update the icons in this package
import React from 'react';
import { registerIcons } from '@fluentui/style-utilities';
import { createIconComponent } from 'owa-fluent-icons-svg';

export default 'IcFluentLoopColor';

const IcFluentLoopColor = createIconComponent({
    IconComponent: props => (
        <svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8 2C4.68629 2 2 4.68629 2 8V14H8C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2ZM8 5.9C9.1598 5.9 10.1 6.8402 10.1 8C10.1 9.1598 9.1598 10.1 8 10.1H5.9V8C5.9 6.8402 6.8402 5.9 8 5.9Z"
                fill="url(#paint0_linear_1_56)"
            />
            <path
                d="M14 8C14 11.3137 11.3137 14 8 14H2C2 11.8461 3.74609 10.1 5.9 10.1H8C9.1598 10.1 10.1 9.1598 10.1 8L14 8Z"
                fill="url(#paint1_linear_1_56)"
            />
            <path
                d="M14 8C14 11.3137 11.3137 14 8 14H2C2 11.8461 3.74609 10.1 5.9 10.1H8C9.1598 10.1 10.1 9.1598 10.1 8L14 8Z"
                fill="url(#paint2_radial_1_56)"
                fill-opacity="0.56"
            />
            <path
                d="M14 8C14 11.3137 11.3137 14 8 14H2C2 11.8461 3.74609 10.1 5.9 10.1H8C9.1598 10.1 10.1 9.1598 10.1 8L14 8Z"
                fill="url(#paint3_radial_1_56)"
                fill-opacity="0.1"
            />
            <path
                d="M8 2C4.68629 2 2 4.68629 2 8V14C2.00055 14 2.0011 14 2.00165 14C4.15048 13.9991 5.893 12.2604 5.89998 10.1129L5.91297 10.1H5.9V8C5.9 6.8402 6.8402 5.9 8 5.9C8.58583 5.9 9.11564 6.13989 9.49655 6.52679L12.2583 3.77305C11.1713 2.67805 9.66482 2 8 2Z"
                fill="url(#paint4_linear_1_56)"
            />
            <path
                d="M8 2C4.68629 2 2 4.68629 2 8V14C2.00055 14 2.0011 14 2.00165 14C4.15048 13.9991 5.893 12.2604 5.89998 10.1129L5.91297 10.1H5.9V8C5.9 6.8402 6.8402 5.9 8 5.9C8.58583 5.9 9.11564 6.13989 9.49655 6.52679L12.2583 3.77305C11.1713 2.67805 9.66482 2 8 2Z"
                fill="url(#paint5_radial_1_56)"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_1_56"
                    x1="9.32"
                    y1="6.26"
                    x2="7.34"
                    y2="14.36"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#9237E3" />
                    <stop offset="1" stop-color="#48E6F3" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_1_56"
                    x1="5.9"
                    y1="13.1"
                    x2="11.18"
                    y2="11.9"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#51E6FF" />
                    <stop offset="1" stop-color="#71D4FF" stop-opacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint2_radial_1_56"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(4.1 11.45) rotate(34.5085) scale(2.91247 5.11874)"
                >
                    <stop />
                    <stop offset="0.699824" stop-opacity="0" />
                </radialGradient>
                <radialGradient
                    id="paint3_radial_1_56"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(3.38 11) rotate(31.866) scale(5.22793 9.1882)"
                >
                    <stop offset="0.328125" />
                    <stop offset="0.604167" stop-opacity="0" />
                </radialGradient>
                <linearGradient
                    id="paint4_linear_1_56"
                    x1="4.46"
                    y1="8.42"
                    x2="7.52"
                    y2="4.1"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#6D05E8" />
                    <stop offset="1" stop-color="#AB5ADD" stop-opacity="0" />
                </linearGradient>
                <radialGradient
                    id="paint5_radial_1_56"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(0.98 5.72) rotate(-9.73945) scale(8.15757 21.4474)"
                >
                    <stop stop-color="#6D05E8" />
                    <stop offset="1" stop-color="#6D05E8" stop-opacity="0" />
                </radialGradient>
            </defs>
        </svg>
    ),
    displayName: 'IcFluentLoopColor',
});

registerIcons({
    icons: {
        IcFluentLoopColor: <IcFluentLoopColor />,
    },
});
