import { createStore } from 'satcheljs';
import type MailVotingStore from './schema/MailVotingStore';
import { defaultProviderName } from './schema/MailVotingTypes';

const initialMailVotingStore: MailVotingStore = {
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (9,5): Type 'undefined' is not assignable to type 'VotingOption[]'.
    // @ts-expect-error
    options: undefined,
    activeProviders: [defaultProviderName],
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (14,5): Type 'undefined' is not assignable to type 'ItemId'.
    // @ts-expect-error
    referenceItemId: undefined,
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (18,5): Type 'undefined' is not assignable to type 'VotingResults'.
    // @ts-expect-error
    results: undefined,
};

const store = createStore<MailVotingStore>('mailVotingStore', initialMailVotingStore)();
export default store;
export const getStore = () => store;
