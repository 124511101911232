import type { IButtonStyles } from '@fluentui/react';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppToggleButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import loc from 'owa-localize';
import { type DrawControlId } from 'owa-ribbon-ids/lib/drawControlId';
import { textPenText } from './drawControlStrings.locstring.json';
import type { RibbonDrawState } from 'owa-editor-graphic-plugin-types/lib/store/schema/RibbonDrawState';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import onToggleDrawTextPen from '../actions/onToggleDrawTextPen';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import { getRibbonViewMode } from 'owa-command-ribbon-store';
import { lazyGetTextPenIcon } from 'owa-compose-drawcontrols-icons';
import { getIsDarkTheme } from 'owa-fabric-theme';
import { getIsMsHighContrast } from 'owa-high-contrast';
import { getDrawButtonStyles } from '../util/getDrawButtonStyles';

export const getTextPen = owaComputedFn(function getTextPen(
    editorId: string,
    composeId: string,
    retriever: ((composeId: string) => RibbonMailComposeInfo | null) | undefined,
    drawViewState: RibbonDrawState | undefined,
    styles: IButtonStyles | undefined,
    tabId: number | undefined
): RibbonControlProps {
    const textPenIcon = lazyGetTextPenIcon.tryImportForRender()?.(
        getRibbonViewMode(),
        getIsDarkTheme(),
        getIsMsHighContrast()
    );

    return createAppToggleButton(
        getComposeRibbonId(12012, editorId),
        loc(textPenText),
        getComputedCallback(12012, onToggleDrawTextPen, composeId, retriever),
        !!(drawViewState && drawViewState.isTextPenSelected),
        tabId ? constructKeytip([tabId], 12012) : undefined,
        textPenIcon,
        undefined,
        getDrawButtonStyles(styles)
    );
});
