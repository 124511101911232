import { logUsage } from 'owa-analytics';

// User opens the discover menu (button in ribbon to create chapter 1 loop)
export function logDiscoverMenuOpened() {
    logUsage('FluidDiscoverMenuOpened');
}

// The service call to fetch the items in the discover menu (button in ribbon to create chapter 1 loop) failed
export function logDiscoverMenuPreloadFailed() {
    logUsage('DiscoverManifestPreloadFailed');
}

// User attempts to open discover menu (button in ribbon to create chapter 1 loop) but the items were not yet loaded to display.
// In this case we show a static fallback list, that will still work for the user.
export function logDiscoverManifestNotLoaded() {
    logUsage('FluidDiscoverManifestNotLoaded');
}
