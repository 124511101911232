import { constructKeytip, createAppFlyoutAnchor } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { pictureShadowButton } from './pictureControlsStrings.locstring.json';
import type { IButtonStyles } from '@fluentui/react';
import type { RibbonFlyoutAnchorProps } from '@1js/acui-ribbon-like';
import ImageShadowRegular from 'owa-fluent-icons-svg/lib/icons/ImageShadowRegular';
import { onShadowMenuClicked } from '../actions/deferredMenuDefinitionMutator';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import type { PictureFormatState } from 'owa-editor-ribbonplugin-store';
import { owaComputedFn } from 'owa-computed-fn';
import { updatePictureFormatState } from '../actions/updatePictureFormatState';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';

export const getPictureShadowControl = owaComputedFn(
    (
        editorId: string,
        pictureFormatState: PictureFormatState,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean
    ) => {
        const storeName = 'shadowMenuDefinitionStore';
        return createAppFlyoutAnchor(
            getComposeRibbonId(6080, editorId),
            loc(pictureShadowButton),
            getControlMenuStore(editorId, storeName),
            tabId ? constructKeytip([tabId], 6080, true /* hasMenu */) : undefined,
            ImageShadowRegular,
            undefined /* iconColor */,
            styles,
            <Partial<RibbonFlyoutAnchorProps>>{
                disabled: isDisabled,
                onExecute: parameter => {
                    const { event } = parameter;
                    event?.stopPropagation();
                    onShadowMenuClicked(editorId, pictureFormatState);
                },
                onAfterMenuDismiss: () => {
                    resetMenuDefinition(editorId, storeName);
                    updatePictureFormatState(editorId, pictureFormatState);
                    callEditorApi(editorId, 'focus');
                },
            }
        );
    }
);
