import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppFlyoutAnchor } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import DocumentSignatureRegular from 'owa-fluent-icons-svg/lib/icons/DocumentSignatureRegular';
import loc from 'owa-localize';
import type { ComposeViewState } from 'owa-mail-compose-store';
import type { MailRibbonTabId } from 'owa-ribbon-ids';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { attachSig_FlyoutButton } from '../strings/actionControlStrings.locstring.json';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import { attachSignatureButtonDesc } from '../strings/hoverTooltipDesc.locstring.json';
import { onSignatureMenuClicked } from '../actions/deferredMenuDefinitionMutator';
import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';

/**
 * This file is for shared components in compose scenarios. Meaning, any tab or control that only shows up
 * when a user is composing. In this case, Insert tab and Message tab are good examples.
 *
 * For controls that show up in read-only scenarios, please use readSharedControlDefinitions.ts.
 */

export const getAttachSigControl = owaComputedFn(
    (
        editorId: string,
        viewState: ComposeViewState, // Only used for getting info to create button/menus, do not use it within any event handler to avoid memory leak
        retriever: (composeId: string) => RibbonMailComposeInfo | null,
        tabId: MailRibbonTabId | undefined,
        styles: IButtonStyles | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        const composeId: string = viewState.composeId;
        const storeName = 'signatureMenuDefinitionStore';
        return createAppFlyoutAnchor(
            getComposeRibbonId(506, viewState.editorId),
            loc(attachSig_FlyoutButton),
            getControlMenuStore(editorId, storeName),
            constructKeytip(tabId ? [tabId] : [], 506, true /* hasMenu */),
            DocumentSignatureRegular,
            undefined /*iconColors*/,
            styles,
            {
                customTooltip: getHoverTooltip(attachSig_FlyoutButton, attachSignatureButtonDesc),
                disabled: isDisabled,
                onExecute: () => {
                    const mailComposeInfo = retriever(composeId);
                    if (mailComposeInfo) {
                        onSignatureMenuClicked(
                            editorId,
                            mailComposeInfo.composeViewState,
                            retriever,
                            tabId
                        );
                    }
                },
                onAfterMenuDismiss: () => {
                    resetMenuDefinition(editorId, storeName);
                    callEditorApi(editorId, 'focus');
                },
            }
        );
    }
);
