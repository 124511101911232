import { LazyAction, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OwaMailPie" */ './lazyIndex'),
    { name: 'OwaMailPie' }
);

export const lazyLoadUpdateContentProtection = new LazyAction(
    lazyModule,
    m => m.updateContentProtection
);
