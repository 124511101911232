import { lazyGetTeamsChatAroundEmailSections } from 'owa-mail-ribbon-sections/lib/sections';

import type { MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import type { ReadOnlyRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { setMenuDefinition } from '../../mutatorActions/setMenuDefinition';

export const onChatAroundEmailMenuClicked = (
    isSLR: boolean,
    keytipTabId: MailRibbonTabId | undefined,
    props: ReadOnlyRibbonControlDefProps
) => {
    lazyGetTeamsChatAroundEmailSections.import().then(getTeamsChatAroundEmailSections => {
        setMenuDefinition('TeamsChatMenuDefinitionStore', props.projectionTabId, () => {
            return {
                sections: getTeamsChatAroundEmailSections(isSLR, keytipTabId, props),
            };
        });
    });
};
