import type { IButtonStyles } from '@fluentui/react';
import { constructKeytip, createAppFlyoutAnchor } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import type { AppControlProps } from '@1js/acui-ribbon-like';
import RotateRightRegular from 'owa-fluent-icons-svg/lib/icons/RotateRightRegular';
import RotateLeftRegular from 'owa-fluent-icons-svg/lib/icons/RotateLeftRegular';
import FlipVerticalRegular from 'owa-fluent-icons-svg/lib/icons/FlipVerticalRegular';
import FlipHorizontalRegular from 'owa-fluent-icons-svg/lib/icons/FlipHorizontalRegular';
import {
    pictureRotateButton,
    rotateRight90Label,
    rotateLeft90Label,
    flipVerticalLabel,
    flipHorizontalLabel,
} from './pictureControlsStrings.locstring.json';
import { operateOwaImageEdit } from 'owa-editor-imageedit-plugin-operator';
import { owaComputedFn } from 'owa-computed-fn';
import type { MenuDefinition } from '@1js/acui-menu';

const PI_HALF = Math.PI / 2;

const applyRotation = (editorId: string, angle: number) => {
    operateOwaImageEdit(editorId, imageEdit => {
        const image = imageEdit.getImageSelection();
        if (image) {
            imageEdit.rotateImage(image, angle);
        }
    });
};

const applyFlip = (editorId: string, flip: 'vertical' | 'horizontal') => {
    operateOwaImageEdit(editorId, imageEdit => {
        const image = imageEdit.getImageSelection();
        if (image) {
            imageEdit.flipImage(image, flip);
        }
    });
};

const getRotateMenuDefinition = owaComputedFn(
    (editorId: string): MenuDefinition => ({
        sections: [
            {
                controls: <AppControlProps[]>[
                    {
                        id: getComposeRibbonId('RotateRight', editorId),
                        type: 'AppButtonProps',
                        icon: RotateRightRegular,
                        label: loc(rotateRight90Label),
                        onExecute: () => applyRotation(editorId, PI_HALF),
                        takeFocusOnClick: true,
                    },
                    {
                        id: getComposeRibbonId('RotateLeft', editorId),
                        type: 'AppButtonProps',
                        icon: RotateLeftRegular,
                        label: loc(rotateLeft90Label),
                        onExecute: () => applyRotation(editorId, -PI_HALF),
                        takeFocusOnClick: true,
                    },
                    {
                        id: getComposeRibbonId('FlipVertical', editorId),
                        type: 'AppButtonProps',
                        icon: FlipVerticalRegular,
                        label: loc(flipVerticalLabel),
                        onExecute: () => applyFlip(editorId, 'vertical'),
                        takeFocusOnClick: true,
                    },
                    {
                        id: getComposeRibbonId('FlipHorizontal', editorId),
                        type: 'AppButtonProps',
                        icon: FlipHorizontalRegular,
                        label: loc(flipHorizontalLabel),
                        onExecute: () => applyFlip(editorId, 'horizontal'),
                        takeFocusOnClick: true,
                    },
                ],
            },
        ],
    })
);

export const getPictureRotateControl = owaComputedFn(
    (
        editorId: string,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean
    ) => {
        return createAppFlyoutAnchor(
            getComposeRibbonId(6082, editorId),
            loc(pictureRotateButton),
            getRotateMenuDefinition(editorId),
            tabId ? constructKeytip([tabId], 6082, true /* hasMenu */) : undefined,
            RotateLeftRegular,
            undefined /* iconColor */,
            styles,
            {
                disabled: isDisabled,
            }
        );
    }
);
