import type { ClientItem } from 'owa-mail-store';
import type MeetingCancellationMessageType from 'owa-service/lib/contract/MeetingCancellationMessageType';
import type MeetingMessage from 'owa-service/lib/contract/MeetingMessage';
import type MeetingRequestMessageType from 'owa-service/lib/contract/MeetingRequestMessageType';
import type MeetingResponseMessageType from 'owa-service/lib/contract/MeetingResponseMessageType';
import {
    isMeetingCancellation,
    isMeetingRequest,
    isMeetingMessageForSeriesException,
    isMeetingMessageForSingleEvent,
    isMeetingMessageForSeriesMaster,
    isMeetingResponse,
} from 'owa-meeting-message-utils';
import type { ClientItemId } from 'owa-client-ids';
import type { MailboxInfo } from 'owa-client-types';
import { getAssociatedCalendarItemId } from 'owa-meeting-message/lib/utils/getAssociatedCalendarItemId';

/**
 * Gets the message ids that will be used to populate the Calendar Card.
 * This message should be representative of all Meeting Messages of the conversation
 * @param items All the items in a Conversation thread
 */
export default function getMeetingCardProps(
    items: ClientItem[],
    mailboxInfo: MailboxInfo,
    showForMoreCases?: boolean // in some instances, such as for CITI card redesign, we should try to load meeting message
    // information even if it's not representative of the whole conversation thread
): {
    latestMeetingMessageId: ClientItemId | null;
    eventId: ClientItemId | null;
    latestRequestId: ClientItemId | null;
    latestCancellationId: ClientItemId | null;
} {
    const meetingMessages = items.filter(isValidMeetingMessageForCard);
    const singles = meetingMessages.filter(isMeetingMessageForSingleEvent);
    const occurrences = meetingMessages.filter(isMeetingMessageForSeriesException);
    const recurringMasters = meetingMessages.filter(isMeetingMessageForSeriesMaster);

    let latestMeetingMessageId: ClientItemId | null = null;
    let eventId: ClientItemId | null = null;
    let latestRequestId: ClientItemId | null = null;
    let latestCancellationId: ClientItemId | null = null;

    if (singles.length > 0) {
        // If there is at least one single event. All other events must be singles
        // and share the same ItemId. Return the last received one.
        const commonEventId = getCommonAssociatedCalendarItemId(singles);
        if (
            ((recurringMasters.length == 0 && occurrences.length == 0) || !!showForMoreCases) &&
            commonEventId
        ) {
            latestMeetingMessageId = getLatestMeetingMessageId(singles, mailboxInfo);
            eventId = commonEventId;
            latestRequestId = getLatestMeetingRequestId(singles, mailboxInfo);
            latestCancellationId = getLatestMeetingCancellationId(singles, mailboxInfo);
        }
    } else if (recurringMasters.length > 0) {
        // If there is at least one Recurring Master, all the others must be recurring masters
        // and share the same ItemId. Return the last received one.
        const commonEventId = getCommonAssociatedCalendarItemId(recurringMasters);
        if ((!!showForMoreCases || occurrences.length == 0) && commonEventId) {
            latestMeetingMessageId = getLatestMeetingMessageId(recurringMasters, mailboxInfo);
            eventId = commonEventId;
            latestRequestId = getLatestMeetingRequestId(recurringMasters, mailboxInfo);
            latestCancellationId = getLatestMeetingCancellationId(recurringMasters, mailboxInfo);
        }
    } else if (occurrences.length > 0) {
        // If there are only occurrences, all messages must share the same ItemId
        // Return the last received one.
        const commonEventId = getCommonAssociatedCalendarItemId(occurrences);
        if (commonEventId) {
            latestMeetingMessageId = getLatestMeetingMessageId(occurrences, mailboxInfo);
            eventId = commonEventId;
            latestRequestId = getLatestMeetingRequestId(occurrences, mailboxInfo);
            latestCancellationId = getLatestMeetingCancellationId(occurrences, mailboxInfo);
        }
    }

    return {
        latestMeetingMessageId,
        eventId,
        latestRequestId,
        latestCancellationId,
    };
}

function getLatestMeetingMessageId(
    items: MeetingMessage[],
    mailboxInfo: MailboxInfo
): ClientItemId | null {
    const latestMessage = items.length > 0 ? items[items.length - 1] : null;
    return latestMessage?.ItemId ? { Id: latestMessage.ItemId.Id, mailboxInfo } : null;
}

function getLatestMeetingRequestId(
    items: MeetingMessage[],
    mailboxInfo: MailboxInfo
): ClientItemId | null {
    const requests = items.filter(item => isMeetingRequest(item.ItemClass));
    const latestRequest = requests.length > 0 ? requests[requests.length - 1] : null;
    return latestRequest?.ItemId ? { Id: latestRequest.ItemId.Id, mailboxInfo } : null;
}

function getLatestMeetingCancellationId(
    items: MeetingMessage[],
    mailboxInfo: MailboxInfo
): ClientItemId | null {
    const cancellations = items.filter(item => isMeetingCancellation(item.ItemClass));
    const lastCancellation =
        cancellations.length > 0 ? cancellations[cancellations.length - 1] : null;
    return lastCancellation?.ItemId ? { Id: lastCancellation.ItemId.Id, mailboxInfo } : null;
}

function isValidMeetingMessageForCard(item: ClientItem): boolean {
    if (item.MailboxInfo && item.MailboxInfo.type === 'GroupMailbox') {
        // TODO VSO:54447 - Add group calendar support for the card
        return false;
    }

    if (
        !isMeetingRequest(item.ItemClass) &&
        !isMeetingCancellation(item.ItemClass) &&
        !isMeetingResponse(item.ItemClass)
    ) {
        return false;
    }

    const meetingItem = item as
        | MeetingRequestMessageType
        | MeetingCancellationMessageType
        | MeetingResponseMessageType;

    if (!!meetingItem.IsDelegated && !!meetingItem.ReceivedRepresenting) {
        // TODO VSO:55115 - Add delegate support for the card
        return false;
    }

    if (!meetingItem.AssociatedCalendarItemId) {
        return false;
    }

    return true;
}

function getCommonAssociatedCalendarItemId(items: ClientItem[]): ClientItemId | null {
    const firstId = getAssociatedCalendarItemId(items[0]);
    if (
        firstId &&
        items.every(item => {
            const eventId = getAssociatedCalendarItemId(item);
            return eventId && eventId.Id == firstId.Id;
        })
    ) {
        return firstId;
    } else {
        return null;
    }
}
