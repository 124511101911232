import type { IButtonStyles } from '@fluentui/react';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { getRemoveFormatControl as getRemoveFormatControlInternal } from 'owa-compose-formatcontrols-sections/lib/components/removeFormat';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';

export const getRemoveFormatControl = owaComputedFn(
    (
        editorId: string,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean,
        source: FormattingSource
    ): RibbonControlProps => {
        return getRemoveFormatControlInternal(
            editorId,
            styles,
            false /* shouldStopLivePreview */,
            tabId,
            isDisabled,
            source
        );
    }
);
