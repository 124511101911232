import type { TopicsSDK } from '@1js/cortex-topics-bootstrapper';
import { getTopicsSdk } from '@1js/cortex-topics-bootstrapper';
import { getApplicationSettings } from 'owa-application-settings';
import type { MailboxInfo } from 'owa-client-types';
import { lazyInitializeLokiConfiguration } from 'owa-photo-picker-dialog';
import { lazyGetTopicBootstrapperConfig } from 'owa-topic-annotations/lib/lazyFunctions';
import getAccountInfo from 'owa-topic-annotations/lib/utils/getAccountInfo';
import { isTopicsMultiAccountEnabled } from './TopicsFeatureGate';

let topicsSdkPromise: Promise<TopicsSDK | undefined> | undefined = undefined;
export default async function getTopicsSdkAsync(
    mailboxInfo: MailboxInfo
): Promise<TopicsSDK | undefined> {
    // The application setting for loadSDK is set to false in AirGap, ,
    // don't load the SDK there.
    if (!getApplicationSettings('Topics').loadSDK) {
        return Promise.resolve(undefined);
    }
    if (isTopicsMultiAccountEnabled()) {
        return lazyGetTopicBootstrapperConfig
            .importAndExecute(mailboxInfo)
            .then(bootstrapperConfig => {
                return getTopicsSdk(bootstrapperConfig, getAccountInfo(mailboxInfo));
            })
            .catch(error => {
                return Promise.reject(error);
            });
    } else {
        if (!topicsSdkPromise) {
            await lazyInitializeLokiConfiguration.importAndExecute();
            // Initialize LPC initializes the Loki configuration, which is needed for the Topics SDK initialization.
            topicsSdkPromise = lazyGetTopicBootstrapperConfig
                .importAndExecute(mailboxInfo)
                .then(bootstrapperConfig => {
                    return getTopicsSdk(bootstrapperConfig);
                })
                .catch(error => {
                    // todo log with data point
                    return Promise.reject(error);
                });
        }
        return topicsSdkPromise;
    }
}
