import { LazyAction, LazyModule, registerLazyOrchestrator } from 'owa-bundling';
import { onStartChatViaSipProtocol, onStartCallViaSipProtocol } from 'owa-nova-actions';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "OwaTeamsIntegration" */ './lazyIndex')
);

registerLazyOrchestrator(onStartChatViaSipProtocol, lazyModule, m => m.startChatViaSipProtocol);
registerLazyOrchestrator(onStartCallViaSipProtocol, lazyModule, m => m.startCallViaSipProtocol);

// Lazily-loaded Components
export const lazyChatAroundEmail = new LazyAction(lazyModule, m => m.chatAroundEmail);

export { TeamsRespondType } from './types/TeamsRespondType';
