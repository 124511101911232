import { FluentButton } from 'owa-fluent-v9-shims';
import { MoveFolderDocument } from 'owa-folder-movefolder/lib/graphql/__generated__/MoveFolderMutation.interface';
import { Dialog, DialogFooter, DialogType } from '@fluentui/react/lib/Dialog';
import type { MailboxInfo, MailboxType } from 'owa-client-types';
import loc from 'owa-localize';
import { cancelButton } from 'owa-locstrings/lib/strings/cancelbutton.locstring.json';
import Strings from 'owa-locstrings/lib/strings/move.locstring.json';
import { resetFocus } from 'owa-mail-focus-manager';
import { lazyMoveFolder } from 'owa-folder-movefolder';
import { MoveToFolder } from 'owa-mail-moveto-control';
import React from 'react';
import { useManagedMutation } from 'owa-apollo-hooks';
import type { MailFolder } from 'owa-graph-schema';

import { moveFolderContainer, moveFolderBody } from './MailFolderDialog.scss';

const modalDialogProps = {
    containerClassName: moveFolderContainer,
};

const folderSupportedMailboxTypes: MailboxType[] = [
    'UserMailbox',
    'ArchiveMailbox',
    'SharedMailbox',
];

export interface MoveToFolderDialogProps {
    onClose: () => void;
    sourceFolder: MailFolder;
}

export const MoveToFolderDialog = function MoveToFolderDialog(props: MoveToFolderDialogProps) {
    const [moveFolderMutation] = useManagedMutation(MoveFolderDocument);
    const [moveToFolderId, setMoveToFolderId] = React.useState<string | undefined>(undefined);
    const [showHideDialog, setShowHideDialog] = React.useState<boolean>(true);
    const [destinationFolderMailboxInfo, setDestinationFolderMailboxInfo] = React.useState<
        MailboxInfo | undefined
    >(undefined);

    const moveDialogContentProps = React.useMemo(() => {
        return {
            type: DialogType.normal,
            title: loc(Strings.moveFolderTo, props.sourceFolder.displayName),
        };
    }, [props.sourceFolder.displayName]);

    const onDismiss = React.useCallback(() => {
        setShowHideDialog(false /*shouldShow*/);
        setMoveToFolderId(undefined);
        props.onClose();
        resetFocus('MoveToFolderDialogDismissed');
    }, [props.onClose]);

    const onMoveToFolderClicked = (folderId: string, mailboxInfo: MailboxInfo) => {
        setMoveToFolderId(folderId);
        setDestinationFolderMailboxInfo(mailboxInfo);
    };

    const onMoveToDismiss = () => {
        onDismiss;
    };
    const renderDialogFooter = (): JSX.Element => {
        return (
            <DialogFooter>
                {renderDoneButton()}
                {renderDismissButton()}
            </DialogFooter>
        );
    };
    const renderDoneButton = (): JSX.Element => {
        return (
            <FluentButton
                appearance="primary"
                disabled={props.sourceFolder.id === moveToFolderId}
                onClick={onMoveToFolder}
            >
                {loc(Strings.move)}
            </FluentButton>
        );
    };
    const onMoveToFolder = (ev: any) => {
        ev.preventDefault();
        if (moveToFolderId && destinationFolderMailboxInfo) {
            lazyMoveFolder.importAndExecute(
                moveFolderMutation,
                moveToFolderId /*destinationFolderId */,
                destinationFolderMailboxInfo,
                props.sourceFolder.id,
                props.sourceFolder.mailboxInfo,
                props.sourceFolder.parentFolderId,
                props.sourceFolder.displayName
            );
        }
        onDismiss();
    };
    const renderDismissButton = (): JSX.Element => {
        return <FluentButton onClick={onDismiss}>{loc(cancelButton)}</FluentButton>;
    };
    const renderDialogBody = (): JSX.Element => {
        return <div className={moveFolderBody}>{renderFolderMenu()}</div>;
    };
    const renderFolderMenu = (): JSX.Element => {
        return (
            <MoveToFolder
                actionSource={'MoveToLeftNav'}
                dismissMenu={onMoveToDismiss}
                shouldShowSearchBox={false}
                supportedMailboxTypes={folderSupportedMailboxTypes}
                onFolderClick={onMoveToFolderClicked}
                defaultInAllFolders={true}
                shouldHideNewFolder={true}
                shouldStyleSelectedFolder={true}
                selectedFolderId={moveToFolderId}
                shouldShowRootFolder={true}
                sourceFolderId={props.sourceFolder.id}
                mailboxInfo={props.sourceFolder.mailboxInfo}
                shouldHideCurrentFolder={true}
                currentFolderId={props.sourceFolder.id}
            />
        );
    };
    return (
        <Dialog
            hidden={!showHideDialog}
            onDismiss={onDismiss}
            dialogContentProps={moveDialogContentProps}
            modalProps={modalDialogProps}
        >
            {renderDialogBody()}
            {renderDialogFooter()}
        </Dialog>
    );
};
