import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { logUsage } from 'owa-analytics';
import {
    constructKeytip,
    createAppButton,
    getActionSourceFromCommandingViewMode,
} from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import Save from 'owa-fluent-icons-svg/lib/icons/SaveRegular';
import loc from 'owa-localize';
import { save } from 'owa-mail-compose-command';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { save_Button } from '../strings/actionControlStrings.locstring.json';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import { saveDraftDesc } from '../strings/hoverTooltipDesc.locstring.json';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { type SaveSource } from 'owa-mail-compose-store';

const onSaveDraft = (
    composeId: string,
    retriever: (composeId: string) => RibbonMailComposeInfo | null
) => {
    const editingInfo = retriever(composeId);
    const viewState = editingInfo?.composeViewState;
    if (viewState) {
        // Other methods of save have their own logging pipeline with which it would be difficult to merge
        logUsage('SaveDraft', {
            actionSource: getActionSourceFromCommandingViewMode(),
        });
        save(viewState, 1);
    }
};

export const getSaveControl = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonMailComposeInfo | null,
        tabId: number | undefined,
        styles: IButtonStyles | undefined,
        iconColor: string | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        return createAppButton(
            getComposeRibbonId(560, editorId),
            loc(save_Button),
            getComputedCallback(560, onSaveDraft, composeId, retriever),
            constructKeytip(tabId ? [tabId] : [], 560),
            Save,
            iconColor,
            styles,
            {
                customTooltip: getHoverTooltip(save_Button, saveDraftDesc),
                disabled: isDisabled,
            }
        );
    }
);
