import setFocusedRowKey from './mutators/setFocusedRowKey';
import setMultiSelectionAnchorRowKey from './mutators/setMultiSelectionAnchorRowKey';
import setSelectionAnchorRowKey from './mutators/setSelectionAnchorRowKey';
import resetSelectionInternal from './resetSelectionInternal';
import { setSelectionOnRow } from 'owa-mail-actions/lib/setSelectionOnRow';
import { isReadingPanePositionOff } from 'owa-mail-layout';
import type { TableView } from 'owa-mail-list-store';
import { MailRowDataPropertyGetter } from 'owa-mail-list-store';
import getInstrumentationContextsFromTableView from 'owa-mail-list-store/lib/utils/getInstrumentationContextsFromTableView';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import { openTableRowInPopout } from 'owa-popout-utils';
import type ReadingPanePopoutItemFolderInfo from 'owa-popout-utils/lib/schema/ReadingPanePopoutItemFolderInfo';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * satcheljs/lib/legacy imports are not allowed
 *	> 'satcheljs/lib/legacy' import is restricted from being used. */
import { action } from 'satcheljs/lib/legacy';
import { lazyPreparePopoutDataForReadingPane } from 'owa-mail-folder-common';
import {
    addCustomDataToSelectMailItemDatapoint,
    endSelectMailItemDatapoint,
} from '../utils/selectMailItemDatapointUtils';
import { isFeatureEnabled } from 'owa-feature-flags';
import endSmiV2 from 'owa-mail-logging/lib/utils/endSelectMailItemDatapoint';
import { getSelectMailItemDatapoint } from 'owa-mail-logging/lib/utils/selectMailItemDatapointGetterAndSetter';

// Bug 32656: In an orchestrator, the render is triggered several times
// Due to this bug, ReadingPane.render() is triggered several times inside this method.
// To solve the reading pane perf issue, wrap this function as an action.
// When the bug is resolve, please remove the action wrapper.

/**
 * Single select mail row
 * @param tableView where the operation is being performed
 * @param rowKey the rowKey of the item to select
 * @param mailListItemSelectionSource The source of selection on mail item
 */
export default action('singleSelectRowInternal')(function singleSelectRowInternal(
    tableView: TableView,
    rowKey: string,
    mailListItemSelectionSource: MailListItemSelectionSource
) {
    if (shouldOpenPopout(mailListItemSelectionSource)) {
        const rowKeyValue =
            mailListItemSelectionSource ===
            MailListItemSelectionSource.MailListItemSimulatedDoubleClick
                ? rowKey
                : [...tableView.selectedRowKeys.keys()][0];
        const instrumentationContext = getInstrumentationContextsFromTableView(
            [rowKeyValue],
            tableView
        )[0];
        openTableRowInPopout(
            tableView.tableQuery.listViewType,
            MailRowDataPropertyGetter.getRowClientItemId(rowKeyValue, tableView),
            async () => {
                const preparePopoutDataForReadingPane =
                    await lazyPreparePopoutDataForReadingPane.import();
                const folderInfo = preparePopoutDataForReadingPane(tableView);
                return {
                    ...folderInfo,
                    instrumentationContext,
                } as ReadingPanePopoutItemFolderInfo;
            }
        );

        // For simulated double click, don't return early so that the selection
        // logic kicks in and allows the auto mark as read behavior to work.
        if (
            mailListItemSelectionSource !==
            MailListItemSelectionSource.MailListItemSimulatedDoubleClick
        ) {
            return;
        }
    }

    const isSingleRowSelected =
        tableView.selectedRowKeys.has(rowKey) && tableView.selectedRowKeys?.size === 1;

    // Simply log and retrun if user is selecting the same item and that item is focused unless
    // user has reading pane setting as hidden (single line view) AND the reading pane should be opened.
    if (
        isSingleRowSelected &&
        rowKey === tableView.focusedRowKey &&
        !shouldOpenReadingPaneInSingleLineView(mailListItemSelectionSource)
    ) {
        // Add custom data to selectMailItem/selectMailItemNonCritical datapoint
        addCustomDataToSelectMailItemDatapoint(
            mailListItemSelectionSource,
            tableView,
            rowKey,
            isFeatureEnabled('rp-enhanced-telemetry-v2')
                ? getSelectMailItemDatapoint()
                : undefined /* datapoint */
        );
        // End the SelectMailItemNonCritical datapoint here and invalidate since reading pane
        // is already loaded.
        endSelectMailItemDatapoint(true /* shouldInvalidate */);
        isFeatureEnabled('rp-enhanced-telemetry-v2') &&
            endSmiV2('' /* contentType */, true /* shouldInvalidate */);
        return;
    }

    // If this rowKey is the only selected row and its twisty is being toggled, there is no need to reset and set anything new.
    if (
        !(
            mailListItemSelectionSource == MailListItemSelectionSource.MailListItemTwisty &&
            isSingleRowSelected
        )
    ) {
        // Reset list view selection
        resetSelectionInternal(tableView);
        setFocusedRowKey(tableView, rowKey);

        // Set the selection anchor for possible future range, keyboard, or multi selections
        setSelectionAnchorRowKey(tableView, rowKey);
        setMultiSelectionAnchorRowKey(tableView, null);

        // Select new item
        setSelectionOnRow(rowKey, tableView.id, true /* shouldSelect */);
    }
});

// Open popout if user is double clicking item or using the popout shortcut regardless of selection state
function shouldOpenPopout(selectionSource: MailListItemSelectionSource): boolean {
    return (
        selectionSource === MailListItemSelectionSource.MailListItemBodyDoubleClick ||
        selectionSource === MailListItemSelectionSource.MailListItemSimulatedDoubleClick ||
        selectionSource === MailListItemSelectionSource.KeyboardShiftEnter
    );
}

// Reading pane should be opened in single line view if message was selected via keyboard enter,
// clicking on mail item body, clicking on the preview image/doc or via route handler.
function shouldOpenReadingPaneInSingleLineView(
    selectionSource: MailListItemSelectionSource
): boolean {
    return (
        isReadingPanePositionOff() &&
        (selectionSource == MailListItemSelectionSource.KeyboardEnter ||
            selectionSource == MailListItemSelectionSource.MailListItemBody ||
            selectionSource == MailListItemSelectionSource.MailListItemRichPreview ||
            selectionSource == MailListItemSelectionSource.RouteHandler)
    );
}
