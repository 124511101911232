import type { IButtonStyles } from '@fluentui/react';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppToggleButton } from 'owa-command-ribbon';
import type RibbonFormatState from 'owa-editor-ribbonplugin-store/lib/store/schema/RibbonFormatState';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import StrikeThrough from 'owa-fluent-icons-svg/lib/icons/TextStrikethroughRegular';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { strikethroughText } from './formatControlStrings.locstring.json';
import type RibbonToggleFormatControlInfo from '../schema/RibbonToggleFormatControlInfo';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { onToggleButtonClicked } from '../util/commandActions';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';

export const getStrikeThroughControl = owaComputedFn(
    (
        editorId: string,
        composeId: string | undefined,
        retriever: ((composeId: string) => RibbonToggleFormatControlInfo | null) | undefined,
        formatViewState: RibbonFormatState,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean,
        source: FormattingSource
    ): RibbonControlProps => {
        return createAppToggleButton(
            getComposeRibbonId(6004, editorId),
            loc(strikethroughText),
            getComputedCallback(
                6004,
                onToggleButtonClicked,
                editorId,
                composeId,
                'toggleStrikethrough',
                retriever,
                source
            ),
            !!formatViewState.isStrikeThrough,
            tabId ? constructKeytip([tabId], 6004) : undefined,
            StrikeThrough,
            undefined /* iconColor */,
            styles,
            {
                disabled: isDisabled,
            }
        );
    }
);
