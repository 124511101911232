import type { AppCheckboxProps } from '@1js/acui-checkbox';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppCheckBox } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import { focusToEditor } from 'owa-editor';
import loc from 'owa-localize';
import toggleFrom from 'owa-mail-compose-actions/lib/actions/toggleFrom';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { toggleFromText } from '../strings/actionControlStrings.locstring.json';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import getCheckboxRootStyles from '../utils/getCheckboxRootStyles';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { showFromDesc } from '../strings/hoverTooltipDesc.locstring.json';

const onShowFromCheck = (
    composeId: string,
    retriever: (composeId: string) => RibbonMailComposeInfo | null
) => {
    const editingInfo = retriever(composeId);
    if (editingInfo) {
        const viewState = editingInfo.composeViewState;
        toggleFrom(viewState);
        focusToEditor(viewState);
    }
};

export const getShowFrom = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonMailComposeInfo | null,
        styles: IButtonStyles | undefined,
        keyTipTabId: number | undefined,
        isDisabled: boolean
    ): AppCheckboxProps => {
        return createAppCheckBox(
            getComposeRibbonId(7009, editorId),
            loc(toggleFromText),
            getComputedCallback(7009, onShowFromCheck, composeId, retriever),
            !!retriever(composeId)?.composeViewState.fromViewState.isFromShown /* isChecked */,
            keyTipTabId ? constructKeytip([keyTipTabId], 7009) : undefined,
            {
                ...styles,
                root: getCheckboxRootStyles(),
            },
            {
                customTooltip: getHoverTooltip(toggleFromText, showFromDesc),
                disabled: isDisabled,
            }
        );
    }
);
