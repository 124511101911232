import { createStore } from 'satcheljs';
import type {
    SummaryExpandedKey,
    ThreadSummarizationCacheEntry,
    ThreadSummarizationStore,
} from './schema/ThreadSummarizationStore';
import { ObservableMap } from 'mobx';
import { MruCache } from 'owa-mru-cache';

function buildEmptyCache() {
    const cache = new MruCache<ThreadSummarizationCacheEntry>(10);
    cache.initialize(new ObservableMap<string, ThreadSummarizationCacheEntry>());
    return cache;
}

const store = createStore<ThreadSummarizationStore>('threadSummarization', {
    cachedSummaries: buildEmptyCache(),
    currentFeedback: {},
    isCardDismissed: false,
    isCurrentSummaryExpanded: new ObservableMap<SummaryExpandedKey, boolean>(),
    isSummaryButtonDisplayed: false,
})();

export default store;
