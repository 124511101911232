import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppFlyoutAnchor } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import Table from 'owa-fluent-icons-svg/lib/icons/TableRegular';
import loc from 'owa-localize';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import type { RibbonEditingInfo } from 'owa-compose-actioncontrols-sections/lib/schema/RibbonEditingInfo';
import { tableText } from '../strings/actionControlStrings.locstring.json';
import { tableButtonDesc } from '../strings/hoverTooltipDesc.locstring.json';
import { onTableMenuClicked } from '../actions/deferredMenuDefinitionMutator';
import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import type { FormattingSource } from 'owa-editor-command/lib/schema/FormattingSource';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';
import { isFeatureEnabled } from 'owa-feature-flags';

export const getTableControl = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonEditingInfo | null,
        keyTipTabId: number | undefined,
        styles: IButtonStyles | undefined,
        isDisabled: boolean,
        source: FormattingSource
    ): RibbonControlProps => {
        const tooltip = getHoverTooltip(tableText, tableButtonDesc);
        const keytip = keyTipTabId ? constructKeytip([keyTipTabId], 637) : undefined;
        const storeName = 'tableMenuDefinitionStore';
        return createAppFlyoutAnchor(
            getComposeRibbonId(637, editorId),
            loc(tableText),
            getControlMenuStore(editorId, storeName),
            keytip,
            Table,
            undefined /*iconColors*/,
            styles,
            {
                customTooltip: tooltip,
                disabled: isDisabled,
                onExecute: () => {
                    onTableMenuClicked(editorId, composeId, retriever, keytip, tooltip, source);
                },
                onAfterMenuDismiss: () => {
                    resetMenuDefinition(editorId, storeName);
                    if (!isFeatureEnabled('cmp-v9-dialog')) {
                        // This is stealing focus away from the V9 dialog
                        callEditorApi(editorId, 'focus');
                    }
                },
            }
        );
    }
);
