import React from 'react';
import { SearchHeader } from 'owa-mail-search';
import type { SearchTableQuery } from 'owa-mail-list-search';
import { getSelectedTableView } from 'owa-mail-list-store';
import { SelectAllCheckbox } from 'owa-mail-list-view';
import { lazyGetIsNonMailEntitySearchTab, TabbedSearchPane } from 'owa-tabbed-search';
import MailView from './MailView';
import { observer } from 'owa-mobx-react';
import { SearchScopeKind } from 'owa-search-service/lib/data/schema/SearchScope';
import {
    searchResults,
    searchResultsFromArchive,
} from 'owa-locstrings/lib/strings/searchresults.locstring.json';
import loc from 'owa-localize';
import {
    searchHeaderContainer,
    searchHeaderBorders,
    searchResultsContainer,
    newSearchHeaderContainer,
} from './MailModule.scss';

export default observer(function SearchView() {
    const tableView = getSelectedTableView();
    const tableViewId = tableView.id;
    const searchTableQuery = tableView?.tableQuery as SearchTableQuery;
    const getIsNonMailEntitySearchTab = lazyGetIsNonMailEntitySearchTab.tryImportForRender();
    const showTabbedSearchPane = getIsNonMailEntitySearchTab
        ? getIsNonMailEntitySearchTab()
        : false;
    const isPrimaryMailBox = searchTableQuery.searchScope?.kind === SearchScopeKind.PrimaryMailbox;
    const searchResultHeaderText =
        searchTableQuery.searchScope?.kind == SearchScopeKind.ArchiveMailbox
            ? loc(searchResultsFromArchive)
            : loc(searchResults);

    const getSearchHeader = React.useMemo(() => {
        if (searchTableQuery == undefined) {
            return;
        } else {
            return (
                <div className={searchHeaderContainer}>
                    <div className={searchHeaderBorders}>
                        <SearchHeader
                            searchScenarioId={searchTableQuery.searchScenarioId}
                            noTabsEnabledTitleText={searchResultHeaderText}
                            isPrimaryMailBox={isPrimaryMailBox}
                        >
                            <SelectAllCheckbox
                                tableViewId={tableViewId}
                                isInboxWithPivots={true}
                                isSearchRender={true}
                            />
                        </SearchHeader>
                    </div>
                </div>
            );
        }
    }, [tableViewId, searchTableQuery]);

    const getSearchResultsContainer = React.useMemo(() => {
        if (showTabbedSearchPane) {
            return (
                <div className={searchResultsContainer}>
                    <TabbedSearchPane header={undefined} />
                </div>
            );
        } else {
            return (
                <div className={searchResultsContainer}>
                    <MailView />
                </div>
            );
        }
    }, [showTabbedSearchPane]);

    return (
        <div className={newSearchHeaderContainer}>
            {getSearchHeader}
            {getSearchResultsContainer}
        </div>
    );
}, 'SearchView');
