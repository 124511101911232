import { LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "GroupsSharedFoldersStore"*/ './lazyIndex')
);
export const lazyGetGroupFolderName = new LazyImport(lazyModule, m => m.getGroupFolderName);
export const lazyHasUserCreatedFolders = new LazyImport(lazyModule, m => m.hasUserCreatedFolders);
export const lazyGetGroupFolderChildFolderIds = new LazyImport(
    lazyModule,
    m => m.getGroupFolderChildFolderIds
);
export const lazyGetGroupFolderId = new LazyImport(lazyModule, m => m.getGroupFolderId);
export const lazyGetGroupFolderTreeLoadingState = new LazyImport(
    lazyModule,
    m => m.getGroupFolderTreeLoadingState
);
