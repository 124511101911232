import getMLRTableFormatTab from './mlr-table-format-tab/getMLRTableFormatTab';
import getMLRPictureFormatTab from './mlr-picture-format-tab/getMLRPictureFormatTab';
import { owaComputedFn } from 'owa-computed-fn';
import type { RibbonTabDefinitionBase } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { getMailRibbonConfigStore } from 'owa-mail-ribbon-store/lib/store/store';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import { isFeatureEnabled } from 'owa-feature-flags';
import { errorThatWillCauseAlert } from 'owa-trace';

const getContextualMlrTabsDefinition = owaComputedFn(function getInitialScalingSteps(
    props: ComposeRibbonControlDefProps
): RibbonTabDefinitionBase[] {
    const tabs: RibbonTabDefinitionBase[] = [];
    let contextualTabs: MailRibbonTabId[] | undefined = undefined;

    if (isFeatureEnabled('cmp-multiWindows-contextualTabs')) {
        const editorId: string = props.composeViewState.editorId;
        contextualTabs = getMailRibbonConfigStore(props.mailboxInfo).contextualTabsMultiWindows.get(
            editorId
        );
    } else {
        contextualTabs = getMailRibbonConfigStore(props.mailboxInfo).contextualTabs;
    }

    contextualTabs?.forEach(tab => {
        switch (tab) {
            case 7:
                tabs.push(getMLRTableFormatTab(props));
                break;
            case 13:
                tabs.push(getMLRPictureFormatTab(props));
                break;
            default:
                errorThatWillCauseAlert(
                    'Unknown tab: ' + tab + ' searched for in getContextualMlrTabsDefinition'
                );
                break;
        }
    });

    return tabs;
});

export default getContextualMlrTabsDefinition;
