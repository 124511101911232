import type { ComposeViewState } from 'owa-mail-compose-store';
import { mutatorAction } from 'satcheljs';
import createRecipientWell from 'owa-readwrite-recipient-well/lib/utils/createRecipientWell';
import SubstrateSearchScenario from 'owa-search-service/lib/data/schema/SubstrateSearchScenario';

export default mutatorAction('toggleBcc', function toggleBcc(viewState: ComposeViewState) {
    viewState.isBccShown = !viewState.isBccShown;
    if (viewState.isBccShown && viewState.showCompactRecipientWell) {
        viewState.showCompactRecipientWell = false;
    }
    if (viewState.isBccShown && !viewState.bccRecipientWell) {
        viewState.bccRecipientWell = createRecipientWell(
            viewState.toRecipientWell.mailboxInfo,
            viewState.toRecipientWell.findPeopleFeedbackManager,
            SubstrateSearchScenario.MailCompose
        );
    }
});
