import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import { lazyCloseChecker, lazyOpenChecker } from 'owa-editor-acc-checker';
import AccCheckmark from 'owa-fluent-icons-svg/lib/icons/AccessibilityCheckmarkRegular';
import loc from 'owa-localize';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import type { RibbonAccCheckerInfo } from '../schema/RibbonEditingInfo';
import { accCheckerText } from '../strings/actionControlStrings.locstring.json';
import { accCheckerDescription } from '../strings/hoverTooltipDesc.locstring.json';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';

const onToggleAccChecker = (
    composeId: string,
    retriever: (composeId: string) => RibbonAccCheckerInfo | null
) => {
    const editingInfo = retriever(composeId);
    if (editingInfo) {
        const { editorViewState, targetWindow, accChecker } = editingInfo;

        if (accChecker.isOpen) {
            lazyCloseChecker.import().then(closeChecker => closeChecker(accChecker, targetWindow));
        } else {
            lazyOpenChecker
                .import()
                .then(openChecker => openChecker(accChecker, editorViewState, targetWindow));
        }
    }
};

export const getAccChecker = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonAccCheckerInfo | null,
        styles: IButtonStyles | undefined,
        keyTipTabId: number | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        return createAppButton(
            getComposeRibbonId(7004, editorId),
            loc(accCheckerText),
            getComputedCallback(7004, onToggleAccChecker, composeId, retriever),
            keyTipTabId ? constructKeytip([keyTipTabId], 7004) : undefined,
            AccCheckmark,
            undefined /* iconColor */,
            styles,
            {
                customTooltip: getHoverTooltip(accCheckerText, accCheckerDescription),
                disabled: isDisabled,
            }
        );
    }
);
