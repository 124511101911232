import { OcsSettingScope, OcsVersion, readOcsSettings } from 'owa-ocs-service';
import { CONDITIONAL_FORMATTING_SETTING_NAME } from '../utils/constants';
import { setConditionalFormattingRules } from '../actions/setConditionalFormattingRules';
import type { MailboxInfo } from 'owa-client-types';

export async function fetchConditionalFormattingRulesService(mailboxInfo: MailboxInfo) {
    const ocsResponse: any[] = await readOcsSettings(
        mailboxInfo,
        OcsVersion.V1,
        OcsSettingScope.Account,
        [CONDITIONAL_FORMATTING_SETTING_NAME]
    );
    if (ocsResponse[0]?.value) {
        setConditionalFormattingRules(
            mailboxInfo,
            JSON.parse(ocsResponse[0]?.value as string),
            true /* is from fetch*/
        );
    } else {
        //set empty value
        setConditionalFormattingRules(mailboxInfo, []);
    }
}
