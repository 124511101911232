import { createLazyComponent, LazyImport, LazyModule } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "TopicDropdown" */ './lazyIndex'),
    { name: 'TopicDropdown' }
);

// Delayed Loaded Components
export const TopicDropdownButton = createLazyComponent(lazyModule, m => m.TopicDropdownButton);
export const InfoPaneTopics = createLazyComponent(lazyModule, m => m.InfoPaneTopics);

export const lazyFetchTopics = new LazyImport(lazyModule, m => m.fetchTopics);
