import type { MailboxInfo } from 'owa-client-types';
import type ProtectionStore from 'owa-mail-protection-types/lib/schema/ProtectionStore';
import { getStore as getProtectionStore } from 'owa-protection-store/lib/store/protectionStore';

export function isAnyDLPPolicyEnforced(mailboxInfo?: MailboxInfo): boolean {
    // Default to true if we don't have the dlpRuleConfig, which can happen sometimes when GWS does not give proper response
    // VSO.249268 has been created to investigate the flakiness of GWS
    let checkPolicyEnforced: boolean = true;

    if (mailboxInfo) {
        const protectionStore: ProtectionStore = getProtectionStore(mailboxInfo);
        const { dlpRuleConfig } = protectionStore;

        if (dlpRuleConfig) {
            checkPolicyEnforced = dlpRuleConfig.isAnyPolicyEnforced;
        }
    }

    return checkPolicyEnforced;
}
