import { rowWrapper, listAutoSizer, row } from './VirtualizedLoadMoreListView.scss';
import classNames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import { isCurrentCultureRightToLeft } from 'owa-localize';
import { useComputedValue } from 'owa-react-hooks/lib/useComputed';
import { useElementSizeTracker } from 'owa-react-hooks/lib/useElementSizeTracker';
import { useResizeObserver } from 'owa-react-hooks/lib/useResizeObserver';
import React from 'react';
import { VariableSizeList } from 'react-window';
import type { ListOnItemsRenderedProps } from 'react-window';
import type { LoadMoreListViewProps } from './LoadMoreListView';
import debounce from 'lodash-es/debounce';

// PAGINATION_PREFETCH_BUFFER defines the size of the preload pagination buffer
// height as a factor of the viewport height. For example, if the viewport height
// is 1000px and the PAGINATION_PREFETCH_BUFFER is 2, then the preload buffer
// height will be 2000px. When the user scrolls within 2000px of the bottom
// of the list, props.onLoadMore will be called to begin prefetching the next
// "page" of rows. Akin to LoadMoreListView's DEFAULT_GUARD_PAGE_COUNT.
const PAGINATION_PREFETCH_BUFFER = 2;

export interface LoadMoreListViewExtendedVirtualizedProps {
    // Estimated height of a row being windowed - This value is used to calculated
    // the estimated total size of a list before its items have all been measured.
    // The total size impacts user scrolling behavior. It is updated whenever new
    // items are measured.
    estimatedRowHeight: number;
    updateStartAndEndIndices: (start: number, end: number) => void;
    activeAnimationsCount: number;
    hiddenRowIndices: number[];
}

export interface VirtualizedLoadMoreListViewProps
    extends Pick<
            LoadMoreListViewProps,
            | 'itemIds'
            | 'onRenderRow'
            | 'onRenderHeader'
            | 'loadingComponent'
            | 'rowWrapperClass'
            | 'PreRowsComponent'
            | 'MidRowsComponent'
            | 'midRowsComponentRowNumber'
            | 'PostRowsComponent'
            | 'listProps'
            | 'onLoadMoreRows'
            | 'isLoadRowsInProgress'
            | 'getCanLoadMore'
            | 'onDidUpdate'
            | 'currentLoadedIndex'
            | 'onScroll'
            | 'className'
            | 'dataSourceId'
            | 'focusedRowKeyIndex'
            | 'focusedNodeId'
        >,
        LoadMoreListViewExtendedVirtualizedProps {}

// VirtualizedLoadMoreListView renders a list of items using react-window by
// defining a renderRow callback function which is provided to the VariableSizedSize
// via props.children. This renderRow callback needs access to several
// VirtualizedLoadMoreListViewProps which change frequently (currentLoadedIndex,
// itemIds, isLoadRowsInProgress, etc.) but recreating the callback function each
// time is expensive, because react-window has to re-render all the rows.
// Instead, of prop-drilling, we can provide data to the children via React.Context
// without invalidating the VariableSizeList.
interface RenderRowContextProps
    extends Pick<
        VirtualizedLoadMoreListViewProps,
        | 'itemIds'
        | 'currentLoadedIndex'
        | 'isLoadRowsInProgress'
        | 'listProps'
        | 'loadingComponent'
        | 'rowWrapperClass'
        | 'onRenderRow'
        | 'onRenderHeader'
        | 'PreRowsComponent'
        | 'MidRowsComponent'
        | 'midRowsComponentRowNumber'
        | 'PostRowsComponent'
    > {
    rowHeightChanged: ((index: number, size: number) => void) | undefined;
}

const RenderRowContext = React.createContext<RenderRowContextProps>({
    itemIds: [],
    currentLoadedIndex: 0,
    isLoadRowsInProgress: false,
    listProps: undefined,
    loadingComponent: undefined,
    onRenderRow: () => <></>,
    onRenderHeader: undefined,
    rowHeightChanged: undefined,
    PreRowsComponent: undefined,
    MidRowsComponent: undefined,
    midRowsComponentRowNumber: -1,
    PostRowsComponent: undefined,
});

export interface VirtualizedLoadMoreListViewRef {
    getScrollRegion: () => HTMLDivElement | undefined;
    setFocus: () => void;
}

export default observer(
    React.forwardRef<VirtualizedLoadMoreListViewRef, VirtualizedLoadMoreListViewProps>(
        function VirtualizedLoadMoreListView(
            props: VirtualizedLoadMoreListViewProps,
            ref: React.Ref<VirtualizedLoadMoreListViewRef>
        ) {
            const {
                className,
                currentLoadedIndex,
                estimatedRowHeight,
                getCanLoadMore,
                isLoadRowsInProgress,
                onLoadMoreRows,
                onScroll,
                focusedRowKeyIndex,
                focusedNodeId,
                updateStartAndEndIndices,
                activeAnimationsCount,
                hiddenRowIndices,
            } = props;
            const listAutoSizerRef = React.useRef<HTMLDivElement>(null);
            const listRef = React.useRef<VariableSizeList>(null);

            const scrollingRegionRef = React.useRef<HTMLDivElement | null>(null);
            const [scrollingRegion, setScrollingRegionInner] =
                React.useState<HTMLDivElement | null>(null);

            const setScrollingRegion = React.useCallback(
                (newScrollingRegion: HTMLDivElement | null) => {
                    scrollingRegionRef.current = newScrollingRegion;
                    setScrollingRegionInner(newScrollingRegion);
                },
                []
            );

            // react-window List supports RTL via the style param (https://github.com/bvaughn/react-window/issues/148#issuecomment-468486300)
            const isRtl = isCurrentCultureRightToLeft();
            const rtlStyle: React.CSSProperties = React.useMemo(() => {
                return isRtl
                    ? {
                          direction: 'rtl',
                      }
                    : {};
            }, [isRtl]);

            const onScrollRef = React.useRef(onScroll);
            onScrollRef.current = onScroll;
            const rowHeights = React.useRef<number[]>([]);
            const [listAutoSizerRect] = useElementSizeTracker(
                'VirtualizedLoadMoreListView_ET',
                listAutoSizerRef
            );

            // When the data source changes, scroll to the top of the list. This provides
            // parity with LoadMoreDataZone.resetScrollPosition usage.
            const currentDataSourceId = props.dataSourceId;
            React.useEffect(() => {
                const scrollingRegionRefVal = scrollingRegionRef.current;
                /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
                 * Baseline, please provide a proper justification if touching this code
                 *	> 'scrollTop' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
                if (scrollingRegionRefVal && scrollingRegionRefVal.scrollTop !== 0) {
                    /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
                     * Baseline, please provide a proper justification if touching this code
                     *	> 'scrollTop' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
                    scrollingRegionRefVal.scrollTop = 0;
                }
            }, [currentDataSourceId]);

            React.useEffect(() => {
                if (scrollingRegion) {
                    const onScrollingRegionScroll = (evt: Event) => {
                        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
                         * Baseline, please provide a proper justification if touching this code
                         *	> 'scrollTop' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
                        const scrollTop = scrollingRegion.scrollTop;
                        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
                         * Baseline, please provide a proper justification if touching this code
                         *	> 'offsetHeight' is restricted from being used. This property can cause performance problems by causing re-layouts. Please avoid if possible; if not, move to a requestAnimationFrame callback, and perform all DOM reads before performing any writes. */
                        const offsetHeight = scrollingRegion.offsetHeight;
                        onScrollRef.current?.(evt, scrollTop, offsetHeight);
                    };
                    scrollingRegion.addEventListener('scroll', onScrollingRegionScroll);

                    return function cleanup() {
                        scrollingRegion.removeEventListener('scroll', onScrollingRegionScroll);
                    };
                }

                return undefined; // no cleanup needed
            }, [scrollingRegion]);

            const debounceForceUpdate = debounce(
                () => {
                    listRef.current?.forceUpdate();
                },
                10,
                {
                    leading: true,
                    trailing: true,
                }
            );

            const rowHeightChanged = React.useCallback(
                (index: number, size: number, isBeingCollapsed: boolean = false) => {
                    if (rowHeights.current[index] !== size) {
                        const wasPreviouslyHidden = rowHeights.current[index] === 0;

                        // Update the measured height for the row (that react-window
                        // will use to calculate the scroll position and item sizes)
                        rowHeights.current[index] = size;

                        // If a row was previously hidden and is now being re-rendered
                        // with a non-zero height, then it's the scenario where a previously
                        // collapsed group is expanded and we can debounce the visual
                        // update to avoid layout thrashing.
                        //
                        // We can also debounce the visual update if the row is being
                        // collapsed, as it will likely be followed by a series of
                        // other rows being collapsed and we can batch the visual
                        // update to avoid layout thrashing.
                        const shouldForceUpdateImmediately =
                            !wasPreviouslyHidden && !isBeingCollapsed;

                        // This clears the cached row heights and forces a re-render
                        // of the list. This is necessary to ensure that the list
                        // correctly updates item sizes.
                        listRef.current?.resetAfterIndex(index, shouldForceUpdateImmediately);

                        if (!shouldForceUpdateImmediately) {
                            debounceForceUpdate();
                        }
                    }
                },
                []
            );

            const renderRowContextValue = useComputedValue(
                (): RenderRowContextProps => ({
                    itemIds: props.itemIds,
                    currentLoadedIndex,
                    isLoadRowsInProgress,
                    listProps: props.listProps,
                    loadingComponent: props.loadingComponent,
                    onRenderRow: props.onRenderRow,
                    onRenderHeader: props.onRenderHeader,
                    rowHeightChanged,
                    PreRowsComponent: props.PreRowsComponent,
                    MidRowsComponent: props.MidRowsComponent,
                    midRowsComponentRowNumber: props.midRowsComponentRowNumber,
                    PostRowsComponent: props.PostRowsComponent,
                }),
                [
                    props.itemIds,
                    currentLoadedIndex,
                    isLoadRowsInProgress,
                    props.listProps,
                    props.loadingComponent,
                    props.onRenderRow,
                    props.onRenderHeader,
                    rowHeightChanged,
                    props.PreRowsComponent,
                    props.MidRowsComponent,
                    props.midRowsComponentRowNumber,
                    props.PostRowsComponent,
                ]
            );

            /* eslint-disable-next-line react-perf/jsx-no-new-function-as-prop  -- (https://aka.ms/OWALintWiki)
             * Memoizing this callback causes the list to re-render with stale values
             *	> JSX attribute values should not contain functions created in the same scope */
            const getRowHeight = (index: number) => {
                if (index === 0) {
                    // Not all table views have headers, so the estimatedRowHeight
                    // should account for that (i.e. be 0).
                    return rowHeights.current[0] ?? 0;
                }

                return rowHeights.current[index] ?? estimatedRowHeight;
            };

            // LoadMore logic refers to data pagination. When the user scrolls near the bottom
            // of the list and there is more data available, we'll invoke props.onLoadMoreRows.
            // VariableSizeList.onItemsRendered is the callback that fires when the list is scrolled.
            // When either the currentLoadedIndex or scroll position changes
            const listVisibleRange = React.useRef<{
                start: number;
                end: number;
            }>({
                start: 0,
                end: 0,
            });
            const currentLoadedIndexRef = React.useRef(currentLoadedIndex);
            currentLoadedIndexRef.current = currentLoadedIndex;

            const getNumHiddenRowsInView = React.useCallback((): number => {
                const { start, end } = listVisibleRange.current;
                let numHiddenRows = 0;
                hiddenRowIndices.forEach(index => {
                    if (index + 1 >= start && index + 1 <= end) {
                        numHiddenRows++;
                    }
                });
                return numHiddenRows;
            }, [hiddenRowIndices]);

            const [nearVisibleEnd, setNearVisibleEnd] = React.useState(false);

            // nearVisibleEndRef is a ref version of nearVisibleEnd and should
            // always be kept in sync. (There should only be one codepath that
            // calls setNearVisibleEnd and it should also update the ref). The
            // ref exists as a performance optimization: updateNearVisibleEnd
            // is a memoized callback, which is a dependency to onItemsRendered
            // which is a prop passed to the VariableSizeList. By making
            // nearVisibleEnd a ref, we're able to guarantee that these callbacks
            // don't need to get regenerated each time nearVisibleEnd changes
            // which saves the VariableSizeList from re-rendering.
            const nearVisibleEndRef = React.useRef(nearVisibleEnd);
            nearVisibleEndRef.current = nearVisibleEnd;

            // This callback is triggered whenever there is a scroll event or
            // the length of available rows (currentLoadedIndex) changes. It also gets
            // triggered on a group header collapse/expansion.
            const updateNearVisibleEndAndLoadMoreRowsIfNecessary = React.useCallback(() => {
                const { start, end } = listVisibleRange.current;
                const bufferedElements = Math.max(
                    (end - start - getNumHiddenRowsInView()) * PAGINATION_PREFETCH_BUFFER,
                    0
                );
                const newNearVisibleEnd = currentLoadedIndexRef.current - end < bufferedElements;

                if (nearVisibleEndRef.current !== newNearVisibleEnd) {
                    nearVisibleEndRef.current = newNearVisibleEnd;
                    setNearVisibleEnd(newNearVisibleEnd);
                }

                if (newNearVisibleEnd && getCanLoadMore()) {
                    onLoadMoreRows();
                }
            }, [getCanLoadMore, onLoadMoreRows, getNumHiddenRowsInView]);

            const onItemsRendered = React.useCallback(
                (onItemsRenderedProps: ListOnItemsRenderedProps) => {
                    const { visibleStartIndex, visibleStopIndex } = onItemsRenderedProps;
                    listVisibleRange.current = { start: visibleStartIndex, end: visibleStopIndex };
                    updateStartAndEndIndices(visibleStartIndex, visibleStopIndex);

                    updateNearVisibleEndAndLoadMoreRowsIfNecessary();
                },
                [updateNearVisibleEndAndLoadMoreRowsIfNecessary]
            );

            // When the number of available rows has changed, either because items
            // were added/removed from the view OR because another "onLoadMoreRows"
            // batch has completed loading and is available to render, we need
            // to recalculate whether we're near the end of the vieport or not.
            React.useEffect(() => {
                updateNearVisibleEndAndLoadMoreRowsIfNecessary();
            }, [currentLoadedIndex]);

            // We avoid loading more items in the list while there are active/pending
            // animations to prevent layout shifts while animating. Once all animations
            // have completed, we'll check if we're near the end of the viewport and
            // load more rows if necessary (as we may have prevented the load if
            // the user was near the end of the viewport and had ongoing animations
            // when the check was originally performed).
            React.useEffect(() => {
                if (activeAnimationsCount === 0) {
                    updateNearVisibleEndAndLoadMoreRowsIfNecessary();
                }
            }, [activeAnimationsCount]);

            React.useImperativeHandle(
                ref,
                () => ({
                    getScrollRegion: () => scrollingRegionRef.current as HTMLDivElement,
                    setFocus: () => scrollingRegionRef.current?.focus(),
                }),
                []
            );

            // When the tableView's focusedRowKey changes, we want to trigger this useEffect to perform a calculation
            // to determine whether the new focusedRowKey is in view (between the visible indices).
            // If it is not within the current visible indices, we want to scroll to that item.
            React.useEffect(() => {
                const { start, end } = listVisibleRange.current;

                if (
                    focusedRowKeyIndex !== null &&
                    focusedRowKeyIndex !== undefined &&
                    focusedRowKeyIndex !== -1 &&
                    !(start === 0 && end === 0) // If the start and end indices are both 0, the VLV is either empty or hasn't completed loading so we don't want to scroll.
                ) {
                    // Add 1 to the focusedRowKeyIndex to account for the PreRowsComponent that takes index 0 in the VLV.
                    const indexToScrolTo = focusedRowKeyIndex + 1;
                    if (indexToScrolTo < start || indexToScrolTo > end) {
                        listRef.current?.scrollToItem(indexToScrolTo);
                    }
                }
            }, [focusedRowKeyIndex, focusedNodeId]);

            // Because PreRowsComponent and PostRowsComponent need to be contained within
            // the list, VariableSizeList.itemCount is given two extra. The component that
            // renders rows (VariableSizeRowWrapper) will internally map these indexes:
            // [0] corresponds to PreRowsComponent
            // [1, currentLoadedIndex] maps to props.onRenderRow called with index-1
            //   (to compensate for PreRowsComponent taking index 0)
            // [currentLoadedIndex + 1] to PostRowsComponent
            const listItemCount = currentLoadedIndex + 2;

            return (
                <RenderRowContext.Provider value={renderRowContextValue}>
                    <div className={classNames(rowWrapper, props.rowWrapperClass)}>
                        <div ref={listAutoSizerRef} className={listAutoSizer}>
                            <VariableSizeList
                                ref={listRef}
                                outerRef={setScrollingRegion}
                                className={className}
                                width={listAutoSizerRect?.width || 0}
                                height={listAutoSizerRect?.height || 0}
                                overscanCount={4}
                                onItemsRendered={onItemsRendered}
                                itemCount={listItemCount}
                                itemSize={getRowHeight}
                                estimatedItemSize={estimatedRowHeight}
                                style={rtlStyle}
                            >
                                {VariableSizeRowWrapper}
                            </VariableSizeList>
                        </div>
                    </div>
                </RenderRowContext.Provider>
            );
        }
    ),
    'VirtualizedLoadMoreListView'
);

interface VariableSizeListRowWrapperProps {
    index: number;
    style: React.CSSProperties;
}

const VariableSizeRowWrapper = observer(function VariableSizeRowWrapper(
    wrapperProps: VariableSizeListRowWrapperProps
) {
    const renderRowContext = React.useContext(RenderRowContext);
    const {
        itemIds,
        currentLoadedIndex,
        isLoadRowsInProgress,
        listProps,
        loadingComponent,
        onRenderRow,
        onRenderHeader,
        rowHeightChanged,
        PreRowsComponent,
        MidRowsComponent,
        midRowsComponentRowNumber,
        PostRowsComponent,
    } = renderRowContext;

    const renderHeaderAboveRow = React.useCallback(
        (rowToRender: number): JSX.Element | null => {
            if (onRenderHeader) {
                const previousElementId =
                    rowToRender > 0 && rowToRender <= currentLoadedIndex
                        ? itemIds[rowToRender - 1]
                        : null;
                const currentElementId = itemIds[rowToRender];
                return onRenderHeader(previousElementId, currentElementId);
            }
            return null;
        },
        [onRenderHeader, currentLoadedIndex, itemIds]
    );

    const renderRow = React.useCallback(
        (index: number): JSX.Element | null => {
            // `index` is coming from react-window's VariableSizeList and
            // needs to be mapped according to the comment in VirtualizedLoadMoreListView
            // pertaining to VariableSizeList.itemCount / listItemCount
            if (index === 0) {
                return PreRowsComponent ? <PreRowsComponent /> : null;
            }
            if (index === currentLoadedIndex + 1) {
                return PostRowsComponent ? <PostRowsComponent /> : null;
            }

            const indexOfRowToRender = index - 1;
            if (indexOfRowToRender < 0 || indexOfRowToRender >= currentLoadedIndex) {
                return null;
            }

            const header = renderHeaderAboveRow(indexOfRowToRender);
            const midRowsComponent =
                indexOfRowToRender === midRowsComponentRowNumber && MidRowsComponent ? (
                    <MidRowsComponent />
                ) : null;
            const rowToRender = onRenderRow(
                itemIds[indexOfRowToRender],
                indexOfRowToRender,
                listProps
            );
            const loadingSpinnerComponent =
                indexOfRowToRender == currentLoadedIndex - 1 &&
                isLoadRowsInProgress &&
                loadingComponent;

            if (
                header ||
                midRowsComponent ||
                rowToRender /* used to be null, now <></> */ ||
                loadingSpinnerComponent
            ) {
                return (
                    <>
                        {header}
                        {midRowsComponent}
                        {rowToRender}

                        {/* The last row gets a loading spinner if we're actively loading more data */}
                        {loadingSpinnerComponent}
                    </>
                );
            } else {
                return null;
            }
        },
        [
            renderHeaderAboveRow,
            onRenderRow,
            currentLoadedIndex,
            listProps,
            isLoadRowsInProgress,
            loadingComponent,
            itemIds,
            midRowsComponentRowNumber,
            PreRowsComponent,
            MidRowsComponent,
            PostRowsComponent,
        ]
    );

    return (
        <VariableSizeListRow
            {...wrapperProps}
            renderContent={renderRow}
            onRowHeightChanged={rowHeightChanged}
        />
    );
},
'VariableSizeRowWrapper');

interface VariableSizeRowProps {
    style: any;
    index: number;
    renderContent: (index: number) => JSX.Element | null;
    onRowHeightChanged:
        | ((index: number, height: number, shouldForceUpdate?: boolean) => void)
        | undefined;
}

const VariableSizeListRow = observer(function VariableSizeListRowInner(
    props: VariableSizeRowProps
) {
    const rowRef = React.useRef<HTMLDivElement>(null);
    const { onRowHeightChanged, index } = props;

    const onSizeChanged = React.useCallback(
        (rect: DOMRectReadOnly) => {
            if (rowRef.current) {
                onRowHeightChanged?.(index, rect.height);
            }
        },
        [onRowHeightChanged, index]
    );

    useResizeObserver('VirtualizedLoadMoreListView_RO', rowRef, onSizeChanged);

    const content = props.renderContent(index);
    if (content) {
        return (
            <div style={props.style}>
                <div ref={rowRef} data-animatable={true} className={row}>
                    {content}
                </div>
            </div>
        );
    } else {
        onRowHeightChanged?.(index, 0, true /* isBeingCollapsed */);
        return null;
    }
},
'VariableSizeListRowInner');
