import type { IButtonStyles } from '@fluentui/react';
import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import { owaComputedFn } from 'owa-computed-fn';
import { createAppButton, constructKeytip } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import RectangleLandscape from 'owa-fluent-icons-svg/lib/icons/RectangleLandscapeRegular';
import loc from 'owa-localize';
import { type DrawControlId } from 'owa-ribbon-ids/lib/drawControlId';
import { drawCanvasText } from './drawControlStrings.locstring.json';
import onDrawCanvas from '../actions/onDrawCanvas';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import type { RibbonDrawState } from 'owa-editor-graphic-plugin-types/lib/store/schema/RibbonDrawState';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import type { AppButtonOnExecuteParameter } from '@1js/acui-button/lib/components/AppButton/AppButton.Props';

export const getDrawCanvas = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: ((composeId: string) => RibbonMailComposeInfo | null) | undefined,
        _: RibbonDrawState | undefined,
        styles: IButtonStyles | undefined,
        tabId: number | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        return createAppButton(
            getComposeRibbonId(12011, editorId),
            loc(drawCanvasText),
            (parameter?: AppButtonOnExecuteParameter) => {
                const inputMethod = parameter && parameter.inputMethod;

                getComputedCallback(12011, onDrawCanvas, composeId, retriever, inputMethod)();
            },
            tabId ? constructKeytip([tabId], 12011) : undefined,
            RectangleLandscape,
            undefined /* iconColor */,
            styles,
            {
                disabled: isDisabled,
            }
        );
    }
);
