import { getFindConversationShape, getFindItemShape } from 'owa-mail-find-rows';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import type ViewFilter from 'owa-service/lib/contract/ViewFilter';
import getSortByFolderId from 'owa-mail-folder-store/lib/selectors/getSortByFolderId';
import type {
    MailFolderTableQuery,
    TableQuery,
    SortBy,
    MailFolderScenarioType,
} from 'owa-mail-list-store';
import { type TableQueryType, isSearchFolderScenario, type SortColumn } from 'owa-mail-list-store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { isFocusedInboxEnabled } from 'owa-mail-triage-common';
import type { TraceErrorObject } from 'owa-trace';
import { errorThatWillCauseAlert } from 'owa-trace';
import { getMailboxInfoFromFolderId } from 'owa-mail-mailboxinfo/lib/getMailboxInfo';

/**
 * Create the table query to load a mail folder
 * @param folderId the folderId
 * @param listViewType the list view type
 * @param scenarioType the type of scenario
 * @param focusedViewfilter the focusedViewfilter
 * @param viewFilter the viewFilter
 * @param categoryName the category name [this will have a value when create query for a category table]
 * @param sortBy the sortBy (column and direction)
 * @return tableQuery the tableQuery that needed to load a mail folder
 */
export default function createMailFolderTableQuery(
    folderId: string,
    listViewType: ReactListViewType,
    scenarioType: MailFolderScenarioType,
    focusedViewFilter?: FocusedViewFilter,
    viewFilter?: ViewFilter,
    categoryName?: string,
    sortBy?: SortBy
): TableQuery {
    if (!folderId) {
        const error: TraceErrorObject = new Error('CantCreateMailFolderTableQuery');
        error.diagnosticInfo = JSON.stringify({ listViewType, ty: typeof folderId, scenarioType });
        errorThatWillCauseAlert(error);
    }

    const viewFilterToSet = viewFilter ? viewFilter : 'All';
    const sortByToSet: SortBy =
        scenarioType === 'mailFolderSortBySize'
            ? {
                  sortColumn: 7,
                  sortDirection: 'Descending',
              }
            : sortBy
            ? sortBy
            : getSortByFolderId(folderId);
    const focusedViewFilterToSet =
        focusedViewFilter == null
            ? getDefaultFocusedViewFilterForSelectedFolder(folderId)
            : focusedViewFilter;

    // If we are sorting by categories, we need to force table to Message view type
    // because that's the only view it's supported in.
    if (sortByToSet?.sortColumn === 14) {
        listViewType = 1;
    }

    let requestShapeName: string = '';
    switch (listViewType) {
        case 0:
            requestShapeName = getFindConversationShape(
                folderId,
                isSearchFolderScenario(scenarioType)
            );
            break;

        case 1:
            requestShapeName = getFindItemShape();
            break;
    }

    const folderTableQuery: MailFolderTableQuery = {
        folderId,
        focusedViewFilter: focusedViewFilterToSet,
        listViewType,
        viewFilter: viewFilterToSet,
        sortBy: sortByToSet,
        requestShapeName,
        type: 0,
        categoryName,
        scenarioType,
    };

    return folderTableQuery;
}

/**
 * Gets default FocusedViewFilter for selected folder
 * @param folderId of the folder
 */
function getDefaultFocusedViewFilterForSelectedFolder(folderId: string): FocusedViewFilter {
    const distinguishedFolder = folderIdToName(folderId);
    if (distinguishedFolder !== 'inbox') {
        // None if folder is not Inbox
        return -1;
    }

    if (!isFocusedInboxEnabled(getMailboxInfoFromFolderId(folderId))) {
        // None if Focused Inbox feature is not enabled for user
        return -1;
    }

    return 0;
}
