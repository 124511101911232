import type { MailboxInfo } from 'owa-client-types';
import { getGroupInformation } from './getGroupInformation';

export function getGroupDisplayName(
    mailboxInfo: MailboxInfo,
    groupSmtpAddress: string
): string | null {
    const group = getGroupInformation(mailboxInfo, groupSmtpAddress.toLowerCase());
    return group?.groupDetails?.DisplayName ?? group?.basicInformation.DisplayName ?? null;
}
