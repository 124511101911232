import { type MailRibbonGroupId, type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';

import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import GrammarCheck from 'owa-fluent-icons-svg/lib/icons/TextGrammarCheckmarkFilled';
import GroupStrings from 'owa-locstrings/lib/strings/ribbon_mail_groups.locstring.json';
import Important from 'owa-fluent-icons-svg/lib/icons/ImportantRegular';
import MailSettings from 'owa-fluent-icons-svg/lib/icons/MailSettingsRegular';
import type { MenuDefinition } from '@1js/acui-menu/lib/components/MenuDefinition';
import type { RibbonFlyoutAnchorProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon';
import type { RibbonGroupId } from 'owa-ribbon-ids';
import SaveRegular from 'owa-fluent-icons-svg/lib/icons/SaveRegular';
import SendClockRegular from 'owa-fluent-icons-svg/lib/icons/SendClockRegular';
import TextTRegular from 'owa-fluent-icons-svg/lib/icons/TextTRegular';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip } from 'owa-command-ribbon';
import { createMailAppFlyoutAnchor } from 'owa-mail-ribbon-utils/lib/createMailRibbonButton';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import loc from 'owa-localize';

const emptyMenuDefinition: MenuDefinition = {
    sections: [],
};

export const getMLROptionsTabCommonGroupFlyout = owaComputedFn(
    function getMLROptionsTabCommonGroupFlyout(
        group: RibbonGroupId,
        props: ComposeRibbonControlDefProps
    ): RibbonFlyoutAnchorProps | undefined {
        const groupId = getComposeRibbonId(group, props.composeViewState?.editorId).toString();
        switch (group) {
            case 172:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.proofing_Group),
                    undefined /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    constructKeytip([11], 172),
                    GrammarCheck
                );
            case 124:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.proofing_Group),
                    undefined /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    constructKeytip([11], 124),
                    SendClockRegular
                );
            case 175:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.showField_Group),
                    undefined /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    constructKeytip([11], 175),
                    undefined /* icon */
                );
            case 176:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.tracking_Group),
                    undefined /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    constructKeytip([11], 176),
                    undefined /* icon */
                );
            case 167:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.tags_Group),
                    undefined /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    constructKeytip([11], 167),
                    Important
                );
            case 174:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.format_Group),
                    undefined /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    constructKeytip([11], 174),
                    TextTRegular
                );
            case 123:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.save_Group),
                    undefined /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    constructKeytip([11], 123),
                    SaveRegular
                );

            case 162:
                return createMailAppFlyoutAnchor(
                    groupId,
                    loc(GroupStrings.options_Group),
                    undefined /*tooltip*/,
                    props,
                    emptyMenuDefinition,
                    constructKeytip([11], 162),
                    MailSettings
                );

            default:
                return undefined;
        }
    }
);
