import { getMailRibbonConfigStore } from 'owa-mail-ribbon-store/lib/store/store';
import type { MailRibbonGroup } from 'owa-mail-ribbon-store-shared-types';
import type { RibbonId } from 'owa-ribbon-ids';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { computed } from 'mobx';

const composeSecondaryControls = new Set<RibbonId>([624, 7030]);

const composeTabControls = computed((): Set<RibbonId> => {
    const singleline = getMailRibbonConfigStore().singleline;
    const multiline = getMailRibbonConfigStore().multiline;
    const groups: MailRibbonGroup[] = [
        ...singleline.insertTab.layout,
        ...singleline.formatTab.layout,
        ...singleline.messageTab.layout,
        ...singleline.optionsTab.layout,
        ...multiline.insertTab.layout,
        ...multiline.formatTab.layout,
        ...multiline.messageTab.layout,
        ...multiline.optionsTab.layout,
        ...multiline.drawTab.layout,
    ];
    const composeTabControlSet: Set<RibbonId> = new Set();
    for (const group of groups) {
        for (const controlId of group.controlIds) {
            composeTabControlSet.add(controlId);
        }
    }
    return composeTabControlSet;
});
const getComposeTabControls = () => composeTabControls.get();

export const isComposeTabControl = (id: number): boolean => {
    const composeTabControlSet: Set<RibbonId> = getComposeTabControls();
    return composeTabControlSet.has(id) || composeSecondaryControls.has(id);
};
