import { type PredefinedStylesType } from '../enum/predefinedStylesTypeEnum';
import { type PredefinedTableStyles } from '../enum/predefinedTableStylesEnum';
import { type TableColors } from '../enum/tableColors';
import type { TableFormat } from 'roosterjs-editor-types';
import { type TableBorderFormat } from 'roosterjs-editor-types/lib/enum/TableBorderFormat';

const PREDEFINED_STYLES_TYPE: Record<string, (color: string) => TableFormat> = {
    DEFAULT: color =>
        createTableFormat(
            color /**topBorder */,
            color /**bottomBorder */,
            color /** verticalColors*/,
            false /** bandedRows */,
            false /** bandedColumns */,
            false /** headerRow */,
            false /** firstColumn */,
            0,
            null /** bgColorEven */,
            lightColors(color) /** bgColorOdd */,
            color /** headerRowColor */
        ),
    GRID: color =>
        createTableFormat(
            color /**topBorder */,
            color /**bottomBorder */,
            color /** verticalColors*/,
            true /** bandedRows */,
            false /** bandedColumns */,
            false /** headerRow */,
            false /** firstColumn */,
            0,
            null /** bgColorEven */,
            lightColors(color) /** bgColorOdd */,
            color /** headerRowColor */
        ),
    GRID_WITH_HEADER_ROW: color =>
        createTableFormat(
            color /**topBorder */,
            color /**bottomBorder */,
            color /** verticalColors*/,
            true /** bandedRows */,
            false /** bandedColumns */,
            true /** headerRow */,
            false /** firstColumn */,
            0,
            null /** bgColorEven */,
            lightColors(color) /** bgColorOdd */,
            color /** headerRowColor */
        ),
    SPECIAL_GRID: color =>
        createTableFormat(
            color /**topBorder */,
            color /**bottomBorder */,
            color /** verticalColors*/,
            true /** bandedRows */,
            false /** bandedColumns */,
            false /** headerRow */,
            false /** firstColumn */,
            5,
            null /** bgColorEven */,
            lightColors(color) /** bgColorOdd */,
            color /** headerRowColor */
        ),
    LIST: color =>
        createTableFormat(
            color /**topBorder */,
            color /**bottomBorder */,
            'transparent',
            true /** bandedRows */,
            false /** bandedColumns */,
            false /** headerRow */,
            false /** firstColumn */,
            0,
            null /** bgColorEven */,
            lightColors(color) /** bgColorOdd */,
            color /** headerRowColor */
        ),
    LIST_WITH_HEADER: color =>
        createTableFormat(
            color /**topBorder */,
            color /**bottomBorder */,
            color /** verticalColors*/,
            false /** bandedRows */,
            false /** bandedColumns */,
            true /** headerRow */,
            false /** firstColumn */,
            1,
            null /** bgColorEven */,
            lightColors(color) /** bgColorOdd */,
            color /** headerRowColor */
        ),
    LIST_SPECIAL: color =>
        createTableFormat(
            color /**topBorder */,
            color /**bottomBorder */,
            color /** verticalColors*/,
            true /** bandedRows */,
            false /** bandedColumns */,
            false /** headerRow */,
            true /** firstColumn */,
            6,
            null /** bgColorEven */,
            lightColors(color) /** bgColorOdd */,
            color /** headerRowColor */
        ),
    PLAIN: color =>
        createTableFormat(
            color /**topBorder */,
            color /**bottomBorder */,
            color /** verticalColors*/,
            true /** bandedRows */,
            false /** bandedColumns */,
            false /** headerRow */,
            false /** firstColumn */,
            7,
            null /** bgColorEven */,
            lightColors(color) /** bgColorOdd */,
            color /** headerRowColor */
        ),
    PLAIN_LIST: color =>
        createTableFormat(
            color /**topBorder */,
            color /**bottomBorder */,
            'transparent',
            false /** bandedRows */,
            false /** bandedColumns */,
            false /** headerRow */,
            false /** firstColumn */,
            0,
            null /** bgColorEven */,
            lightColors(color) /** bgColorOdd */,
            color /** headerRowColor */
        ),
    PLAIN_WITHOUT_BORDERS: color =>
        createTableFormat(
            'transparent',
            'transparent',
            'transparent',
            true /** bandedRows */,
            false /** bandedColumns */,
            false /** headerRow */,
            false /** firstColumn */,
            0,
            null /** bgColorEven */,
            lightColors(color) /** bgColorOdd */,
            color /** headerRowColor */
        ),
    PLAIN_WITH_FIRST_COLUMN: color =>
        createTableFormat(
            color /**topBorder */,
            color /**bottomBorder */,
            color /** verticalColors*/,
            true /** bandedRows */,
            false /** bandedColumns */,
            false /** headerRow */,
            true /** firstColumn */,
            7,
            null /** bgColorEven */,
            lightColors(color) /** bgColorOdd */,
            color /** headerRowColor */
        ),
};

function createTableFormat(
    topBorder?: string,
    bottomBorder?: string,
    verticalBorder?: string,
    bandedRows?: boolean,
    bandedColumns?: boolean,
    headerRow?: boolean,
    firstColumn?: boolean,
    borderFormat?: TableBorderFormat,
    bgColorEven?: string | null,
    bgColorOdd?: string,
    headerRowColor?: string
): TableFormat {
    return {
        topBorderColor: topBorder,
        bottomBorderColor: bottomBorder,
        verticalBorderColor: verticalBorder,
        hasBandedRows: bandedRows,
        bgColorEven,
        bgColorOdd,
        hasBandedColumns: bandedColumns,
        hasHeaderRow: headerRow,
        headerRowColor,
        hasFirstColumn: firstColumn,
        tableBorderFormat: borderFormat,
    };
}

const lightColors = (color: string) => {
    return color === '#000000' ? `${'#CCCCCC'}` : `${color}20`;
};

export const PREDEFINED_STYLES: Record<string, TableFormat> = {
    ['DEFAULT_BLACK']: PREDEFINED_STYLES_TYPE['DEFAULT']('#000000'),
    ['DEFAULT_GRAY']: PREDEFINED_STYLES_TYPE['DEFAULT']('#CCCCCC'),
    ['GRID_GRAY']: PREDEFINED_STYLES_TYPE['GRID']('#CCCCCC'),
    ['PLAIN_LIST_BLACK']: PREDEFINED_STYLES_TYPE['PLAIN_LIST']('#000000'),
    ['PLAIN_BLACK']: PREDEFINED_STYLES_TYPE['PLAIN']('#000000'),
    ['PLAIN_WITHOUT_BORDERS_BLACK']: PREDEFINED_STYLES_TYPE['PLAIN_WITHOUT_BORDERS']('#000000'),
    ['PLAIN_WITH_FIRST_COLUMN_BLACK']: PREDEFINED_STYLES_TYPE['PLAIN_WITH_FIRST_COLUMN']('#000000'),
    ['GRID_BLACK']: PREDEFINED_STYLES_TYPE['GRID']('#000000'),
    ['GRID_BLUE']: PREDEFINED_STYLES_TYPE['GRID']('#0C64C0'),
    ['GRID_ORANGE']: PREDEFINED_STYLES_TYPE['GRID']('#DE6A19'),
    ['GRID_YELLOW']: PREDEFINED_STYLES_TYPE['GRID']('#DCBE22'),
    ['GRID_RED']: PREDEFINED_STYLES_TYPE['GRID']('#ED5C57'),
    ['GRID_PURPLE']: PREDEFINED_STYLES_TYPE['GRID']('#B36AE2'),
    ['GRID_GREEN']: PREDEFINED_STYLES_TYPE['GRID']('#0C882A'),
    ['SPECIAL_GRID_BLACK']: PREDEFINED_STYLES_TYPE['SPECIAL_GRID']('#000000'),
    ['SPECIAL_GRID_BLUE']: PREDEFINED_STYLES_TYPE['SPECIAL_GRID']('#0C64C0'),
    ['SPECIAL_GRID_ORANGE']: PREDEFINED_STYLES_TYPE['SPECIAL_GRID']('#DE6A19'),
    ['SPECIAL_GRID_YELLOW']: PREDEFINED_STYLES_TYPE['SPECIAL_GRID']('#DCBE22'),
    ['SPECIAL_GRID_RED']: PREDEFINED_STYLES_TYPE['SPECIAL_GRID']('#ED5C57'),
    ['SPECIAL_GRID_PURPLE']: PREDEFINED_STYLES_TYPE['SPECIAL_GRID']('#B36AE2'),
    ['SPECIAL_GRID_GREEN']: PREDEFINED_STYLES_TYPE['SPECIAL_GRID']('#0C882A'),
    ['GRID_WITH_HEADER_ROW_BLACK']: PREDEFINED_STYLES_TYPE['GRID_WITH_HEADER_ROW']('#000000'),
    ['GRID_WITH_HEADER_ROW_BLUE']: PREDEFINED_STYLES_TYPE['GRID_WITH_HEADER_ROW']('#0C64C0'),
    ['GRID_WITH_HEADER_ROW_ORANGE']: PREDEFINED_STYLES_TYPE['GRID_WITH_HEADER_ROW']('#DE6A19'),
    ['GRID_WITH_HEADER_ROW_YELLOW']: PREDEFINED_STYLES_TYPE['GRID_WITH_HEADER_ROW']('#DCBE22'),
    ['GRID_WITH_HEADER_ROW_RED']: PREDEFINED_STYLES_TYPE['GRID_WITH_HEADER_ROW']('#ED5C57'),
    ['GRID_WITH_HEADER_ROW_PURPLE']: PREDEFINED_STYLES_TYPE['GRID_WITH_HEADER_ROW']('#B36AE2'),
    ['GRID_WITH_HEADER_ROW']: PREDEFINED_STYLES_TYPE['GRID_WITH_HEADER_ROW']('#0C882A'),
    ['LIST_BLACK']: PREDEFINED_STYLES_TYPE['LIST']('#000000'),
    ['LIST_BLUE']: PREDEFINED_STYLES_TYPE['LIST']('#0C64C0'),
    ['LIST_ORANGE']: PREDEFINED_STYLES_TYPE['LIST']('#DE6A19'),
    ['LIST_YELLOW']: PREDEFINED_STYLES_TYPE['LIST']('#DCBE22'),
    ['LIST_RED']: PREDEFINED_STYLES_TYPE['LIST']('#ED5C57'),
    ['LIST_PURPLE']: PREDEFINED_STYLES_TYPE['LIST']('#B36AE2'),
    ['LIST_GREEN']: PREDEFINED_STYLES_TYPE['LIST']('#0C882A'),
    ['LIST_WITH_HEADER_BLACK']: PREDEFINED_STYLES_TYPE['LIST_WITH_HEADER']('#000000'),
    ['LIST_WITH_HEADER_BLUE']: PREDEFINED_STYLES_TYPE['LIST_WITH_HEADER']('#0C64C0'),
    ['LIST_WITH_HEADER_ORANGE']: PREDEFINED_STYLES_TYPE['LIST_WITH_HEADER']('#DE6A19'),
    ['LIST_WITH_HEADER_YELLOW']: PREDEFINED_STYLES_TYPE['LIST_WITH_HEADER']('#DCBE22'),
    ['LIST_WITH_HEADER_RED']: PREDEFINED_STYLES_TYPE['LIST_WITH_HEADER']('#ED5C57'),
    ['LIST_WITH_HEADER_PURPLE']: PREDEFINED_STYLES_TYPE['LIST_WITH_HEADER']('#B36AE2'),
    ['LIST_WITH_HEADER_GREEN']: PREDEFINED_STYLES_TYPE['LIST_WITH_HEADER']('#0C882A'),
    ['LIST_SPECIAL_BLACK']: PREDEFINED_STYLES_TYPE['LIST_SPECIAL']('#000000'),
    ['LIST_SPECIAL_BLUE']: PREDEFINED_STYLES_TYPE['LIST_SPECIAL']('#0C64C0'),
    ['LIST_SPECIAL_ORANGE']: PREDEFINED_STYLES_TYPE['LIST_SPECIAL']('#DE6A19'),
    ['LIST_SPECIAL_YELLOW']: PREDEFINED_STYLES_TYPE['LIST_SPECIAL']('#DCBE22'),
    ['LIST_SPECIAL_RED']: PREDEFINED_STYLES_TYPE['LIST_SPECIAL']('#ED5C57'),
    ['LIST_SPECIAL_PURPLE']: PREDEFINED_STYLES_TYPE['LIST_SPECIAL']('#B36AE2'),
    ['LIST_SPECIAL_GREEN']: PREDEFINED_STYLES_TYPE['LIST_SPECIAL']('#0C882A'),
};
