import type { RibbonId } from 'owa-ribbon-ids';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';

const actionControlIds: Set<RibbonId> = new Set([
    7016, 529, 637, 564, 565, 620, 621, 622, 623, 624, 7030, 7024, 7025, 639, 7000, 638, 7001, 7002,
    7003, 7004, 506, 560, 7005, 7006, 7007, 7009, 7020, 7019, 7010, 7011, 7014, 7015, 7021, 7023,
    7027, 7028, 7026, 7029, 7031,
]);

export const isActionControl = (id: number): boolean => {
    return actionControlIds.has(id);
};
