import { observer } from 'owa-mobx-react';
import { readingPane, skipToMessage } from './MailView.locstring.json';
import loc from 'owa-localize';
import { isFeatureEnabled } from 'owa-feature-flags';
import { ListPane } from 'owa-mail-list-pane-view';
import {
    getSelectedTableView,
    shouldSuppressServerMarkReadOnReplyOrForward,
    getIsSearchTableShown,
} from 'owa-mail-list-store';
import { getIsMultipleSelection } from 'owa-mail-list-store/lib/utils/getIsMultipleSelection';
import {
    setListViewDimension,
    isReadingPanePositionBottom,
    isReadingPanePositionRight,
    isReadingPanePositionOff,
    shouldShowListView,
    shouldShowReadingPane,
    getListViewContainerStyles,
    getReadingPaneContainerStyles,
} from 'owa-mail-layout';
import ReadingPaneContainer from 'owa-mail-reading-pane-container/lib/components/ReadingPaneContainer';
import { NotificationBarHost } from 'owa-notification-bar';
import { triggerResizeEvent } from 'owa-resize-event';
import { ResizeHandle, ResizeHandleDirection } from 'owa-resize-handle';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { TabType, getActiveContentTab } from 'owa-tab-store';
import { TabBar } from 'owa-tab-view';
import React from 'react';
import getMailListHeader from 'owa-mail-list-pane-view/lib/components/getMailListHeader';
import getStyleSelectorAsPerUserSettings from 'owa-mail-list-view/lib/utils/getStyleSelectorAsPerUserSettings';
import { SkipLinkRegion } from 'owa-skip-link/lib/components/SkipLinkRegion';
import { NotesPane } from 'owa-notes-components';
import folderStore from 'owa-folders';
import { resetFocus } from 'owa-mail-focus-manager';
import { MailHotkeysMap, lazySetupMailHotkeysMapProperties } from 'owa-mail-hotkeys';
import { getSelectedNode } from 'owa-mail-folder-forest-store';
import { MailPersonaSearchResultsHeader } from 'owa-mail-list-view';
import { lazyGetIsNonMailEntitySearchTab, TabbedSearchPane } from 'owa-tabbed-search';
import { isDumpsterOrDumpsterSearchTable as getIsDumpsterOrDumpsterSearchTable } from '../utils/isDumpsterOrDumpsterSearchTable';
import { lazySetAppSections } from 'owa-accessibility-loop-nav';
import { shouldShowSenderGroupingsHeader } from 'owa-sender-groupings-header';
import { OutlookTourHost } from 'tour-host';
import { type FolderForestNodeType } from 'owa-favorites-types';
import { isCapabilityEnabled, isCapabilitySupported } from 'owa-capabilities';
import { bleedThroughCapability } from 'owa-capabilities-definitions/lib/bleedThroughCapability';
import classNames from 'owa-classnames';
import {
    mailListWithRPRight,
    mailListWithRPHiddenOrBottom,
    rpPositionBottom,
    mailListBleedThrough,
    mailListRefresh,
    mailListHide,
    subCommandBarPaneColumn,
    subCommandBarPaneRow,
    subCommandBarPaneCommon,
    subCommandBarPaneCommonRefresh,
    subCommandBarPaneMail,
    rightPane,
    searchHeaderContainer,
    searchHeaderBorders,
    readingPane as styles_readingPane,
    fullBleed,
} from './MailModule.scss';

export default observer(function MailView() {
    React.useEffect(() => {
        lazySetAppSections.importAndExecute('Mail');
        if (isFeatureEnabled('acct-fullOptionsProjectionPopout')) {
            lazySetupMailHotkeysMapProperties.importAndExecute();
        }
    }, []);

    const tableView = getSelectedTableView();
    const useMultiSelectReadingPane = getIsMultipleSelection();
    const isRPPositionRight = isReadingPanePositionRight();
    const isRPPositionBottom = isReadingPanePositionBottom();
    let showReadingPane = shouldShowReadingPane();
    let showListPane = shouldShowListView();
    const activeTab = getActiveContentTab();
    const isDumpsterOrDumpsterSearchTable = getIsDumpsterOrDumpsterSearchTable();
    const isBleedThroughEnabled = isCapabilityEnabled(bleedThroughCapability);
    const isBleedThroughSupported = isCapabilitySupported(bleedThroughCapability);

    // Adjust list view & reading pane state for tab view
    if (activeTab && !isDumpsterOrDumpsterSearchTable) {
        if (isRPPositionBottom) {
            // When reading pane is on bottom and current tab is full compose, we need to hide list view
            // to give compose enough space.
            // Here we only check TabType.MailCompose. There are still 2 cases that compose can be show but
            // we don't check them here, because:
            // 1. current tab is Primary tab and we have primary compose. This happens when user is open
            // draft items folder. In that case we still show list view otherwise user can never see list
            // view of draft folder.
            // 2. inline compose. This should never happen when reading pane is on bottom.
            showListPane = showListPane && activeTab.type != TabType.MailCompose;
        } else if (!isRPPositionRight) {
            // When reading pane is off, need to adjust reading pane/list view according to current tab type
            // When active tab is primary tab, show reading pane/list view according to the setting in mailStore
            // Otherwise, always show reading pane since reading pane is the container for all other tabs
            showReadingPane = activeTab.type != TabType.Primary || shouldShowReadingPane();
            showListPane = activeTab.type == TabType.Primary && shouldShowListView();
        }
    }
    // When context menu is opened for a message, do not show reading pane, but show tab bar in list view only if
    // reading pane position is off in user setting. (Bug155397)
    const showTabBarInListView =
        showListPane &&
        !showReadingPane &&
        isReadingPanePositionOff() &&
        !isDumpsterOrDumpsterSearchTable;
    const tabBar = React.useMemo(
        () => (showTabBarInListView ? <TabBar /> : null),
        [showTabBarInListView]
    );

    if (!tableView) {
        return null;
    }

    const tableViewId = tableView.id;
    const styleSelectorAsPerUserSettings = getStyleSelectorAsPerUserSettings(tableViewId);

    const showTabBarInReadingPane = !showTabBarInListView && !isDumpsterOrDumpsterSearchTable;
    const mailListClasses = classNames(
        isRPPositionRight ? mailListWithRPRight : mailListWithRPHiddenOrBottom,
        isRPPositionBottom && rpPositionBottom,
        mailListRefresh, // Bug154909 -- remove SunValley "hacks" when 1js natively supports SV2.
        isBleedThroughSupported && mailListBleedThrough,
        !showListPane && mailListHide,
        getListViewContainerStyles()
    );
    const subCommandBarPaneClasses = classNames(
        isRPPositionBottom ? subCommandBarPaneColumn : subCommandBarPaneRow,
        subCommandBarPaneCommon,
        subCommandBarPaneCommonRefresh, // Bug154909 -- remove SunValley "hacks" when 1js natively supports SV2.
        subCommandBarPaneMail
    );
    const showNotificationBar = !isRPPositionRight;
    const isSearchTable = getIsSearchTableShown();
    const showPersonaHeader = getSelectedNode().type === 1 && !isSearchTable;
    const showSearchRefinersExtendedHeader =
        isSearchTable && !showPersonaHeader && !shouldShowSenderGroupingsHeader(tableView);
    const showPersonaSearchExtendedHeader =
        isSearchTable &&
        showPersonaHeader &&
        !getSelectedNode().id &&
        !isFeatureEnabled('sea-newSearchHeader');
    const shouldMailViewRenderHeader = showSearchRefinersExtendedHeader && isRPPositionRight;
    const isNotesFolder = folderIdToName(tableView?.tableQuery?.folderId ?? '') === 'notes';
    const sourceFolder = tableView && folderStore.folderTable.get(tableView.tableQuery.folderId);
    const getIsNonMailEntitySearchTab = lazyGetIsNonMailEntitySearchTab.tryImportForRender();
    const showTabbedSearchPane = getIsNonMailEntitySearchTab
        ? getIsNonMailEntitySearchTab()
        : false;
    const isNewSearchHeaderEnabled = isFeatureEnabled('sea-newSearchHeader');
    const shouldListPaneRenderHeader = () => {
        if (isSearchTable && isNewSearchHeaderEnabled) {
            return false;
        } else {
            return !shouldMailViewRenderHeader;
        }
    };

    if (
        !!sourceFolder &&
        folderIdToName(sourceFolder.id) === 'notes' &&
        isFeatureEnabled('notes-folder-view')
    ) {
        if (window.performance) {
            window.performance.mark('StartStickyNotesSDKLoad');
        }
        return (
            <NotesPane scenario="NotesFolder" header={getMailListHeader(tableViewId, undefined)} />
        );
    }

    return (
        <div className={rightPane}>
            {(shouldMailViewRenderHeader || showTabbedSearchPane) &&
                (isNewSearchHeaderEnabled ? (
                    <div />
                ) : (
                    <div
                        className={classNames(
                            searchHeaderContainer,
                            isBleedThroughEnabled && fullBleed
                        )}
                    >
                        <div className={searchHeaderBorders}>
                            {getMailListHeader(tableViewId, styleSelectorAsPerUserSettings)}
                        </div>
                    </div>
                ))}
            {showPersonaSearchExtendedHeader && (
                <MailPersonaSearchResultsHeader
                    tableView={tableView}
                    hideFilter={true}
                    showRefiners={true}
                    hideCheckAll={false}
                    styleSelectorAsPerUserSettings={styleSelectorAsPerUserSettings}
                />
            )}
            <div className={subCommandBarPaneClasses}>
                {showTabbedSearchPane ? (
                    <TabbedSearchPane header={undefined} />
                ) : (
                    <>
                        <ListPane
                            className={mailListClasses}
                            tableViewId={tableViewId}
                            shouldShowHeader={shouldListPaneRenderHeader()} // ListPane renders a header only if MailView hasn't
                            tabBar={tabBar}
                            styleSelectorAsPerUserSettings={styleSelectorAsPerUserSettings}
                        />
                        {showReadingPane && showListPane && (
                            <ResizeHandle
                                direction={
                                    isRPPositionBottom
                                        ? ResizeHandleDirection.horizontal
                                        : ResizeHandleDirection.vertical
                                }
                                onResized={onRightPaneResized}
                            />
                        )}
                        {showReadingPane && (
                            <SkipLinkRegion
                                skipLinkName={loc(skipToMessage)}
                                role="main"
                                regionName={loc(readingPane)}
                                className={classNames(
                                    styles_readingPane,
                                    getReadingPaneContainerStyles()
                                )}
                                data-app-section="MailReadCompose"
                            >
                                <ReadingPaneContainer
                                    isDumpsterOrDumpsterSearchTable={
                                        isDumpsterOrDumpsterSearchTable
                                    }
                                    suppressServerMarkReadOnReplyOrForward={shouldSuppressServerMarkReadOnReplyOrForward(
                                        tableView
                                    )}
                                    isNotesFolder={isNotesFolder}
                                    useMultiSelectReadingPane={useMultiSelectReadingPane}
                                />
                                {showTabBarInReadingPane && <TabBar />}
                            </SkipLinkRegion>
                        )}
                    </>
                )}
                {showNotificationBar && (
                    <NotificationBarHost
                        hostId={'MailModuleNotificationBarHost'}
                        onNotificationBlur={onNotificationBlurCallback}
                    />
                )}
                {isFeatureEnabled('tips-tour') && <OutlookTourHost />}
            </div>
            {isFeatureEnabled('acct-fullOptionsProjectionPopout') ? <></> : <MailHotkeysMap />}
        </div>
    );
}, 'MailView');

function onRightPaneResized(listViewDimension: number) {
    const isWidth = isReadingPanePositionRight();
    setListViewDimension(listViewDimension, isWidth);
    triggerResizeEvent();
}

function onNotificationBlurCallback() {
    resetFocus('MailViewNotificationBarHost');
}
