import { LazyAction, LazyImport, LazyModule } from 'owa-bundling';
import type { LazyActionOptions } from 'owa-bundling-light';

// import owa-options-message-options to load the message options not included in sessionData

export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailComposeActions"*/ './lazyIndex')
);

const options: LazyActionOptions = {
    captureBundleTime: true,
};

export const lazyReplyToMessage = new LazyAction(lazyModule, m => m.replyToMessage, options);
export const lazyResendMessage = new LazyAction(lazyModule, m => m.resendMessage, options);
export const lazyForwardMessage = new LazyAction(lazyModule, m => m.forwardMessage, options);
export const lazyOnRespondExtendedCardAction = new LazyAction(
    lazyModule,
    m => m.onRespondExtendedCardAction
);
export const lazyCloseCompose = new LazyAction(lazyModule, m => m.closeCompose);
export const lazyDiscardCompose = new LazyAction(lazyModule, m => m.discardCompose);
export const lazyLoadDraftToCompose = new LazyAction(lazyModule, m => m.loadDraftToCompose);
export const lazyOpenCompose = new LazyAction(lazyModule, m => m.openCompose);
export const lazyOpenComposeAndChangeFrom = new LazyAction(
    lazyModule,
    m => m.openComposeAndChangeFrom
);
export const lazyToggleFrom = new LazyAction(lazyModule, m => m.toggleFrom);
export const lazyTrySaveAndCloseCompose = new LazyAction(lazyModule, m => m.trySaveAndCloseCompose);
export const lazyTrySaveAndCloseAllMessages = new LazyAction(
    lazyModule,
    m => m.trySaveAndCloseAllMessages
);
export const lazyMoveComposeToTab = new LazyAction(lazyModule, m => m.moveComposeToTab);
export const lazyTryCreateResendDraft = new LazyAction(lazyModule, m => m.tryCreateResendDraft);
export const lazyReplyToApprovalMessage = new LazyAction(lazyModule, m => m.replyToApprovalMessage);
export const lazyOnEditDraftButtonClicked = new LazyAction(
    lazyModule,
    m => m.onEditDraftButtonClicked
);
export const lazyTrySaveMessage = new LazyAction(lazyModule, m => m.trySaveMessage);

export type { ComposeViewStateInitOptions } from './schema/ComposeViewStateInitOptions';

// Delay loaded utilities
export const lazyGetToCcRecipientsForReply = new LazyImport(
    lazyModule,
    m => m.getToCcRecipientsForReply
);
export const lazyReplyToMessageAndCreateViewState = new LazyImport(
    lazyModule,
    m => m.replyToMessageAndCreateViewState
);
export const lazyCreateAttachments = new LazyImport(lazyModule, m => m.createAttachments);
export const lazyExtractComposePopoutData = new LazyImport(
    lazyModule,
    m => m.extractComposePopoutData
);

export const lazyCheckShouldUseFallbackPicker = new LazyImport(
    lazyModule,
    m => m.checkShouldUseFallbackPicker
);
export const lazyTryGetSmimeViewState = new LazyImport(lazyModule, m => m.tryGetSmimeViewState);
export const lazyGetActingMailboxInfoForNewMessage = new LazyImport(
    lazyModule,
    m => m.getActingMailboxInfoForNewMessage
);
export const lazyGetRespondSubject = new LazyImport(lazyModule, m => m.getRespondSubject);
