import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import loc from 'owa-localize';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import { recordLoopVideo } from '../strings/actionControlStrings.locstring.json';
import { recordVideoDesc } from '../strings/hoverTooltipDesc.locstring.json';
import Recorder from 'owa-fluent-icons-svg/lib/icons/VideoRegular';
import {
    createAppButton,
    constructKeytip,
    getRibbonIconModeColor,
    type RibbonIconColor,
} from 'owa-command-ribbon';
import type { RibbonFluidMenuInfo } from 'owa-fluid-integrations';
import { lazyStartRecorder } from 'owa-fluid-integrations';
import type { MailboxInfo } from 'owa-client-types';

export const getVideoRecorder = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonFluidMenuInfo | null,
        keyTipTabId: number | undefined,
        mailboxInfo: MailboxInfo,
        styles: IButtonStyles | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        return createAppButton(
            getComposeRibbonId(7026, editorId),
            loc(recordLoopVideo),
            lazyStartRecorder.tryImportForRender()?.(
                'MailCompose',
                composeId,
                retriever,
                mailboxInfo
            ) || (() => {}),
            keyTipTabId ? constructKeytip([keyTipTabId], 7026) : undefined,
            Recorder,
            getRibbonIconModeColor(4),
            styles,
            {
                customTooltip: getHoverTooltip(recordLoopVideo, recordVideoDesc),
                disabled: isDisabled,
            }
        );
    }
);
