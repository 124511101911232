import { tableBorders } from './tableControlsStrings.locstring.json';
import { onApplyTableBorderMenuClicked } from '../../actions/deferredMenuDefinitionMutator';
import { constructKeytip, createAppFlyoutAnchor } from 'owa-command-ribbon';
import { resetMenuDefinition } from 'owa-compose-control-sections-store/lib/actions/menuDefinitionActions';
import { getControlMenuStore } from 'owa-compose-control-sections-store/lib/store/menuDefinitionStore';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { owaComputedFn } from 'owa-computed-fn';
import BorderAllRegular from 'owa-fluent-icons-svg/lib/icons/BorderAllRegular';
import loc from 'owa-localize';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import type { IButtonStyles } from '@fluentui/react';
import type { RibbonControlProps } from '@1js/acui-ribbon-like';
import type { RibbonViewStateInfo } from 'owa-compose-formatcontrols-sections/lib/schema/RibbonViewStateInfo';
import { callEditorApi } from 'owa-editor/lib/utils/callEditorApi';

export const getApplyTableBorderControl = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        styles: IButtonStyles | undefined,
        retriever: (composeId: string) => RibbonViewStateInfo | null,
        tabId: number | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        const storeName = 'applyTableBorderMenuDefinitionStore';
        return createAppFlyoutAnchor(
            getComposeRibbonId(6100, editorId),
            loc(tableBorders),
            getControlMenuStore(editorId, storeName),
            tabId ? constructKeytip([tabId], 6100, true /* hasMenu */) : undefined,
            BorderAllRegular,
            undefined /* iconColor */,
            styles,
            {
                disabled: isDisabled,
                onExecute: parameter => {
                    const { event } = parameter;
                    event?.stopPropagation();
                    onApplyTableBorderMenuClicked(editorId, tabId, retriever, composeId);
                },
                onAfterMenuDismiss: () => {
                    resetMenuDefinition(editorId, storeName);
                    callEditorApi(editorId, 'focus');
                },
            }
        );
    }
);
