import React from 'react';

export function useIntersectionObserver(
    source: string,
    target: React.RefObject<HTMLDivElement>,
    callback: (isVisible: boolean) => void,
    options?: IntersectionObserverInit
) {
    React.useEffect(() => {
        if (target.current) {
            const cb = ([entry]: IntersectionObserverEntry[]) => {
                if (target.current) {
                    callback(entry.isIntersecting);
                }
            };
            try {
                Object.defineProperty(cb, 'name', { value: source + '_intersection' });
            } catch {
                // best effort so do nothing if there was an exception
            }

            const observer = new IntersectionObserver(cb, options);
            observer.observe(target.current);
            return () => observer.disconnect();
        }
        return undefined;
    }, [target.current, callback]);
}
