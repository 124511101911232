import { LazyModule, createLazyComponent } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "MailSubjectV3" */ './lazyIndex'),
    { name: 'MailSubjectV3' }
);

// Export lazy components
export const LazySubjectHeaderV3 = createLazyComponent(lazyModule, m => m.SubjectHeaderV3);

export const LazySLVNavButtons = createLazyComponent(lazyModule, m => m.SLVNavButtons);
