import type { RibbonControlProps } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/Controls/RibbonControlProps';
import type { IButtonStyles } from '@fluentui/react';
import { owaComputedFn } from 'owa-computed-fn';
import { constructKeytip, createAppButton } from 'owa-command-ribbon';
import { getComposeRibbonId } from 'owa-compose-ribbon-utils/lib/utils/getComposeRibbonId';
import { getHoverTooltip } from 'owa-compose-ribbon-utils/lib/utils/getHoverTooltip';
import TextTRegular from 'owa-fluent-icons-svg/lib/icons/TextTRegular';
import loc from 'owa-localize';
import { switchBodyType } from 'owa-mail-compose-command';
import { type ActionControlId } from 'owa-ribbon-ids/lib/actionControlId';
import type RibbonMailComposeInfo from 'owa-mail-compose-control-sections/lib/types/RibbonMailComposeInfo';
import {
    switchToHTMLTextText,
    switchToPlainTextText,
} from '../strings/actionControlStrings.locstring.json';
import { getComputedCallback } from 'owa-ribbon-mobx-utils/lib/getComputedCallback';
import { switchBodyTypeDesc } from '../strings/hoverTooltipDesc.locstring.json';

const onSwitchBodyType = (
    composeId: string,
    retriever: (composeId: string) => RibbonMailComposeInfo | null
) => {
    const editingInfo = retriever(composeId);

    if (editingInfo) {
        switchBodyType(editingInfo.composeViewState, editingInfo.targetWindow);
    }
};

export const getSwitchBodyType = owaComputedFn(
    (
        editorId: string,
        composeId: string,
        retriever: (composeId: string) => RibbonMailComposeInfo | null,
        isPlainText: boolean,
        styles: IButtonStyles | undefined,
        keyTipTabId: number | undefined,
        isDisabled: boolean
    ): RibbonControlProps => {
        const controlLabel = isPlainText ? switchToHTMLTextText : switchToPlainTextText;
        return createAppButton(
            getComposeRibbonId(7002, editorId),
            loc(controlLabel),
            getComputedCallback(7002, onSwitchBodyType, composeId, retriever),
            keyTipTabId ? constructKeytip([keyTipTabId], 7002) : undefined,
            TextTRegular,
            undefined /* iconColor */,
            styles,
            {
                customTooltip: getHoverTooltip(controlLabel, switchBodyTypeDesc),
                disabled: isDisabled,
            }
        );
    }
);
