import { popoutMailReadingPane } from 'owa-popout';
import {
    LazyModule,
    registerLazyOrchestrator,
    createLazyComponent,
    LazyAction,
} from 'owa-bundling';

export const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "PopoutMailReadingPane"*/ './lazyIndex')
);

// Register lazy orchestrators
registerLazyOrchestrator(
    popoutMailReadingPane,
    lazyModule,
    m => m.popoutMailReadingPaneOrchestrator
);

// Lazy components
export const ProjectionReadingPane = createLazyComponent(lazyModule, m => m.ProjectionReadingPane);
export const lazyPopoutMailReadingPaneUtil = new LazyAction(
    lazyModule,
    m => m.popoutMailReadingPaneUtil
);
