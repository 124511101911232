import isSMIMEItem from './isSMIMEItem';
import type ClientItem from 'owa-mail-store/lib/store/schema/ClientItem';
import type SmimeType from 'owa-smime-adapter/lib/store/schema/SmimeType';
import {
    isItemClassSmimeClearSigned,
    isItemClassSmimeAndNotClearSigned,
} from './smimeItemClassUtils';

/**
 * Utility to decide S/MIME type for an item.
 * This function will return actual SmimeType if the message is decoded
 * otherwise it will decide S/MIME type based on item class.
 */
export default (item: ClientItem | undefined): SmimeType => {
    if (!item || !isSMIMEItem(item)) {
        return 0;
    }

    if (item.Smime?.SmimeType) {
        return item.Smime.SmimeType;
    }

    // The item has not been decoded, return type by class name
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2345 (27,40): Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
    // @ts-expect-error
    return isItemClassSmimeClearSigned(item.ItemClass) ? 11 : -1;
};

/**
 * Utility to decide conversation S/MIME type.
 * This function will return S/MIME type as Unknown if any of itemClasses in
 * a conversation has itemClass as Encrypted/Opaque otherwise it will return S/MIME type as
 * ClearSign if any of itemClasses in conversation has itemClass as ClearSign.
 * Smime.None will be returned by default if any of the above cases not applicable.
 */
export const getSmimeTypeForConversation = (itemClasses: string[]): SmimeType => {
    if (!itemClasses || !itemClasses.length) {
        return 0;
    }

    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2322 (45,5): Type 'string' is not assignable to type 'SmimeType'.
    // @ts-expect-error
    return itemClasses.reduce((smimeType, itemClass) => {
        if (isItemClassSmimeAndNotClearSigned(itemClass)) {
            return -1;
        }

        if (smimeType === 0 && isItemClassSmimeClearSigned(itemClass)) {
            return 11;
        }

        return smimeType;
    }, 0);
};
