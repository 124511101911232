import getAllFromAddresses from './getAllFromAddresses';
import { isSameStringIgnoreCase } from '../isSelf';
import type EmailAddressWrapper from 'owa-service/lib/contract/EmailAddressWrapper';
import type { MailboxInfo } from 'owa-client-types';
import type Message from 'owa-service/lib/contract/Message';
import { getIsInSharedFolder } from '../sharedFolderUtils';
import { getFolderTable } from 'owa-folders';
import { isFeatureEnabled } from 'owa-feature-flags';

/**
 * Gets the address the email was sent to (when replying to an email)
 * Consumers can set the default address they send from, so this may be different
 * than the account name they used when initally creating the account
 *
 * Looks through all of the addresses a user can send from, and matches against
 * the To or Cc recipients of the email
 *
 * Returns the first address belonging to the sender that is found in the recipients,
 * or else null if no matching address is found
 *
 * @param message
 * @param toRecipients the To recipients of the message
 * @param ccRecipients the Cc recipients of the message
 * @param mailboxInfo
 */
export default function getSenderInRecipients(
    referenceItem: Message,
    mailboxInfo: MailboxInfo
): string | null {
    const toRecipients = referenceItem.ToRecipients;
    const ccRecipients = referenceItem.CcRecipients;
    const recipients = (toRecipients || []).concat(ccRecipients || []);
    return tryFindRecipient(recipients, [
        ...getSharedFolderAddress(referenceItem),
        ...getAllFromAddresses(
            mailboxInfo,
            true /* includeProxyAddressesForEnt */,
            false /* includeAutomappedAccounts */
        ),
    ]);
}

function tryFindRecipient(recipients: EmailAddressWrapper[], addresses: string[]): string | null {
    for (let i = 0; i < addresses.length; i++) {
        if (isInRecipients(addresses[i], recipients)) {
            return addresses[i];
        }
    }
    return null;
}

function isInRecipients(emailAddress: string, recipients: EmailAddressWrapper[]): boolean {
    if (recipients && recipients.length > 0) {
        for (const recipient of recipients) {
            if (recipient && isSameStringIgnoreCase(emailAddress, recipient.EmailAddress)) {
                return true;
            }
        }
    }
    return false;
}

function getSharedFolderAddress(message: Message): string[] {
    if (
        !getIsInSharedFolder(message.ItemId) ||
        !message.ParentFolderId ||
        !isFeatureEnabled('cmp-auto-select-shared-mailbox')
    ) {
        return [];
    }

    const ftable = getFolderTable();
    const folder = ftable.get(message.ParentFolderId.Id);

    return folder?.mailboxInfo ? [folder.mailboxInfo.mailboxSmtpAddress] : [];
}
