import { addDatapointConfig } from 'owa-analytics-actions';
import { action, mutator } from 'satcheljs';
import type { ComposeViewState } from 'owa-mail-compose-store';
import type ImportanceType from 'owa-service/lib/contract/ImportanceType';

const setImportance = action(
    'setImportance',
    (viewState: ComposeViewState, importance: ImportanceType) =>
        addDatapointConfig(
            {
                name: 'ComposeCommmandSetImportance',
                customData: [importance],
            },
            { viewState, importance }
        )
);

mutator(setImportance, ({ viewState, importance }) => {
    viewState.importance = importance;
});

export default setImportance;
