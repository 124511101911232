export const SAFELINKS_CLICK_HANDLER = 'safelinksClickHandler';

export interface SafelinksElement extends HTMLElement {
    href?: string;
    safelinksClickHandler?: (ev: MouseEvent) => void;
}

//should be same maxQueryString in https://o365exchange.visualstudio.com/O365%20Core/_git/Substrate?path=/sources/dev/cafe/src/HttpProxy/web.config/DataCenter/owa.web.config
export const MAX_QUERY_STRING_LENGTH_SHORT = 4096;
export const MAX_QUERY_STRING_LENGTH = 8192;
