import { observer } from 'owa-mobx-react';
import type { PersonaPresence } from '@fluentui/react/lib/Persona';
import { PersonaControl, PersonaCardBehavior } from 'owa-persona';
import { getAccountScopeUserSettings } from 'owa-session-store';
import React from 'react';
import type { PersonaCardBehaviorProps } from 'owa-persona/lib/components/PersonaCardBehavior';
import type { PersonaType } from 'owa-lpc';
import { calculatePersonaSize } from 'owa-persona/lib/utils/calculatePersonaSize';
import type { SenderImageProps } from './SenderImageProps';

const SenderImage = (props: SenderImageProps) => {
    const { sender, displaySelf, style, viewerMailboxInfo } = props;
    const mailBoxType = sender?.Mailbox?.MailboxType;
    let name = sender?.Mailbox?.Name;
    let emailAddress = sender?.Mailbox?.EmailAddress;
    let personaType: PersonaType | undefined = undefined;
    if (displaySelf) {
        const sessionSettings = getAccountScopeUserSettings(viewerMailboxInfo).SessionSettings;
        name = sessionSettings?.UserDisplayName;
        emailAddress = sessionSettings?.UserEmailAddress;
        personaType = 'User';
    }

    return props.disablePersonaCardBehavior ? (
        <div className={style}>
            <PersonaControl
                name={name}
                emailAddress={emailAddress}
                size={props.size || calculatePersonaSize()}
                showUnknownPersonaCoin={props.isUnauthenticatedSender}
                mailboxType={mailBoxType}
            />
        </div>
    ) : (
        <SenderImageDiv
            name={name}
            emailAddress={emailAddress}
            style={style}
            personaType={personaType}
            mailBoxType={mailBoxType}
            isUnauthenticatedSender={props.isUnauthenticatedSender}
            clientScenario="SenderImageDiv"
            size={props.size}
            displayPersonaHighlightRing={props.displayPersonaHighlightRing}
            viewerMailboxInfo={viewerMailboxInfo}
        />
    );
};
export default SenderImage;

export interface SenderImageDivProps extends PersonaCardBehaviorProps {
    style?: string;
    presence?: PersonaPresence; // Delete this flight after fwk-skype-deprecation will be removed, or get presence from another source.
}

const SenderImageDiv = observer(function SenderImageDiv(props: SenderImageDivProps) {
    return (
        <div className={props.style}>
            <PersonaCardBehavior {...props}>
                <PersonaControl
                    name={props.name}
                    emailAddress={props.emailAddress}
                    size={props.size || calculatePersonaSize()}
                    showUnknownPersonaCoin={props.isUnauthenticatedSender}
                    mailboxType={props.mailBoxType}
                />
            </PersonaCardBehavior>
        </div>
    );
}, 'SenderImageDiv');
