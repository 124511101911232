import { LazyAction, LazyModule, createLazyComponent } from 'owa-bundling';

const lazyModule = new LazyModule(
    () => import(/* webpackChunkName: "FindTimeCompose"*/ './lazyIndex')
);

// Export actions
export const lazyOpenMeetingPollPane = new LazyAction(lazyModule, m => m.openMeetingPollPane);

// Export OPX actions
export const MeetingPollPaneOPX = createLazyComponent(lazyModule, m => m.MeetingPollPaneOPX);
export const lazyInitializeOpxData = new LazyAction(lazyModule, m => m.initializeOpxData);

// Export Types
export type { FindTimePanelSource } from './store/schema/FindTimePanelSource';
