import { FocusZone, FocusZoneDirection } from '@fluentui/react/lib/FocusZone';
import classnames from 'owa-classnames';
import { observer } from 'owa-mobx-react';
import { isMonarchMultipleAccountsEnabled } from 'owa-account-source-list/lib/flights';
import Droppable from 'owa-dnd/lib/components/Droppable';
import createDropViewState from 'owa-dnd/lib/utils/createDropViewState';
import { getDensityMode, getDensityModeCssClass } from 'owa-fabric-theme';
import { isFeatureEnabled } from 'owa-feature-flags';
import loc from 'owa-localize';
import Strings from 'owa-locstrings/lib/strings/navigationpanescreenreaderonlytext.locstring.json';
import { AccountsTreeView } from 'owa-mail-accounts-view';
import { FavoritesTree, getFavoritesNodeCount } from 'owa-mail-favorites-view';
import setIsDraggedOverState from 'owa-mail-folder-store/lib/actions/setIsDraggedOverState';
import { MailFolderTreesParentContainer, getSetSize } from 'owa-mail-folder-tree-container-view';
import React from 'react';
import {
    container,
    containerWillChangeScrollPosition,
    full,
    medium,
    compact,
    isChromium as styles_isChromium,
    droppableContainer,
    leftNavSectionWithSectionHeaders,
    containerPadding,
    containerPaddingBleedThrough,
    safariScrollHandling,
} from './FolderPane.scss';
import { getBrowserInfo, type Browser } from 'owa-user-agent';
import { isCapabilitySupported } from 'owa-capabilities';
import { bleedThroughCapability } from 'owa-capabilities-definitions/lib/bleedThroughCapability';
import { BootState, getGlobalSettingsAccountMailboxInfo } from 'owa-account-source-list-store';
import { shouldHideFavoritesList } from 'owa-favorites';
import { getUserConfiguration } from 'owa-session-store';

enum densitySpacing {
    Compact = '12px',
    Full = '12px',
    Simple = '8px',
}

export default observer(function FolderPane() {
    const { browser } = getBrowserInfo();
    const isChromium = browser === 'Chrome' || browser === 'Edge Anaheim';
    const isSafari = browser === 'Safari';
    const containerClassNames = classnames(
        container,
        containerWillChangeScrollPosition,
        getDensityModeCssClass(full, medium, compact),
        isChromium && styles_isChromium, // show persistent scrollbar in Firefox since it does not support overlay scrollbar
        'customScrollBar',
        'disableTextSelection'
    );

    /* TODO: OW:16444: [Accessibility] [Folder] We should be able to tab into the regular Folder tree even if the Favorites tree exists */
    return (
        <FocusZone
            key="folderPane"
            className={containerClassNames}
            direction={FocusZoneDirection.vertical}
        >
            <FolderForest isChromium={isChromium} isSafari={isSafari} />
        </FocusZone>
    );
}, 'FolderPane');

function onDragEnter() {
    const draggableContainer = document.getElementById('folderPaneDroppableContainer');
    if (draggableContainer) {
        draggableContainer.style.overflow = 'overlay';
    }
}

function onDragOver() {
    setIsDraggedOverState(true);
}

function onDragLeave() {
    setIsDraggedOverState(false);
    const draggableContainer = document.getElementById('folderPaneDroppableContainer');
    if (draggableContainer) {
        draggableContainer.style.overflow = '';
    }
}

function onDrop() {
    // NOOP
}

const FolderForest = observer(function FolderForest({
    isChromium,
    isSafari,
}: {
    isChromium: boolean;
    isSafari: boolean;
}) {
    const showAccountsList =
        isMonarchMultipleAccountsEnabled() || isFeatureEnabled('acct-multiacctcomingsoon');
    const favoritesNodeCount = getFavoritesNodeCount();
    const setSize = getSetSize(showAccountsList, favoritesNodeCount);
    const emptyDropViewState = createDropViewState();
    // Must pass bypassActOnDrop as true so that children nodes' canDrop evaluation
    // is not masked by this component's canDrop, which is always true by default
    const bypassActOnDrop = true;
    // The pre-render work for the global settings account happens in startup
    const globalSettingsBootState = BootState.StartupComplete;
    const shouldShowExpanded = !getUserConfiguration()?.UserOptions?.IsMailRootFolderTreeCollapsed;
    const favoritesListCheck = shouldHideFavoritesList();
    const density = getDensityMode();
    const accountPaddingStyle = {
        paddingBottom: densitySpacing[density],
        paddingTop: 0,
    } as React.CSSProperties;
    const ellipsesOnHover = isFeatureEnabled('fp-ellipsesOnHover');
    const isBleedThroughSupported = isCapabilitySupported(bleedThroughCapability);

    return (
        <Droppable
            id="folderPaneDroppableContainer"
            onDragOver={onDragOver}
            onDrop={onDrop}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            dropViewState={emptyDropViewState}
            greedy={true}
            classNames={classnames(
                droppableContainer,
                'customScrollBar',
                isChromium && styles_isChromium,
                isSafari && safariScrollHandling
            )}
            bypassActOnDrop={bypassActOnDrop}
        >
            <div
                className={
                    isBleedThroughSupported ? containerPaddingBleedThrough : containerPadding
                }
            >
                <span className="screenReaderOnly" role="heading" aria-level={2}>
                    {loc(Strings.navigationPaneScreenReaderOnlyText)}
                </span>
                {favoritesNodeCount > 0 && !favoritesListCheck && (
                    <FavoritesTree
                        key="favorites"
                        className={classnames(leftNavSectionWithSectionHeaders)}
                        style={accountPaddingStyle}
                        setSize={setSize}
                        positionInSet={1}
                    />
                )}

                {!showAccountsList && (
                    <MailFolderTreesParentContainer
                        accountMailboxInfo={getGlobalSettingsAccountMailboxInfo()}
                        accountBootState={globalSettingsBootState}
                        favoritesNodeCount={favoritesNodeCount}
                        setSize={setSize}
                        isExpanded={shouldShowExpanded}
                        ellipsesOnHover={ellipsesOnHover}
                    />
                )}
                {showAccountsList && (
                    <AccountsTreeView
                        key="accounts"
                        favoritesNodeCount={favoritesNodeCount}
                        ellipsesOnHover={ellipsesOnHover}
                    />
                )}
            </div>
        </Droppable>
    );
},
'FolderForest');
