import type { RibbonTabDefinitionBase } from '@1js/acui-ribbon-like/lib/UISurfaces/Ribbon/components';
import { owaComputedFn } from 'owa-computed-fn';
import type { ComposeRibbonControlDefProps } from 'owa-mail-ribbon-utils';
import { type FormatControlId } from 'owa-ribbon-ids/lib/formatControlId';
import { type MailRibbonTabId } from 'owa-ribbon-ids/lib/mailRibbonId';
import getSLRComposeTab from '../../../util/getSLRComposeTab';

const getSLRFormatTab = owaComputedFn(function getSLRFormatTab(
    props: ComposeRibbonControlDefProps
): RibbonTabDefinitionBase {
    return getSLRComposeTab(
        props,
        4,
        [] /* controlsDontDropLabels */,
        [] /* controlsDontMoveToOverflow */,
        [] /* controlsFirstMoveToOverflow */,
        [
            6001, 6002, 6003, 6004, 6005, 6006, 6007, 6008, 6013, 6017, 6018, 6019, 6110, 6022,
            6023, 6020, 6021, 6016, 6024, 6025, 6026, 6027, 6028, 6029, 6030, 6012, 6073, 6075,
        ] /* controlsWithoutLabels */
    );
});

export default getSLRFormatTab;
