import { owaComputedFn } from 'owa-computed-fn';
import { getComposeHostItemIndex } from 'owa-addins-store/lib/utils/hostItemIndexUtils';
import getAddinCollection from 'owa-addins-surface-actions/lib/utils/getAddinCollection';
import {
    storePollFilter,
    invalidAddInsFilter,
} from 'owa-addins-surface-actions/lib/utils/getAddinFilters';
import { ExtensibilityModeEnum } from 'owa-addins-types';
import { getDefaultRibbonStyles } from 'owa-command-ribbon-styles/lib/util/getDefaultRibbonStyles';
import { createPinnedAddInButton } from 'owa-mail-compose-controls/lib/components/pinnedAddIns';
import { retrieveCommonInfoForRibbon } from 'owa-mail-compose-controls/lib/utils/retrieveEditingInfoForRibbon';
import getServerOptionsForFeature from 'owa-outlook-service-option-store/lib/selectors/getOptionsForFeature';
import { OwsOptionsFeatureType } from 'owa-outlook-service-option-store/lib/store/schema/OwsOptionsFeatureType';

import type { AddinCommandSurfaceItem } from 'owa-addins-types';
import type { SurfaceActionsOptions } from 'owa-outlook-service-option-store';
import type { ComposeViewState, SharedFolderComposeViewState } from 'owa-mail-compose-store';
import type { RuntimeControlId } from 'owa-ribbon-ids';
import type { RibbonRuntimeControlsGroup, RibbonRuntimeControl } from './getRuntimeControls';
import { isSharedComposeItemCheckForAddins } from 'owa-mail-store/lib/utils/sharedFolderUtilsForAddins';
import createMessageExtensionCommandSurfaceItem from 'owa-addins-view/lib/utils/createMessageExtensionCommandSurfaceItem';
import { readM365AcquisitionsFromCache } from 'owa-m365-acquisitions/lib/readM365AcquisitionsFromCache';
import { hasComposeMessageExtension } from 'owa-m365-acquisitions/lib/utils/hasMessageExtension';
import { getModuleContextMailboxInfo } from 'owa-module-context-mailboxinfo';
import { isFeatureEnabled, isAddinMultiAccountEnabled } from 'owa-feature-flags';
import { isMOS3AppServiceAvailable } from 'owa-m365-acquisitions/lib/utils/isMOS3AppServiceAvailable';
import { logUsage } from 'owa-analytics';

const getRibbonPinnedAddIns = owaComputedFn(function getRibbonPinnedAddIns(
    viewState: ComposeViewState,
    targetWindow: Window
): AddinCommandSurfaceItem[] {
    const hostItemIndex = getComposeHostItemIndex(viewState.composeId);
    const isSharedItem = isSharedComposeItemCheckForAddins(
        (viewState as SharedFolderComposeViewState)?.isInSharedFolder
    );

    const allAddIns: AddinCommandSurfaceItem[] = getAddinCollection(
        ExtensibilityModeEnum.MessageCompose,
        isSharedItem,
        hostItemIndex,
        viewState.mailboxInfo,
        targetWindow
    )
        .filter(invalidAddInsFilter)
        .filter(item => storePollFilter(item, viewState.mailboxInfo));

    const pinnedAddInsIds: string[] = getServerOptionsForFeature<SurfaceActionsOptions>(
        OwsOptionsFeatureType.SurfaceActions
    ).composeSurfaceAddins;

    const mailboxInfo = getModuleContextMailboxInfo();

    if (isFeatureEnabled('mos-messageExtensionPinning') && isMOS3AppServiceAvailable(mailboxInfo)) {
        const acquisitions = readM365AcquisitionsFromCache(
            undefined /* datapoint */,
            isAddinMultiAccountEnabled() ? mailboxInfo : undefined
        );

        acquisitions.forEach(acquisition => {
            if (
                hasComposeMessageExtension(acquisition) &&
                pinnedAddInsIds.includes(acquisition.appId ?? '')
            ) {
                // We only add the add-in if it doesn't have any duplicate in the list
                if (!allAddIns.some(addIn => addIn.key === acquisition.appId)) {
                    allAddIns.push(
                        createMessageExtensionCommandSurfaceItem({
                            id: acquisition.appId ?? '',
                            name: acquisition.titleDefinition.name ?? '',
                            description: acquisition.titleDefinition.shortDescription ?? '',
                            iconUrl: acquisition.titleDefinition.iconLarge?.uri ?? '',
                        })
                    );
                } else {
                    logUsage('Duplicate_AddIn_Found_RibbonPinnedList');
                }
            }
        });
    }

    return allAddIns.filter(addIn => pinnedAddInsIds.includes(addIn.key));
});

export function getPinnedAddInsRuntimeControls(): RibbonRuntimeControlsGroup {
    const ribbonPinnedAddIns = new Map<string, RibbonRuntimeControl>();
    let PINNED_ADDIN_CONTROL_ID: RuntimeControlId.PinnedAddIns = 90000;

    const runtimeControlGroup: RibbonRuntimeControlsGroup = {
        controlsGroupName: 'ComposeAddInsPinned',
        shouldAddScalingSteps: () => {
            return true;
        },
        getControlsProps: (props: { composeViewState?: ComposeViewState }) => {
            const viewState = props.composeViewState;
            if (!viewState) {
                return [];
            }
            const defaultStyles = getDefaultRibbonStyles();
            const targetWindow =
                retrieveCommonInfoForRibbon(viewState.composeId)?.targetWindow ?? window;

            const pinnedAddins = getRibbonPinnedAddIns(viewState, targetWindow);
            return pinnedAddins.map(addIn => {
                let controlId = ribbonPinnedAddIns.get(addIn.key)?.controlId;
                if (!controlId) {
                    controlId = ++PINNED_ADDIN_CONTROL_ID;
                }

                const runtimeControl: RibbonRuntimeControl = {
                    controlId,
                    buttonProps: createPinnedAddInButton(
                        addIn,
                        viewState.composeId,
                        viewState.editorId,
                        controlId,
                        targetWindow,
                        defaultStyles
                    ),
                };
                ribbonPinnedAddIns.set(addIn.key, runtimeControl);
                return runtimeControl;
            });
        },
        getControlIds() {
            return Array.from(ribbonPinnedAddIns.values()).map(({ controlId }) => controlId);
        },
    };
    return runtimeControlGroup;
}
