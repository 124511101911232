export enum ClickSource {
    SafelinkHandler,
    UrlDetectionContentHandler,
    OneDriveLinkHandler,
    WebPageLinkPreviewHandler,
    MailList,
    ConversationAttachmentWell,
    LoopUrlHandlerProvider,
    ActionableMessage,
    MessageExtension,
    M365Links,
    SafelinkComposeHandler,
}

export enum LinkHost {
    Attachment,
    Body,
}
