import React from 'react';
import { FluentButton } from 'owa-fluent-v9-shims';
import { shouldShowListView } from 'owa-mail-layout';
import { lazyOnNavigateAwayViaUpDown } from 'owa-mail-mark-read-actions';
import { selectRowInDirection } from 'owa-mail-actions/lib/mailListSelectionActions';
import MailListItemSelectionSource from 'owa-mail-store/lib/store/schema/MailListItemSelectionSource';
import { observer } from 'owa-mobx-react';
import { getSelectedTableView, SelectionDirection, type TableView } from 'owa-mail-list-store';
import { ImmersiveExitButton } from 'owa-immersive-exit-button';
import { getDensityModeCssClass } from 'owa-fabric-theme';
import loc from 'owa-localize';
import {
    nextItemButton,
    previousItemButton,
    previousItemButtonLabel,
    nextItemButtonLabel,
} from './SLVNavButtons.locstring.json';
/* eslint-disable-next-line @typescript-eslint/no-restricted-imports  -- (https://aka.ms/OWALintWiki)
 * Baseline - Migrating deprecated icons: https://outlookweb.visualstudio.com/Outlook%20Web/_wiki/wikis/Outlook%20Web.wiki/9297/Migrating-deprecated-icons-in-client-web
 *	> 'ControlIcons' import from owa-control-icons is restricted. Use JSX icons from @fluentui/react-icons, otherwise from owa-fluent-icons-svg if they can only be reference by string. */
import { type ControlIcons } from 'owa-control-icons';

import {
    arrowButtonIcons,
    full,
    medium,
    compact,
    slvNav,
    immersiveExitContainer,
    exitButtonIcon,
    separator,
    navButtonsContainer,
    slvNavButtons,
    iconFull,
    iconMedium,
    iconCompact,
} from './SubjectHeaderV2.scss';

import classnames from 'owa-classnames';

export interface SLVNavButtonsProps {
    shouldShrink?: boolean;
}

export default observer(function SLVNavButtons(props: SLVNavButtonsProps) {
    const { shouldShrink } = props;
    const tableView = getSelectedTableView();
    const selectedRows = tableView.selectedRowKeys;
    const rowKeys = tableView.rowKeys;
    const densityMode = getDensityModeCssClass(full, medium, compact);

    const prevArrowIconProps = React.useMemo(() => {
        return {
            iconName: 'Back',
            className: arrowButtonIcons,
            styles: {
                root: getIconStyle(densityMode),
            },
        };
    }, [densityMode]);

    const nextArrowIconProps = React.useMemo(() => {
        return {
            iconName: 'Forward',
            className: arrowButtonIcons,
            styles: {
                root: getIconStyle(densityMode),
            },
        };
    }, [densityMode]);

    // if in slv rp, we should only have 1 selected row
    let isFirst;
    let isLast = false;
    if (rowKeys && selectedRows) {
        const selectedRow = selectedRows.keys().next().value;
        const selectedIndex = rowKeys.indexOf(selectedRow);
        if (selectedIndex == 0) {
            isFirst = true;
        }
        if (selectedIndex == rowKeys.length - 1) {
            isLast = true;
        }
    }

    const arrowUp = React.useCallback(
        React.useCallback(() => nextPrevious(SelectionDirection.Previous, tableView), []),
        []
    );
    const arrowDown = React.useCallback(
        React.useCallback(() => nextPrevious(SelectionDirection.Next, tableView), []),
        []
    );

    return (
        <div className={slvNav}>
            <ImmersiveExitButton
                iconButtonContainer={immersiveExitContainer}
                iconButtonStyle={classnames(exitButtonIcon, getIconStyle(densityMode))}
                shouldUseTextButton={!shouldShrink}
                extraButtonStyles={classnames(
                    slvNavButtons,
                    densityMode,
                    getIconStyle(densityMode)
                )}
            />

            <div className={separator} />
            <div className={navButtonsContainer}>
                <FluentButton
                    appearance={shouldShrink ? 'icon' : 'command'}
                    className={classnames(slvNavButtons, getIconStyle(densityMode))}
                    title={loc(previousItemButton)}
                    aria-label={loc(previousItemButton)}
                    onClick={arrowUp}
                    disabled={isFirst}
                    iconProps={shouldShrink ? prevArrowIconProps : undefined}
                >
                    {shouldShrink ? '' : loc(previousItemButtonLabel)}
                </FluentButton>
                <FluentButton
                    className={classnames(slvNavButtons, getIconStyle(densityMode))}
                    appearance={shouldShrink ? 'icon' : 'command'}
                    title={loc(nextItemButton)}
                    aria-label={loc(nextItemButton)}
                    onClick={arrowDown}
                    disabled={isLast}
                    iconProps={shouldShrink ? nextArrowIconProps : undefined}
                >
                    {shouldShrink ? '' : loc(nextItemButtonLabel)}
                </FluentButton>
            </div>
        </div>
    );
}, 'SLVNavButtons');

function nextPrevious(selectionDirection: SelectionDirection, tableView: TableView) {
    const selectedRows = tableView.selectedRowKeys;

    // Select the next or previous item in immersive mode
    if (!shouldShowListView() && selectedRows.size > 0) {
        lazyOnNavigateAwayViaUpDown.importAndExecute([...selectedRows.keys()][0], tableView);
        selectRowInDirection(
            tableView,
            selectionDirection,
            MailListItemSelectionSource.CommandBarArrows
        );
    }
}

function getIconStyle(densityMode: string): string {
    switch (densityMode) {
        case 'full':
            return iconFull;
        case 'medium':
            return iconMedium;
        case 'compact':
            return iconCompact;
        default:
            return '';
    }
}
