import isSmimeEnabledInViewState from 'owa-smime/lib/utils/isSmimeEnabledInViewState';
import type { ComposeViewState } from 'owa-mail-compose-store';

export function isEncryptionEnabled(viewState: ComposeViewState | undefined) {
    return (
        isSmimeEnabledInViewState(viewState?.smimeViewState) ||
        !!viewState?.protectionViewState?.IRMData?.RmsTemplateId ||
        !!viewState?.protectionViewState?.clpViewState?.selectedCLPLabel?.isEncryptingLabel
    );
}
