import { mutatorAction } from 'satcheljs';
import type { FontPickerItem } from '../utils/FontPickerItem';
import { getStore } from '../store/store';

const setLocalFontList = mutatorAction('setLocalFontList', (items: FontPickerItem[]) => {
    const store = getStore();
    store.isLocalFontListLoaded = true;

    if (items) {
        store.fontName.items = items;
    }
});

export default setLocalFontList;
