import { observer } from 'owa-mobx-react';
import React from 'react';
import type { MailFolderListProps } from 'owa-mail-folder-view';
import type { FolderForestTreeType, MailboxInfoInput } from 'owa-graph-schema';
import type { AriaProperties } from 'owa-accessibility';
import { MailFolderList } from 'owa-mail-folder-view';
import { generateDomPropertiesForAria, AriaRoles } from 'owa-accessibility';
import MailFolderNodeTree from './MailFolderNodeTree';
import type { MailboxInfo } from 'owa-client-types';
import type { MailFolderListWrapperFragment } from 'owa-mail-folder-view-graphql';
import LoadMore from './LoadMore';
import type { FetchPolicy } from '@apollo/client';

export interface MailFolderListWrapperProps extends React.HTMLProps<HTMLDivElement> {
    isFavoritesSupported: boolean; // this tree's folders can or can't support favorites for folder
    rootFolder: MailFolderListWrapperFragment;
    shouldShowLoadingSpinner: boolean;
    hasMoreData: boolean;
    treeType: FolderForestTreeType; // Type of folder tree
    rootNodeId: string;
    mailboxInfo: MailboxInfoInput;
    moveFolder: (
        destinationFolderId: string,
        destinationFolderMailboxInfo: MailboxInfo,
        sourceFolderId: string,
        sourceFolderMailboxInfo: MailboxInfo,
        sourceFolderParentFolderId: string,
        sourceFolderDisplayName: string
    ) => void;
    offset: number;
    fetchMore: (offset: number, fetchPolicy: FetchPolicy) => Promise<void>;
    deleteFolder: (
        folderId: string,
        isSearchFolder?: boolean,
        distinguishedFolderParentIds?: string[]
    ) => void;
    nestDepth?: number;
    ellipsesOnHover?: boolean;
}

/**
 * Wrapper component that reads mobx store specific values for folder hierarchy
 */
export const MailFolderListWrapper = observer(function MailFolderListWrapper(
    props: MailFolderListWrapperProps
) {
    const containerRef = React.useRef<HTMLDivElement | null>(null);
    const { className, style, mailboxInfo, moveFolder, deleteFolder, ellipsesOnHover } = props;

    const renderFolderNode = (
        folderId: string,
        nestDepth: number,
        treeTypeInput: FolderForestTreeType,
        isFavoritesSupported: boolean,
        onContextMenu: (
            evt: React.MouseEvent<unknown>,
            folderId: string,
            distinguishedFolderParentIds: string[]
        ) => void,
        distinguishedFolderParentIds?: string[]
    ): JSX.Element => {
        return (
            <MailFolderNodeTree
                key={folderId}
                folderId={folderId}
                nestDepth={nestDepth}
                treeType={treeTypeInput}
                isFavoritesSupported={isFavoritesSupported}
                onContextMenu={onContextMenu}
                renderFolderNode={renderFolderNode}
                mailboxInfo={mailboxInfo}
                distinguishedFolderParentIds={distinguishedFolderParentIds}
                moveFolder={moveFolder}
                deleteFolder={deleteFolder}
                ellipsesOnHover={ellipsesOnHover}
            />
        );
    };

    const renderLoadNode = () => {
        return (
            <LoadMore
                fetchMore={props.fetchMore}
                shouldShowLoadingSpinner={props.shouldShowLoadingSpinner}
                hasMoreData={props.hasMoreData}
                offset={props.offset}
            />
        );
    };

    const mailFolderListProps: MailFolderListProps = {
        ...props,
        rootFolderId: props.rootFolder?.id,
        renderFolderNode,
        childFolderIds: props.rootFolder?.childFolderIds ?? [],
        renderLoadNode,
        nestDepth: props.nestDepth,
    };

    const ariaProps: AriaProperties = {
        role: AriaRoles.tree,
    };

    return (
        <div
            ref={containerRef}
            style={style}
            className={className}
            {...generateDomPropertiesForAria(ariaProps)}
        >
            {
                /* Render tree */
                <MailFolderList {...mailFolderListProps} />
            }
        </div>
    );
},
'MailFolderListWrapper');
