import { type AddinCommandSurfaceItem } from 'owa-addins-types';
import { onAddinTrigger } from 'owa-nova-actions';
import { createAddInNovaEvent } from 'owa-nova-event-helpers';
import { logUsage } from 'owa-analytics';
import { type ActionSource } from 'owa-analytics-types';

export function addinButtonOnExecuteFn(
    addIn: AddinCommandSurfaceItem,
    itemId: string | undefined,
    projectionTabId: string,
    actionSource: ActionSource
): void {
    logUsage('RibbonAddinInvoked', {
        AddinName: addIn.name,
        Source: actionSource,
    });

    const event = createAddInNovaEvent(
        addIn.key /* appId */,
        'MessageRead' /* entrypoint */,
        projectionTabId,
        '' /* anchorElementId */,
        '' /* composeId */,
        itemId
    );
    onAddinTrigger(event);
}
