import React from 'react';
import { registerIcons } from '@fluentui/style-utilities';
import { outdent, fontIcon } from './style.scss';

import classNames from 'owa-classnames';

export default 'OutdentIcon';

registerIcons({
    icons: {
        OutdentIcon: <span className={classNames(outdent, fontIcon)}></span>,
    },
});
