import { observer } from 'owa-mobx-react';
import { isFeatureEnabled } from 'owa-feature-flags';
import { FocusedExplanationIcon } from 'owa-focused-explanations';
import type ConversationReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ConversationReadingPaneViewState';
import getConversationReadingPaneViewState from 'owa-mail-reading-pane-store-conversation/lib/utils/getConversationReadingPaneViewState';
import getItemReadingPaneViewState from 'owa-mail-reading-pane-store/lib/utils/getItemReadingPaneViewState';
import type ItemReadingPaneViewState from 'owa-mail-reading-pane-store/lib/store/schema/ItemReadingPaneViewState';
import React from 'react';
import { headerIconsContainer, bottomToolBarLeftSide } from './SubjectHeaderV2.scss';

import type CLPLabel from 'owa-mail-protection-types/lib/schema/CLPLabel';
import { CLPSubjectHeaderLabel } from 'owa-mail-protection';
import CategoriesContainer from './CategoriesContainer';
import type { ClientItem } from 'owa-mail-store';
import type { ClientItemId } from 'owa-client-ids';
import MessageAttachmentsContainer from './MessageAttachmentsContainer';

export interface SubjectHeaderBottomToolBarProps {
    categories: JSX.Element[];
    messageCLPLabel: CLPLabel | null;
    shouldShrink: boolean;
    areCategoriesVisible: boolean;
    hideCategories: () => void;
    showCategories: () => void;
    item?: ClientItem;
    itemId?: ClientItemId;
    conversationId?: string;
    firstItemId?: string;
    isItemAttachment?: boolean;
    isPopout?: boolean;
}

export default observer(function SubjectHeaderBottomToolBar(
    props: SubjectHeaderBottomToolBarProps
) {
    const {
        conversationId,
        categories,
        messageCLPLabel,
        shouldShrink,
        item,
        itemId,
        firstItemId,
        isItemAttachment,
        isPopout,
        areCategoriesVisible,
        hideCategories,
        showCategories,
    } = props;

    const conversationReadingPaneViewState = getConversationReadingPaneViewState(conversationId);
    const itemReadingPaneViewState = getItemReadingPaneViewState();

    const focusedConversationIcon = renderFocusedConversationIcon(
        conversationId ? conversationReadingPaneViewState : itemReadingPaneViewState
    );

    return (
        <div className={headerIconsContainer}>
            {shouldShrink && (
                <div className={bottomToolBarLeftSide}>
                    <>
                        {categories.length > 0 && (
                            <CategoriesContainer
                                categories={categories}
                                shouldShrink={shouldShrink}
                                areCategoriesVisible={areCategoriesVisible}
                                showCategories={showCategories}
                                hideCategories={hideCategories}
                            />
                        )}
                        {focusedConversationIcon}
                        {messageCLPLabel && (
                            <CLPSubjectHeaderLabel
                                subjectHeaderLabel={messageCLPLabel}
                                hideLabel={shouldShrink}
                            />
                        )}
                    </>
                </div>
            )}
            {!isFeatureEnabled('doc-infoPane') && !isItemAttachment && !isPopout && (
                <MessageAttachmentsContainer
                    item={item}
                    itemId={itemId}
                    conversationId={conversationId}
                    firstItemId={firstItemId}
                    shouldShrink={shouldShrink}
                />
            )}
        </div>
    );
},
'SubjectHeaderBottomToolBar');

const renderFocusedConversationIcon = (
    viewState: ConversationReadingPaneViewState | ItemReadingPaneViewState
): JSX.Element | null => {
    return !!viewState && !isFeatureEnabled('doc-infoPane') ? <FocusedExplanationIcon /> : null;
};
