import React from 'react';
import {
    subscribeToWindowEvent,
    unsubscribeToWindowEvent,
} from './internal/subscribeToWindowEvent';

const USE_WINDOW_SIZE_REGISTRATION_KEY = Symbol('useWindowSize');

const windowSizeRegistration = new Map<
    Window,
    {
        width: number;
        height: number;
        callbacks: Map<
            any,
            (
                dimensions: Readonly<{
                    width: number;
                    height: number;
                }>
            ) => void
        >;
    }
>();

function subscribeToWindowSize(
    targetWindow: Window,
    refKey: any,
    callback: (
        dimensions: Readonly<{
            width: number;
            height: number;
        }>
    ) => void
) {
    if (!windowSizeRegistration.has(targetWindow)) {
        const resizeCallback = () => {
            /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
             * We need to read this for the purpose of this function, but this is done minimally to minimize performance issues.
             */
            const { innerWidth, innerHeight } = targetWindow;
            const dimensions = { width: innerWidth, height: innerHeight };
            for (const cb of windowSizeRegistration.get(targetWindow)?.callbacks.values() ?? []) {
                cb(dimensions);
            }
        };
        subscribeToWindowEvent(
            targetWindow,
            'resize',
            USE_WINDOW_SIZE_REGISTRATION_KEY,
            resizeCallback
        );
        subscribeToWindowEvent(
            targetWindow,
            'orientationchange',
            USE_WINDOW_SIZE_REGISTRATION_KEY,
            resizeCallback
        );

        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * We need to read this for the purpose of this function, but this is done minimally to minimize performance issues.
         */
        const { innerWidth: width, innerHeight: height } = targetWindow;
        windowSizeRegistration.set(targetWindow, {
            width,
            height,
            callbacks: new Map(),
        });
    }

    windowSizeRegistration.get(targetWindow)?.callbacks.set(refKey, callback);
}

function unsubscribeToWindowSize(targetWindow: Window, refKey: any) {
    windowSizeRegistration.get(targetWindow)?.callbacks.delete(refKey);
    if (windowSizeRegistration.get(targetWindow)?.callbacks.size === 0) {
        unsubscribeToWindowEvent(targetWindow, 'resize', USE_WINDOW_SIZE_REGISTRATION_KEY);
        unsubscribeToWindowEvent(
            targetWindow,
            'orientationchange',
            USE_WINDOW_SIZE_REGISTRATION_KEY
        );
        windowSizeRegistration.delete(targetWindow);
    }
}

export function useWindowSize(targetWindow?: Window) {
    const workWindow = targetWindow ?? window;

    const refKey = React.useRef();

    const [windowDimensions, setWindowDimensions] = React.useState(() => ({
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * We need to read this for the purpose of this function, but this is done at most one time per window instance.
         */
        width: windowSizeRegistration.get(workWindow)?.width ?? workWindow.innerWidth,
        /* eslint-disable-next-line no-restricted-properties  -- (https://aka.ms/OWALintWiki)
         * We need to read this for the purpose of this function, but this is done at most one time per window instance.
         */
        height: windowSizeRegistration.get(workWindow)?.height ?? workWindow.innerHeight,
    }));

    React.useEffect(() => {
        subscribeToWindowSize(workWindow, refKey, setWindowDimensions);

        return () => unsubscribeToWindowSize(workWindow, refKey);
    }, [workWindow]);

    return windowDimensions;
}
